import {useCallback, useRef, useState} from 'react'

import {fromJS, Map} from 'immutable'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {deleteResource} from 'redux-json-api'

import {Typography} from '@mui/material'

import routerUtils from '../../../../../lib/routerUtils'
import SmallModal from '../../../../../shared_components/mui_base_components/modals/small_modal/SmallModal'
import {accountSettingsUrl, railsUrl} from '../../../../../lib/urlTools'
import {currentUserConfig} from '../../../../../lib/userTools'
import ConfirmationModal from '../../../../../shared_components/mui_base_components/modals/confirmation_modal/ConfirmationModal'
import currentUserWrapper from '../../../../../shared_components/currentUserWrapper'
import Form from '../../../../../shared_components/forms/v2/Form'
import validators from '../../../../../shared_components/forms/v2/validators'
import Fields from '../../../../../shared_components/forms/v2/fields/index'

import './deleteAccountModal.scss'

export const DeleteAccountModal = props => {
  const [isProcessing, setIsProcessing] = useState(false)

  const form = useRef(null)

  const deleteAccount = useCallback(() => {
    form.current.onSubmit()

    if (form.current.isValid()) {
      setIsProcessing(true)

      props.deleteResource({
        type: 'users',
        id: props.currentUser.id
      }).then(() => (
        routerUtils.setLocation(railsUrl('sign_out'))
      ))
    }
  }, [form, props.currentUser])

  const handleClosing = useCallback(() => {
    routerUtils.setLocation(accountSettingsUrl)
  }, [props.currentUser])

  const isAdvisor = useCallback(() => {
    const {userConfig} = props

    return !!(userConfig.get('firm-id') && !userConfig.get('client'))
  }, [props.userConfig])

  const formElement = (
    <>
      <div className='body-text'>
        This will permanently delete ALL items, documents, and information you have saved and <strong>permanently</strong> delete your account.
      </div>
      <Form className='core' ref={form}>
        <div className='delete-instructions-text'>
          To proceed, type the word <strong>delete</strong> into the box below.
        </div>
        <Fields.Text
          data={fromJS({
            id: 'delete-verification',
            name: 'delete-verification'
          })}
          validator={validators.deleteAccountValidator}
        />
      </Form>
    </>
  )

  return (
    <>
      {
        isAdvisor() ?
          <SmallModal
            className='delete-account-modal'
            onClose={handleClosing}
            open={true}>
            <Typography
              className='body-text'
              textAlign='center'
              variant='body1'>
              Your account cannot be deleted because you are an advisor.
              Please contact your account manager for assistance.
            </Typography>
          </SmallModal> :
          <ConfirmationModal
            additionalElement={formElement}
            className='delete-account-modal'
            confirmButtonText='Permanently delete account'
            headingText='Are you sure you want to delete your account?'
            loading={isProcessing}
            onClose={handleClosing}
            onConfirmation={deleteAccount}
            open={true}
            showWarningIcon={true}
          />
      }
    </>
  )
}

DeleteAccountModal.propTypes = {
  currentUser: PropTypes.shape({
    id: PropTypes.number
  }),
  deleteResource: PropTypes.func,
  userConfig: PropTypes.instanceOf(Map)
}

const mapStateToProps = ({api}) => {
  const userConfig = currentUserConfig(api)

  return {userConfig}
}

export default currentUserWrapper(connect(mapStateToProps, {deleteResource})(DeleteAccountModal))
