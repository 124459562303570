import './spinner.scss'

import spinnerImage from '../shared_assets/spinner.gif'


const Loader = props => (
  <div>
    {
      props.loading ?
        (
          <div className='page-loader new-loader'>
            <img className='gif-spinner' alt='Loading image' src={spinnerImage} />
          </div>
        ) :
        props.children
    }
  </div>
)

Loader.defaultProps = {loading: true}

export default Loader
