import * as actions from './corpMaxActionTypes'

export const reshapeKits = resource => ({
  type: actions.RESHAPE_KITS,
  payload: resource
})

export const reshapeElements = resource => ({
  type: actions.RESHAPE_ELEMENTS,
  payload: resource
})

export const reshapeItems = resource => ({
  type: actions.RESHAPE_ITEMS,
  payload: resource
})

export const reshapeCategories = resource => ({
  type: actions.RESHAPE_CATEGORIES,
  payload: resource
})

export const reshapeListMappings = resource => ({
  type: actions.RESHAPE_LIST_MAPPINGS,
  payload: resource
})

export const reshapeKeyFieldMappings = resource => ({
  type: actions.RESHAPE_KEY_FIELD_MAPPINGS,
  payload: resource
})

export const reshapeViews = resource => ({
  type: actions.RESHAPE_VIEWS,
  payload: resource
})

export const reshapeOwners = resource => ({
  type: actions.RESHAPE_OWNERS,
  payload: resource
})

export const reshapePlans = data => ({
  type: actions.RESHAPE_PLANS,
  payload: data
})
