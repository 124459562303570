import {Component} from 'react'
import PropTypes from 'prop-types'

import {Accept} from '../../../dashboard/src/components/blocks/Buttons'
import checkmark from '../../../shared_assets/v2/success-checkmark.svg'
import Closer from '../../../shared_components/core/closer/Closer'
import DoneScreenLinks from './DoneScreenLinks'
import Modals from '../../core/modals/Modals'

import './doneScreen.scss'


export default class DoneScreen extends Component {
  constructor() {
    super()

    this.closer = this.closer.bind(this)
    this.closeModal = this.closeModal.bind(this)
  }

  // This is needed to make sure the event is logged when moving back and forth between screens
  componentDidUpdate(prevProps) {
    if (this.props.section === 'done' && prevProps.section !== 'done')
      this.props.logEvent({name: 'viewed_done_modal'})
  }

  closer() { return <Closer closer={this.closeModal} /> }

  closeModal() {
    this.props.logEvent({name: 'dismissed_done_modal'})
    this.props.toggleModal()
  }

  render() {
    return (
      <Modals.PopUpModalLarge className='done-screen' showModal={this.props.section === 'done'} closerComponent={this.closer}>
        <img alt='All set!' src={checkmark} />
        <h2>All set!</h2>
        <DoneScreenLinks {...this.props} />
        <Accept onClick={this.closeModal}>Done</Accept>
      </Modals.PopUpModalLarge>
    )
  }
}


DoneScreen.propTypes = {
  logEvent: PropTypes.func,
  section: PropTypes.string,
  toggleModal: PropTypes.func
}

