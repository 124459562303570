import DisclosureFooter from '../../../shared_components/DisclosureFooter'
import Main from './Main'

import './dashboardMainContentWrapper.scss'


const DashboardMainContentWrapper = props => (
  <div className='dashboard-main-content-wrapper flex-container flex-child'>
    <Main children={props.children} />
    <DisclosureFooter className='dashboard-disclosure-footer' />
  </div>
)

export default DashboardMainContentWrapper
