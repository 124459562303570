import {List, Map} from 'immutable'

import {articles} from '../../../../../shared_components/everplans_articles/articles'


export const EVERPLANS_ARTICLES_BY_CATEGORY = Map({
  'Personal Info and IDs': List([
    articles.get('time-to-go-on-a-document-and-id-scavenger-hunt'),
    articles.get('do-this-if-your-cell-phone-is-lost-or-stolen')
  ]),
  Digital: List([
    articles.get('create-a-digital-estate-plan-using-these-5-steps'),
    articles.get('the-four-most-popular-password-managers')
  ]),
  'Family and Loved Ones': List([
    articles.get('factors-to-consider-when-naming-a-guardian-for-children-or-special-needs-adults'),
    articles.get('how-difficult-is-it-to-talk-with-your-family-about-money')
  ]),
  Financial: List([
    articles.get('6-things-people-never-budget-for'),
    articles.get('how-difficult-is-it-to-talk-with-your-family-about-money')
  ]),
  Health: List([
    articles.get('advance-directive-cheat-sheet-five-tips-to-get-you-started'),
    articles.get('how-to-evaluate-life-support-treatments-and-what-they-ll-do-to-your-body')
  ]),
  'Home and Property': List([
    articles.get('the-3-things-you-must-do-to-leave-your-house-to-someone-else'),
    articles.get('what-happens-to-a-self-storage-unit-when-someone-dies')
  ]),
  Legal: List([
    articles.get('7-life-events-that-require-an-immediate-estate-plan-refresh'),
    articles.get('3-simple-questions-you-should-ask-an-estate-attorney-and-we-have-the-answers')

  ]),
  Aging: List([
    articles.get('a-complete-overview-of-in-home-care'),
    articles.get('how-do-you-want-to-live-at-the-end-of-your-life')
  ]),
  "After I'm Gone": List([
    articles.get('how-to-pre-plan-your-funeral'),
    articles.get('all-you-need-to-know-about-cremation'),
    articles.get('how-to-write-an-ethical-will'),
    articles.get('how-to-give-to-charity-when-you-die')
  ])
})
