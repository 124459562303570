import {Map, fromJS} from 'immutable'
import PropTypes from 'prop-types'
import {useCallback, useState, useRef, useEffect} from 'react'
import classnames from 'classnames'

import {Add, Button} from '../../../../../dashboard/src/components/blocks/Buttons'
import Form from '../../../../forms/v2/Form'
import Fields from '../../../../../shared_components/forms/v2/fields'
import {TextGutterMedium} from '../../../../../dashboard/src/components/blocks/Texts'

import {SELECTED_OPTION_DESCRIPTIONS} from '../configurations/gapFillerAssessmentResponseConfiguration'

import './funeralExpenseAssessmentForm.scss'

const FuneralExpenseAssessmentForm = ({
  sectionConfig,
  onSubmit,
  goBack,
  infoData
}) => {
  const formFieldName = sectionConfig.get('name')
  const defaultValue = infoData.get(formFieldName)
  const [fieldValue, setFieldValue] = useState(defaultValue)
  const formRef = useRef()

  const submitData = useCallback(
    () => onSubmit(Map({[formFieldName]: fieldValue})),
    [formFieldName, fieldValue]
  )

  const onFormUpdate = useCallback(() => {
    const form = formRef.current
    if (form) setFieldValue(form.formData().getIn([formFieldName, 'value']))
  })

  useEffect(() => {
    // This allows the fieldValue to be updated with what is in the form. - Kay
    onFormUpdate()
  }, [formFieldName])

  return (
    <div className={classnames('funeral-expense-assessment-form', formFieldName)}>
      <h2>{sectionConfig.get('question')}</h2>
      <Form
        className='core'
        ref={formRef}
        key={sectionConfig.get('name')}
        onUpdate={onFormUpdate}>
        <Fields.VerticalRadioList
          data={fromJS({
            items: sectionConfig.get('items'),
            name: sectionConfig.get('name'),
            id: sectionConfig.get('name'),
            value: defaultValue
          })}
        />
        <TextGutterMedium className='small-text italicized'>
          {SELECTED_OPTION_DESCRIPTIONS.get(fieldValue)}
        </TextGutterMedium>
      </Form>
      <div className='flex-container funeral-expense-button-group'>
        <Button disabled={!fieldValue} onClick={submitData}>
          Next
        </Button>
        <Add onClick={goBack}>Back</Add>
      </div>
    </div>
  )
}

FuneralExpenseAssessmentForm.propTypes = {
  infoData: PropTypes.instanceOf(Map),
  sectionConfig: PropTypes.instanceOf(Map),
  onSubmit: PropTypes.func,
  goBack: PropTypes.func
}

export default FuneralExpenseAssessmentForm
