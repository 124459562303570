import PropTypes from 'prop-types'
import {fromJS, Map, List} from 'immutable'
import {useState, useRef} from 'react'

import Form from '../../../../../shared_components/forms/v2/Form'
import Fields from '../../../../../shared_components/forms/v2/fields'
import Modals from '../../../../../shared_components/core/modals/Modals'
import ButtonGroup from '../../../../../shared_components/core/buttons/ButtonGroup'
import {Accept} from '../../blocks/Buttons'
import Closer from '../../../../../shared_components/core/closer/Closer'
import {useNotificationContext} from '../../../../../shared_components/notifications/NotificationContext'
import Logger from '../../../../../lib/NewLogger'

import validators from '../../../../../shared_components/forms/v2/validators'
import {TextGutterMedium} from '../../blocks/Texts'

import './coupon.scss'

const Coupon = ({updateResource, userConfig}) => {
  const [showModal, setShowModal] = useState(false)
  const [errors, setErrors] = useState(List())
  const [processing, setProcessing] = useState(false)
  const formRef = useRef()
  const {alwaysNotify} = useNotificationContext()

  const closeModal = () => {
    setErrors(List())
    setShowModal(false)
  }

  const closer = () => <Closer closer={() => closeModal()} />

  const logCouponEvent = name => Logger.log({name})

  const submitCoupon = coupon => {
    const resource = {
      type: 'billings',
      id: userConfig.get('everplan-id'),
      attributes: {coupon}
    }

    setProcessing(true)

    updateResource(resource)
      .then(() => {
        logCouponEvent('user_submitted_coupon_successfully')
        alwaysNotify.shortSuccess('The coupon was successfully applied.')
        setShowModal(false)
      })
      .catch(() => {
        logCouponEvent('user_submitted_coupon_unsuccessfully')
        setErrors(List(['Sorry, we do not recognize this coupon.']))
      })
      .finally(() => setProcessing(false))
  }

  const applyCoupon = () => {
    const form = formRef.current
    const formData = form.onSubmit()

    if (formData)
      submitCoupon(formData.getIn(['coupon', 'value']))
  }

  const clearServerErrors = () => {
    if (!processing)
      setErrors(List())
  }

  return (
    <div className='coupon'>
      <a onClick={() => setShowModal(true)}>Enter a coupon code</a>
      <Modals.LinkPromptPopUpModal closerComponent={closer} showModal={showModal}>
        <div className='forms-playground'>
          <h2>Add A Coupon Code</h2>
          <Form
            className='core'
            ref={formRef}
            showFormLevelError={!errors.isEmpty()}
            invalidFormError={errors}
            onChange={clearServerErrors}>
            <Fields.Text
              data={fromJS({
                id: 'coupon',
                legend: 'Coupon Code',
                name: 'coupon',
                placeholder: 'Enter coupon code'
              })}
              validator={validators.required}
            />
            <TextGutterMedium>
              If you have an active coupon already applied to your account,
              adding a new one will override your previous coupon.
            </TextGutterMedium>
          </Form>
          <ButtonGroup>
            <Accept onClick={applyCoupon} processing={processing}>
              Submit
            </Accept>
          </ButtonGroup>
        </div>
      </Modals.LinkPromptPopUpModal>
    </div>
  )
}

Coupon.propTypes = {
  userConfig: PropTypes.instanceOf(Map),
  updateResource: PropTypes.func
}

export default Coupon
