import {Map} from 'immutable'

const body = `
Deciding on end-of-life living arrangements can be very difficult, partly because the different people involved -- children, parents, extended family members, friends -- may have different perspectives on what's important. It's important for everyone to get on the same page before you get deep into the decision-making process.

Once there's clarity (and agreement) on key needs and wishes, it will become much easier to match those needs and wishes against the different types of care arrangements, and find the right one for you.


###  Consider All The Important Factors Before Making A Decision

Factors such as health, finances, location of family members, and degree of independence all play a significant role in determining the housing option that best meets your needs. When choosing a type of residence, the following factors should be taken into account:

**General wishes**: What do you value most in a living arrangement?

* Do you want to be in a social environment, or a solitary environment?
* Do you want to stay in the city you live in, or would you consider moving to another city?
* Do you have a strong preference for staying in your home, moving to a family member's home, or moving to a care facility?

**Location of family members**: What is the optimal location for the residence?

* Is the residence you're considering close enough so that you'd have regular visitors?
* If you'll have visitors regularly coming in from out of town, is the residence easily accessible to those people?

**Degree of independence**: How much help and care will you require (and desire)?

* Can you manage "basic activities of daily living," such as getting dressed, getting in and out of bed, bathing and grooming, and walking?
* Do you need help remembering to take medications?
* Can you cook for yourself, or do you need meals provided for you?

**Finances**: What can you afford?

* What are the daily, weekly, and monthly costs of the residence you're considering?
* Do you have Long-Term Care Insurance (LTC), or another way of paying for the care you currently need or will need in the future?
* Will Medicaid help pay for housing costs?

**Health**: What level of care is required?

* Can the residence you're considering meet your current medical care needs?
* Can the residence meet your likely future needs if your health deteriorates?
* Can it help you with the basic activities of daily living that you might need help with?
* Does the staff have experience caring for someone in your situation?

### Communicating With Your Family

The most important aspect of making end-of-life living arrangements is open communication. There are often many people involved in making these decisions, such as children, grandchildren, and siblings, and everyone involved should be able to participate in the conversation. Even if decisions are being made on behalf of someone else, such as an aging parent, listening to that person and including him or her in the conversation is important as well.

These conversations can be difficult. It can be hard to talk to your children about your wishes; it can be hard to talk to parents about your concerns; it can be hard to reach a consensus among siblings about what's best for an aging parent. But these conversations are essential, and ultimately lead to understanding, compromise, and making the decisions that are best for you and your family.
`


export default Map({
  body,
  headline: 'How Do You Want To Live At The End Of Your Life?',
  summary: 'Know your options and communicate what you want so you can make the right decisions.',
  query: 'how-do-you-want-to-live-at-the-end-of-your-life',
  url: 'https://www.everplans.com/articles/how-do-you-want-to-live-at-the-end-of-your-life'
})
