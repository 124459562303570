import {Component} from 'react'
import {Map, List} from 'immutable'
import PropTypes from 'prop-types'

import {BoxGutterLarge} from '../../blocks/Boxes'
import {humanizedFullNames} from '../../../../../lib/nameTools'
import {Button} from '../../blocks/Buttons'

import routerUtils from '../../../../../lib/routerUtils'

import './accountInformationPreview.scss'


export default class AccountInformationPreview extends Component {
  constructor() {
    super()

    this.onClick = this.onClick.bind(this)
  }

  onClick() {
    const baseUrlPath = this.props.userConfig.get('everplan-id') ? 'cm' : 'account'
    routerUtils.push(`${baseUrlPath}/account-information/${this.props.user.get('id')}`)
  }

  primaryEmail() {
    return this.props.user.get('emails').find(email => email.get('id') === this.props.user.get('default-email-id')).get('email-address')
  }

  render() {
    const emailCount = this.props.user.get('emails').size

    return (
      <BoxGutterLarge className='account-information-preview'>
        <h2>Account Information:</h2>
        <div className='account-information-container flex-container'>
          <div className='flex-child flex-container list-item'>
            <strong className='flex-child'>Name:</strong>
            <div className='detail flex-child'>{humanizedFullNames(List([this.props.user]))}</div>
          </div>
          <div className='flex-child flex-container list-item'>
            <strong className='flex-child'>Email Address:</strong>
            <div className='detail flex-child'>
              <span className='email'>{this.primaryEmail()}</span>
              <span className='email-count'>{emailCount} email {emailCount > 1 ? 'addresses' : 'address'}</span>
            </div>
          </div>
        </div>
        <Button onClick={this.onClick}>Manage</Button>
      </BoxGutterLarge>
    )
  }
}

AccountInformationPreview.propTypes = {
  user: PropTypes.instanceOf(Map),
  userConfig: PropTypes.instanceOf(Map)
}
