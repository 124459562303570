import {Component} from 'react'
import {List, Map} from 'immutable'
import PropTypes from 'prop-types'

import Select from '../../../../../../shared_components/forms/v2/fields/Select'
import Form from '../../../../../../shared_components/forms/v2/Form'
import {findSelectedValueId} from '../../../../../../shared_components/forms/v2/lib/tools'
import {isCompoundItem, itemTopKitListMappingId} from '../../../../../../lib/plan_data/item'
import Validators from '../../../../../../shared_components/forms/v2/validators'
import {categoryItems} from '../../../../../../lib/plan_data/category'
import {findResourceById, findResourceByAttributeId} from '../../../../../../lib/plan_data/userData'


export default class NextBestActionItemSelectFields extends Component {
  constructor(props) {
    super(props)
    this.state = this.initializeState(props)

    this.onItemSelectChange = this.onItemSelectChange.bind(this)
    this.onListMappingChange = this.onListMappingChange.bind(this)
    this.onCompoundItemChange = this.onCompoundItemChange.bind(this)
    this.onCategorySelectChange = this.onCategorySelectChange.bind(this)
  }

  // Moved this out as to not clutter the state, but basically this method finds the item from the slug, if it exists,
  // and then updates the different pieces of state to pre-populate the fields or defaults them according.
  initializeState(props) {
    const item = findResourceByAttributeId({
      resourceList: this.props.items,
      attribute: 'slug',
      id: props.nextBestAction.getIn(['item', 'item-slug'])
    })

    const category = findResourceById({
      resourceList: this.props.categories,
      id: item.get('category-id')
    })

    this.updateHeader(props, item)

    const itemTopKit = props.kits.get(item.get('kit-id'), Map())

    return {
      item,
      category,
      items: categoryItems(category, this.props.items) || List(),
      itemTopKit,
      isCompoundItem: item.isEmpty() ? false : isCompoundItem(itemTopKit),
      listMappingId: item.isEmpty() ? '' : itemTopKitListMappingId({kits: this.props.kits, itemTopKit})
    }
  }

  /*
   Updating the next best action header if it is empty, so that it appears when updating a next best action that is type full-item
   has an item, but no header. Doing it in this component because all the logic needed to update the header is already munged in this
   component and this component is actually responsible for updating the header when an item changes.
  */
  updateHeader(props, item) {
    return !props.nextBestAction.get('header') &&
      !item.isEmpty() &&
      props.updateNextBestAction(props.nextBestAction.set('header', item.get('header')))
  }

  selectCategories() {
    return this.props.categories
      .sortBy(category => category.get('sort-order'))
      .map(category => Map(
        {
          id: category.get('id'),
          label: category.get('name'),
          value: category.get('name')
        }
      ))
      .unshift(Map({label: 'Choose a Category', value: ''}))
  }

  onCategorySelectChange() {
    this.resetSelectedItem()

    const category = findResourceById({
      resourceList: this.props.categories,
      id: findSelectedValueId({selectId: 'category', form: this.refs.form})
    })

    if (category.isEmpty())
      this.setState({items: Map(), category: Map()}) // to reset everything if 'Choose a Category' is selected
    else
      this.setState({category, items: categoryItems(category, this.props.items)})
  }

  selectItems() {
    return this.state.items
      .sortBy(item => item.get('sort-order'))
      .map(item => Map(
        {
          id: item.get('id'),
          label: item.get('header'),
          value: item.get('header')
        }
      ))
      .unshift(Map({label: 'Choose an Item', value: ''}))
  }

  onItemSelectChange() {
    const item = findResourceById({
      resourceList: this.state.items,
      id: findSelectedValueId({selectId: 'item', form: this.refs.form})
    })

    if (item.isEmpty()) {
      this.resetSelectedItem()
    } else {
      const itemTopKit = this.props.kits.get(item.get('kit-id'))

      this.props.updateNextBestAction(this.props.nextBestAction
        .set('item', Map({'element-id': itemTopKit.getIn(['elements', 0, 'id']), 'item-slug': item.get('slug')}))
        .set('header', item.get('header'))
      )

      this.setState({
        isCompoundItem: isCompoundItem(itemTopKit),
        itemTopKit,
        listMappingId: itemTopKitListMappingId({kits: this.props.kits, itemTopKit})
      })
    }
  }

  listMappingItems() {
    return this.props.listMappings.getIn([this.state.listMappingId, 'mappings'])
      .map(mapping => Map(
        {
          id: mapping.get('value'),
          label: mapping.get('value'),
          value: mapping.get('value')
        }
      ))
  }

  // This adds a conditional value if one is selected in order to show the correct conditional when a next best action is clicked
  onListMappingChange() {
    this.props.updateNextBestAction(
      this.props.nextBestAction.mergeIn(
        ['item'],
        Map({'conditional-value': this.refs.form.formData().getIn(['list-mapping', 'value'])})
      )
    )
  }

  // This updates the element-id of the item in the next best action to the element-id of the compound item selected
  onCompoundItemChange() {
    this.props.updateNextBestAction(
      this.props.nextBestAction.mergeIn(
        ['item'],
        Map({'element-id': findSelectedValueId({selectId: 'compound-item', form: this.refs.form})})
      )
    )
  }

  compoundItems() {
    return this.state.itemTopKit.get('elements')
      .map(element => Map(
        {
          id: element.get('id'),
          label: element.get('name'),
          value: element.get('name')
        }
      ))
  }

  resetSelectedItem() {
    this.setState({
      itemTopKit: Map(),
      isCompoundItem: false,
      listMappingId: '',
      item: Map()
    })

    this.props.updateNextBestAction(this.props.nextBestAction.delete('item'))
  }

  selectedCompoundValue() {
    return this.props.elements.get(this.props.nextBestAction.getIn(['item', 'element-id']), Map()).get('name')
  }

  render() {
    if (this.props.nextBestAction.get('type')) {
      return (
        <Form className='core next-best-action-item-select-fields' ref='form'>
          <Select
            data={
              Map({
                id: 'category',
                items: this.selectCategories(),
                legend: 'Select Category',
                name: 'category',
                value: this.state.category.get('name')
              })
            }
            onChange={this.onCategorySelectChange}
            validator={Validators.required}
          />
          {
            !this.state.category.isEmpty() && (
              <Select
                key={this.state.category.get('id')}
                data={
                  Map({
                    id: 'item',
                    items: this.selectItems(),
                    legend: 'Select Item:',
                    name: 'item',
                    value: this.state.item.get('header')
                  })
                }
                onChange={this.onItemSelectChange}
                validator={Validators.required}
              />
            )
          }
          {
            this.state.listMappingId && !this.state.isCompoundItem && (
              <div key={this.state.listMappingId}>
                <Select
                  key={this.state.listMappingId}
                  data={
                    Map({
                      id: 'list-mapping',
                      items: this.listMappingItems(),
                      legend: 'Select Conditional Value:',
                      name: 'list-mapping',
                      value: this.props.nextBestAction.getIn(['item', 'conditional-value'], '')
                    })
                  }
                  onChange={this.onListMappingChange}
                />
              </div>
            )
          }
          {
            this.state.isCompoundItem && (
              <Select
                key={this.state.itemTopKit.get('id')}
                data={
                  Map({
                    id: 'compound-item',
                    items: this.compoundItems(),
                    legend: 'Select Compound Item Option:',
                    name: 'compound-item',
                    value: this.selectedCompoundValue()
                  })
                }
                onChange={this.onCompoundItemChange}
              />
            )
          }
        </Form>
      )
    } else {
      return null
    }
  }
}

NextBestActionItemSelectFields.propTypes = {
  elements: PropTypes.instanceOf(Map),
  categories: PropTypes.instanceOf(List),
  items: PropTypes.instanceOf(List),
  kits: PropTypes.instanceOf(Map),
  listMappings: PropTypes.instanceOf(Map),
  nextBestAction: PropTypes.instanceOf(Map),
  setItemHeader: PropTypes.func,
  updateNextBestAction: PropTypes.func
}
