import {List, Map} from 'immutable'
import PropTypes from 'prop-types'

import GapFillerGuideSectionContents from './GapFillerGuideSectionContents'
import GapFillerGuideResources from './GapFillerGuideResources'


const GapFillerGuideModalBody = props => (
  <div className='gap-filler-guide-modal-body'>
    {
      props.sections.map(
        subsection => (
          <div key={subsection.heading} className='left-column section'>
            <h3>{subsection.get('heading')}</h3>
            <GapFillerGuideSectionContents
              contents={subsection.get('contents')}
              context={props.context}
              itemResponse={props.itemResponse}
            />
            <GapFillerGuideResources
              context={props.context}
              itemResponse={props.itemResponse}
              resources={subsection.get('resources')}
            />
          </div>
        )
      )
    }
  </div>
)


GapFillerGuideModalBody.propTypes = {
  context: PropTypes.string,
  itemResponse: PropTypes.instanceOf(Map),
  sections: PropTypes.instanceOf(List)
}

export default GapFillerGuideModalBody
