import classnames from 'classnames'

import {useState} from 'react'

import {Tooltip} from '@mui/material'

import {formatPhoneNumber} from '../../../../lib/contactTools'
import phoneIcon from '../../../../dashboard/assets/phone-icon.svg'

import './phoneButton.scss'

const PhoneButton = ({className, phoneNumber}) => {
  const [showTooltip, setShowTooltip] = useState(false)
  
  return (
    <Tooltip
      arrow
      slotProps={{
          popper: {
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, -6],
                },
              },
            ],
          },
        }}
      PopperProps={{
        disablePortal: true,
      }}
      onClose={() => setShowTooltip(false)}
      open={showTooltip}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      title={formatPhoneNumber(phoneNumber)}>
      <div
        className={classnames('phone-button', className)}
        onClick={() => setShowTooltip(!showTooltip)}>
        <img alt='' src={phoneIcon} />
        Phone
      </div>
    </Tooltip>
  )
}

export default PhoneButton
