import {List} from 'immutable'
import PropTypes from 'prop-types'

import CircleIconWrapper from './CircleIconWrapper'

import warningIcon from '../../../../../assets/warning-icon.svg'

import {addPossessive} from '../../../../../../lib/tools'
import {timeWithZoneMonthDayYear} from '../../../../../../lib/dateTools'
import {pendingDeathReport} from '../../../../../../lib/corpMaxDeputyTools'

import './pendingUnlock.scss'

const PendingUnlock = props => {
  const deathReport = pendingDeathReport(props.deathReports)

  if (!props.ownEverplan && !props.isDeceasedEverplanner && deathReport) {
    return (
      <div className='header-everplan-information pending-unlock flex-container'>
        <div className='death-report-warning flex-container'>
          <CircleIconWrapper className='warning-icon'>
            <img alt='Warning Icon' src={warningIcon} />
          </CircleIconWrapper>
          <h3 className='heading flex-child'>Your request to unlock this Everplan is being processed.</h3>
        </div>
        <span className='description'>
          You reported {props.firstName} as deceased on {timeWithZoneMonthDayYear({date: deathReport.get('created-at')})}. Per {addPossessive(props.fullName)} request,
          we're holding the release of their Everplan until {timeWithZoneMonthDayYear({date: deathReport.get('created-at'), additionalHours: deathReport.get('death-verification-period') / 60})}.
        </span>
      </div>
    )
  }
  return null
}

PendingUnlock.propTypes = {
  firstName: PropTypes.string,
  fullName: PropTypes.string,
  deathReports: PropTypes.instanceOf(List),
  isDeceasedEverplanner: PropTypes.bool,
  ownEverplan: PropTypes.bool
}
export default PendingUnlock
