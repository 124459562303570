import PropTypes from 'prop-types'

import StyledTooltip from './StyledTooltip'

import './tooltip.scss'

const Tooltip = ({children, disableScreen, onClose, ...otherProps}) => (
  <div className='tooltip'>
    {
      disableScreen &&
      <div className='tooltip-overlay' onClick={onClose} />
    }
    <StyledTooltip
      {...otherProps}
      arrow
      onClose={onClose}>
      {children}
    </StyledTooltip>
  </div>
)

Tooltip.propTypes = {
  children: PropTypes.node,
  disableScreen: PropTypes.bool,
  onClose: PropTypes.func
}

export default Tooltip
