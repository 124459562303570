import {useState} from 'react'

import {List} from 'immutable'
import PropTypes from 'prop-types'
import Select from 'react-select'

import {humanizedFullName} from '../../../../../lib/nameTools'
import styles from './selectDeputiesStyles'

const SelectDeputies = props => {
  const [noOptionsMessage, setNoOptionsMessage] = useState("We can't find anyone by this name.")

  const options = props.deputies.map(deputy => ({value: deputy.get('id'), label: humanizedFullName(deputy.get('ownable'))})).toJS()

  const onChange = selectedOptions => {
    const selectedDeputyIds = selectedOptions.map(selectedOption => selectedOption.value)

    if (options.length === selectedDeputyIds.length)
      setNoOptionsMessage("You've selected all of your deputies.")
    else
      setNoOptionsMessage("We can't find anyone by this name.")

    props.setSelectedDeputies(props.deputies.filter(deputy => selectedDeputyIds.includes(deputy.get('id'))))
  }

  return (
    <Select
      className='select-deputies'
      classNamePrefix='deputies-select'
      isMulti
      noOptionsMessage={() => noOptionsMessage}
      onChange={onChange}
      openMenuOnClick
      options={options}
      placeholder='Search your existing deputies'
      styles={styles}
    />
  )
}

SelectDeputies.propTypes = {
  addNewDeputyLink: PropTypes.func,
  deputies: PropTypes.instanceOf(List),
  setSelectedDeputies: PropTypes.func
}

export default SelectDeputies
