// @todo Handle the call to action for the Advance Directive gap filler.

import {fromJS} from 'immutable'


const CALL_TO_ACTION_CONFIGURATIONS = fromJS({
  'advance-directive': {
    'action-text': 'Download',
    header: 'Download A Free Advance Directive Form For',
    prompt: 'Download our free form, complete it, sign it, and add the information to your Everplan.'
  },
  'life-insurance-my-life-insurance-policy': {
    'action-text': 'Get started now',
    header: 'Compare Rates On Term Life Insurance',
    intercept: {
      bannerText: 'Connecting you to:',
      bodyText: [
        "We're glad you're considering buying life insurance. It's an important part of the overall plan for a lot of families.",
        "You will now be heading to a site called My Life Covered. We've chosen them as a partner because we think they'll do a good job of helping you find the coverage that's right for your family.",
        'They willl help you compare rates on term life insurance policies from multiple insurance companies.'
      ],
      interceptButtonText: 'Compare rates at My Life Covered',
      callToActionURL: 'https://everplans.mylifecovered.com',
      eventName: 'continued_to_gap_filler_vendor',
      headingText: ['MY LIFE ', <strong key='COVERED'>COVERED</strong>]
    },
    prompt: 'Our partners at My Life Covered will show you quotes from a variety of insurance carriers.'
  },
  'power-of-attorney-my-power-of-attorney': {
    'action-text': 'Get started now',
    header: 'Create a Power of Attorney online in minutes',
    intercept: {
      bannerText: 'Connecting you to:',
      bodyText: [
        'Nolo’s Quicken WillMaker & Trust enables you to get a customized estate plan, including a Will, Living Trust, Health Care Directive, Power of Attorney, and other essential documents.',
        'Once you’ve created your POA, remember to upload a copy to your Everplan and make sure your loved ones know where to find the official signed copy.'
      ],
      callToActionURL: 'https://www.willmaker.com/lander/pricing',
      eventName: 'continued_to_gap_filler_vendor',
      headingText: 'Nolo Quicken WillMaker & Trust',
      interceptButtonText: 'Continue'
    },
    prompt: 'Our partners at Nolo Quicken WillMaker & Trust will walk you through each step.'
  },
  'will-details-about-your-will': {
    'action-text': 'Get started now',
    header: 'Create a Will online in minutes',
    intercept: {
      bannerText: 'Connecting you to:',
      bodyText: [
        'Nolo’s Quicken WillMaker & Trust enables you to get a customized estate plan, including a Will, Living Trust, Health Care Directive, Power of Attorney, and other essential documents.',
        'Once you’ve created your Will, remember to upload a copy to your Everplan and make sure your loved ones know where to find the official signed copy.'
      ],
      callToActionURL: 'https://www.willmaker.com/lander/pricing',
      eventName: 'continued_to_gap_filler_vendor',
      headingText: 'Nolo Quicken WillMaker & Trust',
      interceptButtonText: 'Continue'
    },
    prompt: 'Our partners at Nolo Quicken WillMaker & Trust will walk you through each step.'
  },
  'burial-cremation-or-donation-preferences-my-disposition-wishes-and-plans': {
    'action-text': 'Get started',
    header: 'How Much Will You Want To Spend?',
    prompt: 'Use our interactive calculator to  price out your grand finale.'
  }
})

export default CALL_TO_ACTION_CONFIGURATIONS
