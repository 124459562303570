import {TextGutterMedium} from '../../blocks/Texts'


const HowDoDeputiesGainAccess = () => (
  <div className='flex-child sub-info how-do-deputies-gain-access'>
    <h3>How Do Deputies Gain Access?</h3>
    <div>
      <TextGutterMedium>
        Your Deputies will receive an email invitation with instructions to create a free Everplans account.
      </TextGutterMedium>
      <TextGutterMedium>
        They will then be able to see the sections you chose to share with them.
      </TextGutterMedium>
    </div>
  </div>
)

export default HowDoDeputiesGainAccess
