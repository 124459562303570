import PropTypes from 'prop-types'
import {Component} from 'react'

import routerUtils from '../../../../lib/routerUtils'
import Logger from '../../../../lib/NewLogger'

import DownloadLink from './DownloadLink'

import './downloadPreviewLinkController.scss'


class DownloadPreviewLinkController extends Component {
  constructor() {
    super()
    this.logAndNavigateToPreview = this.logAndNavigateToPreview.bind(this)
  }

  logAndNavigateToPreview() {
    Logger.log({
      name: 'clicked_everplan_preview',
      payload: {
        actor: 'planholder',
        context: 'primary_navigation',
        everplan_id: this.props.currentUser.everplan.id
      }
    })

    routerUtils.push(`/cm/everplan-preview/${this.props.currentUser.everplan.id}`)
  }

  render() {
    if (this.props.hideDownloadLink)
      return null

    return (
      <div className='download-preview-link-controller'>
        <div className='nav-mobile-link'>
          <DownloadLink className={this.props.className} mobile onClick={this.logAndNavigateToPreview} />
        </div>
        <div className='nav-non-mobile-link'>
          <DownloadLink className={this.props.className} onClick={this.logAndNavigateToPreview} />
        </div>
      </div>
    )
  }
}

DownloadPreviewLinkController.propTypes = {
  currentUser: PropTypes.shape({
    everplan: PropTypes.object
  }),
  hideDownloadLink: PropTypes.bool
}

export default DownloadPreviewLinkController
