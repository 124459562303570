import {TextGutterSmall} from '../../blocks/Texts'
import PropTypes from 'prop-types'
import spinnerImage from '../../../../../shared_assets/spinner.gif'

import './everplanPreviewLoader.scss'

const EverplanPreviewLoader = ({ownEverplan}) => (
  <div className='everplan-preview-loader'>
    <img className='preview-loading-spinner' alt='Loading image' src={spinnerImage} />
    {!ownEverplan && (
      <div className='everplan-preview-loader-text'>
        <TextGutterSmall>
          Please wait while this Everplan is decrypted.
        </TextGutterSmall>
        <TextGutterSmall>
          It can take up to 20 seconds.
        </TextGutterSmall>
      </div>
    )}
  </div>
)

EverplanPreviewLoader.propTypes = {
  ownEverplan: PropTypes.bool
}

export default EverplanPreviewLoader
