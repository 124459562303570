import {useState, useCallback, useEffect} from 'react'
import {Map, List} from 'immutable'
import {PropTypes} from 'prop-types'
import {DragDropContext} from 'react-beautiful-dnd'

import Logger from '../../lib/NewLogger'
import {reorderListByIndex} from '../../lib/plan_data/userData'
import {updateResponseGroupOrder} from '../../lib/responsesHelper'
import {usePrevious} from '../../lib/hooks'


const withResponseGroupDragAndDrop = Component => {
  const ResponseGroupDragAndDrop = props => {
    const responseGroupIds = props.responseGroups.keySeq()

    const [responseGroupsOrder, setResponseGroupOrder] = useState(() => props.itemView.get('group-ids', responseGroupIds.toList()))

    const prevResponseGroupOrder = usePrevious(responseGroupsOrder)

    const [isUpdatingItemView, setIsUpdatingItemView] = useState(false)

    const logEvent = () => {
      const itemName = props.itemResponse.get('name')

      Logger.log({
        name: 'response_reordered',
        payload: {
          actor: props.actor,
          context: props.context,
          everplan_id: props.itemResponse.get('everplan-id'),
          item: itemName,
          wildcard: itemName
        }
      })
    }

    const updateItemView = sortedResponseGroups => {
      setIsUpdatingItemView(true)

      props.updateResource({
        type: 'item-views',
        id: props.itemView.get('id'),
        attributes: {
          'group-ids': sortedResponseGroups
        }
      })
    }

    const createItemView = sortedResponseGroups => {
      setIsUpdatingItemView(true)

      props.createResource({
        type: 'item-views',
        attributes: {
          'everplan-id': props.itemResponse.get('everplan-id'),
          'item-id': props.itemResponse.get('item-id'),
          'group-ids': sortedResponseGroups
        }
      })
    }

    /**
     * Always want to update an item view if one exists, otherwise only create an item view if the order is different from
     * the `created-at` order (responseGroupIds) - this makes sure that item views are not being created when
     * re-ordering has not happened at any stage.
    */
    const createOrUpdateItemView = sortedResponseGroups => {
      if (!props.itemView.isEmpty())
        updateItemView(sortedResponseGroups)
      else if (!sortedResponseGroups.equals(responseGroupIds))
        createItemView(sortedResponseGroups)
    }

    const onDragEnd = useCallback(result => {
      if (result.destination && result.destination.index !== result.source.index) {
        const sortedGroups = reorderListByIndex({
          list: responseGroupsOrder,
          sourceIndex: result.source.index,
          targetIndex: result.destination.index
        })

        setResponseGroupOrder(sortedGroups)
        logEvent()
      }
    }, [responseGroupsOrder])


    /**
     * This `useEffect` only fires if `isUpdatingItemView` or `itemView` has changed and is used to check if the response order is correct.
     * Basically if the `itemView` exists, it is not correct, and is currently not being updated then it sets the `responseGroupOrder` according to the `itemView`.
     *
     * This is needed in the situation that a user is on a slow network and re-orders the response groups and then moves from the category page
     * to the preview page before the API has returned the new or updated `itemView`.
    */
    useEffect(() => {
      if (!isUpdatingItemView && !props.itemView.isEmpty() && !props.itemView.get('group-ids').equals(responseGroupsOrder))
        setResponseGroupOrder(props.itemView.get('group-ids'))
    }, [isUpdatingItemView, props.itemView])

    /**
     * This updates the `itemView` if the `responseGroupsOrder` has changed and if the item-view does not match the `responseGroupsOrder`,
     * and this is only fired when  `responseGroupsOrder` has changed.
     *
     * This is needed, because API calls should not be directly triggered within a render (for example in the situation below where a state update is made outside of a hook), futhermore
     * since these calls are actually an effect of the state changing they should be called within a hook and in this case a `useEffect`, as documented in the react docs (https://reactjs.org/docs/hooks-effect.html)
    */
    useEffect(() => {
      // The only time a user can even create or edit item-views is if they have edit permission, and so this prevents any
      // attempts to the api (which would fail anyway) in the situation that the group-ids in itemView and responseGroupsOrder don't match,
      // which would happen if a user doesn't have access to all the response groups that would in an `item-view`.
      if (props.hasEditPermission && !prevResponseGroupOrder.equals(responseGroupsOrder) && !props.itemView.get('group-ids', List()).equals(responseGroupsOrder))
        createOrUpdateItemView(responseGroupsOrder)
    }, [responseGroupsOrder])

    /**
     * Setting isUpdatingItemView to false in a `useEffect` instead of in a `.then()` after an API call, for the following reasons:
     *    1. If multiple API calls are made only want to set `isUpdatingItemView` to false after all `itemView` api calls have finished
     *       (aka the item-view group ids now equal the responseGroupsOrder)
     *    2. If the component un-mounts from the DOM before the API call returns it would trigger a react warning, for a possible memory leak,
     *       since the `.then()` would have a call to update the state of an unmounted component.
    */
    useEffect(() => {
      if (isUpdatingItemView && props.itemView.get('group-ids', List()).equals(responseGroupsOrder))
        setIsUpdatingItemView(false)
    }, [isUpdatingItemView])


    /**
     * This updates the `responseGroupsOrder` if the `responseGroupsOrder` does not have the correct response groups.
     *
     * Can't use `useEffect` to check `responseGroups`, because `useEffect` fires after the dom has rendered/re-rendered and at that point the responseGroup could be different,
     * and this could causes the page to crash or not display a response group.
     *
     * For example if a user adds or deletes a `responseGroup`, and this also safe guards against any mismatch between item view and response groups, in case
     * of any network or timeout issues.
    */
    if (!responseGroupsOrder.toSet().equals(responseGroupIds.toSet())) {
      const updatedResponseGroups = updateResponseGroupOrder({responseGroupsOrder, updatedResponseGroups: props.responseGroups})

      setResponseGroupOrder(updatedResponseGroups)
    }

    return (
      <DragDropContext onDragEnd={onDragEnd} >
        <Component {...props} responseGroupsOrder={responseGroupsOrder} />
      </DragDropContext>
    )
  }

  ResponseGroupDragAndDrop.propTypes = {
    actor: PropTypes.string,
    context: PropTypes.string,
    createResource: PropTypes.func,
    hasEditPermission: PropTypes.bool,
    itemResponse: PropTypes.instanceOf(Map),
    itemView: PropTypes.instanceOf(Map),
    responseGroups: PropTypes.instanceOf(Map),
    updateResource: PropTypes.func
  }

  return ResponseGroupDragAndDrop
}

export default withResponseGroupDragAndDrop
