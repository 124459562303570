import {findRawResourceByAttributeId} from './plan_data/userData'

/**
 * Gets the freemiumResponseGroupThreshold value from settings.
 *
 * @param {Array} settings
 *
 * @returns {Integer}
 */
export const freemiumResponseGroupThreshold = ({settings}) => {
  const record = findRawResourceByAttributeId({
    rawResource: settings,
    id: 'freemium_response_group_threshold',
    attribute: 'name'
  })

  return parseInt(record.get('value', 0), 10)
}

/**
 * Checks if the responseGroups are more than the allowed freemiumResponseGroupThreshold.
 *
 * @param {List} responseGroupCount
 * @param {Map} userConfig
 * @param {Object} settings. Allowed number of response groups for freemium users.
 * @param {String} action. Allowed number of response groups for freemium users.
 *
 * @returns {Bool}
 */
export const hidePremiumFeatures = ({responseGroupCount, userConfig, settings, action = 'add_item'}) => {
  if (userConfig.get('premium')) return false

  const threshold = freemiumResponseGroupThreshold({settings})
  return (action === 'add_item' ? responseGroupCount >= threshold : responseGroupCount > threshold)
}
