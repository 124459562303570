import AppDispatcher from '../../../lib/ep-dispatcher'
import {EventEmitter} from 'events'
import assign from 'object-assign'
import TfaApiResponse from '../web/TfaApiResponse'
import TfaWizardStore from './TfaWizardStore'
import WizardActions from '../actions/WizardActions'
import SettingsActions from '../actions/SettingsActions'

const state = {
  tfaConfig: null,
  error: null,
  pendingTfaPhoneNumber: null,
  backupCode: null
}

const TfaConfigStore = assign({}, EventEmitter.prototype, {
  getState() { return state },
  initialized() { return !!state.tfaConfig },
  setError(error) {
    state.error = error
    this.emitChange()
  },
  clearError() {
    state.error = null
    this.emitChange()
  },
  getTfaConfig() { return state.tfaConfig },
  updateTfaConfig(tfaConfig) {
    state.tfaConfig = tfaConfig
    this.emitChange()
  },
  phoneEditAccepted(tfaPhoneNumber) {
    if (tfaPhoneNumber.primary)
      state.pendingTfaPhoneNumber = tfaPhoneNumber
    else
      state.tfaConfig.secondary_number = tfaPhoneNumber

    this.emitChange()
  },
  phoneEditConfirmed(tfaPhoneNumber) {
    state.pendingTfaPhoneNumber = null
    if (tfaPhoneNumber.primary)
      state.tfaConfig.primary_number = tfaPhoneNumber
    else
      state.tfaConfig.secondary_number = tfaPhoneNumber

    this.emitChange()
  },
  updateBackupCode(backupCode) {
    state.backupCode = backupCode
    this.emitChange()
  },
  addChangeListener(callback) { this.on('CHANGE', callback) },
  removeChangeListener(callback) { this.removeListener('CHANGE', callback) },
  emitChange() { this.emit('CHANGE') }
})

export default TfaConfigStore


TfaConfigStore.dispatchToken = AppDispatcher.register(action => {
  switch (action.actionType) {
    case TfaApiResponse.Types.WEB_TFA_DISABLED:
    case TfaApiResponse.Types.WEB_TFA_ENABLED:
      state.backupCode = null
      TfaConfigStore.updateTfaConfig(action.tfaConfig)
      break
    case TfaApiResponse.Types.WEB_TFA_CONFIG_FETCHED:
      TfaConfigStore.updateTfaConfig(action.tfaConfig)
      break
    case TfaApiResponse.Types.WEB_TFA_PHONE_CONFIRMED:
    case TfaApiResponse.Types.WEB_PHONE_EDIT_CONFIRMED:
      AppDispatcher.waitFor([TfaWizardStore.dispatchToken])
      TfaConfigStore.phoneEditConfirmed(action.tfaPhoneNumber)
      break
    case TfaApiResponse.Types.WEB_TFA_BACKUP_CODE_GENERATED:
      TfaConfigStore.updateBackupCode(action.backupCode)
      break
    case TfaApiResponse.Types.WEB_PHONE_EDIT_REJECTED:
    case TfaApiResponse.Types.WEB_TFA_CONFIRMATION_CODE_REJECTED:
      TfaConfigStore.setError(action.error)
      break
    case WizardActions.Types.TFA_CLEAR_ERRORS:
      TfaConfigStore.clearError()
      break
    case TfaApiResponse.Types.WEB_PRIMARY_PHONE_ACCEPTED:
    case TfaApiResponse.Types.WEB_PHONE_EDIT_ACCEPTED:
      TfaConfigStore.phoneEditAccepted(action.tfaPhoneNumber)
      break
    case SettingsActions.Types.ABANDON_PHONE_EDIT:
      state.pendingTfaPhoneNumber = null
      TfaConfigStore.emitChange()
      break
    case SettingsActions.Types.CLEAR_BACKUP_CODE:
      state.backupCode = null
      TfaConfigStore.emitChange()
      break
    case SettingsActions.Types.DELETE_SECONDARY_NUMBER:
      state.tfaConfig.secondary_number = null
      TfaConfigStore.emitChange()
      break
    default:
      break
  }
})
