import {Map, List} from 'immutable'

import {camelizeImmutableData} from './graphQLTools'
import {allResponseGroupCount} from './plan_data/itemResponsesHelper'
import CALL_TO_ACTION_CONFIGURATIONS from '../shared_components/next_best_actions/gap_fillers/configurations/gapFillerCallToActionConfigurations'

import hasWillNextUpImage from '../dashboard/assets/programs/has-will-next-up-image.svg'
import noWillNextUpImage from '../dashboard/assets/programs/no-will-next-up-image.svg'
import sharingImage from '../dashboard/assets/programs/sharing-image.svg'
import sharingMilestoneIcon from '../dashboard/assets/programs/sharing-milestone-icon.svg'
import vitalInfoImage1 from '../dashboard/assets/programs/vital-info-image-1.svg'
import vitalInfoImage2 from '../dashboard/assets/programs/vital-info-image-2.svg'
import vitalInfoMilestoneIcon from '../dashboard/assets/programs/vital-info-milestone-icon.svg'
import willsImage1 from '../dashboard/assets/programs/wills-image-1.svg'
import willsImage2 from '../dashboard/assets/programs/wills-image-2.svg'
import willsImage3 from '../dashboard/assets/programs/wills-image-3.svg'
import willsImage4 from '../dashboard/assets/programs/wills-image-4.svg'
import willsImage5 from '../dashboard/assets/programs/wills-image-5.svg'
import willsImage6 from '../dashboard/assets/programs/wills-image-6.svg'
import willsImage7 from '../dashboard/assets/programs/wills-image-7.svg'
import willsImage8 from '../dashboard/assets/programs/wills-image-8.svg'
import willsImage9 from '../dashboard/assets/programs/wills-image-9.svg'
import willsMilestoneIcon from '../dashboard/assets/programs/wills-milestone-icon.svg'

const WILL_OPTIONS = {
  will: 'will',
  wills_101: 'wills_101',
  will_alt: 'will_alt'
}

const findWillSectionByType = sections => (
  sections?.find(section => Object.values(WILL_OPTIONS).includes(section.name || section.get('name'))) || Map()
)

export const showNewbieProgram = ({userConfig, itemResponses, userProgram}) => {
  if (userConfig.get('score-enabled')) {
    return userProgram ?
      userProgram.get('status') !== 'completed' :
      allResponseGroupCount(itemResponses) < 10
  }

  return false
}

export const findSectionByName = ({data, name}) => (
  data?.get('sections').find(section => section.get('name') === name) || Map()
)

export const maxPointsSectionConfig = ({userSection, program, name}) => {
  const section = findSectionByName({data: program, name})
  const pointsEarned = userSection.get('status') === 'completed' ? section.getIn(['milestone-config', 'max-points']) : 0

  return {
    name,
    section,
    userSection,
    pointsEarned,
    extraAttributesOnStart: {}
  }
}

export const vitalInfoConfig = ({userProgram, program}) => {
  const name = 'vital_info'
  const userSection = findSectionByName({data: userProgram, name})
  const pointsEarned = userSection.get('status') === 'completed' ? userSection.get('view-slugs-added').size * 8 : 0

  return {
    name,
    section: findSectionByName({data: program, name}),
    userSection,
    pointsEarned,
    extraAttributesOnStart: {
      viewSlugsAdded: [],
      viewSlugsSkipped: []
    }
  }
}

const hasWillConfig = ({userSection, program, name}) => ({
  ...maxPointsSectionConfig({userSection, name, program}),
  extraAttributesOnStart: {
    viewSlugsAdded: [],
    viewSlugsSkipped: []
  }
})

const willConfigName = ({userSection, hasWill, addedWill}) => {
  const userSectionName = userSection.get('name')

  if (userSectionName)
    return userSectionName

  if (addedWill)
    return WILL_OPTIONS.will_alt

  return hasWill ? WILL_OPTIONS.will : WILL_OPTIONS.wills_101
}

export const willConfig = ({userProgram, program, hasWill, addedWill}) => {
  const userSection = findWillSectionByType(userProgram?.get('sections'))
  const name = willConfigName({userSection, hasWill, addedWill})
  const sharedArgs = {userProgram, program, userSection, name}

  return name === WILL_OPTIONS.will ? hasWillConfig(sharedArgs) : maxPointsSectionConfig(sharedArgs)
}

export const sharingConfig = ({userProgram, program}) => {
  const name = 'sharing'

  return maxPointsSectionConfig({userProgram, program, name, userSection: findSectionByName({data: userProgram, name})})
}

export const newbieProgramConfig = ({userProgram, program, hasWill, addedWill}) => {
  if (program.isEmpty())
    return

  const sharedArgs = {userProgram, program}

  return [
    vitalInfoConfig(sharedArgs),
    willConfig({...sharedArgs, hasWill, addedWill}),
    sharingConfig(sharedArgs)
  ]
}

export const newbieProgramNextUpScreen = ({programConfig, partner, program}) => {
  if (!partner.get('enabled-gap-fillers').includes('will') || !programConfig) return

  const upNextSectionScreens = program
    .get('sections')
    .find(section => section.get('section-type') === 'partner-handoff')
    .get('screens')

  const screenType = findWillSectionByType(programConfig).name === WILL_OPTIONS.wills_101 ? 'no_will' : 'has_will'
  const screen = upNextSectionScreens.find(screenData => screenData.get('screen-type') === screenType)

  return screen.merge({
    'call-to-action-url': CALL_TO_ACTION_CONFIGURATIONS.getIn(['will-details-about-your-will', 'intercept', 'callToActionURL']),
    'item-header': 'Will'
  })
}

export const maxPossibleProgramPoints = programConfig => programConfig.reduce((total, config) => total + config.section.getIn(['milestone-config', 'max-points']), 0)

export const allUserSectionsCompleted = programConfig => {
  const completedSections = programConfig.filter(config => ['completed', 'skipped'].includes(config.userSection.get('status'))).length

  return completedSections === programConfig.length
}

export const getActiveSectionName = programConfig => {
  const activeConfig = programConfig.find(config => config.userSection.get('status') === 'active')

  if (activeConfig)
    return activeConfig.name

  // If all sections are completed and the program itself is not (aka the program can still be clicked on) then it must mean there is an up-next section
  return allUserSectionsCompleted(programConfig) ? 'up-next' : 'milestone'
}

export const milestoneStatusText = sectionConfig => {
  switch (sectionConfig.userSection.get('status')) {
    case 'completed':
      return `${sectionConfig.pointsEarned} points earned`
    case 'skipped':
      return 'Skipped'
    default:
      return `Earn ${sectionConfig.section.getIn(['milestone-config', 'max-points'])} points`
  }
}

export const getCompletedScreensCount = (currentScreen, screens) => {
  const currentIndex = screens.findIndex(
    screen => screen.get('sort-order') === currentScreen.get('sort-order')
  )

  const endIndex = currentIndex + 1

  return screens.slice(0, endIndex).reduce((count, screen, index) => {
    if (screen.get('screen-type') === 'content' || screen.get('item-options').size === 1)
      return count + 1

    return count + (index + 1 === endIndex ? 1 : 2)
  }, 0)
}

export const getTotalScreensCount = screens => (
  screens.reduce((count, screen) =>
    count + (
      screen.get('screen-type') === 'content' || screen.get('item-options').size === 1 ? 1 : 2
    ), 0
  )
)

export const updateUserSectionsWithNextActiveSection = ({programConfig, userProgram}) => {
  const nextSection = programConfig[userProgram.get('sections').size]

  // Just adding a precaution so that there are no two active sections at the same time
  if (nextSection && !userProgram.get('sections').some(section => section.get('status') === 'active')) {
    return userProgram.get('sections').push({
      name: nextSection.name,
      status: 'active',
      ...nextSection.extraAttributesOnStart
    })
  }
}

export const getCurrentSectionScreen = ({screens, userSection}) => {
  const itemOptionScreens = screens.filter(screen => screen.get('has-item-options'))
  const firstScreen = screens.find(screen => screen.get('sort-order') === 1)

  if (itemOptionScreens.isEmpty())
    return firstScreen

  const optionsAdded = userSection.get('view-slugs-added', List())
    .concat(userSection.get('view-slugs-skipped', List())).size

  return optionsAdded === 0 ? firstScreen : itemOptionScreens.get(optionsAdded)
}

export const mergeUpdatedSectionIntoUserSections = ({userProgram, updatedSection}) => (
  userProgram.get('sections').map(section => (
    section.get('name') === updatedSection.get('name') ?
      updatedSection :
      section
  ))
)

const updateUserSectionViewSlugs = ({userSection, viewSlugAdded, viewSlugSkipped}) => {
  if (viewSlugAdded || viewSlugSkipped)
    return viewSlugAdded ? userSection.mergeIn(['view-slugs-added'], viewSlugAdded) : userSection.mergeIn(['view-slugs-skipped'], viewSlugSkipped)
  else
    return userSection
}

const updateUserSectionStatus = ({userSection, nextScreenNumber}) => {
  if (nextScreenNumber === 0) {
    const addedViewedSlugs = userSection.get('view-slugs-added')
    const status = addedViewedSlugs && addedViewedSlugs.isEmpty() ? 'skipped' : 'completed'

    return userSection.set('status', status)
  } else {
    return userSection
  }
}

export const updateUserSection = ({viewSlugAdded, viewSlugSkipped, nextScreenNumber, userSection}) => {
  const sectionData = updateUserSectionViewSlugs({viewSlugAdded, viewSlugSkipped, userSection})

  return updateUserSectionStatus({userSection: sectionData, nextScreenNumber})
}

export const buildUserProgramMutationVariables = ({sections, userProgram, status = null}) => ({
  input: {
    id: userProgram.get('id'),
    status: status || userProgram.get('status'),
    sections: camelizeImmutableData(sections)
  }
})

const milestoneScreenType = programConfig => {
  const userSections = programConfig.reduce((total, config) => (config.userSection.isEmpty() ? total : total + 1), 0)
  const programLength = programConfig.length

  if (userSections === 0) return 'intro'
  if (userSections === programLength) return 'done'
  if (userSections === programLength - 1) return 'final-keep-going'

  return 'keep-going'
}

export const getCurrentMilestoneScreen = ({programConfig, program}) => {
  const screenType = milestoneScreenType(programConfig)

  return program.get('milestone-screens').find(screen => screen.get('milestone-screen-type') === screenType)
}

export const programImages = {
  archive: vitalInfoImage2,
  checklist: vitalInfoMilestoneIcon,
  community: sharingMilestoneIcon,
  consultation: willsImage8,
  immediate_family: willsImage3,
  itemize_possessions: willsImage1,
  meeting: willsImage6,
  notepad: willsMilestoneIcon,
  notes: willsImage5,
  no_will_next_up: noWillNextUpImage,
  office_setup: hasWillNextUpImage,
  organize_documents: willsImage4,
  organize_ideas: vitalInfoImage1,
  organize_possessions: willsImage2,
  possessions: willsImage7,
  secure_plans: willsImage9,
  sharing: sharingImage
}

export const userItemOptions = ({screen, userSection}) => {
  const viewSlugsInUserData = userSection.get('view-slugs-added').concat(userSection.get('view-slugs-skipped'))

  return screen.get('item-options')
    .filterNot(itemOption => viewSlugsInUserData.includes(itemOption.get('view-slug')))
    .map(itemOption => Map({id: itemOption.get('id'), label: itemOption.get('label'), value: itemOption.get('id')})).toJS()
}

export const getItemFormScreen = screen => {
  const itemOptions = screen.get('item-options')
  if (itemOptions.isEmpty() || itemOptions.size > 1)
    return

  return itemOptions.first()
}
