import {Component} from 'react'
import {connect} from 'react-redux'
import {Map} from 'immutable'
import PropTypes from 'prop-types'
import {deleteResource, readEndpoint} from 'redux-json-api'

import {cleanResponse} from '../redux/apiHelper'

import EverplanDownloadStatus from './EverplanDownloadStatus'
import {downloadWithAnchorTag} from '../../../../../lib/fileDownload'

const POLLING_TIME_INTERVAL = 20000


class EverplanDownloadStatusController extends Component {
  constructor() {
    super()

    this.state = {jobId: null}
  }

  componentDidUpdate(prevProps) {
    const {everplanBundleDownload} = this.props

    if (
      !everplanBundleDownload.isEmpty() &&
      !everplanBundleDownload.equals(prevProps.everplanBundleDownload)
    ) {
      everplanBundleDownload.get('status') === 'ready' ?
        this.clearDownloadPolling() :
        this.startDownloadPolling(everplanBundleDownload.get('id'))
    }
  }

  clearDownloadPolling() {
    const bundle_url = this.props.everplanBundleDownload.get('download-url')

    if (this.state.jobId) {
      clearInterval(this.state.jobId)
      this.setState({jobId: null})
    }

    if (bundle_url) {
      downloadWithAnchorTag({fileUrl: bundle_url, openOnNewTab: false})
      const payload = {
        type: 'everplan-bundle-downloads',
        id: this.props.everplanBundleDownload.get('id')
      }

      setTimeout(() => this.props.deleteResource(payload), 5000)
    }
  }

  startDownloadPolling(everplanBundleDownloadId) {
    // clear any existing download polling before starting a new one.
    this.clearDownloadPolling()
    this.setState({
      jobId: setInterval(
        () => this.props.readEndpoint(`everplan-bundle-downloads/${everplanBundleDownloadId}`),
        POLLING_TIME_INTERVAL
      )
    })
  }

  componentWillUnmount() { this.clearDownloadPolling() }

  render() {
    return (
      <EverplanDownloadStatus
        everplanBundleDownload={this.props.everplanBundleDownload}
      />
    )
  }
}


EverplanDownloadStatusController.propTypes = {
  deleteResource: PropTypes.func,
  everplanBundleDownload: PropTypes.instanceOf(Map),
  readEndpoint: PropTypes.func
}

const mapStateToProps = ({api}) => ({
  everplanBundleDownload: cleanResponse(api['everplan-bundle-downloads']).last() || Map()
})

export default connect(mapStateToProps, {deleteResource, readEndpoint})(EverplanDownloadStatusController)
