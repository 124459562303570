import PropTypes from 'prop-types'
import React from 'react'

import AccountInformation from '../../../consumer/src/components/accountInformation/AccountInformation'
import AccountSettingsActions from '../../../consumer/src/actions/AccountSettingsActions'
import bindResources from '../../../shared_components/BindToStores'
import Container from '../../../lib/Container'
import Loader from '../../../shared_components/NewLoader'
import PageTitle from '../../../shared_components/PageTitle'
import StorePrototype from '../../../shared_components/StorePrototype'

import './accountController.scss'

export class AccountController extends React.Component {
  render() {
    return (
      <div className='account-controller'>
        <PageTitle title='Account Information' />
        <Loader loading={this.props.loadingAccountInformation}>
          <AccountInformation
            legacyAccountSettings={this.props.legacyAccountSettings}
            location={this.props.location}
            params={this.props.params}
            {...this.props.account_information.data}
          />
        </Loader>
      </div>
    )
  }
}

AccountController.defaultProps = {
  loadingAccountInformation: false,
  account_information: {
    data: {
      emails: {},
      profile: {
        dob: '',
        name: '',
        location: '',
        gender: ''
      }
    },
    errors: {},
    message: null
  }
}

AccountController.propTypes = {
  loadingAccountInformation: PropTypes.bool,
  account_information: PropTypes.shape({
    data: PropTypes.object
  })
}

Container.registerAction('account_information', AccountSettingsActions)
Container.registerStore('account_settings', StorePrototype(AccountSettingsActions.Types.GOT_ACCOUNT_INFORMATION))

export default bindResources(AccountController, {id: 'userId', name: 'account_information', type: 'item'})
