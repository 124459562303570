import PropTypes from 'prop-types'

import {useMemo} from 'react'
import {connect} from 'react-redux'
import {Map} from 'immutable'

import Carousel from '../../../../../shared_components/Carousel'
import Loader from '../../../../../shared_components/NewLoader'
import ResourceBox from './ResourceBox'

import {resourcesByProfile} from '../../../lib/helpfulResourcesHelper'
import {cleanResponse} from '../redux/apiHelper'
import {findResourceByAttributeId} from '../../../../../lib/plan_data/userData'

import './helpfulResources.scss'

export const HelpfulResources = props => {
  const resources = useMemo(() => resourcesByProfile(props.profile.get('state')), [props.profile.get('state')])

  const resourcesArray = resources.map(
    resource => (
      <ResourceBox
        resource={resource}
        key={`${resource.heading}-${resource.url}`}
      />
    )
  )

  return (
    <div className='helpful-resources'>
      <Loader loading={props.profile.isEmpty()}>
        <Carousel location={props.location} slides={resourcesArray} title='Articles' />
      </Loader>
    </div>
  )
}

HelpfulResources.propTypes = {
  location: PropTypes.object,
  profile: PropTypes.instanceOf(Map)
}

const mapStateToProps = ({api}, {userConfig}) => ({
  profile: findResourceByAttributeId({resourceList: cleanResponse(api.profiles), attribute: 'user-id', id: userConfig.get('id')})
})

export default connect(mapStateToProps)(HelpfulResources)
