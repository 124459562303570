import {useEffect} from 'react'

import {Map} from 'immutable'
import PropTypes from 'prop-types'

import {Accept} from '../../../../../dashboard/src/components/blocks/Buttons'
import {TextGutterMedium} from '../../../../../dashboard/src/components/blocks/Texts'
import FuneralExpenseHeader from './FuneralExpenseHeader'
import Logger from '../../../../../lib/NewLogger'

import productRecommendation from '../../../../../shared_assets/product_recommendation@2x.png'

const FuneralExpenseRecommendationPreNeed = props => {
  useEffect(() => {
    Logger.log({
      name: 'viewed_gap_filler_product_recommendation',
      payload: {
        flavor: 'funeral',
        wildcard: 'pre-need',
        product_type: 'pre-need'
      }
    })

    if (props.productRecommendationUserSetting.isEmpty())
      props.productRecommendationSubscription({subName: 'pre_need_reminder'})
  }, [])

  return (
    <div className='funeral-expense-recommendation-pre-need'>
      <FuneralExpenseHeader />
      <div className='top-container flex-container'>
        <div className='left-container flex-child'>
          <TextGutterMedium className='bold-text'>
            Our recommendation for you
          </TextGutterMedium>
          <TextGutterMedium className='large-text'>
            You might be interested in <strong>Pre-need Insurance</strong>
          </TextGutterMedium>
          <TextGutterMedium className='small-text'>
            You will work with a funeral director to price out your send-off and
            create a policy to cover those specific expenses. No more, no less.
          </TextGutterMedium>
          <TextGutterMedium className='small-text'>
            With this type of insurance, your family doesn't have to make any
            decisions, you can guarantee the money gets spent on the funeral you
            want, and the prices are often guaranteed—so if your casket is
            $2,000 today, and it's $3,000 by the time you die, your family won't
            have to pay the difference.
          </TextGutterMedium>
        </div>
        <img
          className='product-recommendation-image'
          src={productRecommendation}
          alt='product recommendation image'
        />
      </div>
      <div className='btn-group'>
        <Accept onClick={props.toggleGapFillerAssessmentModal}>
          Done
        </Accept>
      </div>
    </div>
  )
}

FuneralExpenseRecommendationPreNeed.propTypes = {
  productRecommendationSubscription: PropTypes.func,
  productRecommendationUserSetting: PropTypes.instanceOf(Map),
  toggleGapFillerAssessmentModal: PropTypes.func
}

export default FuneralExpenseRecommendationPreNeed
