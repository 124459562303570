import classnames from 'classnames'
import PropTypes from 'prop-types'

import downloadIcon from '../../../assets/download-icon.svg'

import './downloadLink.scss'

const DownloadLink = ({className, onClick}) => (
  <a className={classnames('download-link', className)} onClick={onClick}>
    <div className='action-link-icon-wrapper'>
      <img alt='download' className='action-link-icon' src={downloadIcon} />
    </div>
    <span> Preview / Download</span>
  </a>
)

DownloadLink.propTypes = {
  onClick: PropTypes.func
}

export default DownloadLink
