import AppDispatcher from '../../../lib/ep-dispatcher'
import TfaApiResponse from './TfaApiResponse'
import SessionApiResponse from './SessionApiResponse'
import TfaConfigStore from '../stores/TfaConfigStore'
import WizardActions from '../actions/WizardActions'
import SettingsActions from '../actions/SettingsActions'
import jsonStatham from '../../../lib/jsonStatham'

const TfaApi = {
  fetchTfaConfig(id) {
    if (!id)
      return

    jsonStatham.get(`tfa_configs/${id}`)
      .fail(errorJSON => { TfaApiResponse.tfaConfigFetchFailed() })
      .done((data, status) => { TfaApiResponse.tfaConfigFetched(data) })
  },

  addPrimaryPhone(number, nationalNumber, preferredMessageMode) {
    var tfaConfig = TfaConfigStore.getTfaConfig()
    jsonStatham.post('tfa_phone_numbers', {
      tfa_phone_number: {
        tfa_config_id: tfaConfig.id,
        number,
        national_number: nationalNumber,
        primary: true,
        preferred_message_mode: preferredMessageMode
      }
    }).fail(errorJSON => { TfaApiResponse.phoneEditRejected(errorJSON.errors.number) })
      .done((data, status) => { TfaApiResponse.primaryPhoneAccepted(data) })
  },

  confirmPhone(tfa_phone_number_id, code) {
    jsonStatham.put(`tfa_phone_numbers/${tfa_phone_number_id}`, {
      tfa_phone_number: {
        confirm_code: code
      }
    }).fail(errorJSON => { TfaApiResponse.tfaConfirmationCodeRejected(errorJSON.errors.confirm_code) })
      .done((data, status) => { TfaApiResponse.tfaPhoneConfirmed(data) })
  },

  confirmPhoneEdit(tfa_phone_number_id, code) {
    jsonStatham.put(`tfa_phone_numbers/${tfa_phone_number_id}`, {
      tfa_phone_number: {
        confirm_code: code
      }
    }).fail(errorJSON => { TfaApiResponse.tfaConfirmationCodeRejected(errorJSON.errors.confirm_code) })
      .done((data, status) => { TfaApiResponse.tfaPhoneEditConfirmed(data) })
  },

  addSecondaryPhone(number, nationalNumber, preferredMessageMode) {
    var tfaConfig = TfaConfigStore.getTfaConfig()
    jsonStatham.post('tfa_phone_numbers', {
      tfa_phone_number: {
        number,
        tfa_config_id: tfaConfig.id,
        national_number: nationalNumber,
        preferred_message_mode: preferredMessageMode
      }
    }).fail(errorJSON => { TfaApiResponse.phoneEditRejected(errorJSON.errors.number) })
      .done((data, status) => { this.getBackupCode() })
  },

  getBackupCode() {
    var tfaConfig = TfaConfigStore.getTfaConfig()
    jsonStatham.put(`tfa_configs/${tfaConfig.id}/backup_code`)
      .fail(errorJSON => { alert(errorJSON.error) })
      .done((data, status) => { TfaApiResponse.tfaBackupCodeGenerated(data.backup_code) })
  },

  enableTfa() {
    var tfaConfig = TfaConfigStore.getTfaConfig()
    jsonStatham.put(`tfa_configs/${tfaConfig.id}`, {
      tfa_config: {
        enabled: true
      }
    }).fail(errorJSON => { alert(errorJSON.error) })
      .done((data, status) => { TfaApiResponse.tfaEnabled(data) })
  },

  disableTfa() {
    var tfaConfig = TfaConfigStore.getTfaConfig()
    jsonStatham.put(`tfa_configs/${tfaConfig.id}`, {
      tfa_config: {
        enabled: false
      }
    }).fail(errorJSON => { alert(errorJSON.error) })
      .done((data, status) => { TfaApiResponse.tfaDisabled(data) })
  },

  editPhone(number, nationalNumber, preferredMessageMode, primary) {
    jsonStatham.post('tfa_phone_numbers', {
      tfa_phone_number: {
        number,
        national_number: nationalNumber,
        primary,
        preferred_message_mode: preferredMessageMode
      }
    }).fail(errorJSON => { TfaApiResponse.phoneEditRejected(errorJSON.errors.number) })
      .done((data, status) => { TfaApiResponse.phoneEditAccepted(data) })
  },
  deleteSecondaryNumber(id) {
    jsonStatham.delete(`tfa_phone_numbers/${id}`)
      .always((data, status) => {
      })
  }
}

export default TfaApi

AppDispatcher.register(action => {
  switch (action.actionType) {
    case SessionApiResponse.Types.WEB_USER_SIGNED_IN:
      TfaApi.fetchTfaConfig(action.user.tfa_config_id)
      break
    case SessionApiResponse.Types.WEB_CURRENT_USER_RECEIVED:
      TfaApi.fetchTfaConfig(action.data.tfa_config_id)
      break
    case WizardActions.Types.TFA_ADD_PRIMARY_PHONE:
      TfaApi.addPrimaryPhone(action.number, action.nationalNumber, action.preferredMessageMode)
      break
    case WizardActions.Types.TFA_CONFIRM_PHONE:
      TfaApi.confirmPhone(action.tfa_phone_number_id, action.code)
      break
    case SettingsActions.Types.CONFIRM_CODE_EDIT:
      TfaApi.confirmPhoneEdit(action.tfa_phone_number_id, action.code)
      break
    case SettingsActions.Types.GET_NEW_BACKUP_CODE:
    case WizardActions.Types.GET_BACKUP_CODE:
      TfaApi.getBackupCode()
      break
    case WizardActions.Types.TFA_ADD_SECONDARY_PHONE:
      TfaApi.addSecondaryPhone(action.number, action.nationalNumber, action.preferredMessageMode)
      break
    case WizardActions.Types.TFA_ENABLE:
      TfaApi.enableTfa()
      break
    case SettingsActions.Types.TFA_DISABLE:
      TfaApi.disableTfa()
      break
    case SettingsActions.Types.SUBMIT_PHONE_EDIT:
      TfaApi.editPhone(action.number, action.nationalNumber, action.preferredMessageMode, action.primary)
      break
    case SettingsActions.Types.DELETE_SECONDARY_NUMBER:
      TfaApi.deleteSecondaryNumber(action.id)
      break
    default:
      break
  }
})
