import PropTypes from 'prop-types'


const EditIcon = ({fill, height, width, ...otherProps}) => (
  <svg {...otherProps} height={height} width={width} version='1.1' viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'>
    <g fill='none' fillRule='evenodd' stroke='none' strokeWidth='1'>
      <path fill={fill} d='M27.2084528,4.71717391 L9.19737696,22.7276087 L7.29009553,20.8203261 L25.2838888,2.79391304 C25.4443234,2.6325 25.6314972,2.60869565 25.7286711,2.60869565 C25.826171,2.60869565 26.0133448,2.6325 26.1741056,2.79358696 L27.2084528,3.8276087 C27.45367,4.07282609 27.45367,4.47228261 27.2084528,4.71717391 L27.2084528,4.71717391 Z M4.81672749,23.3693478 L6.64933506,25.2019565 L3.62161954,26.3879348 L4.81672749,23.3693478 Z M29.0534517,1.98326087 L28.0187784,0.948913043 C27.4073657,0.337173913 26.5944314,0 25.7289972,0 L25.7286711,0 C24.8629108,0.000326086957 24.0493243,0.337826087 23.4375856,0.950543478 L2.82662003,21.5990217 C2.70107663,21.7248913 2.60259843,21.8748913 2.53738108,22.0402174 L0.091730412,28.2156522 C-0.0996825132,28.6982609 0.0141217643,29.2483696 0.38031719,29.6161957 C0.629773558,29.8666304 0.964012482,30 1.30477314,30 C1.46455565,30 1.62564251,29.9706522 1.78020763,29.9103261 L7.9752038,27.4845652 C8.1428124,27.4190217 8.29476882,27.3195652 8.42194266,27.1923913 L29.0531256,6.56184783 C30.3154074,5.30021739 30.3157335,3.24619565 29.0534517,1.98326087 L29.0534517,1.98326087 Z' />
    </g>
  </svg>
)

EditIcon.defaultProps = {
  fill: '#00000',
  height: '18',
  width: '18'
}

EditIcon.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string
}

export default EditIcon
