import classnames from 'classnames'
import PropTypes from 'prop-types'

import {Map} from 'immutable'

import {Badge} from '@mui/material'

import UserAvatar from './UserAvatar'

const DeputyForAvatar = ({deputizer, onClick}) => {
  const everplanOwners = deputizer.getIn(['everplan-owner', 'owners'])

  const sharedProps = {
    className: 'deputizer-avatar',
    isPending: deputizer.get('ownable-type') === 'Invite',
    onClick,
    user: everplanOwners.first()
  }

  return (
    deputizer.getIn(['everplan-owner', 'is-household']) ?
      <Badge
        className='badge'
        overlap='circular'
        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
        badgeContent={
          <UserAvatar
            {...sharedProps}
            className={`${sharedProps.className} badge`}
          />
        }>
        <UserAvatar {...sharedProps} user={everplanOwners.last()} />
      </Badge> :
      <UserAvatar {...sharedProps} />
  )
}

DeputyForAvatar.propTypes = {
  deputizer: PropTypes.instanceOf(Map),
  onClick: PropTypes.func
}

export default DeputyForAvatar
