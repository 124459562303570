import PropTypes from 'prop-types'

import {PureComponent} from 'react'
import {Map} from 'immutable'

import ModuleBox from '../../../../../shared_components/component_templates/ModuleBox'
import Logger from '../../../../../lib/NewLogger'
import routerUtils from '../../../../../lib/routerUtils'
import CategoryResponseGroupsAdded from './CategoryResponseGroupsAdded'
import CategoryHeaderIcon from '../shared/CategoryHeaderIcon'
import {categoryPath} from '../../../../../lib/urlTools'

import './categoryBox.scss'

export default class CategoryBox extends PureComponent {
  constructor() {
    super()
    this.logAndGoToCategory = this.logAndGoToCategory.bind(this)
  }

  logAndGoToCategory() {
    const category = this.props.category
    Logger.log({
      name: 'clicked_category',
      payload: {
        context: 'dashboard_grid',
        category: category.get('name'),
        wildcard: category.get('name')
      }
    })
    routerUtils.push(categoryPath(category))
  }

  render() {
    const categoryName = this.props.category.get('name')

    return (
      <ModuleBox className='category-box' onClick={this.logAndGoToCategory}>
        <div className='category-inner-box'>
          <div className='category-icon-and-info-container'>
            <CategoryHeaderIcon categoryName={categoryName} />
            <div className='category-info'>
              <h3>{categoryName.replace(/and/g, '&')}</h3>
            </div>
          </div>
          <CategoryResponseGroupsAdded responseGroupSize={this.props.categoryResponseGroupsSize} />
        </div>
      </ModuleBox>
    )
  }
}

CategoryBox.propTypes = {
  category: PropTypes.instanceOf(Map),
  categoryResponseGroupsSize: PropTypes.number
}
