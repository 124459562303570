import {Component} from 'react'
import PropTypes from 'prop-types'

import UnsupportedBrowserBanner from './UnsupportedBrowserBanner'

import Logger from '../../../../lib/NewLogger'
import {displayBanner} from '../../../../lib/bannerTools'

import './feedbackBanner.scss'
import MobileAppDownloadBanner from './MobileAppDownloadBanner'
import GlobalAnnouncementBanner from './GlobalAnnouncementBanner'
import {GlobalAnnouncementContext} from '../../../../shared_components/ApplicationContainer'


class FeedbackBanner extends Component {
  constructor() {
    super()

    this.bannerFlavour = this.bannerFlavour.bind(this)
    this.logBrowserClicked = this.logBrowserClicked.bind(this)
  }

  logBrowserClicked(browserName) {
    Logger.log({
      name: 'clicked_supported_browser',
      payload: {
        browser_name: browserName
      }
    })
  }

  bannerFlavour() {
    const value = displayBanner({
      currentUser: this.props.currentUser,
      globalAnnouncementText: this.context.globalAnnouncementText
    })

    switch (value) {
      case 'show-feedback-banner unsupported-browser':
        return <UnsupportedBrowserBanner onClick={this.logBrowserClicked} />
      case 'show-feedback-banner mobile-app-download':
        return <MobileAppDownloadBanner {...this.props} />
      case 'show-feedback-banner global-announcement':
        return <GlobalAnnouncementBanner className='feedback-banner' />
      default:
        return null
    }
  }

  render() {
    return this.bannerFlavour()
  }
}

FeedbackBanner.propTypes = {
  currentUser: PropTypes.object
}

FeedbackBanner.contextType = GlobalAnnouncementContext

export default FeedbackBanner
