import {fromJS, List, Map} from 'immutable'
import PropTypes from 'prop-types'
import React from 'react'

import formFieldFactory from '../formFieldFactory'
import FieldErrors from './FieldErrors'
import FieldSet from './FieldSet'

export class KeyFieldValueText extends React.Component {
  constructor(props) {
    super(props)

    this.state = {textValue: props.data.get('value') || ''}
    this.onInput = this.onInput.bind(this)
  }

  onInput() {
    this.setState({textValue: this.refs.formInput.value})
  }

  UNSAFE_componentWillMount() { this.props.onChange() }

  componentDidUpdate(nextProps, nextState) {
    if (nextState.textValue !== this.state.textValue)
      this.props.onChange()
  }

  inputProps() {
    return {
      name: this.props.data.get('name'),
      ref: 'formInput',
      type: 'text',
      value: this.state.textValue
    }
  }

  value() { return this.state.textValue }


  render() {
    const {data, errors} = this.props
    const legend = data.get('legend')

    return (
      <FieldSet className='controls-group'>
        {legend && <legend>{legend}</legend>}
        <pre className='controls-flexbox flex-container'>
          <input {...this.inputProps()} onInput={this.onInput} />
        </pre>
        <FieldErrors errors={errors} />
      </FieldSet>
    )
  }
}

KeyFieldValueText.defaultProps = {
  data: fromJS({
    legend: '',
    name: ''
  }),
  errors: List(),
  type: 'text'
}

KeyFieldValueText.propTypes = {
  data: PropTypes.instanceOf(Map),
  errors: PropTypes.instanceOf(List),
  type: PropTypes.string
}

export default formFieldFactory(<KeyFieldValueText />)
