import {Component} from 'react'
import PropTypes from 'prop-types'

import Container from '../../../../lib/Container'
import HelpCenterLink from '../user_menu/HelpCenterLink'
import SecurityInfoLink from '../user_menu/SecurityInfoLink'
import Nav from './Nav'
import NavWrapper from './NavWrapper'
import NavHeader from './NavHeader'
import UserMenu from '../user_menu/UserMenu'

import {displayBanner} from '../../../../lib/bannerTools'
import SearchContainer from '../corp_max/search/SearchContainer'
import {GlobalAnnouncementContext} from '../../../../shared_components/ApplicationContainer'


export class DashboardNav extends Component {
  constructor(props) {
    super(props)

    this.state = {
      cobranding: Container.getStore('config').getState().data.cobranding,
      isPreviewPage: this.isPreviewPage(props)
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // Only bother updating the `state` if the page changed:
    if (nextProps.location.pathname !== this.props.location.pathname)
      this.setState({isPreviewPage: this.isPreviewPage(nextProps)})
  }

  isPreviewPage(props) {
    return props.router.isActive(`cm/everplan-preview/${props.params.everplanId}`)
  }

  render() {
    const cobranding = this.state.cobranding
    const displayBannerText = displayBanner({
      currentUser: this.props.currentUser,
      globalAnnouncementText: this.context.globalAnnouncementText
    })

    return (
      <NavWrapper isPreviewPage={this.state.isPreviewPage} cobranding={cobranding} displayBanner={displayBannerText}>
        <NavHeader cobranding={cobranding} />
        <SecurityInfoLink className='security-info-launcher-mobile' />
        <HelpCenterLink className='help-center-link-mobile' />
        <UserMenu className='user-menu-mobile flex-child' />
        <SearchContainer className='search-mobile' cobranding={cobranding} />
        {
          !this.state.isPreviewPage &&
            <Nav
              cobranding={cobranding}
              currentUser={this.props.currentUser}
              loadingCurrentUser={this.props.loadingCurrentUser}
              params={this.props.params}
              router={this.props.router}
            />
        }
      </NavWrapper>
    )
  }
}

DashboardNav.defaultProps = {
  currentUser: {
    is_deputy: false
  },
  loadingCurrentUser: true
}

DashboardNav.propTypes = {
  cobranding: PropTypes.object,
  currentUser: PropTypes.object,
  loadingCurrentUser: PropTypes.bool,
  location: PropTypes.shape({
    pathname: PropTypes.string
  }),
  params: PropTypes.object,
  router: PropTypes.object
}

DashboardNav.contextType = GlobalAnnouncementContext

export default DashboardNav
