import {Component} from 'react'
import {connect} from 'react-redux'
import * as reduxActions from 'redux-json-api'
import PropTypes from 'prop-types'

import NextBestActionFormController from './next_best_action_form/NextBestActionFormController'
import NextBestActionsController from './NextBestActionsController'

import {currentUserConfig} from '../../../../../lib/userTools'
import {redirectToDashboardFromTools} from '../../../../../lib/urlTools'
import {environmentMetadataVersion} from '../../../../../lib/apiV2Tools'

export class NextBestActionsToolController extends Component {
  constructor(props) {
    super(props)

    redirectToDashboardFromTools(props)
  }

  componentDidMount() {
    this.props.readEndpoint('settings')
    this.props.readEndpoint('metadata/versions')
  }

  render() {
    if (this.props.environmentVersion.isEmpty()) {
      return null
    } else {
      return (
        <div>
          <h2>Metadata Version {this.props.environmentVersion.get('name')}</h2>
          <NextBestActionFormController {...this.props} />
          <NextBestActionsController {...this.props} />
        </div>
      )
    }
  }
}

NextBestActionsToolController.propTypes = {
  environmentVersion: PropTypes.instanceOf(Map),
  readEndpoint: PropTypes.func
}

const mapStateToProps = state => ({
  environmentVersion: environmentMetadataVersion({versions: state.api.versions, settings: state.api.settings}),
  elements: state.elements,
  categories: state.categories,
  items: state.items,
  kits: state.kits,
  listMappings: state.listMappings,
  userConfig: currentUserConfig(state.api),
  views: state.views.filterNot(view => view.get('name') === 'default-en') // don't want the default view as a wizard option
})

export default connect(mapStateToProps, reduxActions)(NextBestActionsToolController)
