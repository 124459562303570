import {GAP_FILLER_ASSESSMENT_STEP} from './gapFillerActionTypes'

export const nextStep = ({step}) => ({
  type: GAP_FILLER_ASSESSMENT_STEP,
  payload: step + 1
})

export const previousStep = ({step}) => ({
  type: GAP_FILLER_ASSESSMENT_STEP,
  payload: step - 1
})

export const resetStep = () => ({
  type: GAP_FILLER_ASSESSMENT_STEP,
  payload: 0
})
