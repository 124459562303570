import PropTypes from 'prop-types'
import {useCallback, useEffect, useState} from 'react'
import {Map} from 'immutable'

import Modals from '../../../../core/modals/Modals'
import FuneralExpenseAssessmentIntro from './FuneralExpenseAssessmentIntro'
import FuneralExpenseCalculatorContainer from '../../funeral_expense_calculator/FuneralExpenseCalculatorContainer'
import FuneralExpenseAssessmentIntroContainer from './FuneralExpenseAssessmentIntroContainer'
import FuneralExpenseAssessmentOutroContainer from './FuneralExpenseAssessmentOutroContainer'
import FuneralExpenseRecommendationsContainer from './FuneralExpenseRecommendationsContainer'
import FuneralExpenseRecommendationHandoffConfirmation from './FuneralExpenseRecommendationHandoffConfirmation'
import FuneralExpenseOneMoment from './FuneralExpenseOneMoment'
import GapFillerAssessmentCloser from '../GapFillerAssessmentCloser'

import {differenceKeys} from '../../../../../lib/tools'
import {useScoreLedgerEventLogger} from '../../../../../lib/ScoreLedgerEventLogger'


export const FuneralExpenseAssessmentContainer = props => {
  const {logWithNotification} = useScoreLedgerEventLogger(props.userConfig)
  const step = props.step
  const [assessmentData, setAssessmentData] = useState(Map())

  const onStepChange = useCallback(
    newAssessmentData => {
      if (newAssessmentData) setAssessmentData(assessmentData.merge(newAssessmentData))

      props.nextStep({step})
    },
    [assessmentData, step]
  )

  const setAbandonedFlag = value => {
    const resource = {
      type: 'users',
      id: props.user.get('id'),
      attributes: {
        'viewed-flags': {
          ...props.user.get('viewed-flags').toJS(),
          abandoned_funeral_gapfiller: value
        }
      }
    }
    props.updateResource(resource)
  }

  // Update the `viewed_flag` to show the user started the assessment
  const userStartedAssessment = () => {
    const {user} = props

    if (!user.hasIn(['viewed-flags', 'abandoned_funeral_gapfiller']))
      setAbandonedFlag(true)
  }

  // Only update the `viewed_flag` and log the score event if it is the first time the user goes through this flow
  const userVisitedCalculator = () => {
    const {user} = props
    // Need to account for when the viewed flag hasn't been set at all or if it has and it is true
    if (!user.hasIn(['viewed-flags', 'abandoned_funeral_gapfiller']) || props.user.getIn(['viewed-flags', 'abandoned_funeral_gapfiller'])) {
      setAbandonedFlag(false)
      logWithNotification.completedProgram({name: 'funeral_expense_assessment'})
    }
  }

  const submitData = () => {
    const {assessment} = props
    const currentAssessmentData = assessment.get('data', Map())
    const extraKeys = differenceKeys(currentAssessmentData, assessmentData)

    const resource = {
      type: 'assessments',
      // need to delete one of these keys as a user can only make plans for burial or cremation and not both (at least for now) - Atanda
      attributes: {
        data: {
          ...currentAssessmentData.delete(extraKeys.has('headstone') ? 'ashes' : 'headstone').toJS(),
          ...assessmentData.toJS()
        },
        'user-id': props.user.get('id')
      }
    }

    if (assessment.isEmpty())
      props.createResource(resource)
    else
      props.updateResource({...resource, id: assessment.get('id')})
  }

  useEffect(() => {
    if (step === 0) userStartedAssessment()
    if (step === 3) userVisitedCalculator()
    if (step === 5) submitData()
  }, [step])

  const sharedProps = {onStepChange, goToPreviousStep: props.goToPreviousStep}

  const closer = useCallback(() => (
    <GapFillerAssessmentCloser
      toggleGapFillerAssessmentModal={props.toggleGapFillerAssessmentModal}
    />
  ))

  if (step === 6) {
    return (
      <FuneralExpenseRecommendationHandoffConfirmation
        toggleGapFillerAssessmentModal={props.toggleGapFillerAssessmentModal}
      />
    )
  } else {
    return (
      <Modals.FullHeightModalLarge
        className='funeral-expense-assessment-container forms-playground'
        closerComponent={closer}
        showModal={true}>
        {step === 0 && (
          <FuneralExpenseAssessmentIntro onStepChange={onStepChange} />
        )}
        {step === 1 && <FuneralExpenseAssessmentIntroContainer {...sharedProps} />}
        {step === 2 && <FuneralExpenseOneMoment onStepChange={onStepChange} />}
        {step === 3 && (
          <FuneralExpenseCalculatorContainer
            data={assessmentData}
            {...sharedProps}
          />
        )}
        {step === 4 && (
          <FuneralExpenseAssessmentOutroContainer
            {...sharedProps}
            assessmentData={assessmentData}
          />
        )}
        {step === 5 && (
          <FuneralExpenseRecommendationsContainer
            {...sharedProps}
            assessmentData={assessmentData}
            toggleGapFillerAssessmentModal={props.toggleGapFillerAssessmentModal}
          />
        )}
      </Modals.FullHeightModalLarge>
    )
  }
}

FuneralExpenseAssessmentContainer.propTypes = {
  assessment: PropTypes.instanceOf(Map),
  createResource: PropTypes.func,
  nextStep: PropTypes.func,
  goToPreviousStep: PropTypes.func,
  resetStep: PropTypes.func,
  step: PropTypes.number,
  toggleGapFillerAssessmentModal: PropTypes.func,
  updateResource: PropTypes.func,
  user: PropTypes.instanceOf(Map),
  userConfig: PropTypes.instanceOf(Map)
}


export default FuneralExpenseAssessmentContainer
