/** @class MobileItemNavElements
 *
 * @desc
 * Component that maps the Item’s `navElements` into a List of objects
 * and passes the data to a select form field and renders when viewing
 * a compound item modal in mobile devices.
 *
 */
import {useMemo, useRef} from 'react'
import {fromJS, List, Map} from 'immutable'
import PropTypes from 'prop-types'

import Form from '../../../../../shared_components/forms/v2/Form'
import Fields from '../../../../../shared_components/forms/v2/fields/index'
import Logger from '../../../../../lib/NewLogger'

import './mobileItemNavElements.scss'


const MobileItemNavElements = props => {
  const formRef = useRef()
  /** Maps a List of all nav item elements and returns a List of objects which is passed to the `Select` form field component. */
  const navList = useMemo(() => props.navElements.map(navItem => (
    {
      label: navItem.get('display-name'),
      value: navItem.get('display-name'),
      id: navItem.get('parent-element-id')
    }
  )), [props.navElements])

  /** Returns the name of the currently selected nav item id. */
  const isCurrentTab = () => {
    const currentTabName = navList.find(item => (
      item.id === props.currentTabId
    ))

    return currentTabName.value
  }

  /** Finds the selected option value and returns it's id. */
  const getItemId = selectedVal => navList.find(item => item.value === selectedVal).id

  const onChange = () => {
    const elementId = getItemId(formRef.current.formData().getIn(['item-nav-elements', 'value']))
    props.setCurrentTab(elementId)

    Logger.log({
      name: 'clicked_compound_group_nav',
      payload: {
        actor: props.actor,
        context: props.context,
        element_id: elementId,
        everplan_id: props.itemResponse.get('everplan-id'),
        item: props.itemResponse.get('name'),
        type: props.eventType,
        wildcard: elementId
      }
    })
  }

  return (
    <nav className='mobile-item-nav-elements'>
      <Form ref={formRef} className='core'>
        <Fields.Select
          data={
            fromJS({
              id: 'item-nav-elements',
              items: navList.toJS(),
              legend: '',
              name: 'Item Nav Elements',
              value: isCurrentTab()
            })
          }
          onChange={onChange}
        />
      </Form>
    </nav>
  )
}

MobileItemNavElements.propTypes = {
  actor: PropTypes.string,
  context: PropTypes.string,
  eventType: PropTypes.string,
  itemResponse: PropTypes.instanceOf(Map),
  currentTabId: PropTypes.string,
  navElements: PropTypes.instanceOf(List),
  setCurrentTab: PropTypes.func
}

export default MobileItemNavElements
