import {Component} from 'react'
import PropTypes from 'prop-types'
import {withRouter} from 'react-router'
import {Map, List} from 'immutable'

import {Button} from '../../../blocks/Buttons'
import Modals from '../../../../../../shared_components/core/modals/Modals'
import NewLoader from '../../../../../../shared_components/NewLoader'
import Logger from '../../../../../../lib/NewLogger'

import './download.scss'


export class Download extends Component {
  constructor() {
    super()

    this.state = {showModal: false}

    this.processDownload = this.processDownload.bind(this)
    this.dismissModal = this.dismissModal.bind(this)
  }

  shouldHideDownloadLink() {
    return this.props.categoriesWithItemResponses.isEmpty()
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.everplanBundleDownload.isEmpty() && this.props.everplanBundleDownload.isEmpty())
      this.dismissModal()
  }

  processDownload() {
    if (this.props.everplanBundleDownload.isEmpty()) {
      this.setState({showModal: true})

      Logger.log({
        name: 'clicked_download_pdf_everplan',
        payload: {
          actor: this.props.actor,
          context: this.props.context,
          everplan_id: this.props.params.everplanId
        }
      })

      this.props.createResource({
        type: 'everplan-bundle-downloads',
        attributes: {'everplan-id': this.props.params.everplanId}
      })
    }
  }

  dismissModal() {
    this.setState({showModal: false})
  }

  render() {
    if (this.shouldHideDownloadLink()) {
      return null
    } else {
      return (
        <div className='download'>
          <a onClick={this.processDownload}>Download PDF</a>
          <Modals.SuccessPopUpModal showModal={this.state.showModal} showCloser={false}>
            <NewLoader loading={true} />
            <h2>Your download is processing.</h2>
            <p>
              Please wait - it might take a few minutes to prepare your
              PDF and attachments. Feel free to close this window and
              continue using the site. Your download will begin automatically
              once your PDF is ready.
            </p>
            <Button onClick={this.dismissModal}>Got it</Button>
          </Modals.SuccessPopUpModal>
        </div>
      )
    }
  }
}

Download.defaultProps = {everplanBundleDownload: Map()}

Download.propTypes = {
  categoriesWithItemResponses: PropTypes.instanceOf(List),
  actor: PropTypes.string,
  context: PropTypes.string,
  createResource: PropTypes.func,
  everplanBundleDownload: PropTypes.instanceOf(Map),
  params: PropTypes.shape({
    everplanId: PropTypes.string
  }),
  userConfig: PropTypes.instanceOf(Map)
}

export default withRouter(Download)
