import PropTypes from 'prop-types'
import {Map} from 'immutable'
import {useState} from 'react'

import Loader from '../../../../../shared_components/NewLoader'
import {useNotificationContext} from '../../../../../shared_components/notifications/NotificationContext'

import './updateOrRemoveCreditCard.scss'

const UpdateOrRemoveCreditCard = ({
  userConfig,
  updateResource,
  updateCreditCard
}) => {
  const [processing, setProcessing] = useState(false)
  const {alwaysNotify} = useNotificationContext()

  const removeCreditCard = () => {
    const resource = {
      type: 'billings',
      id: userConfig.get('everplan-id'),
      attributes: {'remove-credit-card': true}
    }

    setProcessing(true)
    updateResource(resource)
      .then(() => alwaysNotify.shortSuccess('Card has been removed'))
      .catch(() => alwaysNotify.shortError('Unable to remove card.'))
      .finally(() => setProcessing(false))
  }

  if (processing) {
    return <Loader loading={true} />
  } else {
    return (
      <div className='update-or-remove-credit-card'>
        <a onClick={updateCreditCard}>Update Credit Card</a>
        <a onClick={removeCreditCard}>Remove Credit Card</a>
      </div>
    )
  }
}

UpdateOrRemoveCreditCard.propTypes = {
  userConfig: PropTypes.instanceOf(Map),
  updateCreditCard: PropTypes.func,
  updateResource: PropTypes.func
}

export default UpdateOrRemoveCreditCard
