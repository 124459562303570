import PropTypes from 'prop-types'
import {useEffect, Fragment} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import {withRouter} from 'react-router'
import {readEndpoint} from 'redux-json-api'

import Loader from '../../../../../shared_components/NewLoader'
import userConfigWithUserWrapper from '../../../../../shared_components/userConfigWithUserWrapper'
import EverplanDownloadStatusController from '../everplan_download_status/EverplanDownloadStatusController'

import * as actionCreators from '../actions/corpMaxActionCreators'

const MetaDataContainer = props => {
  useEffect(() => {
    const corpMaxActions = props.corpMaxActions

    props.readEndpoint('metadata/next-best-actions')
    props.readEndpoint('metadata/content-downloads')
    props.readEndpoint('metadata/kits').then(response => {
      corpMaxActions.reshapeKits(response.body)
      corpMaxActions.reshapeElements(response.body)
    })
    props.readEndpoint('settings')
    props.readEndpoint('metadata/items').then(response => {
      corpMaxActions.reshapeItems(response.body)
    })

    props.readEndpoint('metadata/categories').then(response => corpMaxActions.reshapeCategories(response.body))
    props.readEndpoint('metadata/list-mappings').then(response => corpMaxActions.reshapeListMappings(response.body))
    props.readEndpoint('metadata/key-field-mappings').then(response => corpMaxActions.reshapeKeyFieldMappings(response.body))
    props.readEndpoint('metadata/views').then(response => corpMaxActions.reshapeViews(response.body))
  }, [])


  if (props.loading) {
    return <Loader loading={true} />
  } else {
    return (
      <Fragment>
        {props.children}
        <EverplanDownloadStatusController />
      </Fragment>
    )
  }
}

const isNotLoadingCorpMaxData = state => (
  state.api.kits &&
  !state.categories.isEmpty() &&
  !state.views.isEmpty() &&
  !state.items.isEmpty() &&
  !state.listMappings.isEmpty() &&
  !state.keyFieldMappings.isEmpty()
)

export const mapStateToProps = state => {
  if (isNotLoadingCorpMaxData(state)) {
    return ({
      loading: false
    })
  } else {
    return ({loading: true})
  }
}

const mapDispatchToProps = dispatch => ({
  corpMaxActions: bindActionCreators(actionCreators, dispatch),
  readEndpoint: bindActionCreators(readEndpoint, dispatch)
})

MetaDataContainer.propTypes = {
  corpMaxActions: PropTypes.shape({
    reshapeElements: PropTypes.func,
    reshapeKits: PropTypes.func,
    reshapeItems: PropTypes.func,
    reshapeCategories: PropTypes.func,
    reshapeListMappings: PropTypes.func,
    reshapeKeyFieldMappings: PropTypes.func,
    reshapeViews: PropTypes.func
  }),
  readEndpoint: PropTypes.func,
  router: PropTypes.shape({
    isActive: PropTypes.func
  })
}

export const MetaDataContainerWithUserConfigAndUser = withRouter(connect(mapStateToProps, mapDispatchToProps)(userConfigWithUserWrapper(MetaDataContainer)))

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MetaDataContainer))
