import LegacyActionFactory from '../shared_components/LegacyActionFactory'

const UserActions = new LegacyActionFactory([
  'DID_UPDATE_USER'
])

UserActions.updateUser = ({userId, ...data}) => {
  UserActions.fireApi(
    'patch',
    `users/${userId}`,
    data,
    {
      JSONHead: 'user',
      successAction: UserActions.Types.DID_UPDATE_USER
    }
  )
}

export default UserActions
