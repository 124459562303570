import PropTypes from 'prop-types'
import {useCallback} from 'react'

import {Text, TextGutterExtraLarge, TextGutterLarge} from '../../../../../dashboard/src/components/blocks/Texts'
import {Accept} from '../../../../../dashboard/src/components/blocks/Buttons'
import FuneralExpenseHeader from './FuneralExpenseHeader'

import Balloons from '../../../../../dashboard/assets/gap_fillers/balloons.svg'
import CalculatorGhost from '../../../../../dashboard/assets/gap_fillers/calculatorGhost.svg'
import PiggyBank from '../../../../../dashboard/assets/gap_fillers/piggyBank.svg'
import Logger from '../../../../../lib/NewLogger'

import './funeralExpenseAssessmentIntro.scss'

const FuneralExpenseAssessmentIntro = ({onStepChange}) => {
  const nextScreen = useCallback(
    () => {
      Logger.log({
        name: 'clicked_gap_filler_assessment_intro',
        payload: {flavor: 'funeral', wildcard: 'funeral'}
      })
      onStepChange()
    },
    []
  )

  return (
    <div className='funeral-expense-assessment-intro'>
      <FuneralExpenseHeader />
      <div className='content-container'>
        <h2>Do you know how much your final celebration might cost?</h2>
        <TextGutterExtraLarge className='intro-text'>
          It's not something people usually talk about, but it's something every
          family will face at some point. We think it's better to be informed
          than to be surprised.
        </TextGutterExtraLarge>
        <TextGutterExtraLarge className='infographics'>
          <TextGutterLarge className='item'>
            <img className='balloons' src={Balloons} alt='balloons' onClick={nextScreen} />
            <Text>
              <strong>1. Explore your wishes</strong>
            </Text>
          </TextGutterLarge>
          <TextGutterLarge className='item'>
            <img className='calculator' src={CalculatorGhost} alt='calculator' onClick={nextScreen} />
            <Text>
              <strong>2. Understand the cost</strong>
            </Text>
          </TextGutterLarge>
          <TextGutterLarge className='item'>
            <img className='piggy-bank' src={PiggyBank} alt='piggy-bank' onClick={nextScreen} />
            <Text className='funding'>
              <strong>3. Find a way to fund your celebration</strong>
            </Text>
          </TextGutterLarge>
        </TextGutterExtraLarge>
        <div className='call-to-action'>
          <Accept onClick={nextScreen}>Take our assessment</Accept>
        </div>
      </div>
    </div>
  )
}

FuneralExpenseAssessmentIntro.propTypes = {onStepChange: PropTypes.func}

export default FuneralExpenseAssessmentIntro
