import CompoundFormComponent from '../../../../shared_components/forms/CompoundFormComponent'
import Forms from '../../../../shared_components/forms'
import Payment from 'payment'
import ReactDOM from 'react-dom'
import Validators from '../../../../shared_components/forms/Validators'

export default class CreditCardCompoundFields extends CompoundFormComponent {
  componentDidMount() {
    if (!__TEST__) { // Uses external Stripe library and will break tests and is not needed for tests --JA
      Payment.formatCardExpiry(ReactDOM.findDOMNode(this.refs.creditCardExpirationDateInput))
      Payment.formatCardCVC(ReactDOM.findDOMNode(this.refs.creditCardCVCInput))
    }
  }

  render() {
    return (
      <div className='form-element form-row credit-card-compound-fields'>
        <Forms.Input
          className='credit-card-expiration-date'
          defaultValue={__DEV__ ? '12/25' : ''}
          label='Exp. date'
          name='expirationDate'
          placeholder='MM/YY'
          ref='creditCardExpirationDateInput'
          tabIndex={2}
          type='tel'
        />
        <Forms.Input
          className='credit-card-cvc'
          defaultValue={__DEV__ ? '333' : ''}
          label='CVC'
          name='cvc'
          placeholder='CVC'
          ref='creditCardCVCInput'
          tabIndex={3}
          type='tel'
        />
        <Forms.Errors errors={this.errors()} />
      </div>

    )
  }
}

CreditCardCompoundFields.defaultProps = {
  validator(name, data) { return Validators.creditCardDateAndCVCValidator(name, data) }
}
