import {Map, List} from 'immutable'
import {useRef, useCallback} from 'react'
import PropTypes from 'prop-types'

import {ContentBox} from '../../../../dashboard/src/components/blocks/ContentBoxes'
import Form from '../../../forms/v2/Form'
import Fields from '../../../forms/v2/fields/index'
import {TextGutterMedium, TextGutterSmall} from '../../../../dashboard/src/components/blocks/Texts'
import {Accept} from '../../../../dashboard/src/components/blocks/Buttons'

import {numberWithCommas} from '../../../../lib/tools'

import './funeralExpenseCalculator.scss'

const FuneralExpenseCalculator = ({funeralCost, funeralRequirements, dropdowns, checkboxes, setFuneralChoices, funeralChoices, showFundingOptions, showExistingPlanOptions}) => {
  const formRef = useRef()

  const onSelectionChange = useCallback(() => {
    const formData = formRef.current.formData()

    setFuneralChoices(oldFuneralChoices => {
      const data = Map({
        service: formData.getIn(['service', 'value']),
        disposition: formData.getIn(['disposition', 'value']),
        celebration: formData.getIn(['celebration', 'value']),
        music: formData.getIn(['music', 'value']),
        casket: formData.getIn(['casket', 'value']),
        'additional-cost': parseInt(formData.getIn(['additional-cost', 'value']), 10) || 0,
        'final-ceremony': formData.getIn(['final-ceremony', 'value']),
        'additional-expenses': formData.getIn(['additional-expenses', 'value'])
      })

      if (oldFuneralChoices.get('celebration') === data.get('celebration'))
        return oldFuneralChoices.merge(data)
      else
        return oldFuneralChoices.merge(data).delete('additional-expenses')
    })
  }, [dropdowns, checkboxes])

  return (
    <ContentBox className='forms-playground funeral-expense-calculator'>
      <div className='contents'>
        <div className='selections'>
          <Form className='core' ref={formRef}>
            {
              dropdowns.map(config => (
                <div key={config.get('id')}>
                  <h4>{config.get('label')}</h4>
                  <Fields.Select data={config} onChange={onSelectionChange} />
                </div>
              ))
            }
            <h4>Additional Expenses:</h4>
            <Fields.CheckboxList key={checkboxes.get('value').size} data={checkboxes} onChange={onSelectionChange} />
            <h4>Something else we didn't ask about?</h4>
            <span>(Example: fireworks, rent Yankee stadium, paid appearance by The Rock)</span>
            <Fields.Text data={Map({id: 'additional-cost', name: 'additional-cost', placeholder: '$'})} fieldType='number' onChange={onSelectionChange} />
          </Form>
        </div>
        <div>
          <div className='expense-breakdown'>
            <TextGutterMedium className='summary'>
              <h2>Estimated Cost Breakdown</h2>
            </TextGutterMedium>
            <TextGutterMedium className='requirements-heading'>
              <TextGutterSmall className='heading'>Item</TextGutterSmall>
              <TextGutterSmall className='heading'>Price</TextGutterSmall>
            </TextGutterMedium>
            <TextGutterMedium className='requirements'>
              <div className='description'>
                {funeralRequirements.map(requirement => <TextGutterSmall key={requirement.get('text')}>{requirement.get('text')}</TextGutterSmall>)}
                <TextGutterSmall>Miscellaneous</TextGutterSmall>
              </div>
              <div className='cost'>
                {funeralRequirements.map(requirement => <TextGutterSmall key={`${requirement.get('cost')}-${requirement.get('text')}`}>${requirement.get('cost')}</TextGutterSmall>)}
                <TextGutterSmall>${funeralChoices.get('additional-cost', 0)}</TextGutterSmall>
              </div>
            </TextGutterMedium>
            <TextGutterMedium className='requirements-total'>
              <TextGutterSmall className='heading'>TOTAL</TextGutterSmall>
              <TextGutterSmall className='heading'>${numberWithCommas(funeralCost)}</TextGutterSmall>
            </TextGutterMedium>
            <TextGutterMedium className='helper-text'>
              A final celebration can be expensive, but there are ways to pay for it without your family going broke.
              We'll walk you through options to properly set aside some money so that everything you want becomes a reality.
            </TextGutterMedium>
            <Accept onClick={showFundingOptions} className='call-to-action'>Show me how I can fund it</Accept>
            <a onClick={showExistingPlanOptions}>I already set aside some money</a>
          </div>
        </div>
      </div>
    </ContentBox>
  )
}

FuneralExpenseCalculator.propTypes = {
  checkboxes: PropTypes.instanceOf(List),
  dropdowns: PropTypes.instanceOf(List),
  funeralChoices: PropTypes.instanceOf(Map),
  funeralCost: PropTypes.number,
  funeralRequirements: PropTypes.instanceOf(List),
  setFuneralChoices: PropTypes.func,
  showExistingPlanOptions: PropTypes.func,
  showFundingOptions: PropTypes.func
}

export default FuneralExpenseCalculator
