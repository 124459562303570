const EnvelopeIcon = () => (
  <svg width='26' height='20' viewBox='0 0 26 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M24.0399 0.5H1.96014C1.42987 0.5 1 0.936718 1 1.47544V16.5246C1 17.0633 1.42987 17.5 1.96014 17.5H24.0399C24.5701 17.5 25 17.0633 25 16.5246V1.47544C25 0.936718 24.5701 0.5 24.0399 0.5Z' stroke='#2E2D2C' stroke-width='0.869349' stroke-linecap='round' stroke-linejoin='round'/>
    <path d='M1.49016 17.4997C0.961743 17.4997 0.840855 17.1944 1.22422 16.826L11.8376 6.49965C12.2175 6.12772 12.8426 6.12772 13.2225 6.49965L23.8358 16.826C24.2157 17.1979 24.0983 17.4997 23.5699 17.4997H1.49016Z' fill='none'/>
    <path d='M1.49016 17.4997C0.961743 17.4997 0.840855 17.1944 1.22422 16.826L11.8376 6.49965C12.2175 6.12772 12.8426 6.12772 13.2225 6.49965L23.8358 16.826C24.2157 17.1979 24.0983 17.4997 23.5699 17.4997H1.49016Z' stroke='#2E2D2C' stroke-width='0.869349' stroke-linecap='round' stroke-linejoin='round'/>
    <path d='M23.5686 0.5C24.097 0.5 24.2179 0.805263 23.8346 1.17368L13.2213 11.5C12.8413 11.8719 12.2162 11.8719 11.8363 11.5L1.22297 1.17368C0.843056 0.801754 0.960489 0.5 1.48891 0.5H23.5686Z' fill='#FFF8EF'/>
    <path d='M23.5686 0.5C24.0971 0.5 24.2179 0.805263 23.8346 1.17368L13.2213 11.5C12.8413 11.8719 12.2162 11.8719 11.8363 11.5L1.22297 1.17368C0.843056 0.801754 0.960489 0.5 1.48891 0.5H23.5686Z' stroke='#2E2D2C' stroke-width='0.869349' stroke-linecap='round' stroke-linejoin='round'/>
  </svg>
)

export default EnvelopeIcon
