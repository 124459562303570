import {Map} from 'immutable'
import PropTypes from 'prop-types'
import {useState, useCallback, useEffect} from 'react'

import FuneralExpenseAssessmentForm from './FuneralExpenseAssessmentForm'
import NewProgressBar from '../../../../NewProgressBar'
import FuneralExpenseHeader from './FuneralExpenseHeader'

import {scrollModalTopIntoView} from '../../../../../lib/scrollTools'

import './funeralExpenseAssessmentScreensContainer.scss'

const FuneralExpenseAssessmentScreensContainer = ({
  conditionalKeyDictionary,
  initialScreenKey,
  onStepChange,
  goToPreviousStep,
  screenDictionary
}) => {
  const [infoData, setInfoData] = useState(Map())

  const [sectionState, setSectionState] = useState({
    sectionConfig: screenDictionary.get(initialScreenKey),
    screenTotal: screenDictionary.getIn([initialScreenKey, 'number-of-screens']),
    isLastSection: false
  })

  const nextSectionKey = useCallback(
    (newInfoData, goNext) => {
      const sectionConfig = sectionState.sectionConfig
      const sectionKey = sectionConfig.get(goNext ? 'next-screen' : 'back-screen')

      if (sectionKey) {
        return sectionKey
      } else if (sectionConfig.get('conditional-branch')) {
        return conditionalKeyDictionary.get(
          infoData.merge(newInfoData).get(sectionConfig.get('name'))
        )
      }
    },
    [infoData, sectionState]
  )

  const updateSectionState = useCallback(
    ({newInfoData, goNext = true}) => {
      const {sectionConfig, screenTotal} = sectionState
      const sectionKey = nextSectionKey(newInfoData, goNext)
      const nextSectionConfig = screenDictionary.get(sectionKey, sectionConfig)

      setSectionState({
        sectionConfig: nextSectionConfig,
        screenTotal: nextSectionConfig.get('number-of-screens', screenTotal),
        isLastSection: !sectionKey
      })
    },
    [sectionState]
  )

  const goToNextStep = useCallback(() => onStepChange(infoData), [infoData])

  const onSubmit = useCallback(
    newInfoData => {
      if (newInfoData) setInfoData(infoData.merge(newInfoData))
      updateSectionState({newInfoData})
    },
    [sectionState]
  )

  const goBack = useCallback(() => {
    if (!sectionState.sectionConfig.get('back-screen')) goToPreviousStep()
    else updateSectionState({newInfoData: Map(), goNext: false})
  }, [sectionState])

  useEffect(() => {
    scrollModalTopIntoView()

    if (sectionState.isLastSection) goToNextStep()
  }, [sectionState])

  return (
    <div className='funeral-expense-assessment-screens-container'>
      <FuneralExpenseHeader />
      <NewProgressBar
        step={sectionState.sectionConfig.get('screen-position')}
        total={sectionState.screenTotal}
      />
      <FuneralExpenseAssessmentForm
        sectionConfig={sectionState.sectionConfig}
        onSubmit={onSubmit}
        goBack={goBack}
        infoData={infoData}
      />
    </div>
  )
}

FuneralExpenseAssessmentScreensContainer.propTypes = {
  goToPreviousStep: PropTypes.func,
  initialScreenKey: PropTypes.string,
  onStepChange: PropTypes.func,
  screenDictionary: PropTypes.instanceOf(Map),
  screenOrder: PropTypes.instanceOf(Map),
  conditionalKeyDictionary: PropTypes.instanceOf(Map)
}

export default FuneralExpenseAssessmentScreensContainer
