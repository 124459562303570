/**
 * @desc
 * This component handles gatekeeping access to the Everplan web app if users have a pending required action
 * like accepting terms of service, privacy policy (to be added once that branch is released) and profile completion.
 * To answer the question `why not just use interstitial?`. Our Interstitial system is currently implemented
 * as a nice to have and users can easily bypass it. Whereas, the redirects that would be handled here are a
 * must have.
 */
import {useEffect, Fragment} from 'react'
import {connect} from 'react-redux'
import {Map} from 'immutable'
import PropTypes from 'prop-types'

import {currentUserConfig, needsRequiredActionCheck} from '../../../lib/userTools'
import routerUtils from '../../../lib/routerUtils'
import {webAppsUrl} from '../../../lib/urlTools'

export const PendingRequiredActionCheck = props => {
  const {userConfig} = props

  useEffect(
    () => {
      if (needsRequiredActionCheck(userConfig)) {
        if (!userConfig.get('agreement-accepted'))
          routerUtils.setLocation(webAppsUrl('terms-of-service-and-privacy-policy'))
        else if (!userConfig.get('profile-complete'))
          routerUtils.setLocation(webAppsUrl('profile-completion'))
      }
    },
    [
      userConfig.get('agreement-accepted'),
      userConfig.get('account-manager'),
      userConfig.get('admin'),
      userConfig.get('profile-complete')
    ]
  )

  return (
    <Fragment>
      {props.children}
    </Fragment>
  )
}

PendingRequiredActionCheck.propTypes = {
  userConfig: PropTypes.instanceOf(Map)
}

const mapStateToProps = state => ({userConfig: currentUserConfig(state.api)})

export default connect(mapStateToProps)(PendingRequiredActionCheck)
