import {Map} from 'immutable'

const body = `
Before we get into the qualities of a guardian, here are the responsibilities:

**Residence**: The guardian may determine where the child or person under their care lives as long as it is in their best interests. To move them out of state, the guardian may need a court's permission, depending on state laws.

**Education**: The guardian is responsible for choosing schools, being active in the education, and helping set and achieve educational goals.

**Medical care**: The guardian is responsible for basic medical, dental, and mental health treatments. In many states, after a child turns 14, the child must agree to certain treatments before receiving them, such as surgery.

Now that we're clear on the general duties, here's how you know the guardian named is up to the task.

#### Values
Does the guardian share your beliefs, principles, and philosophy of life? Will the guardian be able to raise your child, or care for the special needs adult, according to your values?

#### Personality and lifestyle
What kind of person is the guardian, and what kind of life do they live? Are the guardian's personality and lifestyle compatible with your child's personality and interests?

#### Religious views
Does the guardian share your religious views? If not, would the guardian still be willing or able to raise your child according to your religious views?

#### Parenting style
If the guardian is already a parent, what is their parenting style like? Is it similar to your own or very different? Do you think your child would thrive with the guardian's style of parenting?

#### Ability to act as a guardian
Can the guardian handle the responsibilities and duties that come with the guardianship? Do they have the resources necessary to raise a child, which may include time, energy, health, financial ability, and emotional wherewithal.

#### Existing relationship
Does the child or special needs adult already know the guardian? Do they like each other and have a friendly, healthy relationship?

#### Location
Does the guardian live near you, or far away? Would placing the child with the guardian require the child or special needs adult to relocate?

### Bottom Line

Choosing, or being chosen, as a guardian should be an open and honest process and it shouldn't be a surprise to anyone involved. This isn't a reality show (or at least you don't want it to become one). If you have doubts about a person you plan on naming, or if you don't think you're up to the task upon being named, you need to work it out before it's officially included in a Will.
`


export default Map({
  body,
  headline: 'Factors To Consider When Naming A Guardian For Children Or Special Needs Adults',
  summary: "Choosing a guardian is a tough decision, as is being chosen. Since it's possible you could face both of these possibilities in your life, here's how you know if you made (or are) the right choice.",
  query: 'factors-to-consider-when-naming-a-guardian-for-children-or-special-needs-adults',
  url: 'https://www.everplans.com/articles/how-to-choose-a-guardian-of-the-person'
})
