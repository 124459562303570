import PropTypes from 'prop-types'

import {Accept} from '../../../../../dashboard/src/components/blocks/Buttons'
import tryAgain from '../../../../../shared_assets/v2/try-again.png'

import './digitalSubscriptionEmptySubscription.scss'

const DigitalSubscriptionEmptySubscription = ({
  toggleGapFillerAssessmentModal,
  plaidUtils
}) => (
  <div className='digital-subscription-empty-subscription'>
    <h2>No services found</h2>
    <p>
      Looks like you don't have any recurring payments for digital services in
      these accounts.
    </p>
    <img alt='try-again animation' src={tryAgain} />
    <a onClick={plaidUtils.open}>Try another bank or credit card</a>
    <Accept onClick={toggleGapFillerAssessmentModal}>Done</Accept>
  </div>
)

DigitalSubscriptionEmptySubscription.propTypes = {
  toggleGapFillerAssessmentModal: PropTypes.func,
  plaidUtils: PropTypes.shape({
    open: PropTypes.func,
    ready: PropTypes.bool
  })
}

export default DigitalSubscriptionEmptySubscription
