import {Map} from 'immutable'

const body = `
Perhaps the reason more people don't buckle down and get serious about estate planning is in the name itself. "Estate planning" sounds like something you do in between charity dinner parties and tennis dates with the von Bülows. It's clearly something meant for wealthy people who have "estates" in the first place, right?

Nope!

An estate is just a fancy name for "all your stuff" -- even if it's not a vintage Bentley and stocks you can trace back the Dutch East India Company. It's still worth something to someone. It's also why you should take some time to talk with an estate attorney to ensure you've done everything you can to make sure it ends up in the right hands.

"Estate planning consists of three documents, and it's not necessarily attuned to monetary funds," says tax attorney and CPA Howard Chernoff. "It's attuned to making provisions so people don't have to go crazy when you die."

To that end, here the three frequently asked questions answered by someone who does this for a living.

### "I'm not rich so do I really need a Last Will & Testament?"

The answer is simple, as Chernoff explains. "The reason you need a Will is because you should be the one who designates who takes care of your stuff, and you should designate who should get your stuff. Because otherwise it may go to people who you don't want to have the stuff."

Chernoff is quick not to make light of the more serious consequences of not having a Will, however. He points out that declaring a guardian for children under the age of 18, or special needs adults under your supervision, is vital. "If you don't do that, presuming that both parents die, the kids will go into state care and relatives will have to apply to a court [for custody]. Judges are not so prone to just give them to relatives as they used to. So you're talking $5,000 or $6,000 paid to a court so that the kids can be placed with the relative that you would have wanted them to be placed with anyway had you stated it in a Will."

Or perhaps there's a relative you don't want raising your kids. But if it's not down on paper then it won't be your decision to make anymore. And the kids might end up with Crazy Uncle Jimmy, who can't be trusted around a houseplant.

### "What happens if I don't name someone as my Health Care Proxy?"

A Health Care Proxy (a.k.a. Medical Power Of Attorney or Health Care Agent), which is part of an Advance Directive, is important if you want to avoid living in a semi-permanent state of limbo.

"At some point you may have decided, 'look, I want extended emergency care to cease'," says Chernoff.  "And if you don't have a piece of paper that says, 'I don't want things done,' they're going to keep you alive if they can keep you alive for 90 years. Because they get paid a lot of money to keep you alive."

Each state has its own Advance Directive form, which you can find right here. If you're having a difficult time making decisions, consult your doctor, close friends and family, or estate attorney to better understand what choices you have to make and the repercussions.

### "What exactly is Power Of Attorney, and is it necessary?"

According to Chernoff, naming a Power Of Attorney is essential. "It's extremely important that you establish legal and financial Power Of Attorney," he explains. "So a surviving spouse or someone else can make decisions for you if you are incapable. Not if you're dead, mind you. If you are in any way unable to function."

The POA lists out your hand-selected roster of people you trust to make necessary legal and financial decisions in your stead. "You can have a POA that takes effect immediately," says Chernoff. "Or you can have what's called a 'springing power' that goes into effect only at the point you are unable to move forward."

A POA is only active when you're alive. Once you die your family needs your Will to start settling your estate. Once submitted to Probate, which is when the court makes sure the Will is authentic, things can get complex and you'll most likely want to hire an estate attorney to make sure everything goes as smoothly as possible.

If it all works out well, it could take a few months. If there are complications -- like someone contesting the Will -- then it could take years. While it's difficult to predict unexpected drama among your heirs, by getting these documents completed and a plan in place, you've done your very best to eliminate surprises and allow your family to remember you as the great, responsible, and caring person you are.
`


export default Map({
  body,
  headline: '3 Simple Questions You Should Ask An Estate Attorney (And We Have The Answers)',
  summary: 'Find out what you need...before you need it.',
  query: '3-simple-questions-you-should-ask-an-estate-attorney-and-we-have-the-answers',
  url: 'https://www.everplans.com/articles/3-simple-questions-you-should-ask-an-estate-attorney-and-we-have-the-answers'
})
