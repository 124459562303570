import PropTypes from 'prop-types'

import {Map} from 'immutable'
import {useState, useEffect} from 'react'
import {fromJS} from 'immutable'

import {BoxGutterLarge} from '../../blocks/Boxes'
import {Button} from '../../blocks/Buttons'
import {TextGutterMedium} from '../../blocks/Texts'

import routerUtils from '../../../../../lib/routerUtils'
import TfaConfigStore from '../../../../../auth/src/stores/TfaConfigStore'
import TfaWizardStore from '../../../../../auth/src/stores/TfaWizardStore'

const TfaInformation = ({readEndpoint, userConfig}) => {
  const [
    tfaConfig, setTfaConfig
  ] = useState(fromJS(TfaConfigStore.getTfaConfig() || {}))

  const bodyText = userConfig.get('tfa-enabled') ?
    'Two-Step verification is enabled for your account. Manage your settings here.' :
    'Two-Step verification is not enabled for your account. We recommend that you turn on this extra layer of security as soon as possible.'

  useEffect(() => {
    const onUpdate = () => {
      setTfaConfig(fromJS(TfaConfigStore.getTfaConfig()))
    }

    TfaConfigStore.addChangeListener(onUpdate)

    return () => TfaConfigStore.removeChangeListener(onUpdate)
  }, [])

  useEffect(() => {
    if (tfaConfig.get('status') === 'disabled')
      TfaWizardStore.initialize()

    readEndpoint('user-configs')
  }, [tfaConfig.get('status')])

  const handleClick = () => routerUtils.push('cm/tfa-setting')

  return (
    <BoxGutterLarge>
      <h2>Two-Step Verification:</h2>
      <TextGutterMedium>{bodyText}</TextGutterMedium>
      <Button onClick={handleClick}>Manage</Button>
    </BoxGutterLarge>
  )
}

TfaInformation.propTypes = {
  readEndpoint: PropTypes.func,
  userConfig: PropTypes.instanceOf(Map)
}

export default TfaInformation

