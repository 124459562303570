import PropTypes from 'prop-types'
import {Component} from 'react'
import {Map} from 'immutable'

import {Button} from '../../../blocks/Buttons'
import NextBestActionForm from './NextBestActionForm'
import Closer from '../../../../../../shared_components/core/closer/Closer'
import Modals from '../../../../../../shared_components/core/modals/Modals'
import {apiV2MetaDataCall} from '../../../../../../lib/apiV2Tools'


export default class UpdateNextBestActionController extends Component {
  constructor() {
    super()

    this.state = {
      processing: false,
      showModal: false
    }

    this.submitNextBestActionData = this.submitNextBestActionData.bind(this)
    this.toggleModal = this.toggleModal.bind(this)
    this.closer = this.closer.bind(this)
  }

  // Will do a back-fill for any existing next-best-actions to have a type of 'full-item' and remove this, but don't want
  // to break the front-end until that happens.
  updateNextBestActionWithType() {
    const nextBestActionData = this.props.nextBestAction.get('data')
    return nextBestActionData.get('type') ? nextBestActionData : nextBestActionData.set('type', 'full-item')
  }

  submitNextBestActionData(nextBestAction) {
    this.setState({processing: true})
    const resource = {
      type: 'next-best-actions',
      id: this.props.nextBestAction.get('id'),
      attributes: {
        data: nextBestAction,
        'version-id': this.props.nextBestAction.get('version-id')
      }
    }

    apiV2MetaDataCall({apiCall: this.props.updateResource, resource})
      .then(() => {
        this.setState({processing: false})
        this.toggleModal()
      })
  }

  closer() { return <Closer closer={this.toggleModal} /> }

  toggleModal() { this.setState({showModal: !this.state.showModal}) }

  render() {
    return (
      <div className='update-next-best-action-controller'>
        <Button onClick={this.toggleModal}>Update Next Best Action</Button>
        <Modals.PopUpModalLarge showModal={this.state.showModal} closerComponent={this.closer}>
          <NextBestActionForm
            {...this.props}
            processing={this.state.processing}
            key={this.props.nextBestAction.get('id')}
            submitNextBestActionData={this.submitNextBestActionData}
            nextBestAction={this.updateNextBestActionWithType()}
          />
        </Modals.PopUpModalLarge>
      </div>
    )
  }
}

UpdateNextBestActionController.propTypes = {
  nextBestAction: PropTypes.instanceOf(Map),
  createResource: PropTypes.func,
  updateResource: PropTypes.func
}
