import {connect} from 'react-redux'
import {Map, List} from 'immutable'
import PropTypes from 'prop-types'

import PaymentReceivedModal from './PaymentReceivedModal'

import {cleanResponse} from '../../../../dashboard/src/components/corp_max/redux/apiHelper'
import FeatureFlags from '../../../../lib/FeatureFlags'

export const PaymentReceivedWithTrial = ({bookOfferExperiment}) => {
  const bookOfferEnabled = FeatureFlags.isEnabled('trialing_users_book_offer')
  const hasBookOffer = bookOfferEnabled && !bookOfferExperiment.get('converted') && bookOfferExperiment.get('variant') === '1'
  const bodyText = hasBookOffer ?
    [
      "One last step. If you'd like a complimentary copy of our latest book, ",
      <strong key={bookOfferExperiment.get('variant')}>In Case You Get Hit By a Bus</strong>,
      ", please provide your mailing address. (Don't worry—we dont give your address to anyone.)"
    ] :
    'Thank you! Your purchase was successful.'

  return <PaymentReceivedModal hasBookOffer={hasBookOffer} bodyText={bodyText} />
}

PaymentReceivedWithTrial.propTypes = {bookOfferExperiment: PropTypes.instanceOf(Map)}

const mapStateToProps = ({api}) => {
  const experiments = cleanResponse(api['user-experiments']).getIn([0, 'experiments'], List())
  const bookOfferExperiment = experiments.find(experiment => experiment.get('experiment') === 'trialing_users_book_offer_emails')

  return {bookOfferExperiment: bookOfferExperiment || Map()}
}

export default connect(mapStateToProps)(PaymentReceivedWithTrial)
