import classnames from 'classnames'
import PropTypes from 'prop-types'

import Logger from '../../../../lib/NewLogger'
import routerUtils from '../../../../lib/routerUtils'


const SecureSharingItems = props => {
  const logEvent = name => {
    Logger.log({
      name,
      payload: {
        context: 'primary_navigation'
      }
    })
  }

  const goToMyDeputies = () => {
    logEvent('clicked_my_deputies')
    routerUtils.push('cm/deputies')
  }

  const goToDeputyFor = () => {
    logEvent('clicked_im_a_deputy_for')
    routerUtils.push('cm/deputy-for')
  }


  const showDeputyNav = () => props.currentUser.is_deputy || props.currentUser.has_pending_deputy_invite

  return (
    <ul className={classnames('nav-group-sublist', props.className)}>
      <li className={classnames('nav-deputy-link', {active: props.myDeputiesActive})}>
        <a onClick={goToMyDeputies}>My deputies</a>
      </li>
      {
        showDeputyNav() && (
          <li className={classnames('nav-deputy-link', {active: props.deputiesOthersActive})}>
            <a onClick={goToDeputyFor}>I'm a deputy for...</a>
          </li>
        )
      }
    </ul>
  )
}

SecureSharingItems.propTypes = {
  currentUser: PropTypes.shape({
    has_invited_deputy: PropTypes.bool,
    has_pending_deputy_invite: PropTypes.bool,
    is_deputy: PropTypes.bool
  }),
  deputiesOthersActive: PropTypes.bool,
  myDeputiesActive: PropTypes.bool
}

export default SecureSharingItems
