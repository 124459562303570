import PropTypes from 'prop-types'
import {List} from 'immutable'

const KeyFieldPreview = ({keyFieldValues}) => {
  const plusSign = index => {
    if (index === keyFieldValues.size - 1)
      return ''
    else
      return ' + '
  }

  return (
    <pre className='key-field-preview'>
      {keyFieldValues.map((keyFieldValue, index) => `${keyFieldValue.get('name')}${plusSign(index)}`)}
    </pre>
  )
}

KeyFieldPreview.propTypes = {
  keyFieldValues: PropTypes.instanceOf(List)
}

export default KeyFieldPreview
