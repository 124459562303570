import {Component} from 'react'
import {List} from 'immutable'
import {PropTypes} from 'prop-types'

import {BoxGutterMedium} from '../../../../blocks/Boxes'
import Form from '../../../../../../../shared_components/forms/v2/Form'
import ViewElement from './ViewElement'

export default class SelectedViewElements extends Component {
  render() {
    if (this.props.selectedElements.isEmpty()) {
      return null
    } else {
      return (
        <BoxGutterMedium className='selected-view-elements'>
          <Form className='core' ref='form'>
            <h3>Selected Fields</h3>
            {
              this.props.selectedElements.map(
                element => (
                  <ViewElement
                    {...this.props}
                    onElementSelect={this.props.removeElement}
                    formData={List()}
                    selectElementText='Remove Field'
                    key={element.get('id')}
                    element={element}
                  />
                )
              )
            }
          </Form>
        </BoxGutterMedium>
      )
    }
  }
}

SelectedViewElements.propTypes = {
  removeElement: PropTypes.func,
  selectedElements: PropTypes.instanceOf(List),
  toggleEditViewForm: PropTypes.func
}

