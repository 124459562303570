import PropTypes from 'prop-types'

import Modals from '../../../../core/modals/Modals'
import {TextGutterMedium} from '../../../../../dashboard/src/components/blocks/Texts'
import {Accept} from '../../../../../dashboard/src/components/blocks/Buttons'
import Closer from '../../../../core/closer/Closer'

import consultationConfirmation from '../../../../../shared_assets/consultation_confirmation@2x.png'
import blueCheckMark from '../../../../../shared_assets/blue_checkmark@2x.png'

import './funeralExpenseRecommendationHandoffConfirmation.scss'

const FuneralExpenseRecommendationHandoffConfirmation = props => {
  const closer = () => <Closer onClick={props.toggleGapFillerAssessmentModal} />

  return (
    <Modals.PopUpModalLarge showModal={true} closerComponent={closer}>
      <div className='funeral-expense-recommendation-handoff-confirmation'>
        <header>
          <img src={blueCheckMark} />
          <strong>All Set!</strong>
        </header>
        <img src={consultationConfirmation} />
        <h4>Someone will be in touch with you shortly.</h4>
        <TextGutterMedium>
          You're a planning champion for getting through this. We know it's not
          necessarily the most fun topic to think about and hope we were able to
          make it more understandable. You can expect to hear from someone
          within a few days. Now, go make yourself a nice drink. You deserve it.
        </TextGutterMedium>
        <Accept onClick={props.toggleGapFillerAssessmentModal}>Done</Accept>
      </div>
    </Modals.PopUpModalLarge>
  )
}

FuneralExpenseRecommendationHandoffConfirmation.propTypes = {
  toggleGapFillerAssessmentModal: PropTypes.func
}

export default FuneralExpenseRecommendationHandoffConfirmation
