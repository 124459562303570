/** @class DeputyUpgradePromptController
 *
 * @desc
 * Controller component that determines if the client upgrade prompt should be shown.
 * Due to the need to have the component below the Everplan preview in
 * mobile views and in the left sidebar in desktop views the component takes a
 * `className` prop and suppresses the entire module accordingly via CSS rules.
 *
 * @note logging the display of this component is done in PreviewSideNav, because this controller
 *  is put on the DOM in two places and we didn't want duplicate logging. --BLR
 *
 */

import classnames from 'classnames'
import {List, Map} from 'immutable'
import PropTypes from 'prop-types'

import ClientDeputyUpgradePrompt from './ClientDeputyUpgradePrompt'
import {shouldGetClientNetworkAmplifier} from '../../../../../lib/corpMaxDeputyTools'

import './deputyUpgradePromptController.scss'


const DeputyUpgradePromptController = ({className, userConfig, advisorContactCard, ownerships}) => {
  if (shouldGetClientNetworkAmplifier(userConfig, advisorContactCard)) {
    return (
      <div className={classnames('deputy-upgrade-prompt-controller', className)}>
        <ClientDeputyUpgradePrompt
          advisorContactCard={advisorContactCard}
          ownerships={ownerships}
        />
      </div>
    )
  } else {
    return null
  }
}

DeputyUpgradePromptController.propTypes = {
  advisorContactCard: PropTypes.instanceOf(Map),
  ownerships: PropTypes.instanceOf(List),
  userConfig: PropTypes.instanceOf(Map)
}

DeputyUpgradePromptController.defaultProps = {
  advisorContactCard: Map()
}

export default DeputyUpgradePromptController
