/** @class NextBestActionWizardContainer
 *
 * @desc
 * This container controls the flow of the wizard screens, which are separated into sections:
 *  - Gather: renders before either the ownership or fields if there is any gather text in the NBA
 *  - Ownership: renders before the fields if it is a household
 *  - Fields: render the elements, which are the questions asked for each wizard
 *  - Done: renders after the last field or on the exit and only if any responses have been saved
 */
import {useMemo, useState} from 'react'
import {connect} from 'react-redux'
import {List, Map} from 'immutable'
import PropTypes from 'prop-types'
import uuid from 'uuid'
import {useMutation} from '@apollo/client'

import DoneScreen from './DoneScreen'
import GatherScreen from './GatherScreen'
import Logger from '../../../lib/NewLogger'
import OwnershipScreen from './OwnershipScreen'
import WizardFieldContainer from './WizardFieldContainer'

import {elementsPerViewByType} from '../../../lib/viewTools'
import {findResourceById} from '../../../lib/plan_data/userData'
import {UPDATE_ITEM_RESPONSE} from '../../../graphql/mutations/itemResponse'
import {useScoreLedgerEventLogger} from '../../../lib/ScoreLedgerEventLogger'

export const NextBestActionWizardContainer = props => {
  const [updateItemResponse] = useMutation(UPDATE_ITEM_RESPONSE)
  const {logNewResponseGroupWithNotification} = useScoreLedgerEventLogger(props.userConfig)

  const view = useMemo(() => findResourceById({resourceList: props.views, id: props.nextBestAction.getIn(['data', 'view-id'])}), [props.nextBestAction])
  const wizardElements = useMemo(() => elementsPerViewByType({view, elements: props.elements, type: 'elements'}), [view])
  const groupId = useMemo(() => [uuid.v4(), uuid.v4()], [])

  const logEvent = ({name, payload = {}}) => {
    Logger.log({
      name,
      payload: {
        actor: 'planholder',
        context: props.context,
        everplan_id: props.userConfig.get('everplan-id'),
        header: props.nextBestAction.getIn(['data', 'header']),
        item: props.itemResponse.get('name'),
        type: props.eventType,
        view_id: view.get('id'),
        wildcard: props.itemResponse.get('name'),
        ...payload
      }
    })
  }

  const setInitialSection = () => {
    if (props.nextBestAction.getIn(['data', 'gather-text'])) {
      logEvent({
        name: 'viewed_prompt',
        payload: {
          prompt_type: 'suggested_things_to_gather'
        }
      })

      return 'gather'
    } else if (props.userConfig.get('household')) {
      logEvent({name: 'viewed_household_ownership_question'})

      return 'ownership'
    } else {
      return 'fields' // fields gets logged on the `wizardField`
    }
  }

  const [responsesOwnership, setResponsesOwnership] = useState(List())
  const [section, setSection] = useState(setInitialSection)
  const [previousSection, setPreviousSection] = useState('')

  const setNextSection = nextSection => {
    setPreviousSection(section)
    setSection(nextSection)
  }

  const setOwnershipWithSection = ({ownership, nextSection}) => {
    setResponsesOwnership(ownership)
    setPreviousSection(section)
    setSection(nextSection)
  }


  const sharedProps = {
    ...props,
    previousSection,
    updateItemResponse,
    section,
    groupId,
    responsesOwnership,
    setNextSection,
    view,
    wizardElements,
    logEvent
  }


  return (
    <div className='forms-playground next-best-action-wizard-container'>
      <GatherScreen {...sharedProps} />
      <OwnershipScreen {...sharedProps} setOwnershipWithSection={setOwnershipWithSection} />
      <WizardFieldContainer {...sharedProps} logNewResponseGroupWithNotification={logNewResponseGroupWithNotification} />
      <DoneScreen {...sharedProps} />
    </div>
  )
}

NextBestActionWizardContainer.propTypes = {
  context: PropTypes.string,
  elements: PropTypes.instanceOf(Map),
  eventType: PropTypes.string,
  userConfig: PropTypes.instanceOf(Map),
  itemResponse: PropTypes.instanceOf(Map),
  nextBestAction: PropTypes.instanceOf(Map),
  views: PropTypes.instanceOf(List)
}

const mapStateToProps = state => ({
  listMappings: state.listMappings,
  views: state.views,
  elements: state.elements,
  ownerships: state.owners
})


export default connect(mapStateToProps)(NextBestActionWizardContainer)
