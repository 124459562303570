import {useState} from 'react'
import PropTypes from 'prop-types'

import Closer from '../../../core/closer/Closer'
import ConfirmationModal from '../../../mui_base_components/modals/confirmation_modal/ConfirmationModal'

import './gapFillerAssessmentCloser.scss'

const GapFillerAssessmentCloser = ({
  toggleGapFillerAssessmentModal,
  confirmationText
}) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)

  return (
    <div className='gap-filler-assessment-closer'>
      <Closer
        closer={() => setShowConfirmationModal(true)}
        data-testid='closer'
      />
      <ConfirmationModal
        headingText={confirmationText}
        onClose={() => setShowConfirmationModal(false)}
        onConfirmation={toggleGapFillerAssessmentModal}
        open={showConfirmationModal}
      />
    </div>
  )
}

GapFillerAssessmentCloser.defaultProps = {
  confirmationText: 'Exit this assessment?'
}

GapFillerAssessmentCloser.propTypes = {
  toggleGapFillerAssessmentModal: PropTypes.func,
  confirmationText: PropTypes.string
}

export default GapFillerAssessmentCloser
