import {useEffect, Fragment} from 'react'
import {connect} from 'react-redux'
import {Map} from 'immutable'
import PropTypes from 'prop-types'

import {currentUserConfig} from '../../../lib/userTools'
import {findRawResourceByAttributeId, findRawResourceById} from '../../../lib/plan_data/userData'
import routerUtils from '../../../lib/routerUtils'
import {webAppsUrl} from '../../../lib/urlTools'

export const PendingRequiredFirmUserActionCheck = props => {
  const {userConfig, organizationConfig} = props

  useEffect(() => {
    if (!userConfig.get('tfa-enabled') && organizationConfig.get('mandatory-tfa-enabled'))
      routerUtils.setLocation(webAppsUrl('auth/tfa-intro'))
  }, [
    userConfig.get('tfa-enabled'),
    organizationConfig.get('mandatory-tfa-enabled')
  ])

  return (
    <Fragment>
      {props.children}
    </Fragment>
  )
}

PendingRequiredFirmUserActionCheck.propTypes = {
  userConfig: PropTypes.instanceOf(Map),
  organizationConfig: PropTypes.instanceOf(Map)
}

const mapStateToProps = state => {
  const userConfig = currentUserConfig(state.api)

  const firm = findRawResourceById({
    rawResource: state.api.firms,
    id: userConfig.get('firm-id')
  })

  const organizationConfig = findRawResourceByAttributeId({
    rawResource: state.api['organization-configs'],
    attribute: 'organization-id',
    id: firm.get('organization-id')
  })

  return {userConfig, organizationConfig}
}

export default connect(mapStateToProps)(PendingRequiredFirmUserActionCheck)
