import classnames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import {Link} from 'react-router'

import DesktopNavEverplansBrandedCobranding from '../../../../shared_components/everplans_branded_cobranding/DesktopNavEverplansBrandedCobranding'
import NavCategories from './NavCategories'
import NavLinksController from './NavLinksController'
import SecureSharingItems from './SecureSharingItems'
import {GlobalAnnouncementContext} from '../../../../shared_components/ApplicationContainer'
import {hasEverplansCobranding} from '../../../../lib/cobrandingTools'
import {noop} from '../../../../lib/tools'
import {displayBanner} from '../../../../lib/bannerTools'

import './nav.scss'

/**
 * @todo Make the path for the dashboard better somehow...
 */


export class Nav extends React.Component {
  constructor() {
    super()

    this.state = {
      showDropdownNav: false,
      showSecureSharing: false
    }

    this.onClickDashboardLink = this.onClickDashboardLink.bind(this)
    this.onLocationChange = this.onLocationChange.bind(this)
    this.toggleNavMenu = this.toggleNavMenu.bind(this)
    this.toggleSecureSharing = this.toggleSecureSharing.bind(this)
    this.updateWhichNavGroupIsActive = this.updateWhichNavGroupIsActive.bind(this)
  }

  componentDidMount() { this.props.router.listenBefore(this.onLocationChange) } // Listen for router location changes

  onLocationChange() { this.setState({showDropdownNav: false, showSecureSharing: false}) } // Close all dropdowns as soon as the location changes

  componentWillUnmount() { this.onLocationChange() }

  getChildContext() { return {toggleNavMenu: this.toggleNavMenu} }

  navGroupClassNames(navGroupType, activeNavGroupCondition) {
    return classnames(
      'nav-group',
      `nav-group-${navGroupType}`, // One of `link` or `row`
      {'active-nav-group': activeNavGroupCondition}
    )
  }

  dashboardClassNames() {
    const activeNavGroupCondition = (
      this.isRouteActive('cm/dashboard') && // Show as active if user is on dashboard page and...
      !( // Neither the My Plan dropdown nor the Secure Sharing dropdown is open. (Note the negating ! before the paren on this line.)
        this.state.showDropdownNav ||
        this.state.showSecureSharing
      )
    )

    return this.navGroupClassNames('link', activeNavGroupCondition)
  }

  myPlanClassNames() {
    const activeNavGroupCondition = (
      !this.state.showSecureSharing && // Show as active if the Secure Sharing dropdown is not open and one of the following is true:
      (
        this.state.showDropdownNav || // The My Plan dropdown nav is open
        this.props.router.isActive(`cm/category/${this.props.params.slug}`)
      )
    )

    return this.navGroupClassNames('row', activeNavGroupCondition)
  }

  addressBookClassName() {
    if (this.props.router.isActive('cm/address-book'))
      return 'active-address-book-link'
  }

  secureSharingClassNames() {
    const activeNavGroupCondition = (
      !this.state.showDropdownNav && // Show as active if the My Plan dropdown is not open and one of the following is true:
      (
        this.state.showSecureSharing || // The Secure Sharing dropdown is open
        this.isRouteActive('cm/deputies') || // Any Deputy page is open
        this.isRouteActive('cm/deputy-for') // Deputy For page is open
      )
    )

    return this.navGroupClassNames('secured-sharing', activeNavGroupCondition)
  }

  updateWhichNavGroupIsActive(sectionToDefinitelyToggle) {
    const newState = {
      showDropdownNav: false,
      showSecureSharing: false
    }

    if (['showDropdownNav', 'showSecureSharing'].includes(sectionToDefinitelyToggle)) // Don't set this value if it is a synthetic event. --BLR
      newState[sectionToDefinitelyToggle] = !this.state[sectionToDefinitelyToggle]

    this.setState(newState)
  }

  toggleNavMenu() { this.updateWhichNavGroupIsActive('showDropdownNav') }

  toggleSecureSharing() { this.updateWhichNavGroupIsActive('showSecureSharing') }

  secureSharingProps() {
    return {
      currentUser: this.props.currentUser,
      deputiesOthersActive: this.isRouteActive('cm/deputy-for'),
      myDeputiesActive: this.isRouteActive('cm/deputies', true)
    }
  }

  isRouteActive(cmRoute, exactPath = false) {
    return this.props.router.isActive(cmRoute, exactPath)
  }

  onClickDashboardLink() {
    if (this.state.showDropdownNav || this.state.showSecureSharing)
      return this.updateWhichNavGroupIsActive()
  }

  render() {
    if (this.props.loadingCurrentUser)
      return null

    const displayBannerText = displayBanner({
      currentUser: this.props.currentUser,
      globalAnnouncementText: this.context.globalAnnouncementText
    })

    return (
      <nav className={classnames('nav flex-child', displayBannerText)}>
        {hasEverplansCobranding(this.props.cobranding) && <DesktopNavEverplansBrandedCobranding cobranding={this.props.cobranding} />}
        <ul className='nav-groups flex-container'>
          <li className={this.dashboardClassNames()} onClick={this.onClickDashboardLink}>
            <Link className='nav-group-name nav-dashboard' to='cm/dashboard'>
              <p>Dashboard</p>
            </Link>
          </li>
          <li className={this.myPlanClassNames()}>
            <span>
              <div className='nav-menu'>
                <span className='nav-group-name nav-group-my-plan' onClick={this.toggleNavMenu}>My Everplan</span>
              </div>
              <NavCategories
                className='nav-categories-desktop'
                params={this.props.params}
                router={this.props.router}
              />
              <NavLinksController
                className={this.addressBookClassName()}
                currentUser={this.props.currentUser}
                showDropdownNav={this.state.showDropdownNav}
              />
            </span>
          </li>
          <li className={this.secureSharingClassNames()}>
            <span className='nav-group-name' onClick={this.toggleSecureSharing}>Sharing</span>
            <SecureSharingItems {...this.secureSharingProps()} className='secure-sharing-desktop' />
          </li>
        </ul>
        <NavLinksController
          className={this.addressBookClassName()}
          currentUser={this.props.currentUser}
          mobile
          showDropdownNav={this.state.showDropdownNav}
        />
        <NavCategories
          className={classnames('nav-categories-mobile', {'show-dropdown-nav': this.state.showDropdownNav, 'cm-layout': true})}
          params={this.props.params}
          router={this.props.router}
        />
        <SecureSharingItems
          {...this.secureSharingProps()}
          className={classnames('secure-sharing-mobile', {'show-dropdown-nav': this.state.showSecureSharing})}
        />
      </nav>
    )
  }
}

Nav.defaultProps = {
  router: {
    isActive: noop,
    listenBefore: noop
  }
}

Nav.propTypes = {
  cobranding: PropTypes.object,
  currentUser: PropTypes.shape({
    has_invited_deputy: PropTypes.bool,
    is_deputy: PropTypes.bool,
    everplan: PropTypes.object
  }),
  loadingCurrentUser: PropTypes.bool,
  params: PropTypes.object,
  router: PropTypes.shape({
    isActive: PropTypes.func,
    listenBefore: PropTypes.func
  })
}

Nav.childContextTypes = {toggleNavMenu: PropTypes.func}
Nav.contextType = GlobalAnnouncementContext

export default Nav
