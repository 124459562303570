
import {Component} from 'react'
import PropTypes from 'prop-types'
import {List, Map} from 'immutable'

import FieldSet from '../../../../../../shared_components/forms/v2/fields/FieldSet'
import * as Labels from '../../../../../../shared_components/forms/v2/fields/Labels'
import formFieldFactory from '../../../../../../shared_components/forms/v2/formFieldFactory'
import {onBlur, onFocus} from '../../../../../../dev_only/forms_playground/lib/tools'


export class SimpleContactField extends Component {
  constructor(props) {
    super(props)

    this.contactData = this.findContactById(props.data.get('value'))
  }

  findContactById(contactId) {
    if (contactId) {
      return this.props.newContacts
        .find(contact => contact.get('id') === contactId)
        .get('data')
        .set('contact-id', contactId)
    } else {
      return Map()
    }
  }

  value() {
    const contactValue = Map({
      'first-name': this.refs.name.value,
      'phone-numbers': !this.props.hidePhoneField && this.refs.phone.value ? List([this.refs.phone.value]) : ''
    })

    return this.contactData.merge(contactValue).filter(inputs => inputs !== '')
  }

  fieldProps() {
    return {
      onChange: this.props.onChange,
      onFocus,
      onBlur
    }
  }


  render() {
    const {errors} = this.props
    const legend = this.props.data.get('legend')

    return (
      <div>
        <FieldSet className='controls-group'>
          {legend && <legend>{legend}</legend>}
          <Labels.Label errors={errors}>
            <Labels.Accessible>{legend}</Labels.Accessible>
            <input
              {...this.fieldProps()}
              type='text'
              name='first-name'
              ref='name'
              placeholder='Enter their full name if possible'
              defaultValue={this.contactData.get('first-name')}
            />
          </Labels.Label>
        </FieldSet>
        {!this.props.hidePhoneField && (
          <FieldSet className='controls-group'>
            {legend && <legend>Phone Number</legend>}
            <Labels.Label errors={errors}>
              <Labels.Accessible>Phone Number:</Labels.Accessible>
              <input
                {...this.fieldProps()}
                type='tel'
                name='phone-number'
                ref='phone'
                placeholder='Only visible to your recipients'
                defaultValue={this.contactData.getIn(['phone-numbers', 0])}
              />
            </Labels.Label>
          </FieldSet>
        )}
      </div>
    )
  }
}

SimpleContactField.propTypes = {
  newContacts: PropTypes.instanceOf(List),
  data: PropTypes.instanceOf(Map),
  errors: PropTypes.instanceOf(List),
  hidePhoneField: PropTypes.bool,
  onChange: PropTypes.func
}


export default formFieldFactory(<SimpleContactField />)
