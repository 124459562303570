import PropTypes from 'prop-types'


import {BoxGutterMedium} from '../../blocks/Boxes'
import ClientPrivacyNoticeModal from '../../../../../shared_components/ClientPrivacyNoticeModal'
import {TextGutterMedium} from '../../blocks/Texts'


const Privacy = ({advisorFullName}) => (
  <BoxGutterMedium className='privacy'>
    <h2>Privacy:</h2>
    <TextGutterMedium>
      Your account comes courtesy of {advisorFullName}. In order to help you progress in your Everplan, certain information
      about how you are using your account may be shared with {advisorFullName}.
    </TextGutterMedium>
    <ClientPrivacyNoticeModal advisorFullName={advisorFullName} linkText='Learn More »' />
  </BoxGutterMedium>
)

Privacy.propTypes = {advisorFullName: PropTypes.string}

export default Privacy
