import PropTypes from 'prop-types'
import React from 'react'

import bindUpdate from '../../../../shared_components/BoundUpdate'
import {Cancel, Continue} from '../../../../shared_components/ButtonTypes'
import Forms from '../../../../shared_components/forms'
import ModalForm from '../../../../shared_components/ModalForm'
import modalWrapper from '../../../../shared_components/ModalWrapper'
import routerUtils from '../../../../lib/routerUtils'
import storePrototype from '../../../../shared_components/StorePrototype'
import {TextGutterMedium} from '../../../../dashboard/src/components/blocks/Texts'

import Container from '../../../../lib/Container'
import AccountSettingsActions from '../../actions/AccountSettingsActions'

import './changePassword.scss'

class ChangePassword extends React.Component {
  submit() {
    const data = this.refs.form.formData()

    if (this.refs.form.validate().length === 0) {
      this.props.updating(() => {
        AccountSettingsActions.fetchAccountInformation(this.props.userId)
        this.context.modalClosed()
        routerUtils.setLocation(API_HOST)
      })
      AccountSettingsActions.changePassword(data, this.props.userId)
    }
    if (this.props.errors.length > 0)
      this.props.finished()
  }
  closeModal() {
    this.props.finished()
    this.context.closeModal()
  }

  render() {
    const errors = this.props.errors
    return (
      <section className='change-password'>
        <a onClick={this.context.showModal}>Change Password</a>
        <ModalForm visible={this.props.showModal} ref='form' title='Change Password' closer={this.closeModal.bind(this)}>
          <TextGutterMedium>
            Passwords must be at least 8 characters and contains a number and at least 1 upper case and 1 lower case letter.
          </TextGutterMedium>
          <TextGutterMedium>
            Once your password is updated you will be required to sign in again using your new password.
          </TextGutterMedium>
          <Forms.PasswordInput label='Current password' name='current_password' validator={Forms.Validators.required} />
          <Forms.Password label='Your new password' name='password' />
          { errors && errors.length > 0 && <Forms.Errors errors={this.props.errors[0].errors} /> }
          <div className='button-group'>
            <Continue onClick={this.submit.bind(this)} loading={this.props.processing}>Update</Continue>
            <Cancel onClick={this.closeModal.bind(this)} />
          </div>
        </ModalForm>
      </section>
    )
  }
}

ChangePassword.contextTypes = {
  showModal: PropTypes.func,
  closeModal: PropTypes.func,
  modalClosed: PropTypes.func
}

ChangePassword.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string),
  finished: PropTypes.func,
  processing: PropTypes.bool,
  showModal: PropTypes.bool,
  updating: PropTypes.func,
  userId: PropTypes.string
}

Container.registerStore('change_password', storePrototype(AccountSettingsActions.Types.DID_CHANGE_PASSWORD))
export default modalWrapper(bindUpdate(ChangePassword, 'change_password', 'message'))
