import {useEffect} from 'react'
import PropTypes from 'prop-types'

import calculatingImage from '../../../../dashboard/assets/score_assessment/score-calculation.svg'
import './scoreCalculation.scss'

const ScoreCalculation = ({submitAssessment}) => {
  useEffect(() => {
    setTimeout(submitAssessment, 1000) // Give this screen a chance to be displayed in case of fast network conditions
  }, [])

  return (
    <div className='score-calculation'>
      <img alt='score-calculation-image' src={calculatingImage} />
      <h1 className='heading'>Calculating your Everplans score</h1>
    </div>
  )
}

ScoreCalculation.propTypes = {
  submitAssessment: PropTypes.func
}

export default ScoreCalculation
