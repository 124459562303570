import {ToggleButtonGroup, toggleButtonGroupClasses, styled} from '@mui/material'

import colors from '../dashboard/styles/abstracts/colors.module.scss'

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({theme}) => ({
  alignItems: 'center',
  [`& .${toggleButtonGroupClasses.grouped}`]: {
    backgroundColor: '#FFF',
    border: 'none',
    borderRadius: '16px',
    color: colors.headerText,
    fontWeight: '400',
    lineHeight: '150%',
    marginBottom: '24px',
    marginTop: 'unset',
    padding: theme.spacing(1.5, 4),
    textTransform: 'none',
    width: 'fit-content',
    ['@media (hover: none)']: {
      ':hover': {
        backgroundColor: '#FFF',
        color: colors.headerText
      }
    },
    ['@media (hover: hover)']: {
      ':hover': {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.contrastText
      }
    },
    ':last-of-type': {
      marginBottom: '0'
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.contrastText,
      ['@media (hover: none)']: {
        ':hover': {
          backgroundColor: theme.palette.primary.light,
          color: theme.palette.primary.contrastText
        }
      },
      ['@media (hover: hover)']: {
        ':hover': {
          backgroundColor: '#979797'
        }
      }
    }
  }
}))

export default StyledToggleButtonGroup
