import DownloadStatus from '../../../../../shared_components/DownloadStatus'

const EverplanDownloadStatus = ({everplanBundleDownload}) => {
  if (everplanBundleDownload.isEmpty() || everplanBundleDownload.get('status') === 'ready')
    return null
  else
    return <DownloadStatus />
}

export default EverplanDownloadStatus
