import classnames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import {Privacy} from './Links'
import UnorderedList from './UnorderedList'
import ResponsiveModal from './ResponsiveModal'

import wrapModal from './ModalWrapper'

import './privacyNoticeModal.scss'

const advisorAccessibleList = [
  'What parts of your Everplan you have completed',
  'Whether you have uploaded documents',
  'Changes to your username or email address',
  'Date and time of your log-ins'
]

const advisorInaccessibleList = [
  'Download, open, or view specific information that you enter into your Everplan, including account numbers, notes, addresses, contacts, preferences, or any other items that you chose to add.',
  "Download, open, or view the contents of any files you've added to your Everplan."
]

export class ClientPrivacyNoticeModal extends React.Component {
  render() {
    return (
      <span className={classnames('privacy-notice-modal', this.props.className)}>
        <a onClick={this.context.showModal}>{this.props.linkText}</a>
        <ResponsiveModal closer={this.context.modalClosed} visible={this.props.showModal}>
          <div className='modal-title'>
            <h3>Privacy Notice</h3>
          </div>
          <p>
            In order to help you progress with Everplans, <strong>{this.props.advisorFullName} will have access to the
            following information</strong> about how you are using this website:
          </p>
          <UnorderedList children={advisorAccessibleList} />
          <p>
            Unless you explicitly grant read/write Deputy permission, <strong>{this.props.advisorFullName} will not be
            able to:</strong>
          </p>
          <UnorderedList children={advisorInaccessibleList} />
          <p>Lastly, in the event that your death is reported to Everplans by another person, your advisor will be notified.</p>
          <p>See the complete <Privacy target='_blank'>Everplans Privacy Policy</Privacy>.</p>
        </ResponsiveModal>
      </span>
    )
  }
}

ClientPrivacyNoticeModal.contextTypes = {
  closeModal: PropTypes.func,
  modalClosed: PropTypes.func,
  showModal: PropTypes.func
}

ClientPrivacyNoticeModal.defaultProps = {
  advisorFullName: '',
  className: '',
  linkText: 'Learn more'
}

ClientPrivacyNoticeModal.propTypes = {
  advisorFullName: PropTypes.string.isRequired,
  linkText: PropTypes.string
}

export default wrapModal(ClientPrivacyNoticeModal)
