export default {
  defaultChecklist: {
    summary: 'Here are some of the essential documents, accounts, and types of information to organize and put in your Everplan.',
    headline: 'Checklist: Documents To Organize And Share',
    type: 'checklist',
    query: 'documents-to-organize',
    url: 'https://www.everplans.com/articles/checklist-documents-to-organize-and-share'
  },
  domestic: {
    defaultArticles: [
      {
        summary: "You're the only person alive with all of this information. If you don't share it, who will?",
        headline: "How To Make Sure Your Legacy Lives On After You're Gone",
        type: 'article',
        query: 'leave-a-legacy',
        url: 'https://www.everplans.com/articles/how-to-make-sure-your-legacy-lives-on-after-youre-gone'
      }
    ],
    stateGuides: {
      AK: {
        summary: 'Important health, legal, and end-of-life planning forms and information for people living in The Last Frontier.',
        stateName: 'Alaska',
        headline: 'Alaska Health, Legal, And End-Of-Life Resources',
        type: 'guide',
        query: 'health-legal-end-of-life',
        url: 'https://www.everplans.com/articles/alaska-health-legal-and-end-of-life-resources'
      },
      AL: {
        summary: 'Important health, legal, and end-of-life planning forms and information for people living in the Heart of Dixie.',
        stateName: 'Alabama',
        headline: 'Alabama Health, Legal, And End-Of-Life Resources',
        type: 'guide',
        query: 'health-legal-end-of-life',
        url: 'https://www.everplans.com/articles/alabama-health-legal-and-end-of-life-resources'
      },
      AR: {
        summary: 'Important health, legal, and end-of-life planning forms and information for people living in The Natural State.',
        stateName: 'Arkansas',
        headline: 'Arkansas Health, Legal, And End-Of-Life Resources',
        type: 'guide',
        query: 'health-legal-end-of-life',
        url: 'https://www.everplans.com/articles/arkansas-health-legal-and-end-of-life-resources'
      },
      AZ: {
        summary: 'Important health, legal, and end-of-life planning forms and information for people living in The Grand Canyon State.',
        stateName: 'Arizona',
        headline: 'Arizona Health, Legal, And End-Of-Life Resources',
        type: 'guide',
        query: 'health-legal-end-of-life',
        url: 'https://www.everplans.com/articles/arizona-health-legal-and-end-of-life-resources'
      },
      CA: {
        summary: 'Important health, legal, and end-of-life planning forms and information for people living in The Golden State.',
        stateName: 'California',
        headline: 'California Health, Legal, And End-Of-Life Resources',
        type: 'guide',
        query: 'health-legal-end-of-life',
        url: 'https://www.everplans.com/articles/california-health-legal-and-end-of-life-resources'
      },
      CO: {
        summary: 'Important health, legal, and end-of-life planning forms and information for people living in The Centennial State.',
        stateName: 'Colorado',
        headline: 'Colorado Health, Legal, And End-Of-Life Resources',
        type: 'guide',
        query: 'health-legal-end-of-life',
        url: 'https://www.everplans.com/articles/colorado-health-legal-and-end-of-life-resources'
      },
      CT: {
        summary: 'Important health, legal, and end-of-life planning forms and information for people living in The Constitution State.',
        stateName: 'Connecticut',
        headline: 'Connecticut Health, Legal, And End-Of-Life Resources',
        type: 'guide',
        query: 'health-legal-end-of-life',
        url: 'https://www.everplans.com/articles/connecticut-health-legal-and-end-of-life-resources'
      },
      DC: {
        summary: "Important health, legal, and end-of-life planning forms and information for people living in The Nation's Capital.",
        stateName: 'Washington DC',
        headline: 'Washington, D.C. (District of Columbia) Health, Legal, And End-Of-Life Resources',
        type: 'guide',
        query: 'health-legal-end-of-life',
        url: 'https://www.everplans.com/articles/washington-dc-district-of-columbia-health-legal-and-end-of-life-resources'
      },
      DE: {
        summary: 'Important health, legal, and end-of-life planning forms and information for people living in First State.',
        stateName: 'Delaware',
        headline: 'Delaware Health, Legal, And End-Of-Life Resources',
        type: 'guide',
        query: 'health-legal-end-of-life',
        url: 'https://www.everplans.com/articles/delaware-health-legal-and-end-of-life-resources'
      },
      FL: {
        summary: 'Important health, legal, and end-of-life planning forms and information for people living in The Sunshine State.',
        stateName: 'Florida',
        headline: 'Florida Health, Legal, And End-Of-Life Resources',
        type: 'guide',
        query: 'health-legal-end-of-life',
        url: 'https://www.everplans.com/articles/florida-health-legal-and-end-of-life-resources'
      },
      GA: {
        summary: 'Important health, legal, and end-of-life planning forms and information for people living in The Peach State.',
        stateName: 'Georgia',
        headline: 'Georgia Health, Legal, And End-Of-Life Resources',
        type: 'guide',
        query: 'health-legal-end-of-life',
        url: 'https://www.everplans.com/articles/georgia-health-legal-and-end-of-life-resources'
      },
      HI: {
        summary: 'Important health, legal, and end-of-life planning forms and information for people living in The Aloha State.',
        stateName: 'Hawaii',
        headline: 'Hawaii Health, Legal, And End-Of-Life Resources',
        type: 'guide',
        query: 'health-legal-end-of-life',
        url: 'https://www.everplans.com/articles/hawaii-health-legal-and-end-of-life-resources'
      },
      IA: {
        summary: 'Important health, legal, and end-of-life planning forms and information for people living in The Hawkeye State.',
        stateName: 'Iowa',
        headline: 'Iowa Health, Legal, And End-Of-Life Resources',
        type: 'guide',
        query: 'health-legal-end-of-life',
        url: 'https://www.everplans.com/articles/iowa-health-legal-and-end-of-life-resources'
      },
      ID: {
        summary: 'Important health, legal, and end-of-life planning forms and information for people living in The Gem State.',
        stateName: 'Idaho',
        headline: 'Idaho Health, Legal, And End-Of-Life Resources',
        type: 'guide',
        query: 'health-legal-end-of-life',
        url: 'https://www.everplans.com/articles/idaho-health-legal-and-end-of-life-resources'
      },
      IL: {
        summary: 'Important health, legal, and end-of-life planning forms and information for people living in The Land of Lincoln.',
        stateName: 'Illinois',
        headline: 'Illinois Health, Legal, And End-Of-Life Resources',
        type: 'guide',
        query: 'health-legal-end-of-life',
        url: 'https://www.everplans.com/articles/illinois-health-legal-and-end-of-life-resources'
      },
      IN: {
        summary: 'Important health, legal, and end-of-life planning forms and information for people living in The Hoosier State.',
        stateName: 'Indiana',
        headline: 'Indiana Health, Legal, And End-Of-Life Resources',
        type: 'guide',
        query: 'health-legal-end-of-life',
        url: 'https://www.everplans.com/articles/indiana-health-legal-and-end-of-life-resources'
      },
      KS: {
        summary: 'Important health, legal, and end-of-life planning forms and information for people living in The Sunflower State.',
        stateName: 'Kansas',
        headline: 'Kansas Health, Legal, And End-Of-Life Resources',
        type: 'guide',
        query: 'health-legal-end-of-life',
        url: 'https://www.everplans.com/articles/kansas-health-legal-and-end-of-life-resources'
      },
      KY: {
        summary: 'Important health, legal, and end-of-life planning forms and information for people living in The Bluegrass State.',
        stateName: 'Kentucky',
        headline: 'Kentucky Health, Legal, And End-Of-Life Resources',
        type: 'guide',
        query: 'health-legal-end-of-life',
        url: 'https://www.everplans.com/articles/kentucky-health-legal-and-end-of-life-resources'
      },
      LA: {
        summary: 'Important health, legal, and end-of-life planning forms and information for people living in The Pelican State.',
        stateName: 'Louisiana',
        headline: 'Louisiana Health, Legal, And End-Of-Life Resources',
        type: 'guide',
        query: 'health-legal-end-of-life',
        url: 'https://www.everplans.com/articles/louisiana-health-legal-and-end-of-life-resources'
      },
      MA: {
        summary: 'Important health, legal, and end-of-life planning forms and information for people living in The Bay State.',
        stateName: 'Massachusetts',
        headline: 'Massachusetts Health, Legal, And End-Of-Life Resources',
        type: 'guide',
        query: 'health-legal-end-of-life',
        url: 'https://www.everplans.com/articles/massachusetts-health-legal-and-end-of-life-resources'
      },
      MD: {
        summary: 'Important health, legal, and end-of-life planning forms and information for people living in The Old Line State.',
        stateName: 'Maryland',
        headline: 'Maryland Health, Legal, And End-Of-Life Resources',
        type: 'guide',
        query: 'health-legal-end-of-life',
        url: 'https://www.everplans.com/articles/maryland-health-legal-and-end-of-life-resources'
      },
      ME: {
        summary: 'Important health, legal, and end-of-life planning forms and information for people living in The Pine Tree State.',
        stateName: 'Maine',
        headline: 'Maine Health, Legal, And End-Of-Life Resources',
        type: 'guide',
        query: 'health-legal-end-of-life',
        url: 'https://www.everplans.com/articles/maine-health-legal-and-end-of-life-resources'
      },
      MI: {
        summary: 'Important health, legal, and end-of-life planning forms and information for people living in The Great Lakes State.',
        stateName: 'Michigan',
        headline: 'Michigan Health, Legal, And End-Of-Life Resources',
        type: 'guide',
        query: 'health-legal-end-of-life',
        url: 'https://www.everplans.com/articles/michigan-health-legal-and-end-of-life-resources'
      },
      MN: {
        summary: 'Important health, legal, and end-of-life planning forms and information for people living in The North Star State.',
        stateName: 'Minnesota',
        headline: 'Minnesota Health, Legal, And End-Of-Life Resources',
        type: 'guide',
        query: 'health-legal-end-of-life',
        url: 'https://www.everplans.com/articles/minnesota-health-legal-and-end-of-life-resources'
      },
      MO: {
        summary: 'Important health, legal, and end-of-life planning forms and information for people living in The Show-Me State.',
        stateName: 'Missouri',
        headline: 'Missouri Health, Legal, And End-Of-Life Resources',
        type: 'guide',
        query: 'health-legal-end-of-life',
        url: 'https://www.everplans.com/articles/missouri-health-legal-and-end-of-life-resources'
      },
      MS: {
        summary: 'Important health, legal, and end-of-life planning forms and information for people living in The Magnolia State.',
        stateName: 'Mississippi',
        headline: 'Mississippi Health, Legal, And End-Of-Life Resources',
        type: 'guide',
        query: 'health-legal-end-of-life',
        url: 'https://www.everplans.com/articles/mississippi-health-legal-and-end-of-life-resources'
      },
      MT: {
        summary: 'Important health, legal, and end-of-life planning forms and information for people living in The Treasure State.',
        stateName: 'Montana',
        headline: 'Montana Health, Legal, And End-Of-Life Resources',
        type: 'guide',
        query: 'health-legal-end-of-life',
        url: 'https://www.everplans.com/articles/montana-health-legal-and-end-of-life-resources'
      },
      NC: {
        summary: 'Important health, legal, and end-of-life planning forms and information for people living in The Tar Heel State.',
        stateName: 'North Carolina',
        headline: 'North Carolina Health, Legal, And End-Of-Life Resources',
        type: 'guide',
        query: 'health-legal-end-of-life',
        url: 'https://www.everplans.com/articles/north-carolina-health-legal-and-end-of-life-resources'
      },
      ND: {
        summary: 'Important health, legal, and end-of-life planning forms and information for people living in The Peace Garden State.',
        stateName: 'North Dakota',
        headline: 'North Dakota Health, Legal, And End-Of-Life Resources',
        type: 'guide',
        query: 'health-legal-end-of-life',
        url: 'https://www.everplans.com/articles/north-dakota-health-legal-and-end-of-life-resources'
      },
      NE: {
        summary: 'Important health, legal, and end-of-life planning forms and information for people living in The Cornhusker State.',
        stateName: 'Nebraska',
        headline: 'Nebraska Health, Legal, And End-Of-Life Resources',
        type: 'guide',
        query: 'health-legal-end-of-life',
        url: 'https://www.everplans.com/articles/nebraska-health-legal-and-end-of-life-resources'
      },
      NH: {
        summary: 'Important health, legal, and end-of-life planning forms and information for people living in The Granite State.',
        stateName: 'New Hampshire',
        headline: 'New Hampshire Health, Legal, And End-Of-Life Resources',
        type: 'guide',
        query: 'health-legal-end-of-life',
        url: 'https://www.everplans.com/articles/new-hampshire-health-legal-and-end-of-life-resources'
      },
      NJ: {
        summary: 'Important health, legal, and end-of-life planning forms and information for people living in The Garden State.',
        stateName: 'New Jersey',
        headline: 'New Jersey Health, Legal, And End-Of-Life Resources',
        type: 'guide',
        query: 'health-legal-end-of-life',
        url: 'https://www.everplans.com/articles/new-jersey-health-legal-and-end-of-life-resources'
      },
      NM: {
        summary: 'Important health, legal, and end-of-life planning forms and information for people living in The Land of Enchantment.',
        stateName: 'New Mexico',
        headline: 'New Mexico Health, Legal, And End-Of-Life Resources',
        type: 'guide',
        query: 'health-legal-end-of-life',
        url: 'https://www.everplans.com/articles/new-mexico-health-legal-and-end-of-life-resources'
      },
      NV: {
        summary: 'Important health, legal, and end-of-life planning forms and information for people living in The Silver State.',
        stateName: 'Nevada',
        headline: 'Nevada Health, Legal, And End-Of-Life Resources',
        type: 'guide',
        query: 'health-legal-end-of-life',
        url: 'https://www.everplans.com/articles/nevada-health-legal-and-end-of-life-resources'
      },
      NY: {
        summary: 'Important health, legal, and end-of-life planning forms and information for people living in The Empire State.',
        stateName: 'New York',
        headline: 'New York Health, Legal, And End-Of-Life Resources',
        type: 'guide',
        query: 'health-legal-end-of-life',
        url: 'https://www.everplans.com/articles/new-york-health-legal-and-end-of-life-resources'
      },
      OH: {
        summary: 'Important health, legal, and end-of-life planning forms and information for people living in The Buckeye State.',
        stateName: 'Ohio',
        headline: 'Ohio Health, Legal, And End-Of-Life Resources',
        type: 'guide',
        query: 'health-legal-end-of-life',
        url: 'https://www.everplans.com/articles/ohio-health-legal-and-end-of-life-resources'
      },
      OK: {
        summary: 'Important health, legal, and end-of-life planning forms and information for people living in The Sooner State.',
        stateName: 'Oklahoma',
        headline: 'Oklahoma Health, Legal, And End-Of-Life Resources',
        type: 'guide',
        query: 'health-legal-end-of-life',
        url: 'https://www.everplans.com/articles/oklahoma-health-legal-and-end-of-life-resources'
      },
      OR: {
        summary: 'Important health, legal, and end-of-life planning forms and information for people living in The Beaver State.',
        stateName: 'Oregon',
        headline: 'Oregon Health, Legal, And End-Of-Life Resources',
        type: 'guide',
        query: 'health-legal-end-of-life',
        url: 'https://www.everplans.com/articles/oregon-health-legal-and-end-of-life-resources'
      },
      PA: {
        summary: 'Important health, legal, and end-of-life planning forms and information for people living in The Keystone State.',
        stateName: 'Pennsylvania',
        headline: 'Pennsylvania Health, Legal, And End-Of-Life Resources',
        type: 'guide',
        query: 'health-legal-end-of-life',
        url: 'https://www.everplans.com/articles/pennsylvania-health-legal-and-end-of-life-resources'
      },
      RI: {
        summary: 'Important health, legal, and end-of-life planning forms and information for people living in The Ocean State.',
        stateName: 'Rhode Island',
        headline: 'Rhode Island Health, Legal, And End-Of-Life Resources',
        type: 'guide',
        query: 'health-legal-end-of-life',
        url: 'https://www.everplans.com/articles/rhode-island-health-legal-and-end-of-life-resources'
      },
      SC: {
        summary: 'Important health, legal, and end-of-life planning forms and information for people living in The Palmetto State.',
        stateName: 'South Carolina',
        headline: 'South Carolina Health, Legal, And End-Of-Life Resources',
        type: 'guide',
        query: 'health-legal-end-of-life',
        url: 'https://www.everplans.com/articles/south-carolina-health-legal-and-end-of-life-resources'
      },
      SD: {
        summary: 'Important health, legal, and end-of-life planning forms and information for people living in The Mount Rushmore State.',
        stateName: 'South Dakota',
        headline: 'South Dakota Health, Legal, And End-Of-Life Resources',
        type: 'guide',
        query: 'health-legal-end-of-life',
        url: 'https://www.everplans.com/articles/south-dakota-health-legal-and-end-of-life-resources'
      },
      TN: {
        summary: 'Important health, legal, and end-of-life planning forms and information for people living in The Volunteer State.',
        stateName: 'Tennessee',
        headline: 'Tennessee Health, Legal, And End-Of-Life Resources',
        type: 'guide',
        query: 'health-legal-end-of-life',
        url: 'https://www.everplans.com/articles/tennessee-health-legal-and-end-of-life-resources'
      },
      TX: {
        summary: 'Important health, legal, and end-of-life planning forms and information for people living in The Lone Star State.',
        stateName: 'Texas',
        headline: 'Texas Health, Legal, And End-Of-Life Resources',
        type: 'guide',
        query: 'health-legal-end-of-life',
        url: 'https://www.everplans.com/articles/texas-health-legal-and-end-of-life-resources'
      },
      UT: {
        summary: 'Important health, legal, and end-of-life planning forms and information for people living in The Beehive State.',
        stateName: 'Utah',
        headline: 'Utah Health, Legal, And End-Of-Life Resources',
        type: 'guide',
        query: 'health-legal-end-of-life',
        url: 'https://www.everplans.com/articles/utah-health-legal-and-end-of-life-resources'
      },
      VA: {
        summary: 'Important health, legal, and end-of-life planning forms and information for people living in Old Dominion.',
        stateName: 'Virginia',
        headline: 'Virginia Health, Legal, And End-Of-Life Resources',
        type: 'guide',
        query: 'health-legal-end-of-life',
        url: 'https://www.everplans.com/articles/virginia-health-legal-and-end-of-life-resources'
      },
      VT: {
        summary: 'Important health, legal, and end-of-life planning forms and information for people living in The Green Mountain State.',
        stateName: 'Vermont',
        headline: 'Vermont Health, Legal, And End-Of-Life Resources',
        type: 'guide',
        query: 'health-legal-end-of-life',
        url: 'https://www.everplans.com/articles/vermont-health-legal-and-end-of-life-resources'
      },
      WA: {
        summary: 'Important health, legal, and end-of-life planning forms and information for people living in The Evergreen State.',
        stateName: 'Washington',
        headline: 'Washington Health, Legal, And End-Of-Life Resources',
        type: 'guide',
        query: 'health-legal-end-of-life',
        url: 'https://www.everplans.com/articles/washington-health-legal-and-end-of-life-resources'
      },
      WI: {
        summary: 'Important health, legal, and end-of-life planning forms and information for people living in The Badger State.',
        stateName: 'Wisconsin',
        headline: 'Wisconsin Health, Legal, And End-Of-Life Resources',
        type: 'guide',
        query: 'health-legal-end-of-life',
        url: 'https://www.everplans.com/articles/wisconsin-health-legal-and-end-of-life-resources'
      },
      WV: {
        summary: 'Important health, legal, and end-of-life planning forms and information for people living in The Mountain State.',
        stateName: 'West Virginia',
        headline: 'West Virginia Health, Legal, And End-Of-Life Resources',
        type: 'guide',
        query: 'health-legal-end-of-life',
        url: 'https://www.everplans.com/articles/west-virginia-health-legal-and-end-of-life-resources'
      },
      WY: {
        summary: 'Important health, legal, and end-of-life planning forms and information for people living in The Equality State.',
        stateName: 'Wyoming',
        headline: 'Wyoming Health, Legal, And End-Of-Life Resources',
        type: 'guide',
        query: 'health-legal-end-of-life',
        url: 'https://www.everplans.com/articles/wyoming-health-legal-and-end-of-life-resources'
      }
    }
  },
  international: {
    defaultArticles: [
      {
        summary: "You're the only person alive with all of this information. If you don't share it, who will?",
        headline: "How To Make Sure Your Legacy Lives On After You're Gone",
        type: 'article',
        query: 'leave-a-legacy',
        url: 'https://www.everplans.com/articles/how-to-make-sure-your-legacy-lives-on-after-youre-gone'
      },
      {
        summary: "What happens to your digital property after you die? Great question! Here's how you can create a Digital Estate Plan in 5 easy steps.",
        headline: 'Digital Cheat Sheet: How To Create A Digital Estate Plan',
        type: 'article',
        query: 'leave-a-legacy',
        url: 'https://www.everplans.com/articles/digital-cheat-sheet-how-to-create-a-digital-estate-plan'
      }
    ]
  }
}
