import PropTypes from 'prop-types'
import {useState} from 'react'
import {withRouter} from 'react-router'

import Closer from '../../../../../shared_components/core/closer/Closer'
import ConfirmationModal from '../../../../../shared_components/mui_base_components/modals/confirmation_modal/ConfirmationModal'
import Logger from '../../../../../lib/NewLogger'
import {routeContainsVerb} from '../../../lib/corpMaxRouteMatcher'

const ItemCloser = props => {
  const [showModal, setShowModal] = useState(false)

  const logEvent = name => {
    Logger.log({
      name,
      payload: {
        context: props.context,
        type: props.eventType,
        item: props.itemName,
        wildcard: props.itemName,
        compound_item: props.compoundItem
      }
    })
  }

  const logExitEvent = () => {
    if (routeContainsVerb(props.location, ['edit']))
      logEvent('discarded_edit_response')
    else
      logEvent('closed_add_new')
  }

  const exitItemForm = () => {
    logExitEvent()

    props.confirmModalExit ? setShowModal(true) : props.exit()
  }

  const returnToItemForm = () => { setShowModal(false) }

  return (
    <div>
      <Closer closer={exitItemForm} data-testid='closer' />
      <ConfirmationModal
        bodyText='Changes you made will not be saved.'
        confirmButtonText='Close'
        headingText='Close this window?'
        onClose={returnToItemForm}
        onConfirmation={props.exit}
        open={showModal}
      />
    </div>
  )
}

ItemCloser.propTypes = {
  compoundItem: PropTypes.bool,
  context: PropTypes.string,
  confirmModalExit: PropTypes.bool,
  exit: PropTypes.func.isRequired,
  itemName: PropTypes.string,
  location: PropTypes.object,
  eventType: PropTypes.string
}

export default withRouter(ItemCloser)
