import PropTypes from 'prop-types'

import Closer from '../core/closer/Closer'

import './nextBestActionCloser.scss'

const NextBestActionCloser = ({dismissNextBestAction, fill}) => (
  <div className='next-best-action-closer'>
    <Closer
      fill={fill}
      height='10px'
      onClick={dismissNextBestAction}
      width='10px'
    />
  </div>
)

NextBestActionCloser.defaultProps = {
  fill: '#FFFFFF'
}

NextBestActionCloser.propTypes = {
  dismissNextBestAction: PropTypes.func.isRequired,
  fill: PropTypes.string
}

export default NextBestActionCloser

