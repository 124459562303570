import {Map} from 'immutable'

const body = `
You spent four years (maybe more) earning a diploma. Do you even know where you keep it? Unless it's on a wall in your office or home, it's probably in a drawer or box somewhere collecting dust.

In a world that's becoming increasingly digital, we forget that some of the most important and official things are still printed on paper or require some sort of physical ID. While some are ornamental certificates meant to be displayed, others are boring on the surface but extremely crucial when you need them.

Our challenge to you: How long would it take you to locate the following list of things below?

#### Basic rules
* If you don't have or need a document, skip to the next item on the list.
* You must physically find the official doc or ID; If you can't search for this stuff right now you must know with absolute certainty where it is and write down the location. We're operating on the honor system, so play fair.
* You've got a 10 minute time limit.

Set the timer on your watch or phone right now. Ready...set...GO!

### Let's See Some ID
* Birth Certificate
* Driver's License / Non-Driver's ID
* Citizenship Documentation
* Passport
* Social Security Card
* Military ID
* Work ID
* Primary Health Insurance Card(s)
* Supplemental Health Insurance Card(s) (example: Dental, Vision)

### Degrees & Decrees
* High School Diploma
* College/University Diploma
* Certifications (technical degree, doctorate, masters, etc.)
* Marriage Certificate
* Divorce Decree
* Military Discharge Papers

### VIPs: Very Important Papers
* Property Deed(s) or Lease
* Vehicle Title(s)
* Loan Agreements (auto, mortgage, student loans)
* Tax Returns for the past 7 years

**TEST COMPLETE!**

How'd you do? Could you find all the things you needed? It makes you realize how many forms of ID and official documents we accumulate throughout our lives. While some are ceremonial and reminders of accomplishments (graduating college, being born), others are vital in day to day life (driver's license).

If you had a difficult time locating any of the things listed above, imagine if someone close to you had to do it because you weren't around? Could they find all these things or would they have to spend days, weeks, or months searching?

Take some time and scan the list again. You might even want to make some notes of things you need to start searching for, or ordering replacements if necessary. Once you have all these things, take photos or scans of each and upload them to your Everplan.
`


export default Map({
  body,
  headline: 'Time To Go On A Document And ID Scavenger Hunt',
  summary: 'How long would it take you to locate the following list of things below?',
  query: 'time-to-go-on-a-document-and-id-scavenger-hunt',
  url: 'https://www.everplans.com/articles/where-do-you-keep-your-college-diploma'
})
