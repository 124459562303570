import PropTypes from 'prop-types'
import {Component} from 'react'

import Logger from '../../../../../lib/NewLogger'

export default class CorpCategory extends Component {
  constructor() {
    super()

    this.logAndGoToCategoryPage = this.logAndGoToCategoryPage.bind(this)
  }

  logAndGoToCategoryPage() {
    Logger.log({
      name: 'clicked_category',
      payload: {
        context: 'primary_navigation',
        category: this.props.categoryName,
        wildcard: this.props.categoryName
      }
    })

    this.props.onClick()
  }

  render() {
    return (
      <li className={this.props.className}>
        <a className='nav-category-name' onClick={this.logAndGoToCategoryPage}>
          {this.props.categoryName}
        </a>
      </li>
    )
  }
}

CorpCategory.propTypes = {
  categoryName: PropTypes.string,
  onClick: PropTypes.func
}
