import PropTypes from 'prop-types'
import classnames from 'classnames'
import {useRef, useState, useEffect, useMemo} from 'react'
import {List, Map} from 'immutable'
import {useMutation} from '@apollo/client'
import uuid from 'uuid'

import ButtonGroup from '../../../../../shared_components/core/buttons/ButtonGroup'
import * as Buttons from '../../blocks/Buttons'
import Element from './Element'
import Form from '../../../../../shared_components/forms/v2/Form'
import NextBestActionNotification from '../bit_by_bits/NextBestActionsNotification'

import {anyDataUpdated, findResourceByAttributeId} from '../../../../../lib/plan_data/userData'
import {routeContainsVerb} from '../../../lib/corpMaxRouteMatcher'
import {UPDATE_ITEM_RESPONSE} from '../../../../../graphql/mutations/itemResponse'
import Logger from '../../../../../lib/NewLogger'
import routerUtils from '../../../../../lib/routerUtils'
import {useNotificationContext} from '../../../../../shared_components/notifications/NotificationContext'
import {useScoreLedgerEventLogger} from '../../../../../lib/ScoreLedgerEventLogger'
import {
  itemResponseTopKitListMappingId,
  buildItemResponseMutationVariables,
  itemResponseKitElementsById,
  elementWithConditionalKitElementsByValue
} from '../../../../../lib/plan_data/itemResponsesHelper'

/** returns an array of group ids based on whether we are
  editing, adding a new response or adding a new response group
  note: all responses must have two group ids*/
const getGroupId = groupId => {
  if (groupId && groupId.length > 0)
    return groupId
  else
    return [uuid.v4()].concat([uuid.v4()])
}

const ItemForm = props => {
  const formRef = useRef()
  const groupId = useMemo(() => getGroupId(props.groupId), [])
  const {alwaysNotify, dismissToastNotification} = useNotificationContext()
  const {logExistingResponseGroupWithDelayedNotification, logNewResponseGroupWithDelayedNotification} = useScoreLedgerEventLogger(props.userConfig)

  const topKitListMappingId = useMemo(() => itemResponseTopKitListMappingId(props.itemResponse), [])

  const secondGroupIdResponses = useMemo(() => {
    const secondGroupIdData = findResourceByAttributeId({resourceList: props.responsesGroupedBySecondGroupId, attribute: 'second-group-id', id: groupId[1]})
    // Reformatting responses to remove top level "value" to avoid having to refactor many more components that also use the same components as the ItemForm
    return secondGroupIdData.get('responses', List()).map(response => response.set('value', response.getIn(['value', 'data'])))
  }, [])

  /**
   * Checks if there is a query in the location if the element is a list-mapping and it matches the item's top-kit list-mapping,
   * and if it is then returns the conditional value in the query. This is used to populate a conditional for a simple item
   * form when a user has arrived to this form via a next best action. --ZD
   */
  const conditionalValue = element => ((props.conditionalValue && element.get('list-mapping', '') === topKitListMappingId) ? props.conditionalValue : null)

  const updateViewElements = () => {
    // When viewing a sub-compound section that has no data we want to show the top level element and not the fields underneath
    // until a user clicks "start" and `showExpandedForm` is true.
    if (props.addMore && !props.showExpandedForm)
      return List.of(props.currentElement)

    const formResponses = formRef.current ? formRef.current.formData().toList() : secondGroupIdResponses

    const parentElements = itemResponseKitElementsById({itemResponse: props.itemResponse, id: props.currentElement.get('id')})

    return parentElements.reduce((collection, element) =>
      collection.concat(
        elementWithConditionalKitElementsByValue({
          itemResponse: props.itemResponse,
          element,
          formResponses,
          defaultConditionalValue: conditionalValue(element)
        })), List()
    )
  }

  const [elements, setElements] = useState(updateViewElements)
  const [uploadingFiles, setUploadingFiles] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [updateItemResponse] = useMutation(UPDATE_ITEM_RESPONSE)

  useEffect(() => {
    setElements(updateViewElements)
  }, [props.currentElement])

  const setHasChangedItemFormData = () => {
    const form = formRef.current

    form ? props.setHasChangedItemFormData(anyDataUpdated(secondGroupIdResponses, form.formData().toList())) : props.setHasChangedItemFormData(false)
  }

  useEffect(() => {
    setHasChangedItemFormData()
  }, [])

  const itemViewGroupId = props.initialGroupId || groupId[0]

  const goToViewItem = () => routerUtils.push(props.viewItemPath({elementId: props.params.elementId, groupId: itemViewGroupId}))

  const notifyOnSuccessfulUpdate = () => {
    if (props.context === 'dashboard') {
      alwaysNotify.shortSuccess(
        <NextBestActionNotification
          {...props}
          dismissNotification={dismissToastNotification}
          groupId={itemViewGroupId}
        />
      )
    } else {
      alwaysNotify.shortSuccess(
        props.ownEverplan ? 'Your Everplan has been updated.' : "Your client's Everplan has been updated."
      )
    }
  }

  const logEvent = name => {
    const itemName = props.itemResponse.get('name')

    Logger.log({
      name,
      payload: {
        actor: props.actor,
        everplan_id: props.itemResponse.get('everplan-id'),
        context: props.context,
        type: props.eventType,
        item: itemName,
        wildcard: itemName,
        compound_item: props.hasMultipleFieldGroups
      }
    })
  }

  const cancel = () => {
    if (routeContainsVerb(props.location, ['edit']))
      logEvent('discarded_edit_response')
    else
      logEvent('closed_add_new')

    props.exit()
  }

  const exit = () => (props.hasMultipleFieldGroups ? goToViewItem() : props.exit())

  const logScoreLedgerEvent = () => {
    if (props.ownEverplan) {
      const {responseGroup, itemResponse, eventType} = props
      const isNextBestAction = eventType === 'full-item'

      responseGroup.isEmpty() ?
        logNewResponseGroupWithDelayedNotification({itemResponse, isNextBestAction}) :
        logExistingResponseGroupWithDelayedNotification({itemResponse})
    }
  }

  const submit = () => {
    setProcessing(true)

    const form = formRef.current

    form.onSubmit()

    if (form.isValid()) {
      logEvent('submitted_response_group')

      const responses = form.formData().toList()

      updateItemResponse({
        variables: buildItemResponseMutationVariables({
          itemResponse: props.itemResponse,
          ownershipIds: props.responseOwnershipIds,
          groupId,
          responses
        }),
        onCompleted: () => {
          notifyOnSuccessfulUpdate()
          logScoreLedgerEvent()
          exit()
        }
      })
    } else {
      setProcessing(false)
    }
  }

  const onUpdate = () => {
    if (formRef.current) {
      setElements(updateViewElements)
      setHasChangedItemFormData()
    }
  }

  const updateFileUploadStatus = isUploadingFiles => {
    if (isUploadingFiles !== uploadingFiles)
      setUploadingFiles(isUploadingFiles)
  }

  const formData = formRef.current ? formRef.current.formData() : Map()

  return (
    <div className={classnames('edit-item flex-child', {'compound-item': (props.addMore || props.showExpandedForm)})}>
      <Form className='core' ref={formRef} groupId={groupId} onUpdate={onUpdate}>
        {
          elements.map(
            element => (
              <Element
                {...props}
                elements={elements}
                addMore={!element.get('conditional') && props.addMore}
                conditionalValue={conditionalValue(element)}
                data={secondGroupIdResponses}
                element={element}
                key={element.get('id')}
                showAdd={!props.showExpandedForm}
                updateFileUploadStatus={updateFileUploadStatus}
                formData={formData}
                nextBestActionHeader={props.nextBestAction.getIn(['data', 'header'])}
              />
            )
          )
        }
      </Form>
      {
        (!props.addMore || props.showExpandedForm) && (
          <ButtonGroup>
            <Buttons.Cancel onClick={cancel} />
            <Buttons.Accept onClick={submit} processing={processing} disabled={!props.hasChangedItemFormData || uploadingFiles}>
            Save
            </Buttons.Accept>
          </ButtonGroup>
        )
      }
    </div>
  )
}


ItemForm.defaultProps = {
  nextBestAction: Map()
}

ItemForm.propTypes = {
  actor: PropTypes.string,
  addMore: PropTypes.bool,
  context: PropTypes.string,
  currentElement: PropTypes.instanceOf(Map),
  eventType: PropTypes.string,
  exit: PropTypes.func,
  expandForm: PropTypes.func,
  groupId: PropTypes.array,
  hasChangedItemFormData: PropTypes.bool,
  initialGroupId: PropTypes.string,
  isUpdatingResource: PropTypes.bool,
  itemResponse: PropTypes.instanceOf(Map),
  keyField: PropTypes.instanceOf(List),
  newContacts: PropTypes.instanceOf(List),
  params: PropTypes.shape({
    elementId: PropTypes.string,
    groupId: PropTypes.string,
    slug: PropTypes.string
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string,
    query: PropTypes.shape({
      conditional: PropTypes.string
    })
  }),
  ownEverplan: PropTypes.bool,
  conditionalValue: PropTypes.string,
  responseOwnershipIds: PropTypes.instanceOf(List),
  responseGroup: PropTypes.instanceOf(List),
  responsesGroupedBySecondGroupId: PropTypes.instanceOf(List),
  showExpandedForm: PropTypes.bool,
  readEndpoint: PropTypes.func,
  userConfig: PropTypes.instanceOf(Map),
  viewItemPath: PropTypes.func,
  hasMultipleFieldGroups: PropTypes.bool,
  views: PropTypes.instanceOf(List),
  setHasChangedItemFormData: PropTypes.func,
  listMappings: PropTypes.instanceOf(Map),
  nextBestAction: PropTypes.instanceOf(Map)
}

export default ItemForm
