import extra_storage from './extra_storage'
import {findRawResourceByAttributeId, findRawResourceById} from './plan_data/userData'
/**
 * Modifies the axios config so that api calls can be made to the meta data endpoints, since they are a slightly different url then
 * other endpoints.
 *
 * @param {function} apiCall create/update/deleteResource from 'redux-json-api' library already bound in the component calling this function
 * @param {Map} resource that will be sent
 *
 * @returns {function} which allows the component using this to also call .then() if needed
 */
export const apiV2MetaDataCall = ({apiCall, resource}) => (
  apiCall(
    resource,
    {
      data: {data: resource}, // needed to make sure that the resource is passed correctly on the `delete` call, which does not automatically include the resource in the call
      baseURL: `${API_HOST}/api/v2/metadata`,
      headers: {
        Authorization: `Token token=${extra_storage.getItem('authToken')}`
      }
    }
  )
)

/**
 * @param {Object} versions from global state
 * @param {Object} settings from global state
 *
 * @returns {Map} of the environment's metadata version
 */
export const environmentMetadataVersion = ({versions, settings}) => {
  const setting = findRawResourceByAttributeId({rawResource: settings, attribute: 'name', id: 'metadata_version'})
  return findRawResourceById({rawResource: versions, id: setting.get('value')})
}

/**
 * determines whether to create or update a resource depending on if the resource is present or not
 *
 * @param {function} createResource - the createResource function
 * @param {function} updateResource - the updateResource function
 * @param {Object} attributes - the attributes to send back as part of the api call
 * @param {Map} resource - the resource api record
 * @param {string} type - the title of the endpoint
 * @param {Object} callback - an object of callback functions (success and error functions)
 *
 * @example
 * createOrUpdateResource({
 *    createResource: this.props.createResource,
 *    updateResource: this.props.updateResource,
 *    attributes: {name: 'test', user_id: 2},
 *    resource: Map(),
 *    type: 'user-configs',
 *    callback: {success: successCallback, error: errorCallback}
 * })
 *
 */
export const createOrUpdateResource = ({createResource, updateResource, attributes, resource, type, callback}) => {
  const apiAction = resource.isEmpty() ? createResource : updateResource

  apiAction({
    type,
    id: resource.get('id'),
    attributes
  })
    .then(() => callback.success())
    .catch(() => { if (callback.error) callback.error() })
}
