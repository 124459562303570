import {Map} from 'immutable'

export const SELECTED_OPTION_DESCRIPTIONS = Map({
  // Celebration Options
  'A grand affair with every possible funeral event you can imagine': '',
  'An intimate ceremony with a personal touch': '',
  'I want it as low-key as possible': '',

  // Music Options
  'A Spotify playlist of my favorites': '',
  'Something small or solo (like a harp or organ)': '',
  'A big band': '',
  'Silence is music to my ears': '',

  // Service Options
  'Somewhere spiritual (church, synagogue, temple, mosque)':
    "You might not be charged if you're an active member, though it's typical to give a sizable donation. If you're a non-member, there's usually a rental fee.",
  'Out in nature (mountains, ocean, beach, park)':
    "For a casual gathering, it shouldn't cost you anything. If you want to have a more formal affair, it will probably require a permit.",
  'Modern or unusual (public space,  sporting event, museum)':
    "This probably won't be cheap depending on the location; they may charge extra fees to block off an area.",
  "Somewhere private (a person's home, undisclosed location)":
    'This is the least expensive because you own the space, or someone you know does.',
  "I'm good with a funeral home":
    'This might be the most convenient option, because you have one place managing everything.',

  // Proximity to home
  'Not far at all':
    "Great. This means you'll only need ground transportation, which shouldn't cost you that much.",
  'We got some ground to cover':
    'This can get pricey since it might involve flights or long road trips; it can also be very expensive if you have to transfer between funeral homes.',

  // Disposition Options
  'Yes, I like my family to be able to visit me':
    'Being buried appeals to many since it provides loved ones with a place to visit.',
  "I'm open to it if it's done in a certain way":
    'These days there are many options—including green burial to help preserve the environment.',
  "No, I'd prefer to be cremated":
    'Cremation is becoming a more popular choice due to cost and convenience.',

  // Ashes Options
  'Safely buried in the ground':
    "Just because you choose to cremated, doesn't mean you still can't be buried or stored in a columbarium at a cemetery of your choice.",
  'Scattered at my favorite place on earth':
    'Leaving instructions behind lets your family know if you want to be scattered at sea or at your favorite place (or places) on earth.',
  'Preserved in a nice urn for my family to display':
    "If your family wants your ashes on display they'll want a presentable urn or else you may end up in a box in the attic.",
  'Get creative with them (space, fireworks, reef, diamond)':
    'There are so many creative things to do with the ashes. For example, you can be turned into fireworks, made into jewelry, or even shot into space. It all depends on how big of a bang you want to go out.',

  // Casket Options
  'Any container will do': 'You might not know you can get a cheaper casket from Walmart, Costco, Amazon, and other places, which a funeral home has to use.',
  'A shroud or a casket made of natural materials': "Green caskets are easily biodegradable, don't add toxins to the earth as they decompose, and are often produced in a way that's carbon-neutral. ",
  'Something respectful (like a nice mid-range car)': 'You might not know you can get a cheaper casket from Walmart, Costco, Amazon, and other places, which a funeral home has to use.',
  'Something epic (think King Tut, only bigger!)': "If you have an idea of what you'd like, find some examples and share them with your family.",

  // Headstone Options
  'I want to mark the grave with natural elements':
    "Cemeteries sometimes have restrictions for what can be used so you'd need to check with them before making a final decision.",
  "Something that doesn't stand out too much":
    "Cemeteries sometimes have restrictions for what can be used so you'd need to check with them before making a final decision.",
  'A standard headstone / gravestone is fine with me':
    "Cemeteries sometimes have restrictions for what can be used so you'd need to check with them before making a final decision.",
  'I want people to see it from space':
    "Cemeteries sometimes have restrictions for what can be used so you'd need to check with them before making a final decision.",

  // Planning Options
  'I want to select each item myself (example: casket, venue)':
    "Funerals have lots of different fees, some mandatory, some optional, that can add up quickly. Planning ahead allows you to pick the details you want and avoid the ones you don't.",
  'I just want to set aside some money': '',

  // Prepaid Arrangement Options
  "I've made arrangements with a funeral home":
    "Congratulations! Setting money aside for your final celebration is a great accomplishment. You're ahead of the game. Now, let's make sure someone in your life knows about this so your planning pays off.",
  "I've got a version of Trust / Totten Trust":
    "Congratulations! Setting money aside for your final celebration is a great accomplishment. You're ahead of the game. Now, let's make sure someone in your life knows about this so your planning pays off.",
  'I did as an Insurance Policy':
    "Congratulations! Setting money aside for your final celebration is a great accomplishment. You're ahead of the game. Now, let's make sure someone in your life knows about this so your planning pays off.",
  "I've made other types of arrangements":
    "Congratulations! Setting money aside for your final celebration is a great accomplishment. You're ahead of the game. Now, let's make sure someone in your life knows about this so your planning pays off."
})
