import PropTypes from 'prop-types'
import {List, Map} from 'immutable'
import {useEffect, useState} from 'react'

import Carousel from '../Carousel'
import NextBestActionBox from './NextBestActionBox'
import NextBestActionModalController from './NextBestActionModalController'
import Logger from '../../lib/NewLogger'
import FeatureFlags from '../../lib/FeatureFlags'

// This is slug that the digital subscription NBA Gapfiller is connected to
const VIDEO_STREAMING_ITEM_SLUG = 'digital-world-video-streaming'

const NextBestActions = props => {
  const [currentNextBestAction, setCurrentNextBestAction] = useState(Map())
  const [currentItemResponse, setCurrentItemResponse] = useState(Map())
  const [showModal, setShowModal] = useState(false)

  const logWhenNBAsAreShownOrSuppressed = () => {
    const nextBestActionsCount = props.nextBestActions.size
    const additionalPayload = (
      nextBestActionsCount ?
        {wildcard: nextBestActionsCount} :
        {
          reason: 'no_next_best_actions_remaining',
          wildcard: 'no_next_best_actions_remaining'
        }
    )

    Logger.log({
      name: nextBestActionsCount ? 'viewed_next_best_actions' : 'suppressed_next_best_actions',
      payload: {
        context: props.context,
        next_best_actions_count: nextBestActionsCount,
        ...additionalPayload
      }
    })
  }

  useEffect(() => {
    if (props.context === 'dashboard') logWhenNBAsAreShownOrSuppressed()
  }, [])

  useEffect(() => {
    setCurrentNextBestAction(Map())
  }, [props.location.pathname])

  useEffect(() => {
    // Because the carousel never unmounts when navigating amongst `Category` pages, the logging of whether
    // the user has been shown the NBAs or had them suppressed needs to be repeated if the user is on a
    // `Category` page *and* there is a new `slug` in the URL, which indicates that they have navigated to a
    // different `Category` from the one they were just viewing. It is important to only do this if the user
    // is staying on a `Category` page since we don't want the component to log again when it is about to
    // unmount (e.g., when they go to their address book). --BLR
    if (props.location.pathname.includes('category')) logWhenNBAsAreShownOrSuppressed()
  }, [props.params.slug])

  const logCarouselEvent = ({actionType, direction}) => {
    Logger.log({
      name: 'scrolled_next_best_actions_slider',
      payload: {
        action_type: actionType,
        context: props.context,
        direction,
        next_best_actions_count: props.nextBestActions.size,
        wildcard: actionType
      }
    })
  }

  const onSwipe = direction => {
    logCarouselEvent({
      actionType: 'swipe',
      direction: direction === 'left' ? 'forward' : 'backward'
    })
  }

  const onNextBestActionClick = ({itemResponse, nextBestAction}) => {
    setCurrentItemResponse(itemResponse)
    setCurrentNextBestAction(nextBestAction)
    setShowModal(true)
  }

  const next = () => {
    logCarouselEvent({
      actionType: 'click_navigation_arrow',
      direction: 'forward'
    })
  }

  const previous = () => {
    logCarouselEvent({
      actionType: 'click_navigation_arrow',
      direction: 'backward'
    })
  }

  const nextBestActions = FeatureFlags.isEnabled('digital_subscription') ?
    props.nextBestActions :
    props.nextBestActions.filterNot(
      nba =>
        nba.getIn(['data', 'item', 'item-slug']) === VIDEO_STREAMING_ITEM_SLUG
    )

  const nextBestActionsArray = nextBestActions.toArray().map(nextBestAction => (
    <NextBestActionBox
      nextBestAction={nextBestAction}
      categories={props.categories}
      context={props.context}
      itemResponses={props.itemResponses}
      key={nextBestAction.get('id')}
      pathname={props.location.pathname}
      onNextBestActionClick={onNextBestActionClick}
      profile={props.profile}
      updateResource={props.updateResource}
      userConfig={props.userConfig}
    />
  ))

  return (
    <div>
      <NextBestActionModalController
        {...props}
        itemResponse={currentItemResponse}
        nextBestAction={currentNextBestAction}
        showModal={showModal}
        toggleModal={() => setShowModal(!showModal)}
      />
      {
        !nextBestActions.isEmpty() && (
          <div className='next-best-actions'>
            <Carousel
              className='next-best-action-boxes'
              location={props.location}
              onNextClick={next}
              onPreviousClick={previous}
              onSwipe={onSwipe}
              slides={nextBestActionsArray}
              title='Recommended for you'
            />
          </div>
        )
      }
    </div>
  )
}

NextBestActions.propTypes = {
  nextBestActions: PropTypes.instanceOf(List).isRequired,
  categories: PropTypes.instanceOf(List).isRequired,
  context: PropTypes.string.isRequired,
  itemResponses: PropTypes.instanceOf(List).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string
  }),
  params: PropTypes.shape({
    slug: PropTypes.string
  }),
  profile: PropTypes.instanceOf(Map).isRequired,
  updateResource: PropTypes.func,
  userConfig: PropTypes.instanceOf(Map).isRequired
}

export default NextBestActions
