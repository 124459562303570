import {Map} from 'immutable'
import {PropTypes} from 'prop-types'

import {BoxGutterMedium} from '../../../../blocks/Boxes'
import DependentResponseElement from './DependentResponseElement'

const DependentResponseElements = props => {
  if (props.dependentResponseElements.isEmpty()) {
    return null
  } else {
    return (
      <BoxGutterMedium className='dependent-response-elements'>
        <h3>Required Responses</h3>
        {
          props.dependentResponseElements.valueSeq().map(
            responseElement => (
              <DependentResponseElement
                {...props}
                key={responseElement.get('element-id')}
                responseElement={responseElement}
              />
            )
          )
        }
      </BoxGutterMedium>
    )
  }
}

DependentResponseElements.propTypes = {
  dependentResponseElements: PropTypes.instanceOf(Map)
}

export default DependentResponseElements
