import {Fragment, useCallback} from 'react'
import {List, Map} from 'immutable'
import PropTypes from 'prop-types'

import {Add} from '../../blocks/Buttons'
import {Text} from '../../blocks/Texts'
import CheckIcon from '../../CheckIcon'

import routerUtils from '../../../../../lib/routerUtils'

import './addContactAsDeputy.scss'
import MixpanelLogger from '../../../../../lib/MixpanelLogger'

const AddContactAsDeputy = ({contact, deputies}) => {
  const contactDeputy = deputies.filter(deputy => contact.get('emails', List()).includes(deputy.get('email')))
  const goToNewDeputy = useCallback(() => {
    MixpanelLogger.track(
      'click_add_deputy',
      {
        context: 'address_book',
        deputy_count: deputies.size
      }
    )
    routerUtils.push(`cm/deputies/new/${contact.get('id')}`)
  }, [contact.size, deputies.size])

  return (
    <div className='add-contact-as-deputy'>
      {
        contactDeputy.isEmpty() ?
          (
            <Fragment>
              <Add className='add-deputy' onClick={goToNewDeputy}>Make Deputy</Add>
              <Text className='mobile-add-deputy' onClick={goToNewDeputy}>Make Deputy</Text>
            </Fragment>
          ) :
          (
            <Text>
              <CheckIcon />
              Deputy
            </Text>
          )
      }
    </div>
  )
}

AddContactAsDeputy.propTypes = {
  contact: PropTypes.instanceOf(Map),
  deputies: PropTypes.instanceOf(List)
}

export default AddContactAsDeputy
