import {useState, useEffect} from 'react'
import {Map, List} from 'immutable'
import PropTypes from 'prop-types'

import NextBestActionGapFillerGuide from './gap_fillers/NextBestActionGapFillerGuide'

import routerUtils from '../../lib/routerUtils'
import {useEverplanData} from '../../lib/hooks'
import {itemBySlug} from '../../lib/plan_data/item'

const NextBestActionContainer = props => {
  const {itemResponses} = useEverplanData(props.userConfig.get('everplan-id'))
  const [showModal, setShowModal] = useState(true)
  const nextBestAction = props.nextBestActions.find(currentNextBestAction => currentNextBestAction.getIn(['data', 'item', 'item-slug']) === props.params.itemSlug) || Map()

  const goToCategory = () => routerUtils.push(`cm/category/${props.params.slug}`)

  const modalVisible = () => !nextBestAction.isEmpty() && nextBestAction.getIn(['data', 'type']) === 'gap-filler'

  useEffect(() => {
    if (!modalVisible())
      goToCategory()
  }, []) // if component won't display a modal on mount just send them back, 'cause that means they're no longer supposed to see the next best action

  const onModalClose = () => {
    setShowModal(false)
    goToCategory()
  }

  if (modalVisible()) {
    return (
      <NextBestActionGapFillerGuide
        {...props}
        itemResponse={itemBySlug({items: itemResponses, itemSlug: props.params.itemSlug})}
        showModal={showModal}
        nextBestAction={nextBestAction}
        toggleModal={onModalClose}
        closeModal={onModalClose}
      />
    )
  } else {
    return null
  }
}

NextBestActionContainer.propTypes = {
  nextBestActions: PropTypes.instanceOf(List),
  params: PropTypes.shape({itemSlug: PropTypes.string, slug: PropTypes.string}),
  userConfig: PropTypes.instanceOf(Map)
}

export default NextBestActionContainer
