import {Map} from 'immutable'

const body = `
According to AARP, "close to 90-percent of seniors want to stay in their own homes as they age, often referred to as 'aging in place.'" While there are communities centered around aging in place (sometimes called "naturally occurring retirement community" or NORCs), we'd like to focus on what it takes to reconfigure a home into one that's senior friendly.

In-home care is a good housing option if you'd like to continue to live at home but need some help with daily activities such as cooking, cleaning, using the toilet, bathing, dressing, eating, and managing medications.

Most in-home care is non-medical, meaning those providing assistance are either professional caregivers or certified nursing assistants, rather than registered nurses or physicians. In addition, it's common for family and friends to help out as well.

### Home Modifications For In-Home Care

Depending on the situation and needs, there are certain adjustments or modifications that can be made to make caregiving easier and reduce accidents.

#### General home modifications:

* Widening doorways to accommodate a wheelchair
* Installing railings on stairways
* Installing a stair lift or wheelchair lift on stairways
* Installing ramps
* Replacing knobs with lever handles on doors and faucets

#### Bedroom modifications:

* Relocating a bedroom from the second-floor to the ground-floor
* Replacing a standard bed with a hospital bed

#### Bathroom modifications:

* Installing railings in the bathroom and shower
* Installing a bench or seat in the shower
* Installing a wheelchair-accessible shower stall
* Installing slip-resistant bathroom flooring
* Raising the height of the toilet seat for easier access
* Installing handrails on a toilet

There are a number of companies that offer supplies if you're considering making modifications to accommodate in-home care. For a list of companies that might be helpful to you as you transition to in-home care, use our resource <a href="https://www.everplans.com/tools-and-resources/guide-in-home-care-supply-companies" rel='noreferrer noopener' target='_blank'>Guide: In-Home Care Supply Companies</a>.

There are also a number of products that many seniors living at home commonly require. For a list of these products, see our resource <a href="https://www.everplans.com/tools-and-resources/guide-products-for-seniors-living-at-home" rel='noreferrer noopener' target='_blank'>Guide: Products for Seniors Living at Home</a>.

### Services

There are two main types of in-home care caregivers:

#### Home Care Aides (HCA)

Home Care Aides are people who provide help with household duties such as:

* Housekeeping and laundry
* Grocery shopping and cooking
* Personal care services, such as feeding, walking, bathing, grooming, dressing, and assistance using the toilet
* Transportation to medical appointments

In addition, Home Care Aides may provide companionship to the patient, like reading aloud and having conversations. There is no state or federal certification required to be a Home Care Aide.

### Home Health Aides (HHA)

Home Health Aides have received formal medical training and are usually certified nursing assistants. Home Health Aides may provide basic healthcare services, such as:

* Monitoring pulse, body temperature, and blood pressure
* Managing and administering medications
* Tending to wounds if necessary

In addition, medical professionals such as registered nurses, nurse practitioners, physical therapists, occupational therapists, and physicians may all play a role in in-home care. Many medical professionals make house calls, and can administer to the patient in the home.

### In-Home Care Costs

The cost of in-home care depends on the type of care that the patient is receiving, including the number of Home Care Aides and Home Health Aides that are employed.

For the most part, Medicare, Medicaid, and private insurance will all cover some portion of in-home care services provided by home health agencies. In many cases the patient will need to provide evidence that the treatments and care he or she is receiving are medically necessary. As all insurance plans are different, there may be additional qualifications or criteria that the patient must meet in order to have in-home care covered by insurance.

Many insurance companies will not cover care provided by Home Care Aides alone, as this care may not always qualify as medically necessary.

In the case of home modifications, some insurance plans will cover some of the costs of modifications. The modifications usually covered are those recommended by a doctor and seen by the insurance company as medically necessary. In some cases, the products used for home modification will need to be ordered by the doctor for insurance to cover the cost.

For a more complete list of issues to consider for when deciding on in-home care, use our resource <a href="https://www.everplans.com/tools-and-resources/checklist-questions-to-consider-when-choosing-in-home-care" rel='noreferrer noopener' target='_blank'>Checklist: Questions to Consider When Choosing In-Home Care</a>.
`


export default Map({
  body,
  headline: 'A Complete Overview Of In-Home Care',
  summary: 'In-home care, also called home health care or aging-in-place, brings medical care and treatments right to your doorstep. And living room, kitchen, bathroom...you get the point.',
  query: 'a-complete-overview-of-in-home-care',
  url: 'https://www.everplans.com/articles/a-complete-overview-of-in-home-care'
})
