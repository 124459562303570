import {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {Map, fromJS} from 'immutable'
import PropTypes from 'prop-types'
import {readEndpoint} from 'redux-json-api'

import TfaInfoDisplay from './tfa_info/TfaInfoDisplay'

import {currentUserConfig} from '../../../../lib/userTools'
import {
  findRawResourceById,
  findRawResourceByAttributeId
} from '../../../../lib/plan_data/userData'

import TfaConfigStore from '../../stores/TfaConfigStore'

import './tfa-info.scss'


export const TfaInfo = props => {
  const [tfaConfig, setTfaConfig] = useState(fromJS(TfaConfigStore.getTfaConfig() || {}))
  const {organizationConfig, userConfig, firm} = props
  const loadOrgConfig =
    (firm.isEmpty() || firm.get('organization-id')) &&
    organizationConfig.isEmpty()
  const loadClientDetails = userConfig.get('client') && loadOrgConfig
  const loadFirmUserDetails = userConfig.get('firm-id') && loadOrgConfig

  const onUpdate = () => {
    setTfaConfig(fromJS(TfaConfigStore.getTfaConfig()))
  }

  useEffect(() => {
    if (loadClientDetails) {
      props.readEndpoint(
        `everplans/${userConfig.get(
          'everplan-id'
        )}?include=firm.organization.organization-config`
      )
    }

    if (loadFirmUserDetails) {
      props.readEndpoint(
        `firms/${userConfig.get(
          'firm-id'
        )}?include=organization.organization-config`
      )
    }

    TfaConfigStore.addChangeListener(onUpdate)

    return () => TfaConfigStore.removeChangeListener(onUpdate)
  }, [])

  useEffect(() => {
    props.readEndpoint('user-configs')
  }, [tfaConfig.get('status')])

  return (
    <TfaInfoDisplay
      loading={loadClientDetails || loadFirmUserDetails}
      tfaConfig={tfaConfig}
      organizationConfig={organizationConfig}
    />
  )
}

TfaInfo.propTypes = {
  firm: PropTypes.instanceOf(Map),
  readEndpoint: PropTypes.func,
  organizationConfig: PropTypes.instanceOf(Map),
  userConfig: PropTypes.instanceOf(Map)
}

const mapStateToProps = state => {
  const userConfig = currentUserConfig(state.api)

  const firmOwnership = findRawResourceByAttributeId({
    rawResource: state.api['firm-ownerships'],
    attribute: 'everplan-id',
    id: userConfig.get('everplan-id')
  })

  const firm = findRawResourceById({
    rawResource: state.api.firms,
    id: userConfig.get('firm-id') || firmOwnership.get('ownable-id')
  })

  const organizationConfig = findRawResourceByAttributeId({
    rawResource: state.api['organization-configs'],
    attribute: 'organization-id',
    id: firm.get('organization-id')
  })

  return {
    firm,
    organizationConfig,
    userConfig
  }
}


export default connect(mapStateToProps, {readEndpoint})(TfaInfo)
