import {Component} from 'react'
import {Map, List} from 'immutable'
import {PropTypes} from 'prop-types'

import Form from '../../../../../../../shared_components/forms/v2/Form'
import Fields from '../../../../../../../shared_components/forms/v2/fields/index'
import {BoxGutterMedium} from '../../../../blocks/Boxes'
import {elementLabel} from '../../../../../../../lib/viewTools'

export default class EditViewElementForm extends Component {
  constructor() {
    super()

    this.removeElement = this.removeElement.bind(this)
    this.onUpdate = this.onUpdate.bind(this)
  }

  removeElement() {
    this.props.removeElement(this.props.element)
  }

  onUpdate() {
    if (this.refs.form) {
      this.props.updateViewElement(
        this.props.viewElement
          .merge(this.refs.form.formData().map(data => data.get('value')))
          .set('sort-order', parseFloat(this.refs.form.formData().getIn(['sort-order', 'value']), 10))
      )
    }
  }

  render() {
    const viewElement = this.props.viewElement
    if (this.props.viewElement) {
      return (
        <BoxGutterMedium className='edit-view-element-form'>
          <Form className='core' ref='form' onUpdate={this.onUpdate}>
            <h4>{elementLabel({selectedElements: this.props.selectedElements, element: this.props.element})}</h4>
            <a onClick={this.removeElement}>Remove Field</a>
            <Fields.Number
              data={
                Map({
                  id: 'sort-order',
                  legend: 'Sort Order Number:',
                  name: 'sort-order',
                  value: viewElement.get('sort-order', '')
                })
              }
            />
            <Fields.Text
              data={
                Map({
                  id: 'field-prompt',
                  legend: 'Field Prompt:',
                  name: 'field-prompt',
                  value: viewElement.get('field-prompt')
                })
              }
            />
            <Fields.Text
              data={
                Map({
                  id: 'placeholder',
                  legend: 'Helper Text:',
                  name: 'placeholder',
                  value: viewElement.get('placeholder')
                })
              }
            />
          </Form>
        </BoxGutterMedium>
      )
    } else {
      return null
    }
  }
}

EditViewElementForm.propTypes = {
  element: PropTypes.instanceOf(Map),
  selectedElements: PropTypes.instanceOf(List),
  removeElement: PropTypes.func,
  updateViewElement: PropTypes.func,
  viewElement: PropTypes.instanceOf(Map)
}
