import {Map} from 'immutable'

const body = `
### Step 1: Get The Documents

Obtain your state's Living Will or Advance Health Directive form from our <a href='https://www.everplans.com/articles/state-by-state-advance-directive-forms' rel='noreferrer noopener' target='_blank'>State-by-State Advance Health Care Directive Guide</a>.

### Step 2: Treatment Time

Indicate on the form whether you would like to receive life support treatments at the end of life or whether you would not like to receive life-support treatments at the end of life. You must be of sound mind when you do this or else it won't be legit.

### Step 3: Proxy Up

Part of an Advance Directive is naming a Health Care Proxy (a.k.a. Health Care Power of Attorney, Medical Power of Attorney, Health Care Agent). In short, this is the person who dictates your health decisions when you can't, including what happens to the plug if it comes to that.

### Step 4: Can I Get A Witness?

Once completed, you need to have a witness countersign the document to make it legally binding. The witness can be anyone over the age of 18, but cannot be your Health Care Proxy. [Note: Some states might require the document to be notarized; if it's required in your state it'll say so on the form.]

### Step 5: Let People Know About It

Give a copy to your primary doctor and Health Care Proxy, pin it to your fridge, carry it in your bag, keep it in the glove compartment of your car -- better yet, add it to your Everplan and share it with your Deputies. (Hint, hint...)

### BONUS Step: High Five

You're done! Take a break and relax. You earned it.
`


export default Map({
  body,
  headline: 'Advance Directive Cheat Sheet: Five Tips To Get You Started',
  summary: 'Create a Living Will and Health Care Proxy in five steps.',
  query: 'advance-directive-cheat-sheet-five-tips-to-get-you-started',
  url: 'https://www.everplans.com/articles/advance-directive-cheat-sheet-five-tips-to-get-you-started'
})
