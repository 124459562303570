import {List} from 'immutable'
import PropTypes from 'prop-types'

import {humanizedFirstNamesWithConjunction} from '../../../../../../lib/nameTools'
import {ownersNotReportedDeceased, showReportLink} from '../../../../../../lib/corpMaxDeputyTools'
import {deathReportUrl} from '../../../../../../lib/urlTools'

import Logger from '../../../../../../lib/NewLogger'
import routerUtils from '../../../../../../lib/routerUtils'

const UnlockEverplanLink = props => {
  const onClick = () => {
    Logger.log({
      name: 'clicked_report_user_as_deceased',
      payload: {
        actor: props.actor,
        context: props.context,
        everplan_id: props.everplanId
      }
    })

    routerUtils.push(deathReportUrl({everplanId: props.everplanId, isHousehold: props.isHousehold, deathReports: props.deathReports}))
  }

  if (showReportLink(props)) {
    return (
      <div className='unlock-everplan-link'>
        <a onClick={onClick}>
          Report {humanizedFirstNamesWithConjunction(ownersNotReportedDeceased({deathReports: props.deathReports, ownerships: props.ownerships}), 'or')} as deceased
        </a>
      </div>
    )
  } else {
    return null
  }
}

UnlockEverplanLink.propTypes = {
  actor: PropTypes.string,
  context: PropTypes.string,
  deathReports: PropTypes.instanceOf(List),
  everplanId: PropTypes.number,
  isHousehold: PropTypes.bool,
  ownerships: PropTypes.instanceOf(List)
}

export default UnlockEverplanLink

