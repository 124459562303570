import PropTypes from 'prop-types'
import React from 'react'
import routerUtils from '../../../../lib/routerUtils'
import storePrototype from '../../../../shared_components/StorePrototype'
import SubscriptionActions from '../../actions/SubscriptionActions'

import * as Buttons from '../../../../dashboard/src/components/blocks/Buttons'
import Container from '../../../../lib/Container'
import CreditCardFormController from './CreditCardFormController'
import CreditCardModalCloser from './CreditCardModalCloser'
import Forms from '../../../../shared_components/forms'
import GlobalStyleOverrides from '../../../../shared_components/core/style-overrides/GlobalStyleOverrides'
import Modals from '../../../../shared_components/core/modals/Modals'

Container.registerStore('credit_card_token', storePrototype(SubscriptionActions.Types.DID_UPDATE_CARD))


export default class CreditCardModal extends React.Component {
  constructor() {
    super()
    this.state = {
      processing: false,
      errors: []
    }
    this.store = Container.getStore('credit_card_token')

    this.onUpdate = this.onUpdate.bind(this)
    this.stripeResponseHandler = this.stripeResponseHandler.bind(this)
    this.submitCardToStripe = this.submitCardToStripe.bind(this)
  }
  componentDidMount() { this.store.addChangeListener(this.onUpdate) }
  componentWillUnmount() { this.store.removeChangeListener(this.onUpdate) }
  submitCardToStripe() {
    if (this.refs.creditCardForm.validateForm().length === 0) {
      this.setState({processing: true})
      this.props.sendCardToStripe(this.refs.creditCardForm.formData(), this.stripeResponseHandler)
    }
  }
  onUpdate() {
    const errors = this.store.getState().errors
    if (errors.length) {
      this.setState({
        errors,
        processing: false
      })
    } else {
      this.setState({processing: false})
      routerUtils.goBack()
    }
  }
  stripeResponseHandler(status, response) {
    if (status !== 200) {
      this.setState({
        errors: response.error.message,
        processing: false
      })
    } else if (status === 200) { SubscriptionActions.updateCreditCard({subscriptions: response.id}) }
  }

  render() {
    return (
      <GlobalStyleOverrides>
        <Modals.LinkPromptPopUpModal closerComponent={CreditCardModalCloser} showModal={true}>
          <h2>Add A New Credit Card</h2>
          <CreditCardFormController ref='creditCardForm' />
          <Forms.Errors errors={this.state.errors} />
          <Buttons.Accept
            processing={this.state.processing}
            onClick={this.submitCardToStripe}
            tabIndex={4}
            children='Submit'
          />
        </Modals.LinkPromptPopUpModal>
      </GlobalStyleOverrides>
    )
  }
}

CreditCardModal.propTypes = {
  stripeResponseHandler: PropTypes.func,
  sendCardToStripe: PropTypes.func
}
