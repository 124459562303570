import PropTypes from 'prop-types'

import circleLockIcon from '../../../../../../shared_assets/v2/circleLockIcon.png'
import CircleIconWrapper from './CircleIconWrapper'
import DeathReportStatus from './DeathReportStatus'
import {Text} from '../../../../../../dashboard/src/components/blocks/Texts'
import UnlockEverplanLink from './UnlockEverplanLink'

import {pluralize} from '../../../../../../lib/tools'

import './deferredRead.scss'

const DeferredRead = props => {
  if (!props.ownEverplan && props.itemCount) {
    return (
      <div className='deferred-read flex-child flex-container'>
        <CircleIconWrapper>
          <img alt='Circle Lock Icon' src={circleLockIcon} />
        </CircleIconWrapper>
        <div className='deferred-read-text'>
          <Text className='sections-text'>
            {props.deferredReadItemCount} {pluralize('item', props.deferredReadItemCount)} locked until after death
          </Text>
          <DeathReportStatus {...props} />
          <UnlockEverplanLink {...props} />
        </div>
      </div>
    )
  } else { return null }
}

DeferredRead.propTypes = {
  deferredReadItemCount: PropTypes.number,
  itemCount: PropTypes.number,
  ownEverplan: PropTypes.bool
}

export default DeferredRead
