import {Map} from 'immutable'

const body = `
Pre-planning your funeral arrangements can take some of the logistical and financial stresses off of your family after your death, and ensure that you get the kind of funeral you want. That said, don't feel pressured to plan it down to the last detail. While some have no problem planning their own funeral, it's understandable if you find it too difficult or overwhelming. However, you can still let your family know a few aspects to make it much smoother for everyone involved.

### The Biggest Decision You Should Make: Burial, Cremation Or Donation?

First and foremost: Let your family and loved ones know if you want to be buried or cremated. Or you can put it in your Will or a letter to your family. Just let someone know your definitive answer to avoid any unnecessary stress during an already emotional time. If you don't care, let them know that too.

If you choose burial, you need to purchase a burial plot or a spot in a mausoleum at a cemetery. If you choose cremation, you can decide what you want done with the cremated remains, including burial, scattering, or giving them to friends and family members to be stored in an urn.

#### Pre-Planning: Finding A Funeral Director

Engage the services of a funeral home you trust and like and work with their funeral director to plan your burial or cremation and funeral or memorial service. The funeral director can also help you purchase any goods and services you'll need depending on what you choose.

If you're planning a funeral followed by burial, you will typically work with a funeral director at a funeral home. They will help you with all the arrangements, including working with a cemetery to purchase a plot and orchestrate the burial.

If you are planning a funeral followed by cremation, you will typically work with a funeral director at a funeral home. They will help you connect with a crematory to arrange the cremation.

If you are planning a direct cremation followed by a memorial service or ash scattering, you may be able to work with the crematory directly and don't have to work with a funeral home.

#### How Are You Paying For It?

When making pre-arrangements, it's common to pre-pay for some or all of the products and services you're organizing. There are a number of ways to pay for pre-arrangements, and some may meet your needs better than others.

### Cemetery Arrangements

You can work with a funeral home to find a cemetery or approach a cemetery directly and have a funeral director coordinate the arrangements. Here are a few things you need to consider:

* **Find a cemetery**: Location, religion, and environment are the big factors to consider when choosing a cemetery, as is vacancy (not just for the immediate burial, but for future family members as well).
* **Decide if you want multiple plots**: Are you buying plots for the future as well? If you are, you'll want to find plots or mausoleum spaces that are together.
* **Visit the cemetery**: Take a look at the grounds: Are they well maintained? Inspect the plot you're buying: Is it what you had in mind? Take this opportunity to ask any questions you might have before you sign the paperwork. Someone else can visit the cemetery on your behalf if you cannot visit yourself.

#### A Quick Guide To Cremation and Burial Products

**Casket**: Caskets come in a variety of styles and prices, and the selection of a casket is a personal choice. You can purchase a casket from the funeral home or from an online retailer.

**Burial Vault/Grave Liner**: The cemetery will likely require a burial vault or grave liner, and the funeral home may have a limited selection of vaults and liners, so be aware that you may not have much of a choice for this product.

**Urn**: Cremation urns come in a variety of styles and prices, and the selection of an urn is a personal choice.

**Cremation Casket**: The selection of a cremation casket is a personal choice. You may purchase a casket for the funeral service that is then used for the cremation, though the casket should be made without metal since metal cannot be cremated. If you don't want to buy a casket but you want to use one for the funeral service, you may rent one from the funeral home for the service and use an alternative container for the cremation.

**Tip**: Save all receipts for all funeral-related purchases. These expenses may be deductible on future tax returns.

### Before We Go Any Further...Have You Made Any Arrangements Already?

It may seem strange to ask at this point, but have you made any previous arrangements? You'd probably remember. However, has someone already made these purchases on your behalf? Sometimes family members have plots set aside. Ask around to make sure. If someone did you the kindness of purchasing a plot share those details with your loved ones to avoid confusion and the purchase of another plot.

### What Type of Service Do You Want?

Funerals, graveside services, and memorial services are the most common types, though you can also have a funeral service in your own home.

There are also other events you may want, like wakes, viewings, and visitations. These are sometimes held before the service, and gatherings or receptions are often held after the service. Other things to consider:

*   Choosing a location for the service
*   Deciding who you would like to serve as pallbearers
*   Selecting readings and songs you'd like to have performed at the service

### Personalize Your Funeral or Memorial Service


You may consider identifying desired participants, readings, and even decorations if you wish. Other things to consider:
* **Choose a location**: Funerals are typically held at funeral homes, religious places of worship, chapels at cemeteries, or at gravesites.
* **Choose an officiant**: If you'll be having the service at a religious place of worship, the religious leader there will likely lead the service. Otherwise, anyone you choose can lead the service.
* **Choose participants**: There are many ways for friends and family to participate in a funeral or memorial service. Identify who you'd like to serve as pallbearers and deliver eulogies and other readings. Participants may also sing songs, play music, or offer other tributes.
* **Make a list of guests to invite**: If there are specific people you would like to have invited to the service, write down their names and contact information so that the person managing the service will be sure to invite them.
* **Design other elements of your funeral or memorial service**: Let your family know how you'd like the service to look and feel. Choose flowers, music, and other personal touches.
`


export default Map({
  body,
  headline: 'How To Pre-Plan Your Funeral',
  summary: "Imagine and pre-plan your \"dream funeral,\" even if it's 50 years away, and tell your family what you want.",
  query: 'how-to-pre-plan-your-funeral',
  url: 'https://www.everplans.com/articles/how-to-pre-plan-your-funeral'
})
