import {Map} from 'immutable'
import PropTypes from 'prop-types'

import NextBestActionIcon from '../NextBestActionIcon'
import {Text} from '../../../dashboard/src/components/blocks/Texts'

import {pluralize} from '../../../lib/tools'

import './nextBestActionWizardHeader.scss'

const NextBestActionWizardHeader = ({nextBestAction, userConfig}) => {
  const nextBestActionData = nextBestAction.get('data')
  const nextBestActionDuration = nextBestActionData.get('duration')

  return (
    <header
      className='next-best-action-wizard-header'
      data-testid='next-best-action-wizard-header'>
      <NextBestActionIcon iconName={nextBestActionData.get('icon')} />
      <Text className='next-best-action-wizard-header-text'>
        <h3>{nextBestActionData.get('action-text') || 'Get Started'}</h3>
        {
          userConfig.get('score-enabled') && nextBestActionDuration && (
            <div className='next-best-action-wizard-screen-duration'>
              <span>
                Earn up to {nextBestActionDuration} {pluralize('point', nextBestActionDuration)}
              </span>
            </div>
          )
        }
      </Text>
    </header>
  )
}

NextBestActionWizardHeader.propTypes = {
  nextBestAction: PropTypes.instanceOf(Map),
  userConfig: PropTypes.instanceOf(Map)
}

export default NextBestActionWizardHeader
