import './addressBookContactsHeading.scss'

const AddressBookContactsHeading = () => (
  <div className='address-book-contacts-heading flex-container'>
    <h5 className='flex-child'>Name</h5>
    <h5 className='flex-child'>Appears In</h5>
  </div>
)

export default AddressBookContactsHeading
