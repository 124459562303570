import {Map, List} from 'immutable'
import {useMutation} from '@apollo/client'
import {useEffect, useState} from 'react'
import PropTypes from 'prop-types'

import ManagePermissionsModal from './ManagePermissionsModal'
import {useNotificationContext} from '../../../../../shared_components/notifications/NotificationContext'

import {SPECIFIC_ITEMS_DEPUTIES} from '../../../../../graphql/queries/deputy'
import {permittedDeputies, findSpecificItemDeputies} from '../../../../../lib/deputyPermissionTools'
import Logger from '../../../../../lib/NewLogger'
import {UPDATE_DEPUTIES_PERMISSIONS} from '../../../../../graphql/mutations/deputy'
import managePermissionsIcon from '../../../../../shared_assets/manage-permissions-icon.svg'
import {useDeputyData} from '../../../../../lib/hooks'
import {useScoreLedgerEventLogger} from '../../../../../lib/ScoreLedgerEventLogger'

import './managePermissionsContainer.scss'

const ManagePermissionsContainer = props => {
  const {deputies: allDeputies, specificItemsDeputies} = useDeputyData(props.itemResponse.get('everplan-id'))
  const [showModal, setShowModal] = useState(false)
  const [deputies, setDeputies] = useState(List())
  const [deputiesWithPermission, setDeputiesWithPermission] = useState()
  const [processing, setProcessing] = useState(false)
  const {alwaysNotify, dismissToastNotification} = useNotificationContext()
  const {logWithDelayedNotification} = useScoreLedgerEventLogger(props.userConfig)

  useEffect(() => {
    setDeputies(permittedDeputies({deputies: allDeputies, itemResponse: props.itemResponse, firmConfig: props.firmConfig}))
  }, [allDeputies])

  useEffect(() => {
    setDeputiesWithPermission(findSpecificItemDeputies({specificItemsDeputies, firstGroupId: props.firstGroupId}))
  }, [specificItemsDeputies])

  const [updateDeputiesPermissions] = useMutation(UPDATE_DEPUTIES_PERMISSIONS, {
    awaitRefetchQueries: true,
    refetchQueries: [{query: SPECIFIC_ITEMS_DEPUTIES}]
  })

  const handleNotificationClick = () => {
    setShowModal(true)
    dismissToastNotification()
  }

  const logEvent = name => {
    Logger.log({
      name,
      payload: {
        context: 'deputy_permissions_single_item',
        item: props.itemResponse.get('name')
      }
    })
  }

  const toggleModal = () => {
    if (showModal)
      logEvent('dismissed_deputy_permissions')
    else
      logEvent('clicked_deputy_permissions')

    setShowModal(prevShowModal => !prevShowModal)
  }

  const updatePermissions = updatedDeputyPermissions => {
    setProcessing(true)

    logEvent('saved_deputy_permissions')

    updateDeputiesPermissions({
      variables: {input: updatedDeputyPermissions},
      onCompleted: () => {
        alwaysNotify.shortSuccess(
          <div>
            Permissions for {props.keyField} have been updated.{' '}
            <a onClick={handleNotificationClick}>
              Share with someone else</a>
          </div>
        )
        logWithDelayedNotification.updatedResponseGroupPermissions({item: props.itemResponse.get('header')})
        setProcessing(false)
        setShowModal(false)
      }
    })
  }

  const hasNoPermissions = deputies.isEmpty() || deputiesWithPermission?.isEmpty()

  const modalToggleButton = () => (
    hasNoPermissions ?
      <button className='share-modal-toggle-button core' onClick={toggleModal}>Share</button> :
      <button className='manage-modal-toggle-button' onClick={toggleModal}>
        <img alt='toggle-manage-permissions-modal-button' src={managePermissionsIcon} />
      </button>
  )

  const sharedProps = {
    ...props,
    deputies,
    deputiesWithPermission,
    hasNoPermissions,
    processing,
    toggleModal,
    updatePermissions
  }

  useEffect(() => {
    props.onToggleManagePermissionsModal(showModal)
  }, [showModal])

  return (
    <div className='manage-permissions-container'>
      {deputiesWithPermission && modalToggleButton()}
      {showModal && <ManagePermissionsModal {...sharedProps} />}
    </div>
  )
}

ManagePermissionsContainer.propTypes = {
  itemResponse: PropTypes.instanceOf(Map),
  firmConfig: PropTypes.instanceOf(Map),
  firstGroupId: PropTypes.string,
  keyField: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(List)
  ]),
  onToggleManagePermissionsModal: PropTypes.func,
  userConfig: PropTypes.instanceOf(Map)
}

export default ManagePermissionsContainer
