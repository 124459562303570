// Adding this tool as a proper helper since subscriptionTextTools is component and not tools focused.

export const centToDollars = ({number, decimalPlaces = 2}) =>
  `$${(number / 100).toFixed(decimalPlaces)}`


export const upcomingInvoiceText = ({upcomingInvoicePrice}) =>
  `Your next subscription price will be ${upcomingInvoicePrice}.`

export const upcomingInvoiceTextWithCoupon = ({couponApplied, upcomingInvoicePrice}) => {
  const text = upcomingInvoiceText({upcomingInvoicePrice})

  if (couponApplied && parseInt(upcomingInvoicePrice.replace('$', ''), 10) !== 75)
    return `Your promotion has been applied. ${text}`
  else
    return text
}

export const wholeOrRoundCentsToDollars = ({number}) => {
  const dollars = number / 100

  if (dollars % 1)
    return centToDollars({number})
  else
    return centToDollars({number, decimalPlaces: 0})
}
