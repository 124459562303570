import '../../../auth/src/web/SessionApi' // don't remove this and next line, The TfaStores needs them - TK
import '../../../auth/src/web/TfaApi'

/** @class DashboardWrapper
 *
 * @desc
 * Controller component that wraps all its children in the left-nav version of the consumer Everplans
 * site. Note that this component is expecting to be on a different Express route--`/dashboard/`
 * instead of `/`. This is because there are very-different global styles within the context of the
 * dashboard project that are applied only when the `core` class is present on the `html` tag. This
 * class is applied only in the `dashboard.pug` template so that the new styles do not override the
 * legacy global styles used in the rest of the app.
 */

import {Component} from 'react'
import {connect} from 'react-redux'
import {Map} from 'immutable'
import PropTypes from 'prop-types'

import AppWrapper from './blocks/AppWrapper'

import DashboardMainContentWrapper from './DashboardMainContentWrapper'
import DashboardNav from './nav/DashboardNav'
import FeedbackBanner from './feedback/FeedbackBanner'
import Loader from '../../../shared_components/NewLoader'

import currentUserWrapper from '../../../shared_components/currentUserWrapper'
import Dev from '../../../dev_only'
import {enforceCorrectExpressRoute, isCorrectExpressRoute} from '../../../lib/urlTools'
import extra_storage from '../../../lib/extra_storage'
import {currentUserConfig} from '../../../lib/userTools'

import './dashboardWrapper.scss'

export class DashboardWrapper extends Component {
  constructor(props) {
    super(props)

    this.state = {
      hideMobileHeader: !!extra_storage.getItem('hasDismissedMobileBanner')
    }
    this.dismissMobileHeader = this.dismissMobileHeader.bind(this)
  }

  dismissMobileHeader() {
    extra_storage.setItem('hasDismissedMobileBanner', true)
    this.setState({hideMobileHeader: true})
  }

  /**
   * Ensures all React dashboard routes are always rendered within the context of the correct
   * Express path. For example, it will redirect `/#/dashboard` => `/dashboard/#/dashboard`.
   * Only redirects if the currentUser prop is present to avoid a mistaken redirection of
   * a logged-out user to the 404 page.
   */
  UNSAFE_componentWillMount() { enforceCorrectExpressRoute() }


  /** Ensures the loader is shown if any data is missing or if the Express route being used is wrong. */
  loading() {
    return (
      this.props.loadingCurrentUser ||
      !isCorrectExpressRoute ||
      !this.props.userConfig.get('profile-complete')
    )
  }

  render() {
    return (
      <Loader loading={this.loading()}>
        <Dev.Title title={this.props.currentUser ? this.props.currentUser.id : null} />
        <div className='dashboard-wrapper'>
          <AppWrapper>
            <FeedbackBanner
              currentUser={this.props.currentUser}
              hideMobileHeader={this.state.hideMobileHeader}
              dismissMobileHeader={this.dismissMobileHeader}
            />
            <DashboardNav
              currentUser={this.props.currentUser}
              loadingCurrentUser={this.props.loadingCurrentUser}
              location={this.props.location}
              params={this.props.params}
              router={this.props.router}
            />
            <DashboardMainContentWrapper children={this.props.children} />
          </AppWrapper>
        </div>
      </Loader>
    )
  }
}

const mapStateToProps = state => ({userConfig: currentUserConfig(state.api)})

DashboardWrapper.propTypes = {
  currentUser: PropTypes.shape({
    id: PropTypes.number
  }),
  loadingCurrentUser: PropTypes.bool,
  location: PropTypes.shape({pathname: PropTypes.string}),
  params: PropTypes.object,
  router: PropTypes.object,
  userConfig: PropTypes.instanceOf(Map)
}


export default connect(mapStateToProps)(currentUserWrapper(DashboardWrapper))
