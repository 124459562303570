import {List} from 'immutable'
import {PropTypes} from 'prop-types'

import {BoxGutterMedium} from '../../../../blocks/Boxes'
import SelectedConditionalElement from './SelectedConditionalElement'
import {elementLabel} from '../../../../../../../lib/viewTools'

const SelectedConditionalElements = props => {
  if (props.selectedConditionalElements.isEmpty()) {
    return null
  } else {
    const groupedConditionalElementsByValue = props.selectedConditionalElements.groupBy(element => element.get('list-mapping-value')).entrySeq()

    return (
      <BoxGutterMedium className='selected-conditional-elements'>
        <h3>Selected Conditional Fields</h3>
        {
          groupedConditionalElementsByValue.map(([conditionalValue, conditionalElements]) => (
            <div key={conditionalValue}>
              <h4>
                {elementLabel({selectedElements: props.selectedElements, element: conditionalElements.first()})}
              </h4>
              {
                conditionalElements.map(conditionalElement => (
                  <SelectedConditionalElement
                    {...props}
                    key={conditionalElement.get('id')}
                    conditionalElement={conditionalElement}
                  />
                ))
              }
            </div>
          ))
        }
      </BoxGutterMedium>
    )
  }
}

SelectedConditionalElements.propTypes = {
  selectedConditionalElements: PropTypes.instanceOf(List)
}

export default SelectedConditionalElements
