import classnames from 'classnames'


export const innerDraggableProps = ({isDragDisabled, providedDraggable, snapshot, snapshotDraggable}) => ({
  ref: providedDraggable.innerRef,
  ...providedDraggable.draggableProps,

  // The drag handle icon is meant to indicate draggability but not be the only point that can be grabbed to
  // start dragging. The entire box is draggable so this `div` is handed these props rather than handing them
  // to the drag handle icon.
  ...providedDraggable.dragHandleProps,
  className: classnames(
    'response-groups-draggable flex-container',
    {
      'is-dragging': snapshotDraggable.isDragging,
      'is-drag-disabled': isDragDisabled,
      'other-dragging': snapshot.isDraggingOver && !snapshotDraggable.isDragging
    }
  )
})

export const innerDroppableProps = ({provided, snapshot}) => ({
  ref: provided.innerRef,
  ...provided.droppableProps,
  className: classnames(
    'response-groups-droppable',
    {
      'is-dragging-over': snapshot.isDraggingOver
    }
  )
})
