import PropTypes from 'prop-types'


const AngleRightIcon = ({fill, ...otherProps}) => (
  <svg {...otherProps} className='angle-right-icon' viewBox='0 0 17 30' version='1.1' xmlns='http://www.w3.org/2000/svg'>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <path fill={fill} d='M8.49976301,23.5001525 C7.93700735,23.5001525 7.39804467,23.2786528 7.00856396,22.8861532 L-5.9224025,9.88666736 C-6.71481213,9.09066822 -6.68895019,7.8246696 -5.86498901,7.05867043 C-5.0420623,6.29267127 -3.73189678,6.31767124 -2.9400044,7.11467037 L8.49976301,18.6146579 L19.9395304,7.11467037 C20.7309056,6.31817124 22.0410711,6.29267127 22.864515,7.05867043 C23.6884762,7.8246696 23.7143382,9.09066822 22.9219285,9.88666736 L9.99096207,22.8861532 C9.60148136,23.2786528 9.06251867,23.5001525 8.49976301,23.5001525' transform='translate(8.499763, 15.000153) rotate(-90.000000) translate(-8.499763, -15.000153)'/>
    </g>
  </svg>
)

AngleRightIcon.defaultProps = {
  fill: '#007BC2',
  height: '9',
  width: '5'
}

AngleRightIcon.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string
}


export default AngleRightIcon
