import {fromJS, Map} from 'immutable'
import * as icons from '../../../../../shared_assets/gap_filler_assessment_icons'

export const FINAL_WISHES_INTRO = fromJS({
  celebration: {
    question: 'What kind of celebration do you want?',
    items: [
      {
        label:
          'A grand affair with every possible funeral event you can imagine',
        value:
          'A grand affair with every possible funeral event you can imagine',
        image: icons.celebrationGrand
      },
      {
        label: 'An intimate ceremony with a personal touch',
        value: 'An intimate ceremony with a personal touch',
        image: icons.celebrationIntimate
      },
      {
        label: 'I want it as low-key as possible',
        value: 'I want it as low-key as possible',
        image: icons.celebrationLowkey
      }
    ],
    name: 'celebration',
    'next-screen': 'music',
    'screen-position': 1,
    'number-of-screens': 6
  },
  music: {
    question: 'What kind of music best suits your celebration?',
    items: [
      {
        label: 'A Spotify playlist of my favorites',
        value: 'A Spotify playlist of my favorites',
        image: icons.musicPlaylist
      },
      {
        label: 'Something small or solo (like a harp or organ)',
        value: 'Something small or solo (like a harp or organ)',
        image: icons.musicSolo
      },
      {
        label: 'A big band',
        value: 'A big band',
        image: icons.musicBig
      },
      {
        label: 'Silence is music to my ears',
        value: 'Silence is music to my ears',
        image: icons.musicSilence
      }
    ],
    name: 'music',
    'next-screen': 'service',
    'back-screen': 'celebration',
    'screen-position': 2
  },
  service: {
    question: "What's the ideal setting for your main service?",
    items: [
      {
        label: 'Somewhere spiritual (church, synagogue, temple, mosque)',
        value: 'Somewhere spiritual (church, synagogue, temple, mosque)',
        image: icons.serviceChurch
      },
      {
        label: 'Out in nature (mountains, ocean, beach, park)',
        value: 'Out in nature (mountains, ocean, beach, park)',
        image: icons.serviceOutdoor
      },
      {
        label:
          'Modern or unusual (public space,  sporting event, museum)',
        value:
          'Modern or unusual (public space,  sporting event, museum)',
        image: icons.servicePublic
      },
      {
        label:
          "Somewhere private (a person's home, undisclosed location)",
        value:
          "Somewhere private (a person's home, undisclosed location)",
        image: icons.serviceHome
      },
      {
        label: "I'm good with a funeral home",
        value: "I'm good with a funeral home",
        image: icons.serviceFuneral
      }
    ],
    name: 'service',
    'next-screen': 'proximity-to-home',
    'back-screen': 'music',
    'screen-position': 3
  },
  'proximity-to-home': {
    question: 'How close to home would this take place?',
    items: [
      {
        label: 'Not far at all',
        value: 'Not far at all',
        image: icons.transportationBike
      },
      {
        label: 'We got some ground to cover',
        value: 'We got some ground to cover',
        image: icons.transportationAirplane
      }
    ],
    name: 'proximity-to-home',
    'next-screen': 'disposition',
    'back-screen': 'service',
    'screen-position': 4
  },
  disposition: {
    question: 'Does the thought of being buried give you peace?',
    items: [
      {
        label: 'Yes, I like my family to be able to visit me',
        value: 'Yes, I like my family to be able to visit me',
        image: icons.dispositionBurial
      },
      {
        label: "I'm open to it if it's done in a certain way",
        value: "I'm open to it if it's done in a certain way",
        image: icons.dispositionGreenBurial
      },
      {
        label: "No, I'd prefer to be cremated",
        value: "No, I'd prefer to be cremated",
        image: icons.dispositionCremation
      }
    ],
    name: 'disposition',
    // Shows that the next question is dependent on the option chosen for this question.
    'conditional-branch': true,
    'back-screen': 'proximity-to-home',
    'screen-position': 5
  },
  ashes: {
    question: 'What do you want done with your ashes?',
    items: [
      {
        label: 'Safely buried in the ground',
        value: 'Safely buried in the ground',
        image: icons.ashesUrn
      },
      {
        label: 'Scattered at my favorite place on earth',
        value: 'Scattered at my favorite place on earth',
        image: icons.ashesScattered
      },
      {
        label: 'Preserved in a nice urn for my family to display',
        value: 'Preserved in a nice urn for my family to display',
        image: icons.ashesFamily
      },
      {
        label:
          'Get creative with them (space, fireworks, reef, diamond)',
        value:
          'Get creative with them (space, fireworks, reef, diamond)',
        image: icons.ashesCreative
      }
    ],
    name: 'ashes',
    'back-screen': 'disposition',
    'screen-position': 6
  },
  casket: {
    question: 'What kind of casket would you want?',
    items: [
      {
        label: 'Any container will do',
        value: 'Any container will do',
        image: icons.casketBasic
      },
      {
        label: 'A shroud or a casket made of natural materials',
        value: 'A shroud or a casket made of natural materials',
        image: icons.casketNatural
      },
      {
        label: 'Something respectful (like a nice mid-range car)',
        value: 'Something respectful (like a nice mid-range car)',
        image: icons.casketStandard
      },
      {
        label: 'Something epic (think King Tut, only bigger!)',
        value: 'Something epic (think King Tut, only bigger!)',
        image: icons.casketEpic
      }
    ],
    name: 'casket',
    'next-screen': 'headstone',
    'back-screen': 'disposition',
    'screen-position': 6,
    // This is needed since it elongates the number of sreens.
    'number-of-screens': 7
  },
  headstone: {
    question: 'How big a statement do you want your headstone to be?',
    items: [
      {
        label: 'I want to mark the grave with natural elements',
        value: 'I want to mark the grave with natural elements',
        image: icons.headstoneNatural
      },
      {
        label: "Something that doesn't stand out too much",
        value: "Something that doesn't stand out too much",
        image: icons.headstoneBasic
      },
      {
        label: 'A standard headstone / gravestone is fine with me',
        value: 'A standard headstone / gravestone is fine with me',
        image: icons.headstoneStandard
      },
      {
        label: 'I want people to see it from space',
        value: 'I want people to see it from space',
        image: icons.headstoneEpic
      }
    ],
    name: 'headstone',
    'back-screen': 'casket',
    'screen-position': 7
  }
})

export const CONDITIONAL_KEY_DICTIONARY = Map({
  'Yes, I like my family to be able to visit me': 'casket',
  "I'm open to it if it's done in a certain way": 'casket',
  "No, I'd prefer to be cremated": 'ashes'
})
