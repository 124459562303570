import PropTypes from 'prop-types'
import {Map} from 'immutable'

import {TextGutterMedium} from '../../blocks/Texts'
import UpdateOrRemoveCreditCard from './UpdateOrRemoveCreditCard'

import routerUtils from '../../../../../lib/routerUtils'
import {editCreditCardPath} from '../../../../../lib/urlTools'


const PaymentMethod = ({
  userConfig,
  updateResource,
  cardLast4,
  cardExpirationMonth,
  cardExpirationYear
}) => {
  const updateCreditCard = () => routerUtils.push(editCreditCardPath)

  return (
    <div className='section-container'>
      <h2>Payment Method</h2>
      {!cardLast4 && (
        <div>
          <TextGutterMedium>
            You do not have a credit card on file.
          </TextGutterMedium>
          <a onClick={updateCreditCard}>Add Credit Card</a>
        </div>
      )}
      {cardLast4 && (
        <div>
          <div className='flex-container'>
            <TextGutterMedium className='left-text'>
              Credit card:
            </TextGutterMedium>
            <TextGutterMedium>**** **** **** {cardLast4}</TextGutterMedium>
          </div>
          <div className='flex-container'>
            <TextGutterMedium className='left-text'>
              Expiration date:
            </TextGutterMedium>
            <div>
              <TextGutterMedium>{`${cardExpirationMonth}/${cardExpirationYear}`}</TextGutterMedium>
              <UpdateOrRemoveCreditCard
                userConfig={userConfig}
                updateResource={updateResource}
                updateCreditCard={updateCreditCard}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

PaymentMethod.propTypes = {
  userConfig: PropTypes.instanceOf(Map),
  updateResource: PropTypes.func,
  cardLast4: PropTypes.string,
  cardExpirationMonth: PropTypes.string,
  cardExpirationYear: PropTypes.string
}

export default PaymentMethod
