import {Map} from 'immutable'
import PropTypes from 'prop-types'

import UnorderedList from '../shared_components/UnorderedList'

import {centToDollars} from './planTools'

export const PaymentListText = ({partner, ...props}) => {
  const subscriptionPrice = centToDollars(props.activePlan)
  let listItems = []

  if (partner.suppress_trial) {
    listItems = []
  } else {
    listItems = [
      "Your credit card won't be charged until your trial ends. ",
      "Once your trial ends, you'll be charged for an annual subscription. "
    ]
  }

  return (
    <UnorderedList
      className={listItems.length > 0 ? 'payment-list-text' : 'payment-text'}
      children={
        partner.first_year_additional_fee > 0 ?
          [
            `Your first year of Everplans Premium + ${partner.name} will be $${(subscriptionPrice + (partner.first_year_additional_fee / 100)).toFixed(2)}.`,
            `Your Everplans Premium subscription price will be $${subscriptionPrice}/year after the first year.`,
            'You can cancel your subscription at anytime.'
          ] :
          listItems.concat(
            <ListTextWithPrice
              key={null}
              activePlan={props.activePlan}
              coupon={props.coupon}
              hasPartnerDiscount={props.hasPartnerDiscount}
              partner={partner}
            />
          )
      }
    />
  )
}

PaymentListText.propTypes = {
  activePlan: PropTypes.instanceOf(Map),
  coupon: PropTypes.object,
  hasPartnerDiscount: PropTypes.bool,
  partner: PropTypes.shape({
    first_year_additional_fee: PropTypes.number,
    name: PropTypes.string,
    suppress_trial: PropTypes.bool
  }),
  trialExpired: PropTypes.bool
}

PaymentListText.defaultProps = {partner: {}}

export const subscriptionPriceCalculator = (coupon, plan) => {
  const subscriptionPrice = centToDollars(plan)

  return ` $${coupon.percent_off ? (subscriptionPrice * (1 - (coupon.percent_off / 100))).toFixed(2) : (subscriptionPrice - (coupon.amount_off / 100)).toFixed(2)}`
}

export const ListTextWithPrice = props => {
  const subscriptionPrice = centToDollars(props.activePlan)

  if ($.isEmptyObject(props.coupon)) return <span>Your subscription price will be <strong>${subscriptionPrice}/year</strong>. You can cancel at anytime.</span>

  const discountedPrice = subscriptionPriceCalculator(props.coupon, props.activePlan)
  if (props.hasPartnerDiscount) return <span>Your special offer, provided by <strong>{props.partner.name}</strong>, will be <span className='line-through'>${subscriptionPrice}/year</span><strong>{discountedPrice}/year</strong>.</span>
  return <span>Your subscription price with coupon code <strong>{props.coupon.id}</strong> will be <span className='line-through'>${subscriptionPrice}/year</span><strong>{discountedPrice}/year</strong>.</span>
}

ListTextWithPrice.propTypes = {
  activePlan: PropTypes.instanceOf(Map),
  coupon: PropTypes.object,
  hasPartnerDiscount: PropTypes.bool,
  partner: PropTypes.shape({
    name: PropTypes.string
  })
}

export const CouponAppliedText = props => {
  if (props.partner.suppress_trial) return <span>The cost of your subscription will be<strong>{subscriptionPriceCalculator(props.coupon, props.activePlan)}/year.</strong></span>
  else return <span>The cost of your subscription will be<strong>{subscriptionPriceCalculator(props.coupon, props.activePlan)}/year</strong> after your trial ends.</span>
}

CouponAppliedText.propTypes = {
  activePlan: PropTypes.instanceOf(Map),
  coupon: PropTypes.object,
  partner: PropTypes.shape({
    suppress_trial: PropTypes.bool
  })
}

CouponAppliedText.defaultProps = {partner: {}}

export const CouponApplied = ({callout, coupon, partner, activePlan}) => {
  if ($.isEmptyObject(coupon))
    return <noscript />

  return (
    <div className='coupon-applied'>
      <div><strong>{callout} Applied</strong></div>
      <CouponAppliedText activePlan={activePlan} coupon={coupon} partner={partner} />
    </div>
  )
}

CouponApplied.propTypes = {
  activePlan: PropTypes.instanceOf(Map),
  callout: PropTypes.oneOf(['Coupon', 'Offer']),
  coupon: PropTypes.object,
  partner: PropTypes.object
}
