import {Map, List} from 'immutable'
import PropTypes from 'prop-types'

import {BoxGutterMedium} from '../../blocks/Boxes'
import {findResourceById} from '../../../../../lib/plan_data/userData'
import {monthDayYear} from '../../../../../lib/dateTools'
import UpdateVersionFormController from './version_form/UpdateVersionFormController'

const Version = props => {
  const updateSettingVersion = () => {
    props.updateResource({
      type: 'settings',
      id: props.setting.get('id'),
      attributes: {
        value: props.version.get('id')
      }
    })
  }

  return (
    <BoxGutterMedium className='version'>
      <h4>
        {props.version.get('locked') && <div>Locked</div>}
        {
          !props.environmentVersion.equals(props.version) &&
          <a onClick={updateSettingVersion}>Set as environment version</a>
        }
        <UpdateVersionFormController {...props} />
      </h4>
      <div>
        <strong>Version: </strong>
        {props.version.get('name')}
      </div>
      <div>
        <strong>Created At: </strong>
        {monthDayYear(props.version.get('created-at'))}
      </div>
      <div>
        <strong>Updated At: </strong>
        {monthDayYear(props.version.get('updated-at'))}
      </div>
      <div>
        <strong>Description: </strong>
        {props.version.get('description')}
      </div>
      <div>
        <strong>Copied From: </strong>
        {findResourceById({resourceList: props.versions, id: props.version.get('source-version-id')}).get('name', '')}
      </div>
    </BoxGutterMedium>
  )
}

Version.propTypes = {
  updateResource: PropTypes.func,
  version: PropTypes.instanceOf(Map),
  versions: PropTypes.instanceOf(List),
  environmentVersion: PropTypes.instanceOf(Map),
  setting: PropTypes.instanceOf(Map)
}

export default Version
