import PropTypes from 'prop-types'

import './newProgressBar.scss'


const NewProgressBar = ({step, total}) => (
  <div className='new-progress-bar'>
    <div className='completed-section' style={{width: `${step * 100 / total}%`}} />
  </div>
)

NewProgressBar.propTypes = {
  step: PropTypes.number,
  total: PropTypes.number
}

NewProgressBar.defaultProps = {
  step: 0,
  total: 100
}

export default NewProgressBar
