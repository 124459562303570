import {Fragment} from 'react'
import {List} from 'immutable'
import PropTypes from 'prop-types'

import {ModalHeader} from '../../../../shared_components/core/modals/ModalHeaders'
import {Text} from '../blocks/Texts'
import Closer from '../../../../shared_components/core/closer/Closer'
import Loader from '../../../../shared_components/NewLoader'
import Modals from '../../../../shared_components/core/modals/Modals'
import ViewedItemsByDate from './ViewedItemsByDate'

import {addPossessive} from '../../../../lib/tools'
import {groupByDate} from '../../../../lib/dateTools'
import {humanizedFullName} from '../../../../lib/nameTools'

import './deputyViewingHistory.scss'


const DeputyViewingHistory = props => {
  const deputy = props.deputies.find(deputyData => deputyData.getIn(['ownable', 'id']) === props.params.deputyId)
  const deputyName = humanizedFullName(deputy.get('ownable'))
  const viewedItems = deputy.get('viewed-items')
  const viewedItemsByDates = groupByDate({resourceList: viewedItems, groupBy: 'created-at'})

  return (
    <Modals.PopUpModalLarge className='deputy-viewing-history' showModal closerComponent={() => <Closer closer={props.exit} />}>
      <Loader loading={deputy.isEmpty()}>
        <ModalHeader
          banner='Deputy activity'
          heading={`${addPossessive(deputyName)} viewing history`}
        />
        {
          viewedItems.isEmpty() ?
            <Text>{deputyName} has not viewed any items of your Everplan.</Text> :
            <Fragment>
              {
                viewedItemsByDates.entrySeq().map(
                  ([date, viewedItemsForDate]) => (
                    <div key={date}>
                      <ViewedItemsByDate
                        {...props}
                        date={date}
                        viewedItems={viewedItemsForDate}
                      />
                    </div>
                  )
                )
              }
            </Fragment>
        }
      </Loader>
    </Modals.PopUpModalLarge>
  )
}


DeputyViewingHistory.propTypes = {
  deputies: PropTypes.instanceOf(List),
  exit: PropTypes.func,
  params: PropTypes.object
}

export default DeputyViewingHistory
