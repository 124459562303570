import {Map} from 'immutable'
import PropTypes from 'prop-types'

import {Box} from '@mui/material'

import Button from '../../../../../shared_components/mui_base_components/button/Button'
import routerUtils from '../../../../../lib/routerUtils'
import {corpMaxEverplanPreviewPath} from '../../../../../lib/urlTools'

import Logger from '../../../../../lib/NewLogger'

const AcceptedDeputyFor = props => {
  const onEverplanPreviewClick = () => {
    const everplanId = props.deputyOwnership.get('everplan-id')

    Logger.log({
      name: 'clicked_everplan_preview',
      payload: {
        actor: 'deputy',
        context: 'deputy_dashboard',
        everplan_id: everplanId
      }
    })

    return routerUtils.push(`${corpMaxEverplanPreviewPath(everplanId)}?context=deputy_dashboard`)
  }

  return (
    <Box className='accepted-deputy-for'>
      <div className='everplan-owner-profile'>
        <div className='name-email-container'>
          <h3 className='name'>{props.deputyOwnership.get('everplan-name')}</h3>
          {
            props.deputyOwnership.getIn(['everplan-owner', 'owners']).map(owner => (
              <p
                key={owner.get('id')}
                className='email'>
                {owner.get('email')}
              </p>
            ))
          }
        </div>
        <Button onClick={onEverplanPreviewClick}>
          View plan
        </Button>
      </div>
    </Box>
  )
}

AcceptedDeputyFor.propTypes = {
  deputyOwnership: PropTypes.instanceOf(Map)
}

export default AcceptedDeputyFor
