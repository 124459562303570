import extra_storage from './extra_storage'
import {supportedBrowser} from './tools'


/**
 * checks if user should see the mobile app announcement
 * @returns {Boolean}
 */
export const showMobileAppAnnouncement = ({currentUser}) => {
  const allowedUserCategory = ['consumer_paid', 'consumer_trial', 'partner', 'consumer']
  const household = currentUser.everplan && currentUser.everplan.household
  const hasUsedMobileApp = currentUser.viewed_flags && currentUser.viewed_flags['has_used_mobile_app']

  return (
    !hasUsedMobileApp &&
      !household &&
      allowedUserCategory.includes(currentUser.user_category) &&
      !extra_storage.getItem('hasDismissedMobileBanner')
  )
}

/**
 * Returns a string value depending on the condition that matches
 * which is used to determine with component to render and className name
 * that is to be set
 *
 * @param {object} currentUser
 * @returns {String}
 */
export const displayBanner = ({currentUser, globalAnnouncementText = null}) => {
  if (globalAnnouncementText)
    return 'show-feedback-banner global-announcement'
  else if (!supportedBrowser())
    return 'show-feedback-banner unsupported-browser'
  else if (showMobileAppAnnouncement({currentUser}))
    return 'show-feedback-banner mobile-app-download'
  else
    return null
}
