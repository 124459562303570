import PropTypes from 'prop-types'

import ConfirmationModal from '../../../../../shared_components/mui_base_components/modals/confirmation_modal/ConfirmationModal'
import Logger from '../../../../../lib/NewLogger'

const CancelDeputy = ({exit, hideWarning}) => {
  const cancelAddDeputy = () => {
    Logger.log({
      name: 'canceled_add_deputy',
      payload: {}
    })

    exit()
    hideWarning()
  }

  return (
    <ConfirmationModal
      bodyText='Changes you made will not be saved.'
      confirmButtonText='Close'
      headingText='Close this window?'
      onClose={hideWarning}
      onConfirmation={cancelAddDeputy}
      open={true}
    />
  )
}

CancelDeputy.propTypes = {
  hideWarning: PropTypes.func,
  exit: PropTypes.func
}

export default CancelDeputy
