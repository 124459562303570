import {Map} from 'immutable'
import PropTypes from 'prop-types'

import {Button} from '../../../dashboard/src/components/blocks/Buttons'
import Modals from '../../core/modals/Modals'
import Closer from '../../core/closer/Closer'
import {Text, TextGutterMedium} from '../../../dashboard/src/components/blocks/Texts'
import MixpanelLogger from '../../../lib/MixpanelLogger'
import Logger from '../../../lib/NewLogger'
import {useScoreLedgerEventLogger} from '../../../lib/ScoreLedgerEventLogger'

import './interceptModal.scss'

const InterceptModal = props => {
  const {logWithNotification} = useScoreLedgerEventLogger(props.userConfig)

  const modalCloser = () => <Closer fill='#fff' closer={props.toggleModal} />

  const openCallToActionURL = () => {
    // Suppress the actual tab opening in tests so we can at least test the logging:
    if (!__TEST__)
      window.open(props.callToActionURL, '_blank')

    logWithNotification.clickedActionItemVendor({item: props.itemResponse.get('header')})

    Logger.log({
      name: props.eventName,
      payload: {
        context: props.context,
        item: props.itemResponse.get('name'),
        vendor_url: props.callToActionURL,
        wildcard: props.itemResponse.get('name')
      }
    })

    MixpanelLogger.track('click_gap_filler_modal_continue',
      {type: props.itemResponse.get('name')}
    )

    props.toggleModal()
  }


  return (
    <Modals.ButtonPopUpModalLarge className='intercept-modal' closerComponent={modalCloser} showModal>
      <header>
        {
          props.bannerText &&
            <div className='banner'>{props.bannerText}</div>
        }
        <h1>{props.headingText}</h1>
      </header>
      <article>
        {
          props.bodyText.map(
            paragraph => <TextGutterMedium key={paragraph}>{paragraph}</TextGutterMedium>
          )
        }
        <Button onClick={openCallToActionURL}>
          {props.interceptButtonText}
        </Button>
        {
          props.feedback &&
            <Text className='feedback'>{props.feedback}</Text>
        }
      </article>
    </Modals.ButtonPopUpModalLarge>
  )
}

InterceptModal.propTypes = {
  bannerText: PropTypes.string,
  bodyText: PropTypes.array,
  callToActionURL: PropTypes.string,
  context: PropTypes.string,
  eventName: PropTypes.string,
  feedback: PropTypes.array,
  headingText: PropTypes.array,
  interceptButtonText: PropTypes.string,
  itemResponse: PropTypes.instanceOf(Map),
  toggleModal: PropTypes.func,
  userConfig: PropTypes.instanceOf(Map)
}

export default InterceptModal
