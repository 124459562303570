import {Map} from 'immutable'

const body = `
Your estate is made up of all your money, property, and assets...and it's alive. Not literally, but think of it like a beautiful, low-maintenance house plant. Once purchased and placed in the perfect spot in your home, you just need to provide it a bit of care to keep it from wilting.

Once you have a solid estate plan in place, it needs to be updated when your life changes. Which specific instances call for an edit? Glad you asked.

### A Walk Down The Aisle

Newlyweds, or those getting married again, have a lot on their plate. It's easy for estate planning to get put on the back burner in lieu of wedding and honeymoon planning, setting up a home, maybe having some kids. But it's the perfect time to get a plan in place, whether it's as simple as updating emergency contacts and naming your spouse as a beneficiary for existing health and insurance benefits -- or buying your first Life Insurance policy.

Here's something else: Your money might not automatically go to your spouse when you die, especially if you haven't created or updated your Will. Plus, what if you don't want everything to go to your spouse? We're not here to tell you how to distribute your assets, only letting you know your spouse can be the primary beneficiary or one of many beneficiaries. You just need to specify what you want in the event of your death. (And if your spouse finds out you're giving money or property to a person they don't know or like it might be sooner than you anticipate.)

### Splitting Up

Some people completely (and happily) erase exes from their Will, but you may still choose (or be forced) to leave funds to your former spouse. Maybe it's part of a court settlement, or for child care, or because things ended amicably and you simply want to. Figure out what (if anything) you'd like to bequeath and make the change to prevent any future drama.

A handful of other things you need to update after a divorce:

* Beneficiary designations (examples: Life Insurance, Retirement Accounts)
* Emergency contacts
* Shared passwords
* Power Of Attorney
* Health Care Proxy

### New Additions To The Family

When you have a new baby, everything changes -- including your estate plan. First you need to name guardians in your Will -- or get a Will in place if you haven't already -- and don't forget to include your bundle of cuteness as a beneficiary. If you have more kids, including adoption and/or stepchildren, repeat as necessary.

As your kids transform into full-fledged adults with spending power, it's also important to periodically assess their place in your estate plans to make sure everything is equally distributed or split however you see fit.

The main goal here is to prevent family rifts after you're gone. Don't let your death be an all out sibling war.

### The Departed

Sadly, you may outlive some of the people named in your documents. If your Health Care Proxy, Power Of Attorney, or Executor dies, you need to name new ones or elevate your alternates to primary position and name new alternates. If a beneficiary dies, you should reallocate their inheritance to other living heirs or charities close to your heart. Similarly, if the appointed guardian of your children or special needs adult passes, it's vital to designate a new one.

Along these lines, it's also common for people who recently lost someone close -- or attended a funeral for a family member, friend, or loved one -- to become inspired to get a plan in place before it's too late.

### In Sickness

It's never pleasant to think about, but this is as real as it gets. If you've been diagnosed with a chronic or serious illness, and you're faced with the reality of your own mortality, it's best to start planning now before becoming physically or mentally disabled.

Start with filling out your state's Advance Directive form and decide who you'd like to care for you and make medical decisions on your behalf if you became incapacitated. Then work your way up to the weightier stuff, which includes talking with your doctors about completing a DNR or POLST form if it's available in your state, as well as creating an Ethical Will to make sure your legacy lives on and no longer putting off funeral wishes.

### New Laws & Landscapes

This is when we suggest calling in the pros -- financial advisors and estate attorneys -- since federal and state laws can change at any time and throw your current plan into chaos. If you move to another state, definitely read up on its codes and fill out any documents required for that state. For example, the Advance Directive from your old state might not hold up in the new one.

### Money, Money, Money, Money...MONEY!

A big salary increase or a sudden windfall from something like an inheritance or a great day at the track means a big bank account bump. Perhaps you didn't think it was important to get a plan in place before, but now that you have finances worth protecting a trip to a financial advisor or estate attorney is mandatory. The same applies when you purchase pricey assets like homes or vehicles, or if you find yourself on the winning side of any lucrative businesses ventures.

It's also important to realize that a bigger estate might lead to more people fighting over it, so nip any possible disputes in bud and look into creating a Trust and setting up specific and ironclad asset allocation to your heirs in your Will. Once it's in writing you can rest easy knowing your fortune won't go to waste.
`


export default Map({
  body,
  headline: '7 Life Events That Require An Immediate Estate Plan Refresh',
  summary: "If you've experienced any of the following things, you need to update your estate plan right now.",
  query: '7-life-events-that-require-an-immediate-estate-plan-refresh',
  url: 'https://www.everplans.com/articles/7-life-events-that-require-an-immediate-estate-plan-refresh'
})
