import PropTypes from 'prop-types'

import {Map} from 'immutable'

import ModuleBox from '../../../../shared_components/component_templates/ModuleBox'

import './aboutTheScore.scss'

const AboutTheScore = () => (
  <ModuleBox className='about-the-score'>
    <div className='heading'>About the score</div>
    <div className='section-title'>What does your Score mean?</div>
    <div className='section-body'>Your Score is based on positive planning habits.
        The more things you do, the more points you get. Pretty simple, right?
    </div>
  </ModuleBox>
)


export default AboutTheScore
