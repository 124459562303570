import PropTypes from 'prop-types'
import {Map} from 'immutable'

import Modals from '../../../../core/modals/Modals'
import {Accept} from '../../../../../dashboard/src/components/blocks/Buttons'
import Closer from '../../../../core/closer/Closer'
import DigitalSubscriptionSuccessList from './DigitalSubscriptionSuccessList'

import successCheckmark from '../../../../../shared_assets/v2/success-checkmark.svg'

import './digitalSubscriptionSuccessModal.scss'

const DigitalSubscriptionSuccessModal = props => {
  const closer = () => <Closer onClick={props.toggleGapFillerAssessmentModal} />
  const subscriptionsPerCategory = props.selectedSubscriptions
    .map((data, subscriptionId) => {
      const itemName = data.getIn([`category-${subscriptionId}`, 'value'])

      return Map({
        name: data.getIn([`name-${subscriptionId}`, 'value']),
        'item-name': itemName,
        'category-name': props.itemMap.getIn([itemName, 'category-name']),
        'category-order': props.itemMap.getIn([itemName, 'category-order'])
      })
    })
    .valueSeq()
    .groupBy(data => data.get('category-name'))
    .sortBy(data => data.getIn([0, 'category-order']))

  return (
    <Modals.PopUpModalLarge showModal={true} closerComponent={closer}>
      <div className='digital-subscription-success-modal'>
        <header>
          <img src={successCheckmark} />
          <h3>All Set!</h3>
        </header>
        {subscriptionsPerCategory.keySeq().map(categoryName => (
          <DigitalSubscriptionSuccessList
            key={categoryName}
            subscriptions={subscriptionsPerCategory.get(categoryName)}
            categoryName={categoryName}
          />
        ))}
        <a className='import-another-link' onClick={props.plaidUtils.open}>
          Import from another bank or a credit card
        </a>
        <Accept onClick={props.toggleGapFillerAssessmentModal}>Done</Accept>
      </div>
    </Modals.PopUpModalLarge>
  )
}

DigitalSubscriptionSuccessModal.propTypes = {
  toggleGapFillerAssessmentModal: PropTypes.func,
  selectedSubscriptions: PropTypes.instanceOf(Map),
  itemMap: PropTypes.instanceOf(Map),
  plaidUtils: PropTypes.shape({
    open: PropTypes.func
  })
}

export default DigitalSubscriptionSuccessModal
