import {Component} from 'react'
import PropTypes from 'prop-types'

import {Link} from '../../../../shared_components/Links'

class BannerLink extends Component {
  constructor() {
    super()

    this.onClick = this.onClick.bind(this)
  }

  onClick() {
    if (this.props.onClick)
      this.props.onClick(this.props.browserName)
  }

  render() {
    return (
      <Link href={this.props.href} className='link' rel='noopener noreferrer' target='_blank' onClick={this.onClick}>
        {this.props.children}
      </Link>
    )
  }
}

BannerLink.propTypes = {
  browserName: PropTypes.string,
  onClick: PropTypes.func,
  href: PropTypes.string
}

export default BannerLink
