import {List, Map} from 'immutable'
import PropTypes from 'prop-types'

import ManageSpecificItemPermission from './ManageSpecificItemPermission'

import {findResourceByAttributeId} from '../../../../../lib/plan_data/userData'

export const ManageItemPermissions = props => {
  const responseGroups = props.itemResponse.get('responseGroupMap')

  const itemView = findResourceByAttributeId({resourceList: props.itemViews, id: props.itemResponse.get('item-id'), attribute: 'item-id'})
  const responseGroupOrder = itemView.get('group-ids', responseGroups.keySeq().toList())

  return (
    <div className='manage-item-permissions'>
      {
        responseGroupOrder
          .filter(responseGroupId => responseGroups.has(responseGroupId))
          .map(firstGroupId => (
            <ManageSpecificItemPermission
              {...props}
              key={firstGroupId}
              firstGroupId={firstGroupId}
              responseGroup={responseGroups.get(firstGroupId)}
            />
          ))
      }
    </div>
  )
}

ManageItemPermissions.propTypes = {
  itemResponse: PropTypes.instanceOf(Map),
  itemViews: PropTypes.instanceOf(List)
}

export default ManageItemPermissions
