import routerUtils from '../../../../../lib/routerUtils'
import {Map, List} from 'immutable'
import PropTypes from 'prop-types'

import NewContact from '../shared/NewContact'

const EditAddressBookContact = props => {
  const hideContactModal = () => {
    routerUtils.push('/cm/address-book')
  }

  const contact = props.newContacts.find(
    newContact => newContact.get('id') === props.params.contactId,
    null,
    Map()
  )

  if (contact.isEmpty()) {
    hideContactModal()
    return null
  } else {
    return (
      <NewContact
        context='address_book'
        hideContactModal={hideContactModal}
        hideAdditionalFields={!props.location.query.showAdditionalFields}
        selectedContact={contact}
        params={props.params}
      />
    )
  }
}


EditAddressBookContact.propTypes = {
  newContacts: PropTypes.instanceOf(List),
  params: PropTypes.shape({
    contactId: PropTypes.string
  }),
  location: PropTypes.shape({
    query: PropTypes.shape({
      showAdditionalFields: PropTypes.bool
    })
  })
}


export default EditAddressBookContact
