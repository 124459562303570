import {Component} from 'react'
import {List, Map} from 'immutable'
import PropTypes from 'prop-types'

import ViewElement from './ViewElement'
import Form from '../../../../../../../shared_components/forms/v2/Form'

import {defaultView, dependentResponseElementFromForm} from '../../../../../../../lib/viewTools'
import {
  dereferenceElements,
  allConditionalKitElementsForElement,
  isElementWithConditionalKits,
  rectifyConditionalMaps,
  elementListMappingValue
} from '../../../../../../../lib/plan_data/item'


export default class ElementOptions extends Component {
  constructor(props) {
    super(props)

    this.childElements = dereferenceElements(props.topElement, this.props.kits)

    this.state = {elements: this.childElements}

    this.updateViewElements = this.updateViewElements.bind(this)
    this.onElementSelect = this.onElementSelect.bind(this)
  }

  componentDidMount() { this.updateViewElements() }

  formData() { return this.refs.form ? this.refs.form.formData() : List() }

  updateViewElements() {
    const elements = rectifyConditionalMaps({
      responses: this.formData(),
      parentElement: this.props.topElement,
      childElements: this.childElements,
      kits: this.props.kits,
      listMappings: this.props.listMappings
    })

    this.setState({elements})
  }

  hasExistingDependentResponseElement(element) {
    return this.props.dependentResponseElements.some(dependentElement =>
      dependentElement
        .get('dependent-element-ids')
        .includes(element.get('id'))
    )
  }

  // Setting all the conditional-element-ids an element can have here so that it can be checked when adding other elements
  // to see if they belong to a conditional or not.
  addElementWithConditionalKits(element) {
    const selectedElement = element
      .set('conditional-element-ids',
        allConditionalKitElementsForElement({...this.props, element}).map(elementData => elementData.get('id'))
      )

    this.props.addElement(selectedElement)
  }

  addElement(element) {
    this.props.addElement(element)

    if (!this.hasExistingDependentResponseElement(element)) {
      this.props.addDependentResponseElement(
        dependentResponseElementFromForm({
          ...this.props,
          elements: this.state.elements,
          formData: this.formData(),
          element
        })
      )
    }
  }

  addConditionalElement(element) {
    this.props.addConditionalElement(
      // This adds the associated value for each conditional element - aka How much is your rent? will have a a list-mapping-value of rent,
      // since it belongs to the kit that is associated with the value of rent.
      elementListMappingValue({
        element,
        kits: this.props.kits,
        listMappings: this.props.listMappings
      })
    )
  }

  onElementSelect(element) {
    if (isElementWithConditionalKits({element, listMappings: this.props.listMappings}))
      return this.addElementWithConditionalKits(element)

    if (this.isConditionalElement(element))
      return this.addConditionalElement(element)

    return this.addElement(element)
  }

  hideAddElement(element) {
    return (
      this.props.dependentResponseElements.get(element.get('id')) ||
      this.props.selectedElements
        .concat(this.props.selectedConditionalElements)
        .find(selectedElement => selectedElement.get('id') === element.get('id'))
    )
  }

  // Checks if an element is associated to a selected element that is a conditional
  isConditionalElement(element) {
    return this.props.selectedElements
      .some(selectedElement => selectedElement.get('conditional-element-ids', List()).includes(element.get('id')))
  }


  selectElementText(element) {
    if (this.hideAddElement(element))
      return ''

    if (isElementWithConditionalKits({element, listMappings: this.props.listMappings}))
      return 'Add Conditional'

    if (this.isConditionalElement(element))
      return 'Add Conditional Field'

    return 'Add Field'
  }

  render() {
    return (
      <Form className='core element-options' ref='form' onUpdate={this.updateViewElements}>
        {
          this.state.elements.map(
            element => (
              <ViewElement
                {...this.props}
                view={defaultView(this.props.views)}
                formData={this.formData()}
                onElementSelect={this.onElementSelect}
                selectElementText={this.selectElementText(element)}
                key={element.get('id')}
                element={element}
              />
            )
          )
        }
      </Form>
    )
  }
}

ElementOptions.propTypes = {
  addElement: PropTypes.func,
  addDependentResponseElement: PropTypes.func,
  addConditionalElement: PropTypes.func,
  selectedConditionalElements: PropTypes.func,
  dependentResponseElements: PropTypes.instanceOf(Map),
  kits: PropTypes.instanceOf(Map),
  listMappings: PropTypes.instanceOf(Map),
  topElement: PropTypes.instanceOf(Map),
  views: PropTypes.instanceOf(List),
  selectedElements: PropTypes.instanceOf(List)
}

