import {Component} from 'react'
import PropTypes from 'prop-types'
import {List, Map} from 'immutable'

import {Button} from '../../../blocks/Buttons'
import Closer from '../../../../../../shared_components/core/closer/Closer'
import Modals from '../../../../../../shared_components/core/modals/Modals'
import ViewForm from './ViewForm'

import {apiV2MetaDataCall} from '../../../../../../lib/apiV2Tools'

export default class ViewFormController extends Component {
  constructor() {
    super()

    this.state = {processing: false, showModal: false}

    this.closer = this.closer.bind(this)
    this.submitView = this.submitView.bind(this)
    this.toggleModal = this.toggleModal.bind(this)
  }

  closer() { return <Closer closer={this.toggleModal} /> }

  toggleModal() {
    this.setState({showModal: !this.state.showModal})
  }

  submitView(viewAttributes) {
    this.setState({processing: true})

    const resource = {
      type: 'views',
      attributes: {...viewAttributes, 'version-id': this.props.environmentVersion.get('id')}
    }

    return apiV2MetaDataCall({
      apiCall: this.props.createResource,
      resource
    }).then(() => {
      this.setState({processing: false})
      this.toggleModal()
    })
  }

  render() {
    return (
      <div>
        <Button onClick={this.toggleModal}>Add new wizard</Button>
        <Modals.FullHeightModalLarge showModal={this.state.showModal} closerComponent={this.closer}>
          <ViewForm
            {...this.props}
            processing={this.state.processing}
            submitView={this.submitView}
            toggleModal={this.toggleModal}
            selectedElements={List()}
            selectedConditionalElements={List()}
            dependentResponseElements={Map()}
          />
        </Modals.FullHeightModalLarge>
      </div>
    )
  }
}

ViewFormController.propTypes = {
  createResource: PropTypes.func,
  environmentVersion: PropTypes.instanceOf(Map)
}
