/** @class CorpMaxDeathReportModal
 *
 * @desc
 * Controller component that manages the current user's selection of one or more
 * Everplanners to mark as deceased.
 */

import {Component} from 'react'
import {connect} from 'react-redux'
import {createResource, readEndpoint} from 'redux-json-api'
import {List, Map} from 'immutable'
import PropTypes from 'prop-types'
import {withRouter} from 'react-router'

import {personalPronoun} from '../../../../../lib/pronouns'
import {cleanResponse} from '../../corp_max/redux/apiHelper'
import {currentUserConfig} from '../../../../../lib/userTools'
import {humanizedFullName, humanizedFullNames, humanizedFirstNamesWithConjunction} from '../../../../../lib/nameTools'
import {isCorrectExpressRoute, enforceCorrectExpressRoute} from '../../../../../lib/urlTools'
import {redirectToDeputyPreview} from '../../../../../lib/urlTools'
import * as userDataMunger from '../../../../../lib/plan_data/userData'


import NewLoader from '../../../../../shared_components/NewLoader'
import Closer from '../../../../../shared_components/core/closer/Closer'
import Modals from '../../../../../shared_components/core/modals/Modals'

import routerUtils from '../../../../../lib/routerUtils'
import Logger from '../../../../../lib/NewLogger'

import './corpMaxDeathReportModal.scss'

export class CorpMaxDeathReportModal extends Component {
  constructor() {
    super()

    this.state = {
      ownerToMarkDeceased: Map(),
      processing: false
    }

    this.closeModal = this.closeModal.bind(this)
    this.livingOwnerDetails = this.livingOwnerDetails.bind(this)
    this.displayName = this.displayName.bind(this)
    this.reportModalCloser = this.reportModalCloser.bind(this)
    this.setOwnerToMarkDeceased = this.setOwnerToMarkDeceased.bind(this)
    this.goToStepOne = this.goToStepOne.bind(this)
    this.goToStepTwo = this.goToStepTwo.bind(this)
    this.markAsDeceased = this.markAsDeceased.bind(this)
    this.firstNameOnly = this.firstNameOnly.bind(this)
    this.propsForChildren = this.propsForChildren.bind(this)
  }

  UNSAFE_componentWillMount() { enforceCorrectExpressRoute() }

  componentDidMount() {
    this.props.readEndpoint(`everplans/${this.props.params.everplanId}`)
    this.getDeputyEverplanOwners()
  }

  getDeputyEverplanOwners() {
    this.props.readEndpoint(`users/${this.props.userConfig.get('id')}?include=deputy-ownerships.secondary-everplan-owner`)
  }

  /**
   * Returns the Everplanner being reported as deceased.
   * @returns {object} The object representing the Everplanner that will be marked as deceased.
  */

  livingOwnerDetails() {
    if (this.state.ownerToMarkDeceased.isEmpty())
      return this.props.ownerships.find(owner => !owner.get('deceased'))
    else
      return this.state.ownerToMarkDeceased
  }


  pronoun() {
    const userPronoun = this.props.ownerships.size > 1 ? null : this.livingOwnerDetails().get('pronoun')

    return personalPronoun(userPronoun, 'modifying-possessive')
  }

  firstNameOnly() { return humanizedFirstNamesWithConjunction(this.props.ownerships, 'and') }

  displayForHousehold() { return this.props.userEverplan.get('is-household') }

  /**
   * Determines what name is displayed in `CorpMaxDeathReportModal`'s steps (i.e., StepOne and StepTwo heading).
   *
   * @returns {string} The name of either an individual owner or the Everplan to be displayed in the various
   *   steps of the death reporting process.
  */
  displayName() {
    if (this.displayForHousehold()) {
      const userToMark = this.state.ownerToMarkDeceased.size > 0 ? this.state.ownerToMarkDeceased : this.props.ownerships.find(ownership => !ownership.get('deceased'))
      return humanizedFullName(userToMark)
    } else {
      return humanizedFullNames(this.props.ownerships)
    }
  }

  logEvent() {
    Logger.log({
      name: 'submitted_user_as_deceased',
      payload: {
        actor: 'deputy',
        context: 'everplan_preview',
        everplan_id: this.props.params.everplanId
      }
    })
  }

  /** markAsDeceased */
  markAsDeceased(id) {
    this.setState({processing: true})
    this.logEvent()

    if (id) {
      this.setOwnerToMarkDeceased(id)

      this.buildResourceAndCreateDeathReport(this.owner(id))
    } else {
      const livingOwner = this.livingOwnerDetails()

      this.setState({ownerToMarkDeceased: livingOwner})
      this.buildResourceAndCreateDeathReport(livingOwner)
    }
  }

  buildResourceAndCreateDeathReport(owner) {
    const resource = {
      type: 'death-reports',
      attributes: {
        'death-reportable-type': owner.get('owner-type'),
        'death-reportable-id': owner.get('id')
      }
    }
    this.props.createResource(resource).then(() => {
      this.setState({processing: false})
      this.goToStepTwo()
    })
  }

  /** goToStepOne */
  goToStepOne() { routerUtils.push(`cm/report-death/${this.props.params.everplanId}/1`) }

  /** goToStepTwo */
  goToStepTwo() { routerUtils.push(`cm/report-death/${this.props.params.everplanId}/2`) }

  /** setOwnerToMarkDeceased */
  setOwnerToMarkDeceased(id) {
    if (this.owner(id))
      this.setState({ownerToMarkDeceased: this.owner(id)})
  }

  /** owner */
  owner(id) {
    const splitId = id.split('|')
    return this.props.ownerships.find(
      owner => (owner.get('owner-type') === splitId[0] && owner.get('id') === parseInt(splitId[1], 10))
    )
  }

  /** reportModalCloser */
  reportModalCloser() { return <Closer closer={this.closeModal} /> }

  /** closeModal */
  closeModal() {
    return redirectToDeputyPreview(this.props.userEverplan)
  }

  /** propsForChildren */
  propsForChildren() {
    return {
      ...this.props,
      closeModal: this.closeModal,
      displayName: this.displayName(),
      firstNameOnly: this.firstNameOnly(),
      goToStepOne: this.goToStepOne,
      goToStepTwo: this.goToStepTwo,
      markAsDeceased: this.markAsDeceased,
      ownerToMarkDeceased: this.state.ownerToMarkDeceased,
      processing: this.state.processing,
      pronoun: this.pronoun(),
      setOwnerToMarkDeceased: this.setOwnerToMarkDeceased
    }
  }

  render() {
    if (!isCorrectExpressRoute || !this.props.ownerships || this.props.userEverplan.isEmpty() || !this.props.deputyOwnership) {
      return <NewLoader loading={true} />
    } else {
      return (
        <div className='death-report-modal'>
          <Modals.WrappedPopUpModal showModalOnMount={true} closerComponent={this.reportModalCloser}>
            {routerUtils.childrenWithProps(this.props.children, this.propsForChildren())}
          </Modals.WrappedPopUpModal>
        </div>
      )
    }
  }
}


CorpMaxDeathReportModal.propTypes = {
  closeModal: PropTypes.func,
  deputyOwnership: PropTypes.instanceOf(Map),
  readEndpoint: PropTypes.func,
  createResource: PropTypes.func,
  params: PropTypes.shape({
    everplanId: PropTypes.string
  }),
  ownerships: PropTypes.instanceOf(List),
  userConfig: PropTypes.instanceOf(Map),
  userEverplan: PropTypes.instanceOf(Map)
}


export const mapStateToProps = (state, {params}) => {
  const userConfig = currentUserConfig(state.api)
  const deputyOwnership = userDataMunger.ownershipByDeputy({
    ownerships: cleanResponse(state.api['deputy-ownerships']),
    userConfig,
    everplanId: params.everplanId
  })

  return ({
    userEverplan: userDataMunger.findResourceById({resourceList: cleanResponse(state.api.everplans), id: params.everplanId}),
    ownerships: userDataMunger.everplanOwnerships({everplanPreviewId: params.everplanId, secondaryEverplanOwners: state.api['secondary-everplan-owners'], owners: state.owners}),
    ownEverplan: userDataMunger.isOwnEverplan({everplanPreviewId: params.everplanId, owners: state.owners}),
    deputyOwnership,
    userConfig
  })
}

export default connect(mapStateToProps, {createResource, readEndpoint})(withRouter(CorpMaxDeathReportModal))
