import LegacyActionFactory from '../../../shared_components/LegacyActionFactory'

const SubscriptionActions = new LegacyActionFactory([
  'DID_UPDATE_CARD',
  'DID_APPLY_COUPON',
  'DID_CREATE_SUBSCRIPTION'
])

SubscriptionActions.buildPut('updateCreditCard', 'subscriptions/update_card', {JSONHead: 'subscriptions', successAction: SubscriptionActions.Types.DID_UPDATE_CARD})
SubscriptionActions.buildPost('applyCoupon', 'partners/validate_coupon', {JSONHead: 'partner', successAction: SubscriptionActions.Types.DID_APPLY_COUPON})
SubscriptionActions.buildPost('createSubscription', 'subscriptions', {JSONHead: 'subscriptions', successAction: SubscriptionActions.Types.DID_CREATE_SUBSCRIPTION})

export default SubscriptionActions
