import {fromJS} from 'immutable'

export default fromJS({
  '035aac3f-6d81-429f-ad78-1443b389060b': {
    'item-name': 'Business & Networking',
    name: 'fa01ce61-abec-4bb4-a954-e5d82a712753',
    'payment-method': '93b531dd-4f8b-48e3-a572-d8b4bdf0e3d3',
    amount: 'a3f9359f-39e0-436c-8b7f-57ccfed8609b',
    frequency: 'ea05f270-c5eb-4d4a-806e-551122fdf8c7',
    'auto-pay': '16ac26ca-b47f-4912-b2f1-9de16539210c'
  },
  '7a3e9b22-72b3-4244-95a1-efdad3513f16': {
    'item-name': 'Cloud Storage',
    name: '010cfcc6-4a88-47e8-8908-d9a0888d64b7',
    'payment-method': '8088c124-60d2-4be2-884f-20b02e42ea06',
    amount: '1acb84ee-5251-49c8-a78f-f562985e86f7',
    frequency: '7b43fffa-96c4-4268-b088-56302be2b101',
    'auto-pay': 'daae585f-2dd5-4c9b-bbbe-4f3917a75c28'
  },
  '945e01c5-0752-4182-8a17-6b899b8c6bb8': {
    'item-name': 'Content Subscription',
    name: 'adb4952c-2c2a-4928-beeb-a63e8733ba27',
    'payment-method': '3d386598-a571-4d8c-8588-e13e29e55197',
    amount: '6c8cf6e7-2dbe-4867-b0db-8bfb64d10fe7',
    frequency: '0fc17ca8-8d3a-4d68-974f-ff3d330adf0e',
    'auto-pay': 'fc001441-3b4a-4dad-972b-4743277bfb10'
  },
  '9e394efc-6d13-463d-bd02-57049f1827dd': {
    'item-name': 'Devices',
    name: 'd98b3e1c-ad28-4cd8-943f-9eace0cb53c0',
    'payment-method': '38af9cff-4338-4163-a735-28cc00aae361',
    amount: '1127e324-b7d7-4c3b-989e-57633de7b350',
    frequency: '6d1b7877-d740-4737-8748-6939baa32336',
    'auto-pay': 'da896745-2ede-4fc4-bf74-0c02cc086927'
  },
  'a082fa57-5c7f-4b09-993d-8e463ed63e1d': {
    'item-name': 'Domain & Web Hosting',
    name: 'b0166dbf-0c79-470f-ae36-4301d666b918',
    'payment-method': 'ca250289-b78c-41e6-a1b8-8c2d93d1273b',
    amount: '49b87368-1423-4e40-8d4b-6f0da1ee228c',
    frequency: '2eac72a1-734d-436d-aa97-06f3405f954c',
    'auto-pay': 'ad8d1475-0789-4d31-af49-c4aa846041ab'
  },
  'bf820af4-82b3-4224-b714-a1574da1c451': {
    'item-name': 'Email Accounts',
    name: 'f88b9962-b1b4-421e-be87-2749843625ec',
    'payment-method': '579e2bc4-dec2-4946-b4ce-c3a6056f37c2',
    amount: '611e7b90-046b-4715-8dfa-92f0714a88c3',
    frequency: '80ca2061-83c6-40fd-bd90-1dceed70289c',
    'auto-pay': '64097765-c419-4696-9a49-730a48d7579c'
  },
  '2006a61d-e8a3-4e07-aefe-cbf0a99b2e94': {
    'item-name': 'Gaming',
    name: '910c5b65-be97-4c68-b88a-b12c4e12fef4',
    'payment-method': '7a26be91-7f48-46c6-a6ae-7116bde4499d',
    amount: '115ad98f-fb30-4c16-ae3c-701bffc4cdb6',
    frequency: '610ebe69-a33a-46f9-ac8a-c0a11aeb0541',
    'auto-pay': '68b7c1d7-7b4f-47e8-b79c-330eb4df71d8'
  },
  '808cd88f-b4cf-4c87-8651-3e348458d057': {
    'item-name': 'Money Management',
    name: '98ad0b7e-a214-4d49-90fa-29eae1d3ea32',
    'payment-method': '44a0c9b3-7c2a-42f1-94a9-83f71e4b1d34',
    amount: '767d5db9-1205-4e49-86a1-9263387bd29f',
    frequency: '53dbc926-a7b1-4a14-a82c-f2a9bf38bc43',
    'auto-pay': '79aa7d7b-3dbf-4f11-9fe4-61571b5f4cb1'
  },
  '59b2145b-f2fa-4cd1-9bbe-6876d3bc862c': {
    'item-name': 'Music',
    name: 'a27facdf-15ed-4397-99e9-2329e487adb3',
    'payment-method': '4d798222-6b3e-463d-a133-9f01fc367b3e',
    amount: 'eff8202d-b1d9-42c9-868d-7de99090ed42',
    frequency: '039ca015-d21c-482d-85ad-b8b10190c043',
    'auto-pay': '310c6bd3-73e6-4331-96f8-88edf774655d'
  },
  'a48bb659-55dd-4150-9f63-755328faa6e9': {
    'item-name': 'Other Subscription or Service',
    name: 'd2ec2c1a-a190-4cd6-b72b-79e45e91de4f',
    'payment-method': '5add57d2-4e3e-4970-893a-7fd61a94dafd',
    amount: 'bc389921-1a15-4e51-8673-2a19d88961e8',
    frequency: '2041cd6c-1f10-4816-ae36-7691db9bd013',
    'auto-pay': '9c56e84c-63bd-4be8-87ae-6624ccce0b35'
  },
  '2c2deaa7-8630-4ed0-8750-445c2c8f776b': {
    'item-name': 'Password Management',
    name: 'a45e8d5e-26bf-4fa3-8e92-ef25207f4d46',
    'payment-method': 'a5b36a7b-ac1e-4289-98f5-3b6d669f488d',
    amount: 'b073395d-db89-4b34-b610-4363578331f7',
    frequency: 'a0ba46a8-7b9c-49e8-9fee-6f9ad612dad6',
    'auto-pay': '6874b20e-e8e4-4e16-9a15-9a6645a1776c',
    'top-element': {
      id: '13046c88-bf9d-4daf-8fc3-a36557f491fd',
      value: 'Password manager'
    }
  },
  '849ad9cf-c967-4ae0-a7d7-490268307efe': {
    'item-name': 'Payment',
    name: 'a357b771-9f0f-4eb6-9a05-bfb3f7d4585f',
    'payment-method': 'f4e291d3-07e7-4ed1-8f22-9d7edef58739',
    amount: '64ce36fa-c6b5-41cd-88bd-4d332e247e66',
    frequency: '5a651fcc-75db-409c-8dec-985f219fcef8',
    'auto-pay': '8fd6da27-e79e-481f-b44d-7ac63b846403'
  },
  'c9f1ea74-7af2-4a50-9c4f-e02e542017c9': {
    'item-name': 'Shopping/eCommerce',
    name: '0a2e8113-bc63-4504-ad42-1e8f54d3d20e',
    'payment-method': '3afcbd5b-f80e-436b-8067-786898f1000f',
    amount: '1cfcceb5-2ae8-4629-afe5-bdae93ce7d2b',
    frequency: '5fe2543a-3766-478f-a786-78bd80c0d696',
    'auto-pay': '10fa83b5-36e7-4735-bcea-bd34d1a8ff0e'
  },
  'ce2ef9e4-8947-4cdf-9bfa-e926efe70f0a': {
    'item-name': 'Social Media',
    name: '87dfe2c3-38b8-4817-a860-1d1b875e1084',
    'payment-method': 'ab49e2bb-dbd7-41a9-84fc-26ce07b120d8',
    amount: '56c33b21-a810-4ea4-8e9f-5fe41c835a97',
    frequency: 'ff4edaaf-7754-4bcd-a862-294a26586b4c',
    'auto-pay': 'a1ec03ae-78fc-4dd0-8743-7915dff166d1'
  },
  '4aec7174-807a-4622-99c7-19c60deb3887': {
    'item-name': 'Software & App Licenses',
    name: '80118aea-9eca-478d-bbb5-0a168480fcde',
    'payment-method': '9f9e91d9-21fa-4a95-a9e9-b9c4b18abf88',
    amount: '0ec665af-7ec8-4177-b58e-d5f47cc6e82b',
    frequency: 'a0837fe1-39a0-4d37-9340-1ea2035d081e',
    'auto-pay': '6baf2545-8eb3-45f0-9e11-6a7fd9737631'
  },
  '4319ac04-279b-4eb3-a377-eab71db547fc': {
    'item-name': 'Ticketing',
    name: '3f22ba19-398d-4798-b498-958099bfbb83',
    'payment-method': '777f6a60-3e11-4a45-8a03-a5e2ec3bd744',
    amount: 'ff5cf51f-7b99-45e4-ad44-d37e744a9f3b',
    frequency: '07fd26f2-9afd-4d4a-9160-5dfdf2f41226',
    'auto-pay': '36a78566-2c44-4062-bb07-d091d68a1bb4'
  },
  'cefeddd5-f68b-4875-b8fc-d0cbadccb9e4': {
    'item-name': 'Travel & Transportation',
    name: '56c7f97a-a91b-4b06-a01e-9a5303623675',
    'payment-method': 'c80229b3-ae8e-4b20-a9a5-4f6ca04bf4a0',
    amount: 'f87db32a-c64f-4dd8-b965-b1d0f4eebb45',
    frequency: '3299e23f-65f2-4d8f-b5ed-70a5dec08a5a',
    'auto-pay': '48e00569-5010-4e88-804b-d93fb384d06b'
  },
  'acbd9bba-25a9-4b1a-9732-386fa7915af3': {
    'item-name': 'Video Conferencing & Messaging',
    name: 'fe72a850-2ba2-45d6-b539-cb80770870f8',
    'payment-method': '6eb2a439-b409-46c4-a454-eec8d75b3a36',
    amount: 'a23e9603-0447-4546-9c6e-14c9450f472d',
    frequency: '3dcc58e9-9217-47c4-91e3-d960cc35cb03',
    'auto-pay': '3be6957f-685f-4ce1-aa5c-c606bd6c07df'
  },
  '80ae69cb-e38d-4836-9aa0-bbd5001d56d4': {
    'item-name': 'Video Streaming',
    name: 'ad4d7de3-80c0-4dfa-a505-77702c8389bc',
    'payment-method': '31ce4536-b08e-4088-8656-717646ae6327',
    amount: 'd43235f4-e8d4-457f-8212-02e980942f27',
    frequency: '8c2ab049-282b-46cb-bca0-fc98f766c3a5',
    'auto-pay': 'c064b930-32dc-4d77-b14c-c96e5555e4e2'
  },
  'eaf5b564-4b83-48fc-9789-9e33bc9be3f8': {
    'item-name': 'Charities & Causes',
    name: 'b4e9e930-255c-4a66-a490-52dbb4c69d14',
    'payment-method': 'd0d7be4e-fce7-4ea8-aea4-224e24be1332',
    amount: 'bfae76c3-87d1-4b98-910e-1ae11772b03a',
    frequency: '45f051ba-b16d-4022-b3f5-29312a01cca3',
    'auto-pay': '2bfec258-bdd7-4d22-b573-fa3736f9f16a'
  },
  'ee653b32-5f3d-48de-b2c0-3d0ac67c62e0': {
    'item-name': 'Fitness & Wellness',
    name: '67b9eebe-6df4-477c-9dd0-4f4a5bfdc0ec',
    'payment-method': '6d43d2e8-d830-44b6-bbf9-bc2201fffc9b',
    amount: 'd610e8bb-474f-4946-aa6a-3f0488c27415',
    frequency: 'c97d7acd-0995-4ae9-8804-257a066ee346',
    'auto-pay': '3a9b9a2e-fb43-424b-90aa-50bb86f7626d'
  },
  '37cebfb6-3ada-4e82-9bdb-3317c16cf7c2': {
    'item-name': 'Storage Facilities',
    name: '8007d319-d588-4bda-80e0-051ee09a52ee',
    'payment-method': 'ef3688da-d22f-43d6-9b7a-11bfd848178f',
    amount: 'e5e5ef2f-e0cb-4bee-9819-fdc19375f8d7',
    frequency: '0a80360d-9db9-4867-9ff3-ebd2f6482275',
    'auto-pay': '5c807dad-3a45-47f1-9dc2-6c97765a9438'
  },
  '999c0917-88df-46a1-94b8-ccf6dd7d9b39': {
    'item-name': 'Medications',
    name: 'fcf3acc6-ecb9-4a81-beeb-849ff9e8f7ec',
    'payment-method': 'a2cea33e-6706-4318-8a2e-4e2e271cd06d',
    amount: '3b6c9f49-a94a-4be3-b5b7-e0a218fd9a7b',
    frequency: 'fe3ddf55-13d9-4302-a706-00913bf0b7fc',
    'auto-pay': 'a70d2aa0-ac1e-43e5-8c6e-687166f0d1dd'
  }
})

