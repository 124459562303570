import {useMemo} from 'react'
import {Map, List} from 'immutable'
import PropTypes from 'prop-types'

import {Box} from '../../../dashboard/src/components/blocks/Boxes'
import {Button} from '../../../dashboard/src/components/blocks/Buttons'
import InterceptModal from './InterceptModal'
import MixpanelLogger from '../../../lib/MixpanelLogger'
import Logger from '../../../lib/NewLogger'
import NextBestActionIcon from '../NextBestActionIcon'
import {useScoreLedgerEventLogger} from '../../../lib/ScoreLedgerEventLogger'

import CALL_TO_ACTION_CONFIGURATIONS from './configurations/gapFillerCallToActionConfigurations'
import {GAP_FILLERS_WITH_CONTENT_DOWNLOADS, gapFillerContentDownloadsBySlug} from '../../../dashboard/src/lib/nextBestActionTools'
import {downloadWithAnchorTag} from '../../../lib/fileDownload'
import US_STATES from '../../forms/v2/lib/states.json'

import './gapFillerCallToAction.scss'


const GapFillerCallToAction = props => {
  const {logWithNotification} = useScoreLedgerEventLogger(props.userConfig)

  const itemSlug = useMemo(() => props.nextBestAction.getIn(['data', 'item', 'item-slug']), [props.nextBestAction])

  // No need to check anything else, because the gap filler won't even show as an NBA if it
  // is a gap-filler with content downloads and the user doesn't have any content to download.
  const hasContentDownloads = useMemo(() => GAP_FILLERS_WITH_CONTENT_DOWNLOADS.includes(itemSlug), [itemSlug])
  const callToActionConfiguration = CALL_TO_ACTION_CONFIGURATIONS.get(itemSlug)


  const onClick = () => {
    const {context, gapFillerContentDownloads, itemResponse, toggleInterceptModal} = props

    if (hasContentDownloads) {
      logWithNotification.downloadedActionItemForm({item: itemResponse.get('header')})

      gapFillerContentDownloadsBySlug({gapFillerContentDownloads, slug: itemSlug})
        .map(gapFillerContentDownload => downloadWithAnchorTag({
          fileName: gapFillerContentDownload.get('filename'),
          fileUrl: gapFillerContentDownload.get('url')
        }))
    } else if (!callToActionConfiguration.get('intercept', Map()).isEmpty()) {
      toggleInterceptModal()
    } else {
      props.toggleGapFillerAssessmentModal()
    }

    Logger.log({
      name: hasContentDownloads ? 'downloaded_gap_filler_forms' : 'opened_gap_filler_interstitial',
      payload: {
        context,
        item: itemResponse.get('name'),
        wildcard: itemResponse.get('name')
      }
    })

    MixpanelLogger.track('click_gap_filler_guide_cta',
      {type: itemResponse.get('name')}
    )
  }

  const headerText = () => {
    const configHeader = callToActionConfiguration.get('header')

    if (hasContentDownloads) {
      const profileState = US_STATES.find(
        usState => usState.get('value') === props.profile.get('state')
      )

      return `${configHeader} ${profileState.get('label')}`
    } else {
      return configHeader
    }
  }

  /**
   * The `gap-filler-call-to-action-wrapper` is needed here so that on larger devices the actual
   * `gap-filler-call-to-action` can act as a fixed-position element and follow the scrolling of the
   * modal as the user reads more of the guide content. Because the modal is a relatively-positioned
   * element the only approach that I could find that worked is to put use an absolutely-positioned
   * wrapper with a fixed-position child so that the fixed-position child is fixed with respect to
   * the absolutely-positioned parent. --BLR
   */

  return (
    <div className='gap-filler-call-to-action-wrapper'>
      <Box className='gap-filler-call-to-action flex-child flex-container'>
        <NextBestActionIcon iconName={props.nextBestAction.getIn(['data', 'icon'])} />
        <h2>{headerText()}</h2>
        <p className='tight'>
          {callToActionConfiguration.get('prompt')}
        </p>
        <Button onClick={onClick}>
          {callToActionConfiguration.get('action-text')}
        </Button>
        {
          props.showInterceptModal &&
            (
              <InterceptModal
                {...callToActionConfiguration.get('intercept').toJS()}
                userConfig={props.userConfig}
                context={props.context}
                itemResponse={props.itemResponse}
                toggleModal={props.toggleInterceptModal}
              />
            )
        }
      </Box>
    </div>
  )
}

GapFillerCallToAction.propTypes = {
  context: PropTypes.string,
  gapFillerContentDownloads: PropTypes.instanceOf(List),
  itemResponse: PropTypes.instanceOf(Map),
  nextBestAction: PropTypes.instanceOf(Map),
  profile: PropTypes.instanceOf(Map),
  showInterceptModal: PropTypes.bool,
  toggleInterceptModal: PropTypes.func,
  toggleGapFillerAssessmentModal: PropTypes.func,
  userConfig: PropTypes.instanceOf(Map)
}

export default GapFillerCallToAction
