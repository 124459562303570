/** @class PreviewSideNavContainer
 *
 * @desc
 * Container component broken out mainly for styling purposes that simply
 * renders the actual PreviewSideNav.
 */

import PreviewSideNav from './PreviewSideNav'

import './previewSideNavContainer.scss'


const PreviewSideNavContainer = props => (
  <div className='preview-side-nav-container flex-child'>
    <PreviewSideNav {...props} />
  </div>
)

export default PreviewSideNavContainer
