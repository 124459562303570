import {Component} from 'react'
import PropTypes from 'prop-types'
import {List, Map} from 'immutable'

import UpdateNextBestActionController from './next_best_action_form/UpdateNextBestActionController'
import {BoxGutterLarge} from '../../blocks/Boxes'
import {Remove} from '../../blocks/Buttons'
import NextBestActionFullItemFields from './NextBestActionFullItemFields'
import NextBestActionWizardFields from './NextBestActionWizardFields'
import {apiV2MetaDataCall} from '../../../../../lib/apiV2Tools'
import MarkdownText from '../../../../../pro/src/components/shared_firm/settings/MarkdownText'

import './nextBestAction.scss'

export default class NextBestAction extends Component {
  constructor() {
    super()

    this.deleteNextBestAction = this.deleteNextBestAction.bind(this)
  }

  shouldComponentUpdate(nextProps) {
    return !nextProps.nextBestAction.equals(this.props.nextBestAction)
  }

  deleteNextBestAction() {
    const resource = {
      id: this.props.nextBestAction.get('id'),
      type: 'next-best-actions',
      attributes: this.props.nextBestAction
    }

    apiV2MetaDataCall({apiCall: this.props.deleteResource, resource})
  }

  render() {
    const nextBestActionData = this.props.nextBestAction.get('data')
    return (
      <BoxGutterLarge className='next-best-action'>
        <div>
          <strong>Type: </strong>
          {nextBestActionData.get('type')}
        </div>
        <NextBestActionFullItemFields {...this.props} nextBestAction={nextBestActionData} />
        <NextBestActionWizardFields {...this.props} nextBestAction={nextBestActionData} />
        <div>
          <strong>Icon: </strong>
          {nextBestActionData.get('icon')}
        </div>
        <div>
          <strong>Prompt: </strong>
          {nextBestActionData.get('prompt')}
        </div>
        <div>
          <strong>Call to action text: </strong>
          {nextBestActionData.get('action-text')}
        </div>
        <div>
          <strong>Duration: </strong>
          {nextBestActionData.get('duration')}
        </div>
        <div>
          <strong>Sort Order: </strong>
          {nextBestActionData.get('sort-order')}
        </div>
        <div>
          <strong>Gather Text:</strong>
          <MarkdownText displayText={nextBestActionData.get('gather-text', '')} />
        </div>
        <Remove onClick={this.deleteNextBestAction} className='delete-next-best-action'>
          Delete Next Best Action
        </Remove>
        <UpdateNextBestActionController {...this.props} />
      </BoxGutterLarge>
    )
  }
}

NextBestAction.propTypes = {
  categories: PropTypes.instanceOf(List),
  deleteResource: PropTypes.func,
  items: PropTypes.instanceOf(List),
  kits: PropTypes.instanceOf(Map),
  nextBestAction: PropTypes.instanceOf(Map)
}
