import {Component, Fragment} from 'react'
import {List, Map} from 'immutable'
import PropTypes from 'prop-types'

import Modals from '../../../../../shared_components/core/modals/Modals'
import Loader from '../../../../../shared_components/NewLoader'
import Closer from '../../../../../shared_components/core/closer/Closer'
import ConfirmationModal from '../../../../../shared_components/mui_base_components/modals/confirmation_modal/ConfirmationModal'
import {ModalHeaderWithChildren} from '../../../../../shared_components/core/modals/ModalHeaders'
import Form from '../../../../../shared_components/forms/v2/Form'
import Fields from '../../../../../shared_components/forms/v2/fields/index'
import UnorderedList from '../../../../../shared_components/UnorderedList'
import {Button} from '../../blocks/Buttons'

import './accountSelectionModal.scss'

export default class AccountSelectionModal extends Component {
  constructor() {
    super()

    this.state = {
      showConfirmationModal: false,
      errors: List(),
      processing: false
    }

    this.modalCloser = this.modalCloser.bind(this)
    this.toggleConfirmationModal = this.toggleConfirmationModal.bind(this)
    this.submitAccount = this.submitAccount.bind(this)
  }

  toggleConfirmationModal() {
    this.setState({showConfirmationModal: !this.state.showConfirmationModal})
  }

  modalCloser() {
    return (
      <div>
        <Closer closer={this.toggleConfirmationModal} data-testid='account-selection-modal-closer' />
        <ConfirmationModal
          confirmButtonText='Discard'
          headingText='Discard imported account information?'
          onClose={this.toggleConfirmationModal}
          onConfirmation={this.props.closeModal}
          open={this.state.showConfirmationModal}
        />
      </div>
    )
  }

  submitAccount() {
    this.setState({processing: true})
    const selectedAccountValues = this.refs.form.formData().getIn(['accounts', 'value']) || List()

    if (selectedAccountValues.isEmpty()) {
      this.setState({
        errors: List(['Please select at least one account to continue.']),
        processing: false
      })
    } else {
      const selectedAccounts = this.props.accounts.filter(
        account => selectedAccountValues.includes(account.get('account_id'))
      )

      this.props.submitAccount(selectedAccounts)
    }
  }

  render() {
    const accounts = this.props.accounts.map(
      account => Map({
        label: account.get('name'),
        value: account.get('account_id')
      })
    )

    return (
      <Modals.PopUpModalLarge className='core modal account-selection-modal forms-playground' closerComponent={this.modalCloser} showModal>
        {
          accounts.isEmpty() ?
            <Loader /> :
            (
              <Fragment>
                <ModalHeaderWithChildren>
                  <h2>Which of these accounts do you want to import?</h2>
                  <UnorderedList
                    children={
                      [
                        "We'll import the account name, type of account, and account number.",
                        'Some institutions may only send the last 4 digits of your account number.',
                        'Your balance will not be imported.',
                        'This is a one-time import. Everplans will not store your login credentials or maintain a connection with the institution.'
                      ]
                    }
                  />
                </ModalHeaderWithChildren>
                <Form className='core' ref='form'>
                  <Fields.CheckboxList
                    data={
                      Map({
                        id: 'accounts',
                        items: accounts,
                        name: 'accounts',
                        // Ensure the checkboxes are all checked by default:
                        value: accounts.map(account => account.get('value'))
                      })
                    }
                  />
                  <Fields.FieldErrors errors={this.state.errors} />
                </Form>
                <Button
                  onClick={this.submitAccount}
                  processing={this.state.processing}>
                  Import account info
                </Button>
              </Fragment>
            )
        }
      </Modals.PopUpModalLarge>
    )
  }
}

AccountSelectionModal.propTypes = {
  accounts: PropTypes.instanceOf(List),
  closeModal: PropTypes.func,
  submitAccount: PropTypes.func
}
