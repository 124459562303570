import Loader from '../../../../shared_components/NewLoader'
import PageTitle from '../../../../shared_components/PageTitle'
import currentUserWrapper from '../../../../shared_components/currentUserWrapper'
import routerUtils from '../../../../lib/routerUtils'
import TfaSettings from '../../../../auth/src/components/tfa/TfaSettings'
import TfaInfo from '../../../../auth/src/components/tfa/TfaInfo'
import TfaSetup from '../../../../auth/src/components/tfa/TfaSetup'
import {accountSettingsPath, redirectCorpMaxTfa} from '../../../../lib/urlTools'

import './tfaSetting.scss'

export class TfaSetting extends TfaSettings {
  constructor(props) {
    super(props)
    redirectCorpMaxTfa()

    this.goBack = this.goBack.bind(this)
  }

  goBack(event) {
    if (event) event.preventDefault()
    routerUtils.push(accountSettingsPath)
  }

  render() {
    return (
      // added the auth class so we can reuse the styling on the existing Tfa page - TK
      <div className='auth'>
        <PageTitle title='Two-Step Verification Settings' />
        <Loader loading={this.state.loading}>
          <div className='tfa-settings dashboard-tfa-settings'>
            {
              this.isTfaEnabled() && !this.state.wizardActive ?
                <TfaInfo /> :
                <TfaSetup
                  exitTfa={this.goBack}
                  onboarding={false}
                  tfaConfig={this.state.tfaConfig}
                  wizardActive={this.state.wizardActive}
                />
            }
          </div>
        </Loader>
      </div>
    )
  }
}

export default currentUserWrapper(TfaSetting)
