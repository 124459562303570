import {Component} from 'react'
import {Map} from 'immutable'
import PropTypes from 'prop-types'

import {BoxGutterMedium} from '../../../../blocks/Boxes'
import ElementOptions from './ElementOptions'
import SelectItemForm from './SelectItemForm'
import SelectedView from './SelectedView'

export default class AddViewElements extends Component {
  constructor() {
    super()

    this.state = {topElement: Map()}

    this.setTopElement = this.setTopElement.bind(this)
  }

  setTopElement(topElement) { this.setState({topElement}) }

  render() {
    if (this.props.showEditViewForm) {
      return null
    } else {
      return (
        <div className='add-view-elements'>
          <h2>Select Wizard Fields</h2>
          <div className='selections'>
            <BoxGutterMedium className='view-options'>
              <h3>Select Field Options</h3>
              <SelectItemForm {...this.props} setTopElement={this.setTopElement} />
              {
                !this.state.topElement.isEmpty() && (
                  <ElementOptions
                    {...this.props}
                    topElement={this.state.topElement}
                    key={this.state.topElement.get('id')}
                  />
                )
              }
            </BoxGutterMedium>
            <SelectedView {...this.props} />
          </div>
        </div>
      )
    }
  }
}

AddViewElements.propTypes = {
  showEditViewForm: PropTypes.bool
}

