/**
 * @class LogoutFeedbackModal
 * @desc
 * This `Component` is a modal that asks certain users for feedback at the end of
 * their session when they manually log out of Everplans. For now it is only shown
 * to each user once.
 */

import {Component} from 'react'
import PropTypes from 'prop-types'

import {Button} from '../../../dashboard/src/components/blocks/Buttons'
import Closer from '../../../shared_components/core/closer/Closer'
import currentuserWrapper from '../../../shared_components/currentUserWrapper'
import FeedbackForm from '../../../shared_components/feedback_form/FeedbackForm'
import Modals from '../../../shared_components/core/modals/Modals'

import boundUpdate from '../../../shared_components/BoundUpdate'
import Container from '../../../lib/Container'
import featureFlags from '../../../lib/FeatureFlags'
import {hasViewedFlag, setViewedFlag} from '../../../lib/legacyUserTools'
import Logger from '../../../lib/NewLogger'
import {noop} from '../../../lib/tools'
import {railsUrl} from '../../../lib/urlTools'
import routerUtils from '../../../lib/routerUtils'
import SlackActions from '../../../shared_actions/SlackActions'
import {slackOnFeedbackOptIn} from '../../../lib/slackTools'
import storePrototype from '../../../shared_components/StorePrototype'
import UserActions from '../../../shared_actions/UserActions'

Container.registerStore('logoutFeedback', storePrototype([UserActions.Types.DID_UPDATE_USER, SlackActions.Types.DID_SEND_SLACK_MESSAGE]))


export class LogoutFeedbackModal extends Component {
  constructor() {
    super()

    this.state = {
      closing: false, // Used to prevent spamming when user clicks the closer.
      processing: false // Used to prevent spamming when user submits the form.
    }

    this.closeModal = this.closeModal.bind(this)
    this.feedbackFormProps = this.feedbackFormProps.bind(this)
    this.modalCloser = this.modalCloser.bind(this)
    this.sharedEventPayload = this.sharedEventPayload.bind(this)
    this.shouldSuppressModal = this.shouldSuppressModal.bind(this)
    this.submit = this.submit.bind(this)
  }

  componentDidMount() {
    // Log the user out immediately if they should not be seeing the modal for any reason:
    if (!featureFlags.isEnabled('feedback_modal') || this.shouldSuppressModal())
      routerUtils.setLocation(railsUrl('sign_out'))
    else
      this.logEvent('logout_feedback_modal_rendered', this.sharedEventPayload())
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // Log them out immediately if somehow the `currentUser` was delayed and only now arrived with the flag set to true:
    if (hasViewedFlag('has_seen_logout_feedback_modal', this.props.currentUser))
      routerUtils.setLocation(railsUrl('sign_out'))

    // Log the user out if the had not seen the flag before but now have (i.e., they just filled out the modal):
    else if (hasViewedFlag('has_seen_logout_feedback_modal', nextProps.currentUser))
      this.props.finished()
  }

  closeModal() {
    // Prevent the user from spamming the close or submitting after hitting close:
    this.setState({closing: true})

    this.logEvent('logout_feedback_modal_closed', this.sharedEventPayload())
    this.updateUserFlag()
  }

  logEvent(name, payload) { Logger.log({name, payload}) }

  modalCloser() { return <Closer fill='#fff' closer={this.state.closing ? noop : this.closeModal} /> }

  sharedEventPayload() { return {user_id: this.props.currentUser.id, category: 'Logout'} }

  shouldSuppressModal() {
    return (
      hasViewedFlag('has_seen_logout_feedback_modal', this.props.currentUser) ||
      ['account_manager', 'admin', 'disabled'].some(userType => this.props.currentUser[userType])
    )
  }

  submit() {
    // Prevent multiple submissions:
    this.setState({processing: true})

    const formData = this.refs.feedback_form.formData()
    const optIn = formData.getIn(['feedback-form-checkbox', 'value', 0], 'No')
    const payload = {
      ...this.sharedEventPayload(),
      comment: formData.getIn(['feedback-form-textarea', 'value']),
      opt_in: optIn,
      feedback_value: formData.getIn(['feedback-form-image-radio-list', 'value']),
      wildcard: this.suppressOptIn() ? 'Opt In: N/A' : `Opt In: ${optIn}`
    }

    if (payload.opt_in === 'Yes')
      slackOnFeedbackOptIn('Logout', this.props.currentUser.id)

    this.logEvent('logout_feedback_modal_submitted', payload)
    this.updateUserFlag()
  }

  updateUserFlag() {
    // Once we know the flag has been updated log the user out:
    this.props.updating(() => routerUtils.setLocation(railsUrl('sign_out')))
    setViewedFlag({
      flagToSet: 'has_seen_logout_feedback_modal',
      user: this.props.currentUser
    })
  }

  suppressOptIn() { return this.props.currentUser && (this.props.currentUser.client || this.props.currentUser.firm_user) }

  feedbackFormProps() {
    return {
      ref: 'feedback_form',
      suppressOptIn: this.suppressOptIn(),
      ...this.props
    }
  }

  render() {
    return (
      <Modals.FeedbackModal closerFillColor='#FFF' showModal={!this.shouldSuppressModal()} closerComponent={this.modalCloser}>
        <header>
          <h2>How was your experience with Everplans today?</h2>
        </header>
        <FeedbackForm {...this.feedbackFormProps()}>
          <Button disabled={this.state.closing} onClick={this.submit} processing={this.state.processing}>
            Submit
          </Button>
        </FeedbackForm>
      </Modals.FeedbackModal>
    )
  }
}

LogoutFeedbackModal.propTypes = {
  closeModal: PropTypes.func,
  currentUser: PropTypes.shape({
    client: PropTypes.bool,
    id: PropTypes.number,
    firm_user: PropTypes.bool,
    viewed_flags: PropTypes.object
  }),
  finished: PropTypes.func,
  updating: PropTypes.func
}

export default boundUpdate(
  currentuserWrapper(LogoutFeedbackModal),
  'logoutFeedback',
  null
)
