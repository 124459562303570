import {useMemo} from 'react'
import {List, Map} from 'immutable'
import PropTypes from 'prop-types'

import {BoxGutterSmall} from '../blocks/Boxes'
import {TextGutterSmall} from '../blocks/Texts'

import {categorySlugByItem} from '../../../../lib/plan_data/category'
import {firstTopKitElementIdWithResponse} from '../../../../lib/plan_data/itemResponsesHelper'
import {findResourceById} from '../../../../lib/plan_data/userData'
import {viewItemPath} from '../../../../lib/urlTools'
import routerUtils from '../../../../lib/routerUtils'

import './viewedItemBox.scss'

const ViewedItemBox = props => {
  const responseGroup = useMemo(() => findResourceById({resourceList: props.itemResponse.get('response-groups'), id: props.firstGroupId}), [])

  const keyField = useMemo(() => {
    const responseKeyField = responseGroup.get('key-field', List())

    return responseKeyField.isEmpty() ? 'Response' : responseKeyField
  }, [])

  const goToView = () => {
    routerUtils.push(
      viewItemPath({
        groupId: props.firstGroupId,
        itemSlug: props.itemResponse.get('slug'),
        slug: categorySlugByItem({item: props.itemResponse, categories: props.categories}),
        path: '/cm/category/',
        elementId: firstTopKitElementIdWithResponse(responseGroup)
      })
    )
  }

  return (
    <BoxGutterSmall className='viewed-item-box'>
      <TextGutterSmall>{props.itemResponse.get('header')}</TextGutterSmall>
      {
        responseGroup.isEmpty() ? <div className='deleted-item'>{keyField}</div> : <a onClick={goToView}>{keyField}</a>
      }
    </BoxGutterSmall>
  )
}

ViewedItemBox.propTypes = {
  categories: PropTypes.instanceOf(List),
  itemResponse: PropTypes.instanceOf(Map),
  firstGroupId: PropTypes.string
}

export default ViewedItemBox
