import {List, Map} from 'immutable'

import {isCompoundItemResponse, itemResponseGroupsForUser} from '../../../lib/plan_data/itemResponsesHelper'
import {ASSESSMENT_TO_NEXT_BEST_ACTIONS_TO_BE_REMOVED_FOR_FALSE_ANSWERS} from './assessmentNextBestActionsDictionary'

/** @const ITEM_TO_GAP_FILLER_DICTIONARY
 * Mapping of `Item` slugs to the slug used to represent the gap fillers.
 */
const ITEM_TO_GAP_FILLER_DICTIONARY = Map({
  'life-insurance-my-life-insurance-policy': 'life-insurance',
  'will-details-about-your-will': 'will',
  'power-of-attorney-my-power-of-attorney': 'power-of-attorney',
  'advance-directive': 'advance-directive',
  'burial-cremation-or-donation-preferences-my-disposition-wishes-and-plans': 'burial-arrangements',
  'digital-world-video-streaming': 'digital-subscription'
})

const LIFE_INSURANCE_ASSESSMENT_KEYS = ['life-insurance', 'employer-life-insurance', 'standalone-life-insurance']

export const GAP_FILLERS_WITH_CONTENT_DOWNLOADS = List(['advance-directive'])

export const hasAnyLifeInsurance = ({assessmentData}) => LIFE_INSURANCE_ASSESSMENT_KEYS.some(assessmentKey => assessmentData.get(assessmentKey))

/**
 * Filters out all onboarding assessment keys with false as the selected value
 * from the assessment slug dictionary
 *
 * @param {Map} assessmentData
 *
 * @returns {List} a List of assessment key to slugs maps that has false as the
 * onboarding assessment answer. For example
 * List([
 *  {'item-slug': 'employment-my-employment'}
 *  {'item-slug': 'homes-and-real-estate-homes'}
 *  {'item-slug': 'my-family-my-spouse'}
 *  {'item-slug': 'homes-and-real-estate-homes'}
 *  {'item-slug': 'financial-advisors-financial-advisors', 'conditional-value': 'Accountant'}
 * ])
*/
export const nextBestActionsToBeRemoved = ({assessmentData}) => {
  const reducer = (collection, assessmentValue, assessmentKey) => {
    if ((LIFE_INSURANCE_ASSESSMENT_KEYS.includes(assessmentKey) && hasAnyLifeInsurance({assessmentData})) || assessmentValue)
      return collection
    else
      return collection.concat(ASSESSMENT_TO_NEXT_BEST_ACTIONS_TO_BE_REMOVED_FOR_FALSE_ANSWERS.get(assessmentKey, List()))
  }

  return assessmentData.reduce(reducer, List())
}

/** Returns the `GapFillerContentDownloads` filtered by slug. */
export const gapFillerContentDownloadsBySlug = ({gapFillerContentDownloads, slug}) => (
  gapFillerContentDownloads.filter(gapFillerContentDownload => gapFillerContentDownload.get('slug') === slug)
)

/** Readability helper to return the `item-slug` for the `nextBestAction`. */
export const nextBestActionItemSlug = nextBestAction => nextBestAction.getIn(['data', 'item', 'item-slug'])

/** Returns the `Item` associated with the `nextBestAction`. */
export const nextBestActionItem = ({itemResponses, nextBestAction}) => (
  itemResponses.find(item => item.get('slug') === nextBestActionItemSlug(nextBestAction))
)

/** Returns `true` if the `gapFillerSlug` is a gap-filler with content downloads, but has no content to download. */
export const isGapFillerWithMissingContentDownloads = ({gapFillerContentDownloads, gapFillerSlug}) => (
  GAP_FILLERS_WITH_CONTENT_DOWNLOADS.includes(gapFillerSlug) && gapFillerContentDownloadsBySlug({gapFillerContentDownloads, slug: gapFillerSlug}).isEmpty()
)


/** Returns a `List` of the `Item` slugs for the `gap-fillers` the user is allowed to see. */
export const allowedGapFillerSlugs = ({partner, userConfig, gapFillerContentDownloads}) => {
  if (userConfig.get('client') || userConfig.get('firm-id')) {
    // This is hard-coded for now since `advance-directive` is the only gap-filler we definitely want to show, and in the future when we
    // add more gap-filler content downloads we may not want to show them to clients and/or firm users.
    return isGapFillerWithMissingContentDownloads({gapFillerContentDownloads, gapFillerSlug: 'advance-directive'}) ? List() : List(['advance-directive'])
  } else {
    return partner
      .get('enabled-gap-fillers', List())
      .filterNot(gapFillerSlug => isGapFillerWithMissingContentDownloads({gapFillerContentDownloads, gapFillerSlug}))
  }
}

/** Returns `true` if the `nextBestAction` has a `type` of `gap-filler`, returns `false` otherwise. */
export const isGapFiller = nextBestAction => nextBestAction.getIn(['data', 'type']) === 'gap-filler'

export const isGapFillerSuppressedByAssessment = ({nextBestAction, assessmentData}) => {
  if (nextBestAction.getIn(['data', 'item', 'item-slug']) === 'life-insurance-my-life-insurance-policy')
    return hasAnyLifeInsurance({assessmentData})
  else
    return assessmentData.get(ITEM_TO_GAP_FILLER_DICTIONARY.get(nextBestAction.getIn(['data', 'item', 'item-slug'])))
}

const hasConditionalValueResponseGroup = ({responseGroups, conditionalItemValue}) => responseGroups.some(responseGroup => {
  // Simple items in the structure of ItemResponses will only ever have 1 `top-element-with-responses` and 1 `responses-grouped-by-second-group-id`
  const responseGroupedBySecond = responseGroup.getIn(['top-elements-with-responses', 0, 'responses-grouped-by-second-group-id', 0])

  // The responses-grouped-by-second-group-id for a simple item should never be empty and if it is then the whole
  // response-group should be empty and the check above would catch that, but just putting a guard here just incase.
  if (responseGroupedBySecond)
    return responseGroupedBySecond.get('responses').some(response => response.getIn(['value', 'data']) === conditionalItemValue)
})

/**
 * Returns `true` if there are `Responses` in the user's Everplan associated with the `Item` (or `conditional-value` in
 * the case of more-complex `nextBestActions`) that is assigned to this user's `Ownership`. Returns `false` otherwise.
 */
export const isAlreadyComplete = ({itemResponse, nextBestAction, userConfig}) => {
  const WHAT_I_WANT_DONE_TO_MY_BODY_SLUG = 'burial-cremation-or-donation-preferences-my-disposition-wishes-and-plans'
  const conditionalItemValue = nextBestAction.getIn(['data', 'item', 'conditional-value'])

  const responseGroups = itemResponseGroupsForUser({itemResponse, userConfig})

  // Per product, we want to display the funeral gap filler irrespective of whether there's data in the +WHAT_I_WANT_DONE_TO_MY_BODY+ item.
  if (responseGroups.isEmpty() || isGapFiller(nextBestAction) && itemResponse.get('slug') === WHAT_I_WANT_DONE_TO_MY_BODY_SLUG)
    return false

  // If it is a compound item it shouldn't have a conditional value attached to the NBA - but just incase putting a guard for it here
  return (isCompoundItemResponse(itemResponse) || !conditionalItemValue) ? true : hasConditionalValueResponseGroup({responseGroups, conditionalItemValue})
}

/**
 * Returns `true` if the user's `assessment` answers indicate the `nextBestAction` should not be shown. If the `nextBestAction`
 * is a `gap-filler` this is when the user said they have the associated `Item`, and if the `nextBestAction` is not a `gap-filler`
 * this is based on the existing assessment logic. Returns `false` otherwise.
 */
export const isSuppressedByAssessment = ({assessment, nextBestAction}) => {
  if (assessment.get('data', Map()).isEmpty())
    return false

  if (isGapFiller(nextBestAction)) {
    return isGapFillerSuppressedByAssessment({nextBestAction, assessmentData: assessment.get('data')})
  } else {
    return nextBestActionsToBeRemoved({assessmentData: assessment.get('data', Map())}).some(
      removableNextBestAction => removableNextBestAction.every((value, key) => nextBestAction.getIn(['data', 'item', key], null) === value)
    )
  }
}

/** Returns `true` if the user has dismissed the `nextBestAction`, `false` if they have not. */
export const isDismissed = ({nextBestAction, profile}) => profile.get('dismissed-next-best-actions', List()).includes(nextBestAction.get('id'))

/** Returns `true` if the `nextBestAction` is malformed/invalid and cannot be shown to the user, `false` if it is valid. */
export const isMissingItem = ({nextBestAction, itemResponse}) => nextBestAction.getIn(['data', 'item'], Map()).isEmpty() || !itemResponse

/**
 * Returns `true` if the `nextBestAction` is a `gap-filler` for an `Item` where the user should never see a `gap-filler`.
 * This is when the user is a client, a firm user, or the user's `Partner` does not have that `gap-filler` enabled. Returns
 * `false` otherwise.
 */
export const isDisallowedGapFiller = ({gapFillerSlugs, nextBestAction}) => {
  if (isGapFiller(nextBestAction))
    return !gapFillerSlugs.includes(ITEM_TO_GAP_FILLER_DICTIONARY.get(nextBestActionItemSlug(nextBestAction)))
  else
    return false
}

/**
 * Returns `true` if the `nextBestAction` has the same `item-slug` as any of the `remainingGapFillerItemSlugs` and thus
 * needs to be removed from the `nextBestActions` to be shown to the user--we don't want any non-`gap-filler` `nextBestActions`
 * to show if a `gap-filler` for that same `item-slug` will be shown. Returns `false` otherwise.
 */
export const isReplacedByGapFiller = ({nextBestAction, remainingGapFillerItemSlugs}) => (
  !isGapFiller(nextBestAction) &&
  remainingGapFillerItemSlugs.includes(nextBestAction.getIn(['data', 'item', 'item-slug']))
)

/** Lists the `item-slug` for any of the `nextBestActions` if they have a `type` of `gap-filler`. */
export const gapFillerItemSlugs = nextBestActions => (
  nextBestActions
    .filter(nextBestAction => isGapFiller(nextBestAction))
    .map(nextBestAction => nextBestAction.getIn(['data', 'item', 'item-slug']))
)

/**
 * Returns a List of the next best actions that should be shown to a user based on what they have already told
 * us the do/don't have, the settings of their `Partner`, what they have entered into their Everplan, and what
 * they have dismissed.
 *
 * @param {Map} assessment
 * @param {List} itemResponses
 * @param {List} nextBestActions
 * @param {Map} partner
 * @param {Map} profile
 * @param {Map} userConfig
 *
 * @returns {List} nextBestActions to be shown to the user
 */
export const nextBestActionsToBeRendered = ({assessment, itemResponses, nextBestActions, partner, profile, userConfig, gapFillerContentDownloads}) => {
  const gapFillerSlugs = allowedGapFillerSlugs({partner, userConfig, gapFillerContentDownloads})

  const candidateNextBestActions = nextBestActions
    .filterNot(nextBestAction => {
      const itemResponse = nextBestActionItem({itemResponses, nextBestAction})

      return (
        isMissingItem({nextBestAction, itemResponse}) ||
        isDismissed({nextBestAction, profile}) ||
        isDisallowedGapFiller({gapFillerSlugs, nextBestAction}) ||
        isSuppressedByAssessment({assessment, nextBestAction}) ||
        isAlreadyComplete({itemResponse, nextBestAction, userConfig})
      )
    }).sortBy(nextBestAction => nextBestAction.getIn(['data', 'sort-order']))

  // By this point we have removed all `gap-fillers` that we ever will so we just need to remove any
  // non-`gap-fillers` that have a matching `Item` slug:
  const remainingGapFillerItemSlugs = gapFillerItemSlugs(candidateNextBestActions)
  return candidateNextBestActions.filterNot(
    nextBestAction => isReplacedByGapFiller({remainingGapFillerItemSlugs, nextBestAction})
  )
}

/**
 * @param {Map} assessment
 * @param {List} itemResponses
 * @param {List} nextBestActions
 * @param {Map} partner
 * @param {List} gapFillerContentDownloads
 * @param {Map} profile
 * @param {Map} userConfig
 *
 * @returns {List} The eligible `nextBestActions` associated to one of the `relevantItems`
 */
export const filterNextBestActionsByItems = ({assessment, itemResponses, nextBestActions, partner, profile, userConfig, gapFillerContentDownloads}) => {
  const itemSlugs = itemResponses.map(item => item.get('slug'))


  return nextBestActionsToBeRendered({
    assessment,
    itemResponses,
    nextBestActions: nextBestActions.filter(nextBestAction => itemSlugs.includes(nextBestActionItemSlug(nextBestAction))),
    partner,
    profile,
    userConfig,
    gapFillerContentDownloads
  })
}
