const ClockIcon = () => (
  <svg className='icon' width='30px' height='15px' viewBox='0 0 30 30' version='1.1' xmlns='http://www.w3.org/2000/svg'>
    <title>Clock Icon</title>
    <path
      d='M19.9787609,18.1338261 C20.4881087,18.6431739 20.4881087,19.4694783 19.979087,19.9785 C19.724413,20.2331739 19.3905,
      20.3606739 19.056587,20.3606739 C18.7226739,20.3606739 18.389087,20.2331739 18.134413,19.9788261 L14.0775652,
      15.9223043 C13.8326739,15.6777391 13.6950652,15.3457826 13.6950652,15.0001304 L13.6950652,7.22654348 C13.6950652,
      6.50621739 14.279087,5.92219565 14.999413,5.92219565 C15.7197391,5.92219565 16.3037609,6.50621739 16.3037609,
      7.22654348 L16.3037609,14.4598043 L19.9787609,18.1338261 Z M14.9997391,27.3914348 C8.16723913,27.3914348 2.60843478,
      21.8326304 2.60843478,15.0001304 C2.60843478,8.16763043 8.16723913,2.60882609 14.9997391,2.60882609 C21.8325652,
      2.60882609 27.3910435,8.16763043 27.3910435,15.0001304 C27.3910435,21.8326304 21.8325652,27.3914348 14.9997391,
      27.3914348 L14.9997391,27.3914348 Z M14.9997391,0.000130434783 C6.72886957,0.000130434783 -0.000260869565,6.72893478 -0.000260869565,
      15.0001304 C-0.000260869565,23.271 6.72886957,30.0001304 14.9997391,30.0001304 C23.2709348,
      30.0001304 29.9997391,23.271 29.9997391,15.0001304 C29.9997391,6.72893478 23.2709348,0.000130434783 14.9997391,
      0.000130434783 L14.9997391,0.000130434783 Z'
    />
  </svg>
)

export default ClockIcon
