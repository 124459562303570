import {Component} from 'react'
import PropTypes from 'prop-types'

import Button from '../../../../../shared_components/Button'

import './confirmCodeForm.scss'


class ConfirmCodeForm extends Component {
  constructor() {
    super()

    this.state = {loading: false}
    this.getEnteredCode = this.getEnteredCode.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  static getDerivedStateFromProps(props, state) {
    // only need to update state if the state is already in a loading state and there's an error
    if (props.error && state.loading)
      return {loading: false}

    return null
  }

  getEnteredCode() { return this.refs.otpCode.value.trim() }

  handleSubmit(event) {
    if (event) event.preventDefault()
    this.setState({loading: true})
    this.props.handleSubmit()
  }

  render() {
    return (
      <div>
        <h3>Enter your code</h3><hr />
        <form className='wizard-body' onSubmit={this.handleSubmit}>
          <div className='confirm-container'>
            <p className='wizard-info'>Please enter the 6-digit code we just sent to: {this.props.phoneNumber.national_number} </p>
            <input className='confirm-code' ref='otpCode' type='text' />   <a onClick={this.props.restartFunction}>Didn't get the code&#63;</a>
            <div className='error'>{this.props.error}</div>
          </div>
        </form>
        <div>
          <div>
            {
              this.props.phoneVerified ?
                <div className='btn phone-verified' /> :
                <Button
                  className='wizard-primary-btn'
                  onClick={this.handleSubmit}
                  loading={this.state.loading}>
                  Verify code
                </Button>
            }
          </div>
        </div>
      </div>
    )
  }
}

ConfirmCodeForm.propTypes = {
  phoneVerified: PropTypes.bool,
  phoneNumber: PropTypes.object.isRequired,
  error: PropTypes.string.isRequired,
  restartFunction: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired
}

export default ConfirmCodeForm
