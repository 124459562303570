import {useEffect} from 'react'
import {usePlaidLink} from 'react-plaid-link'
import PropTypes from 'prop-types'

const PlaidLinkButton = props => {
  const plaidLinkProps = {
    token: props.token,
    onSuccess: props.onSuccess,
    onExit: props.onExit
  }
  const {open, ready} = usePlaidLink(plaidLinkProps)

  useEffect(() => {
    if (props.setPlaidUtils && ready) props.setPlaidUtils({open, ready})
  }, [props.setPlaidUtils, ready, open])

  const onClick = () => {
    open()

    if (props.onClick) props.onClick()
  }

  return (
    <div className='plaid-link-button'>
      {props.showAdditionalText && <div>Make things easy for yourself</div>}
      <button
        disabled={!ready}
        onClick={onClick}
        className={props.className}
        style={props.style}>
        {props.text || 'Import account details from your bank'}
      </button>
    </div>
  )
}

PlaidLinkButton.defaultProps = {
  showAdditionalText: false
}

PlaidLinkButton.propTypes = {
  createResource: PropTypes.func,
  onClick: PropTypes.func,
  onExit: PropTypes.func,
  onSuccess: PropTypes.func,
  setPlaidUtils: PropTypes.func,
  showAdditionalText: PropTypes.bool,
  style: PropTypes.object,
  text: PropTypes.string,
  token: PropTypes.string
}

export default PlaidLinkButton
