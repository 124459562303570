import {Map} from 'immutable'
import PropTypes from 'prop-types'

import * as icons from '../../shared_assets/100px_icons'

import './nextBestActionIcon.scss'

export const NEXT_BEST_ACTION_ICONS_MAP = Map({
  Accountant: icons.calculatorAndContract,
  'Adult Dependents': icons.handsHoldingPeopleUp,
  Attorney: icons.contractWithPencil,
  Banking: icons.bankWithDollarSign,
  Businesses: icons.cooperation,
  'Burial or Cremation': icons.ripWreath,
  'Care Facility': icons.buildingsWithCheckmarkBadge,
  'Computer Password': icons.nbaComputerPasswordAlt,
  'Contract With Pen': icons.contractWithPen,
  'Credit Cards': icons.creditCards,
  Dentist: icons.dentistChair,
  'Device Unlock Code': icons.phoneShowingKey,
  Digital: icons.phoneWithCardAndDollar,
  'Disability Insurance': icons.crutches,
  Doctor: icons.medicalBag,
  "Driver's License": icons.driversLicense,
  'Email Address': icons.envelopeContainingEmail,
  'Emergency Contact': icons.sirenExclamationMark,
  Employment: icons.employeeIDBadge,
  'Financial Advisor': icons.bagWithDollarSign,
  Funeral: icons.roses,
  'Graph of Coin Stacks': icons.graphOfCoinStacks,
  'Hand Holding Money Sack': icons.handHoldingMoneySack,
  'Health Insurance': icons.healthInsuranceCard,
  'Healthcare Proxy': icons.medicalClipboardChecklist,
  Homes: icons.nbaHomeAlt,
  Hummingbird: icons.hummingbird,
  Hummingbirds: icons.hummingbirds,
  Kids: icons.photosOfChild,
  'Life Insurance': icons.umbrellaOverPeople,
  'Long-Term Care Insurance': icons.handsHoldingPlaqueUp,
  'Medical Conditions': icons.medicalClipboard,
  Medications: icons.medicationsCapsules,
  'Other IDs': icons.idCardAndPassport,
  Passport: icons.passport,
  'Password Management': icons.shieldWithPadlock,
  Pets: icons.dog,
  'Power of Attorney': icons.moneyDocumentWithFountainPen,
  'Rental Home': icons.houseAngled,
  Retirement: icons.chaiseLounge,
  'Small Business': icons.openForBusinessSign,
  'Social Security Card': icons.socialSecurityCard,
  Taxes: icons.envelopeContainingTaxReturn,
  Vaccines: icons.vaccines,
  Vehicles: icons.vehicleSuv,
  Will: icons.nbaWillAlt
})


const NextBestActionIcon = ({iconName}) => {
  const icon = NEXT_BEST_ACTION_ICONS_MAP.get(iconName)
  if (icon)
    return <img className='next-best-action-icon' src={icon} alt={iconName} />
  else
    return null
}

NextBestActionIcon.propTypes = {
  iconName: PropTypes.string
}

export default NextBestActionIcon
