import PropTypes from 'prop-types'

import USStatesData from '../../../lib/us-states-data.json'
import Select from './Select'

const State = ({name}) => {
  const options = [{label: 'Select a state', value: ''}].concat(USStatesData.map(value => ({label: value, value})))

  return (
    <Select
      label='State'
      name={name}
      options={options}
      placeholder='Select a state'
    />
  )
}

State.propTypes = {
  name: PropTypes.string.isRequired
}

export default State
