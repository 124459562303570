import BoxWithTextAndCircleCheckWithNumber from './BoxWithTextAndCircleCheckWithNumber'
import Download from './Download'
import EverplanPreviewHelpfulResources from './EverplanPreviewHelpfulResources'

import {deceasedHeaderEverplanInformationText} from '../../../../../../lib/corpMaxDeputyTools'
import {TextGutterSmall} from '../../../../../../dashboard/src/components/blocks/Texts'


const DeceasedHeaderEverplanInformation = props => (
  <section className='deceased-header-everplan-information'>
    <BoxWithTextAndCircleCheckWithNumber className='unlocked-text flex-child' textClass='accessible-items-information'>
      <TextGutterSmall className='deceased-header-everplan-info-text'>{deceasedHeaderEverplanInformationText(props)}</TextGutterSmall>
      <Download {...props} />
    </BoxWithTextAndCircleCheckWithNumber>
    <EverplanPreviewHelpfulResources />
  </section>
)

export default DeceasedHeaderEverplanInformation
