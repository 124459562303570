import {Component} from 'react'
import {Map} from 'immutable'
import {PropTypes} from 'prop-types'

import {TextGutterMedium} from '../../../../blocks/Texts'

export default class DependentResponseElement extends Component {
  constructor() {
    super()

    this.removeDependentResponseElement = this.removeDependentResponseElement.bind(this)
  }

  removeDependentResponseElement() {
    this.props.removeDependentResponseElement(this.props.responseElement)
  }

  dependentResponseElementName() {
    return this.props.elements.getIn([this.props.responseElement.get('element-id'), 'name'])
  }

  render() {
    return (
      <div className='dependent-response-element'>
        <a onClick={this.removeDependentResponseElement}>Remove Required Response</a>
        <TextGutterMedium>
          {this.dependentResponseElementName()}
          <strong> {this.props.responseElement.get('value')}</strong>
        </TextGutterMedium>
      </div>
    )
  }
}

DependentResponseElement.propTypes = {
  responseElement: PropTypes.instanceOf(Map),
  elements: PropTypes.instanceOf(Map),
  removeDependentResponseElement: PropTypes.func
}

