import {TextGutterMedium, TextGutterLarge} from '../../blocks/Texts'
import UnorderedList from '../../../../../shared_components/UnorderedList'


const WhoShouldIChoose = () => (
  <div className='flex-child sub-info who-should-i-choose'>
    <h3>Who Should I Choose?</h3>
    <TextGutterMedium>The following are good choices to be a Deputy for your Everplan:</TextGutterMedium>
    <TextGutterLarge>
      <UnorderedList
        children={[
          'Your spouse or partner',
          'Your grown children',
          'The executor of your Will',
          'The person you designate as your Power of Attorney'
        ]}
      />
    </TextGutterLarge>
  </div>
)

export default WhoShouldIChoose
