import {useEffect} from 'react'

import {Map} from 'immutable'
import PropTypes from 'prop-types'

import {Accept} from '../../../../../dashboard/src/components/blocks/Buttons'
import {
  TextGutterMedium,
  TextGutterSmall
} from '../../../../../dashboard/src/components/blocks/Texts'

import Logger from '../../../../../lib/NewLogger'
import FuneralExpenseHeader from './FuneralExpenseHeader'

import productRecommendation from '../../../../../shared_assets/product_recommendation@2x.png'

const FuneralExpenseRecommendationFinalExpense = props => {
  useEffect(() => {
    Logger.log({
      name: 'viewed_gap_filler_product_recommendation',
      payload: {
        flavor: 'funeral',
        wildcard: 'final-expense',
        product_type: 'final-expense'
      }
    })

    if (props.productRecommendationUserSetting.isEmpty()) {
      props.productRecommendationSubscription({
        subName: 'final_expense_reminder'
      })
    }
  }, [])

  return (
    <div className='funeral-expense-recommendation-final-expense'>
      <FuneralExpenseHeader />
      <div className='top-container flex-container'>
        <div className='left-container flex-child'>
          <TextGutterMedium className='bold-text'>
            Our recommendation for you
          </TextGutterMedium>
          <TextGutterMedium className='large-text'>
            You might be interested in <strong>Final Expense Insurance</strong>{' '}
            or <strong>Final Expense Trust</strong>
          </TextGutterMedium>
          <TextGutterMedium className='small-text'>
            <strong>Final Expense Insurance:</strong> Also known as Burial
            Insurance, this is similar to a Life Insurance policy. The
            difference is that the death benefit will be paid out in 48 hours as
            opposed to days, and anybody can get it—even if you don't qualify
            for regular Life Insurance.
          </TextGutterMedium>
          <TextGutterSmall className='small-text'>
            <strong>Final Expense Trust:</strong> The same way a person can
            create a Trust for their children's college education, you can
            create a Trust for your funeral. A Trust is basically a fancy bank
            account you set up to protect money and assets. It can safeguard
            these assets to be used only for specific purposes—like your
            funeral.
          </TextGutterSmall>
        </div>
        <img
          className='product-recommendation-image'
          src={productRecommendation}
          alt='product recommendation image'
        />
      </div>
      <div className='btn-group'>
        <Accept onClick={props.toggleGapFillerAssessmentModal}>
          Done
        </Accept>
      </div>
    </div>
  )
}

FuneralExpenseRecommendationFinalExpense.propTypes = {
  productRecommendationSubscription: PropTypes.func,
  productRecommendationUserSetting: PropTypes.instanceOf(Map),
  toggleGapFillerAssessmentModal: PropTypes.func
}

export default FuneralExpenseRecommendationFinalExpense
