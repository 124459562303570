import {List, Map} from 'immutable'
import PropTypes from 'prop-types'

import {isCompoundItem} from '../../../../../lib/plan_data/item'
import {findResourceByAttributeId} from '../../../../../lib/plan_data/userData'

const NextBestActionFullItemFields = props => {
  if (props.nextBestAction.get('item')) {
    const conditionalValue = props.nextBestAction.getIn(['item', 'conditional-value'])

    const nextBestActionHasCompoundItem = item => isCompoundItem(props.kits.get(item.get('kit-id')))

    const nextBestActionCompoundItemValue = props.elements.get(props.nextBestAction.getIn(['item', 'element-id'])).get('name')

    const nextBestActionItem = findResourceByAttributeId({
      resourceList: props.items,
      attribute: 'slug',
      id: props.nextBestAction.getIn(['item', 'item-slug'])
    })

    return (
      <div className='next-best-action-full-item-fields'>
        <div>
          <strong>Item: </strong>
          {nextBestActionItem.get('header')}
        </div>
        {
          conditionalValue && (
            <div>
              <strong>Conditional Value: </strong>
              {conditionalValue}
            </div>
          )
        }
        {
          nextBestActionHasCompoundItem(nextBestActionItem) && (
            <div>
              <strong>Compound Item: </strong>
              {nextBestActionCompoundItemValue}
            </div>
          )
        }
        <div>
          <strong>Header: </strong>
          {props.nextBestAction.get('header', nextBestActionItem.get('header'))}
        </div>
      </div>
    )
  } else {
    return null
  }
}


NextBestActionFullItemFields.propTypes = {
  elements: PropTypes.instanceOf(Map),
  items: PropTypes.instanceOf(List),
  kits: PropTypes.instanceOf(Map),
  nextBestAction: PropTypes.instanceOf(Map)
}

export default NextBestActionFullItemFields
