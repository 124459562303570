import {Map} from 'immutable'
import PropTypes from 'prop-types'

import Logger from '../../../../../lib/NewLogger'
import routerUtils from '../../../../../lib/routerUtils'

import './addAnotherResponseGroupLink.scss'


const AddAnotherResponseGroupLink = props => {
  const openAddMoreModal = () => {
    const elementId = props.currentElement.get('parent-element-id')

    Logger.log({
      name: 'clicked_add_another_compound_group',
      payload: {
        actor: props.actor,
        context: props.context,
        everplan_id: props.itemResponse.get('everplan-id'),
        type: props.eventType,
        item: props.itemResponse.get('name'),
        wildcard: elementId,
        element_id: elementId
      }
    })

    routerUtils.push(
      props.addMorePath({
        elementId,
        groupId: props.params.groupId
      })
    )
  }

  const shouldShowLink = () => {
    // TODO: remove 'addMore' when everything is using Transformer
    const addMore = props.currentElement.get('add-more') || props.currentElement.get('addMore')

    return (
      props.isEditableItem &&
      props.hasMultipleFieldGroups &&
      addMore &&
      props.hasAtLeastOneResponse
    )
  }

  if (shouldShowLink()) {
    return (
      <a className='add-another-response-group-link' onClick={openAddMoreModal}>
          Add another: {props.currentElement.get('name')}
      </a>
    )
  } else {
    return null
  }
}

AddAnotherResponseGroupLink.propTypes = {
  actor: PropTypes.string,
  addMorePath: PropTypes.func,
  context: PropTypes.string,
  currentElement: PropTypes.instanceOf(Map),
  eventType: PropTypes.string,
  hasAtLeastOneResponse: PropTypes.bool,
  hasMultipleFieldGroups: PropTypes.bool,
  isEditableItem: PropTypes.bool,
  itemResponse: PropTypes.instanceOf(Map),
  params: PropTypes.object
}

export default AddAnotherResponseGroupLink
