import PropTypes from 'prop-types'
import {TextGutterMedium} from '../../../../../dashboard/src/components/blocks/Texts'

import UnorderedList from '../../../../UnorderedList'
import './digitalSubscriptionSuccessList.scss'

const DigitalSubscriptionSuccessList = ({subscriptions, categoryName}) => {
  const sortedSubscriptions = subscriptions
    .sortBy(data => data.get('item-name'))
    .map(data => `${data.get('name')} (${data.get('item-name')})`)
    .toJS()
  const text = subscriptions.size === 1 ? 'service has' : 'services have'

  return (
    <div className='digital-subscription-success-list'>
      <TextGutterMedium>
        The following {text} been added to the <strong>{categoryName}</strong>{' '}
        section.
      </TextGutterMedium>
      <UnorderedList children={sortedSubscriptions} />
    </div>
  )
}

DigitalSubscriptionSuccessList.propTypes = {
  subscriptions: PropTypes.array,
  categoryName: PropTypes.string
}


export default DigitalSubscriptionSuccessList
