import PropTypes from 'prop-types'
import {useCallback, useEffect, useState, useMemo} from 'react'
import {fromJS, List, Map} from 'immutable'

import Modals from '../../../../core/modals/Modals'
import DigitalSubscriptionIntro from './DigitalSubscriptionIntro'
import DigitalSubscriptionFormContainer from './DigitalSubscriptionFormContainer'
import DigitalSubscriptionSuccessModal from './DigitalSubscriptionSuccessModal'
import DigitalSubscriptionLoader from './DigitalSubscriptionLoader'
import DigitalSubscriptionPlaidLinkContainer from './DigitalSubscriptionPlaidLinkContainer'
import GapFillerAssessmentCloser from '../GapFillerAssessmentCloser'
import Closer from '../../../../core/closer/Closer'

import {useNotificationContext} from '../../../../notifications/NotificationContext'

import './digitalSubscriptionContainer.scss'

const SUBSCRIPTION_POLLING_TIME_INTERVAL = 1000
const MAX_POLLING_TIME = 30000 * 10
const DEFAULT_SELECT_ITEM_ID = 'a48bb659-55dd-4150-9f63-755328faa6e9'
const SUBSCRIPTION_IMPORT_ERROR =
  'There was a problem importing your data. Please try again in a few minutes or after switching to a new network.'

const DigitalSubscriptionContainer = props => {
  const step = props.step
  const [subscriptions, setSubscriptions] = useState(List())
  const [accounts, setAccounts] = useState(List())
  const [selectedSubscriptions, setSelectedSubscriptions] = useState(Map())
  const [itemTokenId, setItemTokenId] = useState()
  const [plaidUtils, setPlaidUtils] = useState({})
  const [jobId, setJobId] = useState()
  const {alwaysNotify} = useNotificationContext()

  // Dictionary of name and id e.g {Devices: {id: '2hu34-324j-34-34nj', 'category-name': 'Devices}}
  const itemMap = useMemo(() =>
    props.digitalItems.reduce(
      (collection, item) => collection.set(item.get('value'), item),
      Map()
    ),
  [props.digitalItems.size]
  )
  const defaultSelectItem = useMemo(
    () =>
      props.digitalItems
        .find(item => item.get('id') === DEFAULT_SELECT_ITEM_ID, null, Map())
        .get('value'),
    [props.digitalItems.length]
  )

  const handleResponse = response => {
    if (response.body.data.attributes.subscriptions) {
      setAccounts(fromJS(response.body.data.attributes.accounts))
      setSubscriptions(
        fromJS(response.body.data.attributes.subscriptions)
          .map(subscription => (itemMap.get(subscription.get('item-guess')) ?
            subscription :
            subscription.set('item-guess', defaultSelectItem))
          )
          .sortBy(subscription => subscription.get('item-guess'))
      )
      props.nextStep({step})
    }
  }

  const closeAndNotifyError = useCallback(message => {
    props.toggleGapFillerAssessmentModal()
    alwaysNotify.longError(message)
  })

  const handlePollingError = currentJobId => {
    clearInterval(currentJobId)
    closeAndNotifyError(SUBSCRIPTION_IMPORT_ERROR)
  }

  const pollSubscriptionData = useCallback(() => {
    clearInterval(jobId)
    const startTime = new Date()

    const currentJobId = setInterval(() => {
      if (new Date() - startTime >= MAX_POLLING_TIME)
        handlePollingError(jobId)

      props
        .readEndpoint(`plaid-item-tokens/${itemTokenId}`)
        .then(handleResponse)
        .catch(() => handlePollingError(currentJobId))
    }, SUBSCRIPTION_POLLING_TIME_INTERVAL)

    setJobId(currentJobId)
  }, [jobId, itemTokenId])

  useEffect(() => {
    // Do not start polling for subscriptions until itemTokenId is set and no currentJobId.
    if (step === 1 && itemTokenId && !jobId) pollSubscriptionData()
    if (step === 2 && jobId) clearInterval(jobId)

    return () => { if (jobId) clearInterval(jobId) }
  }, [step, itemTokenId, jobId])

  const resetData = useCallback(() => {
    setSubscriptions(List())
    setItemTokenId(null)
    setJobId(null)
    props.resetStep()
  })

  const sharedProps = {
    onStepChange: useCallback(() => props.nextStep({step}), [step]),
    closeAndNotifyError,
    plaidUtils,
    toggleGapFillerAssessmentModal: props.toggleGapFillerAssessmentModal
  }

  const closer = useCallback(() => {
    const stepsWithConfirmationModal = [1, 2]
    if (stepsWithConfirmationModal.includes(step)) {
      return (
        <GapFillerAssessmentCloser
          toggleGapFillerAssessmentModal={props.toggleGapFillerAssessmentModal}
          confirmationText='Are you sure you want to exit?'
        />
      )
    } else { return <Closer onClick={props.toggleGapFillerAssessmentModal} /> }
  }, [step])

  return (
    <div>
      {step === 3 && (
        <DigitalSubscriptionSuccessModal
          {...sharedProps}
          selectedSubscriptions={selectedSubscriptions}
          itemMap={itemMap}
        />
      )}
      {step !== 3 && (
        <Modals.FullHeightModalLarge
          className='digital-subscription-container forms-playground'
          closerComponent={closer}
          showModal={true}>
          <div className='digital-subscription-contents'>
            {step === 0 && <DigitalSubscriptionIntro {...sharedProps} />}
            {step === 1 && <DigitalSubscriptionLoader />}
            {step === 2 && (
              <DigitalSubscriptionFormContainer
                {...sharedProps}
                subscriptions={subscriptions}
                accounts={accounts}
                digitalItems={props.digitalItems}
                itemMap={itemMap}
                ownershipIds={props.ownershipIds}
                userConfig={props.userConfig}
                updateResource={props.updateResource}
                setSelectedSubscriptions={setSelectedSubscriptions}
              />
            )}
          </div>
        </Modals.FullHeightModalLarge>
      )}
      <DigitalSubscriptionPlaidLinkContainer
        {...sharedProps}
        toggleGapFillerAssessmentModal={props.toggleGapFillerAssessmentModal}
        setItemTokenId={setItemTokenId}
        setPlaidUtils={setPlaidUtils}
        createResource={props.createResource}
        resetData={resetData}
      />
    </div>
  )
}

DigitalSubscriptionContainer.propTypes = {
  createResource: PropTypes.func,
  digitalItems: PropTypes.instanceOf(List),
  ownershipIds: PropTypes.instanceOf(List),
  userConfig: PropTypes.instanceOf(Map),
  updateResource: PropTypes.func,
  readEndpoint: PropTypes.func,
  nextStep: PropTypes.func,
  resetStep: PropTypes.func,
  step: PropTypes.number,
  toggleGapFillerAssessmentModal: PropTypes.func
}

export default DigitalSubscriptionContainer
