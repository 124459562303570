/** @class CorpMaxStepOne
 * @desc
 * Component displaying information about what will occur when an individual
 * Everplanner, both owners of a household `Everplan`, or the last living
 * member of a household `Everplan` is/are marked as deceased.
 */

import {Component} from 'react'
import PropTypes from 'prop-types'

import {BoxGutterSmall} from '../../blocks/Boxes'
import {Text, TextGutterMedium, TextGutterLarge} from '../../blocks/Texts'
import ButtonGroup from '../../../../../shared_components/core/buttons/ButtonGroup'
import {Button, Cancel} from '../../blocks/Buttons'

import {addPossessive} from '../../../../../lib/tools'
import {anyDeputyHasDeferredItems} from '../../../../../lib/corpMaxDeputyTools'

import './corpMaxStepOne.scss'


export default class CorpMaxStepOne extends Component {
  constructor() {
    super()

    this.markAsDeceased = this.markAsDeceased.bind(this)
  }

  /**
   * Marks the an individual, both household members, or the last living household
   * member as deceased. `this.props.markAsDeceased` cannot be used
   * directly as the parent would then receive a synthetic event that would fool
   * it into believing it was marking one member of a fully-alive household as
   * deceased rather than one of the three types mentioned above.
   */
  markAsDeceased() { this.props.markAsDeceased() }

  render() {
    const planholderName = this.props.firstNameOnly
    const planholderPronoun = this.props.pronoun

    return (
      <div className='step-one'>
        <header><h3>Report {this.props.displayName} as deceased</h3></header>
        <TextGutterMedium>
          We'll take the following steps when you report {addPossessive(this.props.displayName)} death to Everplans.
        </TextGutterMedium>
        <div className='instruction-container'>
          <BoxGutterSmall className='instruction'>
            <div className='number-circle'>
              <Text>Prevent future modifications to {addPossessive(planholderName)} Everplan.</Text>
            </div>
          </BoxGutterSmall>
          {
            anyDeputyHasDeferredItems(this.props.userEverplan) &&
            <BoxGutterSmall className='instruction'>
              <div className='number-circle'>
                <Text>Unlock "after death" items for all deputies who have been granted access.</Text>
              </div>
            </BoxGutterSmall>
          }
          <BoxGutterSmall className='instruction'>
            <div className='number-circle'>
              <Text>Stop future billing and auto-renewal for {addPossessive(planholderName)} Everplans Premium subscription.*</Text>
            </div>
          </BoxGutterSmall>
          <BoxGutterSmall className='instruction'>
            <div className='number-circle'>
              <Text>Ensure that you--and all of {addPossessive(planholderName)} other deputies--can access {planholderPronoun} Everplan for the next 12 months.</Text>
            </div>
          </BoxGutterSmall>
        </div>
        <TextGutterLarge>
            *Please note that a subscription purchased via Apple needs to be managed via Apple. Please see {' '}
          <a href='https://support.apple.com/en-us/HT208510' rel='noreferrer' target='_blank'>Apple documentation</a> for more details.
        </TextGutterLarge>
        <ButtonGroup>
          <Cancel onClick={this.props.closeModal} />
          <Button onClick={this.markAsDeceased} processing={this.props.processing}>Report</Button>
        </ButtonGroup>
      </div>
    )
  }
}
CorpMaxStepOne.propTypes = {
  displayName: PropTypes.string,
  firstNameOnly: PropTypes.string,
  closeModal: PropTypes.func,
  markAsDeceased: PropTypes.func,
  processing: PropTypes.bool,
  pronoun: PropTypes.string,
  userEverplan: PropTypes.instanceOf(Map)
}
