import {List, Map} from 'immutable'
import PropTypes from 'prop-types'
import {useState, useMemo, useEffect} from 'react'

import {deputyManageableItems} from '../../../../../lib/corpMaxDeputyTools'
import {defaultNewDeputyPermissions, hasDeputyWithDeferredPermissions} from '../../../../../lib/deputyPermissionTools'
import {categoriesWithItemResponseGroupMaps} from '../../../../../lib/plan_data/itemResponsesHelper'
import {addPossessive} from '../../../../../lib/tools'
import {ModalHeader} from '../../../../../shared_components/core/modals/ModalHeaders'
import DeputyUnlockPermission from '../permissions/DeputyUnlockPermission'
import ManagePermissionsForm from '../permissions/ManagePermissionsForm'

const AddDeputyStepTwo = props => {
  const [showUnlockPermission, setShowUnlockPermission] = useState(props.deputy.hasOwnProperty('death-reporter'))

  const hasDeputyWithDeferredItems = hasDeputyWithDeferredPermissions(props.deputies)

  useEffect(() => {
    const {logMixpanelEvent} = props

    if (showUnlockPermission)
      logMixpanelEvent('add_deputy_unlock_permissions')
    else
      logMixpanelEvent('add_deputy_permissions')
  }, [showUnlockPermission])

  const handleSharedItems = permissions => {
    if (hasDeputyWithDeferredItems || Object.values(permissions).some(permission => permission.value === 'deferred_read')) {
      setShowUnlockPermission(true)
      props.updateDeputy({permissions})
    } else {
      props.nextStep({permissions})
    }
  }

  const goToSharedItems = () => { setShowUnlockPermission(false) }

  const onSubmit = newData => { props.nextStep({...newData}) }

  const manageableItemResponses = useMemo(() => (
    deputyManageableItems({isFirmUser: props.deputy.firmUser, itemResponses: props.itemResponses, firmConfig: props.firmConfig})
  ), [props.deputy.firmUser])

  const itemsWithResponsesByCategory = useMemo(() => (
    categoriesWithItemResponseGroupMaps({
      categories: props.categories,
      itemResponses: manageableItemResponses
    })
  ), [props.deputy.firmUser])

  const defaultPermissions = useMemo(() => defaultNewDeputyPermissions(itemsWithResponsesByCategory), [props.deputy.firmUser])

  const managePermissionsFormProps = ({
    ...props,
    isAddDeputy: true,
    itemsWithResponsesByCategory,
    permissions: props.deputy.permissions || defaultPermissions,
    cancel: props.goBack,
    submit: handleSharedItems
  })


  const deputyUnlockPermissionProps = ({
    cancel: goToSharedItems,
    deputies: props.deputies,
    // DeputyUnlockPermission is used when managing permissions as well and expects the deputy as an immutable object
    deputy: Map(props.deputy),
    submit: onSubmit
  })


  const modalNote = () => {
    if (manageableItemResponses.size !== props.itemResponses.size)
      return `For compliance purposes, only the items shown below can be shared with ${props.deputy['first-name']} ${props.deputy['last-name']}.`
  }

  return (
    <div>
      {
        showUnlockPermission ?
          (
            <DeputyUnlockPermission {...deputyUnlockPermissionProps}>
              <ModalHeader
                heading={`Choose ${addPossessive(props.deputy['first-name'])} unlock permissions`}
                banner='Permissions'
              />
            </DeputyUnlockPermission>
          ) :
          (
            <ManagePermissionsForm {...managePermissionsFormProps}>
              <ModalHeader
                className='in-manage-permissions-form'
                heading={`Choose ${addPossessive(props.deputy['first-name'])} item permissions`}
                banner='Permissions'
                note={modalNote()}
                subheading='Here are all the items in your Everplan so far.'
              />
            </ManagePermissionsForm>
          )
      }
    </div>
  )
}


AddDeputyStepTwo.propTypes = {
  categories: PropTypes.instanceOf(List),
  deputies: PropTypes.instanceOf(List),
  deputy: PropTypes.object,
  itemResponses: PropTypes.instanceOf(List),
  firmConfig: PropTypes.instanceOf(Map),
  goBack: PropTypes.func,
  logMixpanelEvent: PropTypes.func,
  nextStep: PropTypes.func,
  updateDeputy: PropTypes.func,
  userConfig: PropTypes.instanceOf(Map)
}

export default AddDeputyStepTwo
