import {PropTypes} from 'prop-types'

import {Button} from '../../../../blocks/Buttons'
import SelectedViewElements from './SelectedViewElements'
import DependentResponseElements from './DependentResponseElements'
import SelectedConditionalElements from './SelectedConditionalElements'
import {BoxGutterMedium} from '../../../../blocks/Boxes'

const SelectedView = props => {
  if (props.selectedElements.isEmpty() && props.dependentResponseElements.isEmpty()) {
    return null
  } else {
    return (
      <BoxGutterMedium className='selected-view'>
        <SelectedViewElements {...props} />
        <DependentResponseElements {...props} />
        <SelectedConditionalElements {...props} />
        <Button onClick={props.toggleEditViewForm}>
          Next
        </Button>
      </BoxGutterMedium>
    )
  }
}


SelectedView.propTypes = {
  toggleEditViewForm: PropTypes.func
}

export default SelectedView
