import {Map} from 'immutable'

const body = `
How many times have you been given the wrong change at a store or restaurant and just accepted it? Maybe the line was too long or the cashier didn't seem particularly friendly and you thought a dollar or two wasn't worth the hassle. Or maybe you're one of those people who has no qualms making sure they get back exactly what they're owed regardless of how they're perceived by the long line or unfriendly cashier.

When speaking with your family about money it can go either way too, since most people tend to get touchy when discussing their finances. According to Yahoo Finance the best way to approach this is to simply make money a normal topic of conversation.

### Money Is Harder To Talk About Than Death

A 2014 study from Wells Fargo revealed the following: "The most challenging topic to discuss with others is personal finances (44%), whereas death (38%), politics (35%), religion (32%), taxes (21%), and personal health (20%) rank as less difficult."

Take that in for a second: People would rather talk about no longer being on earth than money. The study also reveals that while people aren't comfortable speaking about money, it's one of the biggest stresses in their lives, often causing sleepless nights. You'd think turning to family would assuage these fears, but silence often prevails.

When it comes to talking about money, Wells Fargo found "71% of adults surveyed learned the importance of saving from their own parents. Despite this, only a third (36%) of today's parents report discussing the importance of saving money with their children on a frequent basis, with 64% indicating they talk about savings with their kids less than weekly or never."

So, what's the best way to comfortably discuss money with your family?

### Tips For The Talk

Yahoo Finance provides four tips to make headway when having a cash chat with those who share your blood.

#### Frequency & Neutrality

Whenever a person prefaces a conversation with "we need to talk..." odds are it won't be a pleasant experience for at least one of you. Most money conversations seem to have ulterior motives, especially if the only time your family talks about money is during a crisis or when a person needs some. However, if money can become an unconditional part of your ongoing conversations (example: it's peppered throughout all the other stuff you speak about), it won't be so awkward.

You don't have to break out bank books and give specific amounts, but regularly discussing financial tips, tricks, or experiences with kids, siblings, and parents will make it more normal and less taboo. If the first time your family talks about money is when an estate needs to be divided after a death, you're doing it wrong. Start small -- savings, investments, monthly budgets, advice you got from your parents or trusted advisors -- and work up to bigger conversations once everyone's on board.

#### Generational Divide

Citing the Wells Fargo study, Yahoo points out that "69% of older respondents viewed monetary gifts as an expression of love. On the flipside, 30% of millennials saw monetary gifts as a way to exert control or influence."

An older person who worked their whole life to amass savings has a completely different perspective than someone in their early 20s just getting started on the journey. While you might never see eye-to-eye with someone 20-40 years your junior or senior (especially when it comes to music), you have to try and understand their point of view.

#### Finding The Right Place

When it comes time for a bigger discussion about money and estate planning, where you meet can dictate how well it will go. Yahoo Finance suggests finding a location that will make everyone comfortable. This means finding neutral ground outside of the family homes to avoid anyone who might try and use the familiar setting, either on purpose or subconsciously, to their advantage.

The article suggests renting a private room at a favorite restaurant or a conference room at a local hotel, which seems to go against the whole "make money talk more informal" thesis they champion, but their point is valid. Once you've graduated to the point where you can have an official family meeting, you might not want to discuss secret business in a Starbucks or at the local park; you need a setting that's suitable for a serious talk, but not at all intimidating. The private room in a restaurant probably works best, especially if they serve potato skins. Because, let's be real here, who doesn't love a good potato skin?

#### Be Calm While You Carry On

Body language is very important around family because they often know you the best (and worst). Unlike strangers who might put up with a little attitude, if a family member recognizes a slight mannerism or catches an eye-roll, things can get dramatic and explosive very quickly.

Ideally, you should have a clear idea of what you want to accomplish. If you're one of the wise family elders, you most likely have a much better handle on all the finances and can dictate the tone and agenda. You can also make sure everyone is comfortable and willing to ask questions even if it's a 15-year-old grandkid baffled by the concept of a mortgage. (Or a 35-year-old adult child baffled by the concept of a mortgage.)

Finally, don't be coy. Unless someone is demanding specific figures you're unwilling to offer, try to answer as openly and honestly as possible. For example, if your Will states that all your assets will be divided equally among your adult children, tell them that. The entire point of normalizing money discussions with your family is to limit surprises, not heighten the suspense and turn it into a free-for-all after you're gone.
`


export default Map({
  body,
  headline: 'How Difficult Is It To Talk With Your Family About Money?',
  summary: 'Some families have no problem discussing money. For the rest, use these tips to get your family on the same financial page.',
  query: 'how-difficult-is-it-to-talk-with-your-family-about-money',
  url: 'https://www.everplans.com/articles/how-difficult-is-it-to-talk-with-your-family-about-money'
})
