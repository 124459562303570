import PropTypes from 'prop-types'
import {useState} from 'react'

import ConfirmationModal from '../../../../../shared_components/mui_base_components/modals/confirmation_modal/ConfirmationModal'

const CancelSubscription = ({cancelAutoSubscription, cancelText}) => {
  const [showModal, setShowModal] = useState(false)

  const handleCancelSubscription = () => {
    cancelAutoSubscription()
    setShowModal(false)
  }

  return (
    <div className='cancel-subscription'>
      <a onClick={() => setShowModal(true)}>{cancelText}</a>
      <ConfirmationModal
        bodyText='Auto-renewal ensures your uninterrupted service by automatically renewing your subscription.'
        confirmButtonText='Turn off auto-renewal'
        headingText='Turn off auto-renewal?'
        onClose={() => setShowModal(false)}
        onConfirmation={handleCancelSubscription}
        open={showModal}
        showWarningIcon={true}
      />
    </div>
  )
}

CancelSubscription.propTypes = {
  cancelText: PropTypes.string,
  cancelAutoSubscription: PropTypes.func
}

export default CancelSubscription
