import {Map} from 'immutable'

const body = `
### 1. Make A List Of All Your Digital Assets And How To Access Each One

What digital assets do you own? Make a list of your digital assets, including everything from hardware to social media accounts to online banking accounts to home utilities that you manage online. In brief, your digital assets may include:

* Computing hardware, such as computers, external hard drives or flash drives, tablets, smartphones, digital music players, e-readers, digital cameras, and other digital devices
* Any information or data that is stored electronically, whether online, in the cloud, or on a physical device
* Any online accounts, such as email and communications accounts, social media accounts, shopping accounts, photo and video sharing accounts, video gaming accounts, online storage accounts, and websites and blogs that you may manage
* Domain names
* Intellectual property, including copyrighted materials, trademarks, and any code you may have written and own

How can your Digital Executor access these assets? Sharing your logins and passwords is essential to the continuity and responsible management of your digital estate. If you use a password manager program, you can simply share your access information to that account. If not, it's important that you record the login and password information for key accounts. If you have computing hardware, such as computers, external hard drives or flash drives, tablets, smartphones, digital music players, e-readers, digital cameras, etc., you'll want to record where those items are located, as well as any passwords that are required to access those devices.

### 2. Decide What You Want Done With These Assets

How should each asset be handled? Depending on the nature of the property, the way you want different types of digital property managed may vary. While you may want some assets to be archived and saved, you may want others to be deleted or erased, while others should be transferred to family members, friends, or business colleagues. For each digital account or asset that you have, specify how you'd like your Digital Executor to handle that asset. While your wishes may conflict with some companies' terms of service, it's still valuable to your Executor to know what your wishes are.

Do any of the assets have monetary value? If so, you may want to instruct your Executor to handle those assets in a specific way. For example, should revenue-generating assets be transferred to people who will continue to manage the accounts? Should credits or points or cash values be redeemed? Should online stores you manage be immediately shut down, shut down after all items are sold, or transferred to someone who can continue to manage the store? If assets will continue to generate revenue, it's worth thinking about where that money is going, and who will be able to access it after you're gone.

### 3. Name A Digital Executor

Who do you trust to carry out your wishes for your digital assets? Basically, your Digital Executor is someone you designate to help settle your digital estate, however you specified in the document you created in steps 1 and 2 of this plan. In most states, a Digital Executor is not a legally binding or enforceable designation. Even so, you can still name a Digital Executor, as this person can be designated by your Executor to follow the wishes laid out in your digital estate plan, or can at least help your Executor with the digital aspects of your estate.

### 4. Store This Information In A Secure-But-Accessible Location

There are three main ways you can securely store this kind of sensitive information.

1. With an attorney.
2. With an online storage service like Everplans.
3. In a locked file cabinet or safe.

No matter how you decide to store your digital estate plan, you'll want to be sure that the people who need to know where the plan is actually know. It's generally a good idea to tell one or two people who you trust—your spouse, your adult children, or your Digital Executor, for example—where the plan is located and how to access it. This means giving the person you trust the name of your attorney, the name of the online storage company you've used, or the location of keys or the combination to your safe. This way, when the time comes, the people who need to access the plan you've made can find the plan and access it.

### 5. If Possible, Make It Legal

Depending on where you live, you may be able to formalize your digital estate plan in a legally binding document, such as your Will or a codicil to a Will. The easiest way to do this is to name a Digital Executor in your Will (or specify who your traditional Executor should work with to settle your digital estate). Then you should specify the location of your digital asset inventory, so that when the time comes your Digital Executor can find and access your plan.

Do not include your passwords other digital asset access information in your Will. When you die, your Will becomes a public document, which means that anyone can read it—including any sensitive information it may contain. A good solution to this is to refer in your Will to an outside document that contains all the necessary information needed to settle your digital estate. This way, you can continue to add to, revise, and update the document without having to formally change your Will or put your digital assets at risk.
`


export default Map({
  body,
  headline: 'Create A Digital Estate Plan Using These 5 Steps',
  summary: 'How to make sure your digital property ends up in the right hands.',
  query: 'create-a-digital-estate-plan-using-these-5-steps',
  url: 'https://www.everplans.com/articles/digital-cheat-sheet-how-to-create-a-digital-estate-plan'
})
