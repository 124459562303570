import classnames from 'classnames'
import PropTypes from 'prop-types'
import {List, Map} from 'immutable'

import AddResponseButton from './AddResponseButton'
import ItemResponseGroups from '../../../../../shared_components/item_response_groups/ItemResponseGroups'
import ModuleBox from '../../../../../shared_components/component_templates/ModuleBox'
import {findResourceByAttributeId} from '../../../../../lib/plan_data/userData'

import './itemWithResponsesBox.scss'

const ItemWithResponsesBox = props => {
  const itemView = findResourceByAttributeId({resourceList: props.itemViews, id: props.itemResponse.get('item-id'), attribute: 'item-id'})

  return (
    <ModuleBox className='item-with-responses-box flex-child'>
      <header className='item-box-heading flex-container'>
        <div className='header-container'>
          <div className='item-header-buttons-container'>
            <h3 className={classnames('flex-child item-header', {'with-household': props.itemResponse.get('is-household')})}>
              <span>{props.itemResponse.get('header')}</span>
            </h3>
            <div className='flex-child flex-container buttons-container'>
              <AddResponseButton {...props} />
            </div>
          </div>
          <div className='labels-container'>
            <div className='belongs-to-last-updated-container'>
              {props.itemResponse.get('is-household') &&
              <div className='label belongs-to-label'>Belongs to</div>}
              <div className='label last-updated-label'>Last updated</div>
            </div>
            <div className='label share-label'>Share</div>
          </div>
        </div>
      </header>
      <div className='response-groups'>
        <ItemResponseGroups
          {...props}
          responseGroups={props.itemResponse.get('responseGroupMap')}
          itemView={itemView}
          path={props.location.pathname}
          hasEditPermission
        />
      </div>
    </ModuleBox>
  )
}

ItemWithResponsesBox.propTypes = {
  categorySlug: PropTypes.string,
  context: PropTypes.string,
  firmConfig: PropTypes.instanceOf(Map),
  itemResponse: PropTypes.instanceOf(Map).isRequired,
  itemViews: PropTypes.instanceOf(List),
  location: PropTypes.object,
  updateResource: PropTypes.func
}

export default ItemWithResponsesBox
