import {Map} from 'immutable'

const body = `
This type of Will is purely voluntary. Unlike a legal Will, which is a tool for transferring your assets and property, an Ethical Will is designed to pass on things like guiding principles, memories, spiritual values, and wishes for your family's future.

### Reasons To Write An Ethical Will

Writing an Ethical Will allows you to communicate your personal story to your family, share your thoughts and memories of your life, and leave a document that details your accomplishments and values. By leaving your family with your Ethical Will, you will be leaving something meaningful behind, so they -- and future generations -- can learn from you and remember your stories after you are gone.

You can also experience the benefits of writing your Ethical Will during your life. By articulating what you value most in life, reflecting on your personal experiences, and thinking about the decisions you've made, you can learn more about yourself. In this way, an Ethical Will can be used as a tool for self-reflection and, if you're so inclined, self-improvement.

Ethical Wills can also be a tool for distributing personal property that has little financial value. Examples of the type of property that might be included in an Ethical Will are:

* Family photographs
* Recipes
* Items of clothing
* Other objects with great personal (but not necessarily commercial) value

They can also be used to explain to your survivors your intentions behind the decisions you made in your legal Will, in case you think there may be any confusion or bad feelings.

### When To Start Writing Your Ethical Will

Writing an Ethical Will is appropriate at any time in life that you feel is right. You can start writing it when you are young and growing your family. This can be a good time to think about what life lessons you'd like to pass on to future generations. What do you wish for your children in the future? You can also begin writing your Ethical Will when you are older. What memories and experiences would you like to share with your family? What lessons have you learned that you would like to impart?

It can be an ongoing experience; you don't have to write the whole thing in one sitting. You might consider adding to it in times of reflection, whether in moments of happiness or hardship.

### How To Start Writing Your Ethical Will

Think about the most significant events, moments, and experiences in your life. What are your happiest memories that you would like to share with your family? What were your most challenging moments? What would you like them to know about your hopes for their futures? How have they changed your life by being a part of it? Your Ethical Will might be a good place to include an apology or confession that you were never able to communicate. You can also start your Ethical Will by telling stories that were passed on to you, that you would like to pass on to future generations.

### What Is The Structure Of An Ethical Will?

Ethical Wills have no standard form or structure, so feel free to personalize it. It can be a formal written letter, an informal note, or a diary. You can assemble a scrapbook or a collage, make a video or audio recording, create a PowerPoint presentation, write a poem or a song, or choose any other form that would be most comfortable and natural for you.

To begin writing yours, use our <a href="https://www.everplans.com/articles/ethical-will-worksheet" rel='noreferrer noopener' target='_blank'>Ethical Will Worksheet</a>.
`


export default Map({
  body,
  headline: 'How To Write An Ethical Will',
  summary: 'An Ethical Will is a personal document that you create in order to communicate your values, experiences, and life lessons to your family.',
  query: 'how-to-write-an-ethical-will',
  url: 'https://www.everplans.com/articles/how-to-write-an-ethical-will'
})
