import AppDispatcher from '../../../lib/ep-dispatcher'
import KeyMirror from 'keymirror'
import TestPrep from '../../../lib/TestPrep'

const SettingsActions = {
  Types: KeyMirror({
    SUBMIT_PHONE_EDIT: null,
    ABANDON_PHONE_EDIT: null,
    GET_NEW_BACKUP_CODE: null,
    CLEAR_BACKUP_CODE: null,
    CONFIRM_CODE_EDIT: null,
    DELETE_SECONDARY_NUMBER: null,
    TFA_DISABLE: null
  }),

  submitPhoneEdit({number, nationalNumber, preferredMessageMode, primary}) {
    AppDispatcher.dispatch({
      actionType: SettingsActions.Types.SUBMIT_PHONE_EDIT,
      number,
      nationalNumber,
      preferredMessageMode,
      primary
    })
  },

  abandonPhoneEdit() {
    AppDispatcher.dispatch({
      actionType: SettingsActions.Types.ABANDON_PHONE_EDIT
    })
  },

  getNewBackupCode() {
    AppDispatcher.dispatch({
      actionType: SettingsActions.Types.GET_NEW_BACKUP_CODE
    })
  },

  clearBackupCode() {
    AppDispatcher.dispatch({
      actionType: SettingsActions.Types.CLEAR_BACKUP_CODE
    })
  },

  confirmCode(id, code) {
    AppDispatcher.dispatch({
      code,
      tfa_phone_number_id: id,
      actionType: SettingsActions.Types.CONFIRM_CODE_EDIT
    })
  },

  deleteSecondaryNumber(id) {
    AppDispatcher.dispatch({
      id,
      actionType: SettingsActions.Types.DELETE_SECONDARY_NUMBER
    })
  },

  disableTfa() {
    AppDispatcher.dispatch({
      actionType: SettingsActions.Types.TFA_DISABLE
    })
  }
}

export default {...TestPrep, ...SettingsActions}
