import {useState, useCallback, useEffect, useMemo} from 'react'
import {List, Map} from 'immutable'
import PropTypes from 'prop-types'
import {useMutation, useQuery} from '@apollo/client'

import AssessmentContentScreen from './AssessmentContentScreen'
import AssessmentScreen from './AssessmentScreen'
import AssessmentScoreResult from './AssessmentScoreResult'
import Modals from '../../../../shared_components/core/modals/Modals'
import ScoreCalculation from './ScoreCalculation'

import {CMS} from '../../../../graphql/queries/cms'
import {CREATE_ASSESSMENT} from '../../../../graphql/mutations/userData'

import {
  assessmentNextScreen,
  assessmentValuesFromOptions,
  getCompletedScoreAssessmentQuestionsPercentage,
  screensSortOrderDictionary
} from '../../lib/onboardingAssessmentTools'
import {cleanGraphQLResponse} from '../../../../lib/graphQLTools'
import MixpanelLogger from '../../../../lib/MixpanelLogger'
import {setViewedFlagV2} from '../../../../lib/userTools'

import './scoreAssessmentController.scss'

const COMPONENT_BY_TYPENAME = {
  ScoreAssessmentContentScreen: AssessmentContentScreen,
  ScoreAssessmentScreen: AssessmentScreen,
  scoreCalculation: ScoreCalculation
}

const ScoreAssessmentController = ({currentUser, userConfig, readEndpoint, updateResource, itemResponses}) => {
  const {data = {}} = useQuery(CMS)
  const scoreAssessment = useMemo(() => cleanGraphQLResponse(data.cms, 'scoreAssessment'), [data])
  const screensDictionary = useMemo(() => screensSortOrderDictionary(scoreAssessment), [scoreAssessment])
  const everplanHasResponses = useMemo(() => itemResponses.some(itemResponse => !itemResponse.get('response-groups').isEmpty()), [itemResponses])
  const assessmentWithoutScore = useMemo(
    () => !!(userConfig.get('client') || userConfig.get('firm-id')) || everplanHasResponses || userConfig.get('household'),
    [userConfig, everplanHasResponses]
  )

  const [createAssessment] = useMutation(CREATE_ASSESSMENT)

  const [showScoreResult, setShowScoreResult] = useState(false)
  const [showModal, setShowModal] = useState(true)
  const [screen, setScreen] = useState(Map())
  const [assessmentValues, setAssessmentValues] = useState(List())
  const [score, setScore] = useState(0)

  useEffect(() => {
    if (!scoreAssessment.isEmpty())
      setScreen(screensDictionary.get(1))
  }, [scoreAssessment])

  const submitAssessment = () => {
    const input = {userId: parseInt(userConfig.get('id'), 10), values: assessmentValues.toJS()}

    createAssessment({variables: {input}, onCompleted: ({createScoreAssessment}) => {
      readEndpoint('user-configs')
      setViewedFlagV2({
        flagToSet: 'has_seen_onboarding_assessment',
        user: currentUser,
        updateResource,
        callback: () => readEndpoint(`users/${userConfig.get('id')}?include=assessment`)
      })
      setScore(createScoreAssessment)

      if (!assessmentWithoutScore)
        setShowScoreResult(true)
    }})
  }

  const submitResponse = userResponse => {
    const response = List.isList(userResponse) ? userResponse : List([userResponse])
    // set the value only if the user has made some selections
    if (!userResponse.isEmpty())
      setAssessmentValues(assessmentValues.merge(assessmentValuesFromOptions(response)))
  }

  const setNextScreenForAssessmentScreens = useCallback(formData => {
    const userResponse = formData.get(screen.get('name'), Map())
    const nextScreen = assessmentNextScreen({response: userResponse, screensDictionary, assessmentWithoutScore})

    setScreen(nextScreen)
    submitResponse(userResponse)
  }, [screen])

  const logMixpanelEvent = ({name, payload = {}}) => MixpanelLogger.track(name, {...payload, score_enabled: !assessmentWithoutScore})

  const setNextScreenForAssessmentContentScreens = useCallback(() => {
    if (screen.get('name') === 'introduction') {
      setScreen(screensDictionary.get(2))
    } else {
      setShowModal(false)
      logMixpanelEvent({name: 'view_assessment_all_set'})
    }
  }, [screen])

  const completedQuestionsPercentage = useMemo(() => (
    getCompletedScoreAssessmentQuestionsPercentage(
      scoreAssessment, screen
    )
  ), [scoreAssessment, screen])

  const componentProps = () => {
    let props = {screen, submitAssessment, logMixpanelEvent}

    if (screen.get('typename') === 'ScoreAssessmentContentScreen') {
      props = {
        ...props,
        assessmentWithoutScore,
        nextScreen: setNextScreenForAssessmentContentScreens,
        numberOfQuestionsAnswered: assessmentValues.size
      }
    } else {
      props = {...props, completedQuestionsPercentage, nextScreen: setNextScreenForAssessmentScreens}
    }

    return props
  }

  if (!screen.isEmpty() && showModal) {
    const ComponentByTypename = COMPONENT_BY_TYPENAME[screen.get('typename')]

    return (
      <Modals.PopUpModal
        className='score-assessment-controller'
        showCloser={false}
        showModal>
        {
          showScoreResult ? (
              <AssessmentScoreResult
                numberOfQuestionsAnswered={assessmentValues.size}
                score={score} setShowModal={setShowModal}
                logMixpanelEvent={logMixpanelEvent}
              />
            ) :
            <ComponentByTypename {...componentProps()} />
        }
      </Modals.PopUpModal>
    )
  } else {
    return null
  }
}

ScoreAssessmentController.propTypes = {
  currentUser: PropTypes.instanceOf(Map),
  itemResponses: PropTypes.instanceOf(List),
  readEndpoint: PropTypes.func,
  userConfig: PropTypes.instanceOf(Map),
  updateResource: PropTypes.func
}

export default ScoreAssessmentController
