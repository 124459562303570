import helpfulResourcesConfiguration from './helpfulResourcesConfiguration.json'

export const validateState = state => Object.keys(helpfulResourcesConfiguration.domestic.stateGuides).includes(state)

export const firstResource = () => helpfulResourcesConfiguration.defaultChecklist

export const secondResource = state => (
  validateState(state) ?
    helpfulResourcesConfiguration.domestic.stateGuides[state] :
    helpfulResourcesConfiguration.international.defaultArticles[0]
)

export const thirdResource = state => (
  validateState(state) ?
    helpfulResourcesConfiguration.domestic.defaultArticles[0] :
    helpfulResourcesConfiguration.international.defaultArticles[1]
)

export const resourcesByProfile = state => [
  firstResource(),
  secondResource(state),
  thirdResource(state)
]
