import ResourceLink from './ResourceLink'
import './everplanPreviewHelpfulResources.scss'

const EverplanPreviewHelpfulResources = () => (
  <div className='everplan-preview-helpful-resources'>
    <h2 className='heading'>Helpful Resources</h2>
    <ResourceLink
      path='articles/10-things-to-do-after-a-death'
      title='10 Things To Do After A Death'
    />
    <ResourceLink
      path='articles/how-to-close-accounts-and-cancel-subscriptions-after-a-death'
      title='How To Close Accounts and Cancel Subscriptions'
    />
    <ResourceLink
      path='articles/how-to-settle-an-estate-pay-final-bills-dues-taxes-and-expenses'
      title='How To Pay Final Bills, Dues, Taxes And Expenses'
    />
    <ResourceLink
      path='settling-an-estate'
      title='View all'
    />
  </div>
)


export default EverplanPreviewHelpfulResources
