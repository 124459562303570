/** @class AuthGateway
 *
 * @desc
 * Controller component that checks for a session and checks if the current user is authorized to be
 * viewing the page they are on.
 */

import {Component} from 'react'
import PropTypes from 'prop-types'
import {withRouter} from 'react-router'

import LegacyLoader from './LegacyLoader'
import NewLoader from './NewLoader'
import TimeoutModal from './TimeoutModal'

import routerUtils from '../lib/routerUtils'
import SessionApi from '../auth/src/web/SessionApi'
import SessionStore from '../auth/src/stores/SessionStore'


export class AuthGateway extends Component {
  constructor() {
    super()

    this.state = {loading: true}

    this.authCheck = this.authCheck.bind(this)
    this.sessionCheck = this.sessionCheck.bind(this)
  }

  componentDidMount() {
    if (this.isLoggedIn()) {
      this.setState({loading: false})
      SessionStore.addChangeListener(this.authCheck)
      this.authCheck()
    } else {
      SessionStore.addChangeListener(this.sessionCheck)
      this.setState({loading: true})
      SessionApi.fetchCurrentUser()
    }
  }

  /** Redirects any user viewing a page they do not have authorization to be on based on a missing session or user property. */
  authCheck() {
    const currentUser = SessionStore.getState().currentUser

    if (this.needsAuth(window.location.hash) && !currentUser)
      return this.redirectToAuth()

    if (currentUser && this.props.needsFirmUser && currentUser.firm_user !== true)
      return this.redirectToNotFound()

    if (currentUser && this.props.needsAccountManager && currentUser.account_manager !== true)
      return this.redirectToNotFound()
  }

  /** Checks if the `SessionStore` is ready to use, and if so starts an authorization check. */
  sessionCheck() {
    if (SessionStore.initialized()) {
      this.setState({loading: false})
      SessionStore.removeChangeListener(this.sessionCheck)
      SessionStore.addChangeListener(this.authCheck)
      this.authCheck()
    }
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) { // @todo Look into whether this method should be checking `nextState` not `this.state`. --BLR
    if (this.state.loading === false) // Basically don't check if we are waiting on the store to tell us if there is a user
      this.authCheck()
  }

  componentWillUnmount() { SessionStore.removeChangeListener(this.authCheck) }

  /**
    * Redirects unauthenticated users to sign in if the page they are attempting to view requires a logged in user. Based
    * on props passed to this component, it may require additional permissions on the user. For example, to view certain
    * pages you must be an account manager.
    */
  needsAuth(path) {
    let needsAuth = true
    const whitelist = this.props.whitelist
    whitelist.map(whitelistedPath => {
      if (path.match(whitelistedPath))
        needsAuth = false
    })

    return needsAuth
  }

  /**
    * Sends a user to sign in page, with a `nextRoute` pointing to the page they are currently attempting to access
    * so they will be redirected there after successful login.
    */
  redirectToAuth() { routerUtils.setLocation(`${WEBAPPS_HOST}/#/auth/sign_in?nextRoute=${this.props.location.pathname.substr(1)}${this.props.location.search}`) }

  /** Sends a user to the 404 page. */
  redirectToNotFound() { routerUtils.push('404') }

  /** Checks if the current user has a valid session. */
  isLoggedIn() { return SessionStore.getState().signedIn }

  render() {
    const LoaderComponent = this.props.useNewLoader ? NewLoader : LegacyLoader

    return (
      <div>
        <TimeoutModal needsCheck={this.isLoggedIn()} />
        <LoaderComponent loading={this.state.loading}>
          {this.state.loading ? null : this.props.children}
        </LoaderComponent>
      </div>
    )
  }
}

AuthGateway.defaultProps = {
  whitelist: []
}

AuthGateway.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string
  }),
  needsAccountManager: PropTypes.bool,
  needsFirmUser: PropTypes.bool,
  useNewLoader: PropTypes.bool,
  whitelist: PropTypes.arrayOf(PropTypes.string)
}

export default withRouter(AuthGateway)
