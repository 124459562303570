import {Component} from 'react'
import {Map} from 'immutable'
import PropTypes from 'prop-types'

import AngleRightIcon from '../../core/svg_icons/icons/AngleRightIcon'
import Logger from '../../../lib/NewLogger'


export default class GapFillerGuideModalResourceLink extends Component {
  constructor() {
    super()

    this.logClickEvent = this.logClickEvent.bind(this)
  }

  logClickEvent() {
    Logger.log({
      name: 'clicked_gap_filler_guide_resource',
      payload: {
        context: this.props.context,
        item: this.props.itemResponse.get('name'),
        link_text: this.props.resource.get('text'),
        url: this.props.resource.get('url'),
        wildcard: this.props.itemResponse.get('name')
      }
    })
  }

  anchorAttributes() {
    return {
      className: 'gap-filler-guide-modal-resource-link',
      href: this.props.resource.get('url'),
      onClick: this.logClickEvent,
      rel: 'noopener noreferrer',
      target: '_blank'
    }
  }

  render() {
    if (this.props.resource && !this.props.resource.isEmpty()) {
      return (
        <a {...this.anchorAttributes()}>
          {this.props.resource.get('text')} <AngleRightIcon />
        </a>
      )
    } else {
      return null
    }
  }
}

GapFillerGuideModalResourceLink.propTypes = {
  resource: PropTypes.instanceOf(Map),
  context: PropTypes.string,
  itemResponse: PropTypes.instanceOf(Map)
}
