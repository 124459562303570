import classnames from 'classnames'

import './blocks.scss'


export const BuildingBlock = props => <div {...props} className={classnames(props.className)}>{props.children}</div>

export const ButtonGroupWrapper = props => <BuildingBlock {...props} className={classnames('button-group', props.className)} />

export const FullWidthPanel = props => <BuildingBlock {...props} className={classnames('full-width-panel', props.className)} />

export const LeftRail = props => <BuildingBlock {...props} className={classnames('left-rail', props.className)} />

export const Main = props => <main className={classnames(props.className)}>{props.children}</main>

export const RightPanel = props => <BuildingBlock {...props} className={classnames('right-panel', props.className)} />

export const TopPanel = props => <BuildingBlock {...props} className={classnames('top-panel', props.className)}><div className='top-panel-container'>{props.children}</div></BuildingBlock>
