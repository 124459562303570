import PropTypes from 'prop-types'
import {List, Map} from 'immutable'

import CorpMaxDeathVerificationPeriodInstructions from './CorpMaxDeathVerificationPeriodInstructions'
import HouseholdStepTwoInstructions from './HouseholdStepTwoInstructions'
import {TextGutterMedium} from '../../blocks/Texts'
import {Button} from '../../blocks/Buttons'
import {remainingHouseholdMember} from '../../../../../lib/corpMaxDeputyTools'


const CorpMaxStepTwo = props => {
  const {
    closeModal,
    displayName,
    firstNameOnly,
    ownerToMarkDeceased,
    ownerships,
    userEverplan
  } = props

  const bothHouseholdMembersAreDeceased = () => remainingHouseholdMember(
    {ownerToMarkDeceased, ownerships}
  ).get('deceased')

  let planholderFirstName

  if (userEverplan.get('is-household') && bothHouseholdMembersAreDeceased())
    planholderFirstName = firstNameOnly
  else if (ownerToMarkDeceased.has('first-name'))
    planholderFirstName = ownerToMarkDeceased.get('first-name')
  else
    planholderFirstName = firstNameOnly

  return (
    <div className='step-two'>
      <h3>
        {displayName} has been reported as deceased.
      </h3>
      <CorpMaxDeathVerificationPeriodInstructions planholderName={planholderFirstName} {...props} />
      <HouseholdStepTwoInstructions {...props} planholderFirstName={planholderFirstName} />
      <TextGutterMedium>
        If you need help tackling the many issues that may arise--settling estates, managing loss,
        understanding probate, and more--we offer a wealth of free articles and how-tos on our website.
        <a href='https://www.everplans.com/settling-an-estate' rel='noopener noreferrer' target='_blank'> Learn more</a>
      </TextGutterMedium>
      <Button onClick={closeModal}>Done</Button>
    </div>
  )
}

export default CorpMaxStepTwo

CorpMaxStepTwo.propTypes = {
  closeModal: PropTypes.func,
  displayName: PropTypes.string,
  firstNameOnly: PropTypes.string,
  ownerships: PropTypes.instanceOf(List),
  ownerToMarkDeceased: PropTypes.instanceOf(Map),
  userEverplan: PropTypes.instanceOf(Map)
}
