import PropTypes from 'prop-types'
import {Map} from 'immutable'

import AccountSettingsRow from './AccountSettingsRow'

const ProfessionalPartnerInformation = ({userConfig, advisorContactCard, accountSetting}) => {
  if (userConfig.get('client')) {
    const bodyText = () => {
      const firstSentence = `${advisorContactCard.get('name')} is your Everplans Professional Partner.`

      if (accountSetting.get('is-write-access-enabled'))
        return `${firstSentence} See contact info and manage editing permissions.`
      else
        return `${firstSentence} Please contact your professional partner if you'd like to disassociate your account from your partner.`
    }

    return (
      <AccountSettingsRow
        title='Everplans Professional Partner:'
        bodyText={bodyText()}
        url='/cm/account-settings/my-professional-partner'
      />
    )
  } else {
    return null
  }
}

ProfessionalPartnerInformation.propTypes = {
  accountSetting: PropTypes.instanceOf(Map),
  advisorContactCard: PropTypes.instanceOf(Map),
  userConfig: PropTypes.instanceOf(Map)
}

export default ProfessionalPartnerInformation
