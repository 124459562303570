/** @module assessmentBitMappingsDictionary
 *
 * @desc
 * This is a dictionary that has assessment stage as the keys and next best action
 * details to suppress as the value. When an assessment stage from an assessment
 * is false, then the corresponding next best action is removed from the next best actions shown to the user.
 */

import {fromJS} from 'immutable'

/**
 * Bits to be removed when assessment key is false
 */
export const ASSESSMENT_TO_NEXT_BEST_ACTIONS_TO_BE_REMOVED_FOR_FALSE_ANSWERS = fromJS({
  will: [{'item-slug': 'will-details-about-your-will'}],
  employed: [{'item-slug': 'employment-my-employment', 'conditional-value': null}],
  partnered: [{'item-slug': 'my-family-my-spouse'}],
  'business-owner': [{'item-slug': 'financial-businesses'}],
  'life-insurance': [{'item-slug': 'life-insurance-my-life-insurance-policy'}],
  'employer-life-insurance': [{'item-slug': 'life-insurance-my-life-insurance-policy'}],
  'standalone-life-insurance': [{'item-slug': 'life-insurance-my-life-insurance-policy'}],
  'advance-directive': [{'item-slug': 'advance-directive'}],
  'power-of-attorney': [{'item-slug': 'power-of-attorney-my-power-of-attorney'}],
  'longterm-care-insurance': [{'item-slug': 'long-term-care-insurance-my-long-term-care-policy'}]
})
