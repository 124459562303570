import {connect} from 'react-redux'
import {fromJS, Map, List} from 'immutable'
import {useMutation} from '@apollo/client'
import {useState, useRef, useMemo} from 'react'
import {withRouter} from 'react-router'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import uuid from 'uuid'

import {Icon} from '../../../../../shared_components/Icon'
import {ModalHeader} from '../../../../../shared_components/core/modals/ModalHeaders'
import * as Buttons from '../../blocks/Buttons'
import ButtonGroup from '../../../../../shared_components/core/buttons/ButtonGroup'
import Closer from '../../../../../shared_components/core/closer/Closer'
import Fields from '../../../../../shared_components/forms/v2/fields'
import Form from '../../../../../shared_components/forms/v2/Form'
import Modals from '../../../../../shared_components/core/modals/Modals'
import multipleFormFieldWrapper from '../../../../../shared_components/forms/v2/MultipleFormFieldWrapper'

import {camelizeImmutableData} from '../../../../../lib/graphQLTools'
import {contactLabel, GENDER_OPTIONS} from '../../../../../lib/contactTools'
import {CONTACTS} from '../../../../../graphql/queries/userData'
import {CREATE_CONTACT, UPDATE_CONTACT} from '../../../../../graphql/mutations/userData'
import {currentUserConfig} from '../../../../../lib/userTools'
import {dateWithMaskFromForm} from '../../../../../lib/dateTools'
import {findSelectedValueId} from '../../../../../shared_components/forms/v2/lib/tools'
import Logger from '../../../../../lib/NewLogger'
import validators from '../../../../../shared_components/forms/v2/validators'

import './newContact.scss'


const multipleFields = Map({
  email: 'emails',
  address: 'addresses',
  'phone-number': 'phone-numbers'
})

const Addresses = multipleFormFieldWrapper(Fields.Address)
const Emails = multipleFormFieldWrapper(Fields.Email)
const Phones = multipleFormFieldWrapper(Fields.Tel)

const formatContactHeading = selectedContact => {
  if (selectedContact.isEmpty())
    return 'New Contact'

  return contactLabel(selectedContact)
}

export const NewContact = props => {
  const formRef = useRef()
  const contactHeading = useMemo(() => formatContactHeading(props.selectedContact), [])

  const [hideAdditionalFields, setHideAdditionalFields] = useState(props.hideAdditionalFields)
  const [processing, setProcessing] = useState(false)
  const [showFormLevelError, setShowFormLevelError] = useState(false)

  const [updateContact] = useMutation(UPDATE_CONTACT)

  const [createContact] = useMutation(CREATE_CONTACT, {
    update: (cache, {data}) => {
      const {contacts} = cache.readQuery({query: CONTACTS, variables: {everplanId: props.everplanId}})

      cache.writeQuery({
        query: CONTACTS,
        data: {contacts: [...contacts, data.createContact]},
        variables: {everplanId: props.everplanId}
      })
    }
  })

  const logEvent = event => {
    Logger.log({
      name: event,
      payload: {
        context: props.context,
        element_id: props.element.get('id'),
        item: props.itemName,
        type: props.eventType,
        view_id: props.view.get('id'),
        wildcard: props.itemName
      }
    })
  }

  const isMissingMandatoryField = formData => (
    !formData.keySeq().some(key =>
      ['first-name', 'last-name', 'company-name'].includes(key)
    )
  )

  const invalidFormError = formData => {
    const errors = []

    if (formRef.current) {
      if (isMissingMandatoryField(formData))
        errors.push('Please add either name or company.')

      if (!formRef.current.errors('birthdate').isEmpty())
        errors.push('Please add a valid date.')
    }

    return List(errors)
  }

  const birthdateDataFromForm = formData => {
    const birthdate = formData.get('birthdate', Map())

    if (birthdate.isEmpty()) {
      return formData.delete('birthdate')
    } else {
      const dateWithMask = birthdate.get('date') ? birthdate : dateWithMaskFromForm(birthdate)

      return formData
        .set('birthdate', dateWithMask.get('date'))
        .set('birthdate-mask', dateWithMask.get('mask'))
    }
  }

  const cleanAddress = formData => {
    const addressList = formData.get('addresses')

    if (addressList)
      return formData.set('addresses', addressList.map(address => address.delete('typename')))
    else
      return formData
  }

  const createOrUpdateContact = variables => {
    if (props.selectedContact.get('id')) {
      logEvent('edited_contact')
      updateContact({
        variables,
        onCompleted: returnedData => {
          setProcessing(false)
          props.hideContactModal(returnedData.updateContact.id)
        }
      })
    } else {
      logEvent('created_new_contact')
      createContact({
        variables,
        onCompleted: returnedData => {
          setProcessing(false)
          props.hideContactModal(returnedData.createContact.id)
        }
      })
    }
  }


  /** Method to flatten a contact's name to what the api expects
   * @param {Map} contact A Map that contains contact to be created or updated
   * @return {Map} A Map that has the name flattened out
  */
  const flattenName = contact => contact.merge(contact.get('name')).delete('name')

  const constructName = data => Map({
    'first-name': data.get('first-name'),
    'middle-name': data.get('middle-name'),
    'last-name': data.get('last-name')
  })

  const constructBirthdate = data => {
    if (data.get('birthdate'))
      return {date: data.get('birthdate'), mask: data.get('birthdate-mask')}
  }

  // Need to clear the form errors when anything is changed otherwise the form always stays invalid
  const clearFormErrors = () => {
    if (showFormLevelError)
      setShowFormLevelError(false)
  }

  const toggleAdditionalFields = () => {
    setHideAdditionalFields(prevHideAdditionalFields => {
      Logger.log({
        name: prevHideAdditionalFields ? 'clicked_expand_contact_form' : 'clicked_collapse_contact_form',
        payload: {context: props.context}
      })

      return !prevHideAdditionalFields
    })
  }

  const cancel = () => {
    logEvent('closed_add_new_contact')
    props.hideContactModal()
  }

  const formDataPayload = () => {
    let formData = formRef.current.formData().map(field => field.get('value'))

    formData = flattenName(formData).filter(value => value)

    formData = birthdateDataFromForm(formData)

    const gender = findSelectedValueId({selectId: 'gender', form: formRef.current})

    if (gender)
      formData = formData.set('gender', gender)


    multipleFields.forEach((fieldValue, field) => {
      const fields = formData.filter((value, key) => key.startsWith(field))
      formData = fields.reduce((data, value, key) => data.delete(key), formData)

      if (!fields.isEmpty())
        formData = formData.set(fieldValue, fields.toList())
    })

    return formData.set('id', props.selectedContact.isEmpty() ? uuid.v4() : props.selectedContact.get('id'))
  }

  const submit = () => {
    formRef.current.onSubmit()
    let formData = formDataPayload()

    if (formRef.current.isValid() && !isMissingMandatoryField(formData)) {
      formData = cleanAddress(formData)

      const variables = {
        input: camelizeImmutableData(formData),
        everplanId: props.everplanId
      }
      setProcessing(true)
      createOrUpdateContact(variables)
    } else {
      setShowFormLevelError(true)
    }
  }

  const contactData = (formRef.current && formDataPayload()) || props.selectedContact || Map()
  const genderValue = contactData.get('gender') ? GENDER_OPTIONS.find(genderOption => genderOption.get('id') === contactData.get('gender')).get('value') : GENDER_OPTIONS.getIn([0, 'value'])

  return (
    <Modals.LinkPopUpModalLarge
      className='new-contact forms-playground'
      closerComponent={() => <Closer closer={cancel} />}
      showModal={true}>
      <ModalHeader heading={contactHeading} />
      <Form
        onChange={clearFormErrors}
        className='core new-contact-form'
        ref={formRef}
        invalidFormError={invalidFormError(contactData)}
        showFormLevelError={showFormLevelError}>
        <Fields.Name
          data={
            fromJS({
              id: 'name',
              legend: 'Name',
              name: 'name',
              value: constructName(contactData)
            })
          }
          showMiddleInitial={true}
        />
        <Emails
          name='email'
          legend='Email'
          values={contactData.get('emails')}
        />
        <Phones
          name='phone-number'
          legend='Phone'
          values={contactData.get('phone-numbers')}
        />
        <Fields.Text
          data={
            fromJS({
              id: 'company-name',
              legend: 'Company',
              name: 'company-name',
              value: contactData.get('company-name')
            })
          }
        />
        <a onClick={toggleAdditionalFields} className='additional-contact-field-link'>
          <Icon className={hideAdditionalFields ? 'fa-angle-right' : 'fa-angle-down'} />
          <span className='additional-contact-field-text'>
            {hideAdditionalFields ? 'Show ' : 'Hide '}
              additional contact fields
          </span>
        </a>
        <div className={classnames({hide: hideAdditionalFields})}>
          <Addresses
            className='address-field'
            name='address'
            legend='Address'
            values={contactData.get('addresses')}
          />
          <Fields.Date
            data={
              fromJS({
                id: 'birthdate',
                legend: 'Birthdate',
                name: 'birthdate',
                value: constructBirthdate(contactData)
              })
            }
            validator={validators.responseDateValidator}
            className='birthdate'
          />
          <Fields.Text
            data={
              fromJS({
                id: 'website',
                legend: 'Website',
                name: 'website',
                value: contactData.get('website')
              })
            }
          />
          <Fields.Select
            data={
              fromJS({
                id: 'gender',
                items: GENDER_OPTIONS,
                legend: 'Gender',
                name: 'gender',
                value: genderValue
              })
            }
          />
          <Fields.TextArea
            data={
              fromJS({
                id: 'notes',
                legend: 'Notes',
                name: 'notes',
                value: contactData.get('notes')
              })
            }
          />
        </div>
      </Form>
      <ButtonGroup>
        <Buttons.Cancel onClick={cancel} />
        <Buttons.Button onClick={submit} processing={processing}>
          Submit
        </Buttons.Button>
      </ButtonGroup>
    </Modals.LinkPopUpModalLarge>
  )
}

NewContact.defaultProps = {
  selectedContact: Map(),
  processing: false,
  view: Map(),
  element: Map(),
  hideAdditionalFields: true
}

NewContact.propTypes = {
  context: PropTypes.string,
  element: PropTypes.instanceOf(Map),
  eventType: PropTypes.string,
  everplanId: PropTypes.number,
  hideAdditionalFields: PropTypes.bool,
  hideContactModal: PropTypes.func,
  itemName: PropTypes.string,
  processing: PropTypes.bool,
  selectedContact: PropTypes.instanceOf(Map),
  view: PropTypes.instanceOf(Map)
}

const mapStateToProps = ({api}, ownProps) => ({
  // If the route has an `everplanId` then do not assume that the plan being edited
  // is the current user's plan:
  everplanId: parseInt((ownProps.params && ownProps.params.everplanId) || currentUserConfig(api).get('everplan-id'), 10)
})

export default withRouter(connect(mapStateToProps)(NewContact))
