import {Component} from 'react'
import PropTypes from 'prop-types'

import 'react-phone-number-input/style.css'
import PhoneInput, {formatPhoneNumber, isValidPhoneNumber} from 'react-phone-number-input'
import flags from 'react-phone-number-input/flags'

import './phoneForm.scss'

export default class PhoneEditForm extends Component {
  constructor(props) {
    super(props)

    this.state = {mode: props.mode}
    this.onChange = this.onChange.bind(this)
    this.updateMode = this.updateMode.bind(this)
  }

  onChange(value = '') {
    const isValid = isValidPhoneNumber(value)
    const nationalNumber = formatPhoneNumber(value)
    this.props.setFormValue(isValid, nationalNumber, value)
  }

  updateMode(event) {
    const mode = event.target.value
    this.props.setMode(mode)
  }

  render() {
    return (
      <div>
        <div className='field-group'>
          <div className='form-label'>Phone number:</div>
          <PhoneInput
            className='phone-number-container'
            numberInputProps={{className: 'phone-number'}}
            autoFocus={true}
            value={this.props.number}
            onChange={this.onChange}
            defaultCountry='US'
            flags={flags}
            limitMaxLength={true}
            initialValueFormat='national'
          />
          <span className='error'>{this.props.error}</span>
        </div>
        <div className='field-group'>
          <div className='form-label'> How should we send code&#63; </div>
          <div className='alternate-delivery-option'>
            <input type='radio' name='message-mode' value='text' className='tfa-radio-btn' defaultChecked={this.props.mode === 'text'} onChange={this.updateMode} />
            <label htmlFor='text'>Text Message</label>
          </div>
          <div className='alternate-delivery-option'>
            <input type='radio' name='message-mode' value='voice' className='tfa-radio-btn' onChange={this.updateMode} defaultChecked={this.props.mode === 'voice'} />
            <label htmlFor='voice'>Voice Call</label>
          </div>
        </div>
      </div>
    )
  }
}

PhoneEditForm.propTypes = {
  number: PropTypes.string,
  mode: PropTypes.string,
  error: PropTypes.string,
  setFormValue: PropTypes.func,
  setMode: PropTypes.func
}
