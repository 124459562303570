import PropTypes from 'prop-types'
import {Map} from 'immutable'

import {TextGutterMedium} from '../../blocks/Texts'

import {addPossessive} from '../../../../../lib/tools'
import {showDeathVerification} from '../../../../../lib/corpMaxDeputyTools'
import {verificationPeriodMinutesToString} from '../../../../../lib/deathVerificationPeriodTools'

const CorpMaxDeathVerificationPeriodInstructions = props => {
  if (showDeathVerification({userEverplan: props.userEverplan, ownerToMarkDeceased: props.ownerToMarkDeceased, ownerships: props.ownerships})) {
    const deathVerificationPeriodString = verificationPeriodMinutesToString(props.deputyOwnership.get('death-verification-period'))
    return (
      <div>
        <TextGutterMedium>
          {
            props.deputyOwnership.get('death-verification-period') > 0 ?
              (
                <p>
                  {props.planholderName} selected to have <strong>{deathVerificationPeriodString} wait
                  time</strong> before {props.pronoun} Everplan is unlocked. We will notify you via email
                  once {props.pronoun} Everplan is unlocked.
                </p>
              ) :
              `${addPossessive(props.planholderName)} "after death" items have been unlocked.`
          }
        </TextGutterMedium>
      </div>
    )
  } else {
    return <noscript />
  }
}


CorpMaxDeathVerificationPeriodInstructions.propTypes = {
  deputyOwnership: PropTypes.instanceOf(Map),
  userEverplan: PropTypes.instanceOf(Map),
  planholderName: PropTypes.string,
  ownerToMarkDeceased: PropTypes.instanceOf(Map)
}

export default CorpMaxDeathVerificationPeriodInstructions
