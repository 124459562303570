import {Component} from 'react'
import PropTypes from 'prop-types'

import WizardActions from '../../../actions/WizardActions'
import PhoneEditForm from '../shared/PhoneEditForm'
import Button from '../../../../../shared_components/Button'


export default class PrimaryPhoneForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      mode: 'text',
      loading: false,
      isValid: false,
      nationalNumber: '',
      value: '',
      error: ''
    }

    this.clearErrors = this.clearErrors.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.setFormValue = this.setFormValue.bind(this)
    this.setMode = this.setMode.bind(this)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.error) {
      this.setState({
        loading: false,
        error: nextProps.error
      })
    }
  }

  setFormValue(isValid, nationalNumber, value) {
    this.setState({isValid, nationalNumber, value})
  }

  setMode(mode) { this.setState({mode}) }

  handleSubmit(event) {
    if (event) event.preventDefault()
    if (this.state.isValid) {
      this.setState({loading: true})
      WizardActions.setPrimaryPhone({
        number: this.state.value,
        nationalNumber: this.state.nationalNumber,
        preferredMessageMode: this.state.mode
      })
    } else {
      this.setState({error: 'Please enter a valid number.'})
    }
  }

  clearErrors() {
    WizardActions.clearTfaErrors()
    this.setState({error: null})
  }

  render() {
    return (
      <div className='primary-phone'>
        <h3>Primary Phone Number</h3>
        <hr />
        <div className='wizard-body'>
          <form onSubmit={this.handleSubmit}>
            <div>
              <p className='phone-text'>
                Which phone number should we send your two-step verification codes to?
              </p>
              <div onFocus={this.clearErrors}>
                <PhoneEditForm
                  error={this.state.error}
                  mode={this.state.mode}
                  setFormValue={this.setFormValue}
                  setMode={this.setMode}
                />
              </div>
            </div>
          </form>
        </div>
        <div>
          <Button className='wizard-primary-btn' onClick={this.handleSubmit} loading={this.state.loading}>Send code</Button>
        </div>
      </div>
    )
  }
}

PrimaryPhoneForm.propTypes = {
  closer: PropTypes.func,
  error: PropTypes.string
}
