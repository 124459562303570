import {Component} from 'react'
import {connect} from 'react-redux'
import {Map} from 'immutable'
import PropTypes from 'prop-types'
import {readEndpoint} from 'redux-json-api'

import Loader from '../../../../../shared_components/NewLoader'
import PageTitle from '../../../../../shared_components/PageTitle'
import Privacy from './Privacy'
import ProvidedByInformation from './ProvidedByInformation'
import ManageItemPermissionsContainer from './manage_item_permissions/ManageItemPermissionsContainer'
import {currentUserConfig} from '../../../../../lib/userTools'
import {findRawResourceByAttributeId} from '../../../../../lib/plan_data/userData'

import './myProfessionalPartner.scss'

class MyProfessionalPartnerContainer extends Component {
  componentDidMount() {
    const everplanId = this.props.userConfig.get('everplan-id')

    this.props.readEndpoint(`everplans/${everplanId}?include=firm-ownership`)
    this.props.readEndpoint(`advisor-contact-cards/${everplanId}`)
  }

  render() {
    if (this.props.loading) {
      return <Loader loading={true} />
    } else {
      return (
        <div className='my-professional-partner'>
          <PageTitle title='Everplans Professional Partner - My Account' />
          <h1>Everplans Professional Partner</h1>
          <ProvidedByInformation advisorContactCard={this.props.advisorContactCard} />
          <ManageItemPermissionsContainer {...this.props} />
          <Privacy advisorFullName={this.props.advisorContactCard.get('name')} />
        </div>
      )
    }
  }
}

MyProfessionalPartnerContainer.propTypes = {
  advisorContactCard: PropTypes.instanceOf(Map),
  firmOwnership: PropTypes.instanceOf(Map),
  loading: PropTypes.bool,
  readEndpoint: PropTypes.func,
  userConfig: PropTypes.instanceOf(Map)
}


const mapStateToProps = ({api}) => {
  const userConfig = currentUserConfig(api)

  const advisorContactCard = findRawResourceByAttributeId({
    rawResource: api['advisor-contact-cards'],
    attribute: 'everplan-id',
    id: userConfig.get('everplan-id')
  })

  const firmOwnership = findRawResourceByAttributeId({
    rawResource: api['firm-ownerships'],
    attribute: 'everplan-id',
    id: userConfig.get('everplan-id')
  })

  return ({
    firmOwnership,
    advisorContactCard,
    loading: (advisorContactCard.isEmpty() || firmOwnership.isEmpty()),
    userConfig
  })
}

export default connect(mapStateToProps, {readEndpoint})(MyProfessionalPartnerContainer)

