import {useCallback} from 'react'
import PropTypes from 'prop-types'
import routerUtils from '../../../../../lib/routerUtils'

import GapFillerAssessmentContainer, {
  DIGITAL_SUBSCRIPTION
} from '../GapFillerAssessmentContainer'


const DigitalSubscriptionLinkContainer = ({params}) => {
  const goToCategory = useCallback(
    () => routerUtils.push(`cm/category/${params.slug}`),
    [params.slug]
  )

  return (
    <GapFillerAssessmentContainer
      toggleGapFillerAssessmentModal={goToCategory}
      itemSlug={DIGITAL_SUBSCRIPTION}
    />
  )
}

DigitalSubscriptionLinkContainer.propTypes = {
  params: PropTypes.shape({
    slug: PropTypes.string
  })
}


export default DigitalSubscriptionLinkContainer
