/** @module currencyTools
 *
 * @desc
 * Helpers related to `Currency`
 */

export const currencyAmountWithSymbol = ({currency = 'USD', amount}) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency
  })

  return formatter.format(amount)
}
