/** @class ExitPreview
 *
 * @desc
 * Renders a link to exit the Everplan preview page.
 *
 */

import PropTypes from 'prop-types'

import routerUtils from '../../../../../lib/routerUtils'
import Logger from '../../../../../lib/NewLogger'
import {corpMaxDashboardPath} from '../../../../../lib/urlTools'


import './exitPreview.scss'

const ExitPreview = props => {
  const logAndExitToDashboard = () => {
    Logger.log({
      name: 'clicked_exit_preview',
      payload: {
        actor: props.actor,
        context: props.context,
        everplan_id: props.everplanId
      }
    })
    routerUtils.push(corpMaxDashboardPath)
  }

  return (
    <div className='exit-preview'>
      <a className='exit-preview-link' onClick={logAndExitToDashboard}> &lt; Exit Preview</a>
    </div>
  )
}

ExitPreview.propTypes = {
  actor: PropTypes.string,
  context: PropTypes.string,
  everplanId: PropTypes.number
}

export default ExitPreview

