import {useForm, FormProvider} from 'react-hook-form'
import {useMutation} from '@apollo/client'
import {Stack} from '@mui/material'
import {useState} from 'react'
import PropTypes from 'prop-types'

import {ModalHeader} from '../../../../../shared_components/core/modals/ModalHeaders'
import Button from '../../../../../shared_components/mui_base_components/button/Button'
import ButtonGroup from '../../../../../shared_components/core/buttons/ButtonGroup'
import Email from '../../../../../shared_components/forms/v3/Email'
import LinkButton from '../../../../../shared_components/mui_base_components/link_button/LinkButton'
import Name from '../../../../../shared_components/forms/v3/Name'
import Select from '../../../../../shared_components/forms/v3/Select'

import {DEPUTY_VALIDATION} from '../../../../../graphql/mutations/deputy'

import './addDeputyStepOne.scss'

const RELATIONSHIPS = [ // Temporary stubbing of relationships until we get them from the DB. --BLR
  {label: 'Select a relationship', value: ''},
  {label: 'Spouse/Domestic Partner', value: 'Spouse/Domestic Partner'},
  {label: 'Child', value: 'Child'},
  {label: 'Parent', value: 'Parent'},
  {label: 'Sibling', value: 'Sibling'},
  {label: 'Friend', value: 'Friend'},
  {label: 'Advisor', value: 'Advisor'},
  {label: 'Attorney', value: 'Attorney'},
  {label: 'Caregiver/Sitter', value: 'Caregiver/Sitter'},
  {label: 'Other', value: 'Other'}
]

const AddDeputyStepOne = props => {
  const [deputyValidation] = useMutation(DEPUTY_VALIDATION, {errorPolicy: 'all'})
  const [processing, setProcessing] = useState(false)

  const methods = useForm({defaultValues: props.deputy})

  const submit = data => {
    setProcessing(true)

    deputyValidation({
      variables: {
        input: {
          email: data.email,
          firstName: data['first-name'],
          lastName: data['last-name']
        }
      },
      onCompleted: response => {
        setProcessing(false)

        props.nextStep({...data, firmUser: response.deputyValidation.firmUser})
      },
      onError: error => {
        setProcessing(false)

        methods.setError('email', {
          type: 'manual',
          message: error.graphQLErrors[0].extensions.title
        })
      }
    })
  }

  return (
    <div className='add-deputy-step-one'>
      <ModalHeader
        heading='Who are you adding as your deputy?'
        subheading='It can be your spouse/partner, family member, or anyone
                    you trust. You have full control over what you share with
                    them.'
      />
      <FormProvider {...methods}>
        <Stack component='form' gap={2}>
          <Name />
          <Email />
          <Select
            required
            name='deputy-relationship'
            label='Relationship'
            placeholder='Select a relationship'
            errorMessage='Please select the relationship.'
            options={RELATIONSHIPS}
          />
        </Stack>
        <ButtonGroup>
          <Button onClick={methods.handleSubmit(submit)} loading={processing}>
            Choose permissions
          </Button>
          {
            props.showSkipLinkButton && (
              <LinkButton disabled={processing} onClick={props.handleSkip}>
              Skip
              </LinkButton>
            )
          }
        </ButtonGroup>
      </FormProvider>
    </div>
  )
}

AddDeputyStepOne.propTypes = {
  deputy: PropTypes.object,
  createResource: PropTypes.func,
  handleSkip: PropTypes.func,
  nextStep: PropTypes.func,
  showSkipLinkButton: PropTypes.bool
}

export default AddDeputyStepOne
