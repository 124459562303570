import PropTypes from 'prop-types'

import {addPossessive} from '../../../../lib/tools'
import BlankAdvisorHeadshot from '../../../../shared_assets/default-advisor-avatar.svg'
import ContactItemContainer from './ContactItemContainer'
import EnvelopeIcon from '../../../../shared_components/svg_icons/icons/EnvelopeIcon'
import ModuleBox from '../../../../shared_components/component_templates/ModuleBox'
import PhoneButton from './PhoneButton'
import PhoneButtonMobile from './PhoneButtonMobile'
import websiteIcon from '../../../../dashboard/assets/globe-icon.svg'
import bindResources from '../../../../shared_components/BindToStores'
import Container from '../../../../lib/Container'
import ContactCardActions from '../../../../shared_actions/ContactCardActions'

Container.registerAction('contact_card', ContactCardActions)

import './contactCard.scss'

const ContactCard = ({contact_card}) => (
  <div className='contact-card'>
    <div className='contact-card-header'>Your Everplan is provided by:</div>
    <ModuleBox className='box flex-container'>
      <div className='headshot'>
        <img
          alt={`Your Advisor, ${contact_card.data.name}`}
          src={contact_card.data.head_shot ? contact_card.data.head_shot : BlankAdvisorHeadshot}
        />
      </div>
      <div className='contact-names flex-child'>
        <div className='advisor-name'>{contact_card.data.name}</div>
        {
          contact_card.data.title &&
          <div className='additional-data'>{contact_card.data.title}</div>
        }
        {
          contact_card.data.accreditation &&
          <div className='additional-data'>{contact_card.data.accreditation}</div>
        }
        <div className='additional-data'>{contact_card.data.firm_name}</div>
      </div>
      <div className='contact-info flex-child flex-container'>
        {
          contact_card.data.phone && (
            <ContactItemContainer>
              <PhoneButtonMobile phoneNumber={contact_card.data.phone} />
              <PhoneButton
                className='contact-link phone-button-desktop'
                phoneNumber={contact_card.data.phone}
              />
            </ContactItemContainer>
          )
        }
        <ContactItemContainer>
          <a className='contact-link email' href={`mailto:${contact_card.data.email}`} title={`Send ${contact_card.data.name} an email`}>
            <EnvelopeIcon />
            Email
          </a>
        </ContactItemContainer>
        {
          contact_card.data.url && (
            <ContactItemContainer>
              <a className='contact-link' href={contact_card.data.url} target='_blank' title={`Visit ${addPossessive(contact_card.data.firm_name)} website`} rel='noreferrer'>
                <img alt='' src={websiteIcon} />
                Website
              </a>
            </ContactItemContainer>
          )
        }
      </div>
    </ModuleBox>
  </div>
)

ContactCard.defaultProps = {
  contact_card: {
    data: {
      head_shot: '',
      accreditation: '',
      email: '',
      firm_name: '',
      name: '',
      phone: '',
      title: '',
      url: ''
    }
  }
}

ContactCard.propTypes = {
  contact_card: PropTypes.shape({
    data: PropTypes.shape({
      head_shot: PropTypes.string,
      accreditation: PropTypes.string,
      email: PropTypes.string,
      firm_name: PropTypes.string,
      name: PropTypes.string,
      phone: PropTypes.string,
      title: PropTypes.string,
      url: PropTypes.string
    })
  })
}

export {ContactCard}

export default bindResources(
  ContactCard,
  {name: 'contact_card', type: 'itemNoId'}
)
