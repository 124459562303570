import {Component, Fragment} from 'react'
import {connect} from 'react-redux'
import {Map} from 'immutable'
import * as reduxActions from 'redux-json-api'
import PropTypes from 'prop-types'

import Versions from './Versions'
import VersionFormController from './version_form/VersionFormController'

import {currentUserConfig} from '../../../../../lib/userTools'
import {cleanResponse} from '../redux/apiHelper'
import {findResourceById, findRawResourceByAttributeId} from '../../../../../lib/plan_data/userData'
import {redirectToDashboardFromTools} from '../../../../../lib/urlTools'

export class VersionToolController extends Component {
  constructor(props) {
    super(props)

    redirectToDashboardFromTools(props)
  }

  componentDidMount() {
    this.props.readEndpoint('settings')
    this.props.readEndpoint('metadata/versions')
  }

  render() {
    if (this.props.environmentVersion.isEmpty()) {
      return null
    } else {
      return (
        <Fragment>
          <VersionFormController {...this.props} />
          <Versions {...this.props} />
        </Fragment>
      )
    }
  }
}

VersionToolController.propTypes = {
  environmentVersion: PropTypes.instanceOf(Map),
  readEndpoint: PropTypes.func
}


const mapStateToProps = state => {
  const versions = cleanResponse(state.api.versions)
  const setting = findRawResourceByAttributeId({rawResource: state.api.settings, attribute: 'name', id: 'metadata_version'})
  return {
    environmentVersion: findResourceById({resourceList: versions, id: setting.get('value')}),
    setting,
    userConfig: currentUserConfig(state.api),
    versions
  }
}

export default connect(mapStateToProps, reduxActions)(VersionToolController)
