import {List} from 'immutable'
import PropTypes from 'prop-types'

import {ContentBoxGutterLarge} from '../../blocks/ContentBoxes'
import DeathReportStatus from './header/DeathReportStatus'
import UnlockEverplanLink from './header/UnlockEverplanLink'
import EverplanPreviewHelpfulResources from './header/EverplanPreviewHelpfulResources'
import {allOwnersDeceased, emptyEverplanText} from '../../../../../lib/corpMaxDeputyTools'

const EmptyEverplanBox = props => (
  <ContentBoxGutterLarge className='empty-everplan-box'>
    <div className='empty-everplan-text'>{emptyEverplanText(props)}</div>
    <DeathReportStatus {...props} />
    <UnlockEverplanLink {...props} />
    {allOwnersDeceased({ownerships: props.ownerships, deathReports: props.deathReports}) && <EverplanPreviewHelpfulResources />}
  </ContentBoxGutterLarge>
)


EmptyEverplanBox.propTypes = {
  deathReports: PropTypes.instanceOf(List),
  isHousehold: PropTypes.bool,
  ownEverplan: PropTypes.bool,
  ownerships: PropTypes.instanceOf(List)
}

export default EmptyEverplanBox

