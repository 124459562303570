import PropTypes from 'prop-types'

import {List} from 'immutable'

import {Button, Stack, Typography} from '@mui/material'

import DeputyForAvatar from './DeputyForAvatar'
import allDeputizersCaret from '../../../../../dashboard/assets/right-caret-262626.svg'
import routerUtils from '../../../../../lib/routerUtils'
import {corpMaxEverplanPreviewPath} from '../../../../../lib/urlTools'

import './deputyForSection.scss'
import Logger from '../../../../../lib/NewLogger'
import MixpanelLogger from '../../../../../lib/MixpanelLogger'

const DeputyForSection = ({deputizers, numberOfAvatarsToDisplay}) => {
  if (deputizers.isEmpty())
    return null

  const logMixpanelEvent = name => {
    MixpanelLogger.track(name, {context: 'dashboard'})
  }

  const handleOnDeputizerClick = deputizer => {
    if (deputizer.get('ownable-type') === 'User') {
      Logger.log({name: 'clicked_everplan_preview', payload: {context: 'dashboard'}})
      routerUtils.push(`${corpMaxEverplanPreviewPath(deputizer.get('everplan-id'))}?context=dashboard`)
    } else {
      logMixpanelEvent('click_deputy_for')
      Logger.log({name: 'clicked_im_a_deputy_for', payload: {context: 'dashboard'}})
      routerUtils.push('cm/deputy-for')
    }
  }

  const handleSeeAllDeputizersClick = () => {
    logMixpanelEvent('click_deputy_for')
    Logger.log({
      name: 'clicked_im_a_deputy_for',
      payload: {
        context: 'dashboard'
      }
    })
    routerUtils.push('cm/deputy-for')
  }

  return (
    <Stack className='deputy-for-section' spacing={2} >
      <Typography className='body-text deputizers-section-label' gutterBottom>Shared with me</Typography>
      <Stack className='deputizers-caret-container' direction='row' spacing={2}>
        <Stack className='deputizers-container' direction='row' justifyContent='space-between'>
          {
            deputizers.slice(0, numberOfAvatarsToDisplay).map(deputizer =>
              (
                <DeputyForAvatar
                  key={deputizer.get('id')}
                  deputizer={deputizer}
                  onClick={() => handleOnDeputizerClick(deputizer)}
                />
              )
            )
          }
        </Stack>
        {
          numberOfAvatarsToDisplay < deputizers.size &&
          <Button
            className='-see-all-deputizers'
            onClick={handleSeeAllDeputizersClick}>
            <img alt='see-all-deputizers-button' src={allDeputizersCaret} />
          </Button>
        }
      </Stack>
    </Stack>
  )
}

DeputyForSection.defaultProps = {
  numberOfAvatarsToDisplay: 2
}

DeputyForSection.propTypes = {
  deputizers: PropTypes.instanceOf(List),
  numberOfAvatarsToDisplay: PropTypes.number.isRequired
}

export default DeputyForSection
