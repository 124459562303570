import {Component} from 'react'
import {List, Map} from 'immutable'
import PropTypes from 'prop-types'

import UpdateViewFormController from '../view_form/UpdateViewFormController'
import View from './View'

export default class ViewsController extends Component {
  constructor() {
    super()
    this.state = {showModal: false, view: Map()}

    this.showUpdateViewModal = this.showUpdateViewModal.bind(this)
    this.closeUpdateViewModal = this.closeUpdateViewModal.bind(this)
  }

  showUpdateViewModal(view) {
    this.setState({showModal: true, view})
  }

  closeUpdateViewModal() {
    this.setState({showModal: false, view: Map()})
  }


  render() {
    const views = this.props.views.filterNot(view => view.get('name') === 'default-en')

    return (
      <div>
        {
          views.map(view => (
            <View
              {...this.props}
              showUpdateViewModal={this.showUpdateViewModal}
              view={view}
              key={view.get('id')}
            />
          ))
        }
        <UpdateViewFormController
          {...this.props}
          {...this.state}
          closeUpdateViewModal={this.closeUpdateViewModal}
        />
      </div>
    )
  }
}

ViewsController.propTypes = {
  views: PropTypes.instanceOf(List)
}
