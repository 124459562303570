/* global Stripe */ // Tell ESLint to ignore the fact that Stripe appears undefined. --BLR

export const sendCardToStripe = (formData, cardCallback) => {
  const date = formData.expirationDate.split('/')
  Stripe.setPublishableKey(STRIPE_PUBLISHABLE_KEY) // this Stripe global variable gets defined when the async script loads from the CreditCardFormController -JA
  Stripe.card.createToken({
    number: formData.creditCardNumber,
    cvc: formData.cvc,
    exp_month: parseInt(date[0], 10),
    exp_year: parseInt(date[1], 10)
  }, cardCallback)
}
