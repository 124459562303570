import {Component} from 'react'
import {List, Map} from 'immutable'
import PropTypes from 'prop-types'
import uuid from 'uuid'

import ElementFields from './ElementFields'
import EmptyElement from './EmptyElement'

import {elementId} from '../../../../../lib/responsesHelper'

export default class Element extends Component {
  constructor(props) {
    super(props)

    this.state = {elementFields: this.initializeElementFields(props)}
    this.addAnotherElementField = this.addAnotherElementField.bind(this)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.removeMultipleEmptyFieldsAndSort(nextProps)
  }

  initializeElementFields(props) {
    const reducer = (collection, datum) => {
      if (elementId(datum) === props.element.get('id'))
        return collection.push(datum.get('id'))
      else
        return collection
    }
    const restructuredData = props.data.reduce(reducer, List())

    // We need all element fields to have a uuid - Kay
    return restructuredData.size ? restructuredData : List().push(uuid.v4())
  }

  // Only one empty field per Element needs to be shown to the user at every
  // point in time. This helps to make sure that only one empty field is shown
  // and that it is always shown as the last field - Kay
  removeMultipleEmptyFieldsAndSort(nextProps) {
    let fields = this.state.elementFields
    let counter = 0
    const fieldsWithoutValue = this.filterFieldsWithoutValue({fields, nextProps})

    fieldsWithoutValue.map(field => {
      if (counter !== 0)
        fields = fields.delete(fields.indexOf(field))
      counter += 1
    })

    // Sorting here so as to always put the field without value below all fields - Kay
    this.setState({elementFields: fields.sortBy(field => fieldsWithoutValue.includes(field))})
  }

  filterFieldsWithoutValue({fields, nextProps}) {
    return fields.filterNot(id => (
      nextProps.formData.get(
        id,
        nextProps.data.find(datum => datum.get('id') === id, null, Map())
      ).get('value')
    ))
  }

  addAnotherElementField() { this.setState({elementFields: this.state.elementFields.push(uuid.v4())}) }

  showAddAnotherButton() {
    // TODO: remove 'addMore' when everything is using Transformer
    const addMore = this.props.element.get('add-more') || this.props.element.get('addMore')

    if (addMore) {
      const fieldsNotInFormData = this.state.elementFields
        .filterNot(id => this.props.formData.get(id))
      const fieldsInFormDataWithValue = this.state.elementFields
        .filter(id => this.props.formData.get(id, Map()).get('value'))

      return (
        (this.state.elementFields.size === (fieldsNotInFormData.size + fieldsInFormDataWithValue.size)) &&
        // Handle an edge case where add another button shows when there is no contact yet - Kay
        !(this.props.data.isEmpty() && (fieldsNotInFormData.size === this.state.elementFields.size))
      )
    } else {
      return false
    }
  }

  render() {
    if (this.props.addMore && this.props.showAdd) {
      return <EmptyElement {...this.props} />
    } else {
    // Actually render a basic field if the element is a primitive:
      return (
        // An element can now have multiple form fields based on the addMore
        // property on it hence this component. - Kay
        <ElementFields
          {...this.props}
          addAnotherElementField={this.addAnotherElementField}
          fields={this.state.elementFields}
          showAddAnotherButton={this.showAddAnotherButton()}
        />
      )
    }
  }
}

Element.propTypes = {
  addMore: PropTypes.bool,
  data: PropTypes.instanceOf(List),
  element: PropTypes.instanceOf(Map),
  formData: PropTypes.oneOfType([
    PropTypes.instanceOf(Map),
    PropTypes.instanceOf(List)
  ]),
  item: PropTypes.instanceOf(Map),
  newContacts: PropTypes.instanceOf(List),
  showAdd: PropTypes.bool,
  views: PropTypes.instanceOf(List),
  updateFileUploadStatus: PropTypes.func
}
