import {Component} from 'react'
import {Map} from 'immutable'
import {PropTypes} from 'prop-types'

import {TextGutterMedium} from '../../../../blocks/Texts'

export default class SelectedConditionalElement extends Component {
  constructor() {
    super()

    this.removeConditionalElement = this.removeConditionalElement.bind(this)
  }

  removeConditionalElement() {
    this.props.removeConditionalElement(this.props.conditionalElement)
  }

  render() {
    return (
      <div className='selected-conditional-element'>
        <a onClick={this.removeConditionalElement}>Remove Field</a>
        <TextGutterMedium>
          {this.props.conditionalElement.get('name')}
        </TextGutterMedium>
      </div>
    )
  }
}

SelectedConditionalElement.propTypes = {
  conditionalElement: PropTypes.instanceOf(Map),
  removeConditionalElement: PropTypes.func
}

