import classnames from 'classnames'
import {connect} from 'react-redux'
import {List} from 'immutable'
import PropTypes from 'prop-types'

import currentUserwrapper from '../../../../shared_components/currentUserWrapper'
import NavCategory from './NavCategory'

import './navCategories.scss'

export const NavCategories = props => (
  <ul className={classnames('nav-categories', props.className)}>
    {
      props.categories.sortBy(category => category.get('sort-order')).map(category => (
        <NavCategory categoryName={category.get('name')} key={category.get('id')} router={props.router} />
      ))
    }
  </ul>
)


NavCategories.defaultProps = {
  categories: List()
}

NavCategories.propTypes = {
  categories: PropTypes.instanceOf(List),
  router: PropTypes.shape({
    isActive: PropTypes.func
  })
}

const mapStateToProps = ({categories}) => ({categories})

export default connect(mapStateToProps)(currentUserwrapper(NavCategories))
