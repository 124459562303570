import classnames from 'classnames'
import PropTypes from 'prop-types'
import {useState} from 'react'

import {ToggleButton} from '@mui/material'

import StyledToggleButtonGroup from '../../StyledToggleButtonGroup'
import {numberToWord} from '../../../lib/tools'

import './selectButtonGroup.scss'

const SelectButtonGroup = props => {
  const {
    field,
    isSingleSelect,
    options,
    prompt,
    selectLimit,
    showError
  } = props

  const [
    isExclusiveOptionSelected,
    setIsExclusiveOptionSelected
  ] = useState(false)

  const [isSelectionError, setIsSelectionError] = useState(false)

  const buttonGroupLabel = () => {
    if (isSelectionError)
      return `Select just ${numberToWord(selectLimit)} options`
    else if (prompt)
      return prompt
    else
      return isSingleSelect ? 'Select one' : 'Select all that apply'
  }

  const errorMessage = isSingleSelect ?
    'Please select one' :
    'Please select at least one'

  const selectedOptions = field?.value || (isSingleSelect ? null : [])

  const isSelectLimitReached = selectedOptions ?
    selectedOptions.length >= selectLimit :
    false

  const handleSingleSelectChange = newValue => {
    field.onChange(newValue ?
      options.find(option => (option.value === newValue)) :
      null
    )
  }

  const handleMultiSelectChange = newValues => {
    let newOptions = newValues.map(newValue => options.find(
      option => option.value === newValue)
    )

    if (!isSelectLimitReached ||
      (isSelectLimitReached && (newValues.length < selectLimit ||
        newOptions.some(option => !option['multi-select']))
      )) {
      setIsSelectionError(false)

      const exclusiveOption = newOptions.find(option => !option['multi-select'])

      if (exclusiveOption) {
        if (isExclusiveOptionSelected) {
          setIsExclusiveOptionSelected(false)
          newOptions = newOptions.filter(option => option['multi-select'])
        } else {
          setIsExclusiveOptionSelected(true)
          newOptions = [exclusiveOption]
        }
      } else {
        setIsExclusiveOptionSelected(false)
      }

      newOptions = newOptions.length === 0 ? null : newOptions

      field.onChange(newOptions)
    } else {
      setIsSelectionError(true)
    }
  }

  const handleChange = (event, newValue) => (
    isSingleSelect ?
      handleSingleSelectChange(newValue) :
      handleMultiSelectChange(newValue)
  )

  const getValue = () => {
    if (isSingleSelect)
      return selectedOptions?.value
    else
      return selectedOptions?.map(option => option.value) || []
  }

  return (
    <div className='select-button-group'>
      <div
        className={classnames('select-button-group-label', {error: showError ||
        isSelectionError})}>
        {showError ? errorMessage : buttonGroupLabel()}
      </div>
      <StyledToggleButtonGroup
        aria-label={field.name}
        exclusive={isSingleSelect}
        onChange={handleChange}
        orientation='vertical'
        value={getValue()}>
        {
          options.map(option => (
            <ToggleButton
              aria-label={option.label}
              disableRipple
              key={option.id}
              value={option.value}>
              {option.label}
            </ToggleButton>
          ))
        }
      </StyledToggleButtonGroup>
    </div>
  )
}

SelectButtonGroup.defaultProps = {
  isSingleSelect: false,
  showError: false
}

SelectButtonGroup.propTypes = {
  field: PropTypes.object,
  isSingleSelect: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.object),
  prompt: PropTypes.string,
  selectLimit: PropTypes.number,
  showError: PropTypes.bool
}

export default SelectButtonGroup
