import PropTypes from 'prop-types'

import ConfirmationModal from '../../../../shared_components/mui_base_components/modals/confirmation_modal/ConfirmationModal'

const RemoveDeputyModal = props => (
  <ConfirmationModal
    confirmButtonText='Remove'
    headingText={`Remove ${props.deputyName} as your deputy?`}
    loading={props.processing}
    onClose={props.exit}
    onConfirmation={props.removeDeputy}
    open={true}
    showWarningIcon={true}
  />
)

RemoveDeputyModal.propTypes = {
  deputyName: PropTypes.string,
  exit: PropTypes.func,
  processing: PropTypes.bool,
  removeDeputy: PropTypes.func
}

export default RemoveDeputyModal
