import PropTypes from 'prop-types'
import {Component} from 'react'
import {fromJS, List, Map} from 'immutable'

import KeyFieldFormField from './KeyFieldFormField'
import KeyFieldPreview from './KeyFieldPreview'

import {Accept, Add} from '../../../../blocks/Buttons'
import Form from '../../../../../../../shared_components/forms/v2/Form'
import Modal from '../../../../../../../shared_components/Modal'
import {findSelectedValueId} from '../../../../../../../shared_components/forms/v2/lib/tools'

export class AddKeyFieldForm extends Component {
  constructor(props) {
    super(props)
    this.state = {keyFieldValues: fromJS({'value-1': {name: ''}})}

    this.addNewField = this.addNewField.bind(this)
    this.onChangeText = this.onChangeText.bind(this)
    this.onDeleteValue = this.onDeleteValue.bind(this)
    this.onSelectChange = this.onSelectChange.bind(this)
    this.resetState = this.resetState.bind(this)
    this.showModal = this.showModal.bind(this)
    this.submit = this.submit.bind(this)
  }

  showModal() {
    this.setState({showModal: true})
  }

  resetState() {
    this.setState({keyFieldValues: fromJS({'value-1': {name: ''}})})
    this.props.closeModal()
  }

  submit() {
    this.props.addKeyFieldValues(this.state.keyFieldValues.toList())

    this.setState({keyFieldValues: fromJS({'value-1': {name: ''}})})
  }

  onDeleteValue(keyFieldValueKey) {
    this.setState({keyFieldValues: this.state.keyFieldValues.delete(keyFieldValueKey)})
  }

  addNewField() {
    this.setState({keyFieldValues: this.state.keyFieldValues.set(`value-${this.state.keyFieldValues.size + 1}`, Map({name: ''}))})
  }

  onSelectChange(keyFieldValueKey) {
    const formFieldValue = this.refs.form && this.refs.form.formData().getIn([keyFieldValueKey, 'value'])

    if (formFieldValue) {
      const keyFieldValue = Map({
        type: findSelectedValueId({selectId: keyFieldValueKey, form: this.refs.form}),
        name: formFieldValue.includes('Add String') ? '' : formFieldValue
      })

      this.setState({keyFieldValues: this.state.keyFieldValues.mergeIn([keyFieldValueKey], keyFieldValue)})
    }
  }

  onChangeText(keyFieldValueKey) {
    const formFieldValue = this.refs.form && this.refs.form.formData().getIn([keyFieldValueKey, 'value'])

    if (formFieldValue)
      this.setState({keyFieldValues: this.state.keyFieldValues.mergeIn([keyFieldValueKey], Map({name: formFieldValue}))})
  }

  render() {
    return (
      <div className='key-field-form-modal'>
        <Modal visible={this.props.showModal} closer={this.resetState}>
          <h1>{this.props.header}</h1>
          <div className='forms-playground'>
            <Form className='core' ref='form'>
              {this.state.keyFieldValues.valueSeq().map((keyFieldValue, index) => (
                <KeyFieldFormField
                  key={index + 1}
                  valueId={`value-${index + 1}`}
                  keyFieldValue={keyFieldValue}
                  dataItems={this.props.elementsOption}
                  onChangeText={this.onChangeText}
                  onDeleteValue={this.onDeleteValue}
                  onSelectChange={this.onSelectChange}
                />
              ))}
            </Form>
            <div className='preview'>
              Preview: <KeyFieldPreview keyFieldValues={this.state.keyFieldValues.toList()} />
            </div>
            <Accept className='accept-button' onClick={this.submit}>Save</Accept>
            <Add onClick={this.addNewField} />
          </div>
        </Modal>
      </div>
    )
  }
}

AddKeyFieldForm.propTypes = {
  addKeyFieldValues: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  elementsOption: PropTypes.instanceOf(List).isRequired,
  header: PropTypes.string.isRequired,
  showModal: PropTypes.bool.isRequired
}

export default AddKeyFieldForm
