import {List} from 'immutable'
import PropTypes from 'prop-types'

import DeceasedHeaderEverplanInformation from './header/DeceasedHeaderEverplanInformation'
import NonDeceasedHeaderEverplanInformation from './header/NonDeceasedHeaderEverplanInformation'
import {allOwnersDeceased} from '../../../../../lib/corpMaxDeputyTools'


const NonEmptyEverplanBox = props => (
  <div className='non-empty-everplan-box'>
    {
      allOwnersDeceased({ownerships: props.ownerships, deathReports: props.deathReports}) ?
        <DeceasedHeaderEverplanInformation {...props} /> :
        <NonDeceasedHeaderEverplanInformation {...props} />
    }
  </div>
)

NonEmptyEverplanBox.propTypes = {
  deathReports: PropTypes.instanceOf(List),
  ownerships: PropTypes.instanceOf(List),
  firstName: PropTypes.string
}

export default NonEmptyEverplanBox
