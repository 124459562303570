import {Component} from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import {withRouter} from 'react-router'

import Container from '../../../lib/Container'
import currentUserWrapper from '../../../shared_components/currentUserWrapper'
import {hasEverplansCobranding, isCobrandableTypePartner} from '../../../lib/cobrandingTools'
import HeaderBar from './HeaderBar'
import MobileFooterEverplansBrandedCobranding from '../../../shared_components/everplans_branded_cobranding/MobileFooterEverplansBrandedCobranding'
import {GlobalAnnouncementContext} from '../../../shared_components/ApplicationContainer'

import {isContactCardVisible} from './corp_max/shared/corpMaxHelper'
import {displayBanner} from '../../../lib/bannerTools'

import './main.scss'

class Main extends Component {
  constructor() {
    super()

    this.state = {cobranding: Container.getStore('config').getState().data.cobranding}
  }

  innerMainClassNames() {
    return classnames('inner-main',
      displayBanner({
        currentUser: this.props.currentUser,
        globalAnnouncementText: this.context.globalAnnouncementText
      }),
      {'show-contact-card': isContactCardVisible(this.props)}
    )
  }

  render() {
    const cobranding = this.state.cobranding
    return (
      <main className='main flex-child'>
        <HeaderBar
          displayBanner={displayBanner({
            currentUser: this.props.currentUser,
            globalAnnouncementText: this.context.globalAnnouncementText
          })}
        />
        <div className={this.innerMainClassNames()}>
          {this.props.children}
          {hasEverplansCobranding(cobranding) && isCobrandableTypePartner(cobranding) && <MobileFooterEverplansBrandedCobranding cobranding={cobranding} />}
        </div>
      </main>
    )
  }
}

Main.propTypes = {
  currentUser: PropTypes.object,
  router: PropTypes.shape({
    isActive: PropTypes.func
  })
}

Main.contextType = GlobalAnnouncementContext

export default withRouter(currentUserWrapper(Main))
