import {fromJS, List, Map} from 'immutable'
import PropTypes from 'prop-types'
import {useRef, useMemo, useCallback, useState} from 'react'
import uuid from 'uuid'

import DigitalSubscriptionFormField from './DigitalSubscriptionFormField'
import DigitalSubscriptionButtonGroup from './DigitalSubscriptionButtonGroup'
import Form from '../../../../forms/v2/Form'

import {currencyAmountWithSymbol} from '../../../../../lib/currencyTools'
import {capitalize} from '../../../../../lib/tools'
import subscriptionItemElementMap from '../configurations/subscriptionItemElementMap'

import './digitalSubscriptionForm.scss'
import Logger from '../../../../../lib/NewLogger'

const RESPONSES_CREATION_ERROR = "Your subscriptions couldn't be added at this time"

const DigitalSubscriptionForm = props => {
  const formRef = useRef()
  const [processing, setProcessing] = useState(false)

  // Dictionary of subscription id and subscription
  const subscriptionMap = useMemo(() => props.subscriptions.reduce(
    (collection, subscription) =>
      collection.set(subscription.get('id'), subscription),
    Map()
  ), [props.subscriptions.size])

  // Dictionary of account id and account
  const accountMap = useMemo(() => props.accounts.reduce(
    (collection, account) =>
      collection.set(account.get('account-id'), account),
    Map()
  ), [props.accounts.size])

  const response = (elementId, groupId, value) => ({
    'element-id': elementId,
    'group-id': groupId,
    'ownership-ids': props.ownershipIds,
    value
  })

  const responseGroup = ({itemId, name, id}) => {
    const groupId = [uuid.v4(), uuid.v4()]
    const elementIds = subscriptionItemElementMap.get(itemId)
    const amount = currencyAmountWithSymbol({
      amount: subscriptionMap.getIn([id, 'price']),
      currency: subscriptionMap.getIn([id, 'iso-currency-code'])
    })
    const accountName = accountMap.getIn([
      subscriptionMap.getIn([id, 'account-id']),
      'name'
    ])
    const occurrenceType = capitalize(subscriptionMap.getIn([id, 'occurrence-type']).toLowerCase())
    const topElement = elementIds.get('top-element')

    return fromJS([
      response(elementIds.get('name'), groupId, name),
      response(elementIds.get('payment-method'), groupId, accountName),
      response(elementIds.get('amount'), groupId, amount),
      response(elementIds.get('frequency'), groupId, occurrenceType),
      response(elementIds.get('auto-pay'), groupId, 'Yes'),
      ...(topElement ? [response(topElement.get('id'), groupId, topElement.get('value'))] : [])
    ])
  }

  const formattedFormData = () => {
    const groupedFormData = formRef.current
      .formData()
      .groupBy(value => value.get('subscription-id'))
      .filter((_val, key) => key) // Remove keys that are undefined. This will remove deleted subscriptons.
    props.setSelectedSubscriptions(groupedFormData)

    return groupedFormData
      .map((fields, id) =>
        responseGroup({
          id,
          itemId: props.itemMap.getIn([
            fields.getIn([`category-${id}`, 'value']),
            'id'
          ]),
          name: fields.getIn([`name-${id}`, 'value'])
        })
      )
      .valueSeq()
      .flatten(1)
  }

  const submitForm = () => {
    const form = formRef.current

    if (!form) return
    form.onSubmit()

    if (form.isValid()) {
      setProcessing(true)
      props
        .updateResource({
          type: 'everplan-responses',
          id: props.userConfig.get('everplan-id'),
          attributes: {responses: formattedFormData()}
        })
        .then(() => {
          Logger.log({name: 'response_added', payload: {integration: 'plaid'}})
          props.onStepChange()
        })
        .catch(() => props.closeAndNotifyError(RESPONSES_CREATION_ERROR))
    }
  }

  const updateForm = field => formRef.current.updateDataAndErrors(field, null, null)

  const removeSubscriptionAndSyncForm = useCallback(id => {
    const fields = [`name-${id}`, `category-${id}`]

    fields.forEach(field => updateForm(field))
    props.removeSubscription(id)
  }, [props.subscriptions.size])

  return (
    <div className='digital-subscription-form'>
      <div className='header-text'>
        <h2>We found the following recurring payments.</h2>
        <p>
          Which of these subscription services or recurring payments do you
          want to add to your Everplan? You can update the name or category so
          that they make the most sense to you. You can also add, remove, or
          edit entries later.
        </p>
      </div>
      <Form className='core' ref={formRef}>
        <div className='desktop-labels flex-container'>
          <p className='desktop-label'>Name</p>
          <p className='desktop-label'>Category</p>
          <p className='desktop-label'>Amount</p>
        </div>
        {props.subscriptions.toIndexedSeq().map(subscription => (
          <DigitalSubscriptionFormField
            key={subscription.get('id')}
            subscription={subscription}
            removeSubscription={removeSubscriptionAndSyncForm}
            digitalItems={props.digitalItems}
          />
        ))}
      </Form>
      <DigitalSubscriptionButtonGroup
        submitForm={submitForm}
        processing={processing}
        toggleGapFillerAssessmentModal={props.toggleGapFillerAssessmentModal}
      />
    </div>
  )
}

DigitalSubscriptionForm.propTypes = {
  accounts: PropTypes.instanceOf(List),
  subscriptions: PropTypes.instanceOf(List),
  itemMap: PropTypes.instanceOf(Map),
  removeSubscription: PropTypes.func,
  onStepChange: PropTypes.func,
  toggleGapFillerAssessmentModal: PropTypes.func,
  setSelectedSubscriptions: PropTypes.func,
  closeAndNotifyError: PropTypes.func,
  digitalItems: PropTypes.instanceOf(List),
  ownershipIds: PropTypes.instanceOf(List),
  updateResource: PropTypes.func,
  userConfig: PropTypes.instanceOf(Map)
}


export default DigitalSubscriptionForm
