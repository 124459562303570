import classnames from 'classnames'
import {List} from 'immutable'
import PropTypes from 'prop-types'

import {Text} from '../../../blocks/Texts'
import ViewAddress from '../../shared/ViewAddress'

import './contactValueByType.scss'

const ContactValue = ({className, ContactComponent, data, header}) => {
  if (!data) {
    return null
  } else {
    return (
      <div className={classnames('contact-value-by-type', className)}>
        <Text className='contact-value-header'>{header}</Text>
        <ContactComponent data={data} />
      </div>
    )
  }
}

const ContactTexts = ({data}) => {
  let collection

  if (List.isList(data))
    collection = data.map(contactText => <Text key={contactText}>{contactText}</Text>)
  else
    collection = <Text>{data}</Text>

  return <div className='contact-value'>{collection}</div>
}

ContactTexts.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(List)
  ])
}

const ContactAddresses = ({data}) => {
  const addresses = data.map(address => <ViewAddress key={address} address={address} />)

  return <div className='contact-value flex-child'>{addresses}</div>
}

ContactAddresses.propTypes = {data: PropTypes.instanceOf(List)}

export const ContactTextValue = ({className, data, header}) => <ContactValue className={className} data={data} header={header} ContactComponent={ContactTexts} />

ContactTextValue.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(List)
  ]),
  header: PropTypes.string.isRequired
}

export const ContactAddressValue = ({data, header}) => <ContactValue data={data} header={header} ContactComponent={ContactAddresses} />

ContactAddressValue.propTypes = {
  data: PropTypes.instanceOf(List),
  header: PropTypes.string.isRequired
}
