/** @class NotificationBase
 *
 * @deprecated Do not use this in new code. Use the newer approach in
 *   +shared_components/notifications/NotificationContext.jsx+ instead.
 * @desc
 * Legacy component to show notifications based on action dispatches.
 */
import React from 'react'

import Flash from './Flash'

import StorePrototype from '../StorePrototype'

import $ from 'jquery'

export default class NotificationBase extends React.Component {
  constructor(props) {
    super(props)

    this.state = {storeState: {data: {}, errors: [], message: null}}

    this.clearMessage = this.clearMessage.bind(this)
    this.onUpdate = this.onUpdate.bind(this)
  }
  onUpdate() {
    this.setState({storeState: this.store.getState()})
  }
  componentWillUnmount() {
    this.store.removeChangeListener(this.onUpdate)
  }
  bindMessageStore(actionType) {
    this.store = StorePrototype(actionType)
    this.store.addChangeListener(this.onUpdate)
  }
  clearMessage() {
    // technically the message is still hanging out in the store, but no one cares.
    this.setState({storeState: {data: {}, errors: [], message: null}})
  }
  message() {
    if (this.state.storeState.message)
      return this.state.storeState.message
    else if (this.state.storeState.data.message)
      return this.state.storeState.data.message
    else
      return null
  }

  render() {
    if (!$.isEmptyObject(this.state.storeState.data) || this.state.storeState.message)
      return <Flash flash={this.message()} closer={this.clearMessage} />
    else
      return null
  }
}
