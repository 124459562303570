import {gql} from '@apollo/client'

import {ITEM_RESPONSE_FRAGMENT} from '../fragments/itemResponse'
import {OWNER_OWNERSHIP_FRAGMENT} from '../fragments/userData'

export const UPDATE_ITEM_RESPONSE = gql`
  ${ITEM_RESPONSE_FRAGMENT}
  mutation UpdateItemResponse($input: ItemResponsesInput!, $everplanId: Int!) {
    updateItemResponse(input: $input, everplanId: $everplanId) {
      ...ItemResponseFragment
    }
  }
`

export const DELETE_ITEM_RESPONSE_GROUP_SUBSECTION = gql`
  ${ITEM_RESPONSE_FRAGMENT}
  mutation DeleteItemResponseGroupSubsection($input: DeleteItemResponseGroupSubsectionInput!, $everplanId: Int!) {
    deleteItemResponseGroupSubsection(input: $input, everplanId: $everplanId) {
      ...ItemResponseFragment
    }
  }
`

export const DELETE_ITEM_RESPONSE_GROUP = gql`
  ${ITEM_RESPONSE_FRAGMENT}
  mutation DeleteItemResponseGroup($input: DeleteItemResponseGroupInput!, $everplanId: Int!) {
    deleteItemResponseGroup(input: $input, everplanId: $everplanId) {
      ...ItemResponseFragment
    }
  }
`

export const UPDATE_RESPONSE_GROUP_OWNERSHIP = gql`
  ${OWNER_OWNERSHIP_FRAGMENT}
  mutation UpdateResponseGroupOwnership($input: UpdateResponseGroupOwnershipInput!) {
    updateResponseGroupOwnership(input: $input) {
      id
      owners {...OwnerOwnershipFragment}
      topElementsWithResponses {
        id
        responsesGroupedBySecondGroupId {
          id
          responses {
            id
            ownershipIds
          }
        }
      }
    }
  }
`


