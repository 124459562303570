import classnames from 'classnames'
import PropTypes from 'prop-types'
import Slick from 'react-slick'
import {useEffect, useRef, useState} from 'react'

import BASE_SLICK_CONFIGURATION from '../lib/baseSlickConfiguration'
import CarouselControls from '../shared_components/CarouselControls'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './carousel.scss'

const Carousel = props => {
  const {
    className,
    location,
    onPreviousClick,
    onNextClick,
    onSwipe,
    slides,
    title
  } = props

  const [currentSlickIndex, setCurrentSlickIndex] = useState(0)
  const slickRef = useRef()

  useEffect(() => {
    setCurrentSlickIndex(0)
    slickRef.current.slickGoTo(0)
  }, [location.pathname])

  const handleNextClick = () => {
    slickRef.current.slickNext()

    if (onNextClick) onNextClick()
  }

  const handlePreviousClick = () => {
    slickRef.current.slickPrev()

    if (onPreviousClick) onPreviousClick()
  }

  const handleSlickChange = slickIndex => {
    setCurrentSlickIndex(slickIndex)
  }

  const slickSettings = {
    ...BASE_SLICK_CONFIGURATION,
    afterChange: handleSlickChange,
    className,
    onSwipe
  }

  if (slides.length === 0) return null

  return (
    <div
      className={classnames('carousel', {
        'first-slide': currentSlickIndex === 0,
        'single-slide': slides.length === 1
      })}>
      <CarouselControls
        currentCarouselIndex={currentSlickIndex}
        next={handleNextClick}
        numberOfSlides={slides.length}
        previous={handlePreviousClick}
        title={title}
      />
      <Slick
        {...slickSettings}
        ref={slickRef}>
        {slides}
      </Slick>
    </div>
  )
}

Carousel.propTypes = {
  className: PropTypes.string,
  location: PropTypes.shape({
    pathname: PropTypes.string
  }),
  onNextClick: PropTypes.func,
  onPreviousClick: PropTypes.func,
  onSwipe: PropTypes.func,
  slides: PropTypes.array.isRequired,
  title: PropTypes.string
}

export default Carousel
