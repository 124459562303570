import {useFormContext, Controller} from 'react-hook-form'
import {Stack} from '@mui/material'

import TextInput from './TextInput'

import './name.scss'

const Name = () => {
  const {control, formState: {errors}} = useFormContext()

  return (
    <div className='name'>
      <label
        aria-label='First and Last Name'
        className='label'
        htmlFor='first-name'>
        Name
      </label>
      <Stack
        alignItems='flex-start'
        className='fields-container'
        direction={{mobileSm: 'column', tabletMd: 'row'}}
        spacing={1}>
        <Controller
          name='first-name'
          control={control}
          defaultValue=''
          rules={{required: 'Please add the first name.'}}
          render={({field}) => (
            <TextInput
              {...field}
              ariaLabel='First Name'
              id='first-name'
              placeholder='First'
              error={!!errors['first-name']}
              helperText={errors['first-name']?.message}
            />
          )}
        />
        <Controller
          name='last-name'
          defaultValue=''
          control={control}
          rules={{required: 'Please add the last name.'}}
          render={({field}) => (
            <TextInput
              {...field}
              ariaLabel='Last Name'
              id='last-name'
              placeholder='Last'
              error={!!errors['last-name']}
              helperText={errors['last-name']?.message}
            />
          )}
        />
      </Stack>
    </div>
  )
}

export default Name
