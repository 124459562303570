import {useMemo} from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import AddressBookLinkContainer from './AddressBookLinkContainer'
import DownloadPreviewLinkController from './DownloadPreviewLinkController'

import {useEverplanData} from '../../../../lib/hooks'

import './myEverplanLinks.scss'


const MyEverplanLinks = props => {
  const {itemResponses} = useEverplanData(props.currentUser.everplan.id)

  const hideDownloadLink = useMemo(() => (
    !itemResponses.some(itemResponse => !itemResponse.get('response-groups').isEmpty())
  ), [itemResponses])

  const linkClasses = classnames({'nav-preview-mobile': props.mobile, 'show-dropdown-nav': props.mobile && props.showDropdownNav})
  const addressBookClass = classnames(linkClasses, {[props.className]: props.className})

  return (
    <div
      className={classnames('my-everplan-links', {
        'nav-action-links': !props.mobile,
        'nav-mobile-action-links': props.mobile
      })}>
      <DownloadPreviewLinkController
        {...props}
        className={linkClasses}
        hideDownloadLink={hideDownloadLink}
      />
      <AddressBookLinkContainer
        {...props}
        className={addressBookClass}
        hideDownloadLink={hideDownloadLink}
      />
    </div>
  )
}


MyEverplanLinks.defaultProps = {
  mobile: false
}

MyEverplanLinks.propTypes = {
  currentUser: PropTypes.shape({
    everplan: PropTypes.shape({id: PropTypes.number})
  }),
  mobile: PropTypes.bool,
  showDropdownNav: PropTypes.bool
}

export default MyEverplanLinks
