/** @class MobileText
 *
 * @desc
 * This is a class intended to standardize the showing of some text only when user is using a mobile device.
 */

import classnames from 'classnames'

import './mobileText.scss'


const MobileText = props => <span {...props} className={classnames('mobile-text', props.className)} />

export default MobileText
