/** @module ItemFormController
 *
 * @desc
 * This controller wraps the Item form  and determines how it is being rendered based on the route being hit.
 */

import {List, Map} from 'immutable'
import PropTypes from 'prop-types'
import uuid from 'uuid'


/**
 * The add more wrapper passes down the needed presentational props to the item form when trying to add a
 * new response group to an item. i.e., visiting this route:
 * `api_host/corp-max/category/digital-world/item/digital-world-email-address/add/66054e61-6db9-497c-a27a-8a4f7c217b9e/more`
 */
const addMore = Component => {
  const AddMore = props => {
    const groupIds = [props.params.id, uuid.v4()]

    return (
      <Component
        {...props}
        addMore={false}
        groupId={groupIds}
      />
    )
  }

  AddMore.propTypes = {
    elements: PropTypes.instanceOf(List),
    item: PropTypes.object,
    params: PropTypes.shape({
      id: PropTypes.string
    })
  }

  return AddMore
}

/**
 * The new Item wrapper passes down the needed presentational props to the item form when trying to add a
 * new Item Response. i.e., visiting this route:
 * `api_host/corp-max/category/digital-world/item/digital-world-ticketing/new/`
 */
const newItem = Component => {
  const NewItem = props => {
    const groupIds = props.params.groupId ? [props.params.groupId, uuid.v4()] : null

    return (
      <Component
        {...props}
        addMore={props.hasMultipleFieldGroups}
        groupId={groupIds}
      />
    )
  }

  NewItem.propTypes = {
    hasMultipleFieldGroups: PropTypes.bool,
    params: PropTypes.shape({
      groupId: PropTypes.string
    })
  }

  return NewItem
}

/**
 * The new Item wrapper passes down the needed presentational props to the item form when trying to edit an
 * Item Response. i.e., visiting this route:
 * `api_host/corp-max/category/digital-world/item/digital-world-email-address/edit/03de43b8-9c94-4e26-a250-8cfef70a395e`
 */
const editItem = Component => {
  const EditItem = props => {
    const groupIds = [props.responseGroup.get('id'), props.params.groupId]

    return (
      <Component
        {...props}
        addMore={false}
        groupId={groupIds}
      />
    )
  }

  EditItem.propTypes = {
    responseGroup: PropTypes.instanceOf(Map),
    params: PropTypes.shape({
      groupId: PropTypes.string
    })
  }

  return EditItem
}

export {
  addMore,
  newItem,
  editItem
}
