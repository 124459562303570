import {List, Map} from 'immutable'
import PropTypes from 'prop-types'

import FileResponseMigrationDetails from '../../../../../shared_components/forms/v2/form_helpers/FileResponseMigrationDetails'
import FileView from '../../../../../shared_components/forms/v2/form_helpers/FileView'
import {Text} from '../../blocks/Texts'
import MaskedText from '../../../../../shared_components/MaskedText'
import UnorderedList from '../../../../../shared_components/UnorderedList'
import ViewAddress from '../shared/ViewAddress'
import ViewContactItem from '../item/new_contact_item/ViewContactItem'
import {dateResponseValue} from '../../../../../lib/dateTools'
import {viewElementById} from '../../../../../lib/viewTools'
import {isValidResponseMapValue, removeEmptyResponses} from '../../../../../lib/plan_data/itemResponsesHelper'

import './itemResponseGroupView.scss'

const ItemResponseGroupView = props => {
  const fileView = ({files, elementId}) => {
    if (files.getIn([0, 'count'])) {
      // files will always be a list containing the count of files for a response
      // when the files are being migrated. For example [{count: 2}]
      // For files that not being migrated, the example of value is
      // [{name: 'Shukura Kilokosi', id: 'fia-1111-davido-2017'}]- Kay
      return <FileResponseMigrationDetails count={files.getIn([0, 'count'])} />
    } else {
      return (
        files &&
        files.size > 0 &&
        files.map(file => (
          <FileView
            {...props}
            elementId={elementId}
            name={file.get('name')} id={file.get('id')} key={file.get('id')}
            isViewMode
          />
        ))
      )
    }
  }

  const elementResponseValue = response => {
    const responseValue = response.getIn(['value', 'data'])
    const elementType = response.get('element-type')

    if (elementType === 'FILE')
      return fileView({files: responseValue, elementId: response.get('element-id')})
    else if (elementType === 'CONTACT')
      return <ViewContactItem {...props} contact={responseValue} />
    else if (elementType === 'ADDRESS')
      return <ViewAddress address={responseValue} />
    else if (List.isList(responseValue))
      return <UnorderedList children={responseValue.toJS()} />
    else if (elementType === 'DATE')
      return dateResponseValue(responseValue)
    else
      return responseValue
  }

  return (
    <div className='item-response-group-view flex-child'>
      {
        removeEmptyResponses(props.responses).map(response => {
          const elementView = viewElementById({views: props.views, elementId: response.get('element-id')})
          const maskText = response.get('mask-text', false)

          if (response.get('element-type') === 'ADDRESS' && !isValidResponseMapValue(response.getIn(['value', 'data'])))
            return

          return (
            <Text className='response' key={response.get('id')}>
              {elementView && <h3 className='response-name'>{elementView.get('display-name')}</h3>}
              <MaskedText maskText={maskText} itemName={props.itemResponse.get('name')} context={props.context}>
                {elementResponseValue(response)}
              </MaskedText>
            </Text>
          )
        })
      }
    </div>
  )
}


ItemResponseGroupView.propTypes = {
  ownEverplan: PropTypes.bool,
  context: PropTypes.string,
  itemName: PropTypes.string,
  itemResponse: PropTypes.instanceOf(Map),
  itemResponses: PropTypes.instanceOf(List),
  responses: PropTypes.instanceOf(List),
  views: PropTypes.instanceOf(List)
}

export default ItemResponseGroupView
