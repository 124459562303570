import classnames from 'classnames'
import PropTypes from 'prop-types'
import {List} from 'immutable'

import {TextGutterMedium} from '../../blocks/Texts'

const BillingHistoryRow = ({data, header}) => (
  <div className={classnames('flex-container billing-history-row', {header})}>
    {data.valueSeq().map(datum => (
      <TextGutterMedium key={datum} className='flex-child'>
        {datum}
      </TextGutterMedium>
    ))}
  </div>
)

BillingHistoryRow.propTypes = {
  data: PropTypes.instanceOf(List),
  header: PropTypes.bool
}


export default BillingHistoryRow
