import colors from '../../../../../dashboard/styles/abstracts/colors.module.scss'

const styles = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    background: '#FFFFFF',
    border: `1px solid ${colors.inputBorder}`,
    borderBottomRightRadius: state.menuIsOpen ? '0' : '8px',
    borderBottomLeftRadius: state.menuIsOpen ? '0' : '8px',
    borderBottom: state.menuIsOpen ? 'none' : `1px solid ${colors.inputBorder}`,
    borderRadius: '8px',
    boxShadow: 'none',
    fontSize: '0.9375rem',
    maxHeight: '328px',
    overflowY: 'auto',
    padding: '12px 11px',
    ':hover': {
      borderColor: colors.inputBorder
    }
  }),
  indicatorsContainer: () => ({
    display: 'none'
  }),
  input: baseStyles => ({
    ...baseStyles,
    cursor: 'text',
    margin: '0',
    padding: '0'
  }),
  menu: baseStyles => ({
    ...baseStyles,
    display: 'block',
    borderBottom: `1px solid ${colors.inputBorder}`,
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
    borderLeft: `1px solid ${colors.inputBorder}`,
    borderRight: `1px solid ${colors.inputBorder}`,
    borderTop: 'none',
    borderTopLeftRadius: '0',
    borderTopRightRadius: '0',
    boxShadow: 'none',
    marginBottom: '0',
    marginTop: '0'
  }),
  menuList: baseStyles => ({
    ...baseStyles,
    padding: '0 11px 6px'
  }),
  multiValue: baseStyles => ({
    ...baseStyles,
    background: '#FFFFFF',
    border: `1px solid ${colors.textboxBorderDivider}`,
    borderRadius: '8px',
    margin: '0',
    padding: '5px 8px'
  }),
  multiValueLabel: baseStyles => ({
    ...baseStyles,
    color: colors.bodyText,
    fontSize: '0.9375rem',
    lineHeight: '18px',
    padding: '0',
    paddingLeft: '0',
    marginRight: '16px'
  }),
  multiValueRemove: baseStyles => ({
    ...baseStyles,
    cursor: 'pointer',
    paddingLeft: '0',
    paddingRight: '0',
    ':hover': {
      backgroundColor: 'unset',
      color: 'unset'
    }
  }),
  noOptionsMessage: baseStyles => ({
    ...baseStyles,
    borderTop: `1px solid ${colors.inputBorder}`,
    color: colors.bodyText,
    fontSize: '0.9375rem',
    lineHeight: '18px',
    padding: '12px 0 6px',
    textAlign: 'left'
  }),
  option: (baseStyles, state) => ({
    ...baseStyles,
    backgroundColor: state.isFocused ? colors.buttonHoverBackground : 'transparent',
    color: state.isFocused ? '#FFFFFF' : colors.bodyText,
    cursor: 'pointer',
    fontSize: '0.9375rem',
    marginLeft: '-11px',
    marginRight: '-11px',
    padding: '6px 11px',
    width: 'unset',
    ':first-of-type': {
      borderTop: `1px solid ${colors.inputBorder}`,
      marginLeft: state.isFocused ? '-11px' : '0',
      marginRight: state.isFocused ? '-11px' : '0',
      marginTop: '0',
      padding: state.isFocused ? '12px 11px 6px' : '12px 0 6px'
    }
  }),
  placeholder: baseStyles => ({
    ...baseStyles,
    color: colors.bodyText,
    lineHeight: '147%',
    margin: '0'
  }),
  valueContainer: baseStyles => ({
    ...baseStyles,
    gap: '8px',
    lineHeight: '22px',
    padding: '0'
  })
}

export default styles
