import {Component} from 'react'
import {List, Map, fromJS} from 'immutable'
import {PropTypes} from 'prop-types'
import uuid from 'uuid'

import {Accept, Cancel} from '../../../../blocks/Buttons'
import ButtonGroup from '../../../../../../../shared_components/core/buttons/ButtonGroup'
import EditViewElementForm from './EditViewElementForm'
import {viewElementsMap} from '../../../../../../../lib/viewTools'
import Form from '../../../../../../../shared_components/forms/v2/Form'
import Fields from '../../../../../../../shared_components/forms/v2/fields/index'
import {BoxGutterMedium} from '../../../../blocks/Boxes'
import DependentResponseElements from '../add_view_elements/DependentResponseElements'
import ViewPreview from './ViewPreview'

export default class EditViewForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      viewElements: viewElementsMap({views: props.views, elements: props.selectedElements, view: props.view}),
      conditionalViewElements: viewElementsMap({views: props.views, elements: props.selectedConditionalElements, view: props.view})
    }

    this.updateViewElement = this.updateViewElement.bind(this)
    this.updateConditionalViewElement = this.updateConditionalViewElement.bind(this)
    this.submitView = this.submitView.bind(this)
  }

  /*
    Needed to update the selected view elements if any are added when a user goes between the
    Add and Edit screens, while still preserving any updated elements in the view.
  */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.showEditViewForm) {
      this.updateSelectedViewElements(nextProps.selectedElements, this.state.viewElements, 'viewElements')
      this.updateSelectedViewElements(nextProps.selectedConditionalElements, this.state.conditionalViewElements, 'conditionalViewElements')
    }
  }


  selectedElementsDidChange(nextSelectedElementsIds, elements) {
    return !nextSelectedElementsIds.equals(elements.keySeq().toList())
  }


  // Adds or removes view elements based on any changes to the selected elements on the previous step
  constructUpdatedViewElements(elements, nextSelectedElementsIds, nextSelectedElements) {
    return elements
      .filter(viewElement => nextSelectedElementsIds.includes(viewElement.get('element-id')))
      .merge(viewElementsMap({views: this.props.views, view: this.props.view, elements: nextSelectedElements}))
  }

  /*
    This checks if the type of view elements (viewElements or conditionalViewElements) need to be updated when the user navigates
    between the Add and Edit screens, by checking if any elements are missing from the view.
  */
  updateSelectedViewElements(nextSelectedElements, elements, type) {
    const nextSelectedElementsIds = nextSelectedElements.map(element => element.get('id'))

    if (this.selectedElementsDidChange(nextSelectedElementsIds, elements)) {
      this.setState({
        [type]: this.constructUpdatedViewElements(elements, nextSelectedElementsIds, nextSelectedElements)
      })
    }
  }

  updateViewElement(element) {
    this.setState({viewElements: this.state.viewElements.mergeIn([element.get('element-id')], element)})
  }

  updateConditionalViewElement(element) {
    this.setState({conditionalViewElements: this.state.conditionalViewElements.mergeIn([element.get('element-id')], element)})
  }

  submitView() {
    this.props.submitView({
      name: this.refs.form.formData().getIn(['name', 'value']),
      data: {
        elements: this.setViewElementIds(this.state.viewElements),
        'dependent-response-elements': this.props.dependentResponseElements.toList(),
        'conditional-elements': this.setViewElementIds(this.state.conditionalViewElements)
      }
    })
  }

  setViewElementIds(elements) {
    return elements.valueSeq().map(element => element.set('id', uuid.v4()))
  }

  // Used when previewing the view to show the updated view attributes
  updatedView() {
    return fromJS({
      data: {
        elements: this.state.viewElements.toList(),
        'conditional-elements': this.state.conditionalViewElements.toList()
      }
    })
  }

  render() {
    if (!this.props.showEditViewForm) {
      return null
    } else {
      return (
        <div className='edit-view-form'>
          <h2>Manage Selected Wizard Fields</h2>
          <div className='selections'>
            <BoxGutterMedium className='view-options'>
              <BoxGutterMedium>
                <Form className='core' ref='form'>
                  <Fields.Text
                    data={
                      Map({
                        id: 'name',
                        legend: 'Name:',
                        name: 'name',
                        value: this.props.view ? this.props.view.get('name') : ''
                      })
                    }
                  />
                </Form>
              </BoxGutterMedium>
              {
                this.props.selectedElements.map(
                  element => (
                    <EditViewElementForm
                      {...this.props}
                      element={element}
                      updateViewElement={this.updateViewElement}
                      removeElement={this.props.removeElement}
                      key={element.get('id')}
                      viewElement={this.state.viewElements.get(element.get('id'))}
                    />
                  )
                )
              }
              {
                this.props.selectedConditionalElements.map(
                  element => (
                    <EditViewElementForm
                      {...this.props}
                      element={element}
                      removeElement={this.props.removeConditionalElement}
                      updateViewElement={this.updateConditionalViewElement}
                      key={element.get('id')}
                      viewElement={this.state.conditionalViewElements.get(element.get('id'))}
                    />
                  )
                )
              }
              <DependentResponseElements {...this.props} />
            </BoxGutterMedium>
            <ViewPreview {...this.props} view={this.updatedView()} />
          </div>
          <ButtonGroup>
            <Cancel onClick={this.props.toggleEditViewForm}>
              Back
            </Cancel>
            <Accept
              processing={this.props.processing}
              onClick={this.submitView}>
              Save
            </Accept>
          </ButtonGroup>
        </div>
      )
    }
  }
}

EditViewForm.propTypes = {
  dependentResponseElements: PropTypes.instanceOf(Map),
  selectedConditionalElements: PropTypes.instanceOf(List),
  processing: PropTypes.bool,
  selectedElements: PropTypes.instanceOf(List),
  showEditViewForm: PropTypes.bool,
  submitView: PropTypes.func,
  removeElement: PropTypes.func,
  removeConditionalElement: PropTypes.func,
  toggleEditViewForm: PropTypes.func,
  views: PropTypes.instanceOf(List),
  view: PropTypes.instanceOf(Map)
}
