/** @class NextBestActionBox
 *
 * @desc
 * Displays a single next best action.
 */

import classnames from 'classnames'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import {List, Map} from 'immutable'
import {useMemo, useState} from 'react'

import {Button} from '@mui/material'

import colors from '../../dashboard/styles/abstracts/colors.module.scss'
import MixpanelLogger from '../../lib/MixpanelLogger'
import ModuleBox from '../component_templates/ModuleBox'
import NextBestActionCloser from './NextBestActionCloser'
import Logger from '../../lib/NewLogger'
import routerUtils from '../../lib/routerUtils'
import {nextBestActionItem} from '../../dashboard/src/lib/nextBestActionTools'
import {categorySlugByItem} from '../../lib/plan_data/category'
import {pluralize} from '../../lib/tools'
import {newItemPath, accountEntrySelectPath, goToPremiumAccessModal} from '../../lib/urlTools'
import {eligibleForAccountsImport} from '../../lib/accountPopulationTools'

import './nextBestActionBox.scss'
import {allResponseGroupCount} from '../../lib/plan_data/itemResponsesHelper'
import {hidePremiumFeatures} from '../../lib/premiumSubscriptionTools'

export const NextBestActionBox = props => {
  const itemResponse = useMemo(() => nextBestActionItem({
    nextBestAction: props.nextBestAction,
    itemResponses: props.itemResponses
  }), [props.nextBestAction])

  const nextBestActionData = props.nextBestAction.get('data')
  const type = nextBestActionData.get('type', 'full-item')

  const [beingDismissed, setBeingDismissed] = useState(false)

  const openFullItemModal = () => {
    const nbaItem = nextBestActionData.get('item')

    routerUtils.push(
      newItemPath({
        itemSlug: nbaItem.get('item-slug'),
        slug: categorySlugByItem({categories: props.categories, item: itemResponse}),
        elementId: nbaItem.get('element-id'),
        path: props.pathname,
        queryParams: {type: nextBestActionData.get('type', 'full-item'), conditional_value: nbaItem.get('conditional-value')}
      })
    )
  }

  const goToNextBestActionItem = () => {
    if (type === 'full-item') {
      openFullItemModal()
    } else {
      props.onNextBestActionClick({
        nextBestAction: props.nextBestAction,
        itemResponse
      })
    }
  }


  const addNextBestAction = () => {
    MixpanelLogger.track('click_next_best_action', {item_name: itemResponse.get('name'), type})
    Logger.log({
      name: 'clicked_next_best_action',
      payload: {
        context: props.context,
        header: nextBestActionData.get('header', ''),
        item: itemResponse.get('name'),
        wildcard: itemResponse.get('name'),
        type
      }
    })

    const hidePremiumFeaturesForUser = hidePremiumFeatures({
      userConfig: props.userConfig,
      responseGroupCount: allResponseGroupCount(props.itemResponses),
      settings: props.settings
    })

    if (hidePremiumFeaturesForUser)
      return goToPremiumAccessModal({itemSlug: itemResponse.get('slug'), currentPath: props.pathname})

    if (eligibleForAccountsImport({item: itemResponse, userConfig: props.userConfig}))
      routerUtils.push(accountEntrySelectPath({itemSlug: itemResponse.get('slug'), path: props.pathname, queryParams: {type: nextBestActionData.get('type', 'full-item')}}))
    else
      goToNextBestActionItem()
  }

  const logDismissEvent = () => {
    Logger.log({
      name: 'dismissed_next_best_action',
      payload: {
        context: props.context,
        item: itemResponse.get('header'),
        header: nextBestActionData.get('header', ''),
        wildcard: itemResponse.get('header'),
        type: nextBestActionData.get('type', 'full-item')
      }
    })
  }

  const dismissNextBestAction = () => {
    logDismissEvent()
    setBeingDismissed(true)
    const previouslyDismissedNextBestActions = props.profile.get('dismissed-next-best-actions')

    const payload = {
      type: 'profiles',
      id: props.profile.get('id'),
      attributes: {
        'dismissed-next-best-actions': previouslyDismissedNextBestActions.push(props.nextBestAction.get('id'))
      }
    }

    props.updateResource(payload)
  }

  const isGapFiller = nextBestActionData.get('type') === 'gap-filler'

  const classNames = classnames(
    'next-best-action-box',
    {
      'gap-filler': isGapFiller,
      'being-dismissed': beingDismissed
    }
  )

  const closerFillColor = isGapFiller ? colors.headerText : '#FFFFFF'

  const nextBestActionPoints = nextBestActionData.get('duration')
  const nextBestActionPointsText = `${nextBestActionPoints}
  ${pluralize('point', Number(nextBestActionPoints))}`

  return (
    <ModuleBox className={classNames}>
      <NextBestActionCloser
        fill={closerFillColor}
        dismissNextBestAction={dismissNextBestAction}
      />
      <div className='content-button-container'>
        <div className='next-best-action-box-content'>
          {
            props.userConfig.get('score-enabled') && nextBestActionPoints &&
            <div className='next-best-action-points'>
              {nextBestActionPointsText}
            </div>
          }
          <div className='next-best-action-prompt'>
            {nextBestActionData.get('prompt')}
          </div>
        </div>
        <Button
          className='button'
          color='secondary'
          onClick={addNextBestAction}
          size='medium'
          variant='contained'>
          Start
        </Button>
      </div>
    </ModuleBox>
  )
}

NextBestActionBox.propTypes = {
  category: PropTypes.instanceOf(Map),
  nextBestAction: PropTypes.instanceOf(Map),
  categories: PropTypes.instanceOf(List),
  context: PropTypes.string,
  itemResponses: PropTypes.instanceOf(List),
  onNextBestActionClick: PropTypes.func,
  pathname: PropTypes.string,
  profile: PropTypes.instanceOf(Map).isRequired,
  updateResource: PropTypes.func,
  userConfig: PropTypes.instanceOf(Map).isRequired,
  settings: PropTypes.object
}

const mapStateToProps = state => ({settings: state.api.settings})

export default connect(mapStateToProps)(NextBestActionBox)
