/** @class ItemResponseGroups
 *
 * @desc
 * Shared component used to display the `Response` groups associated with an `Item`. The
 * `Response` groups can be reordered via drag and drop if there is more than one `Response`
 * group and either:
 *   - The user is interacting with their own Everplan; or,
 *   - The user is a firm user who has been granted edit permission for this `Item` and is on the
 *     client detail page.
 *
 * If neither of the above sets of conditions are met the `Response` groups are ordered based on
 * the current `ItemView` for this `Item`.
 *
 * This is used in multiple places so be careful when editing this! The drag/drop functionality
 * is automatically disabled if only one `ItemResponseGroup` is present.
 */
import {Droppable, Draggable} from 'react-beautiful-dnd'
import {List, Map} from 'immutable'
import {memo, useState} from 'react'
import {PropTypes} from 'prop-types'

import ItemResponseGroup from './ItemResponseGroup'

import {innerDraggableProps, innerDroppableProps} from '../../lib/dragAndDropTools'
import withItemResponseGroupDragAndDrop from './withItemResponseGroupDragAndDrop'

import './itemResponseGroups.scss'


const ItemResponseGroups = props => {
  const [isManagePermissionsModalOpen, setIsManagePermissionsModalOpen] = useState(false)

  // While it would seem that the `map` will prevent anything from being on the DOM the library always puts at least
  // the `div` with class `response-groups-droppable` on the DOM, which leads to a 0px-tall `div` with a `border` on
  // all sides, which looks super weird. Therefore we have to explicitly prevent this from happening via this check:
  if (props.responseGroupsOrder.isEmpty()) {
    return null
  } else {
    const isDragDisabled = props.responseGroupsOrder.size === 1 || !props.hasEditPermission || isManagePermissionsModalOpen

    return (
      <Droppable droppableId='droppable'>
        {
          (provided, snapshot) => (
            <div {...innerDroppableProps({provided, snapshot})}>
              {
                props.responseGroupsOrder.map((firstGroupId, index) => (
                  <Draggable key={firstGroupId} draggableId={firstGroupId} index={index} isDragDisabled={isDragDisabled}>
                    {
                      (providedDraggable, snapshotDraggable) => (
                        <div {...innerDraggableProps({isDragDisabled, providedDraggable, snapshot, snapshotDraggable})}>
                          <ItemResponseGroup
                            {...props}
                            responseGroup={props.responseGroups.get(firstGroupId)}
                            firstGroupId={firstGroupId}
                            onToggleManagePermissionsModal={booleanValue => { setIsManagePermissionsModalOpen(booleanValue) }}
                          />
                        </div>
                      )
                    }
                  </Draggable>
                ))
              }
              {provided.placeholder}
            </div>
          )
        }
      </Droppable>
    )
  }
}

ItemResponseGroups.propTypes = {
  hasEditPermission: PropTypes.bool,
  itemView: PropTypes.instanceOf(Map),
  newContacts: PropTypes.instanceOf(List),
  responseGroupsOrder: PropTypes.instanceOf(List),
  responseGroups: PropTypes.instanceOf(Map)
}

// Avoid unnecessary re-render and munging of the `withItemResponseGroupDragAndDrop` and `ItemResponseGroups` components when opening
// and closing the item modal and when other `responseGroups` have changed.
const areEqual = (prevProps, nextProps) => (
  // account for adding a user to an HH on the pro-client page, since the responseGroups could be the same
  nextProps.itemResponse.get('is-household') === prevProps.itemResponse.get('is-household') &&
  nextProps.responseGroups.equals(prevProps.responseGroups) &&
  prevProps.itemView.equals(nextProps.itemView)
)

export default memo(withItemResponseGroupDragAndDrop(ItemResponseGroups), areEqual)
