import loaderSpinner from '../dashboard/assets/loader-spinner.gif'
import './downloadStatus.scss'

const DownloadStatus = () => (
  <aside className='download-status'>
    <div className='processing'>
      <img
        alt='loader spinner'
        className='loader-spinner'
        src={loaderSpinner}
      />
      <span>Your download is processing</span>
    </div>
  </aside>
)


export default DownloadStatus
