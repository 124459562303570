import {Map} from 'immutable'

const body = `
Someone asks a question and you want to look up the answer on your phone, but as you reach for your pocket, you notice a certain lightness and nakedness. Then a sudden and intense feeling of dread washes over you -- your phone is gone.

This reaction is understandable. Phones are no longer just phones. They contain all our contacts, correspondence (you want someone snooping around your emails and texts?), payment information, personal photos (for many, this is worse than emails and texts), and so much more.

While it might not be easy to access the information without your fingerprint, face, or access code, this is never a fun experience. But, with a bit of pre-planning and a steady mind, it's possible to handle the situation without screaming and rushing to FBI headquarters.
### Find Your Phone

**The Easy Way**: If you're lucky the phone slipped out of your pocket and is now hiding between the couch cushions. The most obvious solution is having someone call it; if no one's around, or if your phone was set on silent, you can use services from your provider that activate a loud alarm on the phone. For example, Verizon offers Support and Protection which you can activate from a nearby computer. Click the button, listen for the sound, and be reunited with your most valuable electronic possession! Hopefully, this solves all the problems and the alarm sound is NOT muffled by toilet water. But if this doesn't work, and your phone isn't within earshot, it's time to get serious.

**The Serious Way**: Both Apple and Android offer a free location program that can track a lost or stolen phone. It's easy to set up and use, just remember to actually set it up. A little preparation now will save you frustration and possible identity theft later.

Once activated, the feature secures your phone and the account, preventing thieves from accessing your data. If your phone is missing, and you were smart enough to set up the Find a Phone feature, Android and iOS users can simply type "I lost my phone" into a Google search and start the location process. But please play it safe. If your phone was stolen by bad guys, contact the authorities before you put on your homemade Batman costume and seek justice.

### Cancel Your Account

It's time to admit the truth: Your phone is gone. After accepting this sad truth, call your service provider from a friend's phone (or the house phone if you still have one), and tell them what happened. They will shut down your account. They'll also help navigate you through your replacement phone options and, if you think the price is right, you can pick up a new phone at a nearby store, or have it mailed to you.

### Disconnect Your Accounts

It's time to access your email and social media accounts and deactivate the mobile device. You can do this two ways: Wipe your entire phone, or disconnect accounts for each app individually. If you connected your phone to a Google account, you can easily wipe the entire phone clean remotely by  <a href="https://support.google.com/accounts/answer/6160500?hl=en" rel='noreferrer noopener' target='_blank'>following these steps</a>. Once completed, no matter where the phone is, all your info has been erased. The process is pretty much the <a href="https://support.apple.com/kb/ph2701?locale=en_US" rel='noreferrer noopener' target='_blank'>same for iPhones</a>.

**Note**: If you didn't set up this feature before your phone went missing, you need to go through each app and disconnect them one at a time.

For instance, log into Facebook from any computer, go to the setting menu at the upper right, and find "Mobile Settings." This will take you to a page where you can easily sign your phone out of the account. Other social media apps and banks have similar settings, and it's important to go through each of your accounts and sign them all out.

Take comfort in knowing all the information, photos, and videos aren't lost. They'll still be available in the cloud, ready to be beamed down to you next mobile device.

### Change Your Passwords

This is a no-brainer, but take this opportunity to reset every password for every online account you have, even if it's an account that wasn't tied to your mobile device. For instance, maybe you don't have an Amazon.com app on your phone, but you should still change your Amazon password because there may be emails on your phone that can be used by thieves and hackers to access your data and credit card info.

According to <em class='italic'>Wired Magazine</em>, you should change your password every six months to a year. So think of this as starting off a whole new year!

### Tell The Cops

If you suspect your phone was stolen,  file a police report. Even if you doubt the police will be able to help, the more information they have regarding crime, the better crime prevention and detection becomes. Also, if theft was covered by your insurance protection policy, your service provider may require a police report as proof that the phone was stolen.

Your phone is gone, and probably isn't coming back, but that doesn't mean you should pretend it never happened.

### Tell Your IT Department

If you use your phone for work, and it contains work-related data, you must, must, must come clean and tell your IT department so they can shut down whatever needs to be shut down. This is non-negotiable, especially if you want to keep your job.

### Tell The World

Use the power of social media to alert friends and family that your phone is lost or stolen. Let them know the best way to reach you until you get a new phone, and warn them of possible scams, spams, and identity thieves who may be impersonating you. On the plus side, you'll get a great amount of sympathy and sad-face emoticons. Everyone will understand.

### Move On

Instead of crying about your lost/stolen phone, it's important to move on and realize that things will work out. Learn about Find a Phone features and talk with your service provider about theft protection programs. Losing a phone doesn't need to be the end of the world, or even the end of a bad week. You'll get through this and be much better prepared if it ever happens again. (And it probably will.)
`


export default Map({
  body,
  headline: 'Do This If Your Cell Phone Is Lost Or Stolen',
  summary: 'Losing a phone is like losing your mind. Stay calm and focused. You will get through this.',
  query: 'do-this-if-your-cell-phone-is-lost-or-stolen',
  url: 'https://www.everplans.com/articles/do-this-if-your-cell-phone-is-lost-or-stolen'
})
