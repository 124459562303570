import PropTypes from 'prop-types'

import {pluralize} from '../../../../../lib/tools'

import './categoryResponseGroupsAdded.scss'

const CategoryResponseGroupsAdded = ({responseGroupSize}) => {
  if (responseGroupSize < 1)
    return null
  return (
    <div className='category-response-groups-added'>
      <span>
        {responseGroupSize} {pluralize('item', responseGroupSize)}
      </span>
    </div>
  )
}

CategoryResponseGroupsAdded.propTypes = {
  responseGroupSize: PropTypes.number
}

export default CategoryResponseGroupsAdded
