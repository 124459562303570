import {EventEmitter} from 'events'
import assign from 'object-assign'
import AppDispatcher from '../../../lib/ep-dispatcher'

import TfaConfigStore from '../stores/TfaConfigStore'
import TfaApiResponse from '../web/TfaApiResponse'
import WizardActions from '../actions/WizardActions'


const state = {
  topLevelView: 'INITIAL',
  phoneVerified: false,
  confirmCancel: false
}

const TfaWizardStore = assign({}, EventEmitter.prototype, {
  initialize() {
    state.topLevelView = 'START'
    state.phoneVerified = false
    state.confirmCancel = false
  },
  getState() { return state },
  active() { return !(state.topLevelView === 'DONE' || state.topLevelView === 'INITIAL') },
  getTopLevelView() { return state.topLevelView },
  emitChange() { this.emit('CHANGE') },
  addChangeListener(callback) { this.on('CHANGE', callback) },
  removeChangeListener(callback) { this.removeListener('CHANGE', callback) },
  transitionToView(view) {
    this.setTopLevelView(view)
    this.emitChange()
  },
  showConfirmed() {
    state.phoneVerified = true
    setTimeout(() => {
      state.phoneVerified = false
      this.transitionToView('SECONDARY')
    }, 500)
    this.emitChange()
  },
  setTopLevelView(view) { state.topLevelView = view },
  tfaEnabled() {
    this.setTopLevelView('ENABLED')
    this.emitChange()
  },
  allDone() {
    state.confirmCancel = false
    this.setTopLevelView('DONE')
    this.emitChange()
  }
})

export default TfaWizardStore


TfaWizardStore.dispatchToken = AppDispatcher.register(action => {
  if (state.topLevelView !== 'INITIAL' && state.topLevelView !== 'DONE') {
    switch (action.actionType) {
      case TfaApiResponse.Types.WEB_PRIMARY_PHONE_ACCEPTED:
        AppDispatcher.waitFor([TfaConfigStore.dispatchToken])
        TfaWizardStore.transitionToView('CONFIRM')
        break
      case WizardActions.Types.RESEND_CODE:
        TfaWizardStore.transitionToView('START')
        break
      case TfaApiResponse.Types.WEB_TFA_PHONE_CONFIRMED:
        TfaWizardStore.showConfirmed()
        break
      case TfaApiResponse.Types.WEB_TFA_BACKUP_CODE_GENERATED:
        AppDispatcher.waitFor([TfaConfigStore.dispatchToken])
        TfaWizardStore.transitionToView('BACKUP')
        break
      case TfaApiResponse.Types.WEB_TFA_ENABLED:
        TfaWizardStore.tfaEnabled()
        break
      case WizardActions.Types.TFA_CONFIRM_CANCEL_SETUP:
        state.confirmCancel = true
        TfaWizardStore.emitChange()
        break
      case WizardActions.Types.TFA_CONTINUE_SETUP:
        state.confirmCancel = false
        TfaWizardStore.emitChange()
        break
      case TfaApiResponse.Types.WEB_TFA_DISABLED:
      case WizardActions.Types.ALL_DONE:
        TfaWizardStore.allDone()
        break

      default:
    }
  }
})
