import {Component} from 'react'
import {List} from 'immutable'
import PropTypes from 'prop-types'

import {Button} from '../../blocks/Buttons'
import Closer from '../../../../../shared_components/core/closer/Closer'
import {pluralize} from '../../../../../lib/tools'
import Modals from '../../../../../shared_components/core/modals/Modals'
import {TextGutterMedium} from '../../blocks/Texts'
import UnorderedList from '../../../../../shared_components/UnorderedList'

import successCheckmarkIcon from '../../../../../shared_assets/v2/success-checkmark.svg'

import './accountImportSuccessModal.scss'

export default class AccountImportSuccessModal extends Component {
  constructor(props) {
    super(props)

    this.accountNames = props.selectedAccounts.map(account => account.get('name')).toJS()

    this.closerComponent = this.closerComponent.bind(this)
  }

  closerComponent() { return <Closer closer={this.props.closeModal} /> }

  render() {
    return (
      <Modals.SuccessPopUpModal className='account-import-success-modal' closerComponent={this.closerComponent} showModal>
        <header>
          <img src={successCheckmarkIcon} alt='Success checkmark' />
          <h2>All set!</h2>
        </header>
        <TextGutterMedium>
          The following {pluralize('account', this.accountNames.length)} {this.accountNames.length > 1 ? 'have' : 'has'} been
          saved in the Financial section of your Everplan:
        </TextGutterMedium>
        <UnorderedList children={this.accountNames} />
        <a className='import-link' onClick={this.props.importAnotherAccount}>
          Import more accounts
        </a>
        <Button onClick={this.props.closeModal}>Done</Button>
      </Modals.SuccessPopUpModal>
    )
  }
}

AccountImportSuccessModal.propTypes = {
  closeModal: PropTypes.func,
  importAnotherAccount: PropTypes.func,
  selectedAccounts: PropTypes.instanceOf(List)
}
