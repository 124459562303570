/*
 * This component handles rendering of the combination of a select field and a
 * box that shows the selected item. It is majorly consumed by the contact type
 * response. -- Kay
*/

import {Component} from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import {List, Map} from 'immutable'

import NewContact from '../../shared/NewContact'
import Fields from '../../../../../../shared_components/forms/v2/fields'
import {TextGutterSmall} from '../../../blocks/Texts'
import ViewContactItem from './ViewContactItem'

import Logger from '../../../../../../lib/NewLogger'
import {contactLabel} from '../../../../../../lib/contactTools'

import './contactField.scss'


export default class ContactField extends Component {
  constructor(props) {
    super(props)

    this.state = {
      // The WizardField and the ItemForm both use this:
      // - In the the case of the ItemForm when editing a contact field that already has a contact saved the ID is in side the `data` object
      // - In the case of the Wizard when moving back and forth between screens the ID is just inside the value
      selectedContactId: Map.isMap(props.data.get('value')) ? props.data.getIn(['value', 'id']) : props.data.get('value'),
      showContactModal: false,
      contactAddedEdited: false
    }

    this.clearSelectedContact = this.clearSelectedContact.bind(this)
    this.onChange = this.onChange.bind(this)
    this.showContactModal = this.showContactModal.bind(this)
    this.hideContactModal = this.hideContactModal.bind(this)
  }


  selectItems() {
    return this.props.newContacts.map(
      contact => Map(
        {
          id: contact.get('id'),
          label: contactLabel(contact),
          value: contact.get('id')
        }
      ))
      .unshift(Map({label: 'Choose an existing contact', value: ''}))
  }

  onChange() {
    // only need to do this if a contact is selected through the select dropdown and not when selectedContact is set by adding or
    // editing a contact --ZD
    if (!this.state.selectedContactId) {
      // The keyboard event has the value inside `value` and the pointer event (which is the mouse event) has the value inside `data-value`
      const contactId = event && (event.target.getAttribute('data-value') || event.target.getAttribute('value'))
      // // This is needed by the Select component -- KAY
      this.setState({selectedContactId: contactId})
    }
  }

  findContactById() {
    return this.props.newContacts.find(contact => contact.get('id') === this.state.selectedContactId) || Map()
  }

  clearSelectedContact() {
    this.setState({selectedContactId: null})
  }

  showContactModal() {
    Logger.log({
      name: 'clicked_add_new_contact',
      payload: {
        context: this.props.context,
        element_id: this.props.element.get('id'),
        item: this.props.itemName,
        type: this.props.eventType,
        view_id: this.props.view.get('id'),
        wildcard: this.props.itemName
      }
    })
    this.setState({showContactModal: true})
  }

  hideContactModal(contactId) {
    if (contactId) {
      this.setState({
        showContactModal: false,
        selectedContactId: contactId,
        contactAddedEdited: true
      })
    } else {
      this.setState({showContactModal: false})
    }
  }

  constructSelectData() {
    // If a contact is added or edited through the contact form, then the select component still needs to be updated
    // with the contactId as value, so that when the ItemForm is submitted the form data is up to date with the contact.
    // Otherwise, we want to delete the value, since nothing would be selected. --ZD
    if (this.state.contactAddedEdited) {
      this.setState({contactAddedEdited: false})
      return this.props.data.merge(Map({items: this.selectItems(), value: this.state.selectedContactId}))
    } else {
      return this.props.data.merge(Map({items: this.selectItems()})).delete('value')
    }
  }

  existingContacts() {
    return this.props.newContacts.size > 0
  }

  render() {
    const {data, element} = this.props
    const addContactText = this.existingContacts() ? 'Add someone new' : 'Add a first contact'
    const isContactSelected = this.state.selectedContactId
    // Want to render the select even if a contact is selected, so that the select form has the added/edited contact
    // and the ItemForm is updated with that information. --ZD
    const renderSelect = (this.state.contactAddedEdited || !this.state.selectedContactId) && this.existingContacts()
    const selectedContact = this.findContactById()

    return (
      <div className='contact-field'>
        {
          this.state.showContactModal && (
            <NewContact
              element={this.props.element}
              view={this.props.view}
              context={this.props.context}
              hideContactModal={this.hideContactModal}
              itemName={this.props.itemName}
              selectedContact={selectedContact}
              eventType={this.props.eventType}
            />
          )
        }
        <div className={classnames('contact-field-container flex-container', {'contact-selected': isContactSelected})}>
          {
            renderSelect && (
              <Fields.Select
                data={this.constructSelectData()}
                element={element}
                onChange={this.onChange}
              />
            )
          }
          {!this.existingContacts() && <legend className='contact-legend'>{this.constructSelectData().get('legend')}</legend>}
          <div className={classnames({'add-new': this.existingContacts(), 'add-first-contact': !this.existingContacts()})}>
            <a onClick={this.showContactModal}>{addContactText}</a>
          </div>
        </div>
        {
          isContactSelected && (
            <div>
              <TextGutterSmall className='title'>{data.get('legend')}</TextGutterSmall>
              <ViewContactItem
                ownEverplan={this.props.ownEverplan}
                contact={selectedContact}
                showEditLink
                showContactModal={this.showContactModal}
                clearSelectedContact={this.clearSelectedContact}
              />
            </div>
          )
        }
      </div>
    )
  }
}

ContactField.defaultProps = {
  newContacts: List(),
  view: Map(),
  element: Map()
}

ContactField.propTypes = {
  context: PropTypes.string,
  data: PropTypes.instanceOf(Map),
  element: PropTypes.instanceOf(Map),
  eventType: PropTypes.string,
  itemName: PropTypes.string,
  newContacts: PropTypes.instanceOf(List),
  ownEverplan: PropTypes.bool,
  view: PropTypes.instanceOf(Map)
}
