import {List, Map} from 'immutable'

import {deputyManageableItems} from './corpMaxDeputyTools'
import {findResourceByAttributeId, findResourceById} from './plan_data/userData'

export const existingPermissionOptions = [
  {
    label: 'Share now',
    selectedLabel: 'Now',
    value: 'read'
  },
  {
    label: 'Share after death',
    selectedLabel: 'After death',
    value: 'deferred_read'
  },
  {
    label: 'Do not share',
    selectedLabel: 'Do not share',
    value: 'never'
  }
]

export const bulkPermissionOptions = [existingPermissionOptions[0], existingPermissionOptions[1]]

export const findSpecificItemDeputies = ({specificItemsDeputies, firstGroupId}) => (
  findResourceById({resourceList: specificItemsDeputies, id: firstGroupId}).get('deputies', List())
)

// used to find a deputy permission within a singular specificItemDeputies
const findSpecificItemDeputyPermission = ({specificItemDeputies, deputyId}) => (
  findResourceByAttributeId({
    resourceList: specificItemDeputies,
    attribute: 'ownership-id',
    id: deputyId
  }).get('permission', Map())
)

// Used to find a deputy permission within all specificItemsDeputies
const findDeputyPermission = ({specificItemsDeputies, firstGroupId, deputyId}) => {
  if (specificItemsDeputies.isEmpty())
    return Map()

  const specificItemDeputies = findSpecificItemDeputies({specificItemsDeputies, firstGroupId})

  return findSpecificItemDeputyPermission({
    specificItemDeputies,
    deputyId
  })
}

const defaultSpecificItemDeputyPermissions = ({itemResponse, specificItemsDeputies = List(), deputyId = null}) => {
  const firstGroupIds = itemResponse.get('responseGroupMap').keySeq().toList()

  return firstGroupIds.reduce((collection, firstGroupId) => {
    const sharedAttributes = {
      itemId: itemResponse.get('item-id'),
      kitId: itemResponse.get('kit-id'),
      firstGroupId
    }
    const existingPermission = findDeputyPermission({specificItemsDeputies, firstGroupId, deputyId})

    if (existingPermission.isEmpty()) {
      return collection.set(firstGroupId, {...existingPermissionOptions[2], ...sharedAttributes})
    } else {
      var permissionOption = existingPermissionOptions.find(option => existingPermission.get('permission-type') === option.value)

      return collection.set(firstGroupId, {...permissionOption, ...sharedAttributes, id: existingPermission.get('id')})
    }
  }, Map())
}

// Used for displaying the default values for all permissions for an existing deputy
export const defaultDeputyPermissions = ({specificItemsDeputies, deputyId, itemsWithResponsesByCategory}) => {
  const itemResponses = itemsWithResponsesByCategory.map(category => category.get('items')).flatten(true)

  return itemResponses.reduce((collection, itemResponse) => collection.merge(
    defaultSpecificItemDeputyPermissions({itemResponse, specificItemsDeputies, deputyId})
  ), Map()).toJS()
}

// Used for displaying the default values for all permissions for new deputy
export const defaultNewDeputyPermissions = itemsWithResponsesByCategory => {
  const itemResponses = itemsWithResponsesByCategory.map(category => category.get('items')).flatten(true)

  return itemResponses.reduce((collection, itemResponse) => collection.merge(defaultSpecificItemDeputyPermissions({itemResponse})), Map()).toJS()
}

// Used for updating all permissions for a new or existing deputy
export const formateDeputyPermissions = updatedPermissions => {
  const changedPermissions = Object.values(updatedPermissions).filter(updatedPermission => updatedPermission.itemId)
  return changedPermissions.map(changedPermission => ({
    firstGroupId: changedPermission.firstGroupId,
    permissionType: changedPermission.value,
    id: changedPermission.id,
    itemId: changedPermission.itemId,
    level: 'specific_item',
    kitId: changedPermission.kitId
  }))
}

// Used for displaying the default values for existing deputies specific item permissions
export const defaultSpecificItemDeputiesPermissionValues = ({deputiesWithPermission}) => (
  deputiesWithPermission.reduce(
    (permissions, deputy) => {
      const permission = deputy.getIn(['permission', 'permission-type'])

      return permissions.set(deputy.get('ownership-id'), existingPermissionOptions.find(existingPermissionOption => permission === existingPermissionOption.value))
    }, Map()).toJS()
)

// Used for updating permissions for existing deputies for a specific item
export const formatExistingDeputiesPermission = ({newPermissions, deputiesWithPermission}) => (
  deputiesWithPermission.reduce((collection, deputy) => (
    collection.push({
      id: deputy.get('ownership-id'),
      permissions: [{
        id: deputy.get('permission').get('id'),
        permissionType: newPermissions[deputy.get('ownership-id')].value
      }]
    })
  ), List()).toArray()
)

// Used for updating multiple deputies with the same permission for a specific item
export const formatSelectedDeputiesPermission = ({selectedPermissionType, selectedDeputies, deputiesWithPermission, firstGroupId, itemResponse}) => (
  selectedDeputies.reduce((collection, deputy) => {
    const existingPermission = findSpecificItemDeputyPermission({specificItemDeputies: deputiesWithPermission, deputyId: deputy.get('id')})

    return collection.push({
      id: parseInt(deputy.get('id'), 10),
      permissions: [
        {
          id: existingPermission.get('id'),
          level: 'specific_item',
          firstGroupId,
          permissionType: selectedPermissionType.value,
          itemId: itemResponse.get('item-id'),
          kitId: itemResponse.get('kit-id')
        }
      ]
    })
  }, List()).toArray()
)

export const permittedDeputies = ({deputies, itemResponse, firmConfig}) => {
  if (firmConfig.isEmpty()) {
    return deputies
  } else {
    return deputies.reduce((collection, deputy) => {
      const manageableItem = deputyManageableItems({isFirmUser: deputy.getIn(['ownable', 'firm-user']), itemResponses: List([itemResponse]), firmConfig})

      return manageableItem.isEmpty() ? collection : collection.push(deputy)
    }, List())
  }
}

export const hasDeputyWithDeferredPermissions = deputies => {
  if (deputies.isEmpty())
    return false
  else
    return deputies.some(deputy => !deputy.getIn(['permissions', 'deferred']).isEmpty())
}

export const isDeferredReadResponseGroup = ({deputyOwnership, firstGroupId}) => {
  if (deputyOwnership)
    return !findResourceByAttributeId({resourceList: deputyOwnership.getIn(['permissions', 'deferred']), attribute: 'first-group-id', id: firstGroupId}).isEmpty()
}
