import {useState} from 'react'
import PropTypes from 'prop-types'

import Button from '../../../../../shared_components/Button'
import Modal from '../../../../../shared_components/Modal'
import SecondaryPhoneEditor from './SecondaryPhoneEditor'

const TfaSecondaryPhone = props => {
  const [showModal, setShowModal] = useState(false)

  const displayModal = () => { setShowModal(true) }

  const hideModal = event => {
    if (event) event.preventDefault()
    setShowModal(false)
  }

  return (
    <div className='status-body'>
      {
        props.secondaryNumber ?
          (
            <div className='status-text'>
              {props.secondaryNumber.national_number}
              <br />
              Verification codes delivered via&nbsp;
              {props.secondaryNumber.preferred_message_mode }.
            </div>
          ) :
          (
            <div className='status-text'>
            You have not added a secondary phone number.
            </div>
          )
      }
      <p />
      <Button className='control-btn' onClick={displayModal}>Edit</Button>
      <Modal visible={showModal} closer={hideModal}>
        <div className='auth-content'>
          <SecondaryPhoneEditor {...props} closer={hideModal} />
        </div>
      </Modal>
    </div>
  )
}

TfaSecondaryPhone.propTypes = {
  secondaryNumber: PropTypes.shape({
    national_number: PropTypes.number,
    preferred_message_mode: PropTypes.string
  })
}

export default TfaSecondaryPhone
