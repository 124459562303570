import PropTypes from 'prop-types'
import {Component} from 'react'

import Closer from '../../../../../../shared_components/core/closer/Closer'
import ConfirmationModal from '../../../../../../shared_components/mui_base_components/modals/confirmation_modal/ConfirmationModal'

export default class ManageItemPermissionsModalCloser extends Component {
  constructor() {
    super()
    this.state = {showModal: false}

    this.toggleModal = this.toggleModal.bind(this)
  }

  toggleModal() { this.setState({showModal: !this.state.showModal}) }

  render() {
    return (
      <div>
        <Closer closer={this.toggleModal} data-testid='manage-item-permissions-modal-closer' />
        <ConfirmationModal
          confirmButtonText='Discard'
          headingText={'Discard changes you\'ve made?'}
          onClose={this.toggleModal}
          onConfirmation={this.props.exit}
          open={this.state.showModal}
        />
      </div>
    )
  }
}

ManageItemPermissionsModalCloser.propTypes = {
  exit: PropTypes.func.isRequired
}
