import PropTypes from 'prop-types'
import {Map} from 'immutable'

import {TextGutterMedium} from '../../blocks/Texts'
import AutoSubscription from './AutoSubscription'

const SubscriptionStatus = ({
  billing,
  periodEnd,
  userConfig,
  updateResource
}) => (
  <div className='section-container'>
    <h2>Subscription Status</h2>
    <div className='flex-container'>
      <TextGutterMedium className='left-text'>
        Subscription Status:
      </TextGutterMedium>
      <TextGutterMedium>Premium</TextGutterMedium>
    </div>
    <div className='flex-container'>
      <TextGutterMedium className='left-text'>
        Expiration Date:
      </TextGutterMedium>
      <TextGutterMedium>{periodEnd}</TextGutterMedium>
    </div>
    <div className='flex-container'>
      <TextGutterMedium className='left-text'>
        Auto-Renewal:
      </TextGutterMedium>
      <AutoSubscription
        noSubscriptionRenewal={billing.get('no-subscription-renewal')}
        billing={billing}
        periodEnd={periodEnd}
        userConfig={userConfig}
        updateResource={updateResource}
      />
    </div>
  </div>
)

SubscriptionStatus.propTypes = {
  billing: PropTypes.instanceOf(Map),
  periodEnd: PropTypes.string,
  userConfig: PropTypes.instanceOf(Map),
  updateResource: PropTypes.func
}

export default SubscriptionStatus
