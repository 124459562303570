import epLogoIcon from '../../../../../shared_assets/ep_logo_icon@2x.png'

import './funeralExpenseHeader.scss'


const FuneralExpenseHeader = () => (
  <div className='funeral-expense-header flex-container'>
    <img src={epLogoIcon} alt='everplans logo icon' />
    FINAL CELEBRATION COST ESTIMATOR
  </div>
)


export default FuneralExpenseHeader
