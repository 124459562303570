import PropTypes from 'prop-types'

import NewContact from '../shared/NewContact'
import {Accept} from '../../blocks/Buttons'

import './addressBookHeader.scss'

const AddressBookHeader = props => (
  <div className='address-book-header flex-container'>
    {
      props.showContactModal &&
      (
        <NewContact
          context='address_book'
          hideContactModal={props.hideContactModal}
          params={props.params}
        />
      )

    }
    <h1 className='flex-child'>Address Book</h1>
    <div className='add-new-contact flex-child'>
      <Accept onClick={props.addNewContact}>Add new</Accept>
    </div>
  </div>
)

AddressBookHeader.propTypes = {
  params: PropTypes.object,
  showContactModal: PropTypes.bool,
  hideContactModal: PropTypes.func,
  addNewContact: PropTypes.func
}

export default AddressBookHeader
