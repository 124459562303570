/** @class NonMobileText
 *
 * @desc
 * This is a class intended to standardize the showing of
 * some text only when user is not using a mobile device.
 */

import classnames from 'classnames'

import './nonMobileText.scss'


const NonMobileText = props => <span {...props} className={classnames('non-mobile-text', props.className)} />

export default NonMobileText
