/** @class ToggleAllControls
 *
 * @desc
 * Controls for checking or uncheck all items in the `ItemPermissionsSelectForm`.
 */

import classnames from 'classnames'
import {Component} from 'react'
import {List} from 'immutable'
import PropTypes from 'prop-types'

import MobileText from '../MobileText'
import NonMobileText from '../NonMobileText'

import './toggleAllControls.scss'


export default class ToggleAllControls extends Component {
  constructor() {
    super()

    this.selectAll = this.selectAll.bind(this)
    this.selectNone = this.selectNone.bind(this)
  }

  allItemsChecked() { return this.props.checkedItemIds.length === this.props.items.size }

  noItemsChecked() { return this.props.checkedItemIds.length === 0 }

  selectAll() { this.props.onCheck(this.props.items.map(item => item.get('id')).toArray()) }

  selectNone() { this.props.onCheck([]) }

  render() {
    return (
      <div className='toggle-all-controls flex-container'>
        <NonMobileText className='flex-child'>
          <strong>Select: </strong>
        </NonMobileText>
        <span className={classnames('toggle-all-control', 'select-all', 'flex-child', {active: this.allItemsChecked()})} onClick={this.selectAll}>
          <MobileText>Select </MobileText>
          All
        </span>
        <span className={classnames('toggle-all-control', 'select-none', 'flex-child', {active: this.noItemsChecked()})} onClick={this.selectNone}>
          <MobileText>Select </MobileText>
          None
        </span>
      </div>
    )
  }
}

ToggleAllControls.propTypes = {
  checkedItemIds: PropTypes.arrayOf(PropTypes.string),
  items: PropTypes.instanceOf(List),
  onCheck: PropTypes.func
}
