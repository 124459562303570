/** @class ClientDeputyUpgradePrompt
 *
 * @desc
 * Prompt pushing Deputies only users to request an Everplan CTA shown to them when they are
 * viewing a preview of a client Everplan associated
 * with a firm that has Deputy cobranding enabled.
 */

/* @todo @todo Add event logging here possibly on mount.
 *    Waiting on product input about the events, payloads, and
 *    wildcards. --TK
*/

import {useState} from 'react'
import {List, Map} from 'immutable'
import PropTypes from 'prop-types'

import AdvisorDetails from './AdvisorDetails'
import Button from '../../../../../shared_components/mui_base_components/button/Button'
import ModuleBox from '../../../../../shared_components/component_templates/ModuleBox'

import {addPossessive} from '../../../../../lib/tools'
import {humanizedFirstNamesWithConjunction} from '../../../../../lib/nameTools'
import Logger from '../../../../../lib/NewLogger'
import routerUtils from '../../../../../lib/routerUtils'
import MixpanelLogger from '../../../../../lib/MixpanelLogger'

import './clientDeputyUpgradePrompt.scss'

const ClientDeputyUpgradePrompt = props => {
  const [processing, setProcessing] = useState(false)

  const goToDeputyUpgrade = () => {
    Logger.log({name: 'clicked_client_network_amplifier', payload: {}})
    MixpanelLogger.track('click_client_network_amplifier')

    const activeOwner = props.ownerships.find(ownership => ownership.get('ownable-type') === 'User')

    setProcessing(true)
    routerUtils.setLocation(`${WEBAPPS_HOST}/external/#/contact-advisor/${activeOwner.get('ownable-id')}`)
    setProcessing(false)
  }

  return (
    <ModuleBox className='client-deputy-upgrade-prompt'>
      <div className='client-deputy-upgrade-prompt-top'>
        {addPossessive(humanizedFirstNamesWithConjunction(props.ownerships, 'and'))}{' '}
        Everplan is provided by
      </div>
      <AdvisorDetails advisorContactCard={props.advisorContactCard} />
      <Button loading={processing} onClick={goToDeputyUpgrade}>
        Request Everplans Premium
      </Button>
    </ModuleBox>
  )
}

ClientDeputyUpgradePrompt.propTypes = {
  advisorContactCard: PropTypes.instanceOf(Map),
  ownerships: PropTypes.instanceOf(List)
}

export default ClientDeputyUpgradePrompt
