import PropTypes from 'prop-types'
import {Component} from 'react'
import {fromJS, List, Map} from 'immutable'

import AddKeyFieldForm from './AddKeyFieldForm'
import KeyFieldPreview from './KeyFieldPreview'

import {Accept} from '../../../../blocks/Buttons'
import TrashCanIcon from '../../../../icons/TrashCanIcon'

import {itemElements} from '../../../../../../../lib/plan_data/item'

import './keyField.scss'

export class KeyFieldController extends Component {
  constructor(props) {
    super(props)
    this.state = this.initializeState()

    this.addKeyFieldValues = this.addKeyFieldValues.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.deleteKeyFieldValues = this.deleteKeyFieldValues.bind(this)
    this.showModal = this.showModal.bind(this)
  }

  showModal() {
    this.setState({showModal: true})
  }

  closeModal() {
    this.setState({showModal: false})
  }

  initializeState() {
    if (this.props.itemKeyFieldOptions && this.props.itemKeyFieldOptions.get(this.props.keyFieldOptionNumber)) {
      return {
        keyFieldValues: this.props.itemKeyFieldOptions.get(this.props.keyFieldOptionNumber).toList(),
        showKeyValue: true,
        showModal: false
      }
    } else {
      return {
        keyFieldValues: List(),
        showKeyValue: false,
        showModal: false
      }
    }
  }

  deleteKeyFieldValues() {
    const keyFieldData = Map({
      key: this.props.item.get('slug'),
      keyFieldOptionNumber: this.props.keyFieldOptionNumber
    })

    this.setState({showKeyValue: false, keyFieldValues: List()})
    this.props.deleteKeyFieldToolData(keyFieldData)
  }

  addKeyFieldValues(keyFieldValues) {
    const keyFieldValuesData = fromJS({
      [this.props.item.get('slug')]: {
        [this.props.keyFieldOptionNumber]: keyFieldValues
      }
    })

    this.props.addKeyFieldToolData(keyFieldValuesData)

    this.setState({keyFieldValues, showKeyValue: true, showModal: false})
  }

  elementsOption() {
    return itemElements(this.props.item, this.props.kits).flatten(true)
      .map(element => (
        Map({
          id: element.get('id'),
          label: element.get('name'),
          value: element.get('name')
        })
      ))
      .unshift(Map({value: '', id: 'empty-value'}))
      .push(Map({id: 'STRING', label: 'Add String', value: 'Add String', type: 'string'}))
  }

  render() {
    return (
      <div className='key-field'>
        {
          this.state.showKeyValue ?
            <span>
              <KeyFieldPreview keyFieldValues={this.state.keyFieldValues} />
              <span onClick={this.deleteKeyFieldValues}>
                <TrashCanIcon />
              </span>
            </span> :
            <Accept onClick={this.showModal}>Add Key Fields</Accept>
        }

        <AddKeyFieldForm
          addKeyFieldValues={this.addKeyFieldValues}
          closeModal={this.closeModal}
          elementsOption={this.elementsOption()}
          header={this.props.item.get('header')}
          showModal={this.state.showModal}
        />
      </div>
    )
  }
}

KeyFieldController.propTypes = {
  addKeyFieldToolData: PropTypes.func.isRequired,
  deleteKeyFieldToolData: PropTypes.func.isRequired,
  item: PropTypes.instanceOf(Map).isRequired,
  itemKeyFieldOptions: PropTypes.instanceOf(Map),
  keyFieldOptionNumber: PropTypes.string.isRequired,
  kits: PropTypes.instanceOf(List).isRequired
}

export default KeyFieldController
