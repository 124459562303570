import {Component} from 'react'
import {Map} from 'immutable'
import PropTypes from 'prop-types'

import routerUtils from '../../lib/routerUtils'
import ArticleBox from './ArticleBox'


export default class EverplansArticle extends Component {
  constructor(props) {
    super(props)

    this.next = this.next.bind(this)
  }

  next() {
    routerUtils.addQuery({
      hashLocation: this.props.location,
      queryKey: 'article',
      queryValue: this.props.article.get('query')
    })
  }

  render() {
    return (
      <ArticleBox
        article={this.props.article}
        next={this.next}
        context={this.props.context}
      />
    )
  }
}

EverplansArticle.propTypes = {
  article: PropTypes.instanceOf(Map),
  context: PropTypes.string,
  location: PropTypes.shape({
    query: PropTypes.shape({
      article: PropTypes.string
    })
  })
}
