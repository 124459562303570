import {Component} from 'react'
import {List, Map} from 'immutable'
import PropTypes from 'prop-types'

import Form from '../../../../../../../shared_components/forms/v2/Form'
import Element from '../../../item/Element'
import {BoxGutterMedium} from '../../../../blocks/Boxes'

import {rectifyConditionalMaps} from '../../../../../../../lib/plan_data/item'


export default class ViewPreview extends Component {
  constructor(props) {
    super(props)

    this.state = {elements: props.selectedElements}

    this.updateElements = this.updateElements.bind(this)
  }

  componentDidMount() { this.updateElements() }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({elements: nextProps.selectedElements})
    this.updateElements()
  }

  formData() { return this.refs.form ? this.refs.form.formData() : List() }

  updateElements() {
    const elements = rectifyConditionalMaps({
      responses: this.formData(),
      parentElement: Map(),
      childElements: this.props.selectedElements,
      kits: this.props.kits,
      listMappings: this.props.listMappings
    })

    this.setState({elements: this.filterElementsByViewElements(elements)})
  }

  /**
    Need to remove any elements returned from rectifyConditionalMaps that are not part of the view, since
    rectifyConditionalMaps returns all elements for a conditional kit, but a view might not have them all.
  */
  filterElementsByViewElements(elements) {
    const allViewElementIds = this.props.view
      .getIn(['data', 'elements'])
      .concat(this.props.view.getIn(['data', 'conditional-elements'], List()))
      .map(viewElement => viewElement.get('element-id'))

    return elements.filter(element => allViewElementIds.includes(element.get('id')))
  }

  render() {
    return (
      <BoxGutterMedium className='view-preview selected-view'>
        <Form className='core' ref='form' onUpdate={this.updateElements}>
          <h3>Preview</h3>
          <div>
            {
              this.state.elements.map(
                element => (
                  <Element
                    {...this.props}
                    key={element.get('id')}
                    formData={this.formData()}
                    element={element}
                    data={List()}
                  />
                )
              )
            }
          </div>
        </Form>
      </BoxGutterMedium>
    )
  }
}

ViewPreview.propTypes = {
  listMappings: PropTypes.instanceOf(Map),
  kits: PropTypes.instanceOf(Map),
  selectedElements: PropTypes.instanceOf(List),
  view: PropTypes.instanceOf(Map)
}
