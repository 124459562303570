

import {Component} from 'react'
import PropTypes from 'prop-types'

import Container from '../lib/Container'

// @todo Consider incorporating this functionality into `bindToStores`
// Description: This wrapper component is for form-components that
// expect an API response as a form field validation.  An example
// use case is validating an email address and receiving an error
// that is belongs to another advisor.  See AddFirmUser.react.js

export default (ComponentToBeWrapped, storeName, errorField) => {

  class BoundUpdate extends Component {
    constructor() {
      super()

      this.boundStore = Container.getStore(storeName)

      this.state = {
        data: {},
        errors: [],
        processing: false
      }

      this.onUpdate = this.onUpdate.bind(this)
      this.finished = this.finished.bind(this)
      this.updating = this.updating.bind(this)
    }

    componentDidMount() { this.boundStore.addChangeListener(this.onUpdate) }

    onUpdate() {
      var storeState = this.boundStore.getState()
      let newState = {processing: false}

      if (storeState.errors.length === 0) {
        newState.errors = []
        newState.data = storeState.data
        if (this.state.updateCallback)
          this.state.updateCallback()
      } else
        newState.errors = [{name: errorField, errors: storeState.errors}]

      newState.updateCallback = null
      this.setState(newState)
    }

    componentWillUnmount() { this.boundStore.removeChangeListener(this.onUpdate) }

    finished() { this.setState({errors: [], processing: false}) }

    updating(updateCallback) {
      this.setState({
        errors: [],
        processing: true,
        updateCallback
      })
    }

    render() {
      return (
        <ComponentToBeWrapped
          data={this.state.data}
          errors={this.state.errors}
          updating={this.updating}
          finished={this.finished}
          {...this.props}
          processing={this.props.processing || this.state.processing} // Prevent stomping on props passed from parents. --BLR
        />
      )
    }
  }

  BoundUpdate.defaultProps = {
    processing: false
  }

  BoundUpdate.propTypes = {
    processing: PropTypes.bool
  }

  return BoundUpdate
}
