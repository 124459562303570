import {Component} from 'react'
import {Map} from 'immutable'
import PropTypes from 'prop-types'

import {BoxGutterMedium} from '../../../blocks/Boxes'
import UnorderedList from '../../../../../../shared_components//UnorderedList'

export default class View extends Component {
  constructor() {
    super()

    this.showUpdateViewModal = this.showUpdateViewModal.bind(this)
  }

  showUpdateViewModal() {
    this.props.showUpdateViewModal(this.props.view)
  }

  viewElementFieldPrompts() {
    return this.props.view.getIn(['data', 'elements'])
      .sortBy(view => view.get('sort-order'))
      .map(element => element.get('field-prompt')).toArray()
  }

  render() {
    return (
      <div className='individual-view'>
        <BoxGutterMedium>
          <h3>{this.props.view.get('name')} Wizard</h3>
          <a onClick={this.showUpdateViewModal}>Update</a>
          <UnorderedList children={this.viewElementFieldPrompts()} />
        </BoxGutterMedium>
      </div>
    )
  }
}

View.propTypes = {
  showUpdateViewModal: PropTypes.func,
  view: PropTypes.instanceOf(Map)
}
