import PropTypes from 'prop-types'
import {Component} from 'react'
import {List, Map, fromJS} from 'immutable'

import {Button} from '../../../blocks/Buttons'
import Form from '../../../../../../shared_components/forms/v2/Form'
import NextBestActionItemSelectFields from './NextBestActionItemSelectFields'
import NextBestActionSimpleFields from './NextBestActionSimpleFields'
import NextBestActionSelectWizardForm from './NextBestActionSelectWizardForm'
import Fields from '../../../../../../shared_components/forms/v2/fields/index'

export default class NextBestActionForm extends Component {
  constructor(props) {
    super(props)

    this.state = {nextBestAction: props.nextBestAction}

    this.saveData = this.saveData.bind(this)
    this.setNextBestActionType = this.setNextBestActionType.bind(this)
    this.updateNextBestAction = this.updateNextBestAction.bind(this)
  }

  saveData() {
    this.props.submitNextBestActionData(this.state.nextBestAction.filter(attribute => attribute))
  }

  typeItems() {
    return [
      {
        label: 'Select Type',
        value: ''
      },
      {
        id: 'full-item',
        label: 'full-item',
        value: 'full-item'
      },
      {
        id: 'wizard',
        label: 'wizard',
        value: 'wizard'
      },
      {
        id: 'gap-filler',
        label: 'gap-filler',
        value: 'gap-filler'
      }
    ]
  }

  // reset wizard information and update type so that the view-id is not saved when changing from wizard to another type
  setNextBestActionType() {
    this.setState({
      nextBestAction: this.state.nextBestAction
        .delete('view-id')
        .set('type', this.refs.form.formData().getIn(['type', 'value']))
    })
  }

  updateNextBestAction(nextBestAction) { this.setState({nextBestAction}) }


  sharedProps() {
    return {
      ...this.props,
      ...this.state,
      updateNextBestAction: this.updateNextBestAction
    }
  }

  render() {
    return (
      <div className='forms-playground next-best-action-form'>
        <Form className='core' ref='form'>
          <Fields.Select
            data={
              fromJS({
                id: 'type',
                items: this.typeItems(),
                legend: 'Select Type',
                name: 'type',
                value: this.state.nextBestAction.get('type')
              })
            }
            onChange={this.setNextBestActionType}
          />
        </Form>
        <NextBestActionSelectWizardForm {...this.sharedProps()} />
        <NextBestActionItemSelectFields {...this.sharedProps()} />
        <NextBestActionSimpleFields
          {...this.sharedProps()}
          key={this.state.nextBestAction.getIn(['item', 'item-slug'], '')} // Needed to update the header field when an item changes
          showFields={this.state.nextBestAction.get('type')}
        />

        <Button onClick={this.saveData} processing={this.props.processing}>
          Save data
        </Button>
      </div>
    )
  }
}

NextBestActionForm.propTypes = {
  categories: PropTypes.instanceOf(List),
  createResource: PropTypes.func,
  items: PropTypes.instanceOf(List),
  kits: PropTypes.instanceOf(Map),
  listMappings: PropTypes.instanceOf(Map),
  nextBestAction: PropTypes.instanceOf(Map),
  processing: PropTypes.bool,
  submitNextBestActionData: PropTypes.func
}
