import classnames from 'classnames'
import {useEffect} from 'react'
import {List, Map} from 'immutable'
import PropTypes from 'prop-types'
import uuid from 'uuid'

import AddAnotherResponseGroupLink from './AddAnotherResponseGroupLink'
import {BoxGutterLarge} from '../../blocks/Boxes'
import ItemForm from './ItemForm'
import ItemIcons from './ItemIcons'
import ItemResponseGroupView from './ItemResponseGroupView'

import './viewItem.scss'

const ViewItem = props => {
  useEffect(() => {
    if (!props.params.groupId)
      props.exit()
  }, [props.params.groupId])


  const newGroupIds = [props.params.groupId].concat([uuid.v4()])

  const hasAtLeastOneResponse = !props.responsesGroupedBySecondGroupId.isEmpty()

  return (
    <div className={classnames('view-item flex-child', props.className)}>
      <AddAnotherResponseGroupLink {...props} hasAtLeastOneResponse={hasAtLeastOneResponse} />
      {
        !hasAtLeastOneResponse &&
          (
            <ItemForm
              {...props}
              addMore={props.hasMultipleFieldGroups}
              groupId={newGroupIds}
              initialGroupId={props.params.groupId}
            />
          )
      }
      {
        props.responsesGroupedBySecondGroupId.map(secondResponseGroup =>
          (
            <BoxGutterLarge key={secondResponseGroup.get('id')} className='responses flex-container'>
              <ItemResponseGroupView {...props} responses={secondResponseGroup.get('responses')} />
              {
                props.isEditableItem && (
                  <ItemIcons
                    {...props}
                    {...props.params}
                    secondResponseGroup={secondResponseGroup}
                  />
                )
              }
            </BoxGutterLarge>
          )
        )
      }
    </div>
  )
}

ViewItem.propTypes = {
  actor: PropTypes.string,
  context: PropTypes.string,
  responsesGroupedBySecondGroupId: PropTypes.instanceOf(List),
  eventType: PropTypes.string,
  exit: PropTypes.func,
  groupId: PropTypes.string,
  hasMultipleFieldGroups: PropTypes.bool,
  header: PropTypes.string,
  initialGroupId: PropTypes.string,
  isEditableItem: PropTypes.bool,
  item: PropTypes.instanceOf(Map),
  keyField: PropTypes.instanceOf(List),
  newContacts: PropTypes.instanceOf(List),
  params: PropTypes.shape({groupId: PropTypes.string}),
  views: PropTypes.instanceOf(List)
}

export default ViewItem
