import {useForm, Controller} from 'react-hook-form'
import {useState, useEffect} from 'react'
import PropTypes from 'prop-types'

import {ModalHeader} from '../../../../../shared_components/core/modals/ModalHeaders'
import Button from '../../../../../shared_components/mui_base_components/button/Button'
import ButtonGroup from '../../../../../shared_components/core/buttons/ButtonGroup'
import TextInput from '../../../../../shared_components/forms/v3/TextInput'

import './addDeputyStepThree.scss'


const AddDeputyStepThree = props => {
  const [processing, setProcessing] = useState(false)

  const {control, handleSubmit} = useForm({
    defaultValues: {
      inviteText: props.deputy.inviteText || ''
    }
  })

  useEffect(() => {
    props.logMixpanelEvent('add_deputy_personal_message')
  }, [])

  const submit = () => {
    setProcessing(true)
    props.submit()
  }

  return (
    <div className='add-deputy-step-three'>
      <div className='add-deputy-message'>
        <ModalHeader
          heading={`One last step before we send ${props.deputy['first-name']} an invitation`}
          banner='Personal message'
          subheading="Let your deputy know exactly why you're sharing your
          Everplan with them in your own words."
        />
        <form>
          <Controller
            name='inviteText'
            control={control}
            render={({field}) => (
              <TextInput
                {...field}
                label='Add a personal message (recommended)'
                multiline
                rows={10}
                onChange={value => {
                  field.onChange(value)
                  props.updateDeputy({inviteText: value.target.value})
                }}
              />
            )}
          />
        </form>
      </div>
      <ButtonGroup>
        <Button color='cancel' disabled={processing} onClick={props.goBack}>
          Back
        </Button>
        <Button
          loading={processing}
          onClick={handleSubmit(submit)}>
          Send invitation
        </Button>
      </ButtonGroup>
    </div>
  )
}

AddDeputyStepThree.propTypes = {
  deputy: PropTypes.object,
  goBack: PropTypes.func,
  logMixpanelEvent: PropTypes.func,
  submit: PropTypes.func,
  updateDeputy: PropTypes.func
}

export default AddDeputyStepThree
