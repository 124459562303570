import {useEffect} from 'react'
import {withRouter} from 'react-router'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'

import MixpanelLogger from '../lib/MixpanelLogger'
import premiumIcon from '../shared_assets/premium.svg'
import routerUtils from '../lib/routerUtils'
import {freemiumResponseGroupThreshold} from '../lib/premiumSubscriptionTools'

import './premiumBanner.scss'


export const PremiumBanner = props => {
  useEffect(() => {
    // For now only want to log this event if the user got here by clicking a premium item
    if (props.itemName) {
      MixpanelLogger.track(
        props.includeLinkToPremiumUpgrade ? 'view_premium_banner' : 'view_premium_prompt',
        {item_name: props.itemName, context: props.context})
    }
  }, [])

  const gotToPaymentForm = () => {
    MixpanelLogger.track('click_upgrade_to_premium', {item_name: props.itemName, context: props.context})

    routerUtils.push(`#/cm/account-settings/payment-form?itemSlug=${props.params.itemSlug}&prevPath=${encodeURIComponent(props.location.pathname)}`)
  }

  const upgradeToPremium = () => {
    if (props.includeLinkToPremiumUpgrade)
      return <span><a onClick={gotToPaymentForm}>upgrade to Premium</a>.</span>

    return <span>upgrade to Premium.</span>
  }

  return (
    <div className='premium-banner'>
      <div className='title-container'>
        <img alt='' src={premiumIcon} />
        <div className='title'>Unlock it all with Premium</div>
        <img alt='' src={premiumIcon} />
      </div>
      <div className='message'>
        To add {props.showEditText && 'or edit '}information for more than {props.freemiumResponseGroupThreshold} items, we invite you to{' '}
        {upgradeToPremium()}
      </div>
    </div>
  )
}

PremiumBanner.defaultProps = {
  includeLinkToPremiumUpgrade: false,
  showEditText: false,
  location: {
    pathname: ''
  },
  params: {
    itemSlug: ''
  }
}

PremiumBanner.propTypes = {
  context: PropTypes.string,
  freemiumResponseGroupThreshold: PropTypes.number,
  includeLinkToPremiumUpgrade: PropTypes.bool,
  itemName: PropTypes.string,
  showEditText: PropTypes.bool,
  location: PropTypes.shape({
    pathname: PropTypes.string
  }),
  params: PropTypes.shape({
    itemSlug: PropTypes.string
  })
}

const mapStateToProps = state => ({
  freemiumResponseGroupThreshold: freemiumResponseGroupThreshold({settings: state.api.settings})
})

export default connect(mapStateToProps)(withRouter(PremiumBanner))
