import {Component} from 'react'

import {Cancel} from '../../blocks/Buttons'
import routerUtils from '../../../../../lib/routerUtils'

import './deleteAccount.scss'
export default class DeleteAccount extends Component {
  constructor() {
    super()

    this.handleClick = this.handleClick.bind(this)
  }

  handleClick() {
    routerUtils.push('cm/account-settings/delete-account')
  }

  render() {
    return (
      <div className='delete-account'>
        <Cancel onClick={this.handleClick}>
          Delete my account
        </Cancel>
      </div>
    )
  }
}
