/** @class FuneralExpenseOneMoment
 *
 * @desc
 * Component to display an animated gears GIF while we pretend we're analyzing what
 * the user entered during the funeral expense assessment.
 */

import PropTypes from 'prop-types'
import {useEffect} from 'react'

import gearsAnimation from '../../../../../shared_assets/v2/gears-animation@2x.gif'

import './funeralExpenseOneMoment.scss'

export const ONE_MOMENT_DURATION = 2000

const FuneralExpenseOneMoment = ({onStepChange, displayText}) => {
  useEffect(() => {
    setTimeout(() => onStepChange(), ONE_MOMENT_DURATION)
  }, [])

  return (
    <div className='funeral-expense-one-moment'>
      <img alt='gears animation' src={gearsAnimation} />
      <h2>One Moment</h2>
      <p>{displayText}</p>
    </div>
  )
}

FuneralExpenseOneMoment.defaultProps = {
  displayText: 'While we determine the cost of your send-off...'
}

FuneralExpenseOneMoment.propTypes = {
  onStepChange: PropTypes.func,
  displayText: PropTypes.string
}

export default FuneralExpenseOneMoment
