import {Map} from 'immutable'
import PropTypes from 'prop-types'

import './greetings.scss'

const Greetings = ({currentUser}) => {
  if (currentUser) {
    return (
      <div className='greetings'>
        <h1>Good to see you, {currentUser.get('first-name')}!</h1>
      </div>
    )
  } else {
    return null
  }
}

Greetings.propTypes = {
  currentUser: PropTypes.instanceOf(Map)
}

export default Greetings
