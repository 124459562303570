import PropTypes from 'prop-types'
import {Component} from 'react'
import {Map} from 'immutable'

import Form from '../../../../../../shared_components/forms/v2/Form'
import Fields from '../../../../../../shared_components/forms/v2/fields/index'
import {findSelectedValueId} from '../../../../../../shared_components/forms/v2/lib/tools'
import {findResourceById} from '../../../../../../lib/plan_data/userData'

export default class NextBestActionSelectWizardForm extends Component {
  constructor() {
    super()

    this.onUpdate = this.onUpdate.bind(this)
  }

  onUpdate() {
    if (this.refs && this.refs.form) {
      this.props.updateNextBestAction(
        this.props.nextBestAction.set('view-id', findSelectedValueId({selectId: 'view-id', form: this.refs.form}))
      )
    }
  }

  viewItems() {
    return this.props.views
      .map(view => Map(
        {
          id: view.get('id'),
          label: view.get('name'),
          value: view.get('name')
        }
      ))
      .unshift(Map({label: 'Choose a Wizard', value: ''}))
  }

  viewSelectValue() {
    return findResourceById({
      resourceList: this.props.views,
      id: this.props.nextBestAction.get('view-id')
    }).get('name')
  }

  render() {
    if (this.props.nextBestAction.get('type') === 'wizard') {
      return (
        <Form className='core next-best-action-wizard-fields' ref='form' onUpdate={this.onUpdate}>
          <Fields.Select
            data={
              Map({
                id: 'view-id',
                items: this.viewItems(),
                legend: 'Select Wizard',
                name: 'view-id',
                value: this.viewSelectValue()
              })
            }
          />
        </Form>
      )
    } else {
      return null
    }
  }
}

NextBestActionSelectWizardForm.propTypes = {
  nextBestAction: PropTypes.instanceOf(Map),
  updateNextBestAction: PropTypes.func,
  views: PropTypes.instanceOf(Map)
}
