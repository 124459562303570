import {Map, fromJS, List} from 'immutable'
import PropTypes from 'prop-types'

import Fields from '../../../../../shared_components/forms/v2/fields'
import validators from '../../../../forms/v2/validators'
import DeleteIcon from '../../../../../shared_assets/v2/DeleteIcon'

import './digitalSubscriptionFormField.scss'
import {currencyAmountWithSymbol} from '../../../../../lib/currencyTools'
import {formatDate} from '../../../../../lib/dateTools'

const OCCURRENCE = Map({
  WEEKLY: 'week',
  MONTHLY: 'month',
  QUARTERLY: 'quarter',
  ANNUALLY: 'year'
})


const DigitalSubscriptionFormField = ({
  subscription,
  removeSubscription,
  digitalItems
}) => {
  const id = subscription.get('id')
  const lastDate = formatDate(subscription.get('last-date'))
  const occurrenceType = OCCURRENCE.get(subscription.get('occurrence-type'))
  const amount = currencyAmountWithSymbol({
    amount: subscription.get('price'),
    currency: subscription.get('iso-currency-code')
  })

  return (
    <div className='flex-container digital-subscription-form-field'>
      <div className='name-field field-container'>
        <div className='field-label'>Name</div>
        <Fields.Text
          data={Map({
            id: `name-${id}`,
            name: `name-${id}`,
            value: subscription.get('name'),
            'subscription-id': id
          })}
          validator={validators.requiredCustomFieldNameCallback('name')}
        />
      </div>
      <div className='category-field field-container'>
        <div className='field-label'>Category</div>
        <Fields.Select
          data={fromJS({
            id: `category-${id}`,
            items: digitalItems,
            name: `category-${id}`,
            value: subscription.get('item-guess'),
            'subscription-id': id
          })}
          validator={validators.requiredCustomFieldNameCallback('category')}
        />
      </div>
      <div className='note-field field-container'>
        <div className='field-label'>Amount</div>
        <div>{`${amount}/${occurrenceType}`}</div>
        <div className='last-date'>Last billed: {lastDate}</div>
      </div>
      <div
        className='delete-field field-container'
        onClick={() => removeSubscription(id)}>
        <DeleteIcon
          alt={`Delete ${subscription.get('name')} subscription`}
          fill='#262626'
          height='15'
          width='15'
        />
      </div>
    </div>
  )
}

DigitalSubscriptionFormField.propTypes = {
  subscription: PropTypes.instanceOf(Map),
  removeSubscription: PropTypes.func,
  digitalItems: PropTypes.instanceOf(List)
}

export default DigitalSubscriptionFormField
