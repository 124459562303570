import {List} from 'immutable'
import PropTypes from 'prop-types'

import {allOwnersDeceased, showOwnerReportedAsDeceased} from '../../../../../../lib/corpMaxDeputyTools'

const DeathReportStatus = ({isHousehold, ownerships, deathReports}) => {
  if (!allOwnersDeceased({ownerships, deathReports})) {
    return (
      <div className='death-report-status'>
        {
          ownerships.map(
            ownership => (
              showOwnerReportedAsDeceased({isHousehold, deathReports, ownership, ownerships}) &&
              (
                <div key={ownership.get('id')}>
                  {ownership.get('first-name')} was reported as deceased
                </div>
              )
            )
          )
        }
      </div>
    )
  } else {
    return null
  }
}

DeathReportStatus.propTypes = {
  deathReports: PropTypes.instanceOf(List),
  isHousehold: PropTypes.bool,
  ownerships: PropTypes.instanceOf(List)
}

export default DeathReportStatus
