import {useEffect} from 'react'
import PropTypes from 'prop-types'

import AnimatedScore from '../../../../shared_components/AnimatedScore'
import {Button} from '../blocks/Buttons'

import Logger from '../../../../lib/NewLogger'

import './assessmentScoreResult.scss'

const AssessmentScoreResult = props => {
  const {score: {heading, body, total}, setShowModal, logMixpanelEvent} = props

  useEffect(() => {
    logMixpanelEvent({name: 'view_assessment', payload: {step: 'result_score'}})
    Logger.log({
      name: 'viewed_onboarding_final_screen',
      payload: {number_of_assessment_questions: props.numberOfQuestionsAnswered}
    })
  }, [])

  const closeModal = () => {
    setShowModal(false)
    logMixpanelEvent({name: 'view_assessment_all_set'})
  }

  return (
    <div className='assessment-score-result'>
      <h1 className='heading top'>Your Everplans Score</h1>
      <AnimatedScore
        referenceArcColor='#FFF'
        value={total}
      />
      <h1 className='heading'>{heading}</h1>
      {body.map(text => (
        <p className='body-text' key={text}>{text}</p>
      ))}
      <Button onClick={closeModal}>Continue</Button>
    </div>
  )
}

AssessmentScoreResult.propTypes = {
  logMixpanelEvent: PropTypes.func.isRequired,
  score: PropTypes.shape({
    body: PropTypes.arrayOf(PropTypes.string),
    heading: PropTypes.string,
    total: PropTypes.number
  }).isRequired,
  setShowModal: PropTypes.func,
  numberOfQuestionsAnswered: PropTypes.number
}

export default AssessmentScoreResult
