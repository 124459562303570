import PropTypes from 'prop-types'
import {useState} from 'react'
import {Map} from 'immutable'

import Modals from '../../../../../shared_components/core/modals/Modals'
import {ModalHeader} from '../../../../../shared_components/core/modals/ModalHeaders'
import ItemForm from './ItemForm'
import ItemCloser from './ItemCloser'

import {routeContainsVerb} from '../../../lib/corpMaxRouteMatcher'
import routerUtils from '../../../../../lib/routerUtils'

import './compoundResponseGroup.scss'

const CompoundResponseGroup = props => {
  const [showModal, setShowModal] = useState(true)

  const shouldGoToViewPath = routeContainsVerb(props.location, ['edit', 'add-more']) || !props.responseGroup.isEmpty()

  const afterModalClosePath = () => {
    const elementId = props.params.elementId
    return shouldGoToViewPath ? props.viewItemPath({elementId, groupId: props.groupId[0]}) : props.newItemPath({elementId})
  }

  const showExpandedForm = routeContainsVerb(props.location, ['new'])

  const exit = () => {
    routerUtils.push(afterModalClosePath())
    setShowModal(false)
  }

  const closer = () => (
    <ItemCloser
      confirmModalExit={props.hasChangedItemFormData}
      exit={exit}
      itemName={props.itemResponse.get('name')}
      context={props.context}
      eventType={props.eventType}
      compoundItem
    />
  )

  return (
    <Modals.FullHeightModalLarge className='forms-playground compound-response-group' closerComponent={closer} showModal={showModal} >
      <ModalHeader heading={props.currentElement.get('name')} />
      <ItemForm
        {...props}
        hasMultipleFieldGroups
        exit={exit}
        showExpandedForm={showExpandedForm}
      />
    </Modals.FullHeightModalLarge>
  )
}


CompoundResponseGroup.propTypes = {
  context: PropTypes.string,
  currentElement: PropTypes.instanceOf(Map),
  eventType: PropTypes.string,
  groupId: PropTypes.array,
  hasChangedItemFormData: PropTypes.bool,
  initialGroupId: PropTypes.string,
  itemResponse: PropTypes.instanceOf(Map),
  location: PropTypes.object,
  newItemPath: PropTypes.func,
  params: PropTypes.shape({
    elementId: PropTypes.string,
    groupId: PropTypes.string,
    itemSlug: PropTypes.string,
    slug: PropTypes.string
  }),
  responseGroup: PropTypes.instanceOf(Map),
  viewItemPath: PropTypes.func
}

export default CompoundResponseGroup
