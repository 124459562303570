import PropTypes from 'prop-types'
import React from 'react'
import {Map} from 'immutable'

import {CouponApplied} from '../../../../lib/subscriptionTextTools'
import Forms from '../../../../shared_components/forms'
import Spinner from '../../../../shared_assets/spinner.svg'

export default class CouponCode extends React.Component {
  constructor() {
    super()

    this.getCouponCode = this.getCouponCode.bind(this)
    this.clearCoupon = this.clearCoupon.bind(this)
  }
  getCouponCode() { return this.refs.form.formData().couponCode }
  clearCoupon() {
    this.refs.couponCode.clearFormValue('couponCode')
    this.refs.couponCode.setState({formValue: ''})
  }

  render() {
    return (
      <div className='coupon-code'>
        <Forms.Form className='core' onSubmit={this.props.applyCoupon} ref='form'>
          <Forms.Input
            defaultValue={this.props.coupon.id}
            label='Have a coupon code?'
            name='couponCode'
            placeholder='Enter here'
            ref='couponCode'
            tabIndex={4}
          />
          {$.isEmptyObject(this.props.coupon) ? <a onClick={this.props.applyCoupon}>Apply</a> : <a onClick={this.props.clearCoupon}>Clear</a>}
          {this.props.processingCoupon && <img className='coupon-processing' src={Spinner} alt='Spinner, Everplans' />}
          <CouponApplied activePlan={this.props.activePlan} coupon={this.props.coupon} callout='Coupon' trialExpired={this.props.trialExpired} partner={this.props.partner} />
        </Forms.Form>
      </div>
    )
  }
}

CouponCode.propTypes = {
  activePlan: PropTypes.instanceOf(Map),
  applyCoupon: PropTypes.func,
  clearCoupon: PropTypes.func,
  coupon: PropTypes.shape({
    id: PropTypes.string
  }),
  processingCoupon: PropTypes.bool,
  partner: PropTypes.shape({
    suppress_trial: PropTypes.bool
  }),
  trialExpired: PropTypes.bool
}

CouponCode.defaultProps = {
  coupon: {}
}
