import {Map} from 'immutable'
import PropTypes from 'prop-types'

import {TextGutterMedium} from '../../../blocks/Texts'
import {pluralize} from '../../../../../../lib/tools'

const ExistingItemPermissionInformation = ({advisorFullName, firmOwnership}) => {
  const editItemIdCount = firmOwnership.get('edit-item-ids').size

  return (
    <TextGutterMedium>
      { editItemIdCount ?
        `You've given ${advisorFullName} permission to edit ${editItemIdCount} ${pluralize('section', editItemIdCount)} of your Everplan.` :
        `Give permission to ${advisorFullName} to edit any section of your Everplan on your behalf.`
      }
    </TextGutterMedium>
  )
}

ExistingItemPermissionInformation.propTypes = {
  advisorFullName: PropTypes.string,
  firmOwnership: PropTypes.instanceOf(Map)
}

export default ExistingItemPermissionInformation
