import AppDispatcher from '../../../lib/ep-dispatcher'
import KeyMirror from 'keymirror'
import TestPrep from '../../../lib/TestPrep'

const WizardActions = {
  Types: KeyMirror({
    TFA_ADD_PRIMARY_PHONE: null,
    TFA_CONFIRM_PHONE: null,
    RESEND_CODE: null,
    GET_BACKUP_CODE: null,
    TFA_ADD_SECONDARY_PHONE: null,
    TFA_ENABLE: null,
    ALL_DONE: null,
    TFA_CLEAR_ERRORS: null,
    TFA_DISABLE: null,
    TFA_CONFIRM_CANCEL_SETUP: null,
    TFA_CONTINUE_SETUP: null
  }),

  setPrimaryPhone({number, nationalNumber, preferredMessageMode}) {
    AppDispatcher.dispatch({
      actionType: WizardActions.Types.TFA_ADD_PRIMARY_PHONE,
      number,
      nationalNumber,
      preferredMessageMode
    })
  },
  confirmPhone(tfa_phone_number_id, code) {
    AppDispatcher.dispatch({
      actionType: WizardActions.Types.TFA_CONFIRM_PHONE,
      code,
      tfa_phone_number_id
    })
  },
  resendCode() {
    AppDispatcher.dispatch({
      actionType: WizardActions.Types.RESEND_CODE
    })
  },
  getBackupCode() {
    AppDispatcher.dispatch({
      actionType: WizardActions.Types.GET_BACKUP_CODE
    })
  },
  setSecondaryPhone({number, nationalNumber, preferredMessageMode}) {
    AppDispatcher.dispatch({
      actionType: WizardActions.Types.TFA_ADD_SECONDARY_PHONE,
      number,
      nationalNumber,
      preferredMessageMode
    })
  },
  enableTfa() {
    AppDispatcher.dispatch({
      actionType: WizardActions.Types.TFA_ENABLE
    })
  },
  allDone() {
    AppDispatcher.dispatch({
      actionType: WizardActions.Types.ALL_DONE
    })
  },
  clearTfaErrors() {
    AppDispatcher.dispatch({
      actionType: WizardActions.Types.TFA_CLEAR_ERRORS
    })
  },
  disableTfa() {
    AppDispatcher.dispatch({
      actionType: WizardActions.Types.TFA_DISABLE
    })
  },
  confirmCancelSetup() {
    AppDispatcher.dispatch({
      actionType: WizardActions.Types.TFA_CONFIRM_CANCEL_SETUP
    })
  },
  continueSetup() {
    AppDispatcher.dispatch({
      actionType: WizardActions.Types.TFA_CONTINUE_SETUP
    })
  }
}

export default {...TestPrep, ...WizardActions}
