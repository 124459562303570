import {gql} from '@apollo/client'

import {DEPUTY_FRAGMENT} from '../fragments/deputy'

export const UPDATE_DEPUTIES_PERMISSIONS = gql`
  ${DEPUTY_FRAGMENT}
  mutation updateDeputiesPermissions($input: [DeputyPermissionsInput!]) {
    updateDeputiesPermissions(input: $input) {
      ...DeputyFragment
    }
  }
`
export const UPDATE_DEPUTY_PERMISSIONS = gql`
  ${DEPUTY_FRAGMENT}
  mutation updateDeputyPermissions($input: [PermissionInput!], $deputyId: String!) {
    updateDeputyPermissions(input: $input deputyId: $deputyId) {
      ...DeputyFragment
    }
  }
`

export const CREATE_DEPUTY = gql`
  ${DEPUTY_FRAGMENT}
  mutation createDeputy($input: CreateDeputyInput!) {
    createDeputy(input: $input) {
      ...DeputyFragment
    }
  }
`

export const DELETE_DEPUTY = gql`
  mutation deleteDeputy($id: String!) {
    deleteDeputy(id: $id) {
      message
    }
  }
`

export const UPDATE_DEPUTY_UNLOCK_PERMISSIONS = gql`
  ${DEPUTY_FRAGMENT}
  mutation updateDeputyUnlockPermission($input: DeputyUnlockPermissionInput!) {
    updateDeputyUnlockPermission(input: $input) {
      ...DeputyFragment
    }
  }
`

export const RESPONSE_TO_DEPUTY_INVITE = gql`
  mutation responseToDeputyInvite($input: DeputyInviteReplyInput!) {
    responseToDeputyInvite(input: $input) {
      id
    }
  }
`

export const DEPUTY_VALIDATION = gql`
  mutation deputyValidation($input: DeputyValidationInput!) {
    deputyValidation(input: $input) {
      email
      firmUser
      firstName
      id
      lastName
    }
  }
`
