import PropTypes from 'prop-types'
import {useFormContext, Controller} from 'react-hook-form'
import {Stack} from '@mui/material'

import TextInput from './TextInput'
import {validateDateInput} from './formTools'

import './date.scss'

const Date = ({label, name}) => {
  const {control, setError, clearErrors, formState: {errors}, getValues} = useFormContext()

  const validateDate = () => {
    const dateValue = getValues(`${name}.value`)

    if (dateValue) {
      const validatedDate = validateDateInput(dateValue)

      if (validatedDate === 'Invalid date') {
        Object.entries(dateValue).forEach(([key, value]) => {
          value ? setError(`${name}.${key}`, {type: 'manual', message: 'Please add a valid date'}) : clearErrors(`${name}.${key}`)
        })
      } else {
        clearErrors(name)
      }
    }
  }

  const renderTextField = (fieldName, fieldLabel) => (
    <Controller
      name={`${name}.value.${fieldName}`}
      control={control}
      defaultValue=''
      render={({field}) => (
        <TextInput
          {...field}
          ariaLabel={fieldName}
          id={fieldName}
          onBlur={validateDate}
          error={!!errors[name]?.[fieldName]}
          label=''
          placeholder={fieldLabel}
          type='number'
        />
      )}
    />
  )

  return (
    <Stack alignItems='flex-start' className='date' display='flex'>
      <label aria-label='date' htmlFor='month'>{label}</label>
      <Stack className='date-fields-container' direction='row'>
        {renderTextField('month', 'MM')}
        {renderTextField('day', 'DD')}
        {renderTextField('year', 'YYYY')}
      </Stack>
      {errors[name] && <span className='error'>{Object.values(errors[name])[0].message}</span>}
    </Stack>
  )
}

Date.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired
}

export default Date
