import {Map} from 'immutable'

const body = `
In order to complete these important documents you should have a sense of what types of life support treatments are commonly available, as well as the benefits and drawbacks to help you make informed decisions.

### How To Evaluate Life Support Treatments

All life-support treatments have benefits and disadvantages. For example, while a certain treatment may improve a particular function, it may also cause the patient discomfort. When considering life-support treatments, you should talk with your doctor about the nature of these treatments, as well as any specific treatments your particular health situation may require.

For each treatment, it's helpful to consider the following questions:

* What purpose does this treatment serve?
* What are the side effects?
* Does this treatment usually improve overall health, or does it simply extend life?

You don't have to do this alone. Your doctor can help you figure out the impact that certain treatments may have, and can work with you to complete your Living Will.

### Types Of Life Support Treatments

**Medical devices to aid breathing**: A ventilator (also known as a respirator or breathing machine) provides air to your lungs through a tube if you're unable to breath on your own. This tube can be inserted in the mouth, nose, or an opening made in the throat. Apart from being uncomfortable and impairing the ability to speak, The National Heart, Lung and Blood Institute reports that it may lead to infections (the most common being pneumonia), lung damage, and damaged vocal chords.

**Medical devices to provide food and water**: Also known as a "feeding tube" or "tube feeding," this provides nutrition and fluids through a tube inserted in the nose, stomach, or intestines. The possible negative side effects include aspiration (inhaling the liquid food into the lungs), organ puncture, and infection.

**Cardiopulmonary resuscitation (CPR)**: If your heartbeat or breathing has stopped, CPR may be administered to restart the heart or breathing. CPR techniques range from blowing air into the mouth (mouth-to-mouth resuscitation) and pushing on the chest (chest compressions), to electric shocks delivered to the heart (defibrillation), to breathing tubes inserted into the windpipe (intubation). The American Heart Association reports that conventional CPR can cause fracturing of ribs and/or the breastbone (sternum) in at least one third of cases. However, they also report that "the chance of surviving an out-of-hospital cardiac arrest is near zero for a victim who does not immediately receive high-quality chest compressions...followed by additional therapy within minutes."

**Blood transfusions**: If you have lost a significant amount of blood, it may be replenished with an intravenous supply of blood from a donor. According to The Center For Disease Control and Prevention (CDC): "Negative side effects of blood transfusion therapy are uncommon but can include blood transfusion reactions, infections, the development of red blood cell antibodies, and iron overload in different organs of the body."

**Dialysis**: If you have experienced kidney failure, measures may be taken to mechanically filter your blood (as the kidneys would). Dialysis usually entails removing blood from the body, filtering the blood, and returning it to the body. Depending on the type of dialysis you receive possible side effects include infection, hernias, and low blood pressure.

**Antibiotics**: If you have developed a bacterial infection, antibiotics may be used to kill the infection. The most widely reported side effect is antibiotic resistance. According to the CDC, while the benefit of antibiotics has been enormous, "these drugs have been used so widely and for so long that the infectious organisms the antibiotics are designed to kill have adapted to them, making the drugs less effective. People infected with antimicrobial-resistant organisms are more likely to have longer, more expensive hospital stays, and may be more likely to die as a result of the infection."
`


export default Map({
  body,
  headline: "How To Evaluate Life Support Treatments And What They'll Do To Your Body",
  summary: 'When you create an Advance Directive or Living Will, you specify the types of life support treatments and other medical treatments that you would or would not like to receive at the end of life.',
  query: 'how-to-evaluate-life-support-treatments-and-what-they-ll-do-to-your-body',
  url: 'https://www.everplans.com/articles/how-to-evaluate-life-support-treatments-and-what-theyll-do-to-your-body'
})
