import {useState, useEffect} from 'react'
import classnames from 'classnames'
import {connect} from 'react-redux'
import {Map} from 'immutable'
import PropTypes from 'prop-types'
import {updateResource} from 'redux-json-api'

import MixpanelLogger from '../../../../../lib/MixpanelLogger'
import {Accept} from '../../blocks/Buttons'
import Closer from '../../../../../shared_components/core/closer/Closer'
import {TextGutterMedium, TextGutterSmall} from '../../blocks/Texts'
import Modals from '../../../../../shared_components/core/modals/Modals'

import {setViewedFlagV2} from '../../../../../lib/userTools'
import SessionStore from '../../../../../auth/src/stores/SessionStore'
import {showMobileAppAnnouncement} from '../../../../../lib/bannerTools'

import mobileIllustration from '../../../../../shared_assets/v2/mobileIllustration.png'

import './mobileAppAnnouncement.scss'

const IOS_APP_ID = '1590237847'

export const MobileAppAnnouncement = props => {
  const [showModal, setState] = useState(
    !props.currentUser.isEmpty() &&
    props.currentUser.getIn(['viewed-flags', 'has_seen_onboarding_assessment'], '') && // user should have interacted with onboarding assessment before download prompt
    showMobileAppAnnouncement({currentUser: SessionStore.getState().currentUser}) &&
    !props.currentUser.getIn(['viewed-flags', 'has_seen_mobile_ios_announcement'], '')
  )

  useEffect(() => {
    if (showModal)
      MixpanelLogger.track('view_mobile_download_popup')
  }, [])

  useEffect(() => {
    if (showModal) {
      setViewedFlagV2({
        user: props.currentUser,
        flagToSet: 'has_seen_mobile_ios_announcement',
        updateResource: props.updateResource
      })
    }
  }, [])

  const modalProps = () => ({
    className: classnames(
      'mobile-app-announcement',
      'forms-playground'
    ),
    showCloser: true,
    showModal
  })

  const closer = () => (<Closer onClick={closeModal} />)

  const closeModal = () => {
    setState(false)
  }

  const downloadApp = () => {
    MixpanelLogger.track('click_mobile_download_popup')

    closeModal()
    window.open(`https://apps.apple.com/us/app/everplans/${IOS_APP_ID}`, '_blank')
  }

  if (!showModal) return null

  return (
    <Modals.WrappedPopUpModalLarge {...modalProps()} closerComponent={closer}>
      <TextGutterSmall className='label'>What's New</TextGutterSmall>
      <h1 className='heading'>Introducing the new Everplans Mobile App!</h1>
      <img
        className='illustration'
        src={mobileIllustration}
        alt='Graphical Illustration showing ease of access to important daily life information with mobile'
      />
      <TextGutterMedium className='description'>
          The Everplans app brings our stellar organizing and planning capabilities to your phone.{' '}
        <strong>Download the iOS app today</strong> for the best Everplans experience!
      </TextGutterMedium>
      <Accept onClick={downloadApp} className='call-to-action'>Download</Accept>
    </Modals.WrappedPopUpModalLarge>
  )
}

MobileAppAnnouncement.propTypes = {
  currentUser: PropTypes.instanceOf(Map),
  updateResource: PropTypes.func
}

export default connect(null, {updateResource})(MobileAppAnnouncement)
