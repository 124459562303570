/** @module newStorePrototype
 *
 *  @desc
 * This factory is basically a refactor of StorePrototype and does not contain all methods in StorePrototype
 * However, please feel free to add new methods as you deem fit since we hope to use this moving forward. If we do, the name of this file has to change - Atanda
 *  - registerActions - this is an array of action types to auto register with the app dispatcher. The callback will
 *  be the receiveData method.
 *
 *  In general, 95% of functionality can be accomplished using registerActions. But you can still register
 *  your own callbacks, add your own methods, etc..
*/

import AppDispatcher from '../lib/ep-dispatcher'
import {EventEmitter} from 'events'

const factory = registerActions => {
  const newStorePrototype = {
    ...EventEmitter.prototype,
    errors: [],
    payload: {},
    emitChange() { this.emit('CHANGE') },
    addChangeListener(callback) { this.on('CHANGE', callback) },
    clearErrors() { this.errors = [] },
    clearPayload() { this.setPayload({}) },
    getPayload() { return this.payload },
    getState() { return {payload: this.payload, errors: this.errors} },
    receivePayload(data) {
      if (data.errors) {
        this.setErrors(data.errors)
      } else {
        this.setPayload(data)
        this.clearErrors()
      }
      this.emitChange()
    },
    registerReceiveCallback(type) {
      return AppDispatcher.register(({actionType, ...data}) => {
        if (actionType === type)
          this.receivePayload(data)
      })
    },
    update(data) {
      this.setPayload(data)
      this.emitChange()
    },
    removeChangeListener (callback) { this.removeListener('CHANGE', callback) },
    setPayload(data) { this.payload = data },
    setErrors(errors) { this.errors = errors }
  }

  const store = Object.create(newStorePrototype)
  if (registerActions)
    (Array.isArray(registerActions) ? registerActions : [registerActions]).forEach(action => store.registerReceiveCallback(action))
  return store
}

export default factory
