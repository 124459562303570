import PropTypes from 'prop-types'
import {List} from 'immutable'

import {TextGutterLarge} from '../blocks/Texts'
import warningIcon from '../../../assets/exclamation_icon_deputy.svg'
import unlockImage from '../../../assets/choose_an_unlocker.svg'

import {hasDeathReporter} from '../../../../lib/corpMaxDeputyTools'

import './deathReporterReminderPrompt.scss'

const DeathReporterReminderPrompt = ({hasDeputyWithDeferredItems, deputies}) => (
  <div>
    {
      hasDeputyWithDeferredItems && !hasDeathReporter(deputies) &&
      (
        <div className='death-reporter-reminder-prompt'>
          <div>
            <header>
              <img src={warningIcon} alt='Warning' />
              <h3>
                Please select a person to unlock your Everplan
              </h3>
            </header>
            <TextGutterLarge>
              Since you decided to lock some items of your Everplan from your deputies, please select a
              person to let us know when it's time to unlock your Everplan.
            </TextGutterLarge>
            <strong>Take an action:</strong>
            <ul>
              <li className='item'>
                Grant one of your existing deputies permission to unlock, or
              </li>
              <li className='item'>
                Add a new deputy and grant them permission to unlock.
              </li>
            </ul>
          </div>
          <img className='unlock-illustration' src={unlockImage} alt='Unlock Image' />
        </div>
      )
    }
  </div>
)

DeathReporterReminderPrompt.propTypes = {
  deputies: PropTypes.instanceOf(List),
  hasDeputyWithDeferredItems: PropTypes.bool
}

export default DeathReporterReminderPrompt
