import PropTypes from 'prop-types'

const ResourceLink = ({path, title}) => (
  <a
    className='resource-link'
    href={`https://www.everplans.com/${path}`}
    target='_blank'
    rel='noopener noreferrer'>
    {title}
  </a>
)

ResourceLink.defaultProps = {
  path: '',
  title: ''
}

ResourceLink.propTypes = {
  path: PropTypes.string,
  title: PropTypes.string
}

export default ResourceLink
