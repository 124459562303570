/** @module deathVerificationPeriodTools
 *
 * @desc
 * Helpers related to determining various ways to display the
 * `death_verification_period` assigned to Deputies who have
 * permission to report the death of an Everplanner.
 */

import moment from 'moment'
import {OrderedMap} from 'immutable'

import featureFlags from './FeatureFlags'

const MINUTES_TO_STRINGS = new OrderedMap([
  [180, '3 hours'],
  [360, '6 hours'],
  [720, '12 hours'],
  [1440, '24 hours'],
  [2880, '48 hours'],
  [4320, '3 days'],
  [10080, '7 days'],
  [20160, '14 days'],
  [30240, '21 days'],
  [43200, '30 days']
])

/** List of just the human-readable duration strings used to populate the Select in DeputyWaitTimeSelect. */
export const verificationPeriodStrings = MINUTES_TO_STRINGS.toList().toJS()

export const addVerificationPeriodToDate = (date, verificationPeriod) => moment(date).add(verificationPeriod / 60, 'hours')

/**
 * Find verification period string given duration in minutes.
 * @todo Remove the conditional after completing QA on our eventual `household_death_reporting` project.
 *
 */
export const verificationPeriodMinutesToString = minutes => {
  if (minutes === 1 && INTERNAL_ONLY && featureFlags.isEnabled('quality_assurance'))
    return 'Sixty seconds'
  else
    return MINUTES_TO_STRINGS.get(minutes)
}

/**
 * Find the duration in minutes associated with a given verification period string.
 * @todo Remove the conditional after completing QA on our eventual `household_death_reporting` project.
 *
 */
export const verificationPeriodStringToMinutes = string => {
  if (string === 'Sixty seconds' && INTERNAL_ONLY && featureFlags.isEnabled('quality_assurance'))
    return 1
  else
    return MINUTES_TO_STRINGS.findKey(value => (value === string))
}
