import PropTypes from 'prop-types'

import {TextGutterMedium} from '../../blocks/Texts'
import NoPermissionsLinkAndButtons from './NoPermissionsLinkAndButtons'

const NoDeputies = props => (
  <div className='no-deputies'>
    <TextGutterMedium>This item hasn't been shared yet, and you do not have any deputies.</TextGutterMedium>
    <NoPermissionsLinkAndButtons {...props} />
  </div>
)

NoDeputies.propTypes = {
  addNewDeputyLink: PropTypes.func,
  toggleModal: PropTypes.func
}

export default NoDeputies
