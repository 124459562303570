import {useState, useCallback, useEffect} from 'react'
import {Map, List} from 'immutable'
import PropTypes from 'prop-types'

import {TextGutterMedium, TextGutterLarge} from '../../../../dashboard/src/components/blocks/Texts'
import FuneralExpenseCalculator from './FuneralExpenseCalculator'
import FuneralExpenseHeader from '../gap_filler_assessments/funeral_expense/FuneralExpenseHeader'
import Logger from '../../../../lib/NewLogger'

import funeralExpenseCalculatorConfiguration, {
  dropdownConfiguration,
  burialDropdown,
  cremationDropdown,
  checkboxConfiguration,
  ANSWERS_SELECT_VALUE_MAP
} from '../configurations/funeralExpenseCalculatorConfiguration'
import {transformKey, checkboxValues} from '../../../../lib/tools'

import Calculator from '../../../../dashboard/assets/gap_fillers/calculator.svg'

import './funeralExpenseCalculatorContainer.scss'

const FuneralExpenseCalculatorContainer = ({data, onStepChange}) => {
  const remappedData = data.map(value => ANSWERS_SELECT_VALUE_MAP.get(value, value))
  const SELECTED_VALUES_TO_ANSWERS = ANSWERS_SELECT_VALUE_MAP.mapEntries(([key, value]) => [value, key])
  const DROPDOWNS_CONFIGURATION = dropdownConfiguration(remappedData)
  const [funeralChoices, setFuneralChoices] = useState(remappedData.set('final-ceremony', remappedData.get('headstone') || remappedData.get('ashes')))
  const CHECKBOX_CONFIGURATION = checkboxConfiguration(funeralChoices)
  const funeralCategoryDropDown = funeralChoices.get('disposition') === 'I prefer to be buried' ?
    DROPDOWNS_CONFIGURATION.concat(burialDropdown(remappedData)) :
    DROPDOWNS_CONFIGURATION.concat(cremationDropdown(remappedData))

  const funeralRequirements = () => {
    const dropdownItems = funeralCategoryDropDown.reduce((items, dropdown) => items.concat(dropdown.get('items')), List())
    let requirements = CHECKBOX_CONFIGURATION.get('items').reduce(
      (items, checkbox) => {
        if (CHECKBOX_CONFIGURATION.get('value').includes(checkbox.get('value')))
          return items.concat(funeralExpenseCalculatorConfiguration.getIn([CHECKBOX_CONFIGURATION.get('id'), checkbox.get('id')], List()))
        else
          return items
      }, List())

    funeralChoices.forEach((choice, key) => {
      const choiceItem = dropdownItems.find(item => item.get('value') === choice) || Map()

      requirements = requirements.concat(funeralExpenseCalculatorConfiguration.getIn([key, choiceItem.get('id')], List()))
    })

    return requirements
  }

  const funeralCost = () => funeralRequirements().reduce((sum, requirement) => sum + requirement.get('cost'), 0) + funeralChoices.get('additional-cost', 0)

  // sort the requirements so it shows the most expensive first
  const sortFuneralRequirements = requirements => requirements.sort((previous, next) => next.get('cost') - previous.get('cost'))

  const funeralExpenseChoices = () => {
    const responses = funeralChoices.filter((value, key) => SELECTED_VALUES_TO_ANSWERS.get(value) || key === 'final-ceremony')
    const transformedResponses = funeralChoices.merge(
      responses.mapEntries(([key, value]) => [transformKey(key, data), SELECTED_VALUES_TO_ANSWERS.get(value, value)])
    ).delete('final-ceremony')

    // This is needed so as to capture other important data from calculator when user only interacts with call to action on funeral calculator - Atanda
    if (funeralChoices.has('additional-cost'))
      return transformedResponses.merge(Map({'funeral-estimate': funeralCost()}))
    else
      return transformedResponses.merge(Map({'additional-expenses': checkboxValues(funeralChoices), 'funeral-estimate': funeralCost()}))
  }

  const showFundingOptions = useCallback(() => {
    Logger.log({
      name: 'clicked_gap_filler_lead_assessment',
      payload: {flavor: 'funeral', wildcard: 'funeral'}
    })
    onStepChange(funeralExpenseChoices().set('prepaid-arrangements', false))
  })

  const showExistingPlanOptions = useCallback(() => {
    Logger.log({
      name: 'clicked_gap_filler_vault_flow',
      payload: {flavor: 'funeral', wildcard: 'funeral'}
    })
    onStepChange(funeralExpenseChoices().set('prepaid-arrangements', true))
  })

  useEffect(() => {
    Logger.log({
      name: 'viewed_gap_filler_calculator',
      payload: {flavor: 'funeral', wildcard: 'funeral'}
    })
  }, [])

  return (
    <div className='funeral-expense-calculator-container'>
      <FuneralExpenseHeader />
      <TextGutterLarge className='title-with-icon'>
        <img src={Calculator} alt='calculator' />
        <h2>How Much Will Your Final Celebration Cost?</h2>
      </TextGutterLarge>
      <TextGutterMedium className='container-description'>
        Now that you've put some thought into the type of celebration you want, here's a breakdown of all the costs so you know what you're up against.
        It's not set in stone so feel free to play around with the options. The key is to plan ahead.
      </TextGutterMedium>
      <FuneralExpenseCalculator
        checkboxes={CHECKBOX_CONFIGURATION}
        dropdowns={funeralCategoryDropDown}
        funeralChoices={funeralChoices}
        funeralCost={funeralCost()}
        funeralRequirements={sortFuneralRequirements(funeralRequirements())}
        setFuneralChoices={setFuneralChoices}
        showFundingOptions={showFundingOptions}
        showExistingPlanOptions={showExistingPlanOptions}
      />
      <TextGutterMedium className='footnotes'>
        This calculator is designed to be an informational tool only and the results provided may not cover the entire cost of your funeral at the time of your death.
        This is a listing of U.S. national average prices; prices may vary based on location and specific circumstances.
        This information is supplied from sources we believe to be reliable but we cannot guarantee its accuracy.
      </TextGutterMedium>
      <TextGutterMedium className='footnotes'>
        Sources: 2015 National Funeral Directors Association General Price List Survey;
        Federal Trade Commission Consumer Information;
        Everplans <a href='http://www.everplans.com/articles/how-much-does-it-cost-to-be-buried-in-a-cemetery' target='_blank' rel='noopener noreferrer'>
          [1]
        </a>
        <a href='http://www.everplans.com/articles/how-to-transport-a-body-via-airplane' target='_blank' rel='noopener noreferrer'>[2]</a>
        <a href='https://www.everplans.com/articles/heres-how-you-can-easily-understand-funeral-home-costs' target='_blank' rel='noopener noreferrer'>[3]</a>
      </TextGutterMedium>
    </div>
  )
}

FuneralExpenseCalculatorContainer.propTypes = {
  data: PropTypes.instanceOf(Map),
  onStepChange: PropTypes.func
}

export default FuneralExpenseCalculatorContainer
