import {Component} from 'react'
import {List, Map} from 'immutable'
import PropTypes from 'prop-types'

import {newItemPath, viewItemPath, newCompoundItemPath, categoryPath} from '../../../lib/urlTools'
import {categorySlug} from '../../../lib/plan_data/category'
import {findResourceById} from '../../../lib/plan_data/userData'
import {isCompoundItemResponse} from '../../../lib/plan_data/itemResponsesHelper'
import routerUtils from '../../../lib/routerUtils'

import './doneScreenLinks.scss'


export default class DoneScreenLinks extends Component {
  constructor(props) {
    super(props)

    this.category = findResourceById({resourceList: props.categories, id: props.itemResponse.get('category-id')})
    this.itemPathArguments = this.itemPathArguments(props)

    this.clickAddAnother = this.clickAddAnother.bind(this)
    this.clickView = this.clickView.bind(this)
  }

  clickAddAnother() {
    this.props.logEvent({name: 'clicked_add_another'})

    this.goToPath(isCompoundItemResponse(this.props.itemResponse) ?
      newCompoundItemPath({...this.itemPathArguments, groupId: ''}) : // groupId needs to be empty to route to open a new compound item
      newItemPath(this.itemPathArguments)
    )
  }

  clickView() {
    this.props.logEvent({name: 'viewed_response_group'})
    this.goToPath(viewItemPath(this.itemPathArguments))
  }

  itemPathArguments(props) {
    return {
      elementId: this.props.nextBestAction.getIn(['data', 'item', 'element-id']),
      groupId: props.groupId[0],
      itemSlug: props.itemResponse.get('slug'),
      slug: categorySlug(this.category),
      path: categoryPath(this.category)
    }
  }

  goToPath(path) {
    routerUtils.push(path)
    this.props.toggleModal() // need to close the wizard modal or it stays open in the background
  }

  render() {
    return (
      <div className='done-screen-links'>
        <p className='tight'>
          Your information has been saved in the <strong>{this.category.get('name')}</strong> section
          of your Everplan under "{this.props.itemResponse.get('header')}."
        </p>
        <div className='notification-links flex-container'>
          <a className='notification-link' onClick={this.clickView}>
            View what's been saved »
          </a>
          <a className='notification-link' onClick={this.clickAddAnother}>
            Add another: {this.props.itemResponse.get('header')} »
          </a>
        </div>
      </div>
    )
  }
}

DoneScreenLinks.propTypes = {
  categories: PropTypes.instanceOf(List),
  currentElement: PropTypes.instanceOf(Map),
  groupId: PropTypes.arrayOf(PropTypes.string),
  itemResponse: PropTypes.instanceOf(Map),
  kits: PropTypes.instanceOf(Map),
  logEvent: PropTypes.func,
  nextBestAction: PropTypes.instanceOf(Map),
  toggleModal: PropTypes.func
}
