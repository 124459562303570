import PropTypes from 'prop-types'

import DeleteIcon from '../../../../../../shared_assets/v2/DeleteIcon'
import EditIcon from '../../../../../../shared_assets/v2/EditIcon'

import './contactResponseIcons.scss'

const headerText = '#262626' // Bring in color from Style Guide. --KTW

const ContactResponseIcons = ({showContactModal, clearSelectedContact}) => (
  <div className='contact-response-icons flex-child'>
    <div className='edit-contact-response' onClick={showContactModal}>
      <EditIcon alt='Edit contact response' fill={headerText} height='12' width='12' />
      Edit contact
    </div>
    <div className='delete-contact-response' onClick={clearSelectedContact}>
      <DeleteIcon alt='Delete contact response' fill={headerText} height='12' width='12' />
      Remove
    </div>
  </div>
)

ContactResponseIcons.propTypes = {
  showContactModal: PropTypes.func.isRequired,
  clearSelectedContact: PropTypes.func.isRequired
}

export default ContactResponseIcons
