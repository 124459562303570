import {Map} from 'immutable'
import PropTypes from 'prop-types'

import DeleteIcon from '../../../../../shared_assets/v2/DeleteIcon'
import DeleteResponse from '../shared/DeleteResponse'
import EditIcon from '../../../../../shared_assets/v2/EditIcon'

import routerUtils from '../../../../../lib/routerUtils'
import Logger from '../../../../../lib/NewLogger'

import './itemIcons.scss'

const headerText = '#262626' // Bring in color from Style Guide. --KTW


const ItemIcons = props => {
  const itemName = props.itemResponse.get('name')

  const editPath = () => {
    const path = props.hasMultipleFieldGroups ? props.editCompoundItemPath : props.editItemPath
    return path({groupId: props.secondResponseGroup.get('second-group-id')})
  }

  const openEditModal = () => {
    Logger.log({
      name: 'clicked_edit_response_group',
      payload: {
        context: props.context,
        type: props.eventType,
        item: itemName,
        wildcard: itemName,
        compound_item: props.hasMultipleFieldGroups
      }
    })

    props.setInitialGroupId(props.groupId)

    routerUtils.push(editPath())
  }

  return (
    <div className='item-icons flex-child'>
      {
        !props.hideEditFeaturesForUser &&
          <div className='edit-item-response' onClick={openEditModal}>
            <EditIcon alt={`Edit ${itemName}`} fill={headerText} height='15' width='15' />
          </div>
      }
      <DeleteResponse
        {...props}
        launcher={
          <div className='delete-item-response'>
            <DeleteIcon alt={`Delete ${itemName}`} fill={headerText} height='15' width='15' />
          </div>
        }
      />
    </div>
  )
}

ItemIcons.defaultProps = {groupId: ''}

ItemIcons.propTypes = {
  context: PropTypes.string,
  editCompoundItemPath: PropTypes.func,
  eventType: PropTypes.string,
  editItemPath: PropTypes.func,
  groupId: PropTypes.string,
  hasMultipleFieldGroups: PropTypes.bool,
  secondResponseGroup: PropTypes.instanceOf(Map),
  itemResponse: PropTypes.instanceOf(Map),
  setInitialGroupId: PropTypes.func,
  hideEditFeaturesForUser: PropTypes.bool
}


export default ItemIcons
