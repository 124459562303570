import {Map} from 'immutable'
import PropTypes from 'prop-types'

import {TextGutterMedium} from '../../../components/blocks/Texts'
import {Button} from '../../../components/blocks/Buttons'

import './emptyElement.scss'


const EmptyElement = ({element, expandForm}) => (
  <aside className='empty-element'>
    <TextGutterMedium><strong>{element.get('display-name')}</strong></TextGutterMedium>
    <TextGutterMedium className='element-legend'>{element.get('legend')}</TextGutterMedium>
    <Button onClick={expandForm}>Start</Button>
  </aside>
)

export default EmptyElement

EmptyElement.propTypes = {
  element: PropTypes.instanceOf(Map),
  expandForm: PropTypes.func
}
