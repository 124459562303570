
import {Box} from '../../blocks/Boxes'

const EmptyAddressBook = () => (
  <Box className='empty-address-book'>
    <div>There are no contacts in this Everplan.</div>
  </Box>
)

export default EmptyAddressBook
