import {Component} from 'react'
import PropTypes from 'prop-types'

import {Text} from '../blocks/Texts'
import WarningIconCircle from '../../../assets/WarningIconCircle'
import BannerLink from './BannerLink'

import Logger from '../../../../lib/NewLogger'

class UnsupportedBrowserBanner extends Component {
  componentDidMount() {
    Logger.log({
      name: 'viewed_unsupported_browser_banner'
    })
  }

  render() {
    return (
      <div className='feedback-banner flex-container'>
        <WarningIconCircle className='warning' alt='warning' />
        <Text className='message'>
          Your browser is not supported. For the best experience, please use the latest version of&nbsp;
          <BannerLink href='https://www.google.com/chrome/' onClick={this.props.onClick} browserName='chrome'>Chrome</BannerLink>&nbsp;or&nbsp;
          <BannerLink href='https://support.apple.com/downloads/safari' onClick={this.props.onClick} browserName='safari'>Safari.</BannerLink>
        </Text>
      </div>
    )
  }
}

UnsupportedBrowserBanner.propTypes = {
  onClick: PropTypes.func
}

export default UnsupportedBrowserBanner
