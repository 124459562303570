import classnames from 'classnames'
import {useEffect, useMemo, useState} from 'react'
import {Map} from 'immutable'
import PropTypes from 'prop-types'

import Closer from '../../core/closer/Closer'
import Modals from '../../core/modals/Modals'
import GapFillerCallToAction from './GapFillerCallToAction'
import GapFillerGuideModalBody from './GapFillerGuideModalBody'
import GapFillerAssessmentContainer from './gap_filler_assessments/GapFillerAssessmentContainer'

import Logger from '../../../lib/NewLogger'
import {useScoreLedgerEventLogger} from '../../../lib/ScoreLedgerEventLogger'

import GAP_FILLER_GUIDE_MODAL_CONFIGURATIONS, {GAP_FILLER_ITEM_SLUGS_WITH_ASSESSMENT} from './configurations/gapFillerGuideModalConfigurations'

import './nextBestActionGapFillerGuide.scss'


const NextBestActionGapFillerGuide = props => {
  const {logWithNotification} = useScoreLedgerEventLogger(props.userConfig)
  const [showInterceptModal, setShowInterceptModal] = useState(false)

  const nextBestActionItemSlug = props.nextBestAction.getIn(['data', 'item', 'item-slug'])
  const showGapFillerAssessmentModal = useMemo(() => GAP_FILLER_ITEM_SLUGS_WITH_ASSESSMENT.includes(nextBestActionItemSlug), [nextBestActionItemSlug])
  const guideModalConfiguration = useMemo(() => GAP_FILLER_GUIDE_MODAL_CONFIGURATIONS.get(nextBestActionItemSlug), [nextBestActionItemSlug])

  useEffect(() => {
    // Gapfiller assessments have different score ledger events and this is not one of them
    const timer = setTimeout(() =>
      !showGapFillerAssessmentModal && logWithNotification.readActionItemGuide({item: props.itemResponse.get('header')}),
    16000)

    Logger.log({
      name: 'viewed_gap_filler_guide',
      payload: {
        context: props.context,
        item: props.itemResponse.get('name'),
        wildcard: props.itemResponse.get('name')
      }
    })

    return () => {
      clearTimeout(timer)
    }
  }, [])

  const toggleInterceptModal = () => { setShowInterceptModal(prevShowInterceptModal => !prevShowInterceptModal) }

  const closer = () => <Closer onClick={props.toggleModal} />

  if (showGapFillerAssessmentModal) {
    return (
      <GapFillerAssessmentContainer
        toggleGapFillerAssessmentModal={props.toggleModal}
        itemSlug={props.nextBestAction.getIn(['data', 'item', 'item-slug'])}
      />
    )
  } else {
    return (
      <Modals.FullHeightModalLarge
        className={classnames('next-best-action-gap-filler-guide', {
          'intercept-modal-open': showInterceptModal
        })}
        closerComponent={closer}
        showModal>
        <header className='flex-container next-best-action-gap-filler-guide-header'>
          <div className='flex-child left-column'>
            <h1>{guideModalConfiguration.get('title')}</h1>
            <img
              alt={guideModalConfiguration.get('title')}
              src={guideModalConfiguration.get('imageUrl')}
            />
            <p className='description'>
              {guideModalConfiguration.get('description')}
            </p>
          </div>
          <GapFillerCallToAction
            {...props}
            itemResponse={props.itemResponse}
            showInterceptModal={showInterceptModal}
            toggleInterceptModal={toggleInterceptModal}
            toggleGapFillerAssessmentModal={props.toggleModal}
          />
        </header>
        <GapFillerGuideModalBody
          {...props}
          itemResponse={props.itemResponse}
          sections={guideModalConfiguration.get('sections')}
        />
      </Modals.FullHeightModalLarge>
    )
  }
}

NextBestActionGapFillerGuide.propTypes = {
  context: PropTypes.string,
  itemResponse: PropTypes.instanceOf(Map),
  nextBestAction: PropTypes.instanceOf(Map),
  toggleModal: PropTypes.func,
  userConfig: PropTypes.instanceOf(Map)
}

export default NextBestActionGapFillerGuide
