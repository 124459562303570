import classnames from 'classnames'
import PropTypes from 'prop-types'

import addressBookIcon from '../../../assets/people-icon.svg'

import './addressBookLink.scss'

const AddressBookLink = ({className, onClick}) => (
  <a className={classnames('address-book-link', className)} onClick={onClick}>
    <div className='action-link-icon-wrapper'>
      <img
        alt='address book'
        className='action-link-icon'
        src={addressBookIcon}
      />
    </div>
    <span> Address Book</span>
  </a>
)

AddressBookLink.propTypes = {
  onClick: PropTypes.func
}

export default AddressBookLink
