import {Component} from 'react'
import PropTypes from 'prop-types'

import WizardActions from '../../../actions/WizardActions'
import PhoneEditForm from '../shared/PhoneEditForm'
import Button from '../../../../../shared_components/Button'

import './secondaryPhoneForm.scss'


export default class SecondaryPhoneForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      error: '',
      loading: false,
      isValid: false,
      mode: 'text',
      nationalNumber: '',
      value: ''
    }
    this.clearErrors = this.clearErrors.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.setFormValue = this.setFormValue.bind(this)
    this.setMode = this.setMode.bind(this)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.error)
      this.setState({loading: false, error: nextProps.error})
  }

  setFormValue(isValid, nationalNumber, value) {
    this.setState({isValid, nationalNumber, value})
  }

  setMode(mode) { this.setState({mode}) }

  skipThisStep(event) {
    event.preventDefault()
    WizardActions.getBackupCode()
  }

  handleSubmit(event) {
    if (event) event.preventDefault()
    if (this.state.isValid) {
      this.setState({loading: true})
      WizardActions.setSecondaryPhone({
        number: this.state.value,
        nationalNumber: this.state.nationalNumber,
        preferredMessageMode: this.state.mode
      })
    } else {
      this.setState({error: 'Please enter a valid number.'})
    }
  }

  clearErrors() {
    this.setState({error: null})
  }

  render() {
    return (
      <div>
        <h3>Secondary Phone Number</h3>
        <hr />
        <div className='wizard-info'>
          In the event that your primary phone number is not available to receive codes, please provide a secondary phone number.
        </div>
        <div className='wizard-body' onFocus={this.clearErrors}>
          <form onSubmit={this.handleSubmit}>
            <PhoneEditForm
              error={this.state.error}
              mode='text'
              setFormValue={this.setFormValue}
              setMode={this.setMode}
            />
          </form>
        </div>
        <div className='secondary-phone-bottom-nav'>
          <Button className='wizard-primary-btn' onClick={this.handleSubmit} loading={this.state.loading}>Continue</Button>
          <a href='#' className='skip-this-step' onClick={this.skipThisStep}>Skip this step</a>
        </div>
      </div>
    )
  }
}

SecondaryPhoneForm.propTypes = {
  closer: PropTypes.func,
  error: PropTypes.string
}
