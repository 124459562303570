import {Component} from 'react'
import PropTypes from 'prop-types'

import BackupCode from './BackupCode'
import {Cancel, Continue} from '../../../../../shared_components/ButtonTypes'
import ConfirmCodeForm from './ConfirmCodeForm'
import Enabled from './Enabled'
import PrimaryPhoneForm from './PrimaryPhoneForm'
import SecondaryPhoneForm from './SecondaryPhoneForm'

import TfaConfigStore from '../../../stores/TfaConfigStore'
import TfaWizardStore from '../../../stores/TfaWizardStore'
import WizardActions from '../../../actions/WizardActions'

import './tfaWizardModal.scss'


class TfaWizard extends Component {
  constructor() {
    super()

    this.state = {
      backupCode: TfaConfigStore.getState().backupCode,
      confirmCancel: false,
      error: TfaConfigStore.getState().error,
      pendingNumber: TfaConfigStore.getState().pendingTfaPhoneNumber,
      topLevel: this.queryView()
    }

    this.onUpdate = this.onUpdate.bind(this)
    this.handleConfirmCodeSubmit = this.handleConfirmCodeSubmit.bind(this)
    this.restartPrimaryPhoneAdd = this.restartPrimaryPhoneAdd.bind(this)
  }

  componentDidMount() {
    TfaWizardStore.initialize()
    TfaWizardStore.addChangeListener(this.onUpdate)
    TfaConfigStore.addChangeListener(this.onUpdate)
  }

  componentWillUnmount() {
    TfaWizardStore.removeChangeListener(this.onUpdate)
    TfaConfigStore.removeChangeListener(this.onUpdate)
  }

  onUpdate() {
    if (this.queryView() === 'DONE') {
      this.props.exitTfa()
    } else {
      const pendingNumber = TfaConfigStore.getState().pendingTfaPhoneNumber

      this.setState({
        topLevel: this.queryView(),
        confirmCancel: TfaWizardStore.getState().confirmCancel,
        error: TfaConfigStore.getState().error,
        backupCode: TfaConfigStore.getState().backupCode
      })

      if (pendingNumber) this.setState({pendingNumber})
    }
  }

  queryView() { return TfaWizardStore.getTopLevelView() }

  restartPrimaryPhoneAdd() { WizardActions.resendCode() }

  handleConfirmCodeSubmit() { WizardActions.confirmPhone(this.state.pendingNumber.id, this.refs.confirmCode.getEnteredCode()) }

  topLevelComponent() {
    switch (this.state.topLevel) {
      case 'START':
        return <PrimaryPhoneForm {...this.props} error={this.state.error} tfaConfig={this.props.tfaConfig} />
      case 'CONFIRM':
        return <ConfirmCodeForm {...this.props} error={this.state.error} phoneNumber={this.state.pendingNumber} restartFunction={this.restartPrimaryPhoneAdd} ref='confirmCode' handleSubmit={this.handleConfirmCodeSubmit} phoneVerified={TfaWizardStore.getState().phoneVerified} />
      case 'SECONDARY':
        return <SecondaryPhoneForm {...this.props} tfaConfig={this.props.tfaConfig} error={this.state.error} />
      case 'BACKUP':
        return <BackupCode {...this.props} backupCode={this.state.backupCode} />
      case 'ENABLED':
        return <Enabled {...this.props} />
      default:
        return <PrimaryPhoneForm {...this.props} />
    }
  }

  confirmCancel() {
    return (
      <div>
        <strong>Canceling setup of Two Factor Authentication.</strong>
        <p>If you exit the process now, any confirmed phone settings will need to be re-entered and re-confirmed</p>
        <Continue onClick={WizardActions.continueSetup}>Return to setup</Continue> <Cancel onClick={WizardActions.disableTfa}>Exit setup</Cancel>
      </div>
    )
  }

  render() {
    if (this.state.confirmCancel)
      return this.confirmCancel()
    else
      return this.topLevelComponent()
  }
}

TfaWizard.propTypes = {
  exitTfa: PropTypes.func,
  tfaConfig: PropTypes.object
}

export default TfaWizard
