import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'

import AccountSettingsActions from '../../actions/AccountSettingsActions'
import AddEmail from './AddEmail'
import currentUserWrapper from '../../../../shared_components/currentUserWrapper'
import {If} from '../../../../shared_components/deprecated/IfFlag'
import ChangePassword from './ChangePassword'
import NotificationBase from '../../../../shared_components/deprecated/NotificationBase'
import UpdateAccountInformation from './UpdateAccountInformation'

import Container from '../../../../lib/Container'
import StorePrototype from '../../../../shared_components/StorePrototype'
import {redirectCorpMaxAccountInformation} from '../../../../lib/urlTools'
import {capitalize} from '../../../../lib/tools'

import './accountInformation.scss'


export class AccountInformation extends React.Component {
  constructor(props) {
    super(props)
    redirectCorpMaxAccountInformation(props.currentUser)

    this.store = Container.getStore('email_updates')
    this.pronouns = this.pronouns.bind(this)
    this.onUpdate = this.onUpdate.bind(this)
  }

  componentDidMount() { this.store.addChangeListener(this.onUpdate) }
  onUpdate() {
    if (this.store.getState().data.message)
      AccountSettingsActions.fetchAccountInformation(this.props.params.userId)
  }
  componentWillUnmount() { this.store.removeChangeListener(this.onUpdate) }

  pronouns() {
    const pronoun = this.props.profile.pronoun

    if (pronoun)
      return `${capitalize(pronoun.subject)}/${capitalize(pronoun.object)}`

    else
      return ''
  }

  location(profile) {
    let location = ''
    if (profile.state && profile.zip)
      location = `${profile.state} (${profile.zip})`
    else if (profile.state || profile.zip)
      location = profile.state || profile.zip
    else if (profile.country)
      location = profile.country
    return location
  }

  render() {
    const profile = this.props.profile
    return (
      <div className={classnames({'legacy-account-settings': this.props.legacyAccountSettings, 'account-information': !this.props.legacyAccountSettings})}>
        <AccountSettingsNotifications />
        <ProfileInformationUpdatedNotification />
        <h1 className='main-header'>Account Information</h1>
        <h3 className='sub-header'>My Profile</h3>
        <div className='column-wrapper profile'>
          <span className='row-wrapper-sb'>
            <span className='account-info-row'>
              <p className='account-info-row-title'>Name:</p>
              <p>{profile.first_name} {profile.last_name}&nbsp;</p>
            </span>
            <UpdateAccountInformation params={this.props.params} {...profile} />
          </span>
          <If flag={profile.show_profile}>
            <span className='account-info-row'>
              <p className='account-info-row-title'>Date of Birth:</p>
              <p>{profile.dob}&nbsp;</p>
            </span>
            <span className='account-info-row'>
              <p className='account-info-row-title'>Location:</p>
              <p>{this.location(profile)}&nbsp;</p>
            </span>
            <span className='account-info-row'>
              <p className='account-info-row-title'>Pronouns:</p>
              <p>{this.pronouns()}&nbsp;</p>
            </span>
          </If>
        </div>

        <h3 className='sub-header'>Email Addresses</h3>
        <div className='row-wrapper email-addresses'>
          <p className='email-title row-item'>Email Addresses:</p>
          <div className='row-item'>
            <p>You can log in with any of the email addresses listed below.<br />Primary email is where we will send notifications and updates.</p>
            <EmailAccounts emails={this.props.emails} userId={this.props.params.userId} />
            <AddEmail location={this.props.location} userId={this.props.params.userId} />
          </div>
        </div>

        <h3 className='sub-header'>Password</h3>
        <div className='column-wrapper password'>
          <span className='row-wrapper-sb'>
            <span className='account-info-row'>
              <p className='account-info-row-title'>Password:</p>
              <p>*************</p>
            </span>
            <ChangePassword userId={this.props.params.userId} />
          </span>
        </div>
      </div>
    )
  }
}

AccountInformation.propTypes = {
  currentUser: PropTypes.object,
  emails: PropTypes.object,
  legacyAccountSettings: PropTypes.object,
  location: PropTypes.object,
  params: PropTypes.shape({userId: PropTypes.string}),
  profile: PropTypes.shape({
    dob: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    pronoun: PropTypes.shape({
      subject: PropTypes.string,
      object: PropTypes.string
    }),
    show_profile: PropTypes.bool
  })
}

export default currentUserWrapper(AccountInformation)


const EmailAccounts = props => (
  <div className='address-group'>
    <div className='address-item row-wrapper'>
      <p className='email-address row-item'>{props.emails.primary.email_address}</p>
      <p className='primary row-item'>(Primary)</p>
    </div>
    {props.emails.others.map(email => (
      <div className='address-item row-wrapper' key={email.email_address}>
        <p className='email-address row-item'>{email.email_address}</p>
        <div className='email-options row-item'>
          {
            (email.status === 'pending') ?
              <ResendVerification emailId={email.id} /> :
              <MakePrimary emailId={email.id} />
          }
          <RemoveEmail emailId={email.id} />
        </div>
      </div>
    ))}
  </div>
)

EmailAccounts.propTypes = {emails: PropTypes.object}

class RemoveEmail extends React.Component {
  removeEmail() { AccountSettingsActions.removeEmail(this.props.emailId) }

  render() { return <a onClick={this.removeEmail.bind(this)}>Remove</a> }
}

RemoveEmail.propTypes = {emailId: PropTypes.string}

class ResendVerification extends React.Component {
  resendVerification() { AccountSettingsActions.resendVerification(this.props.emailId) }

  render() { return <a className='make-primary' onClick={this.resendVerification.bind(this)}>Resend verification</a> }
}

ResendVerification.propTypes = {emailId: PropTypes.string}

class MakePrimary extends React.Component {
  setPrimaryEmail() { AccountSettingsActions.setPrimaryEmail(this.props.emailId) }

  render() { return <a className='make-primary' onClick={this.setPrimaryEmail.bind(this)}>Make primary</a> }
}

MakePrimary.propTypes = {emailId: PropTypes.string}

const actions = [
  AccountSettingsActions.Types.DID_ADD_EMAIL,
  AccountSettingsActions.Types.DID_CHANGE_PASSWORD,
  AccountSettingsActions.Types.DID_DELETE_EMAIL,
  AccountSettingsActions.Types.DID_RESEND_VERIFICATION,
  AccountSettingsActions.Types.DID_UPDATE_PRIMARY_EMAIL
]


Container.registerStore('email_updates', StorePrototype(actions))

class AccountSettingsNotifications extends NotificationBase {
  constructor(props) {
    super(props)
  }
  componentDidMount() { this.bindMessageStore(actions) }
}

class ProfileInformationUpdatedNotification extends NotificationBase {
  constructor(props) {
    super(props)
  }
  componentDidMount() { this.bindMessageStore(AccountSettingsActions.Types.DID_UPDATE_PROFILE) }
  message() { return 'Your profile information has been updated.' }
}
