import PropTypes from 'prop-types'

import {styled} from '@mui/material'

import CloseIcon from '../../../core/svg_icons/icons/CloseIcon'
import StyledBaseModal from '../../modals/StyledBaseModal'
import StyledSmallBox from '../../boxes/StyledSmallBox'

const StyledSmallModalContent = styled(StyledSmallBox)(
  () => `
    margin: 134px auto 0;
  `
)

const StyledSmallModal = ({children, onClose, ...otherProps}) => (
  <StyledBaseModal {...otherProps} dataTestId='small-modal' onClose={onClose}>
    <StyledSmallModalContent className='small-modal-content'>
      <CloseIcon
        data-testid='close-icon'
        height='12'
        onClick={onClose}
        style={{alignSelf: 'flex-end', cursor: 'pointer', zIndex: '10'}}
        width='12'
      />
      {children}
    </StyledSmallModalContent>
  </StyledBaseModal>
)

StyledSmallModal.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired
}

export default StyledSmallModal
