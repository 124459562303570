import PropTypes from 'prop-types'
import {Map} from 'immutable'

import FuneralExpenseAssessmentScreensContainer from './FuneralExpenseAssessmentScreensContainer'

import {FINAL_WISHES_OUTRO} from '../configurations/gapFillerAssessmentOutroConfiguration'


const FuneralExpenseAssessmentOutroContainer = props => (
  <FuneralExpenseAssessmentScreensContainer
    {...props}
    initialScreenKey={
      props.assessmentData.get('prepaid-arrangements') ?
        'prepaid-arrangements' :
        'planning'
    }
    screenDictionary={FINAL_WISHES_OUTRO}
  />
)

FuneralExpenseAssessmentOutroContainer.propTypes = {
  assessmentData: PropTypes.instanceOf(Map)
}

export default FuneralExpenseAssessmentOutroContainer
