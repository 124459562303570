import classnames from 'classnames'

import './circleIconWrapper.scss'

const CircleIconWrapper = props => (
  <div className={classnames(props.className, 'circle-icon-wrapper')}>
    {props.children}
  </div>
)

export default CircleIconWrapper
