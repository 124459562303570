/** @module slackTools
 *
 * @desc
 * Helpers related to the sending of Slack messages via an API endpoint
 * in the Rails app.
 */

import SlackActions from '../shared_actions/SlackActions'

/**
 * Send a notification to Slack when user chooses to allow us to contact them
 * for additional feedback via one of our feedback modals.
 *
 * @param {string} feedbackType - The type of feedback modal via which the user opted-in.
 * @param {number} userId - The id of the `User` that has opted in with us.
 */
export const slackOnFeedbackOptIn = (feedbackType, userId) => SlackActions.sendSlackMessage({
  channel: '#customer_optin',
  message: `@channel User ${userId} just opted in to speak with us via the ${feedbackType} feedback modal.`,
  title: 'Customer Opt In Notification',
  username: 'customer_optin_bot'
})
