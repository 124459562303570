import PropTypes from 'prop-types'

import {Button, Cancel} from '../../blocks/Buttons'
import ButtonGroup from '../../../../../shared_components/core/buttons/ButtonGroup'

const NoPermissionsLinkAndButtons = props => (
  <div className='no-permissions-link-and-buttons'>
    <a className='add-deputy-link' onClick={props.addNewDeputyLink}>Add a new deputy</a>
    <ButtonGroup>
      <Cancel onClick={props.toggleModal}>Cancel</Cancel>
      <Button disabled>Share</Button>
    </ButtonGroup>
  </div>
)

NoPermissionsLinkAndButtons.propTypes = {
  addNewDeputyLink: PropTypes.func,
  toggleModal: PropTypes.func
}

export default NoPermissionsLinkAndButtons
