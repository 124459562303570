import PropTypes from 'prop-types'
import {Map} from 'immutable'

import {addPossessive} from '../../../../../lib/tools'
import {humanizedFullName} from '../../../../../lib/nameTools'
import {hasDeferredItems, remainingHouseholdMember} from '../../../../../lib/corpMaxDeputyTools'

import {TextGutterMedium} from '../../blocks/Texts'


const HouseholdStepTwoInstructions = props => {
  const otherHouseholdMember = remainingHouseholdMember({ownerships: props.ownerships, ownerToMarkDeceased: props.ownerToMarkDeceased})

  if (props.userEverplan.get('is-household') && !otherHouseholdMember.get('deceased')) {
    const reportedOwnerNameWithPossessive = addPossessive(props.planholderFirstName)
    return (
      <div className='step-two'>
        <TextGutterMedium>We will disable any future logins from {reportedOwnerNameWithPossessive} account to maintain the security of the information.
        </TextGutterMedium>
        {hasDeferredItems(props.deputyOwnership.get('item-permissions')) && <TextGutterMedium>
          {reportedOwnerNameWithPossessive} "After Death" sections will not be unlocked. If you need to access any of {reportedOwnerNameWithPossessive} items, please reach out to {humanizedFullName(otherHouseholdMember)}.
        </TextGutterMedium>}
      </div>
    )
  } else {
    return null
  }
}

export default HouseholdStepTwoInstructions

HouseholdStepTwoInstructions.propTypes = {
  planholderFirstName: PropTypes.string,
  userEverplan: PropTypes.instanceOf(Map),
  ownerToMarkDeceased: PropTypes.instanceOf(Map)
}
