import {List} from 'immutable'
import PropTypes from 'prop-types'

import Category from './Category'

const Categories = props => {
  if (props.categoriesWithItemResponses.isEmpty()) {
    return null
  } else {
    return (
      <div className='categories flex-child'>
        {
          props.categoriesWithItemResponses.map(category => (
            <Category
              {...props}
              category={category}
              key={category.get('name') + category.getIn(['items', 0, 'id'])}
            />
          ))
        }
      </div>
    )
  }
}

Categories.propTypes = {
  responses: PropTypes.instanceOf(List),
  categoriesWithItemResponses: PropTypes.instanceOf(List)
}


export default Categories

