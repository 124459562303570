import {List, Map} from 'immutable'
import PropTypes from 'prop-types'

import GapFillerGuideModalResourceLink from './GapFillerGuideModalResourceLink'
import UnorderedList from '../../UnorderedList'


const GapFillerGuideResources = props => {
  if (props.resources && !props.resources.isEmpty()) {
    return (
      <div className='gap-filler-guide-resources'>
        <UnorderedList
          children={props.resources.map(resource => (
            <GapFillerGuideModalResourceLink
              {...props}
              key={resource.url}
              resource={resource}
            />
          ))}
        />
      </div>
    )
  } else {
    return null
  }
}

GapFillerGuideResources.propTypes = {
  resources: PropTypes.instanceOf(List),
  context: PropTypes.string,
  item: PropTypes.instanceOf(Map)
}

export default GapFillerGuideResources
