import {Component} from 'react'
import {List, Map} from 'immutable'
import {PropTypes} from 'prop-types'

import Element from '../../../item/Element'

export default class ViewElement extends Component {
  constructor() {
    super()

    this.onElementSelect = this.onElementSelect.bind(this)
  }

  onElementSelect() {
    this.props.onElementSelect(this.props.element)
  }

  render() {
    return (
      <div className='view-element'>
        <a onClick={this.onElementSelect}>{this.props.selectElementText}</a>
        <Element
          {...this.props}
          data={List()}
        />
      </div>
    )
  }
}

ViewElement.propTypes = {
  element: PropTypes.instanceOf(Map),
  onElementSelect: PropTypes.func,
  selectElementText: PropTypes.string
}

