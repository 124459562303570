import {useState, useCallback, useEffect} from 'react'

import Button from '../../../../../shared_components/Button'
import CreateNewBackupCode from './CreateNewBackupCode'
import Modal from '../../../../../shared_components/Modal'

import TfaConfigStore from '../../../stores/TfaConfigStore'

import '../tfaSetup.scss'

const TfaBackup = () => {
  const [showModal, setShowModal] = useState(false)
  const [backupCode, setBackupCode] = useState(TfaConfigStore.getState().backupCode)

  const displayModal = useCallback(() => setShowModal(true))

  const hideModal = useCallback(event => {
    if (event)
      event.preventDefault()

    setShowModal(false)
  })

  const onUpdate = () => setBackupCode(TfaConfigStore.getState().backupCode)

  useEffect(() => {
    TfaConfigStore.addChangeListener(onUpdate)

    return () => {
      TfaConfigStore.removeChangeListener(onUpdate)
    }
  }, [])

  return (
    <div>
      <div className='status-text'>To be used when your primary and secondary phone numbers are not available.</div>
      <Button className='control-btn' onClick={displayModal}>Get new backup code</Button>
      <Modal visible={showModal} closer={hideModal}>
        <div className='auth-content'>
          <CreateNewBackupCode backupCode={backupCode} closer={hideModal} />
        </div>
      </Modal>
    </div>
  )
}

export default TfaBackup
