import {Map} from 'immutable'
import {useMutation} from '@apollo/client'
import {useState} from 'react'
import PropTypes from 'prop-types'

import {Box} from '@mui/material'

import Button from '../../../../../shared_components/mui_base_components/button/Button'
import InviteAcceptNotification from './InviteAcceptNotification'

import {RESPONSE_TO_DEPUTY_INVITE} from '../../../../../graphql/mutations/deputy'
import {useNotificationContext} from '../../../../../shared_components/notifications/NotificationContext'
import MixpanelLogger from '../../../../../lib/MixpanelLogger'

import './pendingDeputyFor.scss'

const PendingDeputyFor = props => {
  const [processingAccept, setProcessingAccept] = useState(false)
  const [processingDecline, setProcessingDecline] = useState(false)
  const {alwaysNotify, dismissToastNotification} = useNotificationContext()
  const [responseToDeputyInvite] = useMutation(RESPONSE_TO_DEPUTY_INVITE)

  const accept = () => {
    if (processingAccept || processingDecline)
      return

    setProcessingAccept(true)
    MixpanelLogger.track('click_deputy_for_accept')

    responseToDeputyInvite({
      variables: {
        input: {
          id: props.deputyOwnership.get('ownable-id'),
          accept: true
        }
      },
      onCompleted: () => {
        props.refetchDeputizers().then(() => {
          alwaysNotify.shortSuccess(
            <InviteAcceptNotification {...props} dismissNotification={dismissToastNotification} />
          )
        })
      }
    })
  }

  const decline = () => {
    if (processingAccept || processingDecline)
      return

    setProcessingDecline(true)
    MixpanelLogger.track('click_deputy_for_decline')

    responseToDeputyInvite({
      variables: {
        input: {
          id: props.deputyOwnership.get('ownable-id'),
          decline: true
        }
      },
      onCompleted: () => {
        props.refetchDeputizers()
      }
    })
  }

  return (
    <Box className='pending-deputy-for'>
      <div className='everplan-owner-profile'>
        <div className='name-email-container'>
          <h3 className='name'>{props.deputyOwnership.get('everplan-name')}</h3>
          {
            props.deputyOwnership.getIn(['everplan-owner', 'owners']).map(owner => (
              <p
                key={owner.get('id')}
                className='email'>
                {owner.get('email')}
              </p>
            ))
          }
        </div>
        <div className='buttons-container'>
          <Button
            color='cancel'
            loading={processingDecline}
            onClick={decline}>
            Decline
          </Button>
          <Button loading={processingAccept} onClick={accept}>Accept</Button>
        </div>
      </div>
    </Box>
  )
}

PendingDeputyFor.propTypes = {
  deputyOwnership: PropTypes.instanceOf(Map),
  refetchDeputizers: PropTypes.func
}

export default PendingDeputyFor
