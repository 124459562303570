import {fromJS, List} from 'immutable'
import CheckboxList from '../../../../shared_components/forms/v2/fields/CheckboxList'
import PropTypes from 'prop-types'

import {TextGutterSmall} from '../blocks/Texts'

const NewsLetterSubscription = props => {
  const items = [{
    value: 'newsletter-subscription',
    label: 'Everplans Monthly Newsletter'
  }]
  return (
    <div className='newsletter-subscription'>
      <CheckboxList
        data={fromJS({
          id: 'newsletter-subscription-status',
          items,
          name: 'newsletter-subscription-status',
          value: [props.subscribedToNewsletter ? 'newsletter-subscription' : '']
        })}
      />
      <TextGutterSmall className='helper-text'>
        Tips, tricks, guides and feature articles about planning and organizing.
      </TextGutterSmall>
    </div>
  )
}

NewsLetterSubscription.propTypes = {
  subscribedToNewsletter: PropTypes.bool
}

export default NewsLetterSubscription
