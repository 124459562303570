import {Map} from 'immutable'
import PropTypes from 'prop-types'

import {Text} from '../../blocks/Texts'

const ViewAddress = ({address}) => (
  <div className='view-address'>
    <Text>
      {address.get('address-line-1')}
    </Text>
    <Text>
      {address.get('address-line-2')}
    </Text>
    <Text>
      {address.get('city')} {address.get('region')}
    </Text>
    <Text>
      {address.get('postal-code')}
    </Text>
    <Text>
      {address.get('country')}
    </Text>
  </div>
)

ViewAddress.propTypes = {
  address: PropTypes.instanceOf(Map)
}

export default ViewAddress
