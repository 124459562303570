import {Map} from 'immutable'
import {useMutation} from '@apollo/client'
import {useState} from 'react'
import PropTypes from 'prop-types'

import {CONTACTS} from '../../../../../graphql/queries/userData'
import {DELETE_CONTACT} from '../../../../../graphql/mutations/userData'
import {useNotificationContext} from '../../../../../shared_components/notifications/NotificationContext'
import ConfirmationModal from '../../../../../shared_components/mui_base_components/modals/confirmation_modal/ConfirmationModal'
import Logger from '../../../../../lib/NewLogger'

const DeleteContactConfirmation = props => {
  const [processing, setProcessing] = useState(false)
  const {alwaysNotify} = useNotificationContext()

  const [deleteContact] = useMutation(DELETE_CONTACT, {
    update: cache => {
      const everplanId = props.userConfig.get('everplan-id')
      const {contacts} = cache.readQuery({query: CONTACTS, variables: {everplanId}})

      const updatedContacts = contacts.filter(contactData => contactData.id !== props.contactId)

      cache.writeQuery({
        query: CONTACTS,
        data: {contacts: updatedContacts},
        variables: {everplanId}
      })
    }
  })

  const removeContact = () => {
    setProcessing(true)

    Logger.log({
      name: 'user_deleted_contact',
      payload: {
        context: 'address_book'
      }
    })

    deleteContact({
      variables: {id: props.contactId},
      onCompleted: () => {
        alwaysNotify.shortSuccess(`${props.contactLabel} has been deleted.`)
      }
    })
  }

  return (
    <ConfirmationModal
      confirmButtonText='Remove'
      headingText={`Remove ${props.contactLabel} as your contact?`}
      loading={processing}
      onClose={props.hideModal}
      onConfirmation={removeContact}
      open={true}
    />
  )
}

DeleteContactConfirmation.propTypes = {
  contactId: PropTypes.string,
  contactLabel: PropTypes.string,
  deleteResource: PropTypes.func,
  hideModal: PropTypes.func,
  userConfig: PropTypes.instanceOf(Map)
}

export default DeleteContactConfirmation
