import PropTypes from 'prop-types'
import {Component} from 'react'
import {connect} from 'react-redux'
import {Map} from 'immutable'
import {readEndpoint, updateResource} from 'redux-json-api'

import KeyFieldsTable from './KeyFieldsTable'
import {Accept} from '../../../blocks/Buttons'

import {cleanResponse} from '../apiHelper'
import {currentUserConfig} from '../../../../../../lib/userTools'
import {redirectToDashboardFromTools} from '../../../../../../lib/urlTools'
import {apiV2MetaDataCall, environmentMetadataVersion} from '../../../../../../lib/apiV2Tools'

export class KeyFieldsToolController extends Component {
  constructor(props) {
    super(props)

    redirectToDashboardFromTools(props)

    this.saveData = this.saveData.bind(this)
  }

  componentDidMount() {
    this.props.readEndpoint('settings')
    this.props.readEndpoint('metadata/versions')
  }

  saveData() {
    return apiV2MetaDataCall({
      apiCall: this.props.updateResource,
      resource: {
        type: 'key-field-mappings',
        id: this.props.keyFieldMappings.get('id'),
        attributes: {
          data: this.props.keyFieldsToolData,
          'version-id': this.props.keyFieldMappings.get('version-id')
        }
      }
    })
  }

  render() {
    if (this.props.environmentVersion.isEmpty()) {
      return null
    } else {
      return (
        <div>
          <h2>Metadata Version {this.props.environmentVersion.get('name')}</h2>
          <Accept onClick={this.saveData} processing={this.props.isUpdating}>Save data</Accept>
          <KeyFieldsTable {...this.props} keyFieldMappings={this.props.keyFieldMappings.get('data')} />
        </div>
      )
    }
  }
}

const mapStateToProps = state => ({
  corpMaxData: {
    categories: state.categories,
    items: state.items,
    kits: state.kits
  },
  environmentVersion: environmentMetadataVersion({versions: state.api.versions, settings: state.api.settings}),
  keyFieldMappings: cleanResponse(state.api['key-field-mappings']).first(),
  keyFieldsToolData: state.keyFieldsToolData,
  isUpdating: state.api.isUpdating === 1,
  userConfig: currentUserConfig(state.api)
})

KeyFieldsToolController.propTypes = {
  environmentVersion: PropTypes.instanceOf(Map),
  isUpdating: PropTypes.bool,
  keyFieldMappings: PropTypes.instanceOf(Map),
  keyFieldsToolData: PropTypes.instanceOf(Map),
  readEndpoint: PropTypes.func.isRequired,
  updateResource: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {readEndpoint, updateResource})(KeyFieldsToolController)
