import PropTypes from 'prop-types'
import {Map} from 'immutable'

import AccountSettingsRow from './AccountSettingsRow'


const SubscriptionInformation = ({accountSetting}) => {
  if (accountSetting.get('subscription-text')) {
    const url = () => {
      if (accountSetting.get('show-subscription-button'))
        return '/cm/account-settings/payment-form'
      else if (accountSetting.get('subscription-payment-gateway') === 'stripe' && accountSetting.get('is-consumer-with-purchased-subscription'))
        return 'cm/account-settings/subscription-and-billing'
    }

    const buttonText = accountSetting.get('show-subscription-button') ? 'Upgrade to Premium' : 'Manage subscription'

    return (
      <AccountSettingsRow
        title='Subscription and Billing:'
        bodyText={accountSetting.get('subscription-text')}
        url={url()}
        buttonText={buttonText}
      />
    )
  } else {
    return null
  }
}

SubscriptionInformation.propTypes = {
  accountSetting: PropTypes.instanceOf(Map)
}

export default SubscriptionInformation
