import {connect} from 'react-redux'
import {Map, List} from 'immutable'
import PropTypes from 'prop-types'
import {deleteResource, readEndpoint} from 'redux-json-api'
import {useState, useEffect} from 'react'

import AccountSettingsRow from './AccountSettingsRow'
import ProfessionalPartnerInformation from './ProfessionalPartnerInformation'
import SubscriptionInformation from './SubscriptionInformation'
import AccountInformationPreview from '../shared/AccountInformationPreview'
import TfaInformation from '../shared/TfaInformation'
import DeleteAccount from '../shared/DeleteAccount'
import Loader from '../../../../../shared_components/NewLoader'

import {findRawResourceByAttributeId} from '../../../../../lib/plan_data/userData'
import {currentUserConfig, currentUser} from '../../../../../lib/userTools'
import {activePlan} from '../../../../../lib/planTools'

import './accountSettings.scss'


const AccountSettings = props => {
  const [loading, setLoading] = useState(true)

  const fetchEndpoints = urls => {
    const promises = urls.map(url => props.readEndpoint(url))
    // Removes the loader once all promises are resolved
    Promise.all(promises.toJS()).then(() => setLoading(false))
  }

  useEffect(
    () => {
      const firmId = props.userConfig.get('firm-id')
      const client = props.userConfig.get('client')
      const everplanId = props.userConfig.get('everplan-id')
      let endpoints = List()

      // // For all users
      endpoints = endpoints.push(`account-settings/${everplanId}`)

      // For firm users
      if (firmId && !client && props.firm.isEmpty())
        endpoints = endpoints.push(`firms/${firmId}`)

      // For clients
      if (client && props.advisorContactCard.isEmpty())
        endpoints = endpoints.push(`advisor-contact-cards/${everplanId}`)

      fetchEndpoints(endpoints)
    },
    []
  )

  if (loading || props.user.isEmpty()) {
    return <Loader loading={true} />
  } else {
    return (
      <div className='corp-max-account-settings'>
        <h1>Account Settings</h1>
        <AccountInformationPreview
          user={props.user}
          userConfig={props.userConfig}
        />
        <SubscriptionInformation accountSetting={props.accountSetting} />
        <ProfessionalPartnerInformation {...props} />
        <TfaInformation
          readEndpoint={props.readEndpoint}
          userConfig={props.userConfig}
        />
        <AccountSettingsRow
          title='Email Preferences:'
          bodyText='Go here to manage your email preferences.'
          url='/cm/account-settings/email-preferences'
        />
        <DeleteAccount />
      </div>
    )
  }
}


AccountSettings.propTypes = {
  advisorContactCard: PropTypes.instanceOf(Map),
  deleteResource: PropTypes.func,
  firm: PropTypes.instanceOf(Map),
  readEndpoint: PropTypes.func,
  accountSetting: PropTypes.instanceOf(Map),
  user: PropTypes.instanceOf(Map),
  userConfig: PropTypes.instanceOf(Map)
}

const mapStateToProps = ({api}) => {
  const userConfig = currentUserConfig(api)
  const advisorContactCard = findRawResourceByAttributeId({
    rawResource: api['advisor-contact-cards'],
    attribute: 'everplan-id',
    id: userConfig.get('everplan-id')
  })
  const firm = findRawResourceByAttributeId({
    rawResource: api.firms,
    attribute: 'id',
    id: userConfig.get('firm-id')
  })
  const accountSetting = findRawResourceByAttributeId({
    rawResource: api['account-settings'],
    attribute: 'everplan-id',
    id: userConfig.get('everplan-id')
  })


  return {
    accountSetting,
    advisorContactCard,
    firm,
    plan: activePlan(api.plans),
    user: currentUser({userConfig, users: api.users}),
    userConfig
  }
}

export default connect(mapStateToProps, {deleteResource, readEndpoint})(
  AccountSettings
)

