import LegacyActionFactory from '../../../shared_components/LegacyActionFactory'

const AccountSettingsActions = new LegacyActionFactory([
  'GOT_ACCOUNT_INFORMATION',
  'DID_CHANGE_PASSWORD',
  'DID_ADD_EMAIL',
  'DID_DELETE_EMAIL',
  'DID_RESEND_VERIFICATION',
  'DID_UPDATE_PRIMARY_EMAIL',
  'DID_UPDATE_PROFILE'
])

AccountSettingsActions.fetchAccountInformation = userId => {
  AccountSettingsActions.fireApi(
    'get',
    `account_settings/${userId}`,
    null,
    {
      JSONHead: 'account_information',
      successAction: AccountSettingsActions.Types.GOT_ACCOUNT_INFORMATION
    }
  )
}

AccountSettingsActions.changePassword = (data, userId) => {
  AccountSettingsActions.fireApi(
    'put',
    `account_settings/${userId}/update_password`,
    data,
    {
      successAction: AccountSettingsActions.Types.DID_CHANGE_PASSWORD
    }
  )
}

AccountSettingsActions.updateAccountInformation = (data, userId) => {
  AccountSettingsActions.fireApi(
    'put',
    `account_settings/${userId}`,
    data,
    {
      JSONHead: 'account_information',
      successAction: AccountSettingsActions.Types.DID_UPDATE_PROFILE
    }
  )
}

AccountSettingsActions.removeEmail = emailId => {
  AccountSettingsActions.fireApi(
    'delete',
    `emails/${emailId}`,
    null,
    {
      JSONHead: 'email',
      successAction: AccountSettingsActions.Types.DID_DELETE_EMAIL
    }
  )
}

AccountSettingsActions.resendVerification = emailId => {
  AccountSettingsActions.fireApi(
    'get',
    `emails/${emailId}/resend_confirmation`,
    null,
    {
      JSONHead: 'email',
      successAction: AccountSettingsActions.Types.DID_RESEND_VERIFICATION
    }
  )
}

AccountSettingsActions.setPrimaryEmail = emailId => {
  AccountSettingsActions.fireApi(
    'put',
    `emails/${emailId}`,
    null,
    {
      JSONHead: 'email',
      successAction: AccountSettingsActions.Types.DID_UPDATE_PRIMARY_EMAIL
    }
  )
}

AccountSettingsActions.buildPost('addEmail', 'emails', {JSONHead: 'email', successAction: AccountSettingsActions.Types.DID_ADD_EMAIL})

export default AccountSettingsActions
