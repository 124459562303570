import {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {List, Map} from 'immutable'
import PropTypes from 'prop-types'
import {readEndpoint} from 'redux-json-api'

import AddressBookContacts from './AddressBookContacts'
import AddressBookHeader from './AddressBookHeader'
import EmptyAddressBook from './EmptyAddressBook'

import {currentUserConfig, currentUser} from '../../../../../lib/userTools'
import {findFirmConfigByFirmOwnership, findRawResourceByAttributeId, findRawResourceById} from '../../../../../lib/plan_data/userData'
import {scrollToTopOfPage} from '../../../../../lib/scrollTools'
import {useDeputyData, useEverplanData} from '../../../../../lib/hooks'
import Logger from '../../../../../lib/NewLogger'
import routerUtils from '../../../../../lib/routerUtils'

import './addressBookContainer.scss'


export const AddressBookContainer = props => {
  const [showContactModal, setShowContactModal] = useState(false)

  const everplanId = props.userConfig.get('everplan-id')

  const {itemResponses, newContacts} = useEverplanData(everplanId)
  const {deputies} = useDeputyData(everplanId)

  useEffect(() => {
    scrollToTopOfPage()

    Logger.log({
      name: 'viewed_address_book',
      payload: {contact_count: newContacts.size}
    })

    if (props.userConfig.get('client'))
      props.readEndpoint(`/advisor-contact-cards/${everplanId}`)
  }, [])

  return (
    <div className='address-book-container flex-container'>
      <div className='address-book-container-inner'>
        <AddressBookHeader
          params={props.params}
          showContactModal={showContactModal}
          addNewContact={() => setShowContactModal(true)}
          hideContactModal={() => setShowContactModal(false)}
        />
        {
          newContacts.isEmpty() ?
            <EmptyAddressBook /> :
            <AddressBookContacts
              {...props}
              itemResponses={itemResponses}
              deputies={deputies}
              newContacts={newContacts}
            />

        }
        {
          routerUtils.childrenWithProps(props.children, {
            newContacts,
            params: props.params
          })
        }
      </div>
    </div>
  )
}

AddressBookContainer.propTypes = {
  advisorContactCard: PropTypes.instanceOf(Map),
  currentUser: PropTypes.instanceOf(Map),
  firm: PropTypes.instanceOf(Map),
  firmConfig: PropTypes.instanceOf(Map),
  newContacts: PropTypes.instanceOf(List),
  organization: PropTypes.instanceOf(Map),
  params: PropTypes.object,
  readEndpoint: PropTypes.func,
  userConfig: PropTypes.instanceOf(Map)
}


const mapStateToProps = ({api}) => {
  const userConfig = currentUserConfig(api)
  const everplanId = userConfig.get('everplan-id')

  const advisorContactCard = findRawResourceByAttributeId({
    rawResource: api['advisor-contact-cards'],
    attribute: 'everplan-id',
    id: everplanId
  })

  const firmOwnership = findRawResourceByAttributeId({
    rawResource: api['firm-ownerships'],
    attribute: 'everplan-id',
    id: userConfig.get('everplan-id')
  })

  const firm = findRawResourceById({
    rawResource: api.firms,
    id: firmOwnership.get('ownable-id')
  })

  const organization = findRawResourceById({
    rawResource: api.organizations,
    id: firm.get('organization-id')
  })

  return ({
    advisorContactCard,
    userConfig,
    currentUser: currentUser({userConfig, users: api.users}),
    organization,
    firm,
    firmConfig: findFirmConfigByFirmOwnership({firmOwnerships: api['firm-ownerships'], firmConfigs: api['firm-configs'], userConfig})
  })
}

export default connect(mapStateToProps, {readEndpoint})(AddressBookContainer)
