import variables from '../dashboard/styles/abstracts/variables.module.scss'

export default {
  arrows: false, // Hide the default arrows
  infinite: false, // Don't loop through the slides again when the end is reached
  slidesToScroll: 1,
  slidesToShow: 2,
  swipeToSlide: true, // Allow swiping to navigate between slides
  touchThreshold: 10, // Set touch action sensitivity. User must swipe slider width divided by `touchThreshold` to change slides

  // Set overrides for the above-defined rules below specific breakpoints. The general approach to these rules is to
  // allow faster, more-sensitive DOM reactions on mobile due to how people use phones (e.g., repeatedly swiping quickly)
  // and then slow things down and drop the drag/swipe sensitivity on larger devices. Any device larger than the largest
  // breakpoint defined in this array will use the default rules above.
  responsive: [
    {
      breakpoint: 768, // `$tabletMd`
      settings: {
        centerMode: true,
        centerPadding: variables.slickPadding,
        slidesToShow: 1,
        speed: 100,
        touchThreshold: 7.5
      }
    },
    {
      breakpoint: 600, // `$tabletSm`
      settings: {
        centerMode: true,
        centerPadding: variables.slickPadding,
        slidesToShow: 1,
        speed: 75,
        touchThreshold: 5
      }
    },
    {
      breakpoint: 480, // `$mobileLg`
      settings: {
        centerMode: true,
        centerPadding: variables.slickPadding,
        slidesToShow: 1,
        speed: 50,
        touchThreshold: 4
      }
    }
  ]
}
