import {Component} from 'react'
import {List, Map} from 'immutable'
import PropTypes from 'prop-types'

import Form from '../../../../../../../shared_components/forms/v2/Form'
import Select from '../../../../../../../shared_components/forms/v2/fields/Select'

import {categoryItems} from '../../../../../../../lib/plan_data/category'
import {findResourceById} from '../../../../../../../lib/plan_data/userData'
import {findSelectedValueId} from '../../../../../../../shared_components/forms/v2/lib/tools'
import {isCompoundItem} from '../../../../../../../lib/plan_data/item'
import Validators from '../../../../../../../shared_components/forms/v2/validators'

export default class SelectItemForm extends Component {
  constructor() {
    super()

    this.state = {
      isCompoundItem: false,
      itemTopKit: Map(),
      items: Map(),
      category: Map()
    }

    this.onItemSelectChange = this.onItemSelectChange.bind(this)
    this.onCompoundItemChange = this.onCompoundItemChange.bind(this)
    this.onCategorySelectChange = this.onCategorySelectChange.bind(this)
  }

  onCompoundItemChange() {
    this.props.setTopElement(
      findResourceById({
        resourceList: this.state.itemTopKit.get('elements'),
        id: findSelectedValueId({selectId: 'compound-item', form: this.refs.form})
      })
    )
  }

  onCategorySelectChange() {
    this.resetSelectedItem()

    const category = findResourceById({
      resourceList: this.props.categories,
      id: findSelectedValueId({selectId: 'category', form: this.refs.form})
    })

    if (category.isEmpty())
      this.setState({items: Map(), category: Map()}) // to reset everything if 'Choose a Category' is selected
    else
      this.setState({category, items: categoryItems(category, this.props.items)})
  }

  resetSelectedItem() {
    this.props.setTopElement(Map())
    this.setState({isCompoundItem: false, itemTopKit: Map()})
  }

  onItemSelectChange() {
    const item = findResourceById({
      resourceList: this.props.items,
      id: findSelectedValueId({selectId: 'item', form: this.refs.form})
    })

    if (item.isEmpty()) {
      this.resetSelectedItem() // to reset everything if 'Choose an Item' is selected
    } else {
      const itemTopKit = this.props.kits.get(item.get('kit-id'))

      this.props.setTopElement(itemTopKit.getIn(['elements', 0]))
      this.setState({isCompoundItem: isCompoundItem(itemTopKit), itemTopKit})
    }
  }

  selectItems() {
    return this.state.items
      .sortBy(item => item.get('sort-order'))
      .map(item => Map(
        {
          id: item.get('id'),
          label: item.get('header'),
          value: item.get('header')
        }
      ))
      .unshift(Map({label: 'Choose an Item', value: ''}))
  }

  categoryItems() {
    return this.props.categories
      .sortBy(category => category.get('sort-order'))
      .map(category => Map(
        {
          id: category.get('id'),
          label: category.get('name'),
          value: category.get('name')
        }
      ))
      .unshift(Map({label: 'Choose a Category', value: ''}))
  }

  compoundItems() {
    return this.state.itemTopKit.get('elements')
      .map(element => Map(
        {
          id: element.get('id'),
          label: element.get('name'),
          value: element.get('name')
        }
      ))
      .unshift(Map({label: 'Choose a compound item', value: ''}))
  }


  render() {
    return (
      <Form className='core select-item-form' ref='form'>
        <Select
          data={
            Map({
              id: 'category',
              items: this.categoryItems(),
              legend: 'Select Category',
              name: 'category'
            })
          }
          onChange={this.onCategorySelectChange}
          validator={Validators.required}
        />
        {
          !this.state.category.isEmpty() && (
            <div key={this.state.category.get('id')}>
              <Select
                data={
                  Map({
                    id: 'item',
                    items: this.selectItems(),
                    legend: 'Select Item:',
                    name: 'item'
                  })
                }
                onChange={this.onItemSelectChange}
                validator={Validators.required}
              />
            </div>
          )
        }
        {
          this.state.isCompoundItem && (
            <div key={this.state.itemTopKit.get('id')}>
              <Select
                data={
                  Map({
                    id: 'compound-item',
                    items: this.compoundItems(),
                    legend: 'Select Compound Item Option:',
                    name: 'compound-item',
                    value: this.state.itemTopKit.getIn(['elements', 0, 'name'])
                  })
                }
                onChange={this.onCompoundItemChange}
              />
            </div>
          )
        }
      </Form>
    )
  }
}

SelectItemForm.propTypes = {
  categories: PropTypes.instanceOf(List),
  items: PropTypes.instanceOf(List),
  kits: PropTypes.instanceOf(Map),
  setTopElement: PropTypes.func
}
