import PropTypes from 'prop-types'

import {Map} from 'immutable'
import {Component} from 'react'

import articleIcon from '../../shared_assets/book.svg'
import ModuleBox from '../component_templates/ModuleBox'
import {contentImageUrl} from '../../lib/s3Tools'
import Logger from '../../lib/NewLogger'

import './articleBox.scss'

export default class ArticleBox extends Component {
  constructor(props) {
    super(props)

    this.backgroundImage = `url("${contentImageUrl(`${props.article.get('query')}-SMALL.jpeg`)}")`

    this.onClick = this.onClick.bind(this)
  }

  onClick() {
    const articleName = this.props.article.get('headline')
    Logger.log({
      name: 'viewed_article',
      payload: {
        article_name: articleName,
        context: this.props.context,
        wildcard: articleName
      }
    })

    this.props.next()
  }

  render() {
    return (
      <ModuleBox className='article-box flex-container' onClick={this.onClick}>
        <div className='everplans-article-details flex-child'>
          <h3>{this.props.article.get('headline')}</h3>
          <a>Learn more</a>
        </div>
        <img alt='article-icon' src={articleIcon} />
      </ModuleBox>
    )
  }
}

ArticleBox.propTypes = {
  article: PropTypes.instanceOf(Map),
  context: PropTypes.string,
  heading: PropTypes.string,
  next: PropTypes.func,
  type: PropTypes.string
}
