import PropTypes from 'prop-types'

import {Gauge, gaugeClasses} from '@mui/x-charts/Gauge'
import {animated, useSpring} from '@react-spring/web'

import './animatedScore.scss'

const AnimatedGauge = animated(Gauge)

const AnimatedScore = props => {
  const springs = useSpring({
    from: {value: 0},
    to: {value: props.value},
    config: {duration: 1000}
  })

  return (
    <div className='animated-score'>
      <AnimatedGauge
        cornerRadius={50}
        innerRadius={'85%'}
        startAngle={-90}
        endAngle={90}
        sx={theme => ({
          [`& .${gaugeClasses.valueArc}`]: {
            fill: theme.palette.secondary.green
          },
          ['& text']: {
            fill: theme.palette.primary.main
          },
          [`& .${gaugeClasses.valueText}`]: {
            color: theme.palette.primary.main,
            fontSize: '3.5vw',
            fontWeight: '600',
            transform: 'translate(1px, -17%)'
          },
          [`& .${gaugeClasses.referenceArc}`]: {
            fill: props.referenceArcColor
          }
        })}
        text={springs.value.to(val => `${Math.floor(val)}`)}
        value={springs.value.to(val => Math.floor(val))}
        valueMin={0}
        valueMax={500}
      />
      <div className='range'>
        <div className='min'>0</div>
        {
          props.value === 500 &&
          <div className='max-score-text'>Max score achieved!</div>
        }
        <div className='max'>500</div>
      </div>
    </div>
  )
}

AnimatedScore.defaultProps = {
  referenceArcColor: '#EEEDED',
  value: 0
}

AnimatedScore.propTypes = {
  referenceArcColor: PropTypes.string,
  value: PropTypes.number
}

export default AnimatedScore
