import {List} from 'immutable'
import PropTypes from 'prop-types'

import {TextGutterMedium} from '../../blocks/Texts'
import ManageSelectedDeputiesPermission from './ManageSelectedDeputiesPermission'
import NoDeputies from './NoDeputies'
import NoPermissionsLinkAndButtons from './NoPermissionsLinkAndButtons'
import SelectDeputies from './SelectDeputies'

const NoExistingPermissions = props => {
  if (props.deputies.isEmpty()) {
    return <NoDeputies {...props} />
  } else {
    return (
      <div className='no-existing-permissions'>
        {props.selectedDeputies.isEmpty() && <TextGutterMedium>This item hasn't been shared yet.</TextGutterMedium>}
        <SelectDeputies {...props} />
        {
          props.selectedDeputies.isEmpty() ?
            <NoPermissionsLinkAndButtons {...props} /> :
            <ManageSelectedDeputiesPermission {...props} />
        }
      </div>
    )
  }
}

NoExistingPermissions.propTypes = {
  addNewDeputyLink: PropTypes.func,
  deputies: PropTypes.instanceOf(List),
  selectedDeputies: PropTypes.instanceOf(List),
  toggleModal: PropTypes.func
}

export default NoExistingPermissions
