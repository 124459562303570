import {Map} from 'immutable'
import PropTypes from 'prop-types'

import {corpMaxEverplanPreviewPath} from '../../../../../lib/urlTools'
import routerUtils from '../../../../../lib/routerUtils'

const InviteAcceptNotification = props => {
  const handleClick = () => {
    routerUtils.push(corpMaxEverplanPreviewPath(props.deputyOwnership.get('everplan-id')))
    props.dismissNotification()
  }

  return (
    <section>
      You're now a Deputy for <a onClick={handleClick}>{props.deputyOwnership.get('everplan-name')}</a>.
    </section>
  )
}

InviteAcceptNotification.propTypes = {
  dismissNotification: PropTypes.func,
  deputyOwnership: PropTypes.instanceOf(Map)
}

export default InviteAcceptNotification
