import {useState} from 'react'
import PropTypes from 'prop-types'

import Button from '../../../../../shared_components/Button'
import Modal from '../../../../../shared_components/Modal'
import PrimaryPhoneEditor from './PrimaryPhoneEditor'

const TfaPrimaryPhone = props => {
  const [showModal, setShowModal] = useState(false)

  const displayModal = () => { setShowModal(true) }

  const hideModal = () => { setShowModal(false) }

  return (
    <div className='status-body'>
      <div className='status-text'>
        {props.primaryNumber.national_number}
        <br />
      Verification codes delivered via {props.primaryNumber.preferred_message_mode}.
      </div>
      <Button className='control-btn' onClick={displayModal}>Edit</Button>
      <Modal visible={showModal} closer={hideModal} >
        <div className='auth-content'>
          <PrimaryPhoneEditor {...props} closer={hideModal} />
        </div>
      </Modal>
    </div>
  )
}

TfaPrimaryPhone.propTypes = {
  primaryNumber: PropTypes.shape({
    national_number: PropTypes.number,
    preferred_message_mode: PropTypes.string
  })
}

export default TfaPrimaryPhone
