import {Map} from 'immutable'

const body = `
Times are rough. We understand. It's hard enough to find the money to cover your daily living expenses, so the idea that you should put away "extra" money for a rainy day seems preposterous.

But it is necessary, and it's easier than you might think.

"The common plan is for people to set aside a percentage of their paycheck," says tax attorney Howard Chernoff. "But in trying times, it's very difficult to set anything aside. So what I've done in the past and what I tell people is sometimes don't take all your exemptions, everything you're entitled to, so that at the end of the year there's a little bit of money left. You know that sometime in February, March, or April you're going to get a little bump." And that little "bump" can be extremely handy if you know what to do with it.

### Home Maintenance

If you are a homeowner, you know that unexpected expenses come standard along with termites, roof damage, and plumbing originally installed during the Mesozoic Era. And yet people are consistently ambushed by these mini-emergencies, even when they don't have to be. "I once had a guy who decided to put $200 extra withholding on his tax form per week -- and his wife did the exact same thing –- and at the end of the year they had a $38,000 refund," says Chernoff.  "And they completely redid their kitchen. Because they knew if they just let it come through their paychecks, they wouldn't have $38,000 -- they'd be lucky if they had $3,800."

### Medical Expenses

"That's the joke with this new healthcare plan," explains Chernoff. "Everybody gets a $3,000 credit. What's that going to cover? That's not going to do anything." As the future of coverage in this country gets murkier by the day, it's all the more important to have a cushion to fall back on in times of need. People who are naturally averse to the word "budget" should also know that it doesn't have to be a line-by-line breakdown of expenses. "It's just a kitty," says Chernoff. "And you put the money in the kitty and you hope that it's there for when you have something that needs to be done."

### Gifts

Unless your savings plan is "be a sociopath," odds are you're going to be invited to a birthday party or wedding at some point in your life and showing up empty handed just won't be an option. Same thing with anniversaries, Valentine's Day, and all the other holidays created by Big Greetings to get you to buy more cards and flowers. Being disciplined with the money is key, though. Putting aside safety money is only effective if, as Chernoff puts it, "you can leave it alone."

### Vacations

The romantic fantasy of jetting off at a moment's notice to parts unknown is great if you live in a movie where you're still allowed to run to the boarding gate at the last minute. But real human beings need to plan and save well in advance. Putting an entire vacation on a credit card without the funds to cover it will only make the return trip home all the more depressing.

### Pets

When an accident or sickness strikes a loyal and loveable pet, even the most common of ailments can get outrageously expensive. While some people might say "it's just a dog" or "it's only a cat," it's much different when it's your cat or dog. Kudos to people who think ahead and shell out between $30-$50 a month for pet insurance, but those who don't can spend thousands to make sure their furry friend is back on their paws.

### Insurance

A lot of people get some form of Life Insurance through their employer -- which is great, but in these volatile times how long can you honestly expect to hold onto that coverage? It's better to establish an insurance policy independent of your job, and that means extra costs. Again, Chernoff recommends using the federal government as a savings plan by tweaking your exemptions. "Some experts say you shouldn't leave money with the government. Why not? The government actually pays a higher rate of interest than you can get anywhere," he says. "You can get 4-5% from the Federal government on your refund -- it's a terrific savings plan."
`


export default Map({
  body,
  headline: '6 Things People Never Budget For',
  summary: "Don't get taken by surprise when these expenses creep up on you.",
  query: '6-things-people-never-budget-for',
  url: 'https://www.everplans.com/articles/6-things-people-never-budget-for'
})
