import PropTypes from 'prop-types'
import {Component} from 'react'
import {fromJS, List, Map} from 'immutable'

import Select from '../../../../../../../shared_components/forms/v2/fields/Select'
import KeyFieldValueText from '../../../../../../../shared_components/forms/v2/fields/KeyFieldValueText'
import TrashCanIcon from '../../../../icons/TrashCanIcon'

export class KeyFieldFormField extends Component {
  constructor(props) {
    super(props)
    this.deleteValue = this.deleteValue.bind(this)
    this.onChangeTextField = this.onChangeTextField.bind(this)
    this.onChangeSelectField = this.onChangeSelectField.bind(this)
  }

  deleteValue() {
    return this.props.onDeleteValue(this.props.valueId)
  }

  onChangeTextField() {
    return this.props.onChangeText(this.props.valueId)
  }

  onChangeSelectField() {
    return this.props.onSelectChange(this.props.valueId)
  }

  render() {
    const keyFieldValue = this.props.keyFieldValue
    return (
      <div className='key-field-form-field'>
        <div className='select-options'>
          <Select
            data={
              Map({
                id: this.props.valueId,
                items: this.props.dataItems,
                legend: 'Select value',
                name: this.props.valueId
              })
            }
            onChange={this.onChangeSelectField}
          />
          <span className='delete' onClick={this.deleteValue}>
            <TrashCanIcon />
          </span>
        </div>

        {
          keyFieldValue.get('type') === 'STRING' &&
            <div className='text-box'>
              <KeyFieldValueText
                data={
                  Map({
                    id: this.props.valueId,
                    legend: 'Add text',
                    name: this.props.valueId
                  })
                }
                onChange={this.onChangeTextField}
              />
            </div>
        }
      </div>
    )
  }
}

KeyFieldFormField.propTypes = {
  dataItems: PropTypes.instanceOf(List).isRequired,
  keyFieldValue: PropTypes.instanceOf(Map).isRequired,
  onChangeText: PropTypes.func.isRequired,
  onDeleteValue: PropTypes.func.isRequired,
  onSelectChange: PropTypes.func.isRequired,
  valueId: PropTypes.string.isRequired
}

export default KeyFieldFormField
