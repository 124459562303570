/* eslint-disable react/jsx-no-bind */
import {List} from 'immutable'
import {useForm, Controller} from 'react-hook-form'
import PropTypes from 'prop-types'

import {Button, Cancel} from '../../blocks/Buttons'
import {TextGutterMedium} from '../../blocks/Texts'
import ButtonGroup from '../../../../../shared_components/core/buttons/ButtonGroup'

import {humanizedFirstNamesWithConjunction} from '../../../../../lib/nameTools'
import {bulkPermissionOptions} from '../../../../../lib/deputyPermissionTools'
import PermissionSelect from './shared_components/PermissionSelect'
import './manageSelectedDeputiesPermission.scss'

const ManageSelectedDeputiesPermission = props => {
  const {
    handleSubmit,
    watch,
    control
  } = useForm({defaultValues: {selectedPermissionType: bulkPermissionOptions[0]}})

  const deputyNames = humanizedFirstNamesWithConjunction(props.selectedDeputies.map(deputy => deputy.get('ownable')), 'and')

  const permissionType = watch('selectedPermissionType')

  return (
    <div className='manage-selected-deputies-permission'>
      <div className='permission-container'>
        <div className='permission-label'>Choose permissions</div>
        <Controller
          name='selectedPermissionType'
          control={control}
          render={({field}) => (
            <PermissionSelect
              {...field}
              classNamePrefix='selected-deputies-permission-select'
              options={bulkPermissionOptions}
            />
          )}
        />
      </div>
      <TextGutterMedium>
        Anything you add to <span>{props.keyField}</span> will be shared {permissionType.value === 'deferred_read' && 'after death '}with {deputyNames}. You can adjust the permissions at any time.
      </TextGutterMedium>
      <ButtonGroup>
        <Cancel onClick={props.toggleModal} disabled={props.processing}>Cancel</Cancel>
        <Button onClick={handleSubmit(props.onSubmit)} processing={props.processing}>Share</Button>
      </ButtonGroup>
    </div>
  )
}

ManageSelectedDeputiesPermission.propTypes = {
  keyField: PropTypes.instanceOf(List),
  selectedDeputies: PropTypes.instanceOf(List),
  onSubmit: PropTypes.func,
  processing: PropTypes.bool,
  toggleModal: PropTypes.func
}

export default ManageSelectedDeputiesPermission
