import {Map} from 'immutable'

const body = `
It's a nightmare scenario for a lot of people -- what if the most valuable thing in your possession was denied to your kids after your death because of some flawed paperwork? Luckily, leaving your house doesn't have to be a legal Thunderdome if you take the time to put everything in order well in advance.

An ABC News report listed three major steps that can keep your home safely and securely in your family for generations to come. Because if your kids are going to fight over something, let it at least be your vintage thimble collection.

### 1. Put It In Your Will

Sounds simple enough, right? But naming beneficiaries is extremely important, and not being specific can cause often unforeseen complications. An example cited is a situation where a brother with a lot of debt shares ownership of his house with his sister. He wants to sell it, she doesn't. Since they both inherited an undivided interest in the estate per your Will, they have to come to an amicable agreement on what to do with the property. If not, it's off to the courts.

### 2. Use the Right Words

The lesson of #1 carries over into #2 -- not only do you have to put everything on paper, you have to be as specific and precise as possible. Do you know the difference between "Transfer on Death" and "Joint Tenant With Right of Survivorship"? Well, you need to. If you choose the first option (assuming you own your home outright), your home is immediately transferred to the person or persons named on the deed, assuming this option is available in your state. In the case of JTWRS, you co-own your home with someone else, and the ownership is passed to your co-owner automatically if you pass away first. The drawback here is that you don't have complete control over your home while you're alive.

### 3. Create a Living Trust

Unlike a Will, a Living Trust helps you manage your assets while you are still alive, and you can exert more detailed control over what happens to your possessions when you die. One of the advantages of this type of situation is that your house will pass to your designated beneficiary without having to go through probate (a court-supervision process that accompanies a Will), which means fewer delays and expenses. Therefore, your home can get transferred to your children much more quickly and efficiently.

Finally, it's smart to run all of your plans by an estate attorney or financial professional to make sure everything is legit and won't cause any unfortunate issues down the road.
`

export default Map({
  body,
  headline: 'The 3 Things You Must Do To Leave Your House To Someone Else',
  summary: "Make sure everything is spelled out and legally binding, or you'll be leaving behind a headache instead of property.",
  query: 'the-3-things-you-must-do-to-leave-your-house-to-someone-else',
  url: 'https://www.everplans.com/articles/the-3-things-you-must-do-to-leave-your-house-to-your-kids'
})
