/** @class AddResponseButton
 *
 * @desc
 * Shared `button` component that opens an item modal for a new response entry

 * @property {Map} item
 * @property {Map} kit
 *
 **/

import {Map} from 'immutable'
import PropTypes from 'prop-types'

import PlusIcon from '../../../../../shared_components/core/svg_icons/icons/PlusIcon'

import {firstTopKitParentElementId, isCompoundItemResponse} from '../../../../../lib/plan_data/itemResponsesHelper'
import {newItemPath} from '../../../../../lib/urlTools'
import routerUtils from '../../../../../lib/routerUtils'
import Logger from '../../../../../lib/NewLogger'
import {accountEntrySelectPath} from '../../../../../lib/urlTools'
import {eligibleForAccountsImport} from '../../../../../lib/accountPopulationTools'

const AddResponseButton = props => {
  const logAddAnother = () => {
    const itemName = props.itemResponse.get('name')

    Logger.log({
      name: 'clicked_add_another',
      payload: {
        context: props.context,
        type: 'vault',
        item: itemName,
        wildcard: itemName,
        compound_item: isCompoundItemResponse(props.itemResponse)
      }
    })
  }

  /** Constructs the URL path to the Item Modal when requesting to add a new item response. */
  const addNew = () => {
    routerUtils.push(
      newItemPath({
        elementId: firstTopKitParentElementId(props.itemResponse),
        itemSlug: props.itemResponse.get('slug'),
        slug: props.categorySlug,
        path: props.location.pathname
      })
    )
  }

  const onClickAddNew = () => {
    logAddAnother()

    if (eligibleForAccountsImport({item: props.itemResponse, userConfig: props.userConfig}))
      routerUtils.push(accountEntrySelectPath({itemSlug: props.itemResponse.get('slug'), path: props.location.pathname}))
    else
      addNew()
  }

  return (
    <div className='add-response-button flex-child' onClick={onClickAddNew}>
      <PlusIcon fill='#FFF' width='10' height='10' />
    </div>
  )
}

AddResponseButton.defaultProps = {
  children: 'Add'
}

AddResponseButton.propTypes = {
  category: PropTypes.instanceOf(Map),
  categorySlug: PropTypes.string,
  context: PropTypes.string,
  itemResponse: PropTypes.instanceOf(Map).isRequired,
  location: PropTypes.object,
  userConfig: PropTypes.instanceOf(Map).isRequired
}

export default AddResponseButton
