/** @class FeedbackForm
 *
 * @desc
 * Component that displays a `form` where the user can submit their user experience.
 *
 * @property {string} checkboxLabel - The `label` and `legend` text used in the `CheckboxList` form field component.
 * @property {Array<Object>} items - An array of configuration objects that describes the data in the
 *   `ImageRadioList` form field component.
 * @property {string} [items.image] - URL for the image radio button.
 * @property {string} [items.label] - The radio's `label` text.
 * @property {string} [items.value] - The radio's `value` that will be passed to the form data.
 * @property {string} placeholder - The 'placeholder' and `legend` text used in the `TextArea` form field component.
 */

import {Component} from 'react'
import {fromJS} from 'immutable'
import PropTypes from 'prop-types'

import Fields from '../../shared_components/forms/v2/fields/index'
import Form from '../../shared_components/forms/v2/Form'

import GoodEmojiIcon from '../../shared_assets/v2/user_feedback_emojis/emoji-good.png'
import GreatEmojiIcon from '../../shared_assets/v2/user_feedback_emojis/emoji-great.png'
import MehEmojiIcon from '../../shared_assets/v2/user_feedback_emojis/emoji-meh.png'
import TerribleEmojiIcon from '../../shared_assets/v2/user_feedback_emojis/emoji-terrible.png'

import './feedbackForm.scss'


export default class FeedbackForm extends Component {
  /** Helper for parent to grab form data. */
  formData() { return this.refs.form.formData() }

  /** Constructs the data for the `CheckboxList` form field component. */
  checkboxListData() {
    return fromJS({
      id: 'feedback-form-checkbox',
      items: [{label: this.props.checkboxLabel, value: 'Yes'}],
      legend: this.props.checkboxLabel,
      name: 'feedback-form-checkbox'
    })
  }

  /** Constructs the data for the `ImageRadioList` form field component. */
  imageRadioListData() {
    return fromJS({
      id: 'feedback-form-image-radio-list',
      items: this.props.items,
      legend: 'Feedback',
      name: 'feedback-form-image-radio-list'
    })
  }

  /** Constructs the data for the `TextArea` form field component. */
  textAreaData() {
    return fromJS({
      id: 'feedback-form-textarea',
      legend: this.props.placeholder,
      name: 'feedback-form-textarea',
      placeholder: this.props.placeholder
    })
  }

  render() {
    return (
      <div className='feedback-form forms-playground'>
        <Form className='core' ref='form'>
          <Fields.ImageRadioList data={this.imageRadioListData()} />
          <Fields.TextArea data={this.textAreaData()} />
          {
            !this.props.suppressOptIn &&
            <Fields.CheckboxList data={this.checkboxListData()} />
          }
          {this.props.children}
        </Form>
      </div>
    )
  }
}

FeedbackForm.defaultProps = {
  checkboxLabel: 'Yes, Everplans can contact me for additional product feedback.',
  items: [
    {image: GreatEmojiIcon, label: 'Great', value: 'Great'},
    {image: GoodEmojiIcon, label: 'Good', value: 'Good'},
    {image: MehEmojiIcon, label: 'Not Good', value: 'Not Good'},
    {image: TerribleEmojiIcon, label: 'Terrible', value: 'Terrible'}
  ],
  placeholder: 'What can we do better?'
}

FeedbackForm.propTypes = {
  checkboxLabel: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      label: PropTypes.string,
      value: PropTypes.string
    })
  ),
  placeholder: PropTypes.string,
  suppressOptIn: PropTypes.bool
}
