import {useEffect} from 'react'
import PropTypes from 'prop-types'
import {Map} from 'immutable'

import {Button} from '../blocks/Buttons'
import introductionImage from '../../../assets/score_assessment/score-assessment-intro.svg'
import resultsWithoutScoreImage from '../../../assets/score_assessment/score-assessment-results-without-score.svg'

import './assessmentContentScreen.scss'
import Logger from '../../../../lib/NewLogger'

const AssessmentContentScreen = props => {
  const {
    screen,
    nextScreen,
    logMixpanelEvent,
    numberOfQuestionsAnswered,
    assessmentWithoutScore,
    submitAssessment
  } = props

  const logEvent = ({name, payload = {}}) => Logger.log({name, payload})

  const logEvents = () => {
    const mixpanelEventName = 'view_assessment'

    switch (screen.get('name')) {
      case 'introduction':
        logMixpanelEvent({name: mixpanelEventName, payload: {step: assessmentWithoutScore ? 'intro_alt' : 'intro'}})
        logEvent({name: 'viewed_onboarding_welcome'})
        break
      case 'result':
        logMixpanelEvent({name: mixpanelEventName, payload: {step: 'result_no_score'}})
        logEvent({name: 'viewed_onboarding_final_screen', payload: {number_of_assessment_questions: numberOfQuestionsAnswered}})
        break
      default:
        break
    }
  }

  useEffect(() => {
    if (screen.get('name') === 'result')
      submitAssessment()

    logEvents()
  }, [])

  const screenName = screen.get('name')

  const introductionScreenBody = assessmentWithoutScore ? screen.get('alternate-body') : screen.get('body')

  const bodyTexts = screenName === 'introduction' ? introductionScreenBody : screen.get('body')

  const [buttonText, className, heading, image] =
    screenName === 'introduction' ?
      ['Start', 'introduction', 'Welcome to Everplans!', introductionImage] :
      ['Continue', 'result', "You're all set!", resultsWithoutScoreImage]

  return (
    <div className='assessment-content-screen'>
      <img
        alt={`${screenName}-image`}
        className={className}
        src={image}
      />
      <h1 className='heading'>{heading}</h1>
      <div className='body'>
        {bodyTexts.map(text => (
          <span
            className='body-text'
            key={text}>
            {text}
          </span>
        ))}
      </div>
      <Button onClick={nextScreen}>{buttonText}</Button>
    </div>
  )
}

AssessmentContentScreen.propTypes = {
  assessmentWithoutScore: PropTypes.bool,
  screen: PropTypes.instanceOf(Map),
  logMixpanelEvent: PropTypes.func.isRequired,
  nextScreen: PropTypes.func,
  numberOfQuestionsAnswered: PropTypes.number,
  submitAssessment: PropTypes.func
}

export default AssessmentContentScreen
