import PropTypes from 'prop-types'

import {TextGutterMedium} from '../../../blocks/Texts'
import UnorderedList from '../../../../../../shared_components//UnorderedList'

const ItemPermissionsExplanation = ({advisorFullName}) => (
  <div>
    <TextGutterMedium>
      By giving permision for a section, you allow {advisorFullName} to do the following on your behalf:
    </TextGutterMedium>
    <UnorderedList
      children={
        [
          'Edit, view, and download the contents of that section',
          'Add, remove and download attached documents within that section'
        ]
      }
    />
  </div>
)

ItemPermissionsExplanation.propTypes = {advisorFullName: PropTypes.string}

export default ItemPermissionsExplanation
