import {Map} from 'immutable'
import PropTypes from 'prop-types'

import NextBestActionGapFillerGuide from './gap_fillers/NextBestActionGapFillerGuide'
import NextBestActionWizardContainer from './wizards/NextBestActionWizardContainer'

const NextBestActionModalController = props => {
  if (props.showModal) {
    // To account for already existing NBAs that won't have a type and should go to the item form
    switch (props.nextBestAction.getIn(['data', 'type'], '')) {
      case 'gap-filler':
        return <NextBestActionGapFillerGuide {...props} />
      case 'wizard':
        return <NextBestActionWizardContainer eventType='wizard' {...props} />
      default:
        return null
    }
  } else {
    return null
  }
}

NextBestActionModalController.propTypes = {
  showModal: PropTypes.bool,
  nextBestAction: PropTypes.instanceOf(Map)
}

export default NextBestActionModalController

