import {Component} from 'react'
import PropTypes from 'prop-types'

import PhoneEditForm from '../shared/PhoneEditForm'
import WizardActions from '../../../actions/WizardActions'

import '../tfaSetup.scss'

export default class PhoneEditor extends Component {
  handleSubmit(event) {
    this.props.handleSubmit(event)
  }

  render() {
    return (
      <div className='wizard-body phone-editor'>
        <h3 className='phone-text'>{this.props.header}</h3>
        <hr />
        <p className='phone-text'>
          Cellphones are best because we can text you verification codes, but we can also place a voice call to any number you choose.
        </p>
        <form onSubmit={this.handleSubmit} onFocus={WizardActions.clearTfaErrors}>
          <PhoneEditForm
            className='phone-text'
            setFormValue={this.props.setFormValue}
            setMode={this.props.setMode}
            number={this.props.number}
            mode={this.props.mode}
            error={this.props.error}
          />
        </form>
      </div>
    )
  }
}

PhoneEditor.propTypes = {
  error: PropTypes.string,
  handleSubmit: PropTypes.func,
  header: PropTypes.string,
  mode: PropTypes.string,
  number: PropTypes.string,
  setFormValue: PropTypes.func,
  setMode: PropTypes.func
}
