import PropTypes from 'prop-types'
import {List, Map} from 'immutable'

import {TextGutterMedium} from '../../blocks/Texts'
import BillingHistoryRow from './BillingHistoryRow'

import {monthDayYear} from '../../../../../lib/dateTools'
import {centToDollars} from '../../../../../lib/subscriptionTools'

import './billingHistory.scss'

const BillingHistory = ({invoices, plans}) => {
  // Handle when there are no invoices or when there is one and the total is $0.
  const notYetBilled = invoices.size < 2 && invoices.getIn([0, 'total'], 0) === 0

  const transaction = invoice => {
    const startDate = monthDayYear(invoice.get('subscription-period-start'))
    const endDate = monthDayYear(invoice.get('subscription-period-end'))
    const invoicePlan = plans.find(plan => parseInt(plan.get('id'), 10) === invoice.get('plan-id'), null, Map())

    return `${invoicePlan.get('name', 'Everplans Annual Subscription')} ${startDate} - ${endDate}`
  }

  return (
    <div className='section-container billing-history'>
      <h2>Billing History</h2>
      {notYetBilled ?
        <div>
          <TextGutterMedium>
            We have not billed your credit card yet.
          </TextGutterMedium>
        </div> :
        <div>
          <BillingHistoryRow
            data={List(['Date', 'Amount', 'Transaction'])}
            header
          />
          {invoices.valueSeq().map(invoice => (
            <BillingHistoryRow
              key={invoice.get('subscription-period-start')}
              data={List([
                monthDayYear(invoice.get('paid-at')),
                centToDollars({number: invoice.get('total')}),
                transaction(invoice)
              ])}
            />
          ))}
        </div>
      }
    </div>
  )
}

BillingHistory.propTypes = {
  invoices: PropTypes.instanceOf(List),
  plans: PropTypes.instanceOf(List)
}

export default BillingHistory
