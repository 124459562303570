import PropTypes from 'prop-types'

import {Button, Stack, Typography} from '@mui/material'

import './addDeputySection.scss'

const AddDeputySection = ({openAddDeputyModal}) => (
  <Stack
    className='add-deputy-section'
    justifyContent='space-between'
    spacing={1}>
    <Typography className='body-text'>
      Add someone new to your Everplan
    </Typography>
    <Button className='invite' variant='contained' size='medium' onClick={openAddDeputyModal}>
      Invite now
    </Button>
  </Stack>
)

AddDeputySection.propTypes = {
  openAddDeputyModal: PropTypes.func
}

export default AddDeputySection
