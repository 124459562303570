import React from 'react' // do not remove because transpiled JS will break for PDF - TK
import PropTypes from 'prop-types'
import Showdown from 'showdown'


export default class MarkdownText extends React.Component {
  constructor() {
    super()

    this.converter = new Showdown.Converter()
    // Takes all features of Github Markdown
    this.converter.setFlavor('github')
  }

  render() {
    if (this.props.displayText) {
      return (
        <div
          className='markdown-text'
          dangerouslySetInnerHTML={{__html: this.converter.makeHtml(this.props.displayText)}}
        />
      )
    } else {
      return null
    }
  }
}

MarkdownText.propTypes = {
  displayText: PropTypes.string
}
