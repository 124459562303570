import {fromJS, Map} from 'immutable'
import {checkboxValues} from '../../../../lib/tools'

export const ANSWERS_SELECT_VALUE_MAP = Map({
  'Yes, I like my family to be able to visit me': 'I prefer to be buried',
  "I'm open to it if it's done in a certain way": 'I prefer to be buried',
  "No, I'd prefer to be cremated": 'I prefer to be cremated',
  'Safely buried in the ground': 'Buried in the ground',
  'Scattered at my favorite place on earth': 'Scattered at my favorite place',
  'Preserved in a nice urn for my family to display': 'Returned to my family',
  'Get creative with them (space, fireworks, reef, diamond)': 'Something creative',
  other: 'Other',
  'A grand affair with every possible funeral event you can imagine': 'A grand affair',
  'Something small or solo (like a harp or organ)': 'A small band or a solo musician',
  'Somewhere spiritual (church, synagogue, temple, mosque)': 'A place of worship',
  'Out in nature (mountains, ocean, beach, park)': 'An outdoor location',
  "I'm good with a funeral home": 'At a funeral home',
  'Modern or unusual (public space,  sporting event, museum)': 'A public space',
  "Somewhere private (a person's home, undisclosed location)": 'At home',
  'Something respectful (like a nice mid-range car)': 'Something respectful',
  'Something epic (think King Tut, only bigger!)': 'Something epic',
  'A standard headstone / gravestone is fine with me': 'A standard headstone is fine with me',
  'An intimate ceremony with a personal touch': 'An intimate ceremony',
  'Silence is music to my ears': 'I prefer no music',
  'A Spotify playlist of my favorites': 'My favorite Spotify playlist',
  'I want it as low-key as possible': 'A low-key celebration',
  'I want to mark the grave with natural elements': 'Natural elements or no headstone'
})

const basicMemorialPackage = {
  text: 'Basic memorial printed package',
  cost: 175
}

const basicDispositionPackage = [
  {
    text: 'Basic Service Fee',
    cost: 2195
  },
  {
    text: 'Transfer of the body',
    cost: 350
  }
]

const casketPackage = {
  text: 'Vault',
  cost: 1495
}

const funeralExpenseCalculatorConfiguration = {
  celebration: {
    memorial: [basicMemorialPackage],
    funeral: [
      basicMemorialPackage,
      {
        text: 'Use of facilities for funeral ceremony',
        cost: 500
      },
      {
        text: 'Removal/transfer of remains to funeral home',
        cost: 350
      }
    ]
  },
  disposition: {
    burial: [
      ...basicDispositionPackage,
      {
        text: 'Plot',
        cost: 2000
      },
      {
        text: 'Endowment care',
        cost: 200
      },
      {
        text: 'Opening and closing',
        cost: 1500
      }
    ],
    cremation: [
      ...basicDispositionPackage,
      {
        text: 'Cremation fee',
        cost: 350
      },
      {
        text: 'Cremation casket',
        cost: 1200
      }
    ]
  },
  service: {
    'funeral-home': [
      {
        text: 'Service venue',
        cost: 500
      }
    ],
    'place-of-worship': [
      {
        text: 'Service venue',
        cost: 1000
      }
    ],
    'public-space': [
      {
        text: 'Service venue',
        cost: 5000
      }
    ],
    'park-or-outdoor': []
  },
  'final-ceremony': {
    returned: [
      {
        text: 'Urn',
        cost: 295
      }
    ],
    buried: [
      {
        text: 'Inurnment',
        cost: 1200
      }
    ],
    simple: [
      {
        text: 'Headstone',
        cost: 500
      }
    ],
    standard: [
      {
        text: 'Headstone',
        cost: 2000
      }
    ],
    'high-end': [
      {
        text: 'Headstone',
        cost: 4000
      }
    ],
    'something-creative': [
      {
        text: 'Creative ash preservation',
        cost: 3000
      }
    ]
  },
  'additional-expenses': {
    viewing: [
      {
        text: 'Embalming',
        cost: 750
      },
      {
        text: 'Other preparation of the body',
        cost: 255
      },
      {
        text: 'Use of facilities for viewing',
        cost: 425
      }
    ],
    reception: [
      {
        text: 'Reception venue and staff',
        cost: 1000
      },
      {
        text: 'Reception food and drinks',
        cost: 500
      }
    ],
    certificate: [
      {
        text: 'Death certificates',
        cost: 100
      }
    ],
    notices: [
      {
        text: 'Obituary',
        cost: 350
      }
    ],
    flowers: [
      {
        text: 'Flowers',
        cost: 300
      }
    ],
    book: [
      {
        text: 'Stationery',
        cost: 175
      }
    ],
    donation: [
      {
        text: 'Officiant',
        cost: 400
      }
    ],
    hearse: [
      {
        text: 'Hearse',
        cost: 340
      }
    ],
    car: [
      {
        text: 'Limousine / car service',
        cost: 150
      }
    ],
    expenses: [
      {
        text: 'Family Travel Expenses',
        cost: 2000
      }
    ],
    transportation: [
      {
        text: 'Air transportation of the body',
        cost: 3000
      }
    ]
  },
  casket: {
    economy: [
      casketPackage,
      {
        text: 'Casket',
        cost: 1000
      }
    ],
    medium: [
      casketPackage,
      {
        text: 'Casket',
        cost: 2000
      }
    ],
    high: [
      casketPackage,
      {
        text: 'Casket',
        cost: 10000
      }
    ],
    shroud: [
      {
        text: 'Casket',
        cost: 900
      }
    ]
  },
  music: {
    'big-band': [
      {
        text: 'A big band',
        cost: 1000
      }
    ],
    small: [
      {
        text: 'A small band or a solo musician',
        cost: 250
      }
    ]
  }
}

export const checkboxConfiguration = (data = Map()) => fromJS({
  id: 'additional-expenses',
  name: 'additional-expenses',
  items: [
    {
      id: 'viewing',
      label: 'Viewing or visitation',
      value: 'Viewing or visitation'
    },
    {
      id: 'reception',
      label: 'Post ceremony reception',
      value: 'Post ceremony reception'
    },
    {
      id: 'certificate',
      label: 'Death certificates',
      value: 'Death certificates'
    },
    {
      id: 'notices',
      label: 'Obituary',
      value: 'Obituary'
    },
    {
      id: 'flowers',
      label: 'Flowers',
      value: 'Flowers'
    },
    {
      id: 'book',
      label: 'Stationery (Guest Book, Cards)',
      value: 'Stationery (Guest Book, Cards)'
    },
    {
      id: 'donation',
      label: 'Officiant (Either Donation or Payment)',
      value: 'Officiant (Either Donation or Payment)'
    },
    {
      id: 'hearse',
      label: 'Hearse',
      value: 'Hearse'
    },
    {
      id: 'car',
      label: 'Limousine / car service',
      value: 'Limousine / car service'
    },
    {
      id: 'transportation',
      label: 'Air transportation of the body',
      value: 'Air transportation of the body'
    }
  ],
  value: checkboxValues(data)
})

export const cremationDropdown = (data = Map()) => fromJS([
  {
    id: 'final-ceremony',
    name: 'final-ceremony',
    items: [
      {
        id: 'buried',
        label: 'Buried in the ground',
        value: 'Buried in the ground'
      },
      {
        id: 'scattered',
        label: 'Scattered at my favorite place',
        value: 'Scattered at my favorite place'
      },
      {
        id: 'returned',
        label: 'Returned to my family',
        value: 'Returned to my family'
      },
      {
        id: 'something-creative',
        label: 'Something creative',
        value: 'Something creative'
      }
    ],
    label: 'What you want done with the ashes:',
    value: data.get('ashes', 'Scattered at my favorite place')
  }
])

export const burialDropdown = (data = Map()) => fromJS([
  {
    id: 'casket',
    name: 'casket',
    items: [
      {
        id: 'economy',
        label: 'Any container will do',
        value: 'Any container will do'
      },
      {
        id: 'shroud',
        label: 'A shroud or a casket made of natural materials',
        value: 'A shroud or a casket made of natural materials'
      },
      {
        id: 'medium',
        label: 'Something respectful',
        value: 'Something respectful'
      },
      {
        id: 'high',
        label: 'Something epic',
        value: 'Something epic'
      }
    ],
    label: 'Type of casket:',
    value: data.get('casket', 'Any container will do')
  },
  {
    id: 'final-ceremony',
    name: 'final-ceremony',
    items: [
      {
        id: 'simple',
        label: "Something that doesn't stand out too much",
        value: "Something that doesn't stand out too much"
      },
      {
        id: 'standard',
        label: 'A standard headstone is fine with me',
        value: 'A standard headstone is fine with me'
      },
      {
        id: 'high-end',
        label: 'I want people to see it from space',
        value: 'I want people to see it from space'
      },
      {
        id: 'natural',
        label: 'Natural elements or no headstone',
        value: 'Natural elements or no headstone'
      }
    ],
    label: 'Type of headstone:',
    value: data.get('headstone', 'A standard headstone is fine with me')
  }
])

export const dropdownConfiguration = (data = Map()) => fromJS([
  {
    id: 'celebration',
    items: [
      {
        id: 'grand-affair',
        label: 'A grand affair',
        value: 'A grand affair'
      },
      {
        id: 'intimate-ceremony-with-personal-touch',
        label: 'An intimate ceremony',
        value: 'An intimate ceremony'
      },
      {
        id: 'low-key',
        label: 'A low-key celebration',
        value: 'A low-key celebration'
      }
    ],
    label: 'Type of service:',
    name: 'service',
    value: data.get('celebration', 'A grand affair')
  },
  {
    id: 'service',
    items: [
      {
        id: 'place-of-worship',
        label: 'A place of worship',
        value: 'A place of worship'
      },
      {
        id: 'outdoor',
        label: 'An outdoor location',
        value: 'An outdoor location'
      },
      {
        id: 'funeral-home',
        label: 'At a funeral home',
        value: 'At a funeral home'
      },
      {
        id: 'public-space',
        label: 'A public space',
        value: 'A public space'
      },
      {
        id: 'home',
        label: 'At home',
        value: 'At home'
      }
    ],
    label: 'Venue:',
    name: 'venue',
    value: data.get('service', 'A place of worship')
  },
  {
    id: 'music',
    items: [
      {
        id: 'playlist',
        label: 'My favorite Spotify playlist',
        value: 'My favorite Spotify playlist'
      },
      {
        id: 'small',
        label: 'A small band or a solo musician',
        value: 'A small band or a solo musician'
      },
      {
        id: 'big-band',
        label: 'A big band',
        value: 'A big band'
      },
      {
        id: 'silence',
        label: 'I prefer no music',
        value: 'I prefer no music'
      }
    ],
    label: 'Type of music:',
    name: 'music',
    value: data.get('music', 'A small band or a solo musician')
  },
  {
    id: 'disposition',
    items: [
      {
        id: 'burial',
        label: 'I prefer to be buried',
        value: 'I prefer to be buried'
      },
      {
        id: 'cremation',
        label: 'I prefer to be cremated',
        value: 'I prefer to be cremated'
      }
    ],
    label: 'Burial or cremation?',
    name: 'disposition',
    value: data.get('disposition', 'I prefer to be buried')
  }
])

export default fromJS(funeralExpenseCalculatorConfiguration)
