import classnames from 'classnames'
import PropTypes from 'prop-types'
import {useMemo, useState, useEffect} from 'react'
import {connect} from 'react-redux'
import {List, Map} from 'immutable'
import {updateResource, readEndpoint} from 'redux-json-api'

import {ModalHeaderWithChildren} from '../../../../../shared_components/core/modals/ModalHeaders'
import Closer from '../../../../../shared_components/core/closer/Closer'
import HouseholdAssignmentRadioListForm from './HouseholdAssignmentRadioListForm'
import ItemCloser from './ItemCloser'
import ItemNavElements from './ItemNavElements'
import Modals from '../../../../../shared_components/core/modals/Modals'
import PremiumBanner from '../../../../../shared_components/PremiumBanner'

import {currentUserConfig, currentActor} from '../../../../../lib/userTools'
import {isCompoundItemResponse, firstTopKitParentElementId, isEditableItemResponse, secondResponseGroupsByElementId, itemResponseGroupByGroupId} from '../../../../../lib/plan_data/itemResponsesHelper'
import {isOwnEverplan, findRawResourceByAttributeId} from '../../../../../lib/plan_data/userData'
import {elementFieldsViewData} from '../../../../../lib/viewTools'
import {routeContainsVerb} from '../../../lib/corpMaxRouteMatcher'
import {usePrevious} from '../../../../../lib/hooks'
import Logger from '../../../../../lib/NewLogger'
import routerUtils from '../../../../../lib/routerUtils'

import '../../../../../dev_only/forms_playground/formsPlayground.scss'
import './item.scss'

const Item = props => {
  const paramElementId = props.params.elementId
  const prevParamElementId = usePrevious(paramElementId)
  const topKitElements = useMemo(() => props.itemResponse.get('top-kits').map(element => element.merge(elementFieldsViewData({views: props.views, elementId: element.get('parent-element-id')}))), [])
  const hasMultipleFieldGroups = useMemo(() => isCompoundItemResponse(props.itemResponse), [])
  const actor = useMemo(() => currentActor({ownEverplan: props.ownEverplan, context: props.context}), [])

  const [currentTabId, setCurrentTabId] = useState(paramElementId || firstTopKitParentElementId(props.itemResponse))
  const [hasChangedItemFormData, setHasChangedItemFormData] = useState(false)
  const [initialGroupId, setInitialGroupId] = useState(null)
  const [currentElement, setCurrentElement] = useState(topKitElements.find(element => currentTabId === element.get('parent-element-id')))
  const [responseOwnershipIds, setResponseOwnershipIds] = useState(List())

  const groupId = initialGroupId || props.params.groupId || props.params.id
  const itemName = props.itemResponse.get('name')

  useEffect(() => {
    const elementIdChanged = paramElementId && (paramElementId !== prevParamElementId)

    if (elementIdChanged && routeContainsVerb(props.location, ['new', 'view'])) {
      setCurrentTabId(paramElementId)
      setCurrentElement(topKitElements.find(element => paramElementId === element.get('parent-element-id')))
    }
  }, [paramElementId, props.location])

  const logEvent = ({name, payload = {}}) => {
    Logger.log({
      name,
      payload: {
        actor,
        context: props.context,
        everplan_id: props.itemResponse.get('everplan-id'),
        type: props.eventType,
        item: itemName,
        wildcard: itemName,
        ...payload
      }
    })
  }

  const exitViewItem = () => {
    // This is to make sure that the event is not logged when closing a compound item where data was just added
    // since the url changes from new to view for compound items after something is added.
    if (initialGroupId)
      logEvent({name: 'closed_response_group'})

    props.exit()
  }

  const showPremiumBanner = routeContainsVerb(props.location, ['view']) &&
    props.ownEverplan && props.hideEditFeaturesForUser

  const closer = () => {
    // No need to go to the item closer component (which is used by a few different components and is already complex) when an item is
    // being viewed, it seemed to make more sense to just log the event here.
    if (routeContainsVerb(props.location, ['edit', 'add-more', 'new'])) {
      return (
        <ItemCloser
          confirmModalExit={hasChangedItemFormData}
          exit={props.exit}
          itemName={itemName}
          context={props.context}
          eventType={props.eventType}
          compoundItem={hasMultipleFieldGroups}
        />
      )
    } else {
      return (
        <Closer
          className={showPremiumBanner ? 'with-premium-banner' : ''}
          closer={exitViewItem}
        />
      )
    }
  }

  // determines which route to go after a nav tab is clicked
  const nextRoute = elementId => {
    const pathParams = {elementId, groupId}

    return routeContainsVerb(props.location, ['edit', 'view', 'add-more']) ? props.viewItemPath(pathParams) : props.newItemPath(pathParams)
  }

  const setCurrentTab = elementId => { routerUtils.push(nextRoute(elementId)) }

  const expandForm = () => {
    // Prevent this method from submitting the form:
    if (event)
      event.preventDefault()

    logEvent({name: 'clicked_add_new_compound_group', payload: {element_id: currentTabId, wildcard: currentTabId}})
    routerUtils.push(props.newCompoundItemPath({elementId: currentTabId, groupId}))
  }

  const sharedProps = {
    ...props,
    actor,
    hasChangedItemFormData,
    currentElement,
    currentTabId,
    hasMultipleFieldGroups,
    initialGroupId,
    responseOwnershipIds,
    isEditableItem: isEditableItemResponse({
      ownEverplan: props.ownEverplan,
      firmOwnership: props.firmOwnership,
      itemResponse: props.itemResponse,
      context: props.context
    }),
    hidePremiumFeaturesForUser: props.hidePremiumFeaturesForUser,
    hideEditFeaturesForUser: props.hideEditFeaturesForUser
  }


  const itemResponseGroupKeyField = itemResponseGroup => {
    const responseGroupKeyField = itemResponseGroup.get('key-field', List())

    return responseGroupKeyField.isEmpty() ? List([props.itemResponse.get('header')]) : responseGroupKeyField
  }

  // hideItem is set on closing or deleting a response group and is needed to stop the flickering of an empty item
  // when deleting a response group.
  if (props.hideItem || !props.itemResponse) // itemResponse will only be empty in the case of a bad slug in the URL
    return null

  const responseGroup = itemResponseGroupByGroupId({itemResponse: props.itemResponse, groupId})
  const keyField = itemResponseGroupKeyField(responseGroup)

  return (
    <div>
      <Modals.FullHeightModalLarge className={classnames('item', 'forms-playground', {'with-nav': topKitElements.size > 1})} closerComponent={closer} showModal={true}>
        {showPremiumBanner && <PremiumBanner includeLinkToPremiumUpgrade showEditText itemName={itemName} context={props.context} />}
        <ModalHeaderWithChildren>
          <h2>{keyField}</h2>
          <HouseholdAssignmentRadioListForm
            {...sharedProps}
            responseGroup={responseGroup}
            updateResponseOwnershipIds={setResponseOwnershipIds}
          />
        </ModalHeaderWithChildren>
        <div className='item-body'>
          {
            hasMultipleFieldGroups &&
              <ItemNavElements
                {...sharedProps}
                elements={topKitElements}
                keyField={keyField}
                responseGroup={responseGroup}
                setCurrentTab={setCurrentTab}
              />
          }
          {
            routerUtils.childrenWithProps(
              props.children,
              {
                ...sharedProps,
                responsesGroupedBySecondGroupId: secondResponseGroupsByElementId({responseGroup, elementId: currentTabId}),
                className: {'compound-items': topKitElements.size > 1},
                responseGroup,
                expandForm,
                keyField,
                setInitialGroupId,
                setHasChangedItemFormData
              }
            )
          }
        </div>
      </Modals.FullHeightModalLarge>
    </div>
  )
}


Item.propTypes = {
  addMorePath: PropTypes.func,
  conditionalValue: PropTypes.string,
  context: PropTypes.string,
  eventType: PropTypes.string,
  ownEverplan: PropTypes.bool,
  editCompoundItemPath: PropTypes.func,
  editItemPath: PropTypes.func,
  exit: PropTypes.func,
  firmConfig: PropTypes.instanceOf(Map),
  firmOwnership: PropTypes.instanceOf(Map),
  hideItem: PropTypes.bool,
  items: PropTypes.instanceOf(List),
  itemResponse: PropTypes.instanceOf(Map),
  listMappings: PropTypes.instanceOf(Map),
  location: PropTypes.shape({
    pathname: PropTypes.string
  }),
  newCompoundItemPath: PropTypes.func,
  newContacts: PropTypes.instanceOf(List),
  newItemPath: PropTypes.func,
  ownerships: PropTypes.instanceOf(List),
  params: PropTypes.shape({
    elementId: PropTypes.string,
    everplanId: PropTypes.string,
    groupId: PropTypes.string,
    id: PropTypes.string,
    itemSlug: PropTypes.string,
    slug: PropTypes.string
  }),
  updateResource: PropTypes.func,
  userConfig: PropTypes.instanceOf(Map),
  viewItemPath: PropTypes.func,
  views: PropTypes.instanceOf(List),
  hidePremiumFeaturesForUser: PropTypes.bool,
  hideEditFeaturesForUser: PropTypes.bool
}

const mapStateToProps = (state, ownParams) => {
  const userConfig = currentUserConfig(state.api)
  const everplanId = ownParams.params.everplanId || userConfig.get('everplan-id')
  return {
    firmOwnership: findRawResourceByAttributeId({
      rawResource: state.api['firm-ownerships'],
      attribute: 'everplan-id',
      id: everplanId
    }),
    items: state.items,
    listMappings: state.listMappings,
    userConfig,
    categories: state.categories,
    views: state.views,
    ownEverplan: isOwnEverplan({everplanPreviewId: everplanId, owners: state.owners})
  }
}

export default connect(mapStateToProps, {updateResource, readEndpoint})(Item)
