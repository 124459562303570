import PropTypes from 'prop-types'

import CategoryHeaderIcon from '../../shared/CategoryHeaderIcon'

import './categoryHeader.scss'


const CategoryHeader = ({categoryName}) => (
  <header className='category-header flex-container'>
    <CategoryHeaderIcon categoryName={categoryName} />
    <h5>{categoryName}</h5>
  </header>
)

CategoryHeader.propTypes = {
  categoryName: PropTypes.string,
  isHousehold: PropTypes.bool
}

export default CategoryHeader
