import {List, Map} from 'immutable'
import {useState} from 'react'
import PropTypes from 'prop-types'

import DashboardHeading from '../../../../../shared_components/mui_base_components/dashboard_heading/DashboardHeading'
import ItemWithResponsesBox from './ItemWithResponsesBox'
import AddDeputyModal from '../../corp_max_deputies/add/AddDeputyModal'

import {useDeputyData} from '../../../../../lib/hooks'
import {itemResponsesWithResponseGroupMap} from '../../../../../lib/plan_data/itemResponsesHelper'

const ItemsWithResponses = props => {
  const [showAddDeputyModal, setShowAddDeputyModal] = useState(false)

  const {deputies} = useDeputyData(props.userConfig.get('everplan-id'))

  const itemResponses = itemResponsesWithResponseGroupMap(props.categoryItemResponses)

  if (itemResponses.isEmpty()) {
    return null
  } else {
    return (
      <div className='items-with-responses'>
        <DashboardHeading>In your Everplan</DashboardHeading>
        {
          itemResponses
            .map(itemResponse =>
              (
                <ItemWithResponsesBox
                  {...props}
                  openAddDeputyModal={() => setShowAddDeputyModal(true)}
                  itemResponse={itemResponse}
                  key={itemResponse.get('id')}
                />
              )
            )
        }
        {
          showAddDeputyModal && (
            <AddDeputyModal {...props} deputies={deputies} exit={() => setShowAddDeputyModal(false)} />
          )
        }

      </div>
    )
  }
}

ItemsWithResponses.propTypes = {
  categoryItemResponses: PropTypes.instanceOf(List),
  userConfig: PropTypes.instanceOf(Map)
}

export default ItemsWithResponses
