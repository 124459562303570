import KeyMirror from 'keymirror'

import AppDispatcher from '../../../lib/ep-dispatcher'

const TfaApiResponse = {
  Types: KeyMirror({
    WEB_TFA_CONFIG_FETCHED: null,
    WEB_TFA_CONFIG_FETCH_FAILED: null,
    WEB_PRIMARY_PHONE_ACCEPTED: null,
    WEB_PRIMARY_PHONE_REJECTED: null,
    WEB_TFA_PHONE_CONFIRMED: null,
    WEB_TFA_CONFIRMATION_CODE_REJECTED: null,
    WEB_TFA_BACKUP_CODE_GENERATED: null,
    WEB_TFA_ENABLED: null,
    WEB_TFA_DISABLED: null,
    WEB_PHONE_EDIT_ACCEPTED: null,
    WEB_PHONE_EDIT_REJECTED: null,
    WEB_PHONE_EDIT_CONFIRMED: null
  }),

  tfaConfigFetched(tfaConfig) {
    AppDispatcher.dispatch({
      actionType: this.Types.WEB_TFA_CONFIG_FETCHED,
      tfaConfig
    })
  },

  tfaConfigFetchFailed(error) {
    AppDispatcher.dispatch({
      actionType: this.Types.WEB_TFA_CONFIG_FETCH_FAILED,
      error
    })
  },

  tfaPhoneConfirmed(tfaPhoneNumber) {
    AppDispatcher.dispatch({
      actionType: this.Types.WEB_TFA_PHONE_CONFIRMED,
      tfaPhoneNumber
    })
  },

  tfaConfirmationCodeRejected(error) {
    AppDispatcher.dispatch({
      actionType: this.Types.WEB_TFA_CONFIRMATION_CODE_REJECTED,
      error
    })
  },

  tfaBackupCodeGenerated(backupCode) {
    AppDispatcher.dispatch({
      actionType: this.Types.WEB_TFA_BACKUP_CODE_GENERATED,
      backupCode
    })
  },

  tfaEnabled(tfaConfig) {
    AppDispatcher.dispatch({
      actionType: this.Types.WEB_TFA_ENABLED,
      tfaConfig
    })
  },

  tfaDisabled(tfaConfig) {
    AppDispatcher.dispatch({
      actionType: this.Types.WEB_TFA_DISABLED,
      tfaConfig
    })
  },

  primaryPhoneAccepted(tfaPhoneNumber) {
    AppDispatcher.dispatch({
      actionType: this.Types.WEB_PRIMARY_PHONE_ACCEPTED,
      tfaPhoneNumber
    })
  },

  phoneEditAccepted(tfaPhoneNumber) {
    AppDispatcher.dispatch({
      actionType: this.Types.WEB_PHONE_EDIT_ACCEPTED,
      tfaPhoneNumber
    })
  },

  phoneEditRejected(error) {
    AppDispatcher.dispatch({
      actionType: this.Types.WEB_PHONE_EDIT_REJECTED,
      error
    })
  },

  tfaPhoneEditConfirmed(tfaPhoneNumber) {
    AppDispatcher.dispatch({
      tfaPhoneNumber,
      actionType: this.Types.WEB_PHONE_EDIT_CONFIRMED
    })
  }
}

export default TfaApiResponse
