import {Component} from 'react'
import PropTypes from 'prop-types'
import {Map, List, fromJS} from 'immutable'

import {Button} from '../../../blocks/Buttons'
import {findSelectedValueId} from '../../../../../../shared_components/forms/v2/lib/tools'
import Form from '../../../../../../shared_components/forms/v2/Form'
import Fields from '../../../../../../shared_components/forms/v2/fields/index'
import validators from '../../../../../../shared_components/forms/v2/validators'

export default class VersionForm extends Component {
  constructor() {
    super()

    this.submit = this.submit.bind(this)
  }

  submit() {
    const form = this.refs.form
    form.onSubmit()

    if (form.isValid()) {
      const formValues = form.formData().map(formData => formData.get('value'))

      if (this.props.version.isEmpty())
        this.props.submitVersion(formValues.set('source-version-id', findSelectedValueId({selectId: 'source-version-id', form})))
      else
        this.props.submitVersion(formValues)
    }
  }

  versionItems() {
    return this.props.versions
      .map(version => Map(
        {
          id: version.get('id'),
          label: version.get('name'),
          value: version.get('name')
        }
      ))
      .unshift(Map({label: 'select version', value: ''}))
  }


  render() {
    if (this.props.showModal) {
      return (
        <div className='forms-playground'>
          <Form className='core' ref='form'>
            <Fields.Text
              data={
                Map({
                  id: 'name',
                  legend: 'Name:',
                  name: 'name',
                  value: this.props.version.get('name')
                })
              }
              validator={validators.required}
            />
            {
              this.props.version.isEmpty() ?
                (
                  <Fields.Select
                    data={
                      Map({
                        id: 'source-version-id',
                        items: this.versionItems(),
                        legend: 'Select which version to clone from:',
                        name: 'source-version-id'
                      })
                    }
                    validator={validators.required}
                  />
                ) :
                (
                  <Fields.InlineRadioList
                    data={
                      fromJS({
                        id: 'locked',
                        items: [
                          {label: 'locked', value: 'true'},
                          {label: 'unlocked', value: 'false'}
                        ],
                        legend: 'Locked:',
                        name: 'locked',
                        value: this.props.version.get('locked').toString()
                      })
                    }
                  />
                )
            }
            <Fields.TextArea
              data={
                Map({
                  id: 'description',
                  legend: 'Description:',
                  name: 'description',
                  value: this.props.version.get('description')
                })
              }
            />
            <Button onClick={this.submit} processing={this.props.processing}>
              Save
            </Button>
          </Form>
        </div>
      )
    } else {
      return null
    }
  }
}


VersionForm.propTypes = {
  processing: PropTypes.bool,
  showModal: PropTypes.bool,
  submitVersion: PropTypes.func,
  version: PropTypes.instanceOf(Map),
  versions: PropTypes.instanceOf(List)
}
