import {List, Map} from 'immutable'
import {useState} from 'react'
import PropTypes from 'prop-types'

import AddDeputyModal from '../corp_max_deputies/add/AddDeputyModal'
import DashboardHeading from '../../../../shared_components/mui_base_components/dashboard_heading/DashboardHeading'
import ManagePermissions from '../corp_max_deputies/permissions/ManagePermissions'
import MixpanelLogger from '../../../../lib/MixpanelLogger'
import ModuleBox from '../../../../shared_components/component_templates/ModuleBox'
import SharingModuleWithDeputies from './with_deputies/SharingModuleWithDeputies'
import SharingModuleWithoutDeputies from './SharingModuleWithoutDeputies'

import {useDeputizerData, useDeputyData} from '../../../../lib/hooks'
import Logger from '../../../../lib/NewLogger'

import './sharingModuleController.scss'


const SharingModuleController = props => {
  const [showAddDeputyModal, setShowAddDeputyModal] = useState(false)
  const [showDeputyPermissionsModal, setShowDeputyPermissionsModal] = useState(false)
  const [manageDeputyOwnershipId, setManageDeputyOwnershipId] = useState()

  const everplanId = props.userConfig.get('everplan-id')

  const {deputies} = useDeputyData(everplanId)
  const {deputizers} = useDeputizerData(props.userConfig)

  const toggleDeputyPermissionsModal = deputyId => {
    setShowDeputyPermissionsModal(prevShowDeputyPermissionsModal => !prevShowDeputyPermissionsModal)
    setManageDeputyOwnershipId(deputyId)
  }

  const openAddDeputyModal = () => {
    MixpanelLogger.track('click_add_deputy', {
      context: props.context,
      deputy_count: deputies.size
    })

    Logger.log({
      name: 'clicked_add_new_deputy',
      payload: {context: props.context}
    })

    setShowAddDeputyModal(true)
  }

  return (
    <div className='sharing-module-controller'>
      <DashboardHeading>Your network</DashboardHeading>
      <ModuleBox className='module-box'>
        {
          deputies.isEmpty() && deputizers.isEmpty() ?
            <SharingModuleWithoutDeputies openAddDeputyModal={openAddDeputyModal} /> :
            <SharingModuleWithDeputies
              deputizers={deputizers}
              deputies={deputies}
              openAddDeputyModal={openAddDeputyModal}
              toggleDeputyPermissionsModal={toggleDeputyPermissionsModal}
            />
        }
        {
          showAddDeputyModal && (
            <AddDeputyModal {...props} deputies={deputies} exit={() => setShowAddDeputyModal(false)} />
          )
        }
        {
          showDeputyPermissionsModal && (
            <ManagePermissions
              {...props}
              exit={toggleDeputyPermissionsModal}
              deputies={deputies}
              deputyOwnershipId={manageDeputyOwnershipId}
            />
          )
        }
      </ModuleBox>
    </div>
  )
}

SharingModuleController.propTypes = {
  context: PropTypes.string.isRequired,
  itemResponses: PropTypes.instanceOf(List),
  userConfig: PropTypes.instanceOf(Map)
}

export default SharingModuleController
