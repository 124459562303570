import {useCallback, useEffect} from 'react'
import PropTypes from 'prop-types'

import MixpanelLogger from '../../../../lib/MixpanelLogger'
import {Text} from '../blocks/Texts'
import WarningIconCircle from '../../../assets/WarningIconCircle'
import BannerLink from './BannerLink'
import CloseIcon from '../../../../shared_components/core/svg_icons/icons/CloseIcon'

import './mobileAppDownloadBanner.scss'

const AppStoreId = '1590237847'

const MobileAppDownloadBanner = props => {
  useEffect(() => {
    MixpanelLogger.track('view_mobile_download_banner')
  }, [])

  const handleClick = useCallback(() =>
    MixpanelLogger.track('click_mobile_download_banner')
  )

  return (
    <div className='mobile-app-download-banner feedback-banner flex-container'>
      <WarningIconCircle className='warning' alt='warning' />
      <Text className='message'>
        Download our new iOS app today for the best Everplans experience!&nbsp;&nbsp;
        <BannerLink href={`https://apps.apple.com/us/app/everplans/${AppStoreId}`} onClick={handleClick}>Download now.</BannerLink>
      </Text>
      <CloseIcon className='banner-closer' onClick={props.dismissMobileHeader} />
    </div>
  )
}

MobileAppDownloadBanner.propTypes = {
  dismissMobileHeader: PropTypes.func
}

export default MobileAppDownloadBanner
