import {Map} from 'immutable'
import PropTypes from 'prop-types'

import CategoryHeader from './CategoryHeader'
import CategoryItem from './CategoryItem'

import './category.scss'


const Category = props => (
  <div className='category everplan-preview'>
    <CategoryHeader categoryName={props.category.get('name')} />
    {
      props.category.get('items').map(
        itemResponse => (<CategoryItem {...props} itemResponse={itemResponse} key={itemResponse.get('id')} />)
      )
    }
  </div>
)

Category.propTypes = {
  category: PropTypes.instanceOf(Map),
  everplan: PropTypes.instanceOf(Map)
}

export default Category
