import {Fragment} from 'react'
import {Map, List} from 'immutable'
import PropTypes from 'prop-types'

import Version from './Version'
import {BoxGutterMedium} from '../../blocks/Boxes'
import {sortByDate} from '../../../../../lib/dateTools'

const Versions = props => (
  <Fragment>
    <BoxGutterMedium>
      <h3>Environment Version: {props.environmentVersion.get('name')}</h3>
    </BoxGutterMedium>
    <BoxGutterMedium>
      <h3>All Versions</h3>
      {
        sortByDate({resourceList: props.versions}).map(version => <Version {...props} key={version.get('id')} version={version} />)
      }
    </BoxGutterMedium>
  </Fragment>

)

Versions.propTypes = {
  showUpdateViewModal: PropTypes.func,
  environmentVersion: PropTypes.instanceOf(Map),
  versions: PropTypes.instanceOf(List)
}

export default Versions
