import {List, Map} from 'immutable'
import {useMutation} from '@apollo/client'
import {useState, useEffect} from 'react'
import PropTypes from 'prop-types'

import RemoveDeputyModal from './RemoveDeputyModal'

import {addPossessive} from '../../../../lib/tools'
import {DELETE_DEPUTY} from '../../../../graphql/mutations/deputy'
import {DEPUTIES, SPECIFIC_ITEMS_DEPUTIES} from '../../../../graphql/queries/deputy'
import {findResourceById} from '../../../../lib/plan_data/userData'
import {humanizedFullName} from '../../../../lib/nameTools'
import {useNotificationContext} from '../../../../shared_components/notifications/NotificationContext'


const RemoveDeputyController = props => {
  const deputyId = props.params.deputyOwnershipId

  const [deputy, setDeputy] = useState(findResourceById({resourceList: props.deputies, id: deputyId}))
  const [processing, setProcessing] = useState(false)
  const {alwaysNotify} = useNotificationContext()

  const [deleteDeputy] = useMutation(DELETE_DEPUTY, {
    update: cache => {
      const everplanId = props.userConfig.get('everplan-id')
      const {deputies} = cache.readQuery({query: DEPUTIES, variables: {everplanId}})

      const updatedDeputies = deputies.filter(deputyData => deputyData.id !== deputyId)

      cache.writeQuery({
        query: DEPUTIES,
        data: {deputies: updatedDeputies},
        variables: {everplanId}
      })
    },
    refetchQueries: [SPECIFIC_ITEMS_DEPUTIES]
  })

  useEffect(() => {
    setDeputy(findResourceById({resourceList: props.deputies, id: props.params.deputyOwnershipId}))
  }, [props.params.deputyOwnershipId])

  const deputyName = humanizedFullName(deputy.get('ownable'))
  const deputyOwnableType = deputy.get('ownable-type')

  const notifyDeputyRemoved = () => {
    if (deputyOwnableType === 'Invite')
      return alwaysNotify.shortSuccess(`${addPossessive(deputyName)} invitation will no longer be valid.`)
    else
      return alwaysNotify.shortSuccess(`${deputyName} is no longer your Deputy.`)
  }

  const removeDeputy = () => {
    setProcessing(true)

    deleteDeputy({
      variables: {id: deputyId},
      onCompleted: () => {
        notifyDeputyRemoved()
        props.exit()
      }
    })
  }

  return (
    <RemoveDeputyModal
      deputyName={deputyName}
      exit={props.exit}
      removeDeputy={removeDeputy}
      processing={processing}
    />
  )
}

RemoveDeputyController.propTypes = {
  deputies: PropTypes.instanceOf(List),
  exit: PropTypes.func,
  params: PropTypes.object,
  userConfig: PropTypes.instanceOf(Map)
}


export default RemoveDeputyController
