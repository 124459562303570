const SendEmailIcon = () => (
  <svg  className='icon' version="1.1" xmlns="http://www.w3.org/2000/svg" width="30" height="15" viewBox="0 0 30 30">
    <title>Send Email Icon</title>
    <path
      d="M23.1054949,27.7052766 L14.1924909,22.2202732 L27.546153,4.43943712 L23.1054949,27.7052766 Z M8.78906653,
      26.4879975 L8.78906653,19.8524616 L23.3465235,7.10457363 C23.0269499,7.52999765 9.16965585,
      25.9809946 8.78906653,26.4879975 L8.78906653,26.4879975 Z M7.86405583,18.3260591 L2.68278235,15.1374016 L23.3613561,
      4.75496555 L7.86405583,18.3260591 Z M28.717586,0.0988985735 L0.484533524,14.2740808 C0.199681468,
      14.4170124 0.0146119075,14.7032129 0.00079068349,15.0214382 C-0.0126934375,
      15.3400005 0.147093396,15.6410335 0.418461331,15.8078995 L7.03107426,
      19.8774072 L7.03107426,29.1214464 C7.03107426,29.499676 7.27311423,29.8354306 7.63212895,
      29.9554392 C7.99080657,30.0747737 8.38555421,29.9517311 8.61276165,29.6493497 L13.1323019,23.6317236 L23.269327,
      29.8701522 C23.5154122,30.0215114 23.8201533,30.0424118 24.0844421,29.9257742 C24.3487308,
      29.8091365 24.539194,29.5701305 24.5934676,29.2862897 L29.9830708,1.0488549 C29.9847563,
      1.03941601 29.9867789,1.02997713 29.9881273,1.02087535 C30.1034166,0.308913758 29.3567334,
      -0.230113978 28.717586,0.0988985735 L28.717586,0.0988985735 Z" id="Fill-1"
    />
  </svg>
)

export default SendEmailIcon
