import transactionAnimation from '../../../../../shared_assets/v2/transaction.gif'

import './digitalSubscriptionLoader.scss'

const DigitalSubscriptionLoader = () => (
  <div className='digital-subscription-loader'>
    <img alt='transaction animation' src={transactionAnimation} />
    <h2>Analyzing your purchase history...</h2>
    <p>
      Depending on the amount of data in your accounts this could take anywhere
      from several seconds to several minutes.
    </p>
  </div>
)

export default DigitalSubscriptionLoader
