/** @class PreviewSideNav
 *
 * @desc
 * Renders the controls and calls to action shown on the left side (when viewing in desktop)
 * of the Everplan preview page.
 */

import {memo, useEffect} from 'react'
import {Map} from 'immutable'
import PropTypes from 'prop-types'

import ExitPreview from './ExitPreview'
import DeputyUpgradePromptController from '../deputy_upgrade_prompt/DeputyUpgradePromptController'
import Logger from '../../../../../lib/NewLogger'
import MixpanelLogger from '../../../../../lib/MixpanelLogger'
import {shouldGetClientNetworkAmplifier} from '../../../../../lib/corpMaxDeputyTools'

import './previewSideNav.scss'

const PreviewSideNav = props => {
  // Need to check and log the client network amplifier events here, because the `DeputyUpgradePromptController`
  // is put on the DOM in two places and we didn't want duplicate logging.
  useEffect(() => {
    if (shouldGetClientNetworkAmplifier(props.userConfig, props.advisorContactCard)) {
      Logger.log({name: 'viewed_client_network_amplifier', payload: {}})
      MixpanelLogger.track('view_client_network_amplifier')
    }
  }, [])

  return (
    <div className='preview-side-nav flex-container'>
      <ExitPreview {...props} />
      <DeputyUpgradePromptController className='desktop-deputy-upgrade-prompt' {...props} />
    </div>
  )
}

PreviewSideNav.propTypes = {
  advisorContactCard: PropTypes.instanceOf(Map),
  userConfig: PropTypes.instanceOf(Map)
}

// Only want the side nav components to re-render if `advisorContactCard` changes and not on all props,
// since `advisorContactCard` is the only props that the components might need that isn't available on first render.
const areEqual = (prevProps, nextProps) => prevProps.advisorContactCard.equals(nextProps.advisorContactCard)

export default memo(PreviewSideNav, areEqual)
