import {Fragment, useState, useCallback} from 'react'
import PropTypes from 'prop-types'

import {
  Accept,
  Cancel
} from '../../../../../dashboard/src/components/blocks/Buttons'
import ConfirmationModal from '../../../../mui_base_components/modals/confirmation_modal/ConfirmationModal'

import './digitalSubscriptionButtonGroup.scss'

const DigitalSubscriptionButtonGroup = props => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)

  const showModal = useCallback(() => setShowConfirmationModal(true), [])
  const hideModal = useCallback(() => setShowConfirmationModal(false), [])

  return (
    <Fragment>
      <div className='flex-container digital-subscription-button-group'>
        <Cancel onClick={showModal} disabled={props.processing} />
        <Accept onClick={props.submitForm} processing={props.processing}>
          Add to my Everplan
        </Accept>
      </div>
      <ConfirmationModal
        confirmButtonText='Discard'
        headingText='Discard imported account information?'
        onClose={hideModal}
        onConfirmation={props.toggleGapFillerAssessmentModal}
        open={showConfirmationModal}
      />
    </Fragment>
  )
}

DigitalSubscriptionButtonGroup.propTypes = {
  processing: PropTypes.bool,
  submitForm: PropTypes.func,
  toggleGapFillerAssessmentModal: PropTypes.func
}

export default DigitalSubscriptionButtonGroup
