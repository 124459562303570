import {Component} from 'react'
import PropTypes from 'prop-types'

import PhoneEditor from './PhoneEditor'
import SettingsActions from '../../../actions/SettingsActions'
import TfaConfigStore from '../../../stores/TfaConfigStore'
import Button from '../../../../../shared_components/Button'

import '../tfaSetup.scss'

export default class SecondaryPhoneEditor extends Component {
  constructor(props) {
    super(props)
    this.state = {
      number: props.secondaryNumber ? props.secondaryNumber.number : '',
      nationalNumber: props.secondaryNumber ? props.secondaryNumber.national_number : '',
      mode: props.secondaryNumber ? props.secondaryNumber.preferred_message_mode : 'text',
      validationStatus: true,
      error: TfaConfigStore.getState().error
    }

    this.oldSecondaryNumber = (TfaConfigStore.getTfaConfig() || {}).secondary_number || {}
    this.deleteSecondaryNumber = this.deleteSecondaryNumber.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.setFormValue = this.setFormValue.bind(this)
    this.setMode = this.setMode.bind(this)
    this.onUpdate = this.onUpdate.bind(this)
  }

  componentDidMount() {
    TfaConfigStore.addChangeListener(this.onUpdate)
  }

  componentWillUnmount() {
    TfaConfigStore.removeChangeListener(this.onUpdate)
  }

  onUpdate() {
    // Doing this for test purpose.
    const tfaConfigStore = TfaConfigStore.getTfaConfig() || {}
    const secondaryNumber = tfaConfigStore.secondary_number

    if (this.numberEditSucceeded(secondaryNumber)) this.props.closer()
    else this.setState({loading: false, error: TfaConfigStore.getState().error})
  }

  handleSubmit(event) {
    if (event) event.preventDefault()
    if (this.state.validationStatus) {
      this.setState({loading: true})
      SettingsActions.submitPhoneEdit({
        number: this.state.number,
        nationalNumber: this.state.nationalNumber,
        preferredMessageMode: this.state.mode,
        primary: false
      })
    } else {
      this.setState({error: 'Please enter a valid number.'})
    }
  }

  setFormValue(validationStatus, nationalNumber, number) {
    this.setState({validationStatus, nationalNumber, number})
  }

  setMode(mode) { this.setState({mode}) }

  deleteSecondaryNumber() {
    SettingsActions.deleteSecondaryNumber(this.props.secondaryNumber.id)
    this.props.closer()
  }

  numberEditSucceeded(secondaryNumber) {
    return (
      this.initialNumber(secondaryNumber) ||
      this.updatedNumber(secondaryNumber) ||
      this.updatedMessageMode(secondaryNumber)
    )
  }

  initialNumber(secondaryNumber) {
    return !secondaryNumber && this.state.number
  }

  updatedNumber(secondaryNumber) {
    return (
      secondaryNumber &&
      secondaryNumber.number !== this.oldSecondaryNumber.number
    )
  }

  updatedMessageMode(secondaryNumber) {
    return (
      secondaryNumber &&
      secondaryNumber.preferred_message_mode !== this.oldSecondaryNumber.preferred_message_mode
    )
  }

  render() {
    return (
      <div className='secondary-phone-editor'>
        <PhoneEditor
          error={this.state.error}
          handleSubmit={this.handleSubmit}
          header='Secondary phone number'
          number={this.state.number}
          mode={this.state.mode}
          setFormValue={this.setFormValue}
          setMode={this.setMode}
        />
        <div>
          <Button className='wizard-secondary-btn' onClick={this.props.closer}>Cancel</Button>
          <Button className='wizard-primary-btn' onClick={this.handleSubmit} loading={this.state.loading}>Save</Button>
          {
            this.props.secondaryNumber &&
            <a onClick={this.deleteSecondaryNumber}>
              Remove my current secondary number.
            </a>
          }
        </div>
      </div>
    )
  }
}

SecondaryPhoneEditor.propTypes = {
  closer: PropTypes.func,
  secondaryNumber: PropTypes.object
}
