import PropTypes from 'prop-types'
import {Component} from 'react'
import {connect} from 'react-redux'
import {List, Map} from 'immutable'

import {addKeyFieldToolData, deleteKeyFieldToolData, fetchKeyFieldsToolData} from './actions'

import KeyFieldController from './key_field/KeyFieldController'
import {categoryItems} from '../../../../../../lib/plan_data/category'

import './keyFieldsTable.scss'


export class KeyFieldsTable extends Component {
  componentDidMount() {
    this.props.fetchKeyFieldsToolData(this.props.keyFieldMappings)
  }

  render() {
    const items = category => categoryItems(category, this.props.corpMaxData.items)
    if (this.props.keyFieldsToolData.size === 0) {
      return <div>Loading</div>
    } else {
      return (
        <table className='key-fields-table'>
          <thead>
            <tr className='headings'>
              <th>Category</th>
              <th>Item</th>
              <th>Preferred Key Field</th>
              <th>Key Field Option 2</th>
              <th>Key Field Option 3</th>
            </tr>
          </thead>
          {
            this.props.corpMaxData.categories.map(category => (
              <tbody key={`${category.get('id')}`}>
                {
                  items(category).map((item, itemIndex) => (
                    <tr key={item.get('id')}>
                      { itemIndex === 0 && <td className='category-heading' rowSpan={items(category).size}>{category.get('name')}</td>}
                      <td className='item' key={`${item.get('id')}-${itemIndex}`}>{item.get('header')}</td>
                      <td className='key-field-option'>
                        <KeyFieldController
                          addKeyFieldToolData={this.props.addKeyFieldToolData}
                          deleteKeyFieldToolData={this.props.deleteKeyFieldToolData}
                          item={item}
                          itemKeyFieldOptions={this.props.keyFieldsToolData.get(item.get('slug'))}
                          keyFieldOptionNumber={'keyFieldOption1'}
                          kits={this.props.corpMaxData.kits}
                        />
                      </td>
                      <td className='key-field-option'>
                        <KeyFieldController
                          addKeyFieldToolData={this.props.addKeyFieldToolData}
                          deleteKeyFieldToolData={this.props.deleteKeyFieldToolData}
                          item={item}
                          itemKeyFieldOptions={this.props.keyFieldsToolData.get(item.get('slug'))}
                          keyFieldOptionNumber={'keyFieldOption2'}
                          kits={this.props.corpMaxData.kits}
                        />
                      </td>
                      <td className='key-field-option'>
                        <KeyFieldController
                          addKeyFieldToolData={this.props.addKeyFieldToolData}
                          deleteKeyFieldToolData={this.props.deleteKeyFieldToolData}
                          item={item}
                          itemKeyFieldOptions={this.props.keyFieldsToolData.get(item.get('slug'))}
                          keyFieldOptionNumber={'keyFieldOption3'}
                          kits={this.props.corpMaxData.kits}
                        />
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            ))
          }
        </table>
      )
    }
  }
}

const mapStateToProps = state => ({keyFieldsToolData: state.keyFieldsToolData})

KeyFieldsTable.propTypes = {
  addKeyFieldToolData: PropTypes.func,
  corpMaxData: PropTypes.shape({
    categories: PropTypes.instanceOf(List).isRequired,
    items: PropTypes.instanceOf(List).isRequired,
    kits: PropTypes.instanceOf(List).isRequired
  }),
  deleteKeyFieldToolData: PropTypes.func,
  fetchKeyFieldsToolData: PropTypes.func,
  keyFieldMappings: PropTypes.instanceOf(Map),
  keyFieldsToolData: PropTypes.instanceOf(Map)
}

export default connect(mapStateToProps, {addKeyFieldToolData, deleteKeyFieldToolData, fetchKeyFieldsToolData})(KeyFieldsTable)
