import classnames from 'classnames'
import PropTypes from 'prop-types'
import {forwardRef} from 'react'
import {Box, TextField} from '@mui/material'

import './textInput.scss'

// eslint-disable-next-line react/display-name
const TextInput = forwardRef((props, ref) => {
  const {id, label, placeholder, select, ...otherProps} = props

  return (
    <Box className={classnames('text-input', {select: select && placeholder})}>
      {label && <label id={select ? id : null} htmlFor={id}>{label}</label>}
      <TextField
        {...otherProps}
        id={id}
        inputProps={{
          'aria-label': otherProps.ariaLabel,
          'data-testid': `${otherProps.name}-input`
        }}
        FormHelperTextProps={{component: 'span'}}
        InputLabelProps={{
          htmlFor: null,
          id: null,
          shrink: false,
          style: {
            display: otherProps.value ? 'none' : 'block'
          }
        }}
        fullWidth
        label={select ? placeholder : ''}
        placeholder={placeholder}
        ref={ref}
        select={select}
      />
    </Box>
  )
})

TextInput.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  select: PropTypes.bool
}

export default TextInput
