import {Map} from 'immutable'
import PropTypes from 'prop-types'
import {useEffect, useState, useCallback} from 'react'
import {connect} from 'react-redux'
import {createResource, readEndpoint, updateResource} from 'redux-json-api'

import FuneralExpenseRecommendationFinalExpense from './FuneralExpenseRecommendationFinalExpense'
import FuneralExpenseRecommendationPreNeed from './FuneralExpenseRecommendationPreNeed'
import FuneralExpenseOneMoment from './FuneralExpenseOneMoment'

import {createOrUpdateResource} from '../../../../../lib/apiV2Tools'
import {
  currentUserConfig,
  currentUser,
  userProfile
} from '../../../../../lib/userTools'
import {findRawResourceByAttributeValue} from '../../../../../lib/plan_data/userData'
import routerUtils from '../../../../../lib/routerUtils'
import {newCompoundItemPath} from '../../../../../lib/urlTools'

import './funeralExpenseRecommendationsContainer.scss'


export const FUNERAL_HOME_OPTION = "I've made arrangements with a funeral home"
export const SELF_PLANNING_OPTION = 'I want to select each item myself (example: casket, venue)'
export const FUNERAL_HOME_ARRANGEMENTS_ELEMENT_ID = 'e01d8975-6000-42d8-8187-8d72992c4076'
export const OTHER_PAYMENT_ARRANGEMENTS_ELEMENT_ID = '355958c4-8058-4d68-9795-c3e73876885b'

const CATEGORY_SLUG = "after-i'm-gone"
const ITEM_SLUG = 'burial-cremation-or-donation-preferences-my-disposition-wishes-and-plans'

export const FuneralExpenseRecommendationsContainer = props => {
  const prepaidArrangements = props.assessmentData.get('prepaid-arrangements')
  const planning = props.assessmentData.get('planning')
  const [showTransition, setShowTransition] = useState(!prepaidArrangements)

  const itemPath = elementId =>
    newCompoundItemPath({
      elementId,
      itemSlug: ITEM_SLUG,
      slug: CATEGORY_SLUG,
      path: 'cm',
      queryParams: {type: 'vault'}
    })

  useEffect(() => {
    if (prepaidArrangements) {
      if (prepaidArrangements === FUNERAL_HOME_OPTION)
        routerUtils.push(itemPath(FUNERAL_HOME_ARRANGEMENTS_ELEMENT_ID))
      else routerUtils.push(itemPath(OTHER_PAYMENT_ARRANGEMENTS_ELEMENT_ID))
    } else if (planning === SELF_PLANNING_OPTION) {
      props.readEndpoint('user-settings')
    } else {
      props.readEndpoint('partner-leads')
      props.readEndpoint('tfa-configs')
      props.readEndpoint('user-settings')
    }
  }, [prepaidArrangements])

  const productRecommendationSubscription = useCallback(({subName, callback = {}}) => {
    const attributes = {
      status: 'subscribed',
      name: 'product_recommendation',
      'sub-name': subName
    }

    createOrUpdateResource({
      createResource: props.createResource,
      updateResource: props.updateResource,
      attributes,
      resource: props.productRecommendationUserSetting,
      type: 'user-settings',
      callback
    })
  })

  if (props.assessmentData.get('prepaid-arrangements')) return null


  const Recommendation = planning === SELF_PLANNING_OPTION ?
    FuneralExpenseRecommendationPreNeed :
    FuneralExpenseRecommendationFinalExpense

  return (
    <div className='funeral-expense-recommendations-container'>
      {
        showTransition || props.loading ?
          (
            <FuneralExpenseOneMoment
              onStepChange={() => setShowTransition(false)}
              displayText='While we determine the best recommendation for you...'
            />
          ) :
          (
            <Recommendation
              productRecommendationSubscription={productRecommendationSubscription}
              productRecommendationUserSetting={props.productRecommendationUserSetting}
              toggleGapFillerAssessmentModal={props.toggleGapFillerAssessmentModal}
              onStepChange={props.onStepChange}
              user={props.user}
              profile={props.profile}
              tfaConfig={props.tfaConfig}
            />
          )
      }
    </div>
  )
}

FuneralExpenseRecommendationsContainer.propTypes = {
  assessmentData: PropTypes.instanceOf(Map),
  createResource: PropTypes.func,
  productRecommendationUserSetting: PropTypes.instanceOf(Map),
  loading: PropTypes.bool,
  onStepChange: PropTypes.func,
  partnerLead: PropTypes.instanceOf(Map),
  readEndpoint: PropTypes.func,
  toggleGapFillerAssessmentModal: PropTypes.func,
  updateResource: PropTypes.func,
  profile: PropTypes.instanceOf(Map),
  tfaConfig: PropTypes.instanceOf(Map),
  user: PropTypes.instanceOf(Map)
}

const mapStateToProps = ({api}) => {
  const userConfig = currentUserConfig(api)
  const user = currentUser({userConfig, users: api.users})
  const profile = userProfile({userConfig, profiles: api.profiles})

  return {
    productRecommendationUserSetting: findRawResourceByAttributeValue(
      {
        attribute: 'name',
        value: 'product_recommendation',
        rawResource: api['user-settings']
      }
    ),
    partnerLead: findRawResourceByAttributeValue({
      attribute: 'user-id',
      value: userConfig.get('id'),
      rawResource: api['partner-leads']
    }),
    tfaConfig: findRawResourceByAttributeValue({
      attribute: 'user-id',
      value: userConfig.get('id'),
      rawResource: api['tfa-configs']
    }),
    loading: api.isReading > 0,
    user,
    profile
  }
}

export default connect(mapStateToProps, {
  createResource,
  readEndpoint,
  updateResource
})(FuneralExpenseRecommendationsContainer)
