import PropTypes from 'prop-types'

import PhoneButton from './PhoneButton'

import './phoneButtonMobile.scss'


const PhoneButtonMobile = ({phoneNumber}) => (
  <a className='phone-button-mobile contact-link' href={`tel:${phoneNumber}`}>
    <PhoneButton />
  </a>
)

PhoneButtonMobile.propTypes = {
  phoneNumber: PropTypes.string
}


export default PhoneButtonMobile
