import {Map} from 'immutable'

const body = `
As our lives become more digitally focused, passwords have grown out of control. In the past many of us only had a handful of passwords. Now between email, social media, online banking, health insurance, entertainment, and more it's not uncommon to have more than a hundred of these things floating around.

Plus, it's not just the passwords. Do you log in with email or a username? What are the answers to your secret questions? If you used a proper name, did you capitalize it? When a vicious hack or virus hits the Internet, how can you quickly and easily change your passwords?

The good news is there's a digital solution for all of these problems. They're called password managers or password wallets and they securely keep track of all this sensitive information, often only requiring you to remember one master password to access all your accounts. All you have to do is install a simple browser plugin or download an app to your phone, and you're ready to go. As avid users of password managers, we surveyed the Everplans team and narrowed it down to four providers: 1Password, Dashlane, KeePassX, and LastPass, listed below in alphabetical order.

### 1Password

**How it Works**: Once you've installed the app and browser plugins, which work on every major platform, it saves usernames and passwords as you log into various websites. For new sign-ups, it has a password generator that allows you to create complex, unique passwords for every site you access. Your password vault is encrypted by a master password, so the company that built 1Password (AgileBits) never has access to your stash of passwords.

**Ease of Use**: It's very simple to use once you make it part of your routine. It will only populate the username and password once you've unlocked the app and clicked the saved entry for that site. It remembers logins for a site even if the URL is not an exact match for the URL you have saved (for example, if all you have saved for the URL is "everplans.com" but the login page is "my.everplans.com/users/sign_in", it will still recognize the site.)

**Tips/Tricks**: The app can store and fill in credit cards and other types of personal info into forms for you. You can also create separate "vaults," so it's possible to set up a private vault for your-eyes-only and separate vault filled shared passwords for a family or co-workers.

**Cost**: The Mac and Windows version cost $2.99 a month for individuals and $4.99 per month for families (billed annually) and includes unlimited installations across all computers and devices.

**Site**: <a href='https://1password.com' rel='noreferrer noopener' target='_blank'>1Password.com</a>

### Dashlane

**How It Works**: This is an computer and mobile app that allows you to save everything, from passwords to credit card info to receipts, in a digital wallet that is accessed by one master password.

**Ease of Use**: You can automatically sign in to online sites using the passwords it has stored, as well as easily generate new strong passwords for websites, which are then auto-saved in your digital wallet.

**Tips/Tricks**: If you lose your password, your entire account gets wiped. So you need to be careful!

**Cost**: Dashlane Premium is $4.99 per month (billed annually) and provides automatic syncs across all your devices, automatic backups of your account, and more. A Premium Plus version for $9.99 (billed annually) includes credit monitoring and identity theft insurance.

**Site**: <a href='https://www.dashlane.com' rel='noreferrer noopener' target='_blank'>Dashlane.com</a>

#### KeePassX

**How it Works**: This is kind of old school in that it doesn't use a cloud. You install an app on your Windows or Mac computer(s) and phone(s), which uses an encrypted file stored on your computer that houses all your passwords. If you put that file in your Dropbox, it becomes available across all your computers and phones. Because the password data is stored on your computer and NOT in the cloud, it's unlikely to be a target of a coordinated break-in.

**Ease of Use**: It takes some tech savviness to set it up--their site can be considered intimidating--but once you're up and running it's very easy to use (one of our founders' mom uses it). Also, it doesn't auto-fill forms for you, which could be considered a benefit since some see these as a security risk.

**Tips/Tricks**: Remember to go to preferences and adjust the "auto logout" and "auto-save" parameters. Also, remember not to leave the password window open after you look up a password--depending on how you leave it open, the program will not be able to auto-logout.

**Cost**: The desktop app is free. There are a few iPhone Apps you can use with it, one of which is PassDrop ($1.99).

**Site**: <a href='https://www.keepassx.org' rel='noreferrer noopener' target='_blank'>KeepassX.org</a>

### LastPass

**How it Works**: LastPass saves all your passwords in their cloud servers, and allows you to access them with a single master password.

**Ease of Use**: Install a plugin on your web browser and it will pre-fill usernames and passwords. It can store unlimited passwords and username combinations for each site, generate secure passwords on the fly, detect newly created or changed passwords (and then asks to save them to your vault), and store credit card info.

**Tips/Tricks**: You can search your vault from the web plugin. Use the right-click menu to copy and paste passwords from matching sites or to auto-fill forms. It also supports multiple user profiles.

**Cost**: The desktop version and mobile app are free. The Premium version for 1 user ($36/year) offers 1GB of encrypted file storage and allows you to securely share passwords with others; the Families plan ($48/year) allows for 6 different users.

**Site**: <a href='https://www.lastpass.com' rel='noreferrer noopener' target='_blank'>LastPass.com</a>
`


export default Map({
  body,
  headline: 'The Four Most Popular Password Managers',
  summary: 'Tired of using a notepad and Post-It notes to keep track of all your login information and passwords? Your digital salvation is here!',
  query: 'the-four-most-popular-password-managers',
  url: 'https://www.everplans.com/articles/the-four-most-popular-password-managers'
})

