import {Component} from 'react'
import {connect} from 'react-redux'
import * as reduxJsonApiActions from 'redux-json-api'
import PropTypes from 'prop-types'


import ViewFormController from './view_form/ViewFormController'
import ViewsController from './views/ViewsController'

import {cleanResponse} from '../redux/apiHelper'
import {currentUserConfig} from '../../../../../lib/userTools'
import {redirectToDashboardFromTools} from '../../../../../lib/urlTools'
import {environmentMetadataVersion} from '../../../../../lib/apiV2Tools'

import './viewsToolController.scss'

export class ViewsToolController extends Component {
  constructor(props) {
    super(props)

    redirectToDashboardFromTools(props)
  }

  componentDidMount() {
    this.props.readEndpoint('settings')
    this.props.readEndpoint('metadata/versions')
  }

  render() {
    if (this.props.environmentVersion.isEmpty()) {
      return null
    } else {
      return (
        <div className='views-tool-controller'>
          <h2>Metadata Version {this.props.environmentVersion.get('name')}</h2>
          <ViewFormController {...this.props} />
          <ViewsController {...this.props} />
        </div>
      )
    }
  }
}

ViewsToolController.propTypes = {
  environmentVersion: PropTypes.instanceOf(Map),
  readEndpoint: PropTypes.func
}

const mapStateToProps = state => ({
  environmentVersion: environmentMetadataVersion({versions: state.api.versions, settings: state.api.settings}),
  categories: state.categories,
  elements: state.elements,
  items: state.items,
  kits: state.kits,
  listMappings: state.listMappings,
  views: cleanResponse(state.api.views),
  userConfig: currentUserConfig(state.api)
})

export default connect(mapStateToProps, reduxJsonApiActions)(ViewsToolController)
