/** @class ItemPermissionsSelectForm
 *
 * @desc
 * Select form with all categories and items used for singular item permissions (aka edit)
 *
 * @param {Array} checkedItemIds list of checked item ids
 * @param {Function} onCheck callback updates the checked item ids in the parent component
 *
 * @todo styles
 * @todo add options to select all and select none
 *
 */

import {Component} from 'react'
import CheckboxTree from 'react-checkbox-tree'
import {connect} from 'react-redux'
import {List} from 'immutable'
import PropTypes from 'prop-types'

import ToggleAllControls from './ToggleAllControls'

import {categoryItems} from '../../lib/plan_data/category'

import './itemPermissionsSelectForm.scss'


export class ItemPermissionsSelectForm extends Component {
  constructor() {
    super()

    this.state = {expanded: []}
  }

  itemsPerCategoryNodes(category) {
    const itemReducer = (collection, item) => {
      collection.push({
        value: item.get('id'),
        label: item.get('header')
      })

      return collection
    }

    return categoryItems(category, this.props.items)
      .sortBy(item => item.get('sort-order'))
      .reduce(itemReducer, [])
  }

  // createes the needed structure by the library used for the checkboxs
  nodes() {
    const reducer = (collection, category) => {
      const items = this.itemsPerCategoryNodes(category)

      if (items.length) {
        collection.push({
          value: category.get('id'),
          label: category.get('name'),
          children: items,
          className: 'item-permissions'
        })
      }

      return collection
    }

    return this.props.categories
      .sortBy(category => category.get('sort-order'))
      .reduce(reducer, [])
  }

  render() {
    return (
      <div className='item-permissions-select-form'>
        <ToggleAllControls {...this.props} />
        <CheckboxTree
          icons={{
            check: <span className='rct-icon fa fa-fw fa-check-square' />,
            uncheck: <span className='rct-icon fa fa-fw fa-square-o' />,
            halfCheck: <span className='rct-icon fa fa-fw fa-check-square-o' />,
            expandClose: <span className='rct-icon fa fa-fw fa-caret-right' />,
            expandOpen: <span className='rct-icon fa fa-fw fa-caret-down' />,
            expandAll: <span className='rct-icon fa-fw rct-icon-expand-all' />,
            collapseAll: <span className='rct-icon fa-fw rct-icon-collapse-all' />,
            parentClose: <span className='rct-icon fa-fw rct-icon-parent-close' />,
            parentOpen: <span className='rct-icon fa-fw rct-icon-parent-open' />,
            leaf: <span className='rct-icon fa-fw rct-icon-leaf' />
          }}
          nodes={this.nodes()}
          checked={this.props.checkedItemIds}
          expanded={this.state.expanded}
          onCheck={checkedItemIds => this.props.onCheck(checkedItemIds)}
          onExpand={expanded => this.setState({expanded})}
        />
      </div>
    )
  }
}

ItemPermissionsSelectForm.propTypes = {
  categories: PropTypes.instanceOf(List),
  items: PropTypes.instanceOf(List),
  onCheck: PropTypes.func,
  checkedItemIds: PropTypes.arrayOf(PropTypes.string)
}


const mapStateToProps = (state, {permittedItemIds}) => ({
  categories: state.categories,
  // Doing it here so that the correct items are sent to both the `ToggleAllControls` and for rendering the items/categories instead of
  // doing it in twice everytime the component re-renders. -- ZD
  items: permittedItemIds ? state.items.filter(item => permittedItemIds.includes(item.get('id'))) : state.items
})

export default connect(mapStateToProps)(ItemPermissionsSelectForm)
