import {Map} from 'immutable'
import PropTypes from 'prop-types'

import {isDeferredReadResponseGroup} from '../../lib/deputyPermissionTools'
import {formatDate} from '../../lib/dateTools'

import './itemResponseGroupInformation.scss'


const ItemResponseGroupInformation = props => {
  if (props.context === 'everplan_preview' && !props.ownEverplan && isDeferredReadResponseGroup({deputyOwnership: props.deputyOwnership, firstGroupId: props.firstGroupId})) {
    return (
      <span className='unlock-state flex-child'>
        <i className='fa fa-flip-horizontal fa-unlock' aria-hidden={true} />
        Unlocked
      </span>
    )
  } else {
    return (
      <div className='value last-updated flex-child'>
        {formatDate(props.responseGroup.get('last-updated-at'))}
      </div>
    )
  }
}

ItemResponseGroupInformation.propTypes = {
  ownEverplan: PropTypes.bool,
  context: PropTypes.string,
  firstGroupId: PropTypes.string,
  deputyOwnership: PropTypes.instanceOf(Map),
  responseGroup: PropTypes.instanceOf(Map)
}

export default ItemResponseGroupInformation

