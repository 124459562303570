import PropTypes from 'prop-types'
import {Map, List} from 'immutable'

import {ContactTextValue, ContactAddressValue} from './ContactValueByType'
import {BoxGutterExtraLarge} from '../../../blocks/Boxes'
import ContactResponseIcons from './ContactResponseIcons'

import {contactLabel, contactGenderDisplayString, getContactAddresses} from '../../../../../../lib/contactTools'
import {dateResponseValue} from '../../../../../../lib/dateTools'

import './viewContactItem.scss'


const ViewContactItem = props => {
  const contactData = props.contact

  return (
    <BoxGutterExtraLarge className='view-contact-item flex-container'>
      <div className='view-contact-label flex-child'>
        {contactLabel(contactData)}
      </div>
      {
        props.showEditLink &&
          <ContactResponseIcons
            showContactModal={props.showContactModal}
            clearSelectedContact={props.clearSelectedContact}
          />
      }
      <div className='view-contact-information flex-child'>
        <ContactTextValue
          data={contactData.get('emails')}
          header='Email'
        />
        <ContactTextValue
          data={contactData.get('phone-numbers')}
          header='Phone'
        />
        <ContactTextValue
          data={contactData.get('company-name')}
          header='Company'
        />
        <ContactAddressValue
          data={getContactAddresses(contactData.get('addresses'))}
          header='Address'
        />
        <ContactTextValue
          data={dateResponseValue(Map({date: contactData.get('birthdate'), mask: contactData.get('birthdate-mask')}))}
          header='Birthdate'
        />
        <ContactTextValue
          data={contactData.get('website')}
          header='Website'
        />
        <ContactTextValue
          data={contactGenderDisplayString(contactData.get('gender'))}
          header='Gender'
        />
        <ContactTextValue
          data={contactData.get('notes')}
          header='Notes'
        />
      </div>
    </BoxGutterExtraLarge>
  )
}


ViewContactItem.defaultProps = {showEditLink: false}

ViewContactItem.propTypes = {
  clearSelectedContact: PropTypes.func,
  contact: PropTypes.instanceOf(Map),
  itemResponses: PropTypes.instanceOf(List),
  ownEverplan: PropTypes.bool,
  showContactModal: PropTypes.func,
  showEditLink: PropTypes.bool
}

export default ViewContactItem
