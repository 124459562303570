import PropTypes from 'prop-types'
import {Component} from 'react'
import {Map} from 'immutable'

import {Button} from '../../../blocks/Buttons'
import Modals from '../../../../../../shared_components/core/modals/Modals'
import NextBestActionForm from './NextBestActionForm'
import Closer from '../../../../../../shared_components/core/closer/Closer'

import {apiV2MetaDataCall} from '../../../../../../lib/apiV2Tools'


export default class NextBestActionFormController extends Component {
  constructor() {
    super()

    this.state = {processing: false, showModal: false}

    this.submitNextBestActionData = this.submitNextBestActionData.bind(this)
    this.toggleModal = this.toggleModal.bind(this)
    this.closer = this.closer.bind(this)
  }

  submitNextBestActionData(nextBestActionData) {
    this.setState({processing: true})

    const resource = {
      type: 'next-best-actions',
      attributes: {
        'version-id': this.props.environmentVersion.get('id'),
        data: nextBestActionData
      }
    }

    apiV2MetaDataCall({apiCall: this.props.createResource, resource})
      .then(() => {
        this.setState({processing: false})
        this.toggleModal()
      })
  }

  closer() { return <Closer closer={this.toggleModal} /> }

  toggleModal() { this.setState({showModal: !this.state.showModal}) }

  render() {
    return (
      <div>
        <Button onClick={this.toggleModal}>Add new Next Best Action</Button>
        <Modals.PopUpModalLarge showModal={this.state.showModal} closerComponent={this.closer}>
          <NextBestActionForm
            {...this.props}
            submitNextBestActionData={this.submitNextBestActionData}
            processing={this.state.processing}
            nextBestAction={Map()}
          />
        </Modals.PopUpModalLarge>
      </div>

    )
  }
}

NextBestActionFormController.propTypes = {
  createResource: PropTypes.func,
  environmentVersion: PropTypes.instanceOf(Map)
}
