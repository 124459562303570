import PropTypes from 'prop-types'

import {styled} from '@mui/material'

import CloseIcon from '../../../core/svg_icons/icons/CloseIcon'
import StyledBaseBox from '../../boxes/StyledBaseBox'
import StyledBaseModal from '../StyledBaseModal'
import breakpoints from '../../../../dashboard/styles/abstracts/breakpoints.module.scss'

const StyledLargeModalContent = styled(StyledBaseBox)(
  () => `
    margin: 50px auto;
    max-width: 900px;
    padding: 32px;
    position: relative;
    @media (min-width: ${breakpoints.tabletMd}) {
      padding: 48px;
    }
  `
)

const StyledLargeModal = ({children, onClose, ...otherProps}) => (
  <StyledBaseModal {...otherProps} dataTestId='large-modal' onClose={onClose}>
    <StyledLargeModalContent className='large-modal-content'>
      <CloseIcon
        data-testid='close-icon'
        height='15'
        onClick={onClose}
        style={{alignSelf: 'flex-end', cursor: 'pointer', zIndex: '10'}}
        width='15'
      />
      {children}
    </StyledLargeModalContent>
  </StyledBaseModal>
)

StyledLargeModal.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired
}

export default StyledLargeModal
