import PropTypes from 'prop-types'

import DropdownTooltip from '../../../../../shared_components/core/tooltips/DropdownTooltip'
import GearIcon from '../../icons/GearIcon'

import './manageDeputyPermissionsToolTip.scss'


const ManageDeputyPermissionsToolTip = ({goToManagePermissions, goToUnlockPermissions}) => (
  <DropdownTooltip
    className='manage-deputy-permissions-tooltip'
    dataTestId='manage-deputy-permissions-tooltip'
    launcher={
      <div className='flex-container tool-tip-launcher'><GearIcon />Manage</div>
    }>
    <ul>
      <li>
        <a onClick={goToManagePermissions}>Shared items</a>
      </li>
      <li>
        <a onClick={goToUnlockPermissions}>Unlock permission</a>
      </li>
    </ul>
  </DropdownTooltip>
)


ManageDeputyPermissionsToolTip.propTypes = {
  goToManagePermissions: PropTypes.func,
  goToUnlockPermissions: PropTypes.func
}

export default ManageDeputyPermissionsToolTip
