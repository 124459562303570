import PropTypes from 'prop-types'
import {Component} from 'react'

import Modals from '../../../../shared_components/core/modals/Modals'
import Closer from '../../../../shared_components/core/closer/Closer'
import {Button} from '../../../../dashboard/src/components/blocks/Buttons'
import {TextGutterMedium} from '../../../../dashboard/src/components/blocks/Texts'
import successCheckmarkIcon from '../../../../shared_assets/v2/success-checkmark.svg'
import {wrapperEnabled} from '../../../../lib/sessionTools'
import routerUtils from '../../../../lib/routerUtils'
import SessionApi from '../../../../auth/src/web/SessionApi'

import './paymentReceivedModal.scss'

export default class PaymentReceivedModal extends Component {
  constructor() {
    super()
    this.modalCloser = this.modalCloser.bind(this)
    this.goToDashboard = this.goToDashboard.bind(this)
    this.gotToDashboardAndRedeemBookOffer = this.gotToDashboardAndRedeemBookOffer.bind(this)
  }

  componentDidMount() { SessionApi.fetchCurrentUser(false) }

  modalCloser() { return <Closer closer={this.goToDashboard} /> }

  goToDashboard() {
    if (wrapperEnabled())
      routerUtils.push('dashboard')
    else
      routerUtils.setLocation(API_HOST)
  }

  gotToDashboardAndRedeemBookOffer() {
    this.goToDashboard()
    window.open('https://www2.everplans.com/in-case-address', '_blank')
  }

  buttonText() { return this.props.hasBookOffer ? 'Get the book for free' : 'Done' }

  render() {
    return (
      <Modals.SuccessPopUpModal className='payment-received-modal' showModalOnMount={true} closerComponent={this.modalCloser}>
        <header>
          <img src={successCheckmarkIcon} alt='Success checkmark' />
          <h2>Thank you!</h2>
        </header>
        <TextGutterMedium>
          {this.props.bodyText}
        </TextGutterMedium>
        <Button onClick={this.props.hasBookOffer ? this.gotToDashboardAndRedeemBookOffer : this.goToDashboard}>{this.buttonText()}</Button>
        {this.props.hasBookOffer && <a onClick={this.goToDashboard}>No thanks</a>}
      </Modals.SuccessPopUpModal>
    )
  }
}

PaymentReceivedModal.propTypes = {
  bodyText: PropTypes.string,
  location: PropTypes.shape({
    query: PropTypes.shape({
      payment_status: PropTypes.string
    })
  }),
  hasBookOffer: PropTypes.bool
}
