import PropTypes from 'prop-types'
import {useCallback} from 'react'

import PlaidLinkButtonContainer from '../../../../../dashboard/src/components/corp_max/plaid/PlaidLinkButtonContainer'

const ITEM_TOKEN_CREATION_ERROR =
  "Something is interfering with the connection to this institution. We'll look into solving this issue."

const DigitalSubscriptionPlaidLinkContainer = props => {
  const onSuccess = useCallback(
    public_token => {
      const resource = {type: 'plaid-item-tokens', attributes: {data: {public_token}}}
      props.resetData()
      props
        .createResource(resource)
        .then(response => props.setItemTokenId(response.data.id))
        .catch(() => props.closeAndNotifyError(ITEM_TOKEN_CREATION_ERROR))
      props.onStepChange()
    }
  )

  return (
    <PlaidLinkButtonContainer
      className='plaid-link'
      text='Continue'
      setPlaidUtils={props.setPlaidUtils}
      onSuccess={onSuccess}
    />
  )
}

DigitalSubscriptionPlaidLinkContainer.propTypes = {
  createResource: PropTypes.func,
  onStepChange: PropTypes.func,
  setItemTokenId: PropTypes.func,
  setPlaidUtils: PropTypes.func,
  closeAndNotifyError: PropTypes.func,
  toggleGapFillerAssessmentModal: PropTypes.func,
  resetData: PropTypes.func
}

export default DigitalSubscriptionPlaidLinkContainer
