import LegacyActionFactory from '../shared_components/LegacyActionFactory'

const ContactCardActions = new LegacyActionFactory([
  'GOT_CONTACT_CARD'
])

// fetchContactCard
ContactCardActions.buildBoundGet('contact_card', 'firms/contact_cards/show_to_client', ContactCardActions.Types.GOT_CONTACT_CARD)

export default ContactCardActions
