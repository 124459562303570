import {Map} from 'immutable'

const body = `
Self storage is enormous business in America, with 10.9 million households renting storage units taking up a total of 2.3 billion feet of space. Blame it on people running out of room in their homes, nomadic lifestyles, reality shows, every bad guy on <em class='italic'>Criminal Minds</em> or <em class='italic'>Law and Order: SVU</em>... Let's just accept storage is here to stay even long after we're gone.

Speaking of which, what happens to people's storage units when they die? Great question! We've rounded up a few scenarios below to help you out if you ever have to deal with it.

### If The Person Has Given You Access

This is the ideal situation, for you and the facility. If the renter has already shared the location, unit number, keys and/or keypad access code to the unit (for example: In their Everplan under "Financial and Legal Info" section) then it's smooth sailing. Just go to the place and empty it out and close it down.

### If A Person Hasn't Given You Access

First you have to keep paying the monthly fee to avoid foreclosure. If it's set-up to auto-pay make sure the credit card or account being used to fund the unit is still active. If the person paid by check then you should continue paying it that way. Next you have to get documentation proving you're the Executor of the estate, which can be quick or take up to 60 days. Once you have the necessary paperwork, the facility manager will grant you access to the unit. From there you can decide if you want to keep it or empty it out and close it down.

### If A Person Never Told You About Their Storage Unit

There's a possibility you could find out the deceased had a rental unit after that person is gone. Some scenarios:

* If you or someone close to the deceased were listed as an emergency contact, you may receive a call from a storage facility manager.
* If you have access to the deceased's mail, the facility manager must send a certified letter to the last known address before foreclosing, although many states have relaxed this requirement.
* If you have access to credit card or bank statements, check for either a monthly or annual charge for a storage facility.

Once you know it exists, you can follow the "If A Person Hasn't Given You Access" section above.
`


export default Map({
  body,
  headline: 'What Happens To A Self Storage Unit When Someone Dies?',
  summary: "Some useful tips to prevent your loved one's stuff from ending up on Storage Wars.",
  query: 'what-happens-to-a-self-storage-unit-when-someone-dies',
  url: 'https://www.everplans.com/articles/what-happens-to-a-self-storage-unit-when-someone-dies'
})
