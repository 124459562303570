import PropTypes from 'prop-types'
import {Fragment} from 'react'
import {connect} from 'react-redux'
import {List, Map} from 'immutable'

import AcceptedDeputyFor from './AcceptedDeputyFor'
import Loader from '../../../../../shared_components/NewLoader'
import PendingDeputyFor from './PendingDeputyFor'

import {currentUserConfig} from '../../../../../lib/userTools'
import {useDeputizerData} from '../../../../../lib/hooks'

import './deputyForController.scss'

export const DeputyForController = props => {
  const {deputizers, loadingDeputizers, refetchDeputizers} = useDeputizerData(props.userConfig)

  if (loadingDeputizers)
    return <Loader loading />

  const groupedDeputizers = deputizers.groupBy(deputizer => deputizer.get('ownable-type'))

  const pendingDeputyOwnerships = groupedDeputizers.get('Invite', List())
  const acceptedDeputyOwnerships = groupedDeputizers.get('User', List())

  return (
    <div className='deputy-for-controller'>
      <h1 className='title'>I'm a deputy for...</h1>
      {
        !pendingDeputyOwnerships.isEmpty() && (
          <Fragment>
            <h4 className='sub-title'>Requests pending</h4>
            <div>
              {
                pendingDeputyOwnerships.map(deputyOwnership => (
                  <PendingDeputyFor
                    refetchDeputizers={refetchDeputizers}
                    key={deputyOwnership.get('id')}
                    deputyOwnership={deputyOwnership}
                  />
                ))
              }
            </div>
          </Fragment>
        )
      }
      {
        !acceptedDeputyOwnerships.isEmpty() && (
          <Fragment>
            {
              !pendingDeputyOwnerships.isEmpty() &&
              <h4 className='sub-title'>I'm a deputy for...</h4>
            }
            <div>
              {
                acceptedDeputyOwnerships.map(deputyOwnership => (
                  <AcceptedDeputyFor
                    key={deputyOwnership.get('id')}
                    deputyOwnership={deputyOwnership}
                  />
                ))
              }
            </div>
          </Fragment>
        )
      }
      {
        acceptedDeputyOwnerships.isEmpty() && pendingDeputyOwnerships.isEmpty() && (
          <p>You are currently not a Deputy for anyone.</p>
        )
      }
    </div>
  )
}

export const mapStateToProps = ({api}) => ({
  userConfig: currentUserConfig(api)
})

DeputyForController.propTypes = {
  userConfig: PropTypes.instanceOf(Map)
}

export default connect(mapStateToProps)(DeputyForController)
