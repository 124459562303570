import {TextGutterMedium, TextGutterLarge} from '../../blocks/Texts'


const WhatIsADeputy = () => (
  <div className='flex-child sub-info what-is-a-deputy'>
    <h3>What Is A Deputy?</h3>
    <TextGutterMedium>
      A Deputy is an important person in your life whom you trust enough to provide access to some or all of your Everplan.
    </TextGutterMedium>
    <TextGutterLarge>
      You can choose to share sections of your Everplan with Deputies immediately, or lock them until after you've passed away.
    </TextGutterLarge>
  </div>
)

export default WhatIsADeputy
