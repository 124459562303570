import {Component} from 'react'
import {Map} from 'immutable'

import Container from '../../../../../lib/Container'
import FileStatusList from './FileStatusList'

import FileUploadActions from '../../../../../shared_actions/FileUploadActions'
import {pluralize} from '../../../../../lib/tools'
import storePrototype from '../../../../../shared_components/StorePrototype'

import './fileStatus.scss'


const CRYPT_ACTIONS = {
  encrypting: [
    FileUploadActions.Types.DID_FINISH_ENCRYPTION,
    FileUploadActions.Types.DID_START_ENCRYPTION
  ],
  decrypting: [
    FileUploadActions.Types.DID_FINISH_DECRYPTION,
    FileUploadActions.Types.DID_START_DECRYPTION
  ]
}

Container.registerStore('fileDecryptionStatus', storePrototype(CRYPT_ACTIONS.decrypting))
Container.registerStore('fileEncryptionStatus', storePrototype(CRYPT_ACTIONS.encrypting))

export default class FileStatus extends Component {
  constructor() {
    super()

    this.state = {
      encryption: Map(),
      decryption: Map(),
      showEncryptingStatus: true,
      showDecryptingStatus: true
    }

    this.fileDecryptionStatusStore = Container.getStore('fileDecryptionStatus')
    this.fileEncryptionStatusStore = Container.getStore('fileEncryptionStatus')
    this.onDecryptionUpdate = this.onDecryptionUpdate.bind(this)
    this.onEncryptionUpdate = this.onEncryptionUpdate.bind(this)
    this.toggleDecryption = this.toggleDecryption.bind(this)
    this.toggleEncryption = this.toggleEncryption.bind(this)
  }

  componentDidMount() {
    this.fileDecryptionStatusStore.addChangeListener(this.onDecryptionUpdate)
    this.fileEncryptionStatusStore.addChangeListener(this.onEncryptionUpdate)
    this.viewingInMobile()
  }

  toggleEncryption() { this.setState({showEncryptingStatus: !this.state.showEncryptingStatus}) }

  toggleDecryption() { this.setState({showDecryptingStatus: !this.state.showDecryptingStatus}) }

  onDecryptionUpdate() {
    const storeData = this.fileDecryptionStatusStore.getState().data

    this.setState({
      decryption: storeData.get('fileStatus') === 'Decrypted' ?
        this.state.decryption.delete(storeData.get('id')) :
        this.state.decryption.set(storeData.get('id'), storeData)
    })
  }

  onEncryptionUpdate() {
    const storeData = this.fileEncryptionStatusStore.getState().data

    this.setState({
      encryption: storeData.get('fileStatus') === 'Encrypted' ?
        this.state.encryption.delete(storeData.get('id')) :
        this.state.encryption.set(storeData.get('id'), storeData)
    })
  }

  componentWillUnmount() {
    this.fileDecryptionStatusStore.removeChangeListener(this.onDecryptionUpdate)
    this.fileEncryptionStatusStore.removeChangeListener(this.onEncryptionUpdate)
  }

  viewingInMobile() {
    if (!window.matchMedia('(min-width: 480px)').matches) { // Use `$mobileLg` from our web style guide breakpoints.
      this.setState({
        showEncryptingStatus: false,
        showDecryptingStatus: false
      })
    }
  }

  render() {
    return (
      <div className='file-status'>
        {
          this.state.encryption.size > 0 &&
          <FileStatusList
            files={this.state.encryption}
            header={`Encrypting ${this.state.encryption.size} ${pluralize('file', this.state.encryption.size)}`}
            showStatus={this.state.showEncryptingStatus}
            toggleModule={this.toggleEncryption}
          />
        }
        {
          this.state.decryption.size > 0 &&
          <FileStatusList
            files={this.state.decryption}
            header={`Decrypting ${this.state.decryption.size} ${pluralize('file', this.state.decryption.size)} for download`}
            showStatus={this.state.showDecryptingStatus}
            toggleModule={this.toggleDecryption}
          />
        }
      </div>
    )
  }
}
