import routerUtils from './routerUtils'
import {corpMaxDashboardUrl} from './urlTools'

export const addQueryToPath = (path, query) => (query ? `${path}${query.startsWith('?') ? query : `?${query}`}` : path)

export const constructInterstitialPath = (id, type, query = null) => {
  let nextPath
  switch (type) {
    case 'add_household_member':
      nextPath = `pending-requests/households/${id}/details`
      break
    case 'client':
      nextPath = `pending-requests/clients/${id}/details`
      break
    case 'migrated_deputy':
      nextPath = `pending-requests/migrated-deputies${id ? `/${id}` : ''}`
      break
    case 'cancel-death-report':
      nextPath = 'pending-requests/cancel-death-report'
      break
    case 'new-everplans':
      nextPath = 'pending-requests/new-everplans'
      break
    case 'request-item-permissions':
      nextPath = `pending-requests/request-item-permissions/${id}`
      break
    case 'mandatory-tfa':
      nextPath = 'pending-requests/mandatory-tfa'
      break
    case 'advisor-sso-setup':
      nextPath = 'pending-requests/advisor-sso-setup'
      break
    case 'new-subscription':
      nextPath = 'pending-requests/new-subscription'
      break
    default:
      nextPath = 'pending-requests'
      break
  }

  return addQueryToPath(nextPath, query)
}

export const goToInterstitials = () => routerUtils.push('pending-requests')

export const onTfaExit = (props, currentUser = {}) => {
  if (props.interstitial)
    props.next()
  else if (props.location.query.loc === '2')
    goToInterstitials()
  else if (currentUser.freemium && props.partner.get('suppress-freemium'))
    routerUtils.push('new-subscription')
  else
    routerUtils.setLocation(corpMaxDashboardUrl)
}
