import {Map} from 'immutable'

// Grab a context referencing all JS files in this directory other than this file:
const articlesContext = import.meta.glob('./*.js', {eager: true})

// Construct a dictionary representing all the articles where the keys are the URL query strings
// representing each article and the values are the actual article `Map`s:
export const articles = Object.keys(articlesContext).reduce(
  (articlesDictionary, articlePath) => {
    const articleQuery = articlePath.match(/(?<=.\/)(.*)(?=.js)/)[0] // Regex here matches filename without ./ and .js
    const article = articlesContext[articlePath].default
    articlesDictionary = articlesDictionary.set(articleQuery, article)

    return articlesDictionary
  },
  Map()
)
