import {useEffect, useMemo, useState} from 'react'
import {List, Map} from 'immutable'
import PropTypes from 'prop-types'

import ItemNavElement from './ItemNavElement'
import MobileItemNavElements from './MobileItemNavElements'
import DeleteCompoundResponseGroup from '../shared/DeleteCompoundResponseGroup'

import {topKitElementIdsWithResponse} from '../../../../../lib/plan_data/itemResponsesHelper'

import './itemNavElements.scss'


const ItemNavElements = props => {
  const [currentTabId, setCurrentTabId] = useState(props.currentTabId)

  useEffect(() => {
    if (props.currentTabId !== currentTabId)
      setCurrentTabId(props.currentTabId)
  }, [props.currentTabId])

  const navElements = useMemo(() => {
    if (props.isEditableItem) {
      return props.elements
    } else {
      const topElementsWithResponses = topKitElementIdsWithResponse(props.responseGroup)

      return props.elements.filter(element => topElementsWithResponses.includes(element.get('parent-element-id')))
    }
  }, [props.elements, props.responseGroup])

  const showDeleteAll = props.isEditableItem && !props.responseGroup.isEmpty()

  return (
    <div className='item-nav-elements'>
      <nav className='item-navigation'>
        {
          navElements.map(element => (
            <ItemNavElement
              element={element}
              key={element.get('id')}
              currentTabId={currentTabId}
              setCurrentTab={props.setCurrentTab}
            />
          ))
        }
      </nav>
      <MobileItemNavElements
        {...props}
        currentTabId={currentTabId}
        navElements={navElements}
      />
      {
        showDeleteAll && <DeleteCompoundResponseGroup {...props} />
      }
    </div>
  )
}

ItemNavElements.propTypes = {
  currentTabId: PropTypes.string,
  elements: PropTypes.instanceOf(List),
  isEditableItem: PropTypes.bool,
  responseGroup: PropTypes.instanceOf(Map),
  setCurrentTab: PropTypes.func,
  userConfig: PropTypes.instanceOf(Map)
}

export default ItemNavElements
