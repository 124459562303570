import PropTypes from 'prop-types'

import {cobrandingPrefixText} from '../../lib/cobrandingTools'

import './mobileFooterEverplansBrandedCobranding.scss'


const MobileFooterEverplansBrandedCobranding = ({cobranding}) => (
  <footer className='mobile-footer-everplans-branded-cobranding'>
    {cobranding.cobranding_prefix}
    <img alt={cobrandingPrefixText(cobranding)} src={cobranding.header_logo} />
  </footer>
)

MobileFooterEverplansBrandedCobranding.defaultProps = {
  cobranding: {
    cobranding_prefix: '',
    cobrandable: {
      name: ''
    },
    header_logo: ''
  }
}

MobileFooterEverplansBrandedCobranding.propTypes = {
  cobranding: PropTypes.shape({
    cobranding_prefix: PropTypes.string,
    cobrandable: {
      name: PropTypes.string
    }
  })
}

export default MobileFooterEverplansBrandedCobranding
