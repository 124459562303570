import {Component} from 'react'
import PropTypes from 'prop-types'

import TfaInfo from './TfaInfo'
import TfaSetup from './TfaSetup'
import LegacyLoader from '../../../../shared_components/LegacyLoader'
import PageTitle from '../../../../shared_components/PageTitle'
import HeaderFlavor from '../../../../shared_components/header/HeaderFlavor'

import {accountSettingsUrl, redirectCorpMaxTfa} from '../../../../lib/urlTools'
import routerUtils from '../../../../lib/routerUtils'
import SessionStore from '../../stores/SessionStore'
import TfaConfigStore from '../../stores/TfaConfigStore'
import TfaWizardStore from '../../stores/TfaWizardStore'

import './tfaSettings.scss'


class TfaSettings extends Component {
  constructor() {
    super()

    this.state = {
      loading: !TfaConfigStore.getTfaConfig(),
      tfaConfig: TfaConfigStore.getTfaConfig(),
      wizardActive: TfaWizardStore.active()
    }

    this.onUpdate = this.onUpdate.bind(this)
  }

  componentDidMount() {
    redirectCorpMaxTfa()
    SessionStore.addChangeListener(this.onUpdate)
    TfaConfigStore.addChangeListener(this.onUpdate)
    TfaWizardStore.addChangeListener(this.onUpdate)
  }

  onUpdate() {
    var updatedTfaConfig = TfaConfigStore.getTfaConfig()

    // If the user just now disabled TFA send them back to their account settings, the only entry point for doing that:
    if (this.isTfaEnabled() && updatedTfaConfig.status === 'disabled') {
      routerUtils.setLocation(accountSettingsUrl)
    } else {
      this.setState({
        loading: !updatedTfaConfig,
        tfaConfig: updatedTfaConfig,
        wizardActive: TfaWizardStore.active()
      })
    }
  }

  componentWillUnmount() {
    SessionStore.removeChangeListener(this.onUpdate)
    TfaConfigStore.removeChangeListener(this.onUpdate)
    TfaWizardStore.removeChangeListener(this.onUpdate)
  }

  isTfaEnabled () { return (this.state.tfaConfig && this.state.tfaConfig.status === 'enabled') }

  exitTfa (event) {
    if (event) event.preventDefault()

    routerUtils.setLocation(accountSettingsUrl)
  }

  render() {
    return (
      <LegacyLoader loading={this.state.loading}>
        <HeaderFlavor flavor='app' />
        <PageTitle title='Two-Step Verification Settings' />
        <div className='tfa-settings'>
          {
            this.isTfaEnabled() && !this.state.wizardActive ?
              <TfaInfo tfaConfig={this.state.tfaConfig} /> :
              <TfaSetup
                exitTfa={this.exitTfa}
                onboarding={false}
                tfaConfig={this.state.tfaConfig}
                wizardActive={this.state.wizardActive}
              />
          }
        </div>
      </LegacyLoader>
    )
  }
}

TfaSettings.propTypes = {
  currentUser: PropTypes.object
}

export default TfaSettings
