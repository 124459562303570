import PropTypes from 'prop-types'

import WizardActions from '../../../actions/WizardActions'

import './backupCode.scss'


const Enabled = props => {
  const handleRedirect = () => {
    WizardActions.allDone()
    props.closer()
  }

  return (
    <div>
      <h3>All Done</h3><hr />
      <div className='wizard-body'>
        <p>Your account is now extra secure with two-step verification. You're all set to continue!</p>
      </div>
      <div>
        <button className='wizard-primary-btn btn' onClick={handleRedirect}>Continue</button>
      </div>
    </div>
  )
}

Enabled.propTypes = {
  closer: PropTypes.func
}

export default Enabled
