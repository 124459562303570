import PropTypes from 'prop-types'

import {List} from 'immutable'

import {Typography, useMediaQuery, useTheme} from '@mui/material'

import AddDeputySection from './AddDeputySection'
import DeputyForSection from './DeputyForSection'
import MyDeputiesSection from './MyDeputiesSection'

import './sharingModuleWithDeputies.scss'

const SharingModuleWithDeputies = props => {
  const theme = useTheme()

  const isMobileSm = useMediaQuery(
    theme.breakpoints.between('mobileSm', 'mobileMd')
  )
  const isTabletLg = useMediaQuery(
    theme.breakpoints.between('tabletLg', 'desktopLg')
  )
  const isDesktopLg = useMediaQuery(
    theme.breakpoints.between('desktopLg', 'desktopXl')
  )

  const numberOfAvatarsToDisplay = () => {
    if (isTabletLg)
      return 2
    else if (isMobileSm || isDesktopLg)
      return 3
    else
      return 4
  }

  return (
    <div className='sharing-module-with-deputies'>
      <Typography className='heading' variant='h3'>
        Importance of sharing
      </Typography>
      <Typography className='body-text'>
        Make your life easier. Share essential info with someone you trust.
      </Typography>
      <AddDeputySection {...props} />
      <MyDeputiesSection
        toggleDeputyPermissionsModal={props.toggleDeputyPermissionsModal}
        deputies={props.deputies}
        numberOfAvatarsToDisplay={numberOfAvatarsToDisplay()}
      />
      <DeputyForSection
        deputizers={props.deputizers}
        numberOfAvatarsToDisplay={numberOfAvatarsToDisplay()}
      />
    </div>
  )
}

SharingModuleWithDeputies.propTypes = {
  deputies: PropTypes.instanceOf(List),
  deputizers: PropTypes.instanceOf(List),
  toggleDeputyPermissionsModal: PropTypes.func
}

export default SharingModuleWithDeputies
