import {PureComponent} from 'react'
import {Map} from 'immutable'
import PropTypes from 'prop-types'

import {Box} from '../../blocks/Boxes'
import routerUtils from '../../../../../lib/routerUtils'
import {newItemPath, preAccountEntryPath} from '../../../../../lib/urlTools'
import {firstElementIdForItem} from '../../../../../lib/plan_data/item'
import {categorySlug} from '../../../../../lib/plan_data/category'


export default class ManualAccountEntry extends PureComponent {
  constructor() {
    super()

    this.addResponseGroup = this.addResponseGroup.bind(this)
  }
  addResponseGroup() {
    routerUtils.push(
      newItemPath({
        elementId: firstElementIdForItem({
          item: this.props.item,
          kits: this.props.kits
        }),
        itemSlug: this.props.item.get('slug'),
        slug: categorySlug(this.props.category),
        path: preAccountEntryPath(this.props.location.pathname),
        queryParams: this.props.location.query
      })
    )
  }

  render() {
    return (
      <Box className='manual-account-entry' onClick={this.addResponseGroup}>
        Enter account details manually
      </Box>
    )
  }
}

ManualAccountEntry.propTypes = {
  category: PropTypes.instanceOf(Map),
  context: PropTypes.string,
  item: PropTypes.instanceOf(Map),
  kits: PropTypes.instanceOf(Map),
  location: PropTypes.object
}
