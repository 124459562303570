import {Component} from 'react'
import {List, Map} from 'immutable'
import PropTypes from 'prop-types'

import {Accept, Cancel} from '../../../dashboard/src/components/blocks/Buttons'
import Closer from '../../..//shared_components/core/closer/Closer'
import Form from '../../../shared_components/forms/v2/Form'
import NextBestActionWizardHeader from './NextBestActionWizardHeader'
import Modals from '../../core/modals/Modals'
import Fields from '../../../shared_components/forms/v2/fields/index'

import {humanizedFirstNamesWithConjunction} from '../../../lib/nameTools'
import {ownershipId} from '../../../lib/responsesHelper'
import {userOwnerOwnership} from '../../../lib/userTools'

import './ownershipScreen.scss'


class OwnershipScreen extends Component {
  constructor(props) {
    super(props)

    this.back = this.back.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.closer = this.closer.bind(this)
    this.defaultOwnership = userOwnerOwnership({userConfig: props.userConfig, ownerships: props.ownerships}).get('id')
    this.next = this.next.bind(this)
    this.onOwnershipChange = this.onOwnershipChange.bind(this)
    this.ownershipItems = this.ownershipItems(props)
    // Only need to show the back button if the first section is not the ownership (aka the gather screen)
    this.showBackButton = props.section !== 'ownership'
  }

  // This is needed to make sure the event is logged when moving back and forth between screens
  componentDidUpdate(prevProps) {
    if (this.props.section === 'ownership' && prevProps.section !== 'ownership')
      this.props.logEvent({name: 'viewed_household_ownership_question'})
  }

  closeModal() {
    this.props.logEvent({name: 'closed_add_new'})
    this.props.toggleModal()
  }

  closer() { return <Closer closer={this.closeModal} /> }

  ownershipItems(props) {
    return props.ownerships.map(ownership =>
      Map({
        label: ownership.get('first-name'),
        value: ownership.get('id')
      })
    ).push(
      Map({
        label: `Both ${humanizedFirstNamesWithConjunction(props.ownerships, 'and')}`,
        value: 'both'
      })
    )
  }

  formData() { return this.refs.form ? this.refs.form.formData() : Map() }

  // Used to set the ownership for the responses when a user navigates away from this section
  responsesOwnership() {
    const ownershipValue = this.formData().getIn(['ownership', 'value'])

    if (ownershipValue === 'both')
      return this.props.ownerships.map(ownership => ownershipId(ownership))
    else
      return List([parseInt(ownershipValue, 10)])
  }

  next() {
    this.props.logEvent({name: 'continued_on_wizard'})
    this.props.setOwnershipWithSection({ownership: this.responsesOwnership(), nextSection: 'fields'})
  }

  back() {
    this.props.logEvent({name: 'clicked_back_on_wizard'})
    this.props.setOwnershipWithSection({ownership: this.responsesOwnership(), nextSection: 'gather'})
  }

  // Used to render the value of the radio list, if no previous ownership has been added (aka the first time this section renders)
  // then the default is used the otherwise returns the previously added value (aka if a user moves away from this section and back)
  ownershipValue() {
    if (this.props.responsesOwnership.isEmpty())
      return this.defaultOwnership
    else
      return this.props.responsesOwnership.size > 1 ? 'both' : this.props.responsesOwnership.first().toString()
  }

  onOwnershipChange() { this.props.logEvent({name: 'updated_household_ownership'}) }

  render() {
    return (
      <Modals.PopUpModalLarge className='ownership-screen' showModal={this.props.section === 'ownership'} closerComponent={this.closer}>
        <Form className='core' ref='form'>
          <NextBestActionWizardHeader {...this.props} />
          <Fields.VerticalRadioList
            data={
              Map({
                id: 'ownership',
                items: this.ownershipItems,
                value: this.ownershipValue(),
                legend: 'Who are you adding this entry for?',
                name: 'ownership'
              })
            }
            onChange={this.onOwnershipChange}
          />
          {
            this.showBackButton && (
              <Cancel onClick={this.back}>
                Back
              </Cancel>
            )
          }
          <Accept onClick={this.next}>
            Next
          </Accept>
        </Form>
      </Modals.PopUpModalLarge>
    )
  }
}


OwnershipScreen.propTypes = {
  formData: PropTypes.instanceOf(Map),
  logEvent: PropTypes.func,
  ownerships: PropTypes.instanceOf(List),
  responsesOwnership: PropTypes.instanceOf(List),
  section: PropTypes.string,
  setOwnershipWithSection: PropTypes.func,
  toggleModal: PropTypes.func,
  userConfig: PropTypes.instanceOf(Map)
}

export default OwnershipScreen
