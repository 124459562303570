import {useEffect} from 'react'
import {Map} from 'immutable'
import PropTypes from 'prop-types'

import {Button} from '../../blocks/Buttons'
import HowDoDeputiesGainAccess from './HowDoDeputiesGainAccess'
import WhatIsADeputy from './WhatIsADeputy'
import WhoShouldIChoose from './WhoShouldIChoose'

import {setViewedFlagV2} from '../../../../../lib/userTools'

import './noDeputies.scss'

const NoDeputies = props => {
  useEffect(() => {
    setViewedFlagV2({
      user: props.currentUser,
      flagToSet: 'first_visit_to_after_death_screen',
      updateResource: props.updateResource
    })
  }, [])

  return (
    <div className='flex-container no-deputies'>
      <div className='no-deputies-image' />
      <div className='flex-child add-deputy'>
        <Button onClick={props.goToAddDeputy}>Add your first Deputy</Button>
      </div>
      <WhatIsADeputy />
      <WhoShouldIChoose />
      <HowDoDeputiesGainAccess />
      {props.children}
    </div>
  )
}

NoDeputies.propTypes = {
  currentUser: PropTypes.instanceOf(Map),
  goToAddDeputy: PropTypes.func,
  updateResource: PropTypes.func
}

export default NoDeputies
