import classnames from 'classnames'
import {fromJS} from 'immutable'
import PropTypes from 'prop-types'

import Fields from './forms/v2/fields/index'

import './unorderedList.scss'
import './UnorderedListWithSelect.scss'

const UnorderedListWithSelect = props => (
  <ul className={classnames('unordered-list', 'unordered-list-with-select', props.className)}>
    {
      [].concat(props.children).map(
        (child, index) => (
          <div key={index}>
            <li className='item' key={child.toString()}>{child}</li>
            {
              props.selectPosition === index &&
              (
                <Fields.Select
                  className='unordered-list-select'
                  data={
                    fromJS({
                      id: 'unordered-list-select',
                      items: props.options,
                      name: 'unordered-list-select',
                      value: props.selectValue
                    })
                  }
                />
              )
            }
          </div>
        )
      )
    }
  </ul>
)

UnorderedListWithSelect.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object),
  selectPosition: PropTypes.number,
  selectValue: PropTypes.string
}

export default UnorderedListWithSelect
