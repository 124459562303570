import FuneralExpenseAssessmentScreensContainer from './FuneralExpenseAssessmentScreensContainer'

import {
  FINAL_WISHES_INTRO,
  CONDITIONAL_KEY_DICTIONARY
} from '../configurations/gapFillerAssessmentIntroConfiguration'


const FuneralExpenseAssessmentIntroContainer = props => (
  <FuneralExpenseAssessmentScreensContainer
    {...props}
    initialScreenKey='celebration'
    screenDictionary={FINAL_WISHES_INTRO}
    conditionalKeyDictionary={CONDITIONAL_KEY_DICTIONARY}
  />
)

export default FuneralExpenseAssessmentIntroContainer
