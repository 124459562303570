import classnames from 'classnames'
import {connect} from 'react-redux'
import {Map} from 'immutable'
import PropTypes from 'prop-types'
import {PureComponent} from 'react'

import Closer from '../../../../../shared_components/core/closer/Closer'
import Loader from '../../../../../shared_components/NewLoader'
import ManualAccountEntry from './ManualAccountEntry'
import PlaidLinkApp from './PlaidLinkApp'
import Modals from '../../../../../shared_components/core/modals/Modals'

import {categoryByItem} from '../../../../../lib/plan_data/category'
import {currentUserConfig} from '../../../../../lib/userTools'
import {itemBySlug} from '../../../../../lib/plan_data/item'
import {preAccountEntryPath} from '../../../../../lib/urlTools'
import routerUtils from '../../../../../lib/routerUtils'

import './accountEntryMethodModal.scss'


export class AccountEntryMethodModal extends PureComponent {
  constructor() {
    super()

    // This `loading` state is only used when the user chooses to import accounts. It allows us to
    // hide the choices below and instead show a spinner after the user authenticates with Plaid
    // and should be sent to the account selection screen. Without this there was previously a
    // flicker where the user saw import or manual entry again before being sent to the next screen.
    // This is not needed for manual entry because in that case we send the user to a new path and
    // this component goes off them DOM entirely.
    this.state = {loading: false}

    this.closeModal = this.closeModal.bind(this)
    this.itemHeaderText = this.itemHeaderText.bind(this)
    this.modalCloser = this.modalCloser.bind(this)
    this.toggleLoading = this.toggleLoading.bind(this)
  }

  closeModal() {
    routerUtils.push(preAccountEntryPath(this.props.location.pathname))
  }

  modalCloser() { return <Closer closer={this.closeModal} /> }

  itemHeaderText() {
    switch (this.props.item.get('header').toLowerCase()) {
      case 'accounts and assets':
        return 'account'
      case 'loans':
        return 'loan'
      case 'credit cards':
        return 'credit card'
      default:
        return 'account'
    }
  }

  toggleLoading() { this.setState({loading: !this.state.loading}) }

  render() {
    const item = this.props.item

    return (
      <Modals.PopUpModalLarge className='modal account-entry-method-modal' closerComponent={this.modalCloser} showModal>
        {this.state.loading && <Loader loading />}
        <span className={classnames({hidden: this.state.loading})}>
          <h2>How do you want to enter your {this.itemHeaderText()} information?</h2>
          <PlaidLinkApp
            item={item}
            className='box core' // Ensure the `button` looks like our `Box` component and any part of it is clickable
            closeModal={this.closeModal}
            text='Import account details from my bank'
            toggleParentLoading={this.toggleLoading} // Renaming the prop so it makes more sense in the child component.
            userConfig={this.props.userConfig}
            isNextBestAction={this.props.location.query?.type === 'full-item'}
          />
          <ManualAccountEntry
            {...this.props}
            item={item}
          />
        </span>
      </Modals.PopUpModalLarge>
    )
  }
}

AccountEntryMethodModal.propTypes = {
  closeModal: PropTypes.func,
  item: PropTypes.instanceOf(Map),
  location: PropTypes.object,
  userConfig: PropTypes.instanceOf(Map),
  showModal: PropTypes.bool
}

const mapStateToProps = (state, {params}) => {
  const userConfig = currentUserConfig(state.api)
  const item = itemBySlug({itemSlug: params.itemSlug, items: state.items})
  const category = categoryByItem({categories: state.categories, item})

  return {
    item,
    userConfig,
    category,
    kits: state.kits
  }
}

export default connect(mapStateToProps, {})(AccountEntryMethodModal)
