import LegacyActionFactory from '../../../shared_components/LegacyActionFactory'

const PartnerActions = new LegacyActionFactory([
  'GOT_PARTNERS',
  'GOT_PARTNER'
])

PartnerActions.buildBoundGet(
  'partners',
  'partners',
   PartnerActions.Types.GOT_PARTNERS
)

PartnerActions.buildBoundGet(
  'partner',
  'partners/show_for_user',
  PartnerActions.Types.GOT_PARTNER
)

export default PartnerActions
