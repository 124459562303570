import {useState} from 'react'
import PropTypes from 'prop-types'

import showInputIcon from '../shared_assets/v2/unmask-icon.svg'
import hideInputIcon from '../shared_assets/v2/mask-icon.svg'
import Logger from '../lib/NewLogger'

import './maskedText.scss'

const MaskedText = ({children, maskText, context, itemName}) => {
  if (maskText) {
    const [maskResponse, toggleResponseMask] = useState(true)
    let icon = hideInputIcon
    let toggleText = 'Hide'

    if (maskResponse) {
      icon = showInputIcon
      toggleText = 'Show'
    }

    const toggleResponseMaskAndLogEvent = () => {
      Logger.log({
        name: maskResponse ? 'clicked_unmask_field' : 'clicked_mask_field',
        payload: {
          context,
          item: itemName,
          wildcard: itemName
        }
      })
      toggleResponseMask(!maskResponse)
    }

    return (
      <div className='masked-text'>
        {
          maskResponse ?
            (
              <div>
                {[...Array(8)].map((_element, index) => <span key={index} className='dot' />)}
              </div>
            ) :
            <div className='children-text'>{children}</div>
        }
        <div className='mask-icon-and-text' onClick={toggleResponseMaskAndLogEvent}>
          <img alt='password show/hide icon' className='mask-icon' src={icon} />
          {toggleText}
        </div>
      </div>
    )
  } else {
    return children
  }
}

MaskedText.defaultProps = {maskText: false}

MaskedText.propTypes = {
  children: PropTypes.node,
  maskText: PropTypes.bool,
  context: PropTypes.string,
  itemName: PropTypes.string
}


export default MaskedText
