import classnames from 'classnames'
import PropTypes from 'prop-types'

import CircleIconWrapper from './CircleIconWrapper'

import circleCheck from '../../../../../../shared_assets/v2/circleCheck.png'

import './boxWithTextAndCircleCheckWithNumber.scss'

const BoxWithTextAndCircleCheckWithNumber = ({className, textClass, children}) => (
  <div className='box-with-text-and-circle-check-with-number flex-child flex-container'>
    <div className={classnames(textClass, 'flex-child flex-container')}>
      <CircleIconWrapper>
        <img alt='Circle Check Icon' src={circleCheck} />
      </CircleIconWrapper>
      <div className={className}>
        {children}
      </div>
    </div>
  </div>
)

BoxWithTextAndCircleCheckWithNumber.propTypes = {
  textClass: PropTypes.string
}

export default BoxWithTextAndCircleCheckWithNumber
