import {useState} from 'react'
import {Map} from 'immutable'
import PropTypes from 'prop-types'

import SendEmailIcon from '../icons/SendEmailIcon'
import {useNotificationContext} from '../../../../shared_components/notifications/NotificationContext'

import './resendInvite.scss'


const ResendInvite = props => {
  const [sendingInvite, setSendingInvite] = useState(false)
  const {alwaysNotify} = useNotificationContext()

  const deputyOwnable = props.deputy.get('ownable')

  const resendInvite = event => {
    if (event)
      event.preventDefault()

    setSendingInvite(true)

    const payload = {
      type: 'deputy-invites',
      id: deputyOwnable.get('id'),
      attributes: {
        resend: 'true'
      }
    }

    props.updateResource(payload)
      .then(() => {
        alwaysNotify.shortSuccess(`Your invitation has been sent again to ${deputyOwnable.get('email')}.`)
      })
      .catch(errors => {
        const errorsList = errors.response.data.errors.map(error => error.title)
        alwaysNotify.shortError(errorsList)
      })
      .then(() => { setSendingInvite(false) })
  }

  if (sendingInvite) {
    return (
      <div className='resend-invite flex-container'>
          Sending
        <span>
          <i>.</i>
          <i>.</i>
          <i>.</i>
        </span>
      </div>
    )
  } else {
    return (
      <div className='resend-invite flex-container' onClick={resendInvite}>
        <SendEmailIcon />
        <span>Resend Invitation</span>
      </div>
    )
  }
}


ResendInvite.propTypes = {
  deputy: PropTypes.instanceOf(Map),
  updateResource: PropTypes.func
}

export default ResendInvite
