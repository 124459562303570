import {useEffect} from 'react'
import PropTypes from 'prop-types'

import routerUtils from '../../../lib/routerUtils'
import Loader from '../../../shared_components/NewLoader'

const AccountSettingsRoutesRedirect = props => {
  useEffect(() => {
    routerUtils.push(`/cm${props.location.pathname}`)
  }, [])

  return <Loader loading={true} />
}

AccountSettingsRoutesRedirect.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string
  })
}

export default AccountSettingsRoutesRedirect
