import PropTypes from 'prop-types'
import classnames from 'classnames'

import routerUtils from '../../../../lib/routerUtils'
import Logger from '../../../../lib/NewLogger'

import AddressBookLink from './AddressBookLink'

import './addressBookLinkContainer.scss'

const AddressBookLinkContainer = props => {
  const logAndNavigateToAddressBook = () => {
    Logger.log({
      name: 'clicked_address_book',
      payload: {context: 'nav'}
    })
    routerUtils.push('/cm/address-book')
  }

  return (
    <div className='address-book-link-container'>
      <div className='nav-mobile-link'>
        <AddressBookLink
          className={classnames(props.className, {
            'hide-download-link-mobile': props.hideDownloadLink
          })}
          mobile
          onClick={logAndNavigateToAddressBook}
        />
      </div>
      <div className='nav-non-mobile-link'>
        <AddressBookLink
          className={classnames(props.className, {
            'hide-download-link': props.hideDownloadLink
          })}
          onClick={logAndNavigateToAddressBook}
        />
      </div>
    </div>
  )
}

AddressBookLinkContainer.propTypes = {
  hideDownloadLink: PropTypes.bool
}

export default AddressBookLinkContainer
