import PropTypes from 'prop-types'
import {Map} from 'immutable'

import {BoxGutterSmall} from '../blocks/Boxes'
import {TextGutterSmall, Text} from '../blocks/Texts'
import ClockIcon from '../icons/ClockIcon'
import DeputyPermissionsSummary from './permissions/DeputyPermissionsSummary'
import ResendInvite from './ResendInvite'
import TrashCanIcon from '../icons/TrashCanIcon'

import {capitalize} from '../../../../lib/tools'
import {corpMaxRemoveDeputyPath, corpMaxDeputyViewingHistoryPath} from '../../../../lib/urlTools'
import {createdAtDate} from '../../../../lib/dateTools'
import {humanizedFullName} from '../../../../lib/nameTools'
import routerUtils from '../../../../lib/routerUtils'

import './deputyBox.scss'

const DeputyBox = props => {
  const deputyOwnable = props.deputy.get('ownable')

  const goToRemoveDeputy = () => routerUtils.push(corpMaxRemoveDeputyPath(props.deputy.get('id')))
  const goToViewingHistory = () => routerUtils.push(corpMaxDeputyViewingHistoryPath(deputyOwnable.get('id')))

  const deputyStatus = deputyOwnable.get('status')
  const pendingDeputy = deputyStatus === 'pending'

  return (
    <BoxGutterSmall className='deputy-box flex-container flex-child'>
      <div className='flex-child flex-container deputy-item'>
        <section className='flex-child'>
          <div className='initial'>{deputyOwnable.get('first-name', '').charAt(0)}</div>
          <div className='full-name'>{humanizedFullName(deputyOwnable)}</div>
          <Text className='thin-text small-text'>{deputyOwnable.get('email')}</Text>
        </section>
        <section className='action-bar flex-child flex-container destroy-action' onClick={goToRemoveDeputy}>
          <TrashCanIcon />
            Remove
        </section>
      </div>
      <DeputyPermissionsSummary {...props} />
      <div className='flex-child flex-container deputy-item'>
        <section className='flex-child status'>
          <div className='section-header-text'>Status</div>
          <div className='section-details'>
            <TextGutterSmall className={deputyStatus}>{capitalize(deputyStatus)}</TextGutterSmall>
            {
              pendingDeputy && <TextGutterSmall className='thin-text'>Invitation not yet accepted</TextGutterSmall>
            }
            {
              !props.deputy.get('viewed-items').isEmpty() && (
                <TextGutterSmall className='thin-text'>
                    Last View: {createdAtDate(props.deputy.get('viewed-items'))}
                </TextGutterSmall>
              )
            }
          </div>
        </section>
        {
          pendingDeputy ?
            <section className='action-bar flex-child'>
              <ResendInvite updateResource={props.updateResource} deputy={props.deputy} />
            </section> :
            <section className='action-bar flex-container flex-child update-action' onClick={goToViewingHistory}>
              <ClockIcon />
                Viewing History
            </section>
        }
      </div>
    </BoxGutterSmall>
  )
}

DeputyBox.propTypes = {
  currentUser: PropTypes.instanceOf(Map),
  deputy: PropTypes.instanceOf(Map),
  readEndpoint: PropTypes.func,
  hasDeputyWithDeferredItems: PropTypes.bool,
  updateResource: PropTypes.func,
  userConfig: PropTypes.instanceOf(Map)
}

export default DeputyBox
