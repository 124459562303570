import {Component} from 'react'
import {Map} from 'immutable'
import PropTypes from 'prop-types'

import Closer from '../../../../../../shared_components/core/closer/Closer'
import Modals from '../../../../../../shared_components/core/modals/Modals'
import ViewForm from './ViewForm'

import {apiV2MetaDataCall} from '../../../../../../lib/apiV2Tools'
import {
  formatSelectedElementsFromView,
  formatDependentResponseElements,
  formatSelectedConditionalElementsFromView
} from '../../../../../../lib/viewTools'


export default class UpdateViewFormController extends Component {
  constructor() {
    super()

    this.state = {processing: false, showModal: false}

    this.submitView = this.submitView.bind(this)
    this.closer = this.closer.bind(this)
  }

  closer() { return <Closer closer={this.props.closeUpdateViewModal} /> }

  submitView(viewAttributes) {
    this.setState({processing: true})

    const resource = {
      type: 'views',
      id: this.props.view.get('id'),
      attributes: {...viewAttributes, 'version-id': this.props.view.get('version-id')}
    }

    apiV2MetaDataCall({
      apiCall: this.props.updateResource,
      resource
    }).then(() => {
      this.setState({processing: false})
      this.props.closeUpdateViewModal()
    })
  }


  render() {
    const {view, elements, listMappings, kits} = this.props

    return (
      <div className='update-view-form-controller'>
        <Modals.FullHeightModalLarge showModal={this.props.showModal} closerComponent={this.closer}>
          <ViewForm
            {...this.props}
            toggleModal={this.props.closeUpdateViewModal}
            processing={this.state.processing}
            submitView={this.submitView}
            selectedElements={formatSelectedElementsFromView({view, kits, listMappings, elements})}
            selectedConditionalElements={formatSelectedConditionalElementsFromView({view, kits, listMappings, elements})}
            dependentResponseElements={formatDependentResponseElements(this.props.view)}
          />
        </Modals.FullHeightModalLarge>
      </div>
    )
  }
}

UpdateViewFormController.propTypes = {
  closeUpdateViewModal: PropTypes.func,
  elements: PropTypes.instanceOf(Map),
  listMappings: PropTypes.instanceOf(Map),
  kits: PropTypes.instanceOf(Map),
  showModal: PropTypes.bool,
  updateResource: PropTypes.func,
  view: PropTypes.instanceOf(Map)
}
