import {useState, useEffect, useRef} from 'react'
import {connect} from 'react-redux'
import {Map} from 'immutable'
import PropTypes from 'prop-types'
import {createResource, readEndpoint, updateResource} from 'redux-json-api'

import Form from '../../../../shared_components/forms/v2/Form'
import Loader from '../../../../shared_components/NewLoader'
import PageTitle from '../../../../shared_components/PageTitle'
import NewsLetterSubscription from './NewsLetterSubscription'

import {Cancel, Accept} from '../blocks/Buttons'
import {currentUserConfig} from '../../../../lib/userTools'
import {cleanResponse} from '../corp_max/redux/apiHelper'
import {useNotificationContext} from '../../../../shared_components/notifications/NotificationContext'
import routerUtils from '../../../../lib/routerUtils'
import {accountSettingsUrl} from '../../../../lib/urlTools'

import './emailPreferences.scss'

export const EmailPreferences = props => {
  const [processing, setProcessing] = useState(false)
  const emailPreferencesRef = useRef()
  const {alwaysNotify} = useNotificationContext()

  useEffect(() => {
    readEndpoint(`email-preferences/${props.userConfig.get('id')}`)
    readEndpoint('user-settings')
  }, [])

  const goToAccountSettings = () => { routerUtils.setLocation(accountSettingsUrl) }


  const postSubmit = () => ({
    success: () => {
      setProcessing(false)
      alwaysNotify.shortSuccess('Your preferences have been saved.')
    },
    error: () => {
      setProcessing(false)
      alwaysNotify.shortError('Your preferences could not be saved. Please try again.')
    }
  })

  const updateEmailPreferences = payload => {
    props.updateResource(payload)
      .then(() => {
        postSubmit().success()
      })
      .catch(() => {
        postSubmit().error()
      })
  }

  const updateNewsletterSubscriptionStatus = newsletterSubscriptionStatus => {
    const payload = {
      type: 'email-preferences',
      id: props.userConfig.get('id'),
      attributes: {
        subscribe: `${newsletterSubscriptionStatus}`
      }
    }

    updateEmailPreferences(payload)
  }

  const submit = () => {
    const formData = emailPreferencesRef.current.formData()

    const newsletterSubscriptionStatus = formData.getIn(['newsletter-subscription-status', 'value']).includes('newsletter-subscription')

    if (newsletterSubscriptionStatus !== props.subscribedToNewsletter) {
      setProcessing(true)
      updateNewsletterSubscriptionStatus(newsletterSubscriptionStatus)
    }

    goToAccountSettings()
  }

  if (props.loading) {
    return <Loader loading />
  } else {
    return (
      <div className='email-preferences'>
        <PageTitle title='Email Preferences' />
        <h3>Email Preferences</h3>
        <Form
          className='email-preferences-settings'
          ref={emailPreferencesRef}>
          <NewsLetterSubscription
            subscribedToNewsletter={props.subscribedToNewsletter}
          />
        </Form>

        <div className='button-group'>
          <Cancel onClick={goToAccountSettings} />
          <Accept processing={processing} onClick={submit}>
            Save
          </Accept>
        </div>
      </div>
    )
  }
}


EmailPreferences.propTypes = {
  createResource: PropTypes.func,
  readEndpoint: PropTypes.func,
  updateResource: PropTypes.func,
  subscribedToNewsletter: PropTypes.bool,
  userConfig: PropTypes.instanceOf(Map)
}

const mapStateToProps = ({api}) => {
  const subscribedToNewsletter = cleanResponse(api['email-preferences']).getIn([
    0,
    'subscribed-to-newsletter'
  ])
  return {
    loading: api.isReading > 0,
    userConfig: currentUserConfig(api),
    subscribedToNewsletter
  }
}

export default connect(mapStateToProps, {createResource, readEndpoint, updateResource})(EmailPreferences)
