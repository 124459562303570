import {List, Map} from 'immutable'
import PropTypes from 'prop-types'

import Fields from '../../../shared_components/forms/v2/fields'
import ContactField from '../components/corp_max/item/new_contact_item/ContactField'
import SimpleContactField from '../components/corp_max/item/new_contact_item/SimpleContactField'

import validators from '../../../shared_components/forms/v2/validators'
import {defaultView} from '../../../lib/viewTools'

const CONTACT_TYPES = List([
  'CONTACT',
  'SIMPLE_CONTACT'
])

const FormFieldByPrimitive = props => {
  const field = () => {
    if (['SELECT-MULTI', 'SELECT-MULTI-WITH-OTHER'].includes(props.element.get('ordinality')))
      return Fields.CheckboxList

    // TODO: remove 'selectType' when everything is using Transformer
    const selectType = props.element.get('select-type') || props.element.get('selectType')

    switch (selectType) {
      case 'Radio':
        return Fields.VerticalRadioList
      case 'Select':
        return Fields.Select
      default:
        break
    }

    switch (props.element.get('type')) {
      case 'SIMPLE_CONTACT':
        return SimpleContactField
      case 'CONTACT':
        return ContactField
      case 'DATE':
        return Fields.Date
      case 'FILE':
        return Fields.File
      case 'TEXT':
        return Fields.Text
      case 'TEXT-MULTI':
        return Fields.TextArea
      case 'ADDRESS':
        return Fields.Address
      case 'STATE':
        return Fields.State
      default:
        return null
    }
  }

  const addDefaultStateToSelectItem = () => {
    const data = props.data
    const label = props.element.get('placeholder') || 'Select an item'
    return data.set('items', data.get('items').unshift(Map({label, value: ''})))
  }

  const specificFieldProps = () => {
    let additionalProps = {}

    if (CONTACT_TYPES.includes(props.element.get('type')))
      additionalProps = {newContacts: props.newContacts, hidePhoneField: props.hidePhoneField}
    else if (props.element.get('type') === 'FILE')
      additionalProps = {updateFileUploadStatus: props.updateFileUploadStatus}
    // SELECT-WITHOUT-DEFAULT ordinality is used when a select is not supposed to auto-select
    // it's first item. Hence the reason why a default state is needed to be passed in.
    else if (props.element.get('ordinality') === 'SELECT-WITHOUT-DEFAULT')
      additionalProps = {data: addDefaultStateToSelectItem()}
    else if (props.element.get('type') === 'DATE')
      additionalProps = {validator: validators.responseDateValidator}

    return additionalProps
  }

  const Field = field()
  const view = props.view || defaultView(props.views)
  // Metadata tools use this and don't have the 'itemResponse'
  const itemName = props.itemResponse?.get('name') || props.item?.get('name') || ''
  // TODO: remove 'maskText' when everything is using Transformer
  const maskText = props.element.get('mask-text') || props.element.get('maskText')

  if (Field) {
    return (
      <Field
        actor={props.actor}
        context={props.context}
        data={props.data}
        data-testid={`element-${props.element.get('id')}`}
        element={props.element}
        eventType={props.eventType}
        itemResponse={props.itemResponse}
        ownEverplan={props.ownEverplan}
        itemName={itemName}
        maskText={maskText}
        nextBestActionHeader={props.nextBestActionHeader}
        view={view}
        {...specificFieldProps()}
      />
    )
  }

  return <span />
}

FormFieldByPrimitive.defaultProps = {
  item: Map(),
  hidePhoneField: false
}

FormFieldByPrimitive.propTypes = {
  actor: PropTypes.string,
  context: PropTypes.string,
  data: PropTypes.instanceOf(Map),
  element: PropTypes.instanceOf(Map),
  eventType: PropTypes.string,
  hidePhoneField: PropTypes.bool,
  item: PropTypes.object,
  itemResponse: PropTypes.instanceOf(Map),
  itemResponses: PropTypes.instanceOf(List),
  newContacts: PropTypes.instanceOf(List),
  nextBestActionHeader: PropTypes.string,
  ownEverplan: PropTypes.bool,
  updateFileUploadStatus: PropTypes.func,
  view: PropTypes.instanceOf(Map),
  views: PropTypes.instanceOf(List)
}

export default FormFieldByPrimitive
