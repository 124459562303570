import {connect} from 'react-redux'
import {Map, List} from 'immutable'
import PropTypes from 'prop-types'
import {readEndpoint, updateResource} from 'redux-json-api'
import {Fragment, useEffect} from 'react'

import routerUtils from '../../../../../lib/routerUtils'
import {TextGutterLarge} from '../../../components/blocks/Texts'
import PageTitle from '../../../../../shared_components/PageTitle'
import Loader from '../../../../../shared_components/NewLoader'
import SubscriptionStatus from './SubscriptionStatus'
import BillingHistory from './BillingHistory'
import PaymentMethod from './PaymentMethod'

import {findRawResourceByAttributeId} from '../../../../../lib/plan_data/userData'
import {currentUserConfig} from '../../../../../lib/userTools'

import {monthDayYear} from '../../../../../lib/dateTools'

import './subscriptionAndBillingContainer.scss'
import {cleanResponse} from '../../corp_max/redux/apiHelper'


export const SubscriptionAndBillingContainer = props => {
  useEffect(() => {
    props.userConfig.get('premium') ? props.readEndpoint(`billings/${props.userConfig.get('everplan-id')}`) : routerUtils.push('cm/account-settings/payment-form')
  }, [])

  const periodEnd = monthDayYear(
    props.billing.get('subscription-current-period-end')
  )

  return (
    <div className='subscription-and-billing-container'>
      <PageTitle title='Membership Status and Billing - My Account' />
      <Loader loading={props.billing.isEmpty()}>
        <h1>Subscription Status and Billing</h1>
        {
          props.billing.get('payment-gateway') === 'stripe' ?
            <Fragment>
              <SubscriptionStatus
                {...props}
                periodEnd={periodEnd}
              />
              <PaymentMethod
                {...props}
                cardLast4={props.billing.get('card-last4')}
                cardExpirationMonth={props.billing.get('card-expiration-month')}
                cardExpirationYear={props.billing.get('card-expiration-year')}
              />
              <BillingHistory
                invoices={props.billing.get('invoices', List())}
                plans={props.plans}
              />
            </Fragment> :
            <TextGutterLarge>You have an annual subscription for Everplans Premium. Please visit your Apple account to manage, edit, or cancel your Premium subscription.</TextGutterLarge>
        }
      </Loader>
    </div>
  )
}

SubscriptionAndBillingContainer.propTypes = {
  readEndpoint: PropTypes.func,
  billing: PropTypes.instanceOf(Map),
  userConfig: PropTypes.instanceOf(Map),
  plans: PropTypes.instanceOf(List)
}

const mapStateToProps = ({api}) => {
  const userConfig = currentUserConfig(api)

  const billing = findRawResourceByAttributeId({
    rawResource: api.billings,
    attribute: 'everplan-id',
    id: userConfig.get('everplan-id')
  })

  return {
    billing,
    userConfig,
    plans: cleanResponse(api.plans)
  }
}

export default connect(mapStateToProps, {readEndpoint, updateResource})(SubscriptionAndBillingContainer)


