
import {useState} from 'react'
import PropTypes from 'prop-types'

import Button from '../../../../../shared_components/Button'

import {formatNumber} from '../../../../../lib/tools'
import SettingsActions from '../../../actions/SettingsActions'

import '../tfaSetup.scss'


const CreateNewBackupCode = props => {
  const [loading, setLoading] = useState(false)

  const clearBackupCodeForm = () => {
    props.closer()
    SettingsActions.clearBackupCode()
  }

  const handleSubmit = () => {
    setLoading(true)
    SettingsActions.getNewBackupCode()
  }

  if (props.backupCode) {
    return (
      <div>
        <h3> Backup Code</h3><hr />
        <div className='wizard-body'>
          <form>
            <div className='form-group'>
              <h4>
                <b>Please write down this backup security code and store it somewhere safe. You'll need it in case you are locked out of your account</b>
              </h4>
              <p className='backup-code'>
                { formatNumber(props.backupCode) }
              </p>

              <div>
                <strong>IMPORTANT: </strong>
                If you are unable to use your primary or backup numbers to receive codes, and if you lose this backup security code, you will be permanently locked out of your account.
              </div>
            </div>
          </form>
        </div>
        <div>
          <button className='btn wizard-primary-btn' onClick={clearBackupCodeForm}>Close</button>
        </div>
      </div>
    )
  } else {
    return (
      <div>
        <h3>Are you sure?</h3>
        <hr />
        <div className='wizard-body'>
          For security reasons, we must erase your previous backup code when generating a new one. Click continue to generate a new code. Your previous code will no longer work.
        </div>
        <div>
          <Button className='wizard-secondary-btn' onClick={props.closer}>Cancel</Button>
          <Button className='wizard-primary-btn' onClick={handleSubmit} loading={loading}>Continue</Button>
        </div>
      </div>
    )
  }
}

CreateNewBackupCode.propTypes = {
  backupCode: PropTypes.string,
  closer: PropTypes.func
}

export default CreateNewBackupCode
