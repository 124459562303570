/** @module lib/item_permission_tools
 *
 * @desc
 * Methods for getting item permission information
 */

import {List, Map} from 'immutable'

export const firmHasEditPermissions = firmOwnership => !firmOwnership.get('edit-item-ids', List()).isEmpty()

// Takes an array of item ids and returns a map with the key of the item id and value of the permission type
export const requestedItemPermissions = ({itemIds, permissionType}) => (
  itemIds.reduce((permissions, itemId) => permissions.set(itemId, permissionType), Map())
)

export const hasInviteByStatus = ({invite, status}) => invite.get('status', '') === status

export const hasPendingRequestPermissionsInvite = invite => hasInviteByStatus({invite, status: 'pending'})

export const showFirmRequestPermissionLink = ({requestItemPermissionsInvite, firmConfig, allUsersDeceased}) => (
  firmConfig.get('write-access') &&
  !hasPendingRequestPermissionsInvite(requestItemPermissionsInvite) &&
  !allUsersDeceased
)

/**
 * Gets the default request item permissions for a firm user, if there are any active clients and the firm has edit permissions returns
 * those, otherwise returns the default firm user's default item permissions from the firm user's config (if any exist).
 *
 * @param {Map} firmOwnership
 * @param {Map} firmUserConfig
 * @param {Boolean} anyoneActive
 *
 * @returns {array} of the default request permission item ids for a firm user
 */
export const defaultRequestItemPermissionIds = ({anyoneActive, firmOwnership, firmUserConfig}) => {
  if (anyoneActive && firmHasEditPermissions(firmOwnership))
    return firmOwnership.get('edit-item-ids').toArray()
  else
    return firmUserConfig.get('default-item-permissions', Map()).keySeq().toArray()
}

/**
  @constant {array} Available levels of permission. Order matters so don't rearrange them!
  Used in the modals for selecting deputy item permissions.
*/
export const PERMISSION_ITEMS = [
  {
    id: 'read',
    label: 'Now',
    value: 'read'
  },
  {
    id: 'deferred_read',
    label: 'After Death',
    value: 'deferred_read'
  },
  {
    id: 'hidden',
    label: 'Never',
    value: 'hidden'
  }
]
