const GearIcon = () => (
  <svg  className='icon' version="1.1" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 30 30">
    <title>Gear Icon</title>
    <path
      d="M13.8160109,27.6580109 C14.11275,27.67725 14.461337,27.69225 14.8219891,27.69225 L14.9997065,27.69225 C15.3632935,27.69225 15.7125326,
      27.67725 16.0092717,27.6580109 L16.313837,25.5077935 C16.3810109,25.0349674 16.7328587,24.6521413 17.1985109,
      24.5451848 C18.2106848,24.313337 19.1749239,23.9226848 20.0651413,23.3849674 C20.3276413,
      23.2264891 20.6426413,23.1795326 20.9400326,23.2529022 C21.11025,23.2952935 21.2667717,
      23.3751848 21.399163,23.4854022 L23.1140543,24.7871413 C23.3370978,24.591163 23.5940543,
      24.3557283 23.8490543,24.1004022 L23.9742717,23.9751848 C24.2309022,23.7185543 24.4676413,
      23.4606196 24.6645978,23.2362717 L23.3644891,21.5050761 C23.0775326,21.1229022 23.056337,
      20.6034457 23.3100326,20.1987717 C23.8500326,19.3388804 24.2498152,18.40725 24.4992717,
      17.428337 C24.5834022,16.9388804 24.9734022,16.5505109 25.4768804,16.4817065 L27.6580761,
      16.1830109 C27.6773152,15.8862717 27.6923152,15.538337 27.6923152,15.1780109 L27.6923152,
      15.0002935 C27.6923152,14.6376848 27.6769891,14.2877935 27.65775,13.9900761 L25.5147065,
      13.6848587 C25.0422065,13.6176848 24.6597065,13.2664891 24.55275,12.801163 C24.3228587,
      11.8010543 23.93775,10.8469239 23.4081848,9.96583696 C23.3589457,9.8898587 23.3181848,
      9.80768478 23.2875326,9.72029348 C23.1587283,9.35475 23.221663,8.94844565 23.4557935,
      8.6396413 L24.7872065,6.88594565 C24.5909022,6.66257609 24.3554674,6.40594565 24.1004674,
      6.15094565 L23.9749239,6.02540217 C23.7179674,5.76844565 23.4600326,5.53203261 23.2373152,
      5.33572826 L21.5009022,6.64040217 C21.0910109,6.94888043 20.5320978,6.94692391 20.1267717,
      6.64953261 C19.2636196,6.11931522 18.3297065,5.72833696 17.349163,5.4873587 C16.8897065,
      5.37420652 16.5456848,4.99203261 16.4817717,4.52311957 L16.1830761,2.34192391 C15.886663,
      2.32301087 15.5384022,2.30768478 15.1780761,2.30768478 L15.0003587,2.30768478 C14.6380761,
      2.30768478 14.2881848,2.32301087 13.9907935,2.34225 L13.6859022,4.4921413 C13.613837,
      5.00083696 13.216337,5.39409783 12.7197065,5.47040217 C11.7375326,5.70453261 10.8006848,
      6.08866304 9.93427174,6.61268478 C9.66720652,6.77442391 9.34666304,6.82105435 9.04470652,
      6.74148913 C8.87611957,6.69746739 8.72155435,6.61627174 8.59111957,6.50572826 L6.88601087,
      5.21246739 C6.6626413,5.40877174 6.40601087,5.64420652 6.15101087,5.89953261 L6.02546739,
      6.02507609 C5.76818478,6.28203261 5.53209783,6.5396413 5.33579348,6.76301087 L6.64046739,8.49909783 C6.92318478,
      8.87540217 6.94927174,9.38572826 6.70601087,9.78844565 C6.68546739,9.82268478 6.66296739,9.85529348 6.63981522,
      9.88659783 C6.1128587,10.7448587 5.72383696,11.6742065 5.48318478,12.650837 C5.37003261,13.1096413 4.98818478,
      13.4530109 4.51959783,13.5175761 L2.34166304,13.8165978 C2.32275,14.1130109 2.30775,
      14.4615978 2.30775,14.82225 L2.30775,14.9996413 C2.30775,15.3619239 2.32307609,15.7118152 2.34198913,
      16.0092065 L4.49188043,16.3140978 C5.00122826,16.386163 5.40101087,
      16.7865978 5.47275,17.2959457 C5.47470652,17.3102935 5.47666304,17.3246413 5.47796739,17.338663 C5.71275,
      18.3012717 6.09198913,19.2195326 6.60688043,20.0699674 C6.78231522,20.3601848 6.82144565,20.7123587 6.71383696,
      21.0338804 C6.66655435,21.17475 6.59351087,21.3032283 6.49927174,21.4140978 L5.21188043,
      23.113337 C5.40851087,23.3367065 5.64394565,23.5939891 5.89959783,23.8489891 L6.02481522,23.9745326 C6.28209783,
      24.2318152 6.54003261,24.4679022 6.76275,24.6642065 L8.49883696,
      23.3595326 C8.93677174,23.0301848 9.53253261,23.0595326 9.93459783,23.3980109 C10.779163,23.9093152 11.6918804,
      24.2875761 12.6489457,24.5230109 C13.10775,24.635837 13.4514457,25.0173587 13.5160109,25.4856196 L13.8160109,
      27.6580109 Z M14.9997065,29.9999674 L14.8219891,29.9999674 C13.689163,29.9999674 12.6936196,
      29.8740978 12.6518804,29.8688804 C12.1350326,29.8026848 11.7267717,29.398337 11.6553587,28.8821413 L11.3335109,
      26.5489891 C10.6174239,26.3220326 9.9251413,26.0285543 9.26351087,
      25.6718152 L7.41948913,27.0576848 C7.00372826,27.36975 6.43144565,27.365837 6.02025,27.0482283 C5.98666304,
      27.0224674 5.19427174,26.4074674 4.39307609,25.6062717 L4.26753261,25.4810543 C3.46666304,24.6798587 2.85166304,
      23.8871413 2.82557609,23.8535543 C2.50698913,23.4413804 2.50438043,22.8668152 2.81872826,22.4513804 L4.24340217,
      20.570837 C3.89742391,19.9043152 3.61568478,19.2081196 3.40046739,18.4900761 L1.11361957,
      18.1659457 C0.599380435,18.0929022 0.19698913,17.6852935 0.131119565,
      17.1700761 C0.125902174,17.1280109 3.26086957e-05,16.1324674 3.26086957e-05,14.9996413 L3.26086957e-05,
      14.82225 C3.26086957e-05,13.6890978 0.125902174,12.6935543 0.131119565,12.6518152 C0.197315217,12.1346413 0.602315217,
      11.7260543 1.11883696,11.6552935 L3.45753261,11.3340978 C3.68481522,10.6199674 3.97731522,9.92996739 4.33307609,
      9.2696413 L2.94198913,7.41942391 C2.62992391,7.00398913 2.63383696,6.43138043 2.95177174,6.02051087 C2.97753261,5.98692391 3.59253261,5.19420652 4.39340217,
      4.39301087 L4.51894565,4.26746739 C5.3201413,3.46659783 6.1128587,2.85159783 6.14611957,2.82583696 C6.55861957,
      2.50692391 7.13383696,2.50398913 7.54927174,2.81931522 L9.43405435,4.24855435 C10.0989457,3.90388043 10.7928587,
      3.62279348 11.5089457,3.40757609 L11.8340543,1.11388043 C11.9070978,0.599315217 12.3147065,0.19725 12.8299239,
      0.131054348 C12.871663,0.125836957 13.8672065,-3.26086957e-05 15.0003587,-3.26086957e-05 L15.1780761,
      -3.26086957e-05 C16.3105761,-3.26086957e-05 17.3064457,
      0.125836957 17.3481848,0.131054348 C17.8653587,0.197576087 18.2739457,0.602576087 18.3450326,
      1.11909783 L18.6655761,3.46138043 C19.3787283,3.68768478 20.0684022,3.97953261 20.7284022,4.3346413 L22.5805761,
      2.94225 C22.9956848,2.63018478 23.5682935,2.63377174 23.9798152,2.95170652 C24.0130761,2.97746739 24.8057935,
      3.59246739 25.6069891,4.39366304 L25.7325326,4.51920652 C26.5337283,5.32040217 27.1487283,
      6.11344565 27.1744891,6.14670652 C27.4930761,6.55920652 27.4953587,
      7.13475 27.1800326,7.5498587 L25.7520978,9.43105435 C26.0980761,10.0962717 26.3798152,10.790837 26.5960109,
      11.5082283 L28.8867717,11.8343152 C29.4010109,11.9073587 29.80275,12.3149674 29.8689457,12.8298587 C29.874163,
      12.8719239 30.0000326,13.8674674 30.0000326,15.0002935 L30.0000326,15.1780109 C30.0000326,
      16.310837 29.874163,17.3063804 29.8689457,17.3481196 C29.8024239,17.8656196 29.3974239,
      18.2742065 28.8805761,18.3446413 L26.5451413,18.6645326 C26.3185109,19.3789891 26.0253587,
      20.0702935 25.6692717,20.7312717 L27.05775,22.5814891 C27.3694891,22.9965978 27.3659022,
      23.5688804 27.0479674,23.97975 C27.0222065,24.0130109 26.4075326,24.8057283 25.606337,25.6069239 L25.4807935,
      25.7324674 C24.6795978,26.533337 23.8868804,27.148663 23.8532935,27.1744239 C23.4411196,
      27.493663 22.8659022,27.4959457 22.4501413,27.1806196 L20.5643804,25.7490978 C19.9001413,
      26.0931196 19.2072065,26.3738804 18.4914457,26.5887717 L18.1656848,28.8863804 C18.0926413,29.4006196 17.6850326,29.8030109 17.1698152,
      29.8688804 C17.1280761,29.8740978 16.1325326,29.9999674 14.9997065,29.9999674 L14.9997065,29.9999674 Z M15.0003587,
      11.0265978 C12.8061196,11.0265978 11.0214457,12.8115978 11.0214457,15.0055109 C11.0214457,
      17.1994239 12.8061196,18.9840978 15.0003587,18.9840978 C17.1939457,18.9840978 18.9786196,
      17.1994239 18.9786196,15.0055109 C18.9786196,12.8115978 17.1939457,11.0265978 15.0003587,
      11.0265978 L15.0003587,11.0265978 Z M15.0003587,21.2921413 C11.5337283,21.2921413 8.71372826,
      18.4718152 8.71372826,15.0055109 C8.71372826,11.5388804 11.5337283,8.71855435 15.0003587,8.71855435 C18.466663,
      8.71855435 21.286337,11.5388804 21.286337,15.0055109 C21.286337,18.4718152 18.466663,
      21.2921413 15.0003587,21.2921413 L15.0003587,21.2921413 Z"
    />
  </svg>
)

export default GearIcon
