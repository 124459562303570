import {useEffect} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {createResource} from 'redux-json-api'
import {cleanResponse} from '../redux/apiHelper'

import PlaidLinkButton from './PlaidLinkButton'


export const PlaidLinkButtonContainer = props => {
  useEffect(() => {
    if (!props.token)
      props.createResource({type: 'plaid-link-tokens', attributes: {}})
  }, [props.token])

  if (props.token) return <PlaidLinkButton {...props} />
  else return null
}

PlaidLinkButtonContainer.propTypes = {
  token: PropTypes.string
}

const mapStateToProps = ({api}) => ({
  token: cleanResponse(api['plaid-link-tokens']).getIn([0, 'link-token'], '')
})

export default connect(mapStateToProps, {createResource})(
  PlaidLinkButtonContainer
)
