import {Map} from 'immutable'
import {useEffect} from 'react'
import PropTypes from 'prop-types'

import Closer from '../core/closer/Closer'
import Modals from '../core/modals/Modals'
import MarkdownText from '../../pro/src/components/shared_firm/settings/MarkdownText'

import {articles} from '../everplans_articles/articles'
import {contentImageUrl} from '../../lib/s3Tools'
import routerUtils from '../../lib/routerUtils'
import {useScoreLedgerEventLogger} from '../../lib/ScoreLedgerEventLogger'

import './contentModal.scss'


const ContentModal = props => {
  const {logWithNotification} = useScoreLedgerEventLogger(props.userConfig)
  const article = articles.get(props.location.query.article)
  const imageUrl = contentImageUrl(`${article.get('query')}-LARGE.jpeg`)

  useEffect(() => {
    const timer = setTimeout(() =>
      logWithNotification.readInAppArticle({article_slug: article.get('query')}),
    16000)

    return () => {
      clearTimeout(timer)
    }
  }, [])


  const removeArticleQuery = () => { routerUtils.removeQuery({hashLocation: props.location, queryKey: 'article'}) }

  const closer = () => <Closer onClick={removeArticleQuery} />


  return (
    <Modals.FullHeightModalLarge className='content-modal' closerComponent={closer} showModal>
      <header className='flex-container'>
        <div className='flex-child shared-elements'>
          <h1>{article.get('headline')}</h1>
          <img
            alt={article.get('headline')}
            className='content-modal-image'
            src={imageUrl}
          />
          <p className='summary'>
            {article.get('summary')}
          </p>
        </div>
      </header>
      <MarkdownText displayText={article.get('body')} />
    </Modals.FullHeightModalLarge>
  )
}

ContentModal.propTypes = {
  location: PropTypes.shape({
    query: PropTypes.shape({
      article: PropTypes.string
    })
  }),
  userConfig: PropTypes.instanceOf(Map)
}

export default ContentModal
