import './app-wrapper.scss'

const AppWrapper = props => (
  <div className='app-wrapper'>
    <div className='flex-container flex-container-full large-flex-container-fit'>
      {props.children}
    </div>
  </div>
)

export default AppWrapper
