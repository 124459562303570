import {styled, Tooltip} from '@mui/material'

const StyledTooltip = styled(props => (
  <Tooltip
    {...props}
    classes={{popper: props.className}}
    data-testid='tooltip'
  />
))`
  & .MuiTooltip-tooltip {
    font-size: 0.9375rem;
    padding: 30px;
  }
`

export default StyledTooltip
