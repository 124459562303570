/** @class ItemController
 *
 * @desc
 * Controller component that contains path construction methods for use in the consumer and Deputy views of
 * an Everplan. Eventually the goal is to have one controller like this for each location in the app
 * where the add, add more, edit, preview, and view component tree is used. (A larger goal is to reduce
 * the interdependence of these components, but that is a more-complicated refactor than is achievable
 * at this time.)
 */

import {List, Map} from 'immutable'
import PropTypes from 'prop-types'
import {useMemo, useState, useEffect} from 'react'
import {connect} from 'react-redux'

import Item from './Item'
import {hidePremiumFeatures} from '../../../../../lib/premiumSubscriptionTools'
import {routeContainsVerb} from '../../../lib/corpMaxRouteMatcher'

import {
  addMorePath,
  editItemPath,
  editCompoundItemPath,
  goToParentCategory,
  newCompoundItemPath,
  newItemPath,
  preCategoryPagePath,
  viewItemPath
} from '../../../../../lib/urlTools'
import {everplanOwners} from '../../../../../lib/plan_data/userData'
import {itemBySlug} from '../../../../../lib/plan_data/item'
import {useEverplanData} from '../../../../../lib/hooks'
import routerUtils from '../../../../../lib/routerUtils'
import MixpanelLogger from '../../../../../lib/MixpanelLogger'
import {allResponseGroupCount} from '../../../../../lib/plan_data/itemResponsesHelper'
import PremiumAccessDenied from '../../../../../shared_components/PremiumAccessDenied'

const ItemController = props => {
  const {itemResponses, newContacts} = useEverplanData(props.params.everplanId || props.userConfig.get('everplan-id'))
  const [hideItem, setHideItem] = useState(false)

  const eventType = props.location.query.type || 'vault'
  const conditionalValue = props.location.query.conditional_value

  const exit = () => {
    setHideItem(true) // needed to be set to stop the flickering of an empty item when deleting a response group.

    if (props.context === 'category_page')
      return goToParentCategory(props.location.pathname)

    return routerUtils.push(preCategoryPagePath(props.location.pathname))
  }

  const ownerships = useMemo(() => everplanOwners({ownership: props.deputyOwnership, ownEverplan: props.ownEverplan, owners: props.owners}), [])

  /* We are adding the query params to the general path construction so they are always present
   * when a user refreshes the page after switching to a different url on a compound item
   */
  const pathParams = ({elementId, groupId}) => ({
    ...props.params,
    elementId,
    groupId,
    path: props.location.pathname,
    queryParams: {type: eventType, conditional_value: conditionalValue}
  })

  const addMore = ({elementId, groupId}) => addMorePath(pathParams({elementId, groupId}))
  const editCompoundItem = ({elementId, groupId}) => editCompoundItemPath(pathParams({elementId, groupId}))
  const editItem = ({elementId, groupId}) => editItemPath(pathParams({elementId, groupId}))
  const newCompoundItem = ({elementId, groupId}) => newCompoundItemPath(pathParams({elementId, groupId}))
  const newItem = ({elementId, groupId}) => newItemPath(pathParams({elementId, groupId}))
  const viewItem = ({elementId, groupId}) => viewItemPath(pathParams({elementId, groupId}))

  const previousPath = () => {
    const pathname = props.location.pathname

    if (props.context === 'category_page')
      return pathname.substring(0, pathname.indexOf('/item/'))

    return 'cm/dashboard'
  }

  const responseGroupCount = allResponseGroupCount(itemResponses)
  const hidePremiumFeaturesForUser = hidePremiumFeatures({
    responseGroupCount,
    userConfig: props.userConfig,
    settings: props.settings
  })
  const hideEditFeaturesForUser = hidePremiumFeatures({
    responseGroupCount,
    userConfig: props.userConfig,
    settings: props.settings,
    action: 'edit_item'
  })
  const itemResponse = itemBySlug({items: itemResponses, itemSlug: props.params.itemSlug})
  const initialGroupId = props.params.groupId || props.params.id
  const denyEditAccess = (routeContainsVerb(props.location, ['edit', 'add-more', 'compound']) && hideEditFeaturesForUser)
  const denyAddAccess = (routeContainsVerb(props.location, ['new']) && hidePremiumFeaturesForUser && !initialGroupId)
  const denyAccess = denyEditAccess || denyAddAccess
  // If you previously had access to the page when the modal was opened then you should continue to see the page for the lifetime
  // of the modal session. This is to prevent flickering of the premium modal when a freemium user adds the last response group
  // before being prompted to upgrade.
  const previouslyDeniedAccess = useMemo(() => hidePremiumFeaturesForUser, [])

  useEffect(() => {
    if (denyAddAccess)
      MixpanelLogger.track('click_premium_item', {item_name: itemResponse.get('name'), context: props.context})
  }, [denyAddAccess, itemResponse, props.context])

  if (denyAccess && previouslyDeniedAccess)
    return <PremiumAccessDenied itemSlug={props.params.itemSlug} prevPath={previousPath()} />

  return (
    <Item
      {...props}
      hideItem={hideItem}
      ownerships={ownerships}
      newContacts={newContacts}
      itemResponse= {itemResponse}
      addMorePath={addMore}
      editCompoundItemPath={editCompoundItem}
      editItemPath={editItem}
      eventType={eventType}
      conditionalValue={conditionalValue}
      exit={exit}
      newCompoundItemPath={newCompoundItem}
      newItemPath={newItem}
      viewItemPath={viewItem}
      hidePremiumFeaturesForUser={hidePremiumFeaturesForUser}
      hideEditFeaturesForUser={hideEditFeaturesForUser}
    />
  )
}


ItemController.propTypes = {
  context: PropTypes.string,
  deputyOwnership: PropTypes.instanceOf(Map),
  location: PropTypes.shape({
    query: PropTypes.object,
    pathname: PropTypes.string
  }),
  owners: PropTypes.instanceOf(List),
  params: PropTypes.object,
  ownEverplan: PropTypes.bool,
  userConfig: PropTypes.instanceOf(Map),
  settings: PropTypes.object
}

const mapStateToProps = state => ({settings: state.api.settings})

export default connect(mapStateToProps)(ItemController)
