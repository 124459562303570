import classnames from 'classnames'
import PropTypes from 'prop-types'

import CorpCategory from '../corp_max/category/CorpCategory'

import {noop} from '../../../../lib/tools'
import routerUtils from '../../../../lib/routerUtils'

const NavCategory = props => {
  const categoryUrl = `cm/category/${props.categoryName.toLowerCase().replace(/\s/g, '-')}`

  const className = () => classnames(
    'nav-category',
    {
      active: props.router.isActive(categoryUrl)
    }
  )
  const goToCategory = () => { routerUtils.push(categoryUrl) }

  const onClick = event => {
    if (event)
      event.preventDefault()

    goToCategory()
  }

  return <CorpCategory className={className()} onClick={onClick} {...props} />
}

NavCategory.defaultProps = {
  categoryName: '',
  router: {
    isActive: noop
  }
}

NavCategory.propTypes = {
  categoryName: PropTypes.string,
  router: PropTypes.shape({
    isActive: PropTypes.func
  })
}

export default NavCategory
