import {Component} from 'react'
import PropTypes from 'prop-types'
import {Map} from 'immutable'

import {Accept} from '../../../dashboard/src/components/blocks/Buttons'
import Closer from '../../..//shared_components/core/closer/Closer'
import MarkdownText from '../../../pro/src/components/shared_firm/settings/MarkdownText'
import NextBestActionWizardHeader from './NextBestActionWizardHeader'
import Modals from '../../core/modals/Modals'

import './gatherScreen.scss'

export default class GatherScreen extends Component {
  constructor() {
    super()

    this.nextSection = this.nextSection.bind(this)
    this.closer = this.closer.bind(this)
    this.closeModal = this.closeModal.bind(this)
  }

  // This is needed to make sure the event is logged when moving back and forth between screens
  componentDidUpdate(prevProps) {
    if (this.props.section === 'gather' && prevProps.section !== 'gather') {
      this.props.logEvent({
        name: 'viewed_prompt',
        payload: {
          prompt_type: 'suggested_things_to_gather'
        }
      })
    }
  }

  nextSection() {
    this.props.setNextSection(this.props.userConfig.get('household') ? 'ownership' : 'fields')
    this.props.logEvent({name: 'continued_on_wizard'})
  }

  closeModal() {
    this.props.logEvent({name: 'closed_add_new'})
    this.props.toggleModal()
  }

  closer() { return <Closer closer={this.closeModal} /> }

  render() {
    return (
      <Modals.PopUpModalLarge className='gather-screen' showModal={this.props.section === 'gather'} closerComponent={this.closer}>
        <NextBestActionWizardHeader {...this.props} />
        <MarkdownText displayText={this.props.nextBestAction.getIn(['data', 'gather-text'])} />
        <Accept onClick={this.nextSection}>
          Continue
        </Accept>
      </Modals.PopUpModalLarge>
    )
  }
}

GatherScreen.propTypes = {
  logEvent: PropTypes.func,
  nextBestAction: PropTypes.instanceOf(Map),
  section: PropTypes.string,
  setNextSection: PropTypes.func,
  toggleModal: PropTypes.func,
  userConfig: PropTypes.instanceOf(Map)
}
