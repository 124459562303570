import {Box, styled} from '@mui/material'

/* Override any of the following styles using the MuiBox-root class */

const ModuleBox = styled(Box)(({theme}) => ({
  background: theme.palette.primary.boxBackground,
  borderRadius: '20px',
  color: theme.palette.primary.text,
  padding: '32px'
}))

export default ModuleBox
