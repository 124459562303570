import epDispatcher from '../lib/ep-dispatcher'
import LegacyActionFactory from '../shared_components/LegacyActionFactory'

const SlackActions = new LegacyActionFactory([
  'DID_SEND_SLACK_MESSAGE'
])

const SLACK_DEFAULTS = {
  channel: '#webhook-dumps',
  color: '#008000',
  title: 'Notification',
  username: 'test_bot'
}

SlackActions.sendSlackMessage = data => {
  if (data.message) {
    SlackActions.fireApi(
      'post',
      'slack',
      {
        ...SLACK_DEFAULTS,
        ...data
      },
      {
        JSONHead: 'slack',
        successAction: SlackActions.Types.DID_SEND_SLACK_MESSAGE
      }
    )
  } else {
    // If no message is included just pretend the notification was sent so nothing
    // waiting on the success hangs. --BLR
    epDispatcher.dispatch({actionType: SlackActions.Types.DID_SEND_SLACK_MESSAGE})
  }
}

export default SlackActions
