import {List, Map} from 'immutable'
import PropTypes from 'prop-types'

import GapFillerGuideModalResourceLink from './GapFillerGuideModalResourceLink'
import UnorderedList from '../../UnorderedList'


const GapFillerGuideSectionContents = props => {
  if (props.contents && !props.contents.isEmpty()) {
    return (
      <div className='gap-filler-guide-section-contents'>
        {
          props.contents.map(
            content => (
              <p className='left-column' key={content.get('text')}>
                <div>
                  <strong>{content.get('strongText')}</strong>
                  <span> {content.get('text')}</span>
                </div>
                <GapFillerGuideModalResourceLink
                  resource={content.get('resource')}
                  context={props.context}
                  itemResponse={props.itemResponse}
                />
                {
                  content.get('listItems') &&
                  <UnorderedList className='notes' children={content.get('listItems').toJS()} />
                }
              </p>
            )
          )
        }
      </div>
    )
  } else {
    return null
  }
}

GapFillerGuideSectionContents.propTypes = {
  contents: PropTypes.instanceOf(List),
  context: PropTypes.string,
  itemResponse: PropTypes.instanceOf(Map)
}

export default GapFillerGuideSectionContents
