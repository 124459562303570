import {useState} from 'react'
import PropTypes from 'prop-types'

import WizardActions from '../../../actions/WizardActions'
import Button from '../../../../../shared_components/Button'

import {formatNumber} from '../../../../../lib/tools'

import './backupCode.scss'


const BackupCode = props => {
  const [loading, setLoading] = useState(false)

  const handleSubmit = event => {
    if (event) event.preventDefault()
    setLoading(true)
    WizardActions.enableTfa()
  }

  return (
    <div>
      <h3> Backup Code</h3>
      <hr />
      <div>
        <form className='wizard-body' onSubmit={handleSubmit}>
          <div className='form-group'>
            Please write down this backup security code and store it somewhere safe. You'll need it in case you are locked out of your account.
            <p className='backup-code'>
              { formatNumber(props.backupCode) }
            </p>
            <p>
              <strong>IMPORTANT: </strong>If you are unable to use your primary or backup numbers to receive codes,
              and if you lose this backup security code, you will be permanently locked out of your account.
            </p>
          </div>
        </form>
      </div>
      <div>
        <Button className='wizard-primary-btn' onClick={handleSubmit} loading={loading}>Continue</Button>
      </div>
    </div>
  )
}

BackupCode.propTypes = {backupCode: PropTypes.string}

export default BackupCode
