import PropTypes from 'prop-types'

import {Button} from '../../../../../dashboard/src/components/blocks/Buttons'
import {
  TextGutterExtraLarge,
  Text
} from '../../../../../dashboard/src/components/blocks/Texts'
import Loader from '../../../../NewLoader'

import overlappingCreditCards from '../../../../../shared_assets/v2/overlapping-credit-cards.png'
import monitorWithDollar from '../../../../../shared_assets/v2/monitor-with-dollar.png'
import scrollWithCheckMark from '../../../../../shared_assets/v2/scroll-with-check-mark.png'

import './digitalSubscriptionIntro.scss'

const DigitalSubscriptionIntro = props => (
  <div className='digital-subscription-intro'>
    <Loader loading={!props.plaidUtils.ready}>
      <h2>How does this work?</h2>
      <TextGutterExtraLarge className='intro-text'>
        We'll help you identify subscription services you autopay (like
        streaming services) and add them to your Everplan so you and your
        Deputies can keep track of them all.
      </TextGutterExtraLarge>
      <div className='infographics flex-container'>
        <Text className='item flex-child'>
          <img src={overlappingCreditCards} alt='overlapping credit cards' />
          <div className='text-container'>
            <strong>1.</strong>
            <Text>
              Log in to a credit card or bank account you use for subscription
              services
            </Text>
          </div>
        </Text>
        <Text className='item flex-child'>
          <img src={monitorWithDollar} alt='monitor with dollar' />
          <div className='text-container'>
            <strong>2.</strong>
            <Text>We'll analyze your purchase history</Text>
          </div>
        </Text>
        <Text className='item flex-child'>
          <img src={scrollWithCheckMark} alt='scroll with check mark' />
          <div className='text-container'>
            <strong>3.</strong>
            <Text>
              Review the results and select which services to add to your
              Everplan
            </Text>
          </div>
        </Text>
      </div>
      <Button onClick={props.plaidUtils.open}>Continue</Button>
      <TextGutterExtraLarge className='footer-text'>
        By using this feature, you agree to let Everplans collect and analyze
        anonymized data for the purpose of improving its product.
      </TextGutterExtraLarge>
    </Loader>
  </div>
)

DigitalSubscriptionIntro.propTypes = {
  plaidUtils: PropTypes.shape({
    ready: PropTypes.bool,
    open: PropTypes.func
  })
}
export default DigitalSubscriptionIntro
