import {fromJS, List} from 'immutable'

import * as icons from '../../../../../shared_assets/gap_filler_assessment_icons'

export const FINAL_WISHES_OUTRO = fromJS({
  planning: {
    question: 'Which one best describes you?',
    items: [
      {
        label: 'I want to select each item myself (example: casket, venue)',
        value: 'I want to select each item myself (example: casket, venue)',
        image: icons.planningChecklist
      },
      {
        label: 'I just want to set aside some money',
        value: 'I just want to set aside some money',
        image: icons.paymentOther
      }
    ],
    name: 'planning',
    'screen-position': 1,
    'number-of-screens': 1
  },
  'prepaid-arrangements': {
    question: 'Which prepaid arrangements have you made?',
    items: [
      {
        label: "I've made arrangements with a funeral home",
        value: "I've made arrangements with a funeral home",
        image: icons.serviceFuneral
      },
      {
        label: "I've got a version of Trust / Totten Trust",
        value: "I've got a version of Trust / Totten Trust",
        image: icons.paymentTrust
      },
      {
        label: 'I did as an Insurance Policy',
        value: 'I did as an Insurance Policy',
        image: icons.paymentInsurance
      },
      {
        label: "I've made other types of arrangements",
        value: "I've made other types of arrangements",
        image: icons.paymentOther
      }
    ],
    name: 'prepaid-arrangements',
    'screen-position': 1,
    'number-of-screens': 1
  }
})

export const FINAL_WISHES_OUTRO_ORDER = List(['planning-or-prepaid-arrangements'])
