import MyEverplanLinks from './MyEverplanLinks'

const NavLinksController = props => (
  <MyEverplanLinks
    className={props.className}
    currentUser={props.currentUser}
    mobile={props.mobile}
    showDropdownNav={props.showDropdownNav}
  />
)

export default NavLinksController
