import {Map} from 'immutable'

const body = `
### How To Set Up Donations In Your Name

Most foundations, charities, and non-profit organizations allow for donations to be made through their websites. Usually on the donation page there is a place for donors to specify the nature of the gift, often under the heading of "Tributes" or "In memory of." Donors can put your name in that space, so that the organization knows the nature of the gift.

You can also establish a dedicated fund in your name where people can submit donations. To set up a fund in your name, contact the organization you'd like to work with and let them know that you want to set up a memorial fund. Most non-profit organizations will have simple protocols in place for making this happen.

Make sure you include your preferred charity in your Everplan so that your deputies can organize donations on your behalf as needed.

### Religious Considerations

Some religions and cultures prefer that donations be made in lieu of flowers or other gifts (such as in Judaism). If you will be following any religious customs, get in touch with local clergy to find out what your religion dictates on the issue, or see our article <a href="https://www.everplans.com/tools-and-resources/religious-funeral-traditions" rel='noreferrer noopener' target='_blank'>Religious Funeral Traditions</a>.
`


export default Map({
  body,
  headline: 'How to Give To Charity When You Die',
  summary: 'If you are involved in any charity or nonprofit work, or if there are any causes that you strongly support, you might ask people to make a memorial donation to a charity in your name, either in addition to or in lieu of flowers or other gifts.',
  query: 'how-to-give-to-charity-when-you-die',
  url: 'https://www.everplans.com/articles/how-to-give-to-charity-after-you-die'
})
