import PropTypes from 'prop-types'
import {List, Map} from 'immutable'

import Carousel from '../../shared_components/Carousel'
import ContentModal from '../content_modals/ContentModal'
import EverplansArticle from './EverplansArticle'

import './everplansArticles.scss'

const EverplansArticles = ({articles, context, location, userConfig}) => {
  // If for some reason any `articles`` are not found then they could be `null` in this `List`.
  // This could be caused by, for example, someone changing the `query`` string identifying an
  // article without changing the places where that article was being fetched (e.g.,
  // `everplansArticlesByCategory`). To ensure we don't have to do presence checks in multiple
  // places I'm trimming down the `List` here to only those `articles` that actually appear
  // to be present. --BLR
  const validArticles = articles && articles.filter(Boolean)

  const articlesArray = validArticles.toArray().map(
    article => (
      <EverplansArticle
        article={article}
        context={context}
        key={article.get('query')}
        location={location}
      />
    )
  )

  if (validArticles && validArticles.size) {
    return (
      <div className='everplans-articles'>
        <Carousel location={location} slides={articlesArray} title='Articles' />
        {
          validArticles.map(article => article.get('query')).some(query => (query === location.query.article)) &&
            <ContentModal location={location} userConfig={userConfig} />
        }
      </div>
    )
  } else {
    return null
  }
}

EverplansArticles.propTypes = {
  articles: PropTypes.instanceOf(List),
  context: PropTypes.string,
  location: PropTypes.object,
  userConfig: PropTypes.instanceOf(Map)
}

export default EverplansArticles
