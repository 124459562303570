import {useCallback, useState} from 'react'
import PropTypes from 'prop-types'
import {List} from 'immutable'

import DigitalSubscriptionForm from './DigitalSubscriptionForm'
import DigitalSubscriptionEmptySubscription from './DigitalSubscriptionEmptySubscription'

const DigitalSubscriptionFormContainer = props => {
  const {plaidUtils, ...otherProps} = props
  const [deletedSubscriptions, setDeletedSubscriptions] = useState(List())
  const removeSubscription = useCallback(
    id => setDeletedSubscriptions(deletedSubscriptions.push(id)),
    [deletedSubscriptions.size]
  )
  const validSubscriptions = props.subscriptions.filterNot(subscription =>
    deletedSubscriptions.includes(subscription.get('id'))
  )

  if (validSubscriptions.isEmpty()) {
    return (
      <DigitalSubscriptionEmptySubscription
        toggleGapFillerAssessmentModal={props.toggleGapFillerAssessmentModal}
        plaidUtils={plaidUtils}
      />
    )
  } else {
    return (
      <DigitalSubscriptionForm
        {...otherProps}
        removeSubscription={removeSubscription}
        subscriptions={validSubscriptions}
      />
    )
  }
}

DigitalSubscriptionFormContainer.propTypes = {
  subscriptions: PropTypes.instanceOf(List),
  toggleGapFillerAssessmentModal: PropTypes.func,
  plaidUtils: PropTypes.object
}

export default DigitalSubscriptionFormContainer
