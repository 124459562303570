import {Component} from 'react'
import {connect} from 'react-redux'
import {List, Map} from 'immutable'
import PropTypes from 'prop-types'

import NextBestAction from './NextBestAction'
import {cleanResponse} from '../redux/apiHelper'

class NextBestActionsController extends Component {
  sortedNextBestActions() {
    return this.props.nextBestActions.sortBy(nextBestAction => nextBestAction.getIn(['data', 'sort-order']))
  }

  render() {
    if (this.props.loading && this.props.nextBestActions.isEmpty()) {
      return (null)
    } else {
      return (
        <div>
          {
            this.sortedNextBestActions().map(nextBestAction => (
              <NextBestAction
                {...this.props}
                key={nextBestAction.get('id')}
                nextBestAction={nextBestAction}
              />
            ))
          }
        </div>
      )
    }
  }
}


const mapStateToProps = ({api}) => ({
  loading: api.isReading !== 0,
  nextBestActions: cleanResponse(api['next-best-actions'])
})

NextBestActionsController.propTypes = {
  nextBestActions: PropTypes.instanceOf(List),
  deleteResource: PropTypes.func,
  items: PropTypes.instanceOf(List),
  kits: PropTypes.instanceOf(Map),
  loading: PropTypes.bool
}

export default connect(mapStateToProps)(NextBestActionsController)
