import {Component} from 'react'
import PropTypes from 'prop-types'
import {Map} from 'immutable'

import {Button} from '../../../blocks/Buttons'
import Closer from '../../../../../../shared_components/core/closer/Closer'
import Modals from '../../../../../../shared_components/core/modals/Modals'
import VersionForm from './VersionForm'

import {apiV2MetaDataCall} from '../../../../../../lib/apiV2Tools'

export default class VersionFormController extends Component {
  constructor() {
    super()

    this.state = {processing: false, showModal: false}

    this.closer = this.closer.bind(this)
    this.submitVersion = this.submitVersion.bind(this)
    this.toggleModal = this.toggleModal.bind(this)
  }

  closer() { return <Closer closer={this.toggleModal} /> }

  toggleModal() { this.setState({showModal: !this.state.showModal}) }

  submitVersion(versionAttributes) {
    this.setState({processing: true})

    const resource = {
      type: 'versions',
      attributes: versionAttributes
    }

    return apiV2MetaDataCall({
      apiCall: this.props.createResource,
      resource
    }).then(() => {
      this.setState({processing: false})
      this.toggleModal()
    })
  }

  render() {
    return (
      <div>
        <Button onClick={this.toggleModal}>Add new version</Button>
        <Modals.PopUpModal showModal={this.state.showModal} closerComponent={this.closer}>
          <VersionForm
            {...this.props}
            processing={this.state.processing}
            submitVersion={this.submitVersion}
            showModal={this.state.showModal}
            version={Map()}
          />
        </Modals.PopUpModal>
      </div>
    )
  }
}

VersionFormController.propTypes = {
  createResource: PropTypes.func
}
