import PropTypes from 'prop-types'

import {Button} from '../../blocks/Buttons'
import Closer from '../../../../../shared_components/core/closer/Closer'
import Modals from '../../../../../shared_components/core/modals/Modals'
import {TextGutterMedium} from '../../blocks/Texts'

import successCheckmarkIcon from '../../../../../shared_assets/v2/success-checkmark.svg'


const DeputyUnlockPermissionsConfirmation = ({exit, name, firstName}) => (
  <Modals.SuccessPopUpModal showModalOnMount={true} closerComponent={() => <Closer closer={exit} />}>
    <header>
      <img src={successCheckmarkIcon} alt='Success checkmark' />
      <h2>{name} has been granted permission to unlock your "after death" items.</h2>
    </header>
    <TextGutterMedium>We'll notify {firstName} via email.</TextGutterMedium>
    <TextGutterMedium>
      <strong>Note:</strong> To ensure your message doesn't get lost, we suggest following up with {firstName}.
    </TextGutterMedium>
    <Button onClick={exit}>Done</Button>
  </Modals.SuccessPopUpModal>
)


DeputyUnlockPermissionsConfirmation.propTypes = {
  firstName: PropTypes.string,
  name: PropTypes.string,
  exit: PropTypes.func
}

export default DeputyUnlockPermissionsConfirmation
