import classnames from 'classnames'
import {Map} from 'immutable'
import PropTypes from 'prop-types'

import arrowDown from '../../../../assets/arrow-down.svg'
import loaderSpinner from '../../../../assets/loader-spinner.gif'

import './fileStatusList.scss'


const FileStatusList = props => {
  const files = props.files.valueSeq().map(value => (
    <div className='flex-container file' key={value.get('id')}>
      <img alt='loader spinner' className='loader-spinner flex-child' src={loaderSpinner} />
      <span className='file-name flex-child'>{value.get('name')}</span>
    </div>
  ))

  return (
    <aside className={classnames('file-status-list', {collapse: !props.showStatus})}>
      <header className='flex-container'>
        <h6 className='flex-child'>{props.header}</h6>
        <div className='toggle-module flex-child' onClick={props.toggleModule}>
          <img alt={props.showStatus ? 'hide file status' : 'show file status'} className='arrow-down' src={arrowDown} />
        </div>
      </header>
      {props.showStatus && <div className='files'>{files}</div>}
    </aside>
  )
}

FileStatusList.propTypes = {
  header: PropTypes.string,
  showStatus: PropTypes.bool,
  toggleModule: PropTypes.func,
  files: PropTypes.instanceOf(Map)
}

export default FileStatusList
