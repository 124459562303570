import PropTypes from 'prop-types'
import React from 'react'

import bindUpdate from '../../../../shared_components/BoundUpdate'
import {Cancel, Continue} from '../../../../shared_components/ButtonTypes'
import Forms from '../../../../shared_components/forms'
import ModalForm from '../../../../shared_components/ModalForm'
import modalWrapper from '../../../../shared_components/ModalWrapper'

import AccountSettingsActions from '../../actions/AccountSettingsActions'

import './addEmail.scss'

class AddEmail extends React.Component {
  componentDidMount() {
    // Checking the stringified boolean here so a query like `showEmailModal=false` doesn't mistakenly trigger the modal. --BLR
    if (this.props.location.query.showEmailModal === 'true')
      this.context.showModal()
  }
  addEmail() {
    if (this.refs.form.validate().length === 0) {
      this.props.updating(() => {
        AccountSettingsActions.fetchAccountInformation(this.props.userId)
        this.context.modalClosed()
      })
      const formData = this.refs.form.formData()
      if (formData.email_address)
        formData.email_address = formData.email_address.toLowerCase().trim()
      AccountSettingsActions.addEmail(formData)
    }
    if (this.props.errors.length > 0)
      this.props.finished()
  }
  closeModal() {
    this.props.finished()
    this.context.closeModal()
  }

  render() {
    const errors = this.props.errors
    return (
      <section className='add-email'>
        <a onClick={this.context.showModal}>Add another email</a>
        <ModalForm visible={this.props.showModal} ref='form' title='Add Another Email' closer={this.closeModal.bind(this)} instructions='Enter another email address you want to associate with your Everplans account.'>
          <Forms.Input label='New email address' name='email_address' validator={Forms.Validators.emailValidator} />
          <Forms.PasswordInput
            label='For your security, please re-enter your password.'
            name='password'
            validator={Forms.Validators.required}
          />
          {errors && errors.length > 0 && <Forms.Errors errors={this.props.errors[0].errors} />}
          <div className='button-group'>
            <Continue onClick={this.addEmail.bind(this)} loading={this.props.processing}>Add</Continue>
            <Cancel onClick={this.closeModal.bind(this)} />
          </div>
        </ModalForm>
      </section>
    )
  }
}

AddEmail.contextTypes = {
  showModal: PropTypes.func,
  closeModal: PropTypes.func,
  modalClosed: PropTypes.func
}

AddEmail.defaultProps = {
  location: {
    query: {}
  }
}

AddEmail.propTypes = {
  finished: PropTypes.func,
  showModal: PropTypes.bool,
  location: PropTypes.shape({
    query: PropTypes.shape({
      showEmailModal: PropTypes.string
    })
  }),
  updating: PropTypes.func
}

export default modalWrapper(bindUpdate(AddEmail, 'email_updates', 'email_address'))
