/** @module planTools
 *
 * @desc
 * Helpers related to `Plan`
 */
import {Map} from 'immutable'

import {cleanResponse} from '../dashboard/src/components/corp_max/redux/apiHelper'

/**
 * Returns the Active Plan from the `state.api.plan` since there is always only one active plan
 *
 * @param {List} plans from the api
 *
 * @returns {Map} the active Plan
 */
export const activePlan = plans => cleanResponse(plans).find(plan => plan.get('activated')) || Map()

export const centToDollars = plan => (plan ? plan.get('amount') / 100 : 75)
