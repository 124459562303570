import {useMemo} from 'react'
import {List, Map} from 'immutable'
import PropTypes from 'prop-types'

import AddContactAsDeputy from './AddContactAsDeputy'
import DeleteContact from './DeleteContact'

import Logger from '../../../../../lib/NewLogger'

import routerUtils from '../../../../../lib/routerUtils'
import {contactItems, contactLabel, contactIsNotCurrentUser} from '../../../../../lib/contactTools'
import {deputizableDomainEmails} from '../../../../../lib/emailTools'

import './addressBookContact.scss'


const AddressBookContact = props => {
  const formattedContactLabel = useMemo(() => contactLabel(props.contact), [props.contact])
  const contactItemHeaderList = useMemo(() => contactItems({contactId: props.contact.get('id'), itemResponses: props.itemResponses}), [])

  const editContact = () => {
    Logger.log({
      name: 'clicked_contact',
      payload: {context: 'address_book'}
    })

    routerUtils.push(`/cm/address-book/${props.contact.get('id')}`)
  }

  const showAddContactAsDeputy = () => {
    const {firmConfig, advisorContactCard, organization, firm, contact, currentUser} = props
    const isClient = props.userConfig.get('client')

    // this prevents flickering of +Make Deputy+ button when any of the data required is empty and user is a client
    if (isClient && (advisorContactCard.isEmpty() || firmConfig.isEmpty() || (firm.get('organization-id') && organization.isEmpty())))
      return false

    if (isClient && contact.get('emails', List()).includes(advisorContactCard.get('email')))
      return (firm.get('organization-id') && !deputizableDomainEmails({contact, organization}).isEmpty()) || firmConfig.get('deputizable')
    else
      return contactIsNotCurrentUser({contact, currentUser})
  }

  const contactEmail = props.contact.getIn(['emails', 0])

  return (
    <div className='address-book-contact flex-container'>
      <div className='contact-summary flex-child flex-container'>
        <div className='contact-main-details flex-child flex-container'>
          <a className='flex-child' onClick={editContact}>
            {formattedContactLabel}
          </a>
          <div className='contact-email flex-child'>{contactEmail}</div>
        </div>
        <div className='contact-appears-in flex-child'>
          {contactItemHeaderList.join(', ')}
        </div>
        {
          showAddContactAsDeputy() &&
            <AddContactAsDeputy
              contact={props.contact}
              deputies={props.deputies.map(deputy => deputy.get('ownable'))}
            />
        }
      </div>
      <DeleteContact
        userConfig={props.userConfig}
        contactId={props.contact.get('id')}
        contactItems={contactItemHeaderList}
        contactLabel={formattedContactLabel}
      />
    </div>
  )
}

AddressBookContact.propTypes = {
  advisorContactCard: PropTypes.instanceOf(Map),
  contact: PropTypes.instanceOf(Map),
  currentUser: PropTypes.instanceOf(Map),
  deputies: PropTypes.instanceOf(List),
  firm: PropTypes.instanceOf(Map),
  firmConfig: PropTypes.instanceOf(Map),
  organization: PropTypes.instanceOf(Map),
  itemResponses: PropTypes.instanceOf(List),
  userConfig: PropTypes.instanceOf(Map)
}

export default AddressBookContact
