import {List} from 'immutable'
import PropTypes from 'prop-types'

import ViewedItemBox from './ViewedItemBox'

import {findResourceByAttributeId} from '../../../../lib/plan_data/userData'

import './viewedItemsByDate.scss'


const ViewedItemsByDate = props => {
  const viewedItemsByItemIds = props.viewedItems.groupBy(viewedItem => viewedItem.get('item-id'))

  return (
    <div className='viewed-items-by-date'>
      <h3 className='date'>{props.date}</h3>
      {
        viewedItemsByItemIds.entrySeq().map(([itemId, viewedItems]) => {
          const viewedItemsByFirstGroupId = viewedItems.groupBy(viewedItem => viewedItem.getIn(['group-id', 0])).keySeq()
          const itemResponse = findResourceByAttributeId({resourceList: props.itemResponses, attribute: 'item-id', id: itemId})

          return viewedItemsByFirstGroupId.map(firstGroupId => (
            <ViewedItemBox
              {...props}
              firstGroupId={firstGroupId}
              itemResponse={itemResponse}
              key={firstGroupId}
            />
          ))
        })
      }
    </div>
  )
}

ViewedItemsByDate.propTypes = {
  itemResponses: PropTypes.instanceOf(List),
  viewedItems: PropTypes.instanceOf(List),
  date: PropTypes.string
}

export default ViewedItemsByDate
