import PropTypes from 'prop-types'
import {Controller, useForm} from 'react-hook-form'
import {Map} from 'immutable'
import {useMemo, useEffect} from 'react'
import {Box, Typography} from '@mui/material'

import Button from '../../../../../shared_components/mui_base_components/button/Button'
import SelectButtonGroup from '../../../../../shared_components/forms/v3/SelectButtonGroup'

import './itemOptionsScreen.scss'

const ItemOptionsScreen = ({handleOptionSelected, itemOptions, screen, logMixpanelProgramEvent}) => {
  const {control, handleSubmit, watch} = useForm()
  const formValues = watch()

  const submitDisabled = useMemo(() => !formValues[screen.get('id')], [formValues])

  useEffect(() => {
    logMixpanelProgramEvent({name: 'view_program_screen', payload: {screen: screen.get('event-name')}})
  }, [])

  return (
    <Box className='item-options-screen'>
      <Typography className='heading' variant='h3' align='center'>
        {screen.get('heading')}
      </Typography>
      <form name={screen.get('id')} onSubmit={handleSubmit(handleOptionSelected)}>
        <Controller
          name={screen.get('id')}
          control={control}
          render={({field}) => (
            <SelectButtonGroup
              selectLimit={1}
              field={field}
              isSingleSelect
              options={itemOptions}
            />
          )}
        />
        <Button
          disabled={submitDisabled}
          type='submit'>
          Next
        </Button>
      </form>
    </Box>
  )
}

ItemOptionsScreen.propTypes = {
  handleOptionSelected: PropTypes.func.isRequired,
  itemOptions: PropTypes.arrayOf(PropTypes.object),
  logMixpanelProgramEvent: PropTypes.func.isRequired,
  screen: PropTypes.instanceOf(Map)
}

export default ItemOptionsScreen
