import classnames from 'classnames'
import PropTypes from 'prop-types'
import {useState, useEffect, useRef, useMemo} from 'react'
import {useMutation} from '@apollo/client'
import {fromJS, List, Map, Set} from 'immutable'

import {itemResponseGroupOwners} from '../../../../../lib/plan_data/itemResponsesHelper'
import {TextGutterMedium} from '../../../../../dashboard/src/components/blocks/Texts'
import {UPDATE_RESPONSE_GROUP_OWNERSHIP} from '../../../../../graphql/mutations/itemResponse'
import {usePrevious} from '../../../../../lib/hooks'
import {userOwnerOwnership} from '../../../../../lib/userTools'
import Fields from '../../../../../shared_components/forms/v2/fields/index'
import Form from '../../../../../shared_components/forms/v2/Form'
import Logger from '../../../../../lib/NewLogger'

import './householdAssignmentRadioListForm.scss'


const HouseholdAssignmentRadioListForm = props => {
  if (props.itemResponse.get('is-household')) {
    const formRef = useRef()
    const [updateResponseGroupOwnership] = useMutation(UPDATE_RESPONSE_GROUP_OWNERSHIP)

    const defaultResponseOwnershipIds = useMemo(() => (
      props.responseGroup.isEmpty() ? Set() : props.responseGroup.get('owners').map(owner => owner.get('id')).toSet()
    ), [])

    const [ownershipIds, setOwnershipIds] = useState(defaultResponseOwnershipIds)
    const prevOwnershipIds = usePrevious(ownershipIds)

    const responseOwnershipIds = () => {
      const form = formRef.current

      if (form) {
        const responseOwnershipValue = form.formData().getIn(['household-assignment-radio', 'value'])
        const everplanOwnershipIds = props.ownerships.map(ownership => parseInt(ownership.get('id'), 10))

        return responseOwnershipValue === 'both' ? everplanOwnershipIds : List([parseInt(responseOwnershipValue, 10)])
      }
    }

    useEffect(() => {
      props.updateResponseOwnershipIds(responseOwnershipIds())
    }, [])

    const logEvent = () => {
      const itemName = props.itemResponse.get('name')
      Logger.log({
        name: 'updated_household_ownership',
        payload: {
          context: props.context,
          type: props.eventType,
          item: itemName,
          wildcard: itemName
        }
      })
    }

    const updateHouseholdOwnership = () => {
      if (props.responseGroup.get('id')) {
        updateResponseGroupOwnership({
          variables: {
            input: {
              everplanId: props.itemResponse.get('everplan-id'),
              firstGroupId: props.responseGroup.get('id'),
              ownershipIds,
              itemId: props.itemResponse.get('item-id')
            }
          }
        })
      }
    }

    useEffect(() => {
      if (!prevOwnershipIds.equals(ownershipIds)) {
        logEvent()
        updateHouseholdOwnership()
      }
    }, [ownershipIds])

    const namesAndIds = () => {
      const items = []

      props.ownerships.forEach(ownership => {
        items.push({
          label: ownership.get('first-name'),
          value: ownership.get('id')
        })
      })

      items.push({label: 'Both', value: 'both'})
      return items
    }

    const onUpdate = () => {
      const form = formRef.current

      if (!form)
        return

      const updatedOwnershipIds = responseOwnershipIds()

      props.updateResponseOwnershipIds(updatedOwnershipIds)

      setOwnershipIds(updatedOwnershipIds.toSet())
    }

    const defaultOwnershipIds = () => {
      if (defaultResponseOwnershipIds.isEmpty()) {
        const userOwnership = userOwnerOwnership({userConfig: props.userConfig, ownerships: props.ownerships})
        return userOwnership ? userOwnership.get('id') : 'both'
      } else {
        return defaultResponseOwnershipIds.size === 1 ? defaultResponseOwnershipIds.first().toString() : 'both'
      }
    }

    return (
      props.isEditableItem ?
        <Form className='core ownership-form' ref={formRef} onUpdate={onUpdate}>
          <Fields.RadioList
            data={
              fromJS({
                id: 'household-assignment-radio',
                items: namesAndIds(),
                value: defaultOwnershipIds(),
                legend: 'Belongs to:',
                name: 'household-assignment-radio'
              })
            }
            className={classnames('household-assignment-radio-list-form', props.className)}
          />
        </Form> :
        <TextGutterMedium className='household-ownership-status'>
            Belongs to {itemResponseGroupOwners(props.responseGroup)}
        </TextGutterMedium>
    )
  } else {
    return null
  }
}


HouseholdAssignmentRadioListForm.propTypes = {
  className: PropTypes.string,
  context: PropTypes.string,
  itemResponse: PropTypes.instanceOf(Map),
  isEditableItem: PropTypes.bool,
  ownerships: PropTypes.instanceOf(List),
  responseGroup: PropTypes.instanceOf(Map),
  eventType: PropTypes.string,
  updateResponseOwnershipIds: PropTypes.func,
  userConfig: PropTypes.instanceOf(Map)
}

export default HouseholdAssignmentRadioListForm
