import PropTypes from 'prop-types'
import {Map} from 'immutable'

import ModuleBox from '../../../../../shared_components/component_templates/ModuleBox'
import PlusIcon from '../../../../../shared_components/core/svg_icons/icons/PlusIcon'

import {accountEntrySelectPath, newItemPath} from '../../../../../lib/urlTools'
import {eligibleForAccountsImport} from '../../../../../lib/accountPopulationTools'
import {isCompoundItemResponse, firstTopKitParentElementId} from '../../../../../lib/plan_data/itemResponsesHelper'
import Logger from '../../../../../lib/NewLogger'
import routerUtils from '../../../../../lib/routerUtils'

import './itemWithoutResponsesBox.scss'

const ItemWithoutResponsesBox = props => {
  // Adding space between any forward-slashes to allow the item header to wrap and not break the layout --KTW
  const header = props.itemResponse.get('header').replace('/', ' / ')

  const addResponseGroup = () => {
    const itemName = props.itemResponse.get('name')
    Logger.log({
      name: 'clicked_add_new',
      payload: {
        context: props.context,
        type: 'vault',
        item: itemName,
        wildcard: itemName,
        compound_item: isCompoundItemResponse(props.itemResponse)
      }
    })

    routerUtils.push(
      newItemPath({
        elementId: firstTopKitParentElementId(props.itemResponse),
        itemSlug: props.itemResponse.get('slug'),
        slug: props.categorySlug,
        path: props.pathname
      })
    )
  }

  const onClickAdd = () => {
    if (eligibleForAccountsImport({item: props.itemResponse, userConfig: props.userConfig}))
      routerUtils.push(accountEntrySelectPath({itemSlug: props.itemResponse.get('slug'), path: props.pathname}))
    else
      addResponseGroup()
  }

  return (
    <ModuleBox className='item-without-responses-box flex-container' onClick={onClickAdd}>
      <div className='add-response-button'>
        <PlusIcon fill='#FFF' width='10' height='10' />
      </div>
      <div className='heading-and-guidance-text'>
        <h3>{header}</h3>
        {props.itemResponse.get('guidance-text') && <div className='guidance-text'>{props.itemResponse.get('guidance-text')}</div>}
      </div>
    </ModuleBox>
  )
}

ItemWithoutResponsesBox.propTypes = {
  category: PropTypes.instanceOf(Map),
  categorySlug: PropTypes.string,
  context: PropTypes.string,
  itemResponse: PropTypes.instanceOf(Map).isRequired,
  userConfig: PropTypes.instanceOf(Map).isRequired,
  pathname: PropTypes.string
}

export default ItemWithoutResponsesBox
