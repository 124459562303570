import {Component} from 'react'
import PropTypes from 'prop-types'

import PhoneEditor from './PhoneEditor'
import ConfirmCodeForm from '../tfa_setup/ConfirmCodeForm'
import SettingsActions from '../../../actions/SettingsActions'
import WizardActions from '../../../actions/WizardActions'
import TfaConfigStore from '../../../stores/TfaConfigStore'
import Button from '../../../../../shared_components/Button'

import '../tfaSetup.scss'

const editStates = {
  INITIAL: 'INITIAL',
  SUBMITTED: 'SUBMITTED',
  CONFIRMING: 'CONFIRMING',
  RESTART: 'RESTART'
}

export default class PrimaryPhoneEditor extends Component {
  constructor(props) {
    super(props)
    this.state = {
      number: props.primaryNumber.number,
      mode: props.primaryNumber.preferred_message_mode,
      pendingTfaPhoneNumber: TfaConfigStore.getState().pendingTfaPhoneNumber,
      editState: editStates.INITIAL,
      loading: false
    }

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleConfirmCodeSubmit = this.handleConfirmCodeSubmit.bind(this)
    this.onUpdate = this.onUpdate.bind(this)
    this.setFormValue = this.setFormValue.bind(this)
    this.setMode = this.setMode.bind(this)
  }

  restart() {
    SettingsActions.abandonPhoneEdit()
    this.setState({editState: editStates.RESTART, loading: false})
  }

  onUpdate() {
    const pendingTfaPhoneNumber = TfaConfigStore.getState().pendingTfaPhoneNumber
    if (pendingTfaPhoneNumber) {
      this.setState({pendingTfaPhoneNumber})
    } else {
      // Either we verifyed the code here was an error when verifying
      const weAreDoneVerifyingCode = this.state.editState === 'CONFIRMING' && !TfaConfigStore.getState().error
      if (weAreDoneVerifyingCode)
        this.props.closer()
      else // Go back to the phone number entry screen and display the error
        this.setState({error: TfaConfigStore.getState().error})
    }
  }

  componentDidMount() {
    SettingsActions.abandonPhoneEdit()
    TfaConfigStore.addChangeListener(this.onUpdate)
  }

  componentWillUnmount() {
    TfaConfigStore.removeChangeListener(this.onUpdate)
  }

  setFormValue(validationStatus, nationalNumber, number) {
    this.setState({validationStatus, nationalNumber, number})
  }

  setMode(mode) { this.setState({mode}) }

  handleSubmit(event) {
    if (event) event.preventDefault()
    if (this.state.validationStatus) {
      this.setState({
        editState: editStates.SUBMITTED,
        validationStatus: true,
        loading: true
      })
      SettingsActions.submitPhoneEdit({
        number: this.state.number,
        nationalNumber: this.state.nationalNumber,
        preferredMessageMode: this.state.mode,
        primary: true
      })
    } else {
      this.setState({error: 'Please enter a valid number.'})
    }
  }

  handleConfirmCodeSubmit(event) {
    if (event) event.preventDefault()
    var code = this.refs.confirmCodeForm.getEnteredCode()
    this.setState({editState: 'CONFIRMING'})
    SettingsActions.confirmCode(this.state.pendingTfaPhoneNumber.id, code)
  }

  render() {
    if (this.state.pendingTfaPhoneNumber) {
      return (
        <div onClick={WizardActions.clearTfaErrors}>
          <ConfirmCodeForm
            ref='confirmCodeForm'
            phoneNumber={this.state.pendingTfaPhoneNumber}
            error={TfaConfigStore.getState().error}
            restartFunction={this.restart}
            handleSubmit={this.handleConfirmCodeSubmit}
          />
        </div>
      )
    } else {
      return (
        <div>
          <PhoneEditor
            error={this.state.error}
            handleSubmit={this.handleSubmit}
            header='Primary phone number'
            number={this.state.number}
            mode={this.state.mode}
            setFormValue={this.setFormValue}
            setMode={this.setMode}
          />
          <div>
            <Button className='wizard-secondary-btn' onClick={this.props.closer}>Cancel</Button>
            <Button className='wizard-primary-btn' onClick={this.handleSubmit} loading={this.state.loading}>Verify phone
            number</Button>
          </div>
        </div>
      )
    }
  }
}

PrimaryPhoneEditor.propTypes = {
  closer: PropTypes.func,
  primaryNumber: PropTypes.object
}
