import PropTypes from 'prop-types'
import React from 'react'
import ReactDOM from 'react-dom'

import CreditCardCompoundFields from './CreditCardCompoundFields'
import Forms from '../../../../shared_components/forms'

import './creditCardForm.scss'

export default class CreditCardForm extends React.Component {
  constructor() {
    super()
    this.formData = this.formData.bind(this)
    this.validateForm = this.validateForm.bind(this)
  }

  componentDidMount() {
    if (!__TEST__) { // Uses external Stripe library and will break tests and is not needed for tests --JA
      $('.credit-card-number').payment('formatCardNumber')
    }
  }
  formData() { return this.refs.form.formData() }
  validateForm() { return this.refs.form.validate() }

  render() {
    return (
      <Forms.Form className='core credit-card-form' ref='form'>
        <Forms.Input
          autoFocus
          className='credit-card-number'
          defaultValue={__DEV__ ? '4242 4242 4242 4242' : ''}
          label='Credit card number'
          name='creditCardNumber'
          placeholder='Credit card number'
          ref='creditCardNumberInput'
          tabIndex={1}
          type='tel'
          validator={Forms.Validators.creditCardNumberValidator}
        />
        <CreditCardCompoundFields name='creditCardCompoundFields' />
      </Forms.Form>
    )
  }
}

CreditCardForm.propTypes = {
  ref: PropTypes.object
}
