import PropTypes from 'prop-types'
import {List, Map} from 'immutable'

import CategoryItemHeader from './CategoryItemHeader'
import ItemResponseGroups from '../../../../../../shared_components/item_response_groups/ItemResponseGroups'
import ModuleBox from '../../../../../../shared_components/component_templates/ModuleBox'
import {findResourceByAttributeId} from '../../../../../../lib/plan_data/userData'

import './categoryItem.scss'

const CategoryItem = props => {
  const itemView = findResourceByAttributeId({
    attribute: 'item-id',
    id: props.itemResponse.get('item-id'),
    resourceList: props.itemViews
  })

  const responseGroups = props.itemResponse.get('responseGroupMap')

  return (
    <ModuleBox className='category-item flex-child'>
      <CategoryItemHeader {...props} responseGroups={responseGroups} />
      <ItemResponseGroups
        {...props}
        responseGroups={responseGroups}
        hasEditPermission={props.ownEverplan}
        itemView={itemView}
        path={props.location.pathname}
      />
    </ModuleBox>
  )
}

CategoryItem.defaultProps = {
  userConfig: Map()
}

CategoryItem.propTypes = {
  item: PropTypes.instanceOf(Map),
  itemViews: PropTypes.instanceOf(List),
  kits: PropTypes.instanceOf(Map),
  location: PropTypes.shape({
    pathname: PropTypes.string
  }),
  ownEverplan: PropTypes.bool,
  itemResponse: PropTypes.instanceOf(Map),
  userConfig: PropTypes.instanceOf(Map)
}

export default CategoryItem
