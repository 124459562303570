// @todo Handle the guide modal for the Advance Directive gap filler.

import {fromJS, List} from 'immutable'

import {contentImageUrl} from '../../../../lib/s3Tools'


const GAP_FILLER_GUIDE_MODAL_CONFIGURATIONS = fromJS({
  'advance-directive': {
    sections: [
      {
        contents: [
          {
            text:
              "Things get very emotional and stressful during a medical emergency. When you have an Advance Directive in place, loved ones won't be forced to make the tough decisions because you would have already made them."
          }
        ],
        heading: "Why It's So Important"
      },
      {
        contents: [
          {
            text:
              "This person, also known as a Medical Power Of Attorney or Health Care Agent, is tasked with making medical decisions for you if you can't make them on your own."
          }
        ],
        heading: 'Who Is Your Health Care Proxy?'
      },
      {
        contents: [
          {
            text:
              "This specifies the types of medical treatments you want or don't want at the end of life, specifically in terms of life-support treatments. Your Proxy and medical team will use this as a guide to give you the care you want."
          }
        ],
        heading: 'All About Living Wills'
      },
      {
        contents: [
          {
            text:
              "After you've downloaded your state's form, which we provide below, follow the directions to fill it out. It's also a good idea to talk to your doctor or someone you trust to help you understand the decisions you need to make. Just make sure the decisions you make are your own since it's your life after all."
          }
        ],
        heading: 'Creating An Advance Directive Is Simple'
      },
      {
        heading: 'Dig Deeper with These Articles from Everplans',
        resources: [
          {
            text: 'All You Need To Know About Advance Directives',
            url:
              'https://www.everplans.com/articles/all-you-need-to-know-about-advance-directives'
          },
          {
            text: 'All You Need To Know About Naming A Health Care Proxy',
            url:
              'https://www.everplans.com/articles/all-you-need-to-know-about-naming-a-health-care-proxy'
          },
          {
            text: 'How To Choose A Health Care Proxy',
            url:
              'https://www.everplans.com/articles/how-to-choose-a-health-care-proxy'
          },
          {
            text: 'What You Need To Know About Creating A Living Will',
            url:
              'https://www.everplans.com/articles/what-you-need-to-know-about-creating-a-living-will'
          },
          {
            text:
              'Something to Share With Your Proxy: How To Be A Good Health Care Proxy',
            url:
              'https://www.everplans.com/articles/how-to-be-a-good-health-care-proxy'
          }
        ],
        contents: []
      }
    ],
    description:
      "When you can't make health decisions for yourself, this combination of your Living Will and Health Care Proxy guides your loved ones and doctors in the right direction.",
    imageUrl: contentImageUrl('gap-filler-guide-modal-advance-directive.jpeg'),
    title: "Everplans' Guide to Advance Directives"
  },
  'life-insurance-my-life-insurance-policy': {
    sections: [
      {
        contents: [
          {
            text:
              "If you've never given Life Insurance much thought before, it's a financial safety net for your family in case you suddenly, or unexpectedly...how do we put this gently...die."
          }
        ],
        heading: 'Life Insurance = Peace of Mind'
      },
      {
        contents: [
          {
            text:
              "If you pay your monthly or annual premiums on time, the insurance company pays your family the amount of money specified in the policy. Of course there's paperwork involved but this is generally how it works."
          }
        ],
        heading: 'Premium Treatment'
      },
      {
        contents: [
          {
            text:
              'covers you for a set amount of time and is often more affordable for most. Example: If you have a 20-year term policy, you have to die within those 20-years for it to payout.',
            resource: {
              text: 'More on Term Life Insurance',
              url:
                'https://www.everplans.com/articles/the-benefits-and-drawbacks-of-term-life-insurance'
            },
            strongText: 'Term Insurance:'
          },
          {
            text:
              "is more expensive since it never expires and often has an investment component, which allows you to draw money from it while you're still alive.",
            resource: {
              text: 'More on Permanent Life Insurance',
              url:
                'https://www.everplans.com/articles/the-benefits-and-drawbacks-of-permanent-life-insurance'
            },
            strongText: 'Permanent Insurance:'
          }
        ],
        heading: 'Two Main Types'
      },
      {
        contents: [
          {
            text:
              "Consider how much money your family needs to live comfortably after you're gone. For example: If you're committed to ongoing expenses, such as college tuition or a mortgage, how long could your family continue to make those payments without your regular income?",
            resource: {
              text: 'Learn how to calculate it',
              url:
                'https://www.everplans.com/articles/how-much-life-insurance-do-you-need'
            }
          }
        ],
        heading: 'How much do you need?'
      }
    ],
    description:
      "Life Insurance is all about peace of mind, so here's a crash course for when it's time to put a financial back-up plan in place.",
    imageUrl: contentImageUrl('gap-filler-guide-modal-life-insurance.jpeg'),
    title: "Everplans' Guide to Life Insurance"
  },
  'power-of-attorney-my-power-of-attorney': {
    sections: [
      {
        contents: [
          {
            text:
              "If you were to experience a medical emergency—coma, stroke, dementia—who would be looking after your money and property? How would your bills get paid? How would your family get access to your accounts for much needed funds? Who would file your taxes? Appointing a Power of Attorney can solve this problem, giving someone you trust the legal right to manage your finances when you can't do it yourself."
          }
        ],
        heading: "Why it's so important"
      },
      {
        contents: [
          {
            text:
              'A Power of Attorney is indeed very powerful, with complete control over everything involving your finances including:'
          },
          {
            listItems: [
              'Bill paying and banking',
              'Managing or selling assets such as real estate or stocks',
              'Paying insurance premiums',
              'Filing tax returns'
            ]
          }
        ],
        heading: 'What kind of power are we talking about?'
      },
      {
        contents: [
          {
            text:
              'There are different kinds of POA for different purposes. For estate planning and end-of-life planning, a Durable Power of Attorney is generally recommended,  as the POA immediately goes into effect upon signing and remains in effect if you become incompetent or incapacitated.'
          },
          {
            text:
              'Other types include Springing Power of Attorney, which goes into effect only after you are incapacitated; and Non-Durable Power of Attorney, which goes into effect when signed, but expires if you become incapacitated.'
          }
        ],
        heading: 'Different types of POA'
      },
      {
        contents: [
          {
            text:
              'For someone who wants to create a simple, Durable Power of Attorney, the online route can be a quick and simple way to do it. There are many reputable and inexpensive options, such as Nolo Quicken WillMaker & Trust and FreeWill. Keep in mind that your POA document may need to be signed by witnesses and notarized.'
          }
        ],
        heading: 'Creating a Power of Attorney online'
      },
      {
        contents: [
          {
            text:
              "If your situation is more complex or nuanced, or if you are thinking of creating a Springing Power of Attorney, we recommend finding a qualified estate and trust attorney near you. Talk to friends, family members, and other attorneys to get recommendations. Meet with the lawyer you're considering before hiring him or her."
          }
        ],
        heading: 'Working with a lawyer'
      },
      {
        heading: 'Dig deeper with these articles from Everplans',
        resources: [
          {
            text: 'Why You Might Need a Durable Power Of Attorney',
            url:
              'https://www.everplans.com/articles/why-you-might-need-a-durable-power-of-attorney-dpoa'
          },
          {
            text: 'Why You Might Need a Springing Power Of Attorney',
            url:
              'https://www.everplans.com/articles/why-you-might-need-a-springing-power-of-attorney'
          },
          {
            text: 'A Guide To Power Of Attorney For Your Parents',
            url:
              'https://www.everplans.com/articles/a-guide-to-power-of-attorney-for-your-parents'
          },
          {
            text: 'Be Careful Buying Power Of Attorney Forms Online',
            url:
              'https://www.everplans.com/articles/this-attorney-makes-a-case-against-buying-power-of-attorney-forms-online'
          }
        ],
        contents: []
      }
    ],
    description:
      'A Power of Attorney (POA) is someone you appoint to make legal and financial decisions on your behalf should you become unable to do so yourself.',
    imageUrl: contentImageUrl('gap-filler-guide-modal-power-of-attorney.jpeg'),
    title: "Everplans' guide to naming a Power of Attorney"
  },
  'will-details-about-your-will': {
    sections: [
      {
        contents: [
          {
            text:
              "If you don't create a Will, decisions about your property, assets, and guardianship of minor children or special needs adults fall to the courts upon your death. State laws will determine who gets what, including the guardianship of your kids. Add in some family drama and this battle can rage on in the courts for years, creating rifts that may never heal."
          }
        ],
        heading: "Why it's so important"
      },
      {
        contents: [
          {
            text: 'Died without a Will. Estate took 6 years to settle.',
            strongText: 'Picasso:'
          },
          {
            text:
              'Died without a Will. Bitter lawsuits ensued between family members.',
            strongText: 'Bob Marley:'
          },
          {
            text:
              'Died without a Will. 6 siblings and 5 half-siblings still battling it out.',
            strongText: 'Prince:'
          },
          {
            text:
              "Her Will wasn't specific. Kids still fighting over her possessions 25 years later.",
            strongText: 'Audrey Hepburn:'
          }
        ],
        heading: "Don't be like these people"
      },
      {
        contents: [
          {
            text:
              "If you don't name guardians in your Will, the courts will probably give your kids to your closest relative, regardless of how you feel about that person. So if you don't want, say, your irresponsible brother raising your kids, you have to declare who you want to take care of them in your Will."
          }
        ],
        heading: 'For parents with young kids'
      },
      {
        contents: [
          {
            text:
              'The executor is responsible for making sure your wishes are carried out. Choose your executor wisely, as the job requires attention to detail, an understanding of finances, and patience, as the process can take a long time.'
          }
        ],
        heading: 'Choose executors wisely'
      },
      {
        contents: [
          {
            text:
              'If you have a relatively simple estate (example: less than $2M in assets) and simple family situation (like no step children or ex spouses), you can use an online service to create a legally valid Will right now. There are many reputable and inexpensive options like Nolo Quicken WillMaker & Trust. Keep in mind that your document will need to be signed by witnesses and notarized in order to be valid.'
          }
        ],
        heading: 'Create a Will online'
      },
      {
        contents: [
          {
            text:
              "If you have complex financial arrangements, such as overseas assets, elaborate investments, or trusts, then you're going to need some help. This means working with a local estate attorney to make sure everything is done right. If you're not sure who to hire, talk to friends, family members, colleagues, and other attorneys to get recommendations."
          }
        ],
        heading: 'Or hire an estate attorney'
      },
      {
        heading: 'Dig deeper with these articles from Everplans',
        resources: [
          {
            text: 'All You Need To Know About Creating A Will',
            url:
              'https://www.everplans.com/articles/all-you-need-to-know-about-creating-a-will'
          },
          {
            text:
              'What Are The Duties And Responsibilities Of An Executor For Your Estate?',
            url:
              'https://www.everplans.com/articles/what-are-the-duties-and-responsibilities-of-an-executor-for-your-estate'
          },
          {
            text: "Property You Can't Include In A Will",
            url:
              'https://www.everplans.com/articles/property-you-cant-include-in-a-will'
          }
        ],
        contents: []
      }
    ],
    description:
      'A Will is a legal document that lays out who gets your property when you die, and who will be the guardians of any minor children or special needs adults.',
    imageUrl: contentImageUrl('gap-filler-guide-modal-will.jpeg'),
    title: "Everplans' guide to Wills"
  }
})

export const GAP_FILLER_ITEM_SLUGS_WITH_ASSESSMENT = List([
  'burial-cremation-or-donation-preferences-my-disposition-wishes-and-plans',
  'digital-world-video-streaming'
])

export default GAP_FILLER_GUIDE_MODAL_CONFIGURATIONS
