import PropTypes from 'prop-types'
import {List} from 'immutable'
import {Button, Stack, Typography} from '@mui/material'

import UserAvatar from './UserAvatar'

import routerUtils from '../../../../../lib/routerUtils'
import allDeputiesCaret from '../../../../../dashboard/assets/right-caret-262626.svg'
import Logger from '../../../../../lib/NewLogger'

import './myDeputiesSection.scss'


const MyDeputiesSection = ({deputies, numberOfAvatarsToDisplay, toggleDeputyPermissionsModal}) => {
  if (deputies.isEmpty())
    return null

  const handleOnClick = deputy => {
    Logger.log({
      name: 'clicked_deputy_permissions',
      payload: {
        context: 'dashboard'
      }
    })

    toggleDeputyPermissionsModal(deputy.get('id'))
  }

  const handleSeeAllDeputiesClick = () => {
    Logger.log({
      name: 'clicked_my_deputies',
      payload: {
        context: 'dashboard'
      }
    })
    routerUtils.push('cm/deputies?context=dashboard')
  }

  return (
    <Stack className='my-deputies-section' spacing={2} >
      <Typography className='body-text deputies-section-label' gutterBottom>I'm sharing with</Typography>
      <Stack className='deputies-caret-container' direction='row' spacing={2}>
        <Stack className='deputies-container' direction='row' justifyContent='space-between'>
          {
            deputies.slice(0, numberOfAvatarsToDisplay).map(deputy => (
              <UserAvatar
                className='deputy-avatar'
                isPending={deputy.getIn(['ownable', 'status']) === 'pending'}
                key={deputy.get('id')}
                onClick={() => handleOnClick(deputy)}
                user={deputy.get('ownable')}
              />
            ))
          }
        </Stack>
        {
          numberOfAvatarsToDisplay < deputies.size &&
          <Button
            className='-see-all-deputies'
            onClick={handleSeeAllDeputiesClick}>
            <img alt='see-all-deputies-button' src={allDeputiesCaret} />
          </Button>
        }
      </Stack>
    </Stack>
  )
}

MyDeputiesSection.defaultProps = {
  numberOfAvatarsToDisplay: 2
}

MyDeputiesSection.propTypes = {
  deputies: PropTypes.instanceOf(List),
  numberOfAvatarsToDisplay: PropTypes.number.isRequired,
  toggleDeputyPermissionsModal: PropTypes.func
}

export default MyDeputiesSection
