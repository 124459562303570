/** @module legacyUserTools
 *
 * @desc
 * Helpers related to `Users`, including condition checking, information
 * gathering, and updating.
 */

import UserActions from '../shared_actions/UserActions'

/** Checks if the given `user` has seen the `viewed_flag` with the name `flagName`. */
export const hasViewedFlag = (flagName, user) => !!(user && user.viewed_flags && user.viewed_flags[flagName])

/**
 * Sets the value of the `flagToSet` key in the `viewed_flags` for the given `user`
 * to `true` if the `user` argument is present and the `flagToSet` has not already
 * been set to `true` for the `user`.
 *
 * @param {string} flagToSet - The name of the flag to set to `true` in the `viewed_flags`
 *   array for the `user`.
 * @param {object} user - The `User` for which `flagToSet` is to be set to `true`.
 * @param {object} user.viewed_flags - An object tracking flags related to things the `user`
 *   has done or seen before. The keys are the flag names and the values are booleans.
 * @param {number} user.id - The database ID of the `user`.
 */
export const setViewedFlag = ({flagToSet, user}) => {
  if (user && user.viewed_flags && !user.viewed_flags[flagToSet]) {
    UserActions.updateUser({
      userId: user.id,
      viewed_flags: {[flagToSet]: true}
    })
  }
}
