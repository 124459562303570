import {Map} from 'immutable'

const body = `
### Having A Funeral Or Memorial Service Before Or After A Cremation

Many people incorrectly believe that choosing cremation means that a traditional funeral cannot be held. In fact, choosing cremation in no way interferes with having a "traditional" funeral, and many cremations commonly take place after a traditional funeral has been held. Alternatively, you can choose to have a direct cremation followed by a memorial service at a later date.

### Cremation Products

The products you will need to purchase for the cremation depend on your personal preferences, the type of service you want to have, and what you want to do with the cremated remains. For the most part, however, you will likely need:

* Cremation Casket
* Urn

If you will be having a funeral before the cremation, you may want to purchase a casket or rent a casket. Would you like to purchase a casket that can be used for the funeral and the cremation, or would you like to rent a casket from the funeral home for the service and use an alternative container for the cremation?

* If you will be burying the cremated remains in a cemetery, you may also need a burial vault or grave liner, depending on the cemetery's rules. What are the cemetery's requirements regarding outer burial containers?
* Does the funeral home offer burial vaults or grave liners that meet both the cemetery's requirements as well as your own preferences, including affordable pricing?
* What are the cemetery's requirements regarding headstones and grave markers?
* Does the cemetery only work with specific vendors, or can you purchase a headstone or grave marker from a vendor of your choosing?

### Handling The Remains

Cremated remains are usually buried in a cemetery (either in a plot in the ground or interred in a columbarium), kept in an urn or other container by family members, or scattered. There are also a number of unconventional things one may do with cremated remains, such as turn them into jewelry, incorporate them into an artificial underwater reef, make them into fireworks, or shoot them into space, among other options.

### Cost Of Cremation

The cost of cremation generally includes:

* Any products you'll be purchasing, including a cremation casket, an urn to hold the remains, a burial vault or grave liner (if the remains will be buried in a plot at a cemetery), and a headstone or grave marker (if the remains will be buried in a plot or interred in a columbarium at a cemetery)
* Any services from the funeral home you'll be purchasing, such as transportation of the body, the cost of the cremation, and the use of funeral home staff and equipment for the service, among other services
* Cemetery costs, including a plot or a space in a columbarium, fees for opening and closing the grave, "endowment care" fees, and headstone installation fees, among others

### How To Plan A Cremation

A funeral home can help you coordinate a cremation. In some cases -- mostly depending on whether or not you want to have a funeral service before the cremation -- you may be able to work with a crematory directly. In addition, certain states have laws about who may coordinate a cremation, and in some state a funeral director at a funeral home must legally handle the arrangements. It's best to reach out to your local crematories and talk to them about the laws and regulations in your state.

### Religious Perspectives On Cremation

Many religions and cultures have strict opinions on cremation -- while some religions forbid cremation, others mandate it, while others are flexible. If you will be observing any religious or cultural traditions, those rules may influence your choice of disposition. We suggest talking to your local religious leader to learn more.

### Family Participation In Cremation

Many crematories will allow immediate family members to watch the cremation. For some, this may be desirable, especially in relation to certain religious rituals (such as in Hinduism). If you are interested in watching the cremation, those arrangements will likely have to be made with the crematory ahead of time.
`


export default Map({
  body,
  headline: 'All You Need To Know About Cremation',
  summary: 'When a body is cremated, the remains are burned to ash (sometimes called "cremains" or "cremated remains") and may be buried in the earth, interred in a columbarium niche, kept by the family in an urn, or scattered.',
  query: 'all-you-need-to-know-about-cremation',
  url: 'https://www.everplans.com/articles/all-you-need-to-know-about-cremation'
})
