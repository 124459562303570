import {useFormContext, Controller} from 'react-hook-form'

import TextInput from './TextInput'

const Email = () => {
  const {control, formState: {errors}} = useFormContext()

  return (
    <div className='email'>
      <Controller
        defaultValue=''
        name='email'
        control={control}
        rules={{
          required: 'Please add an email address.',
          pattern: {
            value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            message: 'Please add a valid email address.'
          }
        }}
        render={({field}) => (
          <TextInput
            {...field}
            id='email'
            type='email'
            label='Email address'
            error={!!errors.email}
            helperText={errors.email?.message}
          />
        )}
      />
    </div>
  )
}

export default Email
