import {useState, useEffect, useMemo} from 'react'
import {connect} from 'react-redux'
import {List, Map} from 'immutable'
import PropTypes from 'prop-types'
import {createResource, readEndpoint, updateResource} from 'redux-json-api'
import {withRouter} from 'react-router'

import Categories from './category/Categories'
import DeputyUpgradePromptController from '../deputy_upgrade_prompt/DeputyUpgradePromptController'
import EverplanPreviewLoader from './EverplanPreviewLoader'
import PreviewHeader from './header/PreviewHeader'
import FileStatus from '../category/FileStatus'
import PreviewSideNavContainer from './PreviewSideNavContainer'

import {allResponseGroupCount, categoriesWithItemResponseGroupMaps} from '../../../../../lib/plan_data/itemResponsesHelper'
import {cleanResponse} from '../redux/apiHelper'
import {currentUserConfig, currentActor} from '../../../../../lib/userTools'
import {everplanOwners, findResourceByAttributeId, isOwnEverplan, filterRawResourceByAttributeId} from '../../../../../lib/plan_data/userData'
import {pendingOrProcessedDeathReports} from '../../../../../lib/corpMaxDeputyTools'
import {scrollToTopOfPage} from '../../../../../lib/scrollTools'
import {usePrevious, useEverplanData, useDeputizerData} from '../../../../../lib/hooks'
import routerUtils from '../../../../../lib/routerUtils'
import MixpanelLogger from '../../../../../lib/MixpanelLogger'

import './everplanPreview.scss'


export const EverplanPreview = props => {
  const everplanId = parseInt(props.params.everplanId, 10)
  const prevParamEverplanId = usePrevious(everplanId)

  const actor = useMemo(() => currentActor({ownEverplan: props.ownEverplan, context: 'everplan_preview'}), [props.ownEverplan])

  const {loadingEverplanData, itemResponses, newContacts} = useEverplanData(everplanId)
  const {deputizers} = useDeputizerData(props.userConfig)

  const [categoriesWithItemResponses, setCategoriesWithItemResponses] = useState()
  const [readResponseGroupCount, setReadResponseGroupCount] = useState()
  const [deputyOwnership, setDeputyOwnership] = useState()

  useEffect(() => {
    if (!loadingEverplanData) {
      setReadResponseGroupCount(allResponseGroupCount(itemResponses))
      setCategoriesWithItemResponses(categoriesWithItemResponseGroupMaps({categories: props.categories, itemResponses}))
    }
  }, [loadingEverplanData, itemResponses])

  useEffect(() => {
    if (!deputizers?.isEmpty() && !props.ownEverplan)
      setDeputyOwnership(deputizers.find(deputizer => deputizer.get('everplan-id') === everplanId))
    else
      setDeputyOwnership()
  }, [everplanId, props.ownEverplan, deputizers])

  useEffect(() => {
    props.readEndpoint(`/advisor-contact-cards/${everplanId}`).catch(() => {})

    if (prevParamEverplanId !== everplanId) {
      setReadResponseGroupCount()
      setCategoriesWithItemResponses()
    }
  }, [everplanId])

  const logViewEvent = () => {
    if (props.ownEverplan)
      return

    const payload = {}
    const context = props.location.query.context

    if (context)
      payload.context = context

    MixpanelLogger.track('view_deputy_for_landing', payload)
  }
  // Adding this useEffect to make sure the sorting happen in the web way for deputies else it defaults to how transformer sorts it for mobile i.e in-reverse - Atanda
  useEffect(() => {
    if (!props.ownEverplan)
      props.readEndpoint(`item-views?filter[everplan_id]=${everplanId}`)
  }, [props.ownEverplan])

  useEffect(() => {
    logViewEvent()
    scrollToTopOfPage()
    props.readEndpoint('death-reports')
  }, [])

  if (!categoriesWithItemResponses || (!props.ownEverplan && !deputyOwnership)) {
    return <EverplanPreviewLoader ownEverplan={props.ownEverplan} />
  } else {
    const sharedProps = {
      newContacts,
      isHousehold: itemResponses.getIn([0, 'is-household']),
      everplanId,
      context: 'everplan_preview',
      actor,
      categoriesWithItemResponses,
      userConfig: props.userConfig,
      deputyOwnership,
      ownerships: everplanOwners({ownership: deputyOwnership, ownEverplan: props.ownEverplan, owners: props.owners}),
      ownEverplan: props.ownEverplan
    }

    const deathReports = pendingOrProcessedDeathReports({ownerships: sharedProps.ownerships, deathReports: props.deathReports})

    return (
      <div className='everplan-preview flex-container' key={everplanId}>
        <FileStatus />
        <PreviewSideNavContainer {...sharedProps} advisorContactCard={props.advisorContactCard} />
        <div className='preview-container flex-child'>
          <div className='preview-container-inner'>
            <PreviewHeader
              {...props}
              {...sharedProps}
              deathReports={deathReports}
              readItemCount={readResponseGroupCount}
            />
            <Categories {...props} {...sharedProps} />
            <DeputyUpgradePromptController
              userConfig={props.userConfig}
              advisorContactCard={props.advisorContactCard}
              ownerships={sharedProps.ownerships}
            />
            {
              routerUtils.childrenWithProps(
                props.children,
                {
                  owners: props.owners,
                  deputyOwnership,
                  context: 'everplan_preview',
                  ownEverplan: props.ownEverplan,
                  userConfig: props.userConfig
                }
              )
            }
          </div>
        </div>
      </div>
    )
  }
}

EverplanPreview.propTypes = {
  advisorContactCard: PropTypes.instanceOf(Map),
  categories: PropTypes.instanceOf(List),
  createResource: PropTypes.func,
  deathReports: PropTypes.instanceOf(List),
  everplanBundleDownload: PropTypes.instanceOf(Map),
  location: PropTypes.shape({
    query: PropTypes.object
  }),
  owners: PropTypes.instanceOf(List),
  ownEverplan: PropTypes.bool,
  params: PropTypes.object,
  readEndpoint: PropTypes.func,
  saveEverplanBundleDownload: PropTypes.func,
  userConfig: PropTypes.instanceOf(Map)
}

export const mapStateToProps = (state, {params}) => {
  const userConfig = currentUserConfig(state.api)
  const advisorContactCard = findResourceByAttributeId({
    resourceList: cleanResponse(state.api['advisor-contact-cards']),
    attribute: 'everplan-id',
    id: params.everplanId
  })

  return ({
    owners: state.owners,
    deathReports: state.api['death-reports'] ? cleanResponse(state.api['death-reports']) : List(),
    categories: state.categories,
    everplanBundleDownload: cleanResponse(state.api['everplan-bundle-downloads']).last() || Map(),
    advisorContactCard,
    ownEverplan: isOwnEverplan({everplanPreviewId: params.everplanId, owners: state.owners}),
    views: state.views,
    itemViews: filterRawResourceByAttributeId({
      attribute: 'everplan-id',
      id: params.everplanId,
      rawResource: state.api['item-views']
    }),
    userConfig
  })
}

export default connect(mapStateToProps, {readEndpoint, createResource, updateResource})(withRouter(EverplanPreview))
