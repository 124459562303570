import PropTypes from 'prop-types'

import {BoxGutterLarge} from '../../blocks/Boxes'
import {Button} from '../../blocks/Buttons'
import {TextGutterMedium} from '../../blocks/Texts'
import routerUtils from '../../../../../lib/routerUtils'


const AccountSettingsRow = ({buttonText = '', bodyText, title, url}) => {
  const onClick = () => routerUtils.push(url)

  return (
    <BoxGutterLarge>
      <h2>{title}</h2>
      <TextGutterMedium>{bodyText}</TextGutterMedium>
      {url && <Button onClick={onClick}>{buttonText || 'Manage'}</Button>}
    </BoxGutterLarge>
  )
}

AccountSettingsRow.propTypes = {
  buttonText: PropTypes.string,
  bodyText: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string
}

export default AccountSettingsRow
