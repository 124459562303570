/** @module onboardingAssessmentTools
 *
 * @desc
 * Helpers used to format the answers for the onboarding assessment.
 */

import {fromJS, List, Map, Set} from 'immutable'

/**
 *
 * @param {Object} form - the form for an individual assessment group
 * @param {string} groupName - name of the assessment group
 * @param {array} groupKeys - the keys for each assessment group
 *
 * @returns {Map} of the formated answers for an individual assessment group
 */
export const assessmentAnswersPerGroup = ({formData, groupName, groupItems}) => {
  const formValues = formData.getIn([groupName, 'value']) || []

  const reducer = (collection, groupValue) => {
    if (formValues.includes(groupValue))
      return collection.set(groupValue, true)
    else
      return collection.set(groupValue, false)
  }

  return groupItems
    .map(groupItem => groupItem.get('value'))
    .reduce(reducer, Map())
}

/**
 * Grabs all the answers in a form and flattens it.
 *
 * @param {Map} answers - the form answers for the whole assessment data
 *
 * @returns {Map} of the formated answers for an individual assessment group
 */
export const allAssessmentAnswers = ({answers}) => (
  answers.map(
    (value, name) => assessmentAnswersPerGroup({
      formData: answers,
      groupName: name,
      groupItems: value.get('items')
    })
  ).flatten(true)
)

/**
 * Gets the default values for each assessment group item from the assessment.
 *
 * @param {Map} assessment
 * @param {List} groupItems
 *
 * @returns {List} of the formated answers for an individual assessment group
 */
export const assessmentDefaultValuesPerGroup = ({assessment, groupItems}) => (
  groupItems
    .map(groupItem => groupItem.get('value'))
    .filter(groupItemValue => assessment.getIn(['data', groupItemValue]))
)

/**
 * Returns the percentage of completed questions for the Score assessment.
 *
 * @param {Map} assessment
 * @param {Map} currentScreen
 *
 * @returns {Number} indicating the percentage of completed Score assessment
 *                   questions
 */
export const getCompletedScoreAssessmentQuestionsPercentage = (
  scoreAssessment, currentScreen) => {
  if (!scoreAssessment.isEmpty() && !currentScreen.isEmpty()) {
    const sortedScoreAssessmentScreens = scoreAssessment.get(
      'assessment-screens').sortBy(
      assessmentScreen => assessmentScreen.get('sort-order')
    )

    const numberOfQuestions = sortedScoreAssessmentScreens.size

    const currentQuestionNumber = sortedScoreAssessmentScreens.findIndex(
      assessmentScreen => assessmentScreen.equals(currentScreen)) + 1

    return Math.round((currentQuestionNumber / numberOfQuestions) * 100)
  }
}

/**
 * Removes images from the assessmentData and sorts them accordingly
 *
 * @param {object} assessmentData
 *
 * @returns {Map} of sorted assessmentData without image key in the item.
 */
export const sortedAssessmentDataWithoutImages = ({assessmentData}) => (
  fromJS(assessmentData)
    .map(
      assessmentGroup => assessmentGroup.updateIn(
        ['items'],
        items => items.map(item => item.delete('image'))
      )
    )
    .sortBy(assessmentGroup => assessmentGroup.get('sort-order'))
)

/**
 * Gets the group names of all updated assessment groups
 *
 * @param {Map} previousAssessment
 * @param {Map} updatedAssessment
 * @param {Map} assessmentData
 *
 * @returns {Set} of all updated assessment group.
 */
export const updatedAssessmentGroupNames = ({previousAssessment, updatedAssessment, assessmentData}) => {
  const reducer = (collection, assessmentValue, assessmentKey) => {
    if (previousAssessment.get(assessmentKey) === assessmentValue) {
      return collection
    } else {
      const updatedAssessmentGroup = assessmentData.find(
        assessmentGroup => (
          assessmentGroup
            .get('items').map(item => item.get('value'))
            .includes(assessmentKey)
        )
      )
      return collection.add(updatedAssessmentGroup.get('name'))
    }
  }

  return updatedAssessment.reduce(reducer, Set())
}

/**
 * Returns true if the passed formData is empty or includes empty values,
 *  false otherwise
 * @param {Map} formData
 * @returns {Boolean}
 */
export const hasEmptyAssessmentFormValues = formData => (
  formData.isEmpty() || formData.some(value => !value)
)

/**
 * Returns a dictionary of sort order to the corresponding screen
 * @param {Map} scoreAssessment
 * @returns {Map}
 */
export const screensSortOrderDictionary = scoreAssessment => {
  if (scoreAssessment.isEmpty())
    return Map()

  const screens = scoreAssessment.get('content-screens').merge(scoreAssessment.get('assessment-screens'))

  return screens.reduce(
    (dictionary, assessmentScreen) => dictionary.set(assessmentScreen.get('sort-order'), assessmentScreen),
    Map()
  )
}

/**
 * Returns values from the specified options
 * @param {List<Map<{value: String}>} options - user selections in score assessment
 * @returns {List<String>}
 */
export const assessmentValuesFromOptions = options => options.reduce((collection, option) => collection.push(option.get('value')), List())

/**
 * Returns the next screen based on user response and account type
 * @param {Map} response
 * @param {Map} screensDictionary
 * @param {Boolean} assessmentOnly
 * @returns {Map}
 */
export const assessmentNextScreen = ({response, screensDictionary, assessmentWithoutScore}) => {
  const sortOrder = List.isList(response) ? response.getIn([0, 'next-screen']) : response.get('next-screen')
  const screen = screensDictionary.get(sortOrder) || Map()

  // Non firm or client users should not get the result screen. Hence the empty response here - Atanda
  if (screen.get('name') === 'result' && !assessmentWithoutScore)
    return Map({typename: 'scoreCalculation'})
  else
    return screen
}
