import {List, Map} from 'immutable'
import PropTypes from 'prop-types'

import {findResourceById} from '../../../../../lib/plan_data/userData'

const NextBestActionWizardFields = props => {
  if (props.nextBestAction.get('view-id')) {
    const viewName = findResourceById({
      resourceList: props.views,
      id: props.nextBestAction.get('view-id')
    }).get('name')

    return (
      <div className='next-best-action-wizard-fields'>
        <div>
          <strong>Wizard: </strong>
          {viewName}
        </div>
      </div>
    )
  } else {
    return null
  }
}


NextBestActionWizardFields.propTypes = {
  views: PropTypes.instanceOf(List),
  nextBestAction: PropTypes.instanceOf(Map)
}

export default NextBestActionWizardFields
