/** @class ManageItemPermissionsModal
 *
 * @desc
 * Modal for managing advisor/firm item permissions
 *
 */

import {useState} from 'react'
import PropTypes from 'prop-types'
import {Map, List} from 'immutable'

import ButtonGroup from '../../../../../../shared_components/core/buttons/ButtonGroup'
import {Cancel, Accept} from '../../../blocks/Buttons'
import Modals from '../../../../../../shared_components/core/modals/Modals'
import ItemPermissionsSelectForm from '../../../../../../shared_components/item_permissions/ItemPermissionsSelectForm'
import {ModalHeader} from '../../../../../../shared_components/core/modals/ModalHeaders'
import ManageItemPermissionsModalCloser from './ManageItemPermissionsModalCloser'

import {addPossessive, arraysAreEqual} from '../../../../../../lib/tools'
import {requestedItemPermissions} from '../../../../../../lib/itemPermissionTools'
import {useNotificationContext} from '../../../../../../shared_components/notifications/NotificationContext'


const ManageItemPermissionsModal = props => {
  const [processing, setProcessing] = useState(false)
  const [showModal, setShowModal] = useState(false)
  // checkedItemIds is set to an empty array, because the toggle will always initialize it with the right item ids.
  const [checkedItemIds, setCheckedItemIds] = useState([])
  const {alwaysNotify} = useNotificationContext()

  const toggleModal = () => {
    setShowModal(prevShowModal => !prevShowModal)
    setProcessing(false)
    setCheckedItemIds(props.firmOwnership.get('edit-item-ids').toArray())
  }

  const closeAndNotify = () => {
    toggleModal()
    alwaysNotify.longSuccess(`${addPossessive(props.advisorFullName)} permissions have been updated.`)
  }

  const submitItemPermissions = () => {
    // Don't want to make an api call when no item permissions have been changed
    if (arraysAreEqual(props.firmOwnership.get('edit-item-ids').toArray(), checkedItemIds)) {
      toggleModal()
    } else {
      setProcessing(true)

      const payload = {
        type: 'firm-ownerships',
        id: props.firmOwnership.get('id'),
        attributes: {
          'item-permissions': requestedItemPermissions({
            itemIds: checkedItemIds,
            permissionType: 'edit'
          })
        }
      }

      props.updateResource(payload).then(() => closeAndNotify())
    }
  }


  const closer = () => <ManageItemPermissionsModalCloser exit={toggleModal} />

  const onCheck = itemIds => { setCheckedItemIds(itemIds) }

  return (
    <span className='manage-item-permissions-modal'>
      <Accept onClick={toggleModal}>
        {props.firmOwnership.get('edit-item-ids').size ? 'Change permissions' : 'Select sections' }
      </Accept>
      <Modals.FullHeightModalLarge showModal={showModal} closerComponent={closer}>
        <ModalHeader
          heading={`Which sections should ${props.advisorFullName} be able to edit?`}
          note={
            props.firmOwnership.get('manageable-edit-item-ids').size !== props.items.size && (
              `Note: You can't give ${props.advisorFullName} permission to edit the sections you marked as "After Death"
                when you added ${props.advisorFullName} as a Deputy.`
            )
          }
        />
        <ItemPermissionsSelectForm
          checkedItemIds={checkedItemIds}
          onCheck={onCheck}
          permittedItemIds={props.firmOwnership.get('manageable-edit-item-ids')}
        />
        <ButtonGroup>
          <Cancel onClick={toggleModal} disabled={processing} />
          <Accept processing={processing} onClick={submitItemPermissions}>
              Save
          </Accept>
        </ButtonGroup>
      </Modals.FullHeightModalLarge>
    </span>

  )
}

ManageItemPermissionsModal.propTypes = {
  advisorFullName: PropTypes.string,
  firmOwnership: PropTypes.instanceOf(Map),
  items: PropTypes.instanceOf(List),
  updateResource: PropTypes.func
}

export default ManageItemPermissionsModal
