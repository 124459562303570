/** @class UndeletableContactModalContents
 *
 * @desc
 * Component that displays information about why a given contact cannot be deleted.
 *
 * @todo Fix up styling once final mocks are in place.
 * @todo Link each of the `contactItems` to at least the correct category page.
 */

import PropTypes from 'prop-types'
import {Component} from 'react'
import {List} from 'immutable'

import {Text, TextGutterMedium} from '../../blocks/Texts'
import UnorderedList from '../../../../../shared_components/UnorderedList'

import Logger from '../../../../../lib/NewLogger'

import './undeletableContactModalContents.scss'

export default class UndeletableContactModalContents extends Component {
  componentDidMount() {
    Logger.log({
      name: 'attempted_to_delete_contact_in_use',
      payload: {
        context: 'address_book',
        items_in_use_count: this.props.contactItems.size
      }
    })
  }

  shouldComponentUpdate() { return false }

  render() {
    return (
      <div
        className='undeletable-contact-modal-contents'
        data-testid='undeletable-contact-modal-contents'>
        <h2 className='heading'>Delete {this.props.contactLabel}</h2>
        <TextGutterMedium>
          Sorry--this contact cannot be removed because it is in use in the following sections of your Everplan:
        </TextGutterMedium>
        <UnorderedList children={this.props.contactItems.toArray()} />
        <Text>
          Please remove the contact from the above locations, then return here to delete it from the address book.
        </Text>
      </div>
    )
  }
}

UndeletableContactModalContents.propTypes = {
  contactItems: PropTypes.instanceOf(List),
  contactLabel: PropTypes.string
}
