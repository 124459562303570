import PropTypes from 'prop-types'
import {Component} from 'react'

import ArticleBox from '../../../../../shared_components/everplans_articles/ArticleBox'
import {Map} from 'immutable'

export default class ResourceBox extends Component {
  constructor(props) {
    super(props)

    this.openResource = this.openResource.bind(this)
  }

  openResource() {
    // Don't open the new tab when running tests (BLR):
    if (__TEST__)
      return

    window.open(this.props.resource.url, '_blank').focus() // Workaround to avoid anchor tags, which have high-specificity CSS rules for blue text. --BLR
  }

  render() {
    return (
      <ArticleBox article={Map(this.props.resource)} context='dashboard' next={this.openResource} />
    )
  }
}

ResourceBox.propTypes = {
  resource: PropTypes.object
}
