import PropTypes from 'prop-types'

import BoxWithTextAndCircleCheckWithNumber from './BoxWithTextAndCircleCheckWithNumber'
import DeferredRead from './DeferredRead'
import Download from './Download'
import {Text} from '../../../../../../dashboard/src/components/blocks/Texts'

import {pluralize} from '../../../../../../lib/tools'

const NonDeceasedHeaderEverplanInformation = props => (
  <div className='header-everplan-information non-deceased-information flex-container'>
    <BoxWithTextAndCircleCheckWithNumber className='read-items-text' textClass='read-items-information' itemCount={props.readItemCount}>
      <Text className='read-text'>
        {props.readItemCount} {pluralize('item', props.readItemCount)} {props.ownEverplan ? 'in your plan' : 'shared with you now'}
      </Text>
      <Download {...props} />
    </BoxWithTextAndCircleCheckWithNumber>
    <DeferredRead {...props} />
  </div>
)

NonDeceasedHeaderEverplanInformation.propTypes = {
  ownEverplan: PropTypes.bool,
  readItemCount: PropTypes.number
}

export default NonDeceasedHeaderEverplanInformation
