/** @class ManageItemPermissionsContainer
 *
 * @desc
 * Container to display information for a client about firm/advisor item permissions and option to manage them.
 *
 */

import {Component} from 'react'
import {connect} from 'react-redux'
import {Map, List} from 'immutable'
import PropTypes from 'prop-types'
import {updateResource} from 'redux-json-api'

import ManageItemPermissionsModal from './ManageItemPermissionsModal'
import {BoxGutterMedium} from '../../../blocks/Boxes'
import ItemPermissionsExplanation from './ItemPermissionsExplanation'
import ExistingItemPermissionInformation from './ExistingItemPermissionInformation'
import {findRawResourceByAttributeId} from '../../../../../../lib/plan_data/userData'

export class ManageItemPermissionsContainer extends Component {
  componentDidMount() {
    this.props.readEndpoint(`firms/${this.props.firmOwnership.get('ownable-id')}?include=firm-config`)
  }

  render() {
    if (this.props.firmConfig.get('write-access')) {
      const advisorFullName = this.props.advisorContactCard.get('name')
      return (
        <BoxGutterMedium>
          <h2>Permission to Edit:</h2>
          <ExistingItemPermissionInformation
            firmOwnership={this.props.firmOwnership}
            advisorFullName={advisorFullName}
          />
          <ManageItemPermissionsModal
            updateResource={this.props.updateResource}
            firmOwnership={this.props.firmOwnership}
            items={this.props.items}
            advisorFullName={advisorFullName}
          />
          <ItemPermissionsExplanation advisorFullName={advisorFullName} />
        </BoxGutterMedium>
      )
    } else {
      return null
    }
  }
}

ManageItemPermissionsContainer.propTypes = {
  advisorContactCard: PropTypes.instanceOf(Map),
  firmConfig: PropTypes.instanceOf(Map),
  firmOwnership: PropTypes.instanceOf(Map),
  readEndpoint: PropTypes.func,
  items: PropTypes.instanceOf(List),
  updateResource: PropTypes.func,
  userConfig: PropTypes.instanceOf(Map)
}


const mapStateToProps = (state, {firmOwnership, userConfig}) => ({
  firmConfig: findRawResourceByAttributeId({
    rawResource: state.api['firm-configs'],
    attribute: 'firm-id',
    id: firmOwnership.get('ownable-id')
  }),
  firmOwnership,
  userConfig,
  items: state.items
})

export default connect(mapStateToProps, {updateResource})(ManageItemPermissionsContainer)
