import {Component} from 'react'
import PropTypes from 'prop-types'
import {Map, List} from 'immutable'

import Form from '../../../../../../shared_components/forms/v2/Form'
import MarkdownText from '../../../../../../pro/src/components/shared_firm/settings/MarkdownText'
import Fields from '../../../../../../shared_components/forms/v2/fields/index'
import {NEXT_BEST_ACTION_ICONS_MAP} from '../../../../../../shared_components/next_best_actions/NextBestActionIcon'

import './nextBestActionSimpleFields.scss'

const SELECT_ICONS = NEXT_BEST_ACTION_ICONS_MAP
  .sortBy((nextBestActionIcon, iconName) => iconName)
  .map(
    (nextBestActionIcon, iconName) => Map({
      id: iconName,
      image: nextBestActionIcon,
      label: iconName,
      value: iconName
    })
  )
  .toList()
  .unshift(Map({label: 'Choose an Icon', value: ''}))


export default class NextBestActionSimpleFields extends Component {
  constructor() {
    super()

    this.onUpdate = this.onUpdate.bind(this)
  }


  onUpdate() {
    if (this.refs && this.refs.form) {
      this.props.updateNextBestAction(
        this.props.nextBestAction
          .merge(this.refs.form.formData().map(data => data.get('value')))
          .set('sort-order', parseFloat(this.refs.form.formData().getIn(['sort-order', 'value']), 10))
      )
    }
  }

  render() {
    if (this.props.showFields) {
      const nextBestAction = this.props.nextBestAction

      return (
        <Form className='core next-best-action-simple-fields' ref='form' onUpdate={this.onUpdate}>
          <Fields.Text
            data={
              Map({
                id: 'header',
                legend: 'Header:',
                name: 'header',
                value: nextBestAction.get('header')
              })
            }
          />
          <Fields.Text
            data={
              Map({
                id: 'prompt',
                legend: 'Prompt:',
                name: 'prompt',
                value: nextBestAction.get('prompt', '')
              })
            }
          />
          <Fields.Text
            data={
              Map({
                id: 'action-text',
                legend: 'Call to action text/Wizard header:',
                name: 'action-text',
                value: nextBestAction.get('action-text', '')
              })
            }
          />
          <Fields.Number
            data={
              Map({
                id: 'duration',
                legend: 'Time to complete:',
                name: 'duration',
                value: nextBestAction.get('duration', '')
              })
            }
          />
          <Fields.Select
            data={
              Map({
                id: 'icon',
                items: SELECT_ICONS,
                legend: 'Select Icon:',
                name: 'icon',
                value: nextBestAction.get('icon', '')
              })
            }
          />
          <Fields.Number
            data={
              Map({
                id: 'sort-order',
                legend: 'Sort Order Number:',
                name: 'sort-order',
                value: nextBestAction.get('sort-order', '')
              })
            }
          />
          <Fields.TextArea
            data={
              Map({
                id: 'gather-text',
                legend: 'Gather Text:',
                name: 'gather-text',
                value: nextBestAction.get('gather-text', '')
              })
            }
          />
          {
            nextBestAction.get('gather-text') && (
              <div>
                <legend>Preview:</legend>
                <MarkdownText displayText={nextBestAction.get('gather-text', '')} />
              </div>

            )
          }
        </Form>
      )
    } else {
      return null
    }
  }
}

NextBestActionSimpleFields.propTypes = {
  categories: PropTypes.instanceOf(List),
  nextBestAction: PropTypes.instanceOf(Map),
  showFields: PropTypes.func,
  updateNextBestAction: PropTypes.func
}
