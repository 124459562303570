import {Component} from 'react'
import {List, Map} from 'immutable'
import PropTypes from 'prop-types'

import AddViewElements from './add_view_elements/AddViewElements'
import EditViewForm from './edit_view_form/EditViewForm'
import {filterNotResourceById} from '../../../../../../lib/plan_data/userData'


export default class ViewForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedElements: props.selectedElements,
      selectedConditionalElements: props.selectedConditionalElements,
      showEditViewForm: false,
      dependentResponseElements: props.dependentResponseElements
    }

    this.addConditionalElement = this.addConditionalElement.bind(this)
    this.removeConditionalElement = this.removeConditionalElement.bind(this)
    this.addDependentResponseElement = this.addDependentResponseElement.bind(this)
    this.removeDependentResponseElement = this.removeDependentResponseElement.bind(this)
    this.removeElement = this.removeElement.bind(this)
    this.addElement = this.addElement.bind(this)
    this.toggleEditViewForm = this.toggleEditViewForm.bind(this)
  }

  addConditionalElement(conditionalElement) {
    this.setState({selectedConditionalElements: this.state.selectedConditionalElements.push(conditionalElement)})
  }

  removeConditionalElement(conditionalElement) {
    this.setState({
      selectedConditionalElements: filterNotResourceById({
        resource: this.state.selectedConditionalElements,
        id: conditionalElement.get('id')
      })
    })
  }

  addElement(selectedElement) {
    this.setState({selectedElements: this.state.selectedElements.push(selectedElement)})
  }

  removeElement(selectedElement) {
    const selectedElements = filterNotResourceById({resource: this.state.selectedElements, id: selectedElement.get('id')})

    this.setState({
      selectedElements,
      dependentResponseElements: this.filterDependentResponseElements(selectedElements),
      selectedConditionalElements: this.filterConditionalElements(selectedElement)
    })
  }

  // Removes dependent response element if all dependent elements have been removed from the selected elements list
  filterDependentResponseElements(selectedElements) {
    const selectedElementIds = selectedElements.map(selectedElement => selectedElement.get('id'))

    return this.state.dependentResponseElements.filter(requiredResponseElement =>
      requiredResponseElement
        .get('dependent-element-ids')
        .some(dependentElementId => selectedElementIds.includes(dependentElementId))
    )
  }

  // Removes all selected conditional elements that are associated to a selected element that has been removed
  filterConditionalElements(selectedElement) {
    const conditionalElementIds = selectedElement.get('conditional-element-ids', List())
    return this.state.selectedConditionalElements
      .filterNot(conditionalElement => conditionalElementIds.includes(conditionalElement.get('id')))
  }

  addDependentResponseElement(responseElement) {
    this.setState({dependentResponseElements: this.state.dependentResponseElements.mergeDeep(responseElement)})
  }

  removeDependentResponseElement(responseElement) {
    this.setState({dependentResponseElements: this.state.dependentResponseElements.delete(responseElement.get('element-id'))})
  }

  toggleEditViewForm() {
    this.setState({showEditViewForm: !this.state.showEditViewForm})
  }

  sharedProps() {
    return {
      ...this.props,
      ...this.state,
      toggleEditViewForm: this.toggleEditViewForm,
      removeElement: this.removeElement,
      addElement: this.addElement,
      addDependentResponseElement: this.addDependentResponseElement,
      removeDependentResponseElement: this.removeDependentResponseElement,
      addConditionalElement: this.addConditionalElement,
      removeConditionalElement: this.removeConditionalElement
    }
  }

  render() {
    return (
      <div className='forms-playground'>
        <AddViewElements {...this.sharedProps()} />
        <EditViewForm {...this.sharedProps()} />
      </div>
    )
  }
}

ViewForm.propTypes = {
  selectedElements: PropTypes.instanceOf(List),
  selectedConditionalElements: PropTypes.instanceOf(List),
  dependentResponseElements: PropTypes.instanceOf(Map)
}
