import {List, Map} from 'immutable'
import PropTypes from 'prop-types'

import AddressBookContact from './AddressBookContact'
import AddressBookContactsHeading from './AddressBookContactsHeading'
import {Box} from '../../blocks/Boxes'

import './addressBookContacts.scss'

const AddressBookContacts = props => (
  <Box className='address-book-contacts'>
    <AddressBookContactsHeading />
    {
      props.newContacts.map(
        contact => <AddressBookContact {...props} contact={contact} key={contact.get('id')} />
      )
    }
  </Box>
)

AddressBookContacts.propTypes = {
  currentUser: PropTypes.instanceOf(Map),
  deputies: PropTypes.instanceOf(List),
  newContacts: PropTypes.instanceOf(List),
  params: PropTypes.object,
  userConfig: PropTypes.instanceOf(Map)
}

export default AddressBookContacts
