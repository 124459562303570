import PropTypes from 'prop-types'

import {Box, Button, Typography} from '@mui/material'

import sharingImage from '../../../../dashboard/assets/megaphone.svg'

import './sharingModuleWithoutDeputies.scss'

const SharingModuleWithoutDeputies = ({openAddDeputyModal}) => (
  <Box className='sharing-module-without-deputies'>
    <img alt='' src={sharingImage} />
    <Typography className='heading' variant='h3'>
      Sharing is a big part of getting it all in order
    </Typography>
    <Typography className='body-text'>
      Make sure people in your life have access to what they need.
    </Typography>
    <Typography className='body-text'>
    At Everplans, we call these trusted contacts “deputies.”
    </Typography>
    <Button className='invite' variant='contained' size='medium' onClick={openAddDeputyModal}>
      Invite now
    </Button>
  </Box>
)

SharingModuleWithoutDeputies.propTypes = {
  openAddDeputyModal: PropTypes.func
}

export default SharingModuleWithoutDeputies
