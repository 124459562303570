import {useEffect, useState} from 'react'

import {List} from 'immutable'
import {useForm, Controller} from 'react-hook-form'
import PropTypes from 'prop-types'

import {Button, Cancel} from '../../blocks/Buttons'
import ButtonGroup from '../../../../../shared_components/core/buttons/ButtonGroup'
import {humanizedFullName} from '../../../../../lib/nameTools'
import {existingPermissionOptions, defaultSpecificItemDeputiesPermissionValues} from '../../../../../lib/deputyPermissionTools'
import PermissionSelect from './shared_components/PermissionSelect'
import './manageExistingPermissions.scss'

const ManageExistingPermissions = props => {
  const {control, formState: {isDirty}, handleSubmit} = useForm({
    defaultValues: defaultSpecificItemDeputiesPermissionValues({deputiesWithPermission: props.deputiesWithPermission})
  })
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true)

  useEffect(() => {
    if (isDirty)
      setIsSaveButtonDisabled(false)
    else
      setIsSaveButtonDisabled(true)
  }, [isDirty])

  return (
    <form className='manage-existing-permissions'>
      <div className='shared-with'>Currently shared with</div>
      <div className='existing-permissions'>
        {
          props.deputiesWithPermission.map(deputy => (
            <div className='existing-permission-container' key={deputy.get('ownership-id')}>
              <div className='name'>{humanizedFullName(deputy.get('ownable'))}</div>
              <Controller
                name={deputy.get('ownership-id').toString()}
                control={control}
                render={({field}) => (
                  <PermissionSelect
                    {...field}
                    classNamePrefix='existing-permission-select'
                    options={existingPermissionOptions}
                  />
                )}
              />
            </div>
          ))
        }
      </div>
      <a className='add-deputy-link' onClick={props.addNewDeputyLink}>Add a new deputy</a>
      <ButtonGroup>
        <Cancel onClick={props.toggleModal} disabled={props.processing}>Cancel</Cancel>
        <Button disabled={isSaveButtonDisabled} onClick={handleSubmit(props.onSubmit)} processing={props.processing}>Save</Button>
      </ButtonGroup>
    </form>
  )
}

ManageExistingPermissions.propTypes = {
  addNewDeputyLink: PropTypes.func,
  deputiesWithPermission: PropTypes.instanceOf(List),
  onSubmit: PropTypes.func,
  processing: PropTypes.bool,
  toggleModal: PropTypes.func
}

export default ManageExistingPermissions
