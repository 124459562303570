import PropTypes from 'prop-types'
import {useEffect, useCallback} from 'react'
import {connect} from 'react-redux'
import {createResource, updateResource, readEndpoint} from 'redux-json-api'
import {Map} from 'immutable'

import FuneralExpenseAssessmentContainer from './funeral_expense/FuneralExpenseAssessmentContainer'
import DigitalSubscriptionContainer from './digital_subscription/DigitalSubscriptionContainer'
import {nextStep, previousStep, resetStep} from './actions/gapFillerActionCreators'

import {currentUserConfig, currentUser, userAssessment} from '../../../../lib/userTools'
import subscriptionItemElementMap from './configurations/subscriptionItemElementMap'

import './gapFillerAssessmentContainer.scss'
import {ownershipId} from '../../../../lib/responsesHelper'

export const FUNERAL_EXPENSE = 'burial-cremation-or-donation-preferences-my-disposition-wishes-and-plans'
export const DIGITAL_SUBSCRIPTION = 'digital-world-video-streaming'

export const GapFillerAssessmentContainer = props => {
  const step = props.step

  // This resets the assessment to start from the beginning the next time a user visits
  // the assessment modal. Please note that the method is passed in as a callback which signifies
  // that it should be called when component unmounts. - Kay
  useEffect(() => props.resetStep, [])

  const goToPreviousStep = useCallback(
    () =>
      (step === 0 ?
        props.toggleGapFillerAssessmentModal() :
        props.previousStep({step})),
    [step]
  )

  const allProps = {
    ...props,
    goToPreviousStep
  }

  if (props.itemSlug === FUNERAL_EXPENSE)
    return <FuneralExpenseAssessmentContainer {...allProps} />
  else if (props.itemSlug === DIGITAL_SUBSCRIPTION)
    return <DigitalSubscriptionContainer {...allProps} />
  else return null
}

GapFillerAssessmentContainer.propTypes = {
  itemSlug: PropTypes.string,
  resetStep: PropTypes.func,
  toggleGapFillerAssessmentModal: PropTypes.func,
  previousStep: PropTypes.func,
  step: PropTypes.number
}

const mapStateToProps = state => {
  const userConfig = currentUserConfig(state.api)
  const assessment = userAssessment({
    userConfig,
    assessments: state.api.assessments
  })
  const user = currentUser({userConfig, users: state.api.users})
  const step = state.gapFillerAssessment.get('step')
  const ownershipIds = state.owners
    .filter(
      owner =>
        parseInt(owner.get('ownable-id'), 10) === parseInt(user.get('id'), 10) &&
        owner.get('ownable-type') === 'User'
    )
    .map(owner => ownershipId(owner))

  const digitalItems = state.items
    .filter(item => subscriptionItemElementMap.has(item.get('id')))
    .sortBy(item => item.get('name'))
    .map(item => {
      const itemCategory = state.categories.find(
        category => category.get('id') === item.get('category-id')
      )
      return Map({
        label: item.get('name'),
        value: item.get('name'),
        'category-name': itemCategory.get('name'),
        'category-order': itemCategory.get('sort-order'),
        id: item.get('id')
      })
    }
    )

  return {assessment, step, user, digitalItems, ownershipIds, userConfig}
}

export default connect(mapStateToProps, {
  createResource,
  updateResource,
  readEndpoint,
  nextStep,
  previousStep,
  resetStep
})(GapFillerAssessmentContainer)

