/** @class CorpMaxHouseholdDeathReport
 *
 * @desc
 * Component that displays a `form` where the current user selects either one or both members
 * of a household to mark as deceased.
 */

import {Component} from 'react'
import PropTypes from 'prop-types'
import {fromJS, List} from 'immutable'

import ButtonGroup from '../../../../../shared_components/core/buttons/ButtonGroup'
import {Button, Cancel} from '../../blocks/Buttons'

import Fields from '../../../../../shared_components/forms/v2/fields'
import Form from '../../../../../shared_components/forms/v2/Form'

import './corpMaxHouseholdDeathReport.scss'


class CorpMaxHouseholdDeathReport extends Component {
  constructor() {
    super()
    this.state = {isReportDeathButtonDisabled: true, value: null}
    this.next = this.next.bind(this)
    this.updateForm = this.updateForm.bind(this)
  }

  /**
   * Constructs the `items` used to populate the radio list in the modal.
   * there are radio buttons for the individual household members
   */
  items() {
    return this.props.ownerships
      .filter(owner => !owner.get('deceased'))
      .map(owner => this.ownerItem(owner))
  }

  ownerItem(owner) {
    return fromJS({
      id: owner.get('id'),
      label: `Report ${owner.get('first-name')} ${owner.get('last-name')} as deceased`,
      value: `${owner.get('owner-type')}|${owner.get('id')}` // had to coerce the value to a string because input checkbox values are always strings
    })
  }

  /** updateForm */
  updateForm() {
    if (this.refs.form) {
      this.setState({
        isReportDeathButtonDisabled: false,
        value: this.refs.form.formData().getIn(['death_to_report', 'value'])
      })
    }
  }

  /** next */
  next() { this.props.markAsDeceased(this.state.value) }

  render() {
    return (
      <div className='household-death-report forms-playground'>
        <header><h3>Who should be reported as deceased?</h3></header>
        <Form className='core' ref='form' onUpdate={this.updateForm}>
          <Fields.VerticalRadioList
            data={
              fromJS({
                id: 'death_to_report',
                items: this.items(),
                name: 'mark_deceased',
                value: this.state.value
              })
            }
          />
        </Form>
        <ButtonGroup>
          <Cancel onClick={this.props.closeModal} />
          <Button
            disabled={this.state.isReportDeathButtonDisabled}
            onClick={this.next}
            processing={this.props.processing}>
            Report Death
          </Button>
        </ButtonGroup>
      </div>
    )
  }
}

CorpMaxHouseholdDeathReport.propTypes = {
  closeModal: PropTypes.func,
  markAsDeceased: PropTypes.func,
  ownerships: PropTypes.instanceOf(List),
  processing: PropTypes.bool
}

export default CorpMaxHouseholdDeathReport
