import classnames from 'classnames'

/*
  You can pass the scoping class selector `global-footer-overrides` to this component if any global overrides are needed. --KTW
*/
import {Component} from 'react'
import PropTypes from 'prop-types'
import {withRouter} from 'react-router'

import MarkdownText from '../pro/src/components/shared_firm/settings/MarkdownText'

import Container from '../lib/Container'
import currentUserWrapper from './currentUserWrapper'
import {routeContainsVerb} from '../dashboard/src/lib/corpMaxRouteMatcher'

import './disclosureFooter.scss'

export class DisclosureFooter extends Component {
  constructor() {
    super()

    this.disclosure = Container.getStore('config').getState().data.disclosure
  }

  isCategoryActive() {
    return routeContainsVerb(this.props.location, ['category'])
  }

  isDashboardActive() {
    return routeContainsVerb(this.props.location, ['dashboard'])
  }

  isDeputiesActive() {
    return routeContainsVerb(this.props.location, ['deputies'])
  }

  isDeputyForActive() {
    return routeContainsVerb(this.props.location, ['deputy-for'])
  }

  isEverplanPreviewActive() {
    return routeContainsVerb(this.props.location, ['everplan-preview'])
  }

  classNames() {
    return classnames(
      'disclosure-footer',
      'flex-child',
      this.props.className,
      {
        'is-category-page': this.isCategoryActive(),
        'is-dashboard-page': this.isDashboardActive(),
        'is-deputies-page': this.isDeputiesActive(),
        'is-deputy-for-page': this.isDeputyForActive(),
        'is-everplan-preview-page': this.isEverplanPreviewActive()
      }
    )
  }

  renderDisclosureFooter() { return (this.disclosure && this.disclosure.enabled && this.disclosure.show_in_footer) }

  render() {
    if (this.renderDisclosureFooter()) {
      return (
        <footer {...this.props} className={this.classNames()}>
          <div className='content-container'>
            <div className='content'>
              <h6 className='disclosure-heading legacy-tiny-subheading'>DISCLOSURE</h6>
              <MarkdownText displayText={this.disclosure.markdown_text} />
            </div>
          </div>
        </footer>
      )
    } else {
      return null
    }
  }
}

DisclosureFooter.propTypes = {
  currentUser: PropTypes.shape({client: PropTypes.bool}),
  location: PropTypes.object,
  markdownText: PropTypes.string,
  router: PropTypes.shape({isActive: PropTypes.func})
}

export default withRouter(currentUserWrapper(DisclosureFooter))
