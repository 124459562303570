import classnames from 'classnames'
import {Map} from 'immutable'
import PropTypes from 'prop-types'

import './categoryItemHeader.scss'


const CategoryItemHeader = props => (
  <header className={classnames('category-item-header item-box-heading flex-container', {empty: props.responseGroups.isEmpty()})}>
    <div className='flex-container'>
      <h3 className='flex-child item-header'>
        <span>{props.itemResponse.get('header')}</span>
      </h3>
    </div>
  </header>
)

CategoryItemHeader.propTypes = {
  itemResponse: PropTypes.instanceOf(Map),
  ownEverplan: PropTypes.bool,
  responseGroups: PropTypes.instanceOf(Map)
}


export default CategoryItemHeader
