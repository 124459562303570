import classnames from 'classnames'
import PropTypes from 'prop-types'

import {useEffect, useMemo} from 'react'
import {connect} from 'react-redux'
import {List, Map} from 'immutable'
import {updateResource, createResource} from 'redux-json-api'

import {Grid} from '@mui/material'

import NextBestActions from '../../../../../shared_components/next_best_actions/NextBestActions'
import ContactCard from '../../contact_card/ContactCard'
import DashboardCategoryHeader from './DashboardCategoryHeader'
import EverplansArticles from '../../../../../shared_components/everplans_articles/EverplansArticles'
import FileStatus from './FileStatus'
import ItemsWithoutResponses from './ItemsWithoutResponses'
import ItemsWithResponses from './ItemsWithResponses'

import {categoryNameFromSlug} from '../../../../../lib/responsesHelper'
import {cleanResponse} from '../redux/apiHelper'
import {currentUserConfig, userAssessment, userPartner, userProfile} from '../../../../../lib/userTools'
import {EVERPLANS_ARTICLES_BY_CATEGORY} from './everplansArticlesByCategory'
import {filterNextBestActionsByItems} from '../../../lib/nextBestActionTools'
import {
  findFirmConfigByFirmOwnership,
  filterRawResourceByAttributeId,
  isOwnEverplan
} from '../../../../../lib/plan_data/userData'
import routerUtils from '../../../../../lib/routerUtils'
import {scrollToTopOfPage} from '../../../../../lib/scrollTools'
import {usePrevious, useEverplanData} from '../../../../../lib/hooks'

import './category.scss'

export const Category = props => {
  const {category, children, params, userConfig} = props

  const {itemResponses, newContacts} = useEverplanData(userConfig.get('everplan-id'))
  const categoryItemResponses = itemResponses.filter(itemResponse => itemResponse.get('category-id') === category.get('id'))
  const isClient = userConfig.get('client')
  const nextBestActions = useMemo(() => filterNextBestActionsByItems({...props, itemResponses: categoryItemResponses}), [categoryItemResponses])

  const prevParamSlug = usePrevious(params.slug)

  useEffect(() => {
    scrollToTopOfPage()
  }, [])

  useEffect(() => {
    if (prevParamSlug !== params.slug)
      scrollToTopOfPage()
  }, [params.slug])

  return (
    <article className='category flex-container'>
      <FileStatus />
      <div className={classnames('category-container', {'with-client': isClient})}>
        <div className='category-inner'>
          <Grid container columnSpacing={{mobileSm: 0, tabletLg: 6}}>
            <Grid item mobileSm={12} tabletLg={isClient ? 8.4 : 12}>
              <DashboardCategoryHeader category={category} userConfig={userConfig} />
              <NextBestActions {...props} nextBestActions={nextBestActions} itemResponses={itemResponses} newContacts={newContacts} context='category_page' />
              <ItemsWithResponses
                {...props}
                itemResponses={itemResponses}
                newContacts={newContacts}
                categoryItemResponses={categoryItemResponses}
                actor='planholder'
                category={category}
                categorySlug={params.slug}
                context='category_page'
              />
              <ItemsWithoutResponses
                categoryItemResponses={categoryItemResponses}
                category={category}
                pathname={props.location.pathname}
                categorySlug={params.slug}
                context='category_page'
                userConfig={userConfig}
              />
              <EverplansArticles
                userConfig={userConfig}
                articles={EVERPLANS_ARTICLES_BY_CATEGORY.get(category.get('name'))}
                context='category_page'
                location={props.location}
              />
            </Grid>
            {isClient &&
              <Grid item mobileSm={12} tabletLg={3.6}>
                <ContactCard />
              </Grid>}
          </Grid>
          {
            routerUtils.childrenWithProps(
              children,
              {
                owners: props.owners,
                firmConfig: props.firmConfig,
                ownEverplan: props.ownEverplan,
                context: 'category_page',
                profile: props.profile,
                userConfig,
                nextBestActions
              }
            )
          }
        </div>
      </div>
    </article>
  )
}


Category.defaultProps = {
  params: {slug: ''}
}

Category.propTypes = {
  assessment: PropTypes.instanceOf(Map),
  categories: PropTypes.instanceOf(List),
  category: PropTypes.instanceOf(Map),
  categoryItems: PropTypes.instanceOf(List),
  firmConfig: PropTypes.instanceOf(Map),
  items: PropTypes.instanceOf(List),
  kits: PropTypes.instanceOf(Map),
  location: PropTypes.object,
  nextBestActions: PropTypes.instanceOf(List),
  owners: PropTypes.instanceOf(List),
  ownEverplan: PropTypes.bool,
  profile: PropTypes.instanceOf(Map),
  params: PropTypes.shape({
    slug: PropTypes.string
  }),
  updateResource: PropTypes.func,
  userConfig: PropTypes.instanceOf(Map)
}

export const mapStateToProps = (state, {params}) => {
  const userConfig = currentUserConfig(state.api)
  const profile = userProfile({profiles: state.api.profiles, userConfig})
  const everplanId = userConfig.get('everplan-id')
  // Using the `slug` to get a 'rough draft' of the actual name, then using the actual `category` once we have that
  // available to avoid subtle capitalization changes (e.g., `Family And Loved Ones` versus the correct version
  // `Family and Loved Ones`). --BLR
  const rawCategoryName = categoryNameFromSlug(params.slug).toLowerCase()
  const category = state.categories.find(
    candidateCategory => (candidateCategory.get('name').toLowerCase() === rawCategoryName)
  )
  const assessment = userAssessment({userConfig, assessments: state.api.assessments})

  const gapFillerContentDownloads = filterRawResourceByAttributeId({
    attribute: 'state',
    id: profile.get('state'),
    rawResource: state.api['content-downloads']
  })

  return ({
    assessment,
    partner: userPartner({userConfig, partners: state.api.partners}),
    nextBestActions: cleanResponse(state.api['next-best-actions']),
    category,
    ownEverplan: isOwnEverplan({everplanPreviewId: everplanId, owners: state.owners}),
    categories: state.categories,
    gapFillerContentDownloads,
    owners: state.owners,
    profile,
    userConfig,
    itemViews: filterRawResourceByAttributeId({
      attribute: 'everplan-id',
      id: userConfig.get('everplan-id'),
      rawResource: state.api['item-views']
    }),
    firmConfig: findFirmConfigByFirmOwnership({firmOwnerships: state.api['firm-ownerships'], firmConfigs: state.api['firm-configs'], userConfig})
  })
}

export default connect(mapStateToProps, {updateResource, createResource})(Category)
