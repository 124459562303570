/** @class DropdownTooltip
 *
 * @desc
 * Component that displays and/or hides the dropdown `tooltip box` when the `launcher` element is clicked.
 *
 *    Props:
 *    id - (Optional) For web accessability. The ID for the `tooltip box` and to be added to the parent element's `aria-describedby` attribute when open.
 *    launcher - (Required) The element that is displayed on the page where the `tooltop box` will display/hide when clicked.
 **/

import classnames from 'classnames'
import {Component} from 'react'
import PropTypes from 'prop-types'

import Tooltip from './Tooltip'

import './dropdownTooltip.scss'

export default class DropdownTooltip extends Component {
  constructor() {
    super()

    this.state = {
      ariaDescribedby: '',
      ariaExpanded: false
    }

    this.hideDropdownTooltipBox = this.hideDropdownTooltipBox.bind(this)
    this.openTooltip = this.openTooltip.bind(this)
  }

  componentDidMount() { document.addEventListener('mousedown', this.hideDropdownTooltipBox) }

  componentWillUnmount() { document.removeEventListener('mousedown', this.hideDropdownTooltipBox) }

  /** dropdownTooltipProps() */
  dropdownTooltipProps() {
    return {
      'aria-expanded': this.state.ariaExpanded,
      'aria-describedby': this.state.ariaDescribedby,
      className: classnames('dropdown-tooltip', this.props.className),
      'data-testid': this.props.dataTestId,
      ref: 'tooltip',
      onClick: this.openTooltip
    }
  }

  /** hideDropdownTooltipBox */
  hideDropdownTooltipBox(event) {
    if (this.refs.tooltip && !this.refs.tooltip.contains(event.target)) {
      this.setState({
        ariaDescribedby: '',
        ariaExpanded: false
      })
    }
  }

  /** openTooltip() */
  openTooltip() {
    this.setState({
      ariaDescribedby: this.props.id ? this.props.id : '',
      ariaExpanded: true
    })
  }

  render() {
    return (
      <div {...this.dropdownTooltipProps()}>
        <div className='tooltip-launcher'>{this.props.launcher}</div>
        {
          this.state.ariaExpanded && (
            <Tooltip className='tooltip-box' id={this.props.id} role='tooltip'>
              {this.props.children}
            </Tooltip>
          )
        }
      </div>
    )
  }
}

DropdownTooltip.propTypes = {
  id: PropTypes.string,
  dataTestId: PropTypes.string,
  launcher: PropTypes.node
}
